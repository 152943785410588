<template>
    <div>
        <v-dialog v-model="dialog_filter_quarter" no-click-animation scrollable persistent max-width="280px" content-class="full-height" transition="dialog-bottom-transition">
            <v-card>
                <v-subheader>
                    Select Quarter
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <div class="subtitle-text dark-opacity-color" v-bind="attrs" v-on="on" cursor-pointer>
                                FY {{selectedFiscalYear.toString().substring(2)}}-{{(selectedFiscalYear+1).toString().substring(2)}}
                                <v-icon class="subtitle-icon">arrow_drop_down</v-icon>
                            </div>
                        </template>

                        <v-list dense nopadding>
                            <template v-for="(item, index) in fiscalYears">
                                <v-list-item @click="selectedFiscalYear=item.value" :key="index" v-if="fn_checkOpeningDate(item.value)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.title}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </v-subheader>
                <v-card-text class="pa-0">

                    <v-alert type="info" style="font-size:12px" dense :value="true">Showing quarters between opening date and end of this fiscal year!</v-alert>

                    <v-list class="pa-0">
                        <v-list-item v-for="(item, index) in filteredQuarters" :key="index" @click="selectPeriod('quarterly', item.month, selectedFiscalYear)">
                          <v-list-item-title>{{ item.name }} {{item.month > 3 ? selectedFiscalYear : (selectedFiscalYear + 1)}}</v-list-item-title>
                        </v-list-item>
                    </v-list>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="blue darken-1" text @click="closeDialogWeb">close</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
    export default{
        props:['selectPeriod'],
        data(){
            return{
                dialog_filter_quarter:false,
                selectedFiscalYear:null,
                fiscalYears:[
                    // {"title":"FY 2021", "value":2021},
                    // {"title":"FY 2022", "value":2022}
                    {"title":"FY 22-23", "value":2022},
                    {"title":"FY 23-24", "value":2023},
                    {"title":"FY 24-25", "value":2024}
                ],
                quarters:[
                    {"name":"Q1 - Apr To June", "quarter":1, "month":6},
                    {"name":"Q2 - Jul To Sep", "quarter":2, "month":9},
                    {"name":"Q3 - Oct To Dec", "quarter":3, "month":12},
                    {"name":"Q4 - Jan To Mar", "quarter":4, "month":3}
                ]
            }
        },
        methods:{
            // openDialogWeb: function () {
            //     let currentDate = new Date();
            //     let currentYear = currentDate.getFullYear();

            //     let openingDate = new Date(this.$root.CurrentBusiness.OpeningDate);
            //     let openingYear = openingDate.getFullYear();
            //     let openingMonth = openingDate.getMonth() + 1;

            //     if(openingMonth<=3 && currentYear <= openingYear){
            //         this.selectedFiscalYear = openingYear - 1;
            //     }
            //     else{
            //         this.selectedFiscalYear = openingYear;
            //     }

            //     this.dialog_filter_quarter = true;
            // },
            openDialogWeb: function () {
                let maxYear = new Date(this.$root.maxDateSelection).getFullYear();

                let currentDate = new Date();
                let currentYear = currentDate.getFullYear() > maxYear ? maxYear : currentDate.getFullYear();
                let currentMonth = currentDate.getMonth() + 1;

                let openingDate = new Date(this.$root.CurrentBusiness.OpeningDate);
                let openingYear = openingDate.getFullYear();
                let openingMonth = openingDate.getMonth() + 1;

                let openingFY = openingMonth <= 3 ? (openingYear - 1) : openingYear;
                let currentFY = currentMonth <= 3 ? (currentYear - 1) : currentYear;

                this.selectedFiscalYear = currentFY > openingFY ? currentFY : openingFY;

                this.dialog_filter_quarter = true;
            },
            closeDialogWeb: function () {
                this.dialog_filter_quarter = false;
            },
            fn_checkOpeningDate: function(item){
                let openingDate = new Date(this.$root.CurrentBusiness.OpeningDate);
                let openingMonth = openingDate.getMonth() + 1;
                let openingYear = openingDate.getFullYear();
                openingMonth <= 3 ? openingYear = openingYear - 1 : '';
                if(item >= openingYear){
                    return true;
                }
                else{
                    return false;
                }
            },
        },
        computed:{
            filteredQuarters: function(){
                let openingDate = new Date(this.$root.CurrentBusiness.OpeningDate);
                let openingMonth = openingDate.getMonth() + 1;
                let openingYear = openingDate.getFullYear();
                openingMonth <= 3 ? openingYear = openingYear - 1 : '';
                let items = this.quarters.filter(item=>{
                    // if(openingYear == 2022){
                    //     return item.year == 2022;
                    // }
                    // else{
                    //     return item.month >= openingMonth || item.month <= 3;
                    // }

                    if(openingYear == this.selectedFiscalYear){
                        if(openingMonth <= 3){
                            return item.month <= 3;
                        }
                        else{
                            return item.month >= openingMonth || item.month <= 3;
                        }
                    }
                    else if(openingYear < this.selectedFiscalYear){
                        return true;
                    }
                });
                return items;
            }
        }
    }
</script>