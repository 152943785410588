<template>
    <div class="display_wrapper">

        <v-toolbar :height="56" flat id="non-printable-area">

            <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>

            <v-toolbar-title :class="{'pt-1':fixedTabs}" :style="{'line-height':fixedTabs ? '20px' : '1.5'}">
                <!-- {{fixedTabs ? displayData.name : 'Ledger'}} -->
                <template v-if="displayData">
                    {{fixedTabs ? displayData.name : displayData.groupTypeId == 101 ? 'Customer' : displayData.groupTypeId == 102 ? 'Supplier' : 'Ledger'}}
                </template>
                <div font12 v-if="fixedTabs">
                    <!-- <span :class="displayData.balance > 0 ? 'greencolor' : 'redcolor'">&#8377; {{fn_absoluteBal(displayData.balance)}}</span> -->
                    <span>&#8377; {{fn_absoluteBal(displayData.balance)}}</span>
                    <v-icon font16 :class="displayData.balance > 0 ? 'greencolor' : 'redcolor'" v-if="displayData.balance">{{displayData.balance > 0 ? 'arrow_downward' : 'arrow_upward'}}</v-icon>
                </div>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- <v-btn outlined small @click="fn_edit" color="primary" v-if="displayData && !displayData.isSystem">
                <v-icon left>edit</v-icon> Edit
            </v-btn>

            <v-btn class="ml-4" small outlined @click="fn_openDialogDeleteConfirmation" color="red" v-if="displayData && !displayData.isSystem">
                <v-icon left>delete</v-icon> Delete
            </v-btn> -->

            <template v-if="displayData && (displayData.groupTypeId == 101 || displayData.groupTypeId == 102)">
                <v-btn class="mr-4" @click="fn_newSalePurchase" capitalize small outlined>
                    <v-icon left>post_add</v-icon> {{displayData.groupTypeId == 101 ? 'Sale' : 'Purchase'}}
                </v-btn>

                <v-btn class="mr-4" @click="fn_newPaymentReceipt" capitalize small outlined>
                    <img src="../../../../assets/icon_payment_in.png" class="mr-2" style="width:14px" v-if="displayData.groupTypeId == 101" /> 
                    <img src="../../../../assets/icon_payment_out.png" class="mr-2" style="width:14px" v-else /> 
                    {{displayData.groupTypeId == 101 ? 'Pay In' : 'Pay Out'}}
                </v-btn>
            </template>
            

            <v-btn class="mr-4" capitalize small outlined @click="fn_openDialogOptions" v-if="displayData">
                <v-icon left>add</v-icon> Add
            </v-btn>

            <!-- <v-btn class="mr-4" capitalize small outlined @click="fn_viewStatement" v-if="displayData && displayData.groupTypeId != 103">
                <v-icon left>picture_as_pdf</v-icon> Statement
            </v-btn> -->

            <v-menu offset-y left v-if="displayData">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-4" small capitalize outlined v-bind="attrs" v-on="on">
                    <v-icon left>list_alt</v-icon> Statement
                  </v-btn>
                </template>
                  <v-list dense class="pa-0">
                    <v-list-item @click="fn_viewStatement(1)" v-if="displayData.groupTypeId != 103">
                        <v-list-item-content>
                            <v-list-item-title>Ledger Statement</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    
                    <v-list-item @click="fn_viewStatement(2)">
                        <v-list-item-content>
                            <v-list-item-title>Ledger Activity</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                  </v-list>
              </v-menu>

            <v-menu offset-y left v-if="displayData">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small capitalize outlined v-bind="attrs" v-on="on">
                    <v-icon left>more_horiz</v-icon> More
                  </v-btn>
                </template>
                  <v-list dense class="pa-0">
                    <v-list-item @click="fn_edit" v-if="!displayData.isSystem">
                        <v-list-item-icon class="mr-4">
                            <v-icon font20>edit</v-icon>
                        </v-list-item-icon>
                        
                        <v-list-item-content>
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item-content>
                      
                    </v-list-item>
                    
                    <v-list-item @click="fn_copy">
                        <v-list-item-icon class="mr-4">
                            <v-icon font20>content_copy</v-icon>
                        </v-list-item-icon>
                        
                        <v-list-item-content>
                            <v-list-item-title>Copy</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                  
                    <v-list-item class="redcolor" @click="fn_openDialogDeleteConfirmation" v-if="!displayData.isSystem">
                        <v-list-item-icon class="mr-4">
                            <v-icon font20 color="red">delete</v-icon>
                        </v-list-item-icon>
                        
                        <v-list-item-content>
                            <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                  </v-list>
              </v-menu>

        </v-toolbar>

        <v-divider></v-divider>

<!--         <div grey-back full-height id="non-printable-area" v-if="displayData">
            <v-toolbar :height="56" flat>

                <v-toolbar-title>
                    {{fixedTabs ? displayData.name : displayData.groupTypeId == 101 ? 'Customer' : displayData.groupTypeId == 102 ? 'Supplier' : 'Ledger'}}
                    <div toolbar-subtitle v-if="fixedTabs">
                        <span>&#8377; {{fn_absoluteBal(displayData.balance)}}</span>
                        <v-icon font16 :class="displayData.balance > 0 ? 'greencolor' : 'redcolor'" v-if="displayData.balance">{{displayData.balance > 0 ? 'arrow_downward' : 'arrow_upward'}}</v-icon>
                    </div>
                </v-toolbar-title>

            </v-toolbar>

        </div>
 -->

            <div class="data-container grey-back" v-if="displayData" v-scroll.self="onScroll">

                <div id="top_section">
                    <!-- <v-list two-line :class="{'pb-0':fn_showHideSendOption()}">
                        <v-list-tile>
                            <v-list-tile-content>
                                <v-list-tile-title class="mb-1">{{displayData.name}}</v-list-tile-title>
                                <v-list-tile-sub-title>
                                    {{displayData.groupTypeId == 101 ? 'Customer' : displayData.groupTypeId == 102 ? 'Supplier' : 'Other'}}
                                </v-list-tile-sub-title>
                            </v-list-tile-content>
                            <v-list-tile-action style="align-items:flex-start;padding-top:12px">
                                <div>
                                    &#8377; {{fn_absoluteBal(displayData.balance)}} <v-icon font20 :class="displayData.balance > 0 ? 'greencolor' : 'redcolor'" v-if="displayData.balance">{{displayData.balance > 0 ? 'arrow_upward' : 'arrow_downward'}}</v-icon>
                                </div>
                            </v-list-tile-action>
                        </v-list-tile>
                    </v-list> -->

                    <div class="pa-4" white-back>
                        <v-layout ma-0 row wrap>
                            <v-flex xs12 sm12 md12 pb-3 font20>{{displayData.name}}</v-flex>
                            <v-flex xs12 sm12 md12 font20 style="align-self: center;">
                                &#8377; {{fn_absoluteBal(displayData.balance)}} 
                                <v-icon class="circle_arrow_icon mt-1" :class="displayData.balance > 0 ? 'greencolor' : 'redcolor'" v-if="displayData.balance">{{displayData.balance > 0 ? 'arrow_downward' : 'arrow_upward'}}</v-icon>
                                <div class="font12">
                                    <span class="greencolor" v-if="displayData.balance > 0">{{ displayData.groupTypeId == 101 || displayData.groupTypeId == 102 ? "To Recieve" : "Debit" }}</span>
                                    <span class="redcolor" v-if="displayData.balance < 0">{{ displayData.groupTypeId == 103 ? "Credit" : "To Pay" }}</span>
                                </div>
                            </v-flex>

                            <!-- <v-flex xs12 sm12 md12 font12 light-opacity-color v-if="displayData.balance && (displayData.groupTypeId == 101 || displayData.groupTypeId == 102)">{{displayData.balance > 0 ? 'To Receive' : 'To Pay'}}</v-flex> -->
                        </v-layout>
                    </div>

                    <!-- <div white-back class="pt-2 pb-3" v-if="displayData.groupTypeId != 103">
                        <v-layout ma-0 row wrap>

                            <v-flex :class="[displayData.balance>0  ? 'xs3 sm3 md3' : 'xs4 sm4 md4']" center-align>
                                <div v-ripple style="height:48px;width:48px;margin:auto;border-radius:50%;padding: 14px 0px;background: #f0f0f0;" @click="fn_whatsAppDirect">
                                    <v-icon>send</v-icon>
                                </div>
                                <div style="font-weight: 600;font-size: 11px;margin-top: 5px;">WhatsApp</div>
                            </v-flex>

                            <v-flex :class="[displayData.balance>0 ? 'xs3 sm3 md3' : 'xs4 sm4 md4']" center-align>
                                <div v-ripple style="height:48px;width:48px;margin:auto;border-radius:50%;padding: 14px 0px;background: #f0f0f0;">
                                    <a :href="'tel:'+displayData.primaryMobileNumber" style="text-decoration:none" v-if="displayData.primaryMobileNumber">
                                        <v-icon font20>call</v-icon>
                                    </a>

                                    <v-icon font20 v-if="!displayData.primaryMobileNumber" @click="fn_openDialogEditConfirmation">call</v-icon>
                                </div>
                                <div style="font-weight: 600;font-size: 11px;margin-top: 5px;">Call</div>
                            </v-flex>

                            <v-flex class="xs3 sm3 md3" center-align v-if="displayData.balance>0">
                                <div v-ripple style="height:48px;width:48px;margin:auto;border-radius:50%;padding: 14px 0px;background: #f0f0f0;" @click="fn_paymentReminder">
                                    <v-icon font20>notifications_none</v-icon>
                                </div>
                                <div style="font-weight: 600;font-size: 11px;margin-top: 5px;">Reminder</div>
                            </v-flex>

                            <v-flex :class="[displayData.balance>0 ? 'xs3 sm3 md3' : 'xs4 sm4 md4']" center-align>
                                <div v-ripple style="height:48px;width:48px;margin:auto;border-radius:50%;padding: 14px 0px;background: #f0f0f0;" @click="fn_viewStatement">
                                    <v-icon>picture_as_pdf</v-icon>
                                </div>
                                <div style="font-weight: 600;font-size: 11px;margin-top: 5px;">Statement</div>
                            </v-flex>

                        </v-layout>
                        
                    </div> -->
                </div>

                <v-divider></v-divider>

                <!-- <v-tabs v-model="activeIndex" fixed-tabs @input="fn_tabChange" slider-color="primary" class="fix_width_tabcount2"> -->
                <v-tabs v-model="activeIndex" @input="fn_tabChange" slider-color="primary">
                    <v-tab v-for="(tab, index) in tabs" :key="index" ripple>{{tab.title}}</v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeIndex" @input="fn_tabChange">

                    <v-tab-item>  
                        <!-- <keep-alive> -->
                            <activity-tab ref="ref_activity" :partyId="displayData.accountId" :partyName="displayData.name" :groupTypeId="displayData.groupTypeId" :preview="fn_preview" :bindInvoiceTemplate="fn_bindInvoiceTemplate" :openDialogOptions="fn_openDialogOptions" v-if="displayData"></activity-tab>
                        <!-- </keep-alive> -->
                    </v-tab-item>

                    <!-- <v-tab-item>  
                        <keep-alive>
                            <transactions-tab ref="ref_transactions" :partyId="displayData.accountId" v-if="displayData"></transactions-tab>
                        </keep-alive>
                    </v-tab-item> -->

                    <v-tab-item>  
                        <!-- <keep-alive> -->
                            <details-tab ref="ref_details" :data="displayData" v-if="displayData"></details-tab>
                        <!-- </keep-alive> -->
                    </v-tab-item>

                </v-tabs-items>

                <!-- <v-footer class="invoice-footer-actions" v-if="displayData">
                    <v-layout ma-0 grid white-back wrap text-xs-center>

                        <v-flex :class="[(displayData.groupTypeId == 101 || displayData.groupTypeId == 102) && (displayData.balance && displayData.groupTypeId != 103) ? 'xs3 sm3 md3' : 'xs4 sm4 md4']" @click="fn_newSalePurchase" pt-2 pb-2 v-ripple v-if="displayData.groupTypeId == 101 || displayData.groupTypeId == 102">
                            <div right-border>
                                <div v-if="displayData.groupTypeId == 101" style="height:26px">
                                    <img src="../../../../assets/icon_invoice.svg" style="opacity:0.6" alt="icon_invoice">
                                </div>
                                <div v-else><v-icon>add_shopping_cart</v-icon></div>
                                
                                <div font11 class="font-weight-bold">{{displayData.groupTypeId == 101 ? 'Sale' : 'Purchase'}}</div>
                            </div>
                        </v-flex>

                        <v-flex :class="[displayData.balance && displayData.groupTypeId != 103 ? 'xs3 sm3 md3' : 'xs4 sm4 md4']" @click="fn_newPaymentReceipt" pt-2 pb-2 v-ripple v-if="displayData.balance && displayData.groupTypeId != 103">
                            <div right-border>
                                <div style="height:26px;padding-top:4px;">
                                    <img src="../../../../assets/icon_payment_in.png" style="width:18px;opacity:0.6" alt="icon_rupee" v-if="displayData.balance>0">
                                    <img src="../../../../assets/icon_payment_out.png" style="width:18px;opacity:0.6" alt="icon_rupee" v-else>
                                </div>
                                <div font11 class="font-weight-bold">{{displayData.groupTypeId == 101 ? 'Pay In' : 'Pay Out'}}</div>
                            </div>
                        </v-flex>

                        <v-flex :class="[displayData.groupTypeId == 103 ? 'xs6 sm6 md6' : displayData.balance ? 'xs3 sm3 md3' : 'xs4 sm4 md4']" pt-2 pb-2 v-ripple @click="fn_openDialogOptions">
                            <div right-border>
                                <div><v-icon>add</v-icon></div>
                                <div font11 class="font-weight-bold">Add</div>
                            </div>
                        </v-flex>

                        <v-flex :class="[displayData.groupTypeId == 103 ? 'xs6 sm6 md6' : displayData.balance ? 'xs3 sm3 md3' : 'xs4 sm4 md4']" @click="fn_openDialogPeriod(2)" pt-2 pb-2 v-ripple>
                            <div right-border>
                                <div><v-icon>vertical_align_bottom</v-icon></div>
                                <div font11 class="font-weight-bold">Download</div>
                            </div>
                        </v-flex>

                    </v-layout>

                    <div v-ripple class="pb-2 pt-2" center-align :style="{'width':displayData.groupTypeId == 103 ? '33.33%' : displayData.balance ? '20%' : '25%'}" white-back @click="fn_getBottomMenu">
                        <div><v-icon>more_horiz</v-icon></div>
                        <div font11 class="font-weight-bold">More</div>
                    </div>
                </v-footer> -->
                
            </div>

            <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);z-index:99" v-if="loading">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>

            <!-- <dialog-transaction-options ref="ref_dialogTransactionOptions" :groupTypeId="displayData.groupTypeId" :accountId="displayData.accountId" :accountName="displayData.name" :showPartyOptions="true" v-if="displayData"></dialog-transaction-options>
            
            <dialog-statement ref="ref_dialogStatement" :closeDialog="fn_closeDialog" :openDatePicker="fn_openDatePicker" :startDate="startDate" :endDate="endDate" :generateStatement="fn_generateStatement" :statementType="statementType"></dialog-statement>

            <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker> -->

            <!-- <canvas id="canvas-display-tab" width="400" height="200" class="payment-reminder-canvas" style="max-width:100%"></canvas> -->

            <!-- <dialog-permission ref="ref_dialogPermission"></dialog-permission> -->

            <v-snackbar v-model="snackbar">
              File downloaded!
              <v-btn text color="primary" @click.native="$root.fn_openDownloadedFile">Open</v-btn>
            </v-snackbar>

            <!-- <v-snackbar multi-line :timeout="0" v-model="snackbarMobileNumber">
              Mobile number not added!
              <v-btn flat color="warning" @click.native="snackbarMobileNumber=false">Later</v-btn>
              <v-btn flat color="primary" @click.native="fn_edit">Add Now</v-btn>
            </v-snackbar> -->

            <dialog-confirmation ref="ref_dialogDeleteConfirmation" :header="confirmationHeader" :confirmationMessage="confirmationMessage" :actionMethod="actionMethod" :okBtnText="okBtnText" :cancelBtnText="cancelBtnText" params="delete" :displayView='true'></dialog-confirmation>

            <dialog-transaction-options ref="ref_dialogTransactionOptions" :groupTypeId="displayData.groupTypeId" :accountId="displayData.accountId" :accountName="displayData.name" :showPartyOptions="true" v-if="displayData"></dialog-transaction-options>
        </div>

        <!-- <div white-back id="printable" full-width v-if="printableType">
          <component :is="printTemplate" ref="ref_printTemplate" :data="printData" v-if="printTemplate && printData!=null"></component>
        </div>

        <div id="printable-area" white-back full-width v-if="!printableType">
            <ledger-statement ref="ref_ledgerStatement" :statementData="statementData" :statementType="statementType" :opening="displayData.opening" v-if="statementData"></ledger-statement>
        </div>

        <dialog-confirmation ref="ref_dialogDeleteConfirmation" :header="confirmationHeader" :confirmationMessage="confirmationMessage" :actionMethod="actionMethod" :okBtnText="okBtnText" :cancelBtnText="cancelBtnText" params="delete" :displayView='true'></dialog-confirmation> -->


    <!-- </div> -->
</template>

<script>
// const menuItem = require('../../../../config.js').configs.menuItem;
let ledgerTabConfigs = require('../../../../config.js').configs.ledgerTabConfigs;
const ledgerGroupTypeId = require('../../../../config.js').configs.ledgerGroupTypeId;

// const dialogjs =  require('../dialog').dialogjs;


import {masterMixin} from '../../master_mixin.js'
import activityTab from './display_tabs/activity'
// import transactionsTab from './display_tabs/transactions'
import detailsTab from './display_tabs/details'
import dialogTransactionOptions from '../../../dialogs/dialog_transaction_options'
// import dialogStatement from './dialogs/dialog_statement'
// import dialogDatepicker from './dialogs/dialog_datepicker'
// import ledgerStatement from './ledger_statement'

// import dialogPermission from '../../../dialogs/dialog_permission'
import dialogConfirmation from '../../../dialogs/dialog_confirmation'

import {transactionMixin} from '../../../transactions/transaction_mixin'

    export default{
        mixins: [masterMixin, transactionMixin],
        components: {
            'activity-tab': activityTab,
            // 'transactions-tab': transactionsTab,
            'details-tab': detailsTab,
            'dialog-transaction-options':dialogTransactionOptions,
            // 'dialog-statement':dialogStatement,
            // 'dialog-datepicker':dialogDatepicker,
            // 'ledger-statement':ledgerStatement,
            // 'dialog-permission': dialogPermission,
            'dialog-confirmation': dialogConfirmation
        },
        data(){
            return{
                displayData:null,
                tabs:[
                    {"id":1, "title":"Activity"},
                    {"id":2, "title":"Details"}
                ],
                // currentBalance:null,
                activeIndex:0,
                offsetTop: 0,
                fixedTabs:false,
                isGoBack:false,
                selectedDate:null,
                startDate:this.$root.minDateSelection,
                endDate:this.$root.maxDateSelection,
                formId:null,
                dialogStorageId:null,
                statementData:null,
                statementType:1,
                snackbar:false,
                openPeriodDialog:false,
                printData:null,
                printTemplate:null,
                printableType:null,//1 for statement, 2 for invoice
                // snackbarMobileNumber:false,
                confirmationHeader:null,
                confirmationMessage:null,
                okBtnText:null,
                cancelBtnText:null,
                actionMethod:null,
                loading:false
            }
        },
        methods:{

            fn_newSalePurchase: function(){
                // this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/saleinvoice/items/new', query:{tagId:this.guidWeb()}});
                if(this.displayData.groupTypeId == 101){
					this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/saleinvoice/items/new', query:{tagId:this.guidWeb(), id:this.displayData.accountId, name:this.displayData.name, dt:1}});
				}
				else{
					this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/purchaseinvoice/items/new', query:{tagId:this.guidWeb(), id:this.displayData.accountId, name:this.displayData.name, dt:1}});
				}
            },

            fn_viewStatement: function(statementType){
                // alert('under development!');
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/ledger/items/'+this.displayData.accountId+'/statement', query:{back:1, type:statementType}});
                // this.$router.push({path:"/masters/accounts/ledgers/items/"+this.displayData.accountId+"/statement"});
            },

            fn_openDialogEditConfirmation: function(){
                this.okBtnText = 'Edit';
                this.cancelBtnText = 'Close';
                this.confirmationHeader = "Mobile Number not added";
                this.confirmationMessage = "Mobile Number is required for WhatsApp and Call.";
                this.actionMethod = this.fn_edit;
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            },

            fn_openDialogDeleteConfirmation: function(){
                this.okBtnText = 'Ok';
                this.cancelBtnText = 'Close';
                this.confirmationHeader = "Delete Master?";
                this.confirmationMessage = null;
                this.actionMethod = this.fn_deleteLedgerAndOpening;
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            },

            fn_preview: function(item, formNameFn){
                item.toolbarTitle = formNameFn ? formNameFn(item.transactionListId) : 'order';
                let printType = item.invoiceTemplate == 101 ? 'ab' : item.invoiceTemplate == 201 ? 'sj' : null;

                let type = null;
                if(item.transactionListId == 10001 || item.transactionListId == 10002 || item.transactionListId == 10003 || item.transactionListId == 10004 || item.transactionListId == 10005){
                  type = 'sales';
                }
                else if(item.transactionListId == 20001 || item.transactionListId == 20002 || item.transactionListId == 20003 || item.transactionListId == 20004 || item.transactionListId == 20005){
                  type = 'purchase';
                }
                else if(item.transactionListId == 40002){
                  type = 'inventory';
                }
                
                this.fn_previewInvoice(true, type, printType, item);
            },

            fn_bindInvoiceTemplate: function(type, item){

                this.printTemplate = null;
                this.printData = null;
                this.printableType = 1;

                this.printTemplate = require('../../../invoice_templates/invoices/template'+item.invoiceTemplate).default;
                // this.printTemplate = require('../invoice_templates/invoices/template1').default;
                console.log(this.printTemplate);

                let dataFnName = item.invoiceTemplate == 101 ? 'AccountBookPrintData' : item.invoiceTemplate == 201 ? 'StockJournalPrintData' : 'InvoiceData';

                if(type=='sharewhatsapp'){
                  this.fn_shareInvoice('sharewhatsapp', item, dataFnName);
                }
                else if(type=='print'){
                  this.fn_getPrintData(dataFnName, item.transactionId, 'print', null, item.mergeTransactionNumber, item.transactionDate, item.transactionListId);
                }
                else if(type=='pdf'){
                  this.fn_getPrintData(dataFnName, item.transactionId, 'pdf', null, item.mergeTransactionNumber, item.transactionDate, item.transactionListId);
                }
                else if(type=='share'){
                  this.fn_shareInvoice('share', item, dataFnName);
                }

                let self = this;
                setTimeout(function(){
                    self.printableType = null;
                },10000);
            },

            fn_shareInvoice: function(type, item, dataFnName){

                let title = null;
                if(item.transactionListId==10003){
                    title = "Invoice";
                }
                else{
                    title = this.fn_getTransactionTitle(item.transactionListId);    
                }

                // if(item.transactionTypeFormName.toLowerCase() == "itc not eligible"){
             //      item.transactionTypeFormName = "Expense";
             //    }

             //    if(item.transactionTypeFormName == "Retail Invoice" || item.transactionTypeFormName == "Wholesale Invoice" || item.transactionTypeFormName == "Export Invoice" || item.transactionTypeFormName ==  "Stock Transfer"){
             //        item.transactionTypeFormName = "Invoice";
             //    }
        
                let shareObj = {};
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = this.displayData.primaryMobileNumber && type == 'sharewhatsapp' ? this.displayData.primaryMobileNumber : null;
                shareObj.subject = title + " Details";
                shareObj.email = this.displayData.email ? [this.displayData.email] : null ;

                let party = this.displayData.name ? this.displayData.name + ',' : '';

                shareObj.textMessage = "Dear "+party+"\n\nBelow are your " + title + " details. Please find the attached pdf.\n\nTransaction Date: "+this.$moment(item.transactionDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(item.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;


                // shareObj.textMessage = "Dear "+this.displayData.name+",\n\nBelow are your invoice details. Please find the attached pdf of your invoice.\n\nInvoice Date: "+this.$moment(item.createdDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(item.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.fn_getPrintData(dataFnName, item.transactionId, 'pdf', shareObj, item.mergeTransactionNumber, null, item.transactionListId);
            },

            // fn_checkDrive: function(){
            //  let self = this;
            //  if(this.$root.CurrentBusiness.DataDrive){
            //      this.fn_openDialogOptions();
            //  }
            //  else{
            //      this.$root.fn_getTransactionCount(function(count){
            //          if(count>=10){
            //              self.$refs['ref_dialogDriveAlert'].dialog_setup_drive = true;
            //          }
            //          else{
            //              self.fn_openDialogOptions();
            //          }
            //      })
            //  }
            // },
            fn_newPaymentReceipt: function(){
                let type = this.displayData.groupTypeId == 101 ? 'receipt' : 'payment';
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+type+'/items/new', query:{tagId:this.guidWeb(), id:this.displayData.accountId, name:this.displayData.name, dt:1}});
                // this.$router.push({path:"/transactions/"+type+"/items/new", query:{tagId:this.guidWeb(), id:this.displayData.accountId, name:this.displayData.name, dt:1}});
            },

            fn_showHideSendOption: function(){
                if(this.displayData.groupTypeId == ledgerGroupTypeId.customer || this.displayData.groupTypeId == ledgerGroupTypeId.supplier){
                    return true;
                }
                else{
                    return false;
                }
            },

            fn_getFlexClass: function(){
                if(this.displayData.groupTypeId != ledgerGroupTypeId.customer && this.displayData.groupTypeId != ledgerGroupTypeId.supplier){
                    return 'xs6 sm6 md6'
                }
                else{
                    return 'xs4 sm4 md4'
                }
                // else if(this.displayData.balance <= 0 && (this.displayData.groupTypeId == ledgerGroupTypeId.customer || this.displayData.groupTypeId == ledgerGroupTypeId.supplier)){
                //  return 'xs4 sm4 md4'
                // }
                // else{
                //  return 'xs3 sm3 md3';
                // }
                
            },

            // fn_openDialogWeb: function (platform, name, ref) {

            //     let scroll_y = window.scrollY;
            //     this.fn_fixBackgroundScroll();

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.data = null;
            //     dialogObj.platform = platform;

            //     dialogObj.scrollPosition = {};
            //     dialogObj.scrollPosition.x = 0;
            //     dialogObj.scrollPosition.y = scroll_y;

            //     dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //     this.$refs[ref].openDialogWeb();
            // },

            fn_openDialogPeriod: function(type){
                this.statementType = type;
                this.fn_openDialogWeb('web', 'Period', 'ref_dialogStatement');
            },

            // fn_closeDialog: function(){
            //     dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                this.selectedDate = date ? date : new Date();
                setTimeout(function () {
                    self.fn_openDialogWeb('web', 'DatePicker', 'ref_dialogDatePicker');
                });
            },

            fn_openPermissionDialog: function(permissionType){
                this.$root.permissionType = permissionType;
                this.fn_openDialogWeb('web', 'Period', 'ref_dialogPermission');
            },

            fn_updateDate: function (value) {
                if(this.dateType=="start"){
                    this.startDate = value;
                }
                else if(this.dateType=="end"){
                    this.endDate = value;
                }

                this.fn_closeDialog();
            },

            fn_generateStatement: function(type, dateRange){
                this.fn_dateRange(type, dateRange);
                // let self = this;
                // this.$root.fn_checkStoragePermission(function(){
                //  self.fn_closeDialog();
                //  self.statementType == 1 ? self.fn_getLedgerStatement(type) : self.fn_getLedgerActivity(type);
                // })
            },

            fn_dateRange:  function (type, dateRange){
              // let dateObj = {};

                if(dateRange==7){
                    let date = new Date();
                    date.setDate(date.getDate() - 7);
                    this.startDate = date
                    this.endDate = new Date();
                }
                else if(dateRange==30){
                    let date = new Date();
                    date.setDate(date.getDate() - 30);
                    this.startDate = date;
                    this.endDate = new Date();
                }
                else if(dateRange==0){
                    this.startDate = this.$root.minDateSelection;
                    this.endDate = this.$root.maxDateSelection;
                }

                let self = this;
                this.$root.fn_checkStoragePermission(function(){
                    self.fn_closeDialog();
                    self.statementType == 1 ? self.fn_getLedgerStatement(type) : self.fn_getLedgerActivity(type);
                })

            },

            formatDate: function(date_obj){
                return date_obj.getFullYear() + "-" + ("0" + (date_obj.getMonth()+1)).slice(-2) + "-" + ("0" + date_obj.getDate()).slice(-2);
            },

            fn_getLedgerStatement: function(type){
                // this.showLoading('Preparing Ledger Statement...');
                let filterObject = {};
                filterObject.reportId = 201;

                if(new Date(this.startDate).getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                    this.startDate = this.$root.CurrentBusiness.OpeningDate;
                }

                filterObject.startDate = this.$moment(this.startDate).startOf('day'); //new Date(this.startDate);
                filterObject.endDate = this.$moment(this.endDate).endOf('day');

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);
                
                let self = this;
                this.getReportData('Report', this.displayData.accountId, filterObject,{

                    onSuccess: function(response){
                        console.log(response);
                        self.statementData = JSON.parse(response);
                        self.statementData.accountDetails = {};
                        self.statementData.accountDetails.accountName = self.displayData.name;
                        self.statementData.accountDetails.startDate = self.startDate;
                        self.statementData.accountDetails.endDate = self.endDate;
                        self.statementData.accountDetails.primaryMobileNumber = self.displayData.primaryMobileNumber
                        self.statementData.accountDetails.email = self.displayData.email;
                        self.fn_statementSummary(type);
                        console.log(self.statementData);
                    }, 
                    onError: function(error){
                        console.log(error);
                        // self.hideLoading();
                    }

                });
            },

            fn_getLedgerActivity: function(type){
                // this.showLoading('Preparing Ledger Activity...');
                let filterObject = {};

                if(new Date(this.startDate).getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                    this.startDate = this.$root.CurrentBusiness.OpeningDate;
                }

                filterObject.startDate = this.$moment(this.startDate).startOf('day');
                filterObject.endDate = this.$moment(this.endDate).endOf('day');

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);
                
                let self = this;
                this.getFilterData('LedgerActivityData', this.displayData.accountId, filterObject, null,{

                    onSuccess: function(response){
                        console.log(response);
                        self.statementData = JSON.parse(response);
                        self.statementData.accountDetails = {};
                        self.statementData.accountDetails.accountName = self.displayData.name;
                        self.statementData.accountDetails.startDate = self.startDate;
                        self.statementData.accountDetails.endDate = self.endDate;
                        self.statementData.accountDetails.primaryMobileNumber = self.displayData.primaryMobileNumber
                        self.statementData.accountDetails.email = self.displayData.email;
                        self.fn_statementSummary(type);
                        console.log(self.statementData);
                    }, 
                    onError: function(error){
                        console.log(error);
                        // self.hideLoading();
                    }

                });
            },

            fn_statementSummary: function(type){
                this.statementData.accountDetails.opening = this.displayData.opening ? this.displayData.opening : 0;

                this.statementData.accountDetails.debitTotal = this.statementData.data.footerRow.debit
                this.statementData.accountDetails.creditTotal = this.statementData.data.footerRow.credit

                if(this.displayData.opening > 0){
                    this.statementData.accountDetails.debitTotal = this.roundUpto2Decimals(this.statementData.data.footerRow.debit - this.displayData.opening); 
                }
                else if(this.displayData.opening < 0){
                    this.statementData.accountDetails.creditTotal = this.roundUpto2Decimals(this.statementData.data.footerRow.credit + this.displayData.opening);
                }

                // this.statementData.accountDetails.opening = this.statementData.data.detailRows[0].cols.balance;
                this.statementData.accountDetails.balance = this.statementData.data.footerRow.balance;

                // if(this.statementData.data.footerRow.creditDebitType == 'To Receive')
             //     this.statementData.accountDetails.balance = this.statementData.data.footerRow.balance;
             //    else
             //     this.statementData.accountDetails.balance = -this.statementData.data.footerRow.balance;


                let self = this;
                setTimeout(function(){
                    self.fn_exportStatementToPdf(type);
                },500);
                // this.hideLoading();
            },

            fn_exportStatementToPdf: function(type){
                let self = this;
                // let startDate = new Date(this.startDate);
                // let endDate = new Date(this.endDate);
                // let downloadTime = new Date();
                // let fileName = "ledger_statement_" + downloadTime.getTime()+'.pdf';
                // let fileName = "Ledger_Statement_" + downloadTime.getTime()+'.pdf';
                let fileName = null;

                let ledgerName = this.displayData.name.replace(/\//g,'');

                if(this.statementType == 1){
                    fileName = "LedgerStatement-"+ledgerName + '_' + this.$root.fn_getCurrentDateTimeString()+".pdf";
                }
                else{
                    fileName = "LedgerActivity-"+ledgerName + '_' + this.$root.fn_getCurrentDateTimeString()+".pdf";
                }

                this.exportToPdf(fileName,{

                  onSuccess: function(response){
                      console.log(response);
                      if(type=='download'){
                        self.snackbar = true;
                      }
                      else{
                        self.fn_shareStatement(type);
                      }
                  }, 
                  onError: function(error){
                      console.log(error);
                      // self.hideLoading();
                      self.showToast('Something went wrong. Try again');
                  }
                });
            },

            fn_shareStatement: function(type){
                let shareObj = {};
                // shareObj.shareType = 'share';
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = this.displayData.primaryMobileNumber ? this.displayData.primaryMobileNumber : null;
                shareObj.subject = this.statementType == 1 ? "Ledger Statement" : "Ledger Activity";
                shareObj.email = this.displayData.email ? [this.displayData.email] : null ;

                let party = this.displayData.name ? this.displayData.name + ',' : '';
                shareObj.textMessage = "Dear "+party+"\n\nPlease find the attached pdf\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.$root.fn_share(shareObj);
            },

            fn_openDialogOptions: function(){
                this.$refs['ref_dialogTransactionOptions'].openDialogWeb();
            },

            fn_goBack: function(){
                this.isGoBack = true;
                this.$router.go(-1);
            },

            fn_getLabel: function(){
                if(this.displayData.balance==0 || this.displayData.balance==null){
                    return '';
                }
                else if(this.displayData && this.displayData.groupTypeId == 101 || this.displayData.groupTypeId == 102){
                    if(this.displayData.balance > 0){
                        return '(Due)';
                    }
                    else{
                        return '(Outstanding)'
                    }
                }
                else{
                    if(this.displayData.balance > 0){
                        return '(Dr)';
                    }
                    else{
                        return '(Cr)'
                    }
                }
            },

            fn_paymentReminder: function(){
                let self = this;
                this.$root.fn_checkStoragePermission(function(){
                    let obj = {};
                    obj.amount = self.displayData.balance;
                    obj.primaryMobileNumber = self.displayData.primaryMobileNumber;
                    obj.email = self.displayData.email;
                    self.$root.fn_sendPaymentReminder(obj, "canvas-display-tab");
                })
            },

            fn_whatsAppDirect: function(){
                if(!this.displayData.primaryMobileNumber){
                    // this.snackbarMobileNumber = true;
                    this.fn_openDialogEditConfirmation();
                    return;
                }

                let shareObj = {};
                shareObj.type = "message"
                shareObj.shareType = "share"
                shareObj.primaryMobileNumber = this.displayData.primaryMobileNumber;
                shareObj.email = null;
                shareObj.subject = null;
                shareObj.textMessage = "";
                shareObj.base64String = null;
                this.$root.fn_share(shareObj);
            },

            // fn_getCurrentBalance: function(ledgerId){
            //  let self = this;
            //  // CurrentBalance
            //  this.getData('AccountDetails', ledgerId, 
            //      {
            //          onSuccess: function(response){
            //              console.log(response);
            //              let data = JSON.parse(response);
            //                  self.currentBalance = self.roundUpto2Decimals(Number(data.balance));
            //                  self.hideLoading();
            //          }, 
            //          onError: function(error){
            //              console.log(error);
            //              self.hideLoading();
            //          }
            //      }   
         //        );
            // },

            fn_absoluteBal: function(bal){
                return this.$root.numberToLocale(Math.abs(bal));
            },

            fn_tabChange:function(tabIndex){
                ledgerTabConfigs.activeIndex = tabIndex;
            },

            // onScroll (e) {
            //     console.log(e);
            //     this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
            //     if(this.offsetTop>100){
            //         this.fixedTabs = true;
            //     }
            //     else{
            //         this.fixedTabs = false;
            //     }
            // },

            onScroll: function(event){
                var element = event.target;

                if(element.scrollTop>100){
                    this.fixedTabs = true;
                }
                else{
                    this.fixedTabs = false;
                }

                if (element.scrollHeight - element.scrollTop - 20 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.$refs['ref_activity'].listItemObject.items.length < this.$refs['ref_activity'].listItemObject.totalCount){
                        this.$refs['ref_activity'].fn_loadItems();
                    }
                }
            },

            fn_get: function(itemId){
                var self = this;
                // this.showLoading('Loading details...');
                this.displayData = null;
                this.loading = true;
                this.activeIndex = 0;

                this.getData('Ledger', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.displayData = JSON.parse(response);

                            self.loading = false;
                            // self.currentBalance = self.roundUpto2Decimals(Number(self.displayData.balance));
                            // self.hideLoading();

                            // if(self.$route.query && self.$route.query.statementType){
                            //  self.fn_openDialogPeriod(self.$route.query.statementType);
                            // }
                            if(self.openPeriodDialog){
                                setTimeout(function(){
                                    self.fn_openDialogPeriod(self.$route.query.statementType);
                                },200);
                            }

                            setTimeout(function(){
                                self.$refs['ref_activity'].fn_loadItems(true);
                            },100);
                            // self.fn_getCurrentBalance(itemId);
                        }, 
                        onError: function(error){
                            self.loading = false;
                            console.log(error);
                            // self.hideLoading();
                        }
                    }
                );
            },

            // fn_showWebView: function () {
            //     var viewObject = {};
            //     viewObject.ToolbarTitle = "Account Ledger";
            //     viewObject.TopMenu = [menuItem.edit];
            //     viewObject.isBottomMenu = true;

            //     var tagId = null;
            //     this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';
            //     var self = this;

            //     this.showWebView(tagId, viewObject, 
            //         {
            //             onSuccess: function(response){
            //               console.log(response);
            //             }, 
            //             onError: function(error){
            //               console.log(error);
            //             }, 
            //             // onChange: null, 
            //             onMenuItemClick: function (tagId, menuId, menuAction) {
            //                 self.fn_menuItemClick(tagId, menuId, menuAction)
            //             }
            //         }
            //     );
            // },

            // fn_getBottomMenu: function (addItems) {
            //     // let bottomMenu = null
            //     // if(addItems){
            //     //  bottomMenu = {"BottomMenu": [menuItem.addOpening]};
            //     // }
            //     // else{
            //     //  bottomMenu = {"BottomMenu": [menuItem.edit, menuItem.copy, menuItem.delete, menuItem.help]};    
            //     // }
            //     let customMenu = {};
            //     // customMenu.ledgerActivity = {"MenuID":1001, "Icon":null, "Text":"Share/Export Activity", "Action":"ledger_activity"};
            //     customMenu.ledgerStatement = {"MenuID":1001, "Icon":require('../../../../assets/icon_pdf_light.png'), "Text":"Statement", "Action":"ledger_statement"};

            //     let bottomMenu = null;
            //     if(this.fn_showHideSendOption()){
            //         bottomMenu = {"BottomMenu": [customMenu.ledgerStatement, menuItem.edit, menuItem.copy]};
            //     }
            //     else{
            //         bottomMenu = {"BottomMenu": [menuItem.edit, menuItem.copy]};
            //     }
            //     // var bottomMenu = {"BottomMenu": [customMenu.ledgerStatement, menuItem.edit, menuItem.copy]};

            //     if(!this.displayData.isSystem){
            //         bottomMenu.BottomMenu.push(menuItem.delete);
            //     }

            //     this.setBottomMenu(null, bottomMenu, 
            //         {
            //             onSuccess: function (response) {
            //                 console.log(response);
            //             },
            //             onError: function (error) {
            //                 console.log(error);
            //             }
            //         }
            //     )
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //     if(menuAction=='ledger_statement'){
            //         // this.fn_openDialogPeriod(1);
            //         this.fn_viewStatement();
            //     }
            //     if(menuAction=='edit' && this.displayData && this.displayData.accountId){
            //         this.fn_edit();
            //     }
            //     else if(menuAction=='copy'){
            //         this.$router.replace({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), itemId:this.displayData.accountId, copy:true, routeToDisplay:true}});
            //     }
            //     else if(menuAction=='delete'){
            //         this.okBtnText = 'Ok';
            //         this.cancelBtnText = 'Close';
            //         this.confirmationHeader = "Delete Master?";
            //         this.confirmationMessage = null;
            //         this.actionMethod = this.fn_deleteLedgerAndOpening;
            //         this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            //         // this.fn_deleteLedgerAndOpening();
            //     }
            //     else if(menuAction=='addopening'){
            //         this.$router.push({path:'/transactions/inventory/openingledger/items/new', query:{tagId:this.guidWeb(), accountId:this.displayData.accountId, name:this.displayData.name}});
            //     }
            // },

            fn_copy: function(){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), itemId:this.displayData.accountId, copy:true, routeToDisplay:true}});
            },

            fn_deleteLedgerAndOpening: function(){
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = false;
                let self = this;
                let filterObject = {};
                filterObject.accountId = this.displayData.accountId;
                if(this.displayData.opening && this.displayData.opening.transactionId){
                    filterObject.transactionId = this.displayData.opening.transactionId;
                }
                this.postFilterData('deleteAccountWithOpening', null, filterObject, {

                    onSuccess: function(response){
                        
                        console.log(response);
                        if(JSON.parse(response)){
                            // alert('Item deleted');
                            self.$eventHub.$emit('snackbarAlert', {msg:'Item deleted'});
                            self.$eventHub.$emit('UpdateLedgerList', {});
                            self.fn_goBack();
                        }
                        else{
                            // alert("Item cannot be deleted as already in use");
                            self.$eventHub.$emit('snackbarAlert', {msg:'Item cannot be deleted as already in use'});
                        }
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                });
            },

            fn_edit: function () {
                // this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = false;
                this.$router.push('/'+this.$route.params.businessId+'/'+this.$route.params.listId+'/ledger/items/'+this.displayData.accountId+'/edit');
            }
        },
        mounted: function(){
            // this.fn_showWebView();
            // this.formId = this.guidWeb();
            // this.dialogStorageId = 'dialog_'+this.formId;

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_onBackPress = this.fn_goBack;
            // window.js_globals.fn_openPermissionDialog = this.fn_openPermissionDialog;
            this.$eventHub.$on('delete', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_openDialogDeleteConfirmation() : '';
            });

            this.$eventHub.$on('edit', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_edit() : '';
            });

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                // ledgerTabConfigs.activeIndex = 0;

                if(vm.$route.params && vm.$route.params.tagId){
                    // from.name == 'ledgerItems' || 'activitytabs' ? ledgerTabConfigs.activeIndex = 0 : '';
                    ledgerTabConfigs.activeIndex = 0;
                    vm.fn_get(vm.$route.params.tagId);
                }

                from.query.routeToDisplay ? ledgerTabConfigs.activeIndex = 1 : '';

                if(ledgerTabConfigs.activeIndex != null){
                    vm.activeIndex = ledgerTabConfigs.activeIndex;
                    vm.fn_tabChange(vm.activeIndex, true);
                }

                if((from.name == 'activitytabs' || from.name == 'ledgerItems') && vm.$route.query && vm.$route.query.statementType){
                    vm.openPeriodDialog = true;
                }

                let self = vm;
                setTimeout(function(){
                    if(self.$route.query && self.$route.query.showMenu && from.name == 'newLedger'){
                        // self.fn_getBottomMenu(1);
                        self.fn_openDialogOptions();
                        self.activeIndex = 1;
                    }
                },200);

            })
        },
        beforeRouteUpdate(to, from, next){
            // alert('up');
            console.log(from);
            next(true)
            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }  

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('connected');
            next(true);
        },
        // beforeRouteLeave (to, from, next) {
        //     var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //     if(dialogData && dialogData.length){
        //         dialogjs.fn_checkAndCloseOpenDialogs(this);
        //         next(false);
        //     }
        //     else if(this.isGoBack && (this.$refs['ref_dialogTransactionOptions'].dialog_transaction_options || this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation)){
        //         this.$refs['ref_dialogTransactionOptions'].dialog_transaction_options = false;
        //         this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = false;
        //         next(false);
        //     }
        //     else{
        //         if(!this.isGoBack && !localStorage.getItem("transaction_items_state")){
        //             localStorage.setItem("scroll_top", 1);
        //         }
        //         else{
        //             localStorage.removeItem("scroll_top");
        //         }
        //         next(true);
        //     }
        // }
    }

</script>

<style>
#printable-area{
  display: none;
}

@media print{
  #non-printable-area{display:none}
  #printable-area{display: block;}
  /* @page {
    margin: 0.5cm 0.5cm 0.5cm 0.5cm;
  } */
}
</style>