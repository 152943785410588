<template>
  <v-dialog v-model="dialog_business" persistent width="300">
      <v-card flat class="grey-back">
        <v-card-title class="white-back pl-4 pr-4 pt-3 pb-3">Select Business</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0" v-if="businessData">
          <v-list two-line dense class="pa-0">
            
            <template v-if="businessData.MyBusiness && businessData.MyBusiness.length">
              <v-subheader>My Business</v-subheader>
              <v-divider></v-divider>
              <v-list-item v-for="(item, index) in businessData.MyBusiness" :key="'mybusiness'+index" @click="fn_selectBusiness(item, 'self')">
                  <v-list-item-content>
                      <v-list-item-title>{{item.BusinessName}}</v-list-item-title>
                      <v-list-item-subtitle>{{item.BusinessProfileNumber}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                      <v-icon color="primary">{{selectedItem && item.BusinessID == selectedItem.BusinessID ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon>
                  </v-list-item-icon>
              </v-list-item>  
            </template>

            <template>
              <v-subheader v-if="businessData.MyBusiness && businessData.MyBusiness.length">Client Business</v-subheader>
              <v-divider></v-divider>
              <template v-for="(item, index) in businessData.ClientBusinesses">
                <v-list-item @click="fn_selectBusiness(item, 'client')" :key="'clientbusiness'+index">
                    <v-list-item-content>
                        <v-list-item-title>{{item.BusinessName}}</v-list-item-title>
                        <v-list-item-subtitle>{{item.BusinessProfileNumber}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-icon color="primary">{{selectedItem && item.BusinessID == selectedItem.BusinessID ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-divider v-if="businessData.ClientBusinesses.length-1 != index" :key="item.BusinessID"></v-divider>
              </template>
              
              <!-- <v-divider></v-divider>
              <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title>Client Business 2</v-list-item-title>
                      <v-list-item-subtitle>+91-9898989898</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                      <v-icon color="primary">done</v-icon>
                  </v-list-item-icon>
              </v-list-item>   -->
            </template>
            
      
                
          </v-list>
        </v-card-text>
        <!-- <v-card-actions>
          <v-btn color="primary" block @click="fn_loginToBusiness" :disabled="!selectedItem">Login <v-icon right>login</v-icon></v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
</template>

<script>
  export default{
    // props:['businessData', 'createConnection'],
    props:['businessData', 'getBusinessUserToken', 'createConnection'],
    data(){
      return{
        dialog_business:false,
        selectedItem:null
      }
    },
    methods:{
      fn_selectBusiness: function(business, type){
        //   storing main business id
        this.$root.mainBusinessID = business.BusinessID;
        this.selectedItem = {};
        this.selectedItem.type = type;
        this.selectedItem.BusinessID = business.BusinessID;
        this.selectedItem.Status = business.Status;
        this.selectedItem.UserID = business.UserID;
        this.selectedItem.IsTemporary = this.businessData.IsTemporary;
        this.selectedItem.TempBusinessID = this.businessData.TempBusinessID;
        this.closeDialogWeb();
        this.getBusinessUserToken(this.selectedItem, this.selectedItem.UserID);
      },
    //   fn_loginToBusiness: function(){
    //     this.closeDialogWeb();
    //     this.getBusinessUserToken(this.selectedItem, this.selectedItem.UserID);
    //   },
      openDialogWeb: function () {
        this.dialog_business = true;
      },
      closeDialogWeb: function () {
        this.dialog_business = false;
      },
    }
  }
</script>