var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"content-class":"half-screen-dialog","no-click-animation":"","scrollable":"","persistent":"","transition":"slide-y-transition","max-width":_vm.$root.fn_getActionPaneWidth()},model:{value:(_vm.dialog_form),callback:function ($$v) {_vm.dialog_form=$$v},expression:"dialog_form"}},[_c('v-card',{attrs:{"no-border-radius":"","grey-back":""}},[_c('v-toolbar',{attrs:{"height":56,"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-toolbar-title',[_vm._v("Set Balance")])],1),_c('v-divider',{staticClass:"noprint"}),_c('div',{staticClass:"data-container grey-back"},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',[_c('v-layout',{attrs:{"row":"","wrap":"","ma-0":"","white-back":"","pt-4":"","pb-4":""}},[_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":"Ledger","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"id":"party_dropdown","items":_vm.$parent.accountLedgers,"label":"Ledger","item-value":"itemId","item-text":"itemName","return-object":"","append":"","required":"","error-messages":errors},on:{"input":_vm.$parent.fn_updateAccountLedger},model:{value:(_vm.$parent.Form.selectedLedger),callback:function ($$v) {_vm.$set(_vm.$parent.Form, "selectedLedger", $$v)},expression:"$parent.Form.selectedLedger"}},[_c('v-list-item',{staticClass:"primary--text",attrs:{"slot":"prepend-item"},on:{"click":_vm.fn_new},slot:"prepend-item"},[_c('v-list-item-title',[_vm._v("Create New")])],1)],1)]}}])})],1),_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","form-item":""},on:{"click":_vm.$parent.fn_openDatePicker}},[_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":"","value":_vm.$parent.fn_getTransactionDate(),"append-icon":"arrow_drop_down","label":"date","required":"","error-messages":errors}})]}}])})],1),_c('v-subheader',{attrs:{"full-width":"","subheader-dense":"","font12":""}},[_vm._v("Balance Type")]),(_vm.$parent.Form.ledgerData)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","form-item":""}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":""},model:{value:(_vm.$parent.Form.balanceType),callback:function ($$v) {_vm.$set(_vm.$parent.Form, "balanceType", $$v)},expression:"$parent.Form.balanceType"}},[_c('v-radio',{attrs:{"label":_vm.$root.fn_payOrReceive(1, _vm.$parent.Form.ledgerData.groupTypeId),"value":1}}),_c('v-radio',{attrs:{"label":_vm.$root.fn_payOrReceive(0, _vm.$parent.Form.ledgerData.groupTypeId),"value":0}})],1)],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":"date","rules":"required|decimal2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","prefix":_vm.$parent.Form.balanceType == 1 ? '(+)' : '(-)',"label":"New Balance","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.$parent.Form.amount),callback:function ($$v) {_vm.$set(_vm.$parent.Form, "amount", _vm._n($$v))},expression:"$parent.Form.amount"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","form-item":""}},[_c('span',{staticClass:"color-light-grey"},[_vm._v("Balance As On: ")]),_vm._v("₹ "+_vm._s(_vm.$root.numberToLocale(_vm.$parent.Form.balanceAsOn))+" ")]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","form-item":"","mt-3":"","pb-3":""}},[_c('span',{staticClass:"color-light-grey"},[_vm._v("Difference: ")]),_vm._v("₹ "+_vm._s(_vm.$root.numberToLocale(_vm.$parent.difference))+" "+_vm._s(_vm.$parent.difference >= 0 ? '(Positive)' : '(Negative)')+" ")])],1),_c('v-layout',{attrs:{"row":"","wrap":"","white-back":"","ma-0":"","mt-2":""}},[_c('v-flex',{attrs:{"sm12":"","md12":"","xs12":"","pa-4":""}},[_c('ValidationProvider',{attrs:{"name":"Notes","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"2","label":"Notes","auto-grow":"","counter":255,"error-messages":errors},model:{value:(_vm.$parent.Form.transactionData.notes),callback:function ($$v) {_vm.$set(_vm.$parent.Form.transactionData, "notes", $$v)},expression:"$parent.Form.transactionData.notes"}})]}}])})],1)],1)],1)],1)],1),_c('v-card-actions',{attrs:{"white-back":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","ma-0":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","height48":""}},[_c('v-btn',{key:_vm.$parent.saveBtnKey,attrs:{"no-border-radius":"","color":"primary","full-height":"","nomargin":"","block":"","no-round-borders":"","noshadow":"","disabled":_vm.$parent.disableOnSave},on:{"~click":function($event){return _vm.fn_validateForm.apply(null, arguments)}}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }