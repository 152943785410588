<template>
    <div>
        <v-card flat>
            <v-card-text full-height nopadding>

                <v-subheader>Filter By
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeFilterNav"><v-icon>close</v-icon></v-btn>
                </v-subheader>

                <v-divider></v-divider>

                <v-list nopadding>
                    <v-list-item v-for="(item, index) in filterItems" :key="index" @click="setFilter(item.filterItemId)">

                        <v-list-item-content>
                          <v-list-item-title>{{item.name}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon v-if="item.filterItemId == selectedFilterItemId">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default{
        props:['title', 'filterItems', 'setFilter', 'selectedFilterItemId', 'closeFilterNav'],
        data(){
            return{
                dialog_filter_items:false,
            }
        }
    }
</script>