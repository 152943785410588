<template>
    <div grey-back full-height class="print_field">

        <!-- fn_draftAction is in draft mixin -->
        <!-- <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_draftAction" :save="fn_checkValidations" :hideKeepDraft="true"></dialog-discard> -->
        <!-- <dialog-quill ref="ref_dialogQuill" :saveContent="fn_saveContent"></dialog-quill> -->
        <dialog-signature ref="ref_dialogSignature" :embedSignature="fn_embedSignature"></dialog-signature>
        <dialog-bank-details ref="ref_dialogBankDetails"></dialog-bank-details>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <v-bottom-sheet v-model="previewSheet">
            <v-card>
                <v-card-title primary-title>
                  Content Preview
                  <v-spacer></v-spacer>
                  <v-icon v-ripple @click="previewSheet=false">close</v-icon>
                </v-card-title>
                <v-card-text class="content-preview">
                    <div v-html="previewContent"></div>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>

    </div>
</template>

<script>
// const menuItem = require('../../config.js').configs.menuItem;
// const dialogjs =  require('../masters/forms/dialog').dialogjs;

// import dialogDiscard from '../dialogs/dialog_discard'
// import dialogQuill from './dialogs/dialog_quill'
import dialogSignature from './dialogs/dialog_signature'
import dialogBankDetails from './dialogs/dialog_bank_details'

import dialogForm from './dialogs/dialog_form'

    export default{
        data(){
            return{
                Form:{
                    "fieldId":null,
                    "name":null,
                    "fieldType":null,
                    "isRequired":null,
                    "additionalData":{"content":null},
                    "isSystem":0,
                    "groupTypeId":102,
                    "parentId":null,
                    "isParent":false
                },
                loading:false,
                backButtonPress: false,
                dialogStorageId:null,
                discardChanges:false,
                bottomSheet:false,
                disableName:false,
                content:'',
                previewSheet:false,
                FormCopy:null,
                previewContent:null,
                saveBtnKey:1,
                defaultContent:null
            }
        },
        components:{
            // 'dialog-discard': dialogDiscard,
            // 'dialog-quill': dialogQuill,
            'dialog-signature': dialogSignature,
            'dialog-bank-details': dialogBankDetails,
            'dialog-form':dialogForm
        },
        methods:{

            fn_resetFieldContent: function(){
				this.$refs['ref_dialogForm'].content = this.defaultContent ? this.defaultContent : this.Form.additionalData.defaultContent;
				// this.$refs['ref_dialogQuill'].content = this.content;
				this.fn_preview();
			},

            // fn_openDialogDiscard: function(){
            //  this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard');
            // },

            fn_closeDialogForm: function(){
                this.fn_goBack();
            },

            // fn_openDialogQuill: function(){
            //     this.$refs['ref_dialogQuill'].content = this.content;
            //     this.fn_openDialogWeb('web', 'Discard', 'ref_dialogQuill');
            // },

            fn_openDialogSignature: function(){
                // this.fn_openDialogWeb('web', 'Signature', 'ref_dialogSignature');
                this.$refs['ref_dialogSignature'].openDialogWeb();
            },

            fn_openDialogBankDetails: function(){
                // this.fn_openDialogWeb('web', 'BankDetails', 'ref_dialogBankDetails');
                this.$refs['ref_dialogBankDetails'].openDialogWeb();
            },

            // fn_saveContent: function(content){
            //     this.content = content;
            //     this.fn_preview();
            //     this.fn_closeDialog();
            //     if(this.$route.query && this.$route.query.dialog){
            //         this.fn_checkValidations();
            //     }
            // },

            fn_preview: function(){
                // let printContent = fromEditor ? content : this.content;
                // let printContent = this.content;
                let printContent = this.$refs['ref_dialogForm']?.content;
                if(this.$root.CurrentBusiness){
                    this.previewContent =  printContent.split('{{BusinessName}}').join(this.$root.CurrentBusiness.BusinessName ? this.$root.CurrentBusiness.BusinessName : '');
                    this.previewContent =  this.previewContent.split('{{BusinessAddress}}').join(this.$root.CurrentBusiness.Address ? this.$root.CurrentBusiness.Address : '');
                    this.previewContent =  this.previewContent.split('{{BusinessState}}').join(this.$root.CurrentBusiness.State ? this.$root.CurrentBusiness.State : '');
                    this.previewContent =  this.previewContent.split('{{GSTIN}}').join(this.$root.CurrentBusiness.GSTNumber ? this.$root.CurrentBusiness.GSTNumber : '');
                }
                // fromEditor ? this.previewSheet = true : '';
            },

            fn_draftAction: function(){
                this.fn_closeDialog()
                this.discardChanges = true;
                this.fn_goBack();
            },

            // fn_openDialogWeb: function (platform, name, ref) {

            //     let scroll_y = window.scrollY;
            //     this.fn_fixBackgroundScroll();

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.data = null;
            //     dialogObj.platform = platform;

            //     dialogObj.scrollPosition = {};
            //     dialogObj.scrollPosition.x = 0;
            //     dialogObj.scrollPosition.y = scroll_y;

            //     dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //     let self = this;

            //     this.$refs[ref].openDialogWeb();
            // },

            // fn_closeDialog: function(type){
            //     dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_embedSignature: function(dataUrl){
                // this.$refs['ref_dialogQuill'].fn_embedSign(dataUrl);
                // this.fn_closeDialog();

                // this.fn_closeDialog();
                this.$refs['ref_dialogSignature'].closeDialogWeb();

                // this.content = "<p>For {{BusinessName}}</p><p><br></p><p><img src="+dataUrl+"></p><p><br></p><p><br></p><p>_____________________________</p><p>Authorised Signatory</p>";
                this.$refs['ref_dialogForm'].content = "<p><strong>For {{BusinessName}}</strong></p><p><br></p><p><img src="+dataUrl+"></p><p><br></p><p><br></p><p>_____________________________</p><p>Authorised Signatory</p>";

                // this.$refs['ref_dialogForm'].content = this.content;

                if(this.$route.query && this.$route.query.signature){
                    this.fn_checkValidations();
                }
            },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.save, menuItem.reset, menuItem.help]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            fn_checkValidations: function(){
                // var self = this;
                // this.$validator.validateAll().then((result) => {
                //     if (result) {
                //         // if(!this.content){
                //         //  this.showToast("Add Content");
                //         //  return;
                //         // }
                //         this.fn_post();
                //     }
                //     else{
                //         this.$vuetify.goTo('.error--text', this.scrollOptions);
                //     }
                //     self.loading = false;
                // });
                this.fn_post();
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('Field', dataObj, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          let status = JSON.parse(response);
                          if(status) {
                            self.fn_cleanUp();
                          }
                          else {
                            self.showToast("Already exists with this name");
                          }
                        },
                        onError: function(error){
                            self.saveBtnKey++;
                            console.log(error);
                        }
                    }
                );
            },

            fn_get: function(itemId){
                var self = this;
                this.loading = true;
                this.getData('Field', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.Form = JSON.parse(response);
                            self.Form.additionalData = JSON.parse(self.Form.additionalData);
                            let dcontent = null;
                            if(self.Form.additionalData){
                                dcontent = self.Form.additionalData.defaultContent ? self.Form.additionalData.defaultContent : null;
                            }
                            if(self.Form.additionalData && self.Form.additionalData.content){
                                // self.content = self.Form.additionalData.content ;
                                // self.$refs['ref_dialogForm'].content = self.content;
                                self.$refs['ref_dialogForm'].content = self.Form.additionalData.content;

                                // if(!self.Form.additionalData.defaultContent){
								// 	self.defaultContent = self.Form.additionalData.content;
								// }
                            }
                            else{
                                self.Form.additionalData = {};  
                                self.Form.additionalData.content = '';
                                if(dcontent){
									self.Form.additionalData.defaultContent = dcontent;
								}
                                // if(!self.Form.additionalData || (self.Form.additionalData && !self.Form.additionalData.defaultContent)){
								// 	self.Form.additionalData = {};	
								// 	self.Form.additionalData.content = '';
								// }
                            }

                            // saving default content to reset print field
							if(self.Form.additionalData.defaultContent){
								self.defaultContent = self.Form.additionalData.defaultContent;
							}
							else{
								self.defaultContent = self.Form.additionalData.content;
							}

                            self.loading = false;
                            // self.content = self.Form.additionalData && self.Form.additionalData.content ?  self.Form.additionalData.content : '';
                            self.FormCopy = JSON.parse(JSON.stringify(self.Form));
                            self.fn_preview();

                            self.$route.query.dialog ? self.fn_openDialogQuill() : '';
                            
                            // self.$route.query.signature ? self.fn_openDialogSignature() : '';
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                        }
                    }
                );
            },

            fn_cleanUp: function () {
                // if(this.$route.query.ls){
                //  let data = {};
                //  data.type = 'printTemplate';
                //  data.id = this.Form.printTemplateId;
                //  data.name = this.Form.name;
                //  localStorage.setItem('newItem', JSON.stringify(data));
                //  localStorage.setItem('closeDialog', 1);
                // }

                this.discardChanges = true;
                this.$router.go(-1);
            },

            fn_post: function(){
                // !this.Form.fieldId ? this.Form.fieldId = this.guid() : '';
                if(!this.Form.fieldId){
                    this.Form.fieldId = this.guid();
                    this.Form.createdDate = new Date();
                }

                this.Form.modifiedDate = new Date();
                
                this.Form.name = this.Form.name.trim();
                this.Form.fieldType = 'print';
                this.Form.additionalData = {};
                this.Form.additionalData.content = null;
                // this.Form.additionalData.content = this.content;
                this.Form.additionalData.content = this.$refs['ref_dialogForm'].content;
                this.Form.additionalData.defaultContent = this.defaultContent ? this.defaultContent : null;
                this.Form.additionalData = JSON.stringify(this.Form.additionalData);
                // this.Form.parentId = this.$route.query && this.$route.query.parentId ? this.$route.query.parentId : null;
                console.log(this.Form);
                this.fn_saveLocal(this.Form);
            },

            fn_clearForm: function(){
                this.Form = {   
                                "fieldId":null, 
                                "name":null,
                                "fieldType":null,
                                "isRequired":null,
                                "additionalData":{"content":''},
                                "isSystem":0,
                                "groupTypeId":102,
                                "parentId":null,
                                "isParent":false,
                                "content":''
                            };
                // this.content = '';
                this.$validator.reset();
            },

            fn_menuItemClick: function(tagId, menuId, menuAction){
                // tagId id form future use
                // menuId id form future use

                this.backButtonPress = false;
                
                if(menuAction=='save'){
                    this.fn_checkValidations();
                }
                else if(menuAction=='reset'){
                    this.fn_resetForm();
                }
            },

            fn_resetForm: function () {
                this.fn_clearForm();
            },

            fn_goBack: function(){
                this.backButtonPress = true;
                this.$router.go(-1);
            }
        },
        mounted: function(){

            if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                // this.dialogStorageId = 'dialog_'+this.formId;
                this.disableName = this.$route.query.disableName ? JSON.parse(this.$route.query.disableName) : '';
            }

            if(this.$route.query && this.$route.query.parentId){
                this.Form.parentId = this.$route.query.parentId;
            }

            this.$refs['ref_dialogForm'].openDialogWeb();
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {

                let itemId = null;

                if(to.params && to.params.tagId){
                    itemId = to.params.tagId;   
                }

                if(itemId){
                    vm.fn_get(itemId);
                }
                else{
                    vm.FormCopy = JSON.parse(JSON.stringify(vm.Form));
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.saveBtnKey++;
                            itemId ? vm.fn_get(itemId) : ''; 
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });

            })
        },
        beforeRouteLeave (to, from, next) {
            this.$eventHub.$off('connected');
            next();
        }
    }

</script>

<style>
.content-preview p{
    margin:0;
}
.print-field-content img{
	width: 100%;
	max-width: 120px !important;
}
</style>