<template>
    <div>
        <v-dialog v-model="dialog_import_item_form" v-if="dialog_import_item_form" content-class="half-screen-dialog without-left-nav" no-click-animation scrollable persistent transition="slide-y-transition">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="dialog_import_item_form = false"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>Import Item</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back" v-if="itemModel">

                    <ValidationObserver ref="observer">

                        <v-form @submit.prevent="submit">
                            <v-layout ma-0 wrap pt-4 white-back class="section-wrapper">

                                <!-- <v-flex xs12 sm12 md12 form-item pb-3>
                                    <v-radio-group v-model="groupTypeId" row>
                                    <v-radio label="Goods" :value="101"></v-radio>
                                    <v-radio label="Service" :value="102"></v-radio>
                                    </v-radio-group>
                                </v-flex> -->

                                <v-flex form-item xs8 sm8 md8 mb-2>
                                    <ValidationProvider name="Item Name" rules="required|max250" v-slot="{ errors }">
                                        <v-text-field 
                                            :key="validationCheckKey"
                                            :error="itemModel.nameHasError"
                                            @input="itemModel.nameHasError = errors.length ? true : false"
                                            v-model="itemModel.Name" 
                                            label="Item Name"
                                            required
                                            autocomplete="null"
                                            :hide-details="!errors.length"
                                            :error-messages="errors">
                                        </v-text-field>
                                        <div font12 class="pt-1 error--text" v-if="itemModel.nameHasError && !errors.length">This field must not be duplicate or blank</div>
                                    </ValidationProvider>
                                </v-flex>

                                <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-text-field  v-model="itemModel.ItemCode" label="Item Code" placeholder=" "></v-text-field>
                                </v-flex>

                                <!-- <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-select
                                        :items="['Yes', 'No']"
                                        label="Service Item"
                                        placeholder=" "
                                        @change="fn_changeServiceItem"
                                        :value="itemModel.ServiceItem"
                                    ></v-select>

                                </v-flex> -->

                                <!-- <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-select
                                        :items="['Yes', 'No']"
                                        label="Expense Item"
                                        placeholder=" "
                                        @change="fn_changeExpenseItem"
                                        :value="itemModel.ExpenseItem"
                                    ></v-select>

                                </v-flex> -->

                                <!-- <v-flex form-item xs4 sm4 md4 v-if="itemModel.ServiceItem=='Yes'">
                                    <ValidationProvider name="Charges" rules="min_value:0|decimal2" v-slot="{ errors }">
                                        <v-text-field 
                                            :error="itemModel.chargesHasError"
                                            @input="itemModel.chargesHasError = errors.length && itemModel.Charges ? true : false"
                                            v-model="itemModel.Charges" 
                                            prefix="₹"
                                            label="Charges"
                                            autocomplete="null"
                                            :hide-details="!errors.length"
                                            :error-messages="errors">
                                        </v-text-field>
                                        <div font12 class="pt-1 error--text" v-if="itemModel.chargesHasError && !errors.length">This field must be numerical</div>
                                    </ValidationProvider>
                                </v-flex> -->

                                </v-layout>

                                <v-layout ma-0 wrap pt-2 pb-3 white-back class="section-wrapper" v-if="$root.CurrentBusiness && $root.CurrentBusiness.GSTRegistrationStatus==1">

                                    <v-flex form-item xs4 sm4 md4 pr-4>

                                        <ValidationProvider name="GST Percentage" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                :error="itemModel.gstHasError"
                                                @input="itemModel.gstHasError = errors.length && itemModel.GSTPercentage ? true : false"
                                                v-model="itemModel.GSTPercentage"  
                                                label="GST Percentage"
                                                autocomplete="null"
                                                :hide-details="!errors.length"
                                                :error-messages="errors">
                                            </v-text-field>
                                            <div font12 class="pt-1 error--text" v-if="itemModel.gstHasError && !errors.length">This field must be numerical</div>
                                        </ValidationProvider>

                                    </v-flex>

                                    <v-flex form-item xs4 sm4 md4 pr-4>

                                        <ValidationProvider name="Cess Percentage" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                :error="itemModel.cessHasError"
                                                @input="itemModel.cessHasError = errors.length ? true : false"
                                                v-model="itemModel.CessPercentage"  
                                                label="Cess Percentage"
                                                autocomplete="null"
                                                :hide-details="!errors.length"
                                                :error-messages="errors">
                                            </v-text-field>
                                            <div font12 class="pt-1 error--text" v-if="itemModel.cessHasError && !errors.length">This field must be numerical</div>
                                        </ValidationProvider>

                                    </v-flex>

                                    <v-flex form-item xs4 sm4 md4 pl-4 v-if="$root.CurrentBusiness.HSNApplicable">

                                        <ValidationProvider name="HSN Code" rules="verify_hsn" v-slot="{ errors }">
                                        <v-text-field 
                                                id="hsnCode"  
                                                :error="itemModel.hsnCodeHasError"
                                                @input="itemModel.hsnCodeHasError = !errors.length && itemModel.HSNCode ? true : false"
                                                v-model="itemModel.HSNCode" 
                                                label="HSN Code"
                                                autocomplete="null"
                                                :hide-details="!errors.length"
                                                :error-messages="errors">
                                            </v-text-field>
                                            <!-- {{ errors.length && itemModel.HSNCode }} -->
                                            <!-- {{ errors.length }}
                                            {{ itemModel.hsnCodeHasError }} -->
                                            <!-- <div font12 class="pt-1 error--text" v-if="itemModel.hsnCodeHasError && !errors.length">HSN should be either 4, 6 or 8 digits.</div> -->
                                        </ValidationProvider>
                                        
                                    </v-flex>
                                </v-layout>

                                <!-- <v-layout ma-0 wrap pt-4 pb-3 white-back v-if="itemModel.ServiceItem=='No'" class="section-wrapper"> -->
                                <v-layout ma-0 wrap pt-4 pb-3 white-back class="section-wrapper">

                                    <v-flex form-item xs4 sm4 md4>

                                        <v-text-field 
                                            id="unit"  
                                            v-model="itemModel.Unit" 
                                            label="Unit"
                                            autocomplete="null">
                                        </v-text-field>

                                    </v-flex>

                                    <v-flex form-item xs4 sm4 md4 v-if="$root.TransactionSettings.mrpWiseStock">
                                        <ValidationProvider name="MRP" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                prefix="₹"
                                                :error="itemModel.mrpHasError"
                                                @input="itemModel.mrpHasError = errors.length && itemModel.MRP ? true : false"
                                                v-model="itemModel.MRP"  
                                                autocomplete="null"
                                                label="MRP"
                                                :hide-details="!errors.length"
                                                :error-messages="errors">
                                            </v-text-field>
                                            <div font12 class="pt-1 error--text" v-if="itemModel.mrpHasError && !errors.length">This field must be numerical</div>
                                        </ValidationProvider>
                                    </v-flex>

                                    <!-- <v-flex form-item xs4 sm4 md4 v-if="itemModel.ServiceItem == 'No'"> -->
                                    
                                    <v-flex form-item xs4 sm4 md4>
                                        <ValidationProvider name="Selling Price" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                prefix="₹"
                                                :error="itemModel.sellingPriceHasError"
                                                @input="itemModel.sellingPriceHasError = errors.length && itemModel.SellingPrice ? true : false"
                                                v-model="itemModel.SellingPrice"  
                                                autocomplete="null"
                                                label="Sale Price"
                                                :hide-details="!errors.length"
                                                :error-messages="errors">
                                            </v-text-field>
                                            <div font12 class="pt-1 error--text" v-if="itemModel.sellingPriceHasError && !errors.length">This field must be numerical</div>
                                        </ValidationProvider>
                                    </v-flex>

                                    <!-- <v-flex form-item xs4 sm4 md4 v-if="itemModel.ServiceItem == 'No' && itemModel.ExpenseItem == 'No'"> -->
                                    <v-flex form-item xs4 sm4 md4>

                                        <ValidationProvider name="Wholesale Price" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                prefix="₹"
                                                :error="itemModel.wholePriceHasError"
                                                @input="itemModel.wholePriceHasError = errors.length && itemModel.WholesalePrice ? true : false"
                                                v-model="itemModel.WholesalePrice" 
                                                label="Wholesale Price"
                                                autocomplete="null"
                                                :hide-details="!errors.length"
                                                :error-messages="errors">
                                            </v-text-field>
                                            <div font12 class="pt-1 error--text" v-if="itemModel.wholePriceHasError && !errors.length">This field must be numerical</div>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex form-item xs4 sm4 md4>

                                        <ValidationProvider name="Purchase Rate" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                prefix="₹"
                                                :error="itemModel.purchaseRateHasError"
                                                @input="itemModel.purchaseRateHasError = errors.length && itemModel.PurchaseRate ? true : false"
                                                v-model="itemModel.PurchaseRate" 
                                                label="Purchase Rate"
                                                autocomplete="null"
                                                :hide-details="!errors.length"
                                                :error-messages="errors">
                                            </v-text-field>
                                            <div font12 class="pt-1 error--text" v-if="itemModel.purchaseRateHasError && !errors.length">This field must be numerical</div>
                                        </ValidationProvider>

                                    </v-flex>

                                    <v-flex form-item xs4 sm4 md4>

                                        <ValidationProvider name="Opening" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                prefix="₹"
                                                :error="itemModel.openingHasError"
                                                @input="itemModel.openingHasError = errors.length && itemModel.Opening ? true : false"
                                                v-model="itemModel.Opening" 
                                                label="Opening"
                                                autocomplete="null"
                                                :hide-details="!errors.length"
                                                :error-messages="errors">
                                            </v-text-field>
                                            <div font12 class="pt-1 error--text" v-if="itemModel.openingHasError && !errors.length">This field must be numerical</div>
                                        </ValidationProvider>

                                    </v-flex>

                                    <v-flex form-item xs4 sm4 md4>

                                        <ValidationProvider name="Opening Rate" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                prefix="₹"
                                                :error="itemModel.openingRateHasError"
                                                @input="itemModel.openingRateHasError = errors.length && itemModel.OpeningRate ? true : false"
                                                v-model="itemModel.OpeningRate" 
                                                label="Opening Rate"
                                                autocomplete="null"
                                                :hide-details="!errors.length"
                                                :error-messages="errors">
                                            </v-text-field>
                                            <div font12 class="pt-1 error--text" v-if="itemModel.openingRateHasError && !errors.length">This field must be numerical</div>
                                        </ValidationProvider>

                                    </v-flex>

                                    <!-- <v-flex form-item xs6 sm6 md6 sibling-form-field v-if="$root.TransactionSettings.mrpWiseStock">
                                        <ValidationProvider name="MRP" rules="min_value:0|decimal2" v-slot="{ errors }">
                                        <v-text-field 
                                                v-model.number="$parent.Form.mrp" 
                                                type="number" 
                                                label="MRP"
                                                autocomplete="null"
                                                :error-messages="errors">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex> -->


                                </v-layout>

                                <v-layout white-back ma-0 row wrap pt-3>
                                            
                                    <v-flex form-item xs12 sm4 md4 pr-4>

                                        <v-text-field 
                                            id="category"  
                                            v-model="itemModel.Category" 
                                            label="Category"
                                            autocomplete="null">
                                        </v-text-field>

                                    </v-flex>

                                    <!-- <template v-if="itemModel.ServiceItem == 'No' && itemModel.ExpenseItem == 'No'"> -->
                                    <template>
                                        <v-flex form-item xs12 sm4 md4 pr-4 pl-4>
                                        
                                            <v-text-field 
                                                id="brand"  
                                                v-model="itemModel.Brand" 
                                                label="Brand"
                                                autocomplete="null">
                                            </v-text-field>

                                        </v-flex>

                                        <v-flex form-item xs12 sm4 md4 pl-4>

                                            <v-text-field 
                                                id="company"  
                                                v-model="itemModel.Company" 
                                                label="Company"
                                                autocomplete="null">
                                            </v-text-field>

                                        </v-flex>
                                    </template>

                                    <v-flex form-item xs12 sm12 md12>
                                        <!-- <v-text-field  v-model="itemModel.Description" label="Description" placeholder=" " autocomplete="null"></v-text-field> -->
                                        <ValidationProvider name="Description" rules="max:255" v-slot="{ errors }">
                                            <v-textarea 
                                                autocomplete="null"
                                                placeholder=" "
                                                v-model="itemModel.Description" 
                                                label="Description"
                                                rows="2"
                                                :counter="255"
                                                :error-messages="errors">
                                            </v-textarea>
                                        </ValidationProvider>
                                    </v-flex>

                                </v-layout>
                        </v-form>

                    </ValidationObserver>
                </div>


                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex xs12 sm12 md12 height48>
                            <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow @click="fn_checkValidations">Save</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>
                  

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { min_value } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// extend('min_value:0|decimal2', value=>{
//     if(value>=0){
//         return true;
//     }

//     return 'This field must be 0 or more.'
// });

extend('min_value', {
    ...min_value,
    message: 'This field must be 0 or more.'
})

extend('decimal2', value =>     {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

})

extend('verify_hsn', value => {
    if(value.length == 4 || value.length == 6 || value.length == 8){
        return true;
    }

    return 'HSN should be either 4, 6 or 8 digits.';
});

// extend('max', max);

extend('max250', value => {
    if(value.length <= 250){
        return true;
    }
    
    return 'This field can not be greater than 250 characters.'
})

export default{
    data(){
        return{
            dialog_import_item_form:false,
            groupTypeId:null,
            itemModel:null,
            validationCheckKey:this.guidWeb()
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        openDialogWeb: function(){
            this.dialog_import_item_form = true;
            this.itemModel = {};
            this.itemModel.Name = this.$parent.editItemField["Name"] ? this.$parent.editItemField["Name"] : null;
            this.itemModel.ItemCode = this.$parent.editItemField["Item Code"] ? this.$parent.editItemField["Item Code"] : null;
            // this.itemModel.Charges = this.$parent.editItemField["Charges"] ? this.$parent.editItemField["Charges"] : null;
            this.itemModel.GSTPercentage = this.$parent.editItemField["GST Percentage"] ? this.$parent.editItemField["GST Percentage"] : null;
            this.itemModel.CessPercentage = this.$parent.editItemField["Cess Percentage"] ? this.$parent.editItemField["Cess Percentage"] : null;
            this.itemModel.HSNCode = this.$parent.editItemField["HSN Code"] ? this.$parent.editItemField["HSN Code"] : null;
            this.itemModel.Unit = this.$parent.editItemField["Unit"] ? this.$parent.editItemField["Unit"] : null;
            this.itemModel.Description = this.$parent.editItemField["Description"] ? this.$parent.editItemField["Description"] : null;
            this.itemModel.MRP = this.$parent.editItemField["MRP"] ?  this.$parent.editItemField["MRP"] :null;
            this.itemModel.SellingPrice = this.$parent.editItemField["Selling Price"] ?  this.$parent.editItemField["Selling Price"] :null;
            this.itemModel.WholesalePrice = this.$parent.editItemField["Wholesale Price"] ? this.$parent.editItemField["Wholesale Price"] : null;

            this.itemModel.PurchaseRate = this.$parent.editItemField["Purchase Rate"] ? this.$parent.editItemField["Purchase Rate"] : null;
            this.itemModel.Opening = this.$parent.editItemField["Opening"] ? this.$parent.editItemField["Opening"] : null;
            this.itemModel.OpeningRate = this.$parent.editItemField["Opening Rate"] ? this.$parent.editItemField["Opening Rate"] : null;

            this.itemModel.hasError = this.$parent.editItemField.hasError;
            this.itemModel.nameHasError = this.$parent.editItemField.nameHasError;
            // this.itemModel.chargesHasError = this.$parent.editItemField.chargesHasError;
            this.itemModel.gstHasError = this.$parent.editItemField.gstHasError;
            this.itemModel.cessHasError = this.$parent.editItemField.cessHasError;
            this.itemModel.hsnCodeHasError = this.$parent.editItemField.hsnCodeHasError;
            this.itemModel.mrpHasError = this.$parent.editItemField.mrpHasError;
            this.itemModel.sellingPriceHasError = this.$parent.editItemField.sellingPriceHasError;
            this.itemModel.wholePriceHasError = this.$parent.editItemField.wholePriceHasError;
            this.itemModel.purchaseRateHasError = this.$parent.editItemField.purchaseRateHasError;
            this.itemModel.openingHasError = this.$parent.editItemField.openingHasError;
            this.itemModel.openingRateHasError = this.$parent.editItemField.openingRateHasError;

            // this.itemModel.ServiceItem = this.$parent.editItemField["Service Item"];
            // this.itemModel.ExpenseItem = this.$parent.editItemField["Expense Item"];
            
            this.itemModel.Category = this.$parent.editItemField["Category"] ? this.$parent.editItemField["Category"] : null;
            this.itemModel.Brand = this.$parent.editItemField["Brand"] ?  this.$parent.editItemField["Brand"] : null;
            this.itemModel.Company = this.$parent.editItemField["Company"] ? this.$parent.editItemField["Company"] : null;
        },

        // fn_changeServiceItem: function(value){
        //     this.itemModel.ServiceItem = value;
        //     this.$forceUpdate();
        // },

        // fn_changeExpenseItem: function(value){
        //     this.itemModel.ExpenseItem = value;
        //     this.$forceUpdate();
        // },

        fn_checkValidations: function(){
            this.$refs.observer.validate().then((result) => {
                if(result) {
                    this.fn_checkItemDuplicate(response=>{
                        if(response == 'invalid'){
                            this.itemModel.nameHasError = true;
                            this.validationCheckKey = this.guidWeb();
                            // this.$set(this.itemModel, 'nameHasError', true);
                            // alert(this.itemModel.nameHasError);
                            this.$eventHub.$emit('snackbarAlert', {msg  :'Item with name "'+ this.itemModel.Name +'" already exist'});
                        }
                        else{
                            this.fn_saveItem();
                        }
                    })
                }
                // else{
                //     alert('invalid');
                // }
            });
        },

        fn_checkItemDuplicate: function(callback){
            if(!this.itemModel.Name){
                this.$eventHub.$emit('snackbarAlert', {msg:"Item name should not be blank"});
                return;
            }

            this.itemModel.Name = this.itemModel.Name.trim();

            // let matchCount = 0;
            for(let i=0; i<this.$parent.importItems.length; i++){
                if(this.$parent.importItems[i].Name && this.itemModel.Name){
                    if(this.$parent.importItems[i].Name.toLowerCase() == this.itemModel.Name.toLowerCase() && this.$parent.editItemField.itemIndex != i && i < this.$parent.editItemField.itemIndex){
                        callback('invalid');
                        return;
                    }
                }
            }

            let filterObj = {};
            filterObj.importItems = [this.itemModel.Name];

            // let self = this;
            this.getFilterData('ValidateImportItems', 'Product', filterObj, null,
                {
                    onSuccess: function(response){
                        console.log(response);
                        let duplicateItems = JSON.parse(response);
                        if(duplicateItems.length){
                            callback('invalid');
                        }
                        else{
                            callback('valid');  
                        }
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_saveItem: function(){
            this.$parent.editItemField["Name"] = this.itemModel.Name;
            this.$parent.editItemField["Item Code"] = this.itemModel.ItemCode;
            // this.$parent.editItemField["Charges"] = this.itemModel.Charges ? Number(this.itemModel.Charges) : null;
            this.$parent.editItemField["GST Percentage"] = this.itemModel.GSTPercentage;
            this.$parent.editItemField["Cess Percentage"] = this.itemModel.CessPercentage;
            this.$parent.editItemField["HSN Code"] = this.itemModel.HSNCode;
            this.$parent.editItemField["Unit"] = this.itemModel.Unit;
            this.$parent.editItemField["Description"] = this.itemModel.Description;
            this.$parent.editItemField["MRP"] = this.itemModel.MRP ? Number(this.itemModel.MRP) : '';
            this.$parent.editItemField["Selling Price"] = this.itemModel.SellingPrice ? Number(this.itemModel.SellingPrice) : '';
            this.$parent.editItemField["Wholesale Price"] = this.itemModel.WholesalePrice ? Number(this.itemModel.WholesalePrice) : '';
            this.$parent.editItemField["Purchase Rate"] = this.itemModel.WholesalePrice ? Number(this.itemModel.PurchaseRate) : '';
            this.$parent.editItemField["Opening"] = this.itemModel.Opening ? Number(this.itemModel.Opening) : '';
            this.$parent.editItemField["Opening Rate"] = this.itemModel.OpeningRate ? Number(this.itemModel.OpeningRate) : '';
            // this.$parent.editItemField["Service Item"] = this.itemModel.ServiceItem;
            // this.$parent.editItemField["Expense Item"] = this.itemModel.ExpenseItem;
            this.$parent.editItemField["Category"] = this.itemModel.Category;
            this.$parent.editItemField["Brand"] = this.itemModel.Brand;
            this.$parent.editItemField["Company"] = this.itemModel.Company;
            
            // if(this.itemModel.ServiceItem == 'Yes'){
            //     this.$parent.editItemField["Unit"] = 'Nos';
            //     this.$parent.editItemField["Selling Price"] = null;
            //     this.$parent.editItemField["Wholesale Price"] = null;
            //     this.$parent.editItemField["Brand"] = null;
            //     this.$parent.editItemField["Company"] = null;
            // }

            // if(this.itemModel.ExpenseItem == 'Yes'){
            //     this.$parent.editItemField["Wholesale Price"] = null;
            //     this.$parent.editItemField["Brand"] = null;
            //     this.$parent.editItemField["Company"] = null;
            // }

            if(this.$parent.editItemField["hasError"]){
                this.$parent.invalidItemsCount--;
            }

            this.$parent.editItemField["hasError"] = false;
            this.$parent.editItemField["nameHasError"] = false;
            // this.$parent.editItemField["chargesHasError"] = false;
            // this.$parent.editItemField["serviceItemHasError"] = false;
            this.$parent.editItemField["gstHasError"] = false;
            this.$parent.editItemField["cessHasError"] = false;
            this.$parent.editItemField["hsnCodeHasError"] = false;
            this.$parent.editItemField["sellingPriceHasError"] = false;
            this.$parent.editItemField["wholePriceHasError"] = false;
            this.$parent.editItemField["puchaseRateHasError"] = false;
            this.$parent.editItemField["descriptionHasError"] = false;
            // this.$parent.editItemField["expenseItemHasError"] = false;

            this.$parent.importItems = JSON.parse(JSON.stringify(this.$parent.importItems));

            this.dialog_import_item_form = false;
        }
    }
}

</script>