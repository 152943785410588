<template>
    <div>
        <v-menu v-model="roundOffDialogMenu" :close-on-content-click="false" :max-width="240">
                          
            <template v-slot:activator="{ on, attrs }">

                <div class="subtitle-text dark-opacity-color" v-bind="attrs" v-on="on" cursor-pointer @click="openRoundOffDialogMenu">
                    <v-text-field autocomplete="null" 
                        id="roundOffValueField"
                        readonly
                        append-icon="arrow_drop_down"
                        class="small-text-box right-align"
                        hide-details
                        :value="roundOffComputed"
                        type="number" 
                        label="" 
                        prefix="₹"
                        outlined
                        dense>
                    </v-text-field>
                </div>

            </template>
            <v-card>
                <v-card-text>
                    <ValidationObserver ref="observer">
                        <v-form @submit.prevent="submit">
                            <v-layout ma-0 row wrap>
                                    <v-flex xs12 sm12 md12 pa-2>
                                        <v-radio-group class="ma-0" hide-details v-model="roundOffNature" row>
                                            <v-radio label="Add" :value="1"></v-radio>
                                            <v-radio label="Subtract" :value="0"></v-radio> 
                                        </v-radio-group>
                                    </v-flex>

                                    <v-flex xs12 sm12 md12 pa-2>
                                        <div font11 light-opacity-color>Round Off Value</div>
                                        <ValidationProvider name="value" rules="required|decimal2" v-slot="{ errors }">
                                            <v-text-field autocomplete="null" 
                                                id="roundOffValueField"
                                                class="small-text-box right-align"
                                                hide-details
                                                v-model.number="roundOffValueManual"
                                                type="number" 
                                                label="" 
                                                prefix="₹"
                                                outlined
                                                dense
                                                required
                                                :error-messages="errors">
                                            </v-text-field>
                                            <div style="font-size:12px;padding:2px;color:#ff5252">{{errors[0]}}</div>
                                        </ValidationProvider>
                                    </v-flex>
                            </v-layout>
                        </v-form>
                    </ValidationObserver>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text small color="primary" @click="roundOffDialogMenu=false">cancel</v-btn>
                    <v-btn text small color="primary" @click="fn_checkValidations">done</v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('required', required);

extend('decimal2', value => {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

});

export default{
    props:['roundOffComputed', 'openRoundOffDialogMenu', 'setRoundOffValue'],
    data(){ 
        return{
            roundOffDialogMenu: false,
            roundOffNature:null,
            roundOffValueManual:null
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        openDialogMenu: function () {    
            this.roundOffNature = this.roundOffComputed < 0 ? 0 : 1;
			this.roundOffValueManual = Math.abs(this.roundOffComputed);
            this.roundOffDialogMenu = true;
        },
        closeDialogMenu: function () {
            this.roundOffDialogMenu = false;
        },
        fn_checkValidations: function(){
            this.$refs.observer.validate().then((result) => {
                if(result) {
                    this.setRoundOffValue(this.roundOffValueManual, this.roundOffNature)
                }
            });
        },
    }
}
</script>