<template>
    <div class="display_wrapper">

        <v-toolbar flat :height="56">
            <!-- <v-toolbar-side-icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-toolbar-side-icon> -->

            <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>

            <v-toolbar-title>Ledger Opening Balance</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn outlined small @click="fn_edit" color="primary" v-if="displayData && !displayData.isSystem">
                <v-icon left>edit</v-icon> Edit
            </v-btn>

            <v-btn class="ml-4" small outlined @click="fn_openDialogConfirmation" color="red" v-if="displayData && !displayData.isSystem">
                <v-icon left>delete</v-icon> Delete
            </v-btn>

        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back" v-if="displayData && ledgerData">
            <div class="title pa-4 white-back">{{displayData.accountLedgerName}}</div>

            <v-list class="mt-2 nopadding" two-line>
                <v-subheader light>Details</v-subheader>
                <v-divider></v-divider>
                <div>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Amount</v-list-item-title>
                            <v-list-item-subtitle>&#8377; {{$root.numberToLocale(displayData.amount)}} {{$root.fn_payOrReceive(displayData.amountType, ledgerData.groupTypeId)}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </div>

            </v-list>

           <!--  <v-list nopadding v-if="displayData.amount && onAccount">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>On Account</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>&#8377; {{onAccount}}</v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
            </v-list> -->

            <!-- <v-list two-line nopadding margin-top-ten v-if="displayData.bwbItems && displayData.bwbItems.length">
                <v-subheader>Bill Wise Breakup</v-subheader>

                <v-divider></v-divider>

                <div v-for="(item, index) in displayData.bwbItems" :key="index">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{item.refNumber}}</v-list-item-title>
                            <v-list-item-sub-title>{{item.refDate | moment("DD-MMM-YYYY")}}</v-list-item-sub-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            &#8377; {{$root.numberToLocale(item.amount)}}
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider v-if="displayData.bwbItems.length-1 != index"></v-divider>
                </div>

            </v-list> -->

        </div>

        <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);" v-if="loading">
          <v-col cols="6">
            <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
          </v-col>
        </v-row>

        <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" header="Delete Transaction?" confirmationMessage="Deleted transaction cannot be restored. Are you sure you want to delete this transaction?" :actionMethod="fn_callCancelTransaction" params="delete" :displayView='true' okBtnText="Yes" cancelBtnText="No" icon="warning" iconClass="redcolor" textClass="redcolor"></dialog-confirmation>

    </div>
</template>

<script>
// const menuItem = require('../../../../config.js').configs.menuItem;
// const transactionForms = require('../../../../config.js').configs.transactionForms;
// const transactionTypes = require('../../../../config.js').configs.transactionTypes;

import dialogConfirmation from '../../../dialogs/dialog_confirmation'
import {transactionMixin} from '../../transaction_mixin'

    export default{
        props:['settings'],
        mixins: [transactionMixin],
        data(){
            return{
                displayData:null,
                ledgerData:null,
                loading:false
                // saleTransactionListId: transactionTypes.bwbRefSaleInvoice.id,
            }
        },
        components:{
         'dialog-confirmation': dialogConfirmation
        },
        methods:{

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_openDialogConfirmation: function(){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            fn_callCancelTransaction: function(){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;

                let self = this;
                this.fn_deleteTransaction(this.displayData.transactionId, this.displayData.transactionListId, function(status){
                    if(status){
                        // alert("Opening ledger of " + self.displayData.accountLedgerName + " has been deleted");
                        self.$eventHub.$emit('snackbarAlert', {msg:"Opening ledger of " + self.displayData.accountLedgerName + " has been deleted"});
                        // self.$eventHub.$emit('UpdateOpeningLedgerList', {});
                        if(self.$route.params.listId == 'parties'){
                            self.$eventHub.$emit('UpdateLedgerList', {});
                        }
                        else{
                            self.$eventHub.$emit('UpdateOpeningLedgerList', {});
                        }
                        self.fn_goBack();
                    }
                    else{
                        // alert("Something went wrong. Try again");
                        self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Try again"});
                    }
                });
            },

            fn_get: function (itemId) {
                this.displayData = null;
                this.loading = true;

                let filterObject = {};
                filterObject.type = this.settings.transactionTypeId;

                let self = this;

                this.getFilterData('Transaction', itemId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            // let itemData = data.transactionLedgers[0];
                            self.displayData = {};
                            self.displayData.transactionId = data.transactionLedgers[0].transactionId;
                            self.displayData.accountLedgerName = data.transactionLedgers[0].accountLedgerName;
                            self.displayData.amount = data.transactionLedgers[0].amount;
                            self.displayData.amountType = data.transactionLedgers[0].amountType;

                            self.displayData.transactionListId = data.transactionListId;

                            self.fn_getLedgerData(data.transactionLedgers[0].accountLedgerId)

                            // self.displayData.bwbItems = data.bwbItems;
                            self.$forceUpdate();
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                          self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                        }
                    }
                );
            },

            fn_getLedgerData: function(selectedValueId){
                let self = this;
                this.getData('Ledger', selectedValueId, 
                    {
                        onSuccess: function(response){
                            console.log(response);

                            self.ledgerData = JSON.parse(response);
                            self.loading = false;
                            // !self.Form.transactionData.transactionId ? self.fn_showHideBwb() : self.fn_changeAmountType(self.Form.amountType);

                        }, 
                        onError: function(error){
                            console.log(error);
                            self.loading = false;
                        }
                    }
                );
            },

            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.ToolbarTitle = "Opening Ledger";
            //  viewObject.TopMenu = [menuItem.edit];
            //  viewObject.isBottomMenu = true;

            //  var tagId = null;
            //  this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';

            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null, 
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.delete]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='edit'){
            //      this.fn_edit();
            //  }
            //  else if(menuAction=='delete'){
            //      this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            //  }
            // },

            fn_edit: function () {
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/openingledger/items/'+this.displayData.transactionId+'/edit');
            },
        },
        mounted: function(){
            
            // this.fn_showWebView();

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_onBackPress = this.fn_goBack;
            
            if(this.$route.params && this.$route.params.tagId){
                this.fn_get(this.$route.params.tagId);
            }

            this.$eventHub.$on('delete', () => {
                this.fn_openDialogConfirmation();
            });

            this.$eventHub.$on('edit', () => {
                this.fn_edit();
            });

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteUpdate(to, from, next){
            this.$eventHub.$off('connected');
            
            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });

            next(true)
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('connected');
            next(true)
        },
        // beforeRouteLeave (to, from, next) {
        //  if(this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation){
        //      this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;
        //      next(false);
        //  }
        //  else{
        //      next(true); 
        //  }
     //    },
        // computed:{
        //  bwbTotal: function(){
        //      let total = 0;
        //      for(let i=0; i<this.displayData.bwbItems.length; i++){
        //          total += this.displayData.bwbItems[i].amount
        //      }
        //      return total;
        //  },
        //  onAccount: function(){
        //      if(this.bwbTotal){
        //          return this.roundUpto2Decimals(this.displayData.amount - this.bwbTotal);
        //      }
        //      else{
        //          return 0;
        //      }
        //  },
        // }
    }

</script>