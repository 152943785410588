<template>
    <div grey-back full-height>
        <!-- fn_draftAction is in draft mixin -->
        <!-- <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_draftAction" :save="fn_checkValidations" :hideKeepDraft="true"></dialog-discard> -->

        <!-- <dialog-draft-items ref="ref_dialogDraftItems" :draftItems="Form.draftItems" :importDraft="fn_importDraft" :removeDraftItem="fn_removeDraftItem"></dialog-draft-items> -->

        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>

    </div>
</template>

<script>
// const dialogjs =  require('../dialog').dialogjs;
// const menuItem = require('../../../../config.js').configs.menuItem;

import dialogDiscard from '../../../dialogs/dialog_discard'
import {draftMixin} from '../../../draft_mixin'

// import dialogDraftItems from '../../../dialogs/dialog_draft_items'

import dialogForm from './dialogs/dialog_form'

    export default{
        mixins: [draftMixin],
        data(){
            return{
                Form:{"addOnsId":null,"name":null, "shortName":null, "addonGroupId":2, "systemDefined":0, "versionId":"1", "status":"1", "adjustInSale":true, "saleAccountItemId":null, "saleAccountItemName":null, "purchaseAccountItemId":null, "adjustInPurchase":true, "purchaseAccountItemName":null,  "nature":1, "mode":0, "isSystem":0, "draftItems":[], "createdDate": null, "modifiedDate":null, "isNew":true, "selectedSaleAccount":null, "selectedPurchaseAccount":null},
                loading:false,
                tagId:null,
                dialogStorageId:null,
                draftStorageId:null,
                backButtonPress: false,

                snackbar:false,
                snackbar_msg:null,

                unwatch:false,
                // loadedFromDraft:false,
                ledgerType:null,
                accountLedgers:null,
                disableOnSave:false
            }
        },
        watch:{
            'Form': {
                handler: function (){
                    // this.fn_saveToLocalStorage(this)
                    if(!this.unwatch){
                        this.fn_saveDraft(this.draftStorageId, this.Form.name);
                     }
                     this.unwatch = false;
                },
                deep: true
            }
        },
        components:{
            'dialog-discard': dialogDiscard,
            // 'dialog-draft-items': dialogDraftItems
            'dialog-form':dialogForm
        },
        methods:{

            fn_closeDialogForm: function(){
                this.askToDiscard = true;
                if(this.fn_showDialogDiscard()){
                    this.$refs['ref_dialogDiscard'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            // fn_accountLedgers: function(val){
            //     let filterObject = {};
            //     filterObject.search = val ? val.toLowerCase() : '';
              
            //     let self = this;
            //     this.getDialogItems("AccountDialog", filterObject, 
            //         {
            //             onSuccess: function (response) {
            //                 console.log(response);
            //                 self.accountLedgers = JSON.parse(response);
            //             },
            //             onError: function (error) {
            //                 console.log(error);
            //             }
            //         }
            //     )
            // },

            // fn_openDialogDiscard: function(){
            //  this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
            // },

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_openAccountLedgerDialog: function (platform, name, ref) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  //dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select Ledger";
            //  viewDialogObject.TopMenu = [menuItem.new];
            //  viewDialogObject.isBottomMenu = false;
            //  viewDialogObject.groupTypeId = 103;

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":null, "name":null};

            //  if(name=='SaleAccountLedger'){
            //      if(this.Form.saleAccountItemId && this.Form.saleAccountItemName){
            //          paramObject.defaultValue = {"id":this.Form.saleAccountItemId, "name":this.Form.saleAccountItemName};
            //      }
            //      this.ledgerType = 'sale';
            //  }
            //  else if(name=='PurchaseAccountLedger'){
            //      if(this.Form.purchaseAccountItemId && this.Form.purchaseAccountItemName){
            //          paramObject.defaultValue = {"id":this.Form.purchaseAccountItemId, "name":this.Form.purchaseAccountItemName};
            //      }
            //      this.ledgerType = 'purchase';
            //  }
                
            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'accounts', tagId, paramObject, ref);
            // },

            // fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
            //  this.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
            // },

            // fn_updateSaleAccountLedger: function (selectedValueId, selectedValueName, isRemoved) {
            //  if(!isRemoved){
            //      this.Form.saleAccountItemId = selectedValueId;
         //         this.Form.saleAccountItemName = selectedValueName;
         //         this.errors.remove('sale account ledger');
            //  }
            //  else{
            //      this.Form.saleAccountItemId = null;
         //         this.Form.saleAccountItemName = null;
            //  }
            //  this.$forceUpdate();
            //  this.fn_saveDraft(this.Form.name);
            // },

            // fn_updatePurchaseAccountLedger: function (selectedValueId, selectedValueName, isRemoved) {
            //  if(!isRemoved){
            //      this.Form.purchaseAccountItemId = selectedValueId;
         //         this.Form.purchaseAccountItemName = selectedValueName;
         //         this.errors.remove('purchase account ledger');
            //  }
            //  else{
            //      this.Form.purchaseAccountItemId = null;
         //         this.Form.purchaseAccountItemName = null;
            //  }
            //  this.$forceUpdate();
            //  this.fn_saveDraft(this.Form.name);
            // },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.save, menuItem.reset, menuItem.help]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_closeDialog: function (tagId) {
            //  // dialogjs.fn_closeDialog(this, tagId);
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_checkValidations: function(){
                this.fn_post();
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('AddOns', dataObj, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          let status = JSON.parse(response);
                          if(status) {
                            self.fn_cleanUp();
                          }
                          else {
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            self.$eventHub.$emit('snackbarAlert', {msg:'Already exists with this name'});
                          }
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                        }
                    }
                );
                // this.fn_goBack();
                // this.$router.go(-1);
            },

            fn_cleanUp: function () {
                // this.loadedFromDraft = false;

                if(this.$route.query.ls){
                    let data = {};
                    data.type = 'taxes';
                    data.id = this.Form.addOnsId;
                    data.name = this.Form.name;
                    data.addonGroupId = this.Form.addonGroupId;
                    localStorage.setItem('newItem', JSON.stringify(data));
                    // localStorage.setItem('closeDialog', 1);
                }

                this.$eventHub.$emit('UpdateTaxList', {});
                this.fn_goBack();
            },

            fn_post: function(){
                // !this.Form.addOnsId ? this.Form.addOnsId = this.guid() : '';
                if(!this.Form.addOnsId){
                    this.Form.addOnsId = this.guidWeb();
                    // this.Form.createdDate = new Date();
                    this.Form.createdDate = this.$root.fn_getLocalDateFormat();
                }

                // this.Form.modifiedDate = new Date();
                this.Form.modifiedDate = this.$root.fn_getLocalDateFormat();
                
                this.Form.name = this.Form.name.trim();
                
                if(this.Form.adjustInSale){
                    this.Form.saleAccountItemId = null;
                    this.Form.saleAccountItemName = null;
                }
                else{
                    this.Form.saleAccountItemId = this.Form.selectedSaleAccount.itemId;
                    this.Form.saleAccountItemName = this.Form.selectedSaleAccount.itemName;   
                }

                if(this.Form.adjustInPurchase){
                    this.Form.purchaseAccountItemId = null;
                    this.Form.purchaseAccountItemName = null;
                }
                else{
                    this.Form.purchaseAccountItemId = this.Form.selectedPurchaseAccount.itemId;
                    this.Form.purchaseAccountItemName = this.Form.selectedPurchaseAccount.itemName;
                }

                console.log(this.Form);
                this.fn_saveLocal(this.Form);
            },

            fn_get: function(itemId, copyData){
                var self = this;
                this.unwatch = true;
                this.Form.isNew = false
                this.getData('AddOns', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.unwatch = true;
                            self.Form = JSON.parse(response);
                            self.Form.shortName == 'null' ? self.Form.shortName = null : '';

                            self.Form.selectedSaleAccount = null;
                            self.Form.selectedPurchaseAccount = null;

                            if(self.Form.saleAccountItemId){
                                self.Form.selectedSaleAccount = {"itemId":self.Form.saleAccountItemId, "itemName":self.Form.saleAccountItemName};
                            }
                            
                            if(self.Form.purchaseAccountItemId){
                                self.Form.selectedPurchaseAccount = {"itemId":self.Form.purchaseAccountItemId, "itemName":self.Form.purchaseAccountItemName};    
                            }

                            if(copyData){
                                self.Form.addOnsId=null;
                                self.Form.isSystem = 0;
                                self.fn_saveDraft(self.draftStorageId, self.Form.name);
                                // alert("Tax copied");
                                self.$eventHub.$emit('snackbarAlert', {msg:'Tax copied'});
                            }

                            self.$root.fn_copyFormObject(self.Form, self.formId);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_clearForm: function(){
                this.Form = {"addOnsId":null,"name":null, "shortName":null, "addonGroupId":1, "systemDefined":0, "versionId":"1", "status":"1", "adjustInSale":true, "saleAccountItemId":null, "saleAccountItemName":null, "purchaseAccountItemId":null, "adjustInPurchase":true, "purchaseAccountItemName":null,  "nature":1, "mode":0, "isSystem":0, "draftItems":[], "createdDate":null, "modifiedDate":null, "isNew":true}
                // this.$validator.reset();
            },

            // fn_saveToLocalStorage: function(){
            //  if(!this.unwatch){
            //      var dataObj = {};
            //      dataObj.type = "Discounts and Offers";
            //      dataObj.formType = "new";
            //      dataObj.modifiedDate = new Date();
            //      dataObj.formId = this.formId;
            //      dataObj.draftId = this.draftStorageId;
            //      dataObj.uri = this.$route.path;
            //      dataObj.dataModel = this.Form;
            //      localStorage.setItem(this.draftStorageId, JSON.stringify(dataObj));
            //  }
            //  this.unwatch = false;
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  this.backButtonPress = false;

            //  if(menuAction=='save'){
            //      this.fn_checkValidations();
            //  }
            //  else if(menuAction=='new'){
            //      this.$router.push({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), ls:1, type:this.ledgerType, groupTypeId:103}})
            //  }
            //  else if(menuAction=='reset'){
            //      this.fn_resetForm();
            //  }
            // },

            // fn_resetForm: function () {
            //  this.unwatch = true;
            //  // this.loadedFromDraft = false;
                
            //  if(this.Form.addOnsId){
            //      this.fn_get(this.Form.addOnsId);
            //  }
            //  else{
            //      this.fn_clearForm();
            //  }
                
            //  let self = this;
            //  this.fn_removeDraft(this.draftStorageId, function(){
            //      console.log("draft deleted");
            //      // self.showToast("Item deleted from draft");
            //  });
            // },

            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.ToolbarTitle = "Discounts";
            //  viewObject.TopMenu = [menuItem.save];
            //  viewObject.isBottomMenu = true;

            //  var tagId = this.formId;
            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null,
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            fn_setNewItem: function(){
                if(localStorage.getItem('newItem')){

                    let itemData = JSON.parse(localStorage.getItem('newItem'));

                    switch (itemData.type) {
                        case "sale":
                            // alert(JSON.stringify(itemData));
                            this.Form.selectedSaleAccount = {"itemId":itemData.id, "itemName":itemData.name}
                            break;
                        case "purchase":
                            this.Form.selectedPurchaseAccount = {"itemId":itemData.id, "itemName":itemData.name}
                            break;
                        default:
                            console.log("condition not matched");
                    }

                    localStorage.removeItem('newItem');
                }

                this.Form.isNew ? this.$root.fn_copyFormObject(this.Form, this.formId) : '';
            },

            fn_goBack: function(){
                this.$root.viaKeyboardShortCut = false;
                this.$refs['ref_dialogForm'].closeDialogWeb();
                localStorage.removeItem(this.draftStorageId);
                this.backButtonPress = true;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            }
        },
        mounted: function(){
            // var self = this;

            if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }

            setTimeout(()=>{this.fn_setNewItem()},300);

            if(localStorage.getItem(this.draftStorageId)){
                let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
                this.Form = JSON.parse(dataObj.formData);
            }

            // this.fn_accountLedgers();

            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                // vm.enableToolbar();
                
                let itemId = null;
                let copyData = false;

                // to.query && to.query.copy && from.name == 'itemlist' ? copyData = true : '';
                to.query && to.query.copy ? copyData = true : '';

                // if(to.query && to.query.itemId && from.name == 'itemlist'){
                //     itemId = to.query.itemId;
                // }
                if(to.query && to.query.itemId){
                    itemId = to.query.itemId;
                }

                if(to.params && to.params.tagId){//from display view
                  itemId = to.params.tagId;
                }

                // if(itemId){
                //     vm.fn_get(itemId, copyData);
                // }

                if(vm.$route.query && vm.$route.query.tagId){//in case of new form
                    vm.formId = vm.$route.query.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }
                else if(vm.$route.params && vm.$route.params.tagId){//in case of edit form
                    vm.formId = vm.$route.params.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }

                if(itemId){
                    !localStorage.getItem(vm.draftStorageId) ? vm.fn_get(itemId, copyData) : '';
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            // !vm.accountLedgers ? vm.fn_accountLedgers() : '';
                            itemId ? vm.fn_get(itemId, copyData) : '';
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });

            })
        },
        beforeRouteLeave (to, from, next) {
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('connected');
                next();
            }
        },
        // beforeRouteUpdate (to, from, next) {
        //     // this.enableToolbar();
            
        //     // var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //     // var existingForm = false;
        //     // if(dialogData && dialogData.length){
        //     //  var openForms = JSON.parse(localStorage.getItem('discountforms'));
        //     //  for(var i=0; i<openForms.length; i++){
        //     //      if(openForms[i]==to.query.tagId){
        //     //          existingForm = true;
        //     //          dialogjs.fn_checkAndCloseOpenDialogs(this);
        //     //          break;
        //     //      }
        //     //  }
        //     //  existingForm ? next(false) : next(true);
        //     // }
        //     // else{
        //     //  next();
        //     // }

        //     if(to.query && to.query.tagId){//in case of new form
        //         this.formId = to.query.tagId;
        //         this.draftStorageId = 'draft_master_'+this.formId;
        //         // this.dialogStorageId = 'dialog_'+this.formId;
        //     }
        //     else if(to.params && to.params.tagId){//in case of edit form
        //         this.formId = to.params.tagId;
        //         this.draftStorageId = 'draft_master_'+this.formId;
        //         // this.dialogStorageId = 'dialog_'+this.formId;
        //     }

        //     if(localStorage.getItem(this.draftStorageId)){
        //         // alert('ins');
        //         let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
        //         // callback(dataObj.formData);
        //         // alert(this.draftStorageId);
        //         this.Form = JSON.parse(dataObj.formData);
        //     }
        //     else{
        //         this.fn_clearForm();
        //     }

        //     this.fn_accountLedgers();

        //     setTimeout(()=>{this.fn_setNewItem()},300);

        //     this.$refs['ref_dialogForm'].openDialogWeb();

        //     next();
        // },
      //   beforeRouteLeave (to, from, next) {
            // var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
            // if(dialogData && dialogData.length){

            //  if(this.backButtonPress){
            //      dialogjs.fn_checkAndCloseOpenDialogs(this);
            //      next(false);
            //  }
            //  else{
            //      // this.$root.fn_deleteFormObject();
            //      this.fn_saveDraft(this.Form.name);
            //      next(true);
            //  }
            // }
            // else{
            //  // if(this.askToDiscard){
            //  //  this.fn_openDialogDiscard();
            //  //  next(false);
            //  // }
            //  if(this.fn_showDialogDiscard()){
            //      this.fn_openDialogDiscard();
            //      next(false);
            //  }
            //  else{
            //      this.fn_clearData('discountforms', function(){
            //          next();
            //      })
            //  }
            //  // if(this.loadedFromDraft || (this.$root.fn_getFormObject(this.formId) && !this.$root.fn_deepEqual(this.Form, JSON.parse(this.$root.fn_getFormObject(this.formId))))){
            //  //  this.fn_openDialogDiscard();
            //  //  next(false);
            //  // }
            //  // else{
            //  //  this.$root.fn_deleteFormObject(this.formId);
            //  //  !this.askToDiscard ? this.fn_removeDraft(this.draftStorageId, null, -1) : '';
            //  //  localStorage.removeItem('discountforms')
            //  //  next(); 
            //  // }
            // }
      //   }
    }

</script>