<template>
    <div>
        <v-dialog v-model="dialog_tax_rate" no-click-animation scrollable persistent max-width="300px" transition="dialog-bottom-transition">
          <v-card>
            <v-card-title nopadding noshadow class="dialog_searchbox">
                <v-subheader full-width>Select GST Rate</v-subheader>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text nopadding single-selection-dialog>

                <v-list nopadding>
                    <v-list-item v-for="(item, index) in taxRates" :key="index" @click="updateTaxRate(item.value)">

                        <v-list-item-content>
                          <v-list-item-title>{{item.name}}&nbsp;<span v-if="item.value == 'custom' && $parent.compTaxRate.type == 2">({{$parent.compTaxRate.value}}%)</span></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon v-if="item.value == selectedTaxRate">radio_button_checked</v-icon>
                            <v-icon v-else-if="item.value == 'custom' &&  $parent.compTaxRate.type == 2">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>

                    </v-list-item>
                </v-list>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default{
        props:['taxRates', 'selectedTaxRate', 'updateTaxRate'],
        data(){
            return{
                dialog_tax_rate: false
            }
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_tax_rate = true;
            },
            closeDialogWeb: function () {
                this.dialog_tax_rate = false;
            },
        }
    }
</script>