<template>
    <div full-height>
        <div class="display_wrapper" id="non-printable">

            <v-toolbar flat :height="56">
                <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>

                <v-toolbar-title :class="{'two-line-title':offsetTop>=36}">
                    {{toolbarTitle}}
                    <div class="toolbar-subtitle" v-if="displayData && offsetTop>=36">
                        <div class="subtitle-text dark-opacity-color">
                            {{displayData.mergeTransactionNumber}}
                        </div>
                    </div>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <!-- <v-btn round-borders outline @click="fn_edit" v-if="displayData && displayData.status==1">edit</v-btn> -->

                <v-btn outlined small @click="fn_preview" color="primary" v-if="displayData">
                    <v-icon left>preview</v-icon> Preview
                </v-btn>

                <v-btn outlined class="ml-4" small @click="fn_edit" color="primary" v-if="displayData && displayData.status==1">
                    <v-icon left>edit</v-icon> Edit
                </v-btn>

                <v-btn class="ml-4" small outlined color="warning" v-if="displayData && displayData.status==1" @click="fn_cancel">
                    <v-icon left>close</v-icon> Cancel
                </v-btn>

                <v-btn class="ml-4" small outlined color="red" v-if="displayData" @click="fn_delete">
                    <v-icon left>delete</v-icon> Delete
                </v-btn>

                <!-- <v-btn icon @click="fn_edit" v-if="displayData && displayData.status==1">
                    <v-icon>edit</v-icon>
                </v-btn> -->
            </v-toolbar>

            <v-divider></v-divider>

            <div class="data-container grey-back" v-scroll="onScrollTransactionForm" v-if="displayData">

                <v-alert :height="40" class="pa-2 no-border-radius mb-0" :value="displayData && displayData.status==0" type="warning" icon="info_outline" transition="slide-y-transition">
                    <span>This transaction has been cancelled!</span>
                </v-alert>

                <v-layout row wrap pa-4 ma-0 font16 white-back>
                    <v-flex xs6 sm6 md6>{{displayData.mergeTransactionNumber}}</v-flex>
                    <v-flex xs6 sm6 md6 right-align>{{displayData.transactionDate | moment("DD-MMM-YYYY")}}</v-flex>
                </v-layout>

                <!-- <v-layout grid wrap text-xs-center>
                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('sharewhatsapp')">
                        <div right-border>
                            <div><v-icon>send</v-icon></div>
                            <div class="font-weight-bold">Send</div>
                        </div>  
                    </v-flex>

                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('print')">
                        <div right-border>
                            <div><v-icon>print</v-icon></div>
                            <div class="font-weight-bold">Print</div>
                        </div>
                    </v-flex>

                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('pdf')">
                        <div right-border>
                            <div><v-icon>import_export</v-icon></div>
                            <div class="font-weight-bold">Export</div>
                        </div>
                    </v-flex>

                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_getBottomMenu">
                        <div>
                            <div><v-icon>more_horiz</v-icon></div>
                            <div class="font-weight-bold">More</div>
                        </div>
                    </v-flex>
                </v-layout> -->

                <v-list nopadding>
                    <v-list-item @click="fn_viewPartyDetails(displayData.partyId)">
                        <v-list-item-content>
                          <v-list-item-title>Supplier</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action font16 primary-color>{{displayData.partyName}}</v-list-item-action>
                    </v-list-item>

                    <!-- <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Bill No: <span v-if="displayData.refNumber">#{{displayData.refNumber}}</span></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>{{displayData.refDate | moment("DD-MMM-YYYY")}}</v-list-item-action>
                    </v-list-item> -->
                    <v-list-item v-if="displayData.refNumber">
                        <v-list-item-content>
                          <v-list-item-title>Bill No:</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action font16>#{{displayData.refNumber}}</v-list-item-action>
                    </v-list-item>

                    <v-list-item v-if="displayData.refDate">
                        <v-list-item-content>
                          <v-list-item-title>Bill Date:</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action font16>{{displayData.refDate | moment("DD-MMM-YYYY")}}</v-list-item-action>
                    </v-list-item>

                    <v-list-item v-if="displayData && (displayData.placeOfSupplyId != $root.CurrentBusiness.StateID) && $root.CurrentBusiness.GSTRegistrationStatus==1 && displayData.placeOfSupplyName">
                        <v-list-item-content>
                          <v-list-item-title>Place of Supply</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action font16>{{displayData.placeOfSupplyName}}</v-list-item-action>
                    </v-list-item>

                    <v-list-item v-if="displayData.placeOfSupplyCountryName">
                        <v-list-item-content>
                          <v-list-item-title>Country</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action font16>{{displayData.placeOfSupplyCountryName}}</v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-list nopadding class="mt-2" v-if="displayData.transactionLedgers">
                    <v-subheader>Expense Category</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                        <!-- <v-list-item-content>
                          <v-list-item-title>{{displayData.transactionLedgers[0].accountLedgerName}}</v-list-item-title>
                        </v-list-item-content> -->
                        <v-list-item-content>
                          <v-list-item-title>{{displayData.expenseCategoryName}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action font16 v-if="!displayData.transactionProducts">
                            &#8377; {{displayData.transactionLedgers[0].amount ? $root.numberToLocale(displayData.transactionLedgers[0].amount) : displayData.transactionLedgers[0].amount}}
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                    <v-list nopadding class="mt-2" v-if="fn_checkForItems()">

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Items</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>
                        
                        <div v-for="(item, itemIndex) in displayData.transactionProducts" :key="itemIndex">
                            <v-list-item class="right-subtitle-tile custom-action-tile">
                                <v-list-item-content>
                                  <v-list-item-title class="mb-1">{{item.productName}}</v-list-item-title>
                                  <!-- <v-list-item-subtitle class="mb-1"><span class="right_subtitle">&#8377; {{item.netAmount ? $root.numberToLocale(item.netAmount) : item.netAmount}}</span> </v-list-item-subtitle> -->

                                  <v-list-item-subtitle class="mb-1" v-for="(variant, vIndex) in item.productVariants" :key="vIndex">
                                    <template v-if="variant.qty">
                                        {{variant.qty}} {{variant.unitName}} X {{item.rate ? $root.numberToLocale(item.rate) : item.rate}}    
                                    </template>
                                  </v-list-item-subtitle>

                                  <template v-for="(addOns, addOnsIndex) in item.transactionProductAddOns">
                                    <v-list-item-subtitle class="mb-1" :key="addOnsIndex" v-if="addOns.value && !addOns.isGst">
                                        {{addOns.name}}
                                        <span v-if="addOns.percentage"> @{{addOns.percentage}}%</span>: {{addOns.nature ? '-' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}
                                    </v-list-item-subtitle>    
                                  </template>

                                  <v-list-item-subtitle class="mb-1" v-if="displayData.taxMode">
                                    GST @{{item.taxpercent}}%: {{item.taxvalue ? $root.numberToLocale(item.taxvalue) : item.taxvalue}} {{displayData.isRcmApplicable ? ' (RCM)' : ''}}
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle class="mb-1" v-if="displayData.taxMode && item.cessvalue">
                                    Cess<span v-if="item.cesspercent!=null"> @{{item.cesspercent}}%</span>: {{item.cessvalue ? $root.numberToLocale(item.cessvalue) : item.cessvalue}} {{displayData.isRcmApplicable ? ' (RCM)' : ''}}
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle class="mb-1" v-if="item.description">
                                    {{item.description}}
                                  </v-list-item-subtitle>

                                </v-list-item-content>

                                <v-list-item-action font16>
                                    {{$root.numberToLocale(item.netAmount)}}
                                    <!-- <div v-if="displayData.isRcmApplicable">
                                        &#8377; {{fn_itemTotal(item)}}
                                    </div>
                                    <div v-else>&#8377; {{$root.numberToLocale(item.netAmount)}}</div> -->
                                    <!-- <div>&#8377; {{item.netAmount ? $root.numberToLocale(item.netAmount) : item.netAmount}}</div> -->
                                </v-list-item-action>

                            </v-list-item>

                            <v-divider v-if="displayData.transactionProducts.length-1 != itemIndex"></v-divider>
                        </div>

                    </v-list>


                    <v-divider v-if="fn_checkForItems()"></v-divider>

                    <v-list nopadding v-if="displayData.transactionProducts && displayData.transactionProducts.length" class="oneline-tile-dense">

                        <!-- <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Total Qty</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{totalQty}} Units</v-list-item-action>
                        </v-list-item> -->

                        <!-- <v-divider></v-divider> -->

                        <v-list-item>
                            <v-list-item-content>
                              <!-- <v-list-item-title>Gross Amount</v-list-item-title> -->
                              <v-list-item-title>Items Total</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>{{displayData.grossAmount ? $root.numberToLocale(displayData.grossAmount) : displayData.grossAmount}}</v-list-item-action>
                        </v-list-item>

                        <v-list-item v-for="(addOns, index) in addOnsSummary" :key="index">
                            <v-list-item-content>
                              <!-- <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title> -->
                              <v-list-item-title>{{addOns.addOnsNature ? 'Items Discount' : addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>{{addOns.addOnsNature ? '-' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>

                        <v-list-item v-for="(addOns, index) in gstAddOns" :key="index">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}{{displayData.isRcmApplicable ? ' (RCM)' : ''}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>{{addOns.addOnsNature ? '-' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>

                        <!-- <v-divider></v-divider> -->

                        <!-- <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Net Amount</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>&#8377; {{displayData.netAmount ? $root.numberToLocale(displayData.netAmount) : displayData.netAmount}}</v-list-item-action>
                        </v-list-item> -->
                    </v-list>

                    <!-- <v-list nopadding margin-top-ten v-if="displayData.transactionAddOns && displayData.transactionAddOns.length">
                        <v-subheader>Discounts/Taxes/Other Charges</v-subheader>
                        <v-divider></v-divider>
                        <v-list-item v-for="(addOns, index) in displayData.transactionAddOns" :key="index">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>
                    </v-list> -->

                    <!-- <div nopadding white-back margin-top-ten v-if="isOtherChargesAddons">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title medium-theme-color>Other Charges</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list-item v-for="(addOns, index) in displayData.transactionAddOns" :key="index" v-if="addOns.addOnsType==3">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>
                    </div>

                    <div nopadding white-back margin-top-ten v-if="isDiscountAddons">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title medium-theme-color>Discounts</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list-item v-for="(addOns, index) in displayData.transactionAddOns" :key="index" v-if="addOns.addOnsType==1">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>
                    </div>

                    <div nopadding white-back margin-top-ten v-if="isTaxAddons">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title medium-theme-color>Taxes</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list-item v-for="(addOns, index) in displayData.transactionAddOns" :key="index" v-if="addOns.addOnsType==2">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>
                    </div> -->

                    <div class="oneline-tile-dense mt-2" v-if="displayData.transactionAddOns && displayData.transactionAddOns.length">
                        <div white-back v-if="isOtherChargesAddons">
                            <v-list-item v-for="(addOns, index) in otherChargesAddons" :key="index">
                                <v-list-item-content>
                                  <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action font16>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                            </v-list-item>
                        </div>

                        <div white-back v-if="isDiscountAddons">
                            <v-list-item v-for="(addOns, index) in discountAddons" :key="index">
                                <v-list-item-content>
                                  <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action font16>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                            </v-list-item>
                        </div>

                        <div white-back v-if="isTaxAddons">
                            <v-list-item v-for="(addOns, index) in taxAddons" :key="index">
                                <v-list-item-content>
                                  <v-list-item-title>{{addOns.addOnsName}}{{displayData.isRcmApplicable ? ' (RCM)' : ''}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action font16>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                            </v-list-item>
                        </div>
                    </div>  

                    <v-list class="oneline-tile-dense" nopadding>

                        <div v-if="displayData.transactionTds && displayData.transactionTds.length">
                            <v-list-item v-for="(tds, index) in displayData.transactionTds" :key="index">
                                <v-list-item-content>
                                  <v-list-item-title>{{tds.tdsAccountName}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action font16>-{{tds.amount ? $root.numberToLocale(tds.amount) : tds.amount}}</v-list-item-action>
                            </v-list-item>

                            <!-- <v-divider></v-divider> -->
                        </div>

                        <!-- <v-list-item v-if="displayData && displayData.roundOffValue">
                            <v-list-item-content>
                              <v-list-item-title>Round Off</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>&#8377; {{displayData.roundOffValue}}</v-list-item-action>
                        </v-list-item> -->
                        <v-list-item v-if="roundOffValue">
                            <v-list-item-content>
                              <v-list-item-title>Round Off</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>&#8377; {{roundOffValue}}</v-list-item-action>
                        </v-list-item>

                        <!-- <v-divider></v-divider>
                        
                        <v-list-item font-medium sticky-bottom>
                            <v-list-item-content>
                              <v-list-item-title>Total Amount</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>&#8377; {{displayData.roundedAmount ? $root.numberToLocale(displayData.roundedAmount) : displayData.roundedAmount}}</v-list-item-action>
                        </v-list-item> -->
                    </v-list>

                    <v-list sticky-bottom nopadding>
                        <v-divider></v-divider>
                        <v-list-item font-medium>
                            <v-list-item-content>
                              <v-list-item-title>Total Amount</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>&#8377; {{displayData.roundedAmount ? $root.numberToLocale(displayData.roundedAmount) : displayData.roundedAmount}}</v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <v-list nopadding>
                        <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Term of Payment</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{displayData.paymentTerm != -1 ? displayData.paymentTerm + ' Days' : 'No Due Date'}}</v-list-item-action>
                        </v-list-item>

                        <v-list-item v-if="displayData.paymentTerm != -1">
                            <v-list-item-content>
                              <v-list-item-title>Due Date</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{fn_dueDate(displayData.refDate, displayData.transactionDate, displayData.paymentTerm) | moment("DD-MMM-YYYY")}}</v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <div v-if="displayData.description" class="mt-2">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Description</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-card flat class="note-card">
                          <v-card-text font16 v-html="displayData.description" white-space-pre-line></v-card-text>
                        </v-card>
                    </div>

                    <div v-if="displayData.notes" class="mt-2">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title medium-theme-color>Notes</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-card flat class="note-card">
                          <v-card-text font16 v-html="displayData.notes" white-space-pre-line></v-card-text>
                        </v-card>
                    </div>

                
                    <v-layout row wrap mt-2 ma-0 white-back v-if="customFields.length">

                        <!-- {{ customFields }} -->

                        <v-expansion-panels nomargin>

                            <v-expansion-panel v-for="(item, index) in customFields" :key="index">

                            <!-- <v-divider></v-divider> -->

                            <!-- <div slot="header" font16>{{item.sectionName}}</div> -->
                            <v-expansion-panel-header>{{ item.sectionName }}</v-expansion-panel-header>

                            <!-- <v-divider class="pb-3"></v-divider> -->

                            <v-expansion-panel-content>
                                <v-card v-for="(fieldItem, itemIndex) in item.items" :key="itemIndex">
                                    <template v-if="fieldItem.fieldValue">
                                        <v-card-text class="pt-0">
                                            <div font12 light-theme-color>{{fieldItem.fieldName}}</div>
                                            <div font16 v-if="fieldItem.fieldDataType == 'yes/no'">{{fieldItem.fieldValue ? 'Yes' : 'No'}}</div>

                                            <div font16 v-else-if="fieldItem.fieldDataType == 'dateTime'">
                                                <span v-if="fieldItem.fieldValue">{{fieldItem.fieldValue | moment("DD-MMM-YYYY")}}</span>
                                                <span v-else>-</span>
                                            </div>
                                            
                                            <div font16 v-html="fieldItem.fieldValue ? fieldItem.fieldValue : '-'" white-space-pre-line v-else></div>
                                        </v-card-text>    
                                    </template>
                                </v-card>
                                </v-expansion-panel-content>

                            </v-expansion-panel>

                        </v-expansion-panels>

                        </v-layout>

                <div v-if="displayData.documentDetails" class="mt-2">
                    <v-list full-width nopadding>
                        <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Attachments {{ displayData.documentDetails.length ? '('+displayData.documentDetails.length+')' : '' }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <template v-for="(item, index) in displayData.documentDetails">
                        <v-list-item white-back :key="index">
                            <v-list-item-content>
                              <v-list-item-title>{{item.fileName}}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn text icon :disabled="$root.CurrentBusiness.temporaryBusiness ? true : false" @click.native="fn_checkFileExist(item)"><v-icon>cloud_download</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="displayData.documentDetails.length-1 != index" :key="item.fileName"></v-divider>
                    </template>
                </div>

                <v-list class="mt-2" nopadding v-if="displayData && displayData.paymentMode==1">
                    <!-- <v-subheader full-width border-bottom-thin nomargin norightpadding>Payment Mode</v-subheader> -->
                    <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Payment Mode</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <div v-for="(item, index) in displayData.transactionLedgers" :key="index">
                        <v-list-item v-if="item.orderNumber != 1">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.accountLedgerId == creditAccountLedgerId ? 'On Credit' : item.accountLedgerName}}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>&#8377; {{item.amount ? $root.numberToLocale(item.amount) : item.amount}}</v-list-item-action>
                        </v-list-item>  
                    </div>
                </v-list>

                <!-- <v-footer class="invoice-footer-actions">

                    <v-layout white-back grid wrap text-xs-center>
                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('sharewhatsapp')">
                            <div right-border>
                                <div><v-icon>send</v-icon></div>
                                <div font11 class="font-weight-bold">Send</div>
                            </div>  
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('share')">
                            <div right-border>
                                <div><v-icon>share</v-icon></div>
                                <div font11 class="font-weight-bold">Share</div>
                            </div>
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('print')">
                            <div right-border>
                                <div><v-icon>print</v-icon></div>
                                <div font11 class="font-weight-bold">Print</div>
                            </div>
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('pdf')">
                            <div right-border>
                                <div><v-icon>vertical_align_bottom</v-icon></div>
                                <div font11 class="font-weight-bold">Download</div>
                            </div>
                        </v-flex>
                    </v-layout> 

                    <div v-ripple class="pb-2 pt-2" style="width:20%" center-align white-back @click="fn_getBottomMenu">
                        <div><v-icon>more_horiz</v-icon></div>
                        <div font11 class="font-weight-bold">More</div>
                    </div>  

                </v-footer> -->

                            

            </div>

            <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);" v-if="loading">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>

            <!-- <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" :header="confirmationDialogHeader" :confirmationMessage="confirmationMessage" :actionMethod="fn_callCancelTransaction" :params="{type:actionType}" :displayView='true'></dialog-confirmation> -->

            <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" :header="confirmationDialogHeader" :confirmationMessage="confirmationMessage" :actionMethod="fn_callCancelTransaction" :params="{type:actionType}" :displayView='true' okBtnText="Yes" cancelBtnText="No" icon="warning" iconClass="redcolor" textClass="redcolor"></dialog-confirmation>
            
            <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>
        </div>

        <!-- <div id="printable" full-width>
            <component :is="printTemplate" ref="ref_printTemplate" :data="printData" v-if="printTemplate && printData!=null"></component>
        </div>

        <dialog-permission ref="ref_dialogPermission"></dialog-permission> -->

        <dialog-loading ref="ref_dialogLoading" message="Downloading..." :value="loadingStatusObj.value"></dialog-loading>

        <v-snackbar v-model="snackbarOpenFile">
          File downloaded!
          <v-btn text color="primary" @click.native="$root.fn_openDownloadedFile">Open</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
const menuItem = require('../../../../config.js').configs.menuItem;
const transactionForms = require('../../../../config.js').configs.transactionForms;
// const transactionTypes = require('../../../../config.js').configs.transactionTypes;

// import printTemplateInventory from '../../../invoice_templates/tax_invoice'
// import printTemplateNonInventrory from '../../../invoice_templates/invoices/template101'

import dialogConfirmation from '../../../dialogs/dialog_confirmation'

import dialogLoading from '../../../dialogs/dialog_loading'

import {transactionMixin} from '../../transaction_mixin'

    export default{
        props:['settings'],
        mixins: [transactionMixin],
        data(){
            return{
                displayData:{},
                toolbarTitle:null,
                snackbar:false,
                snackbar_msg:null,
                addOnsSummary:[],
                customFields:[],
                itemsQuanity:[],
                // inventory:true,
                actionType:null,
                confirmationDialogHeader:null,
                confirmationMessage:null,
                gstAddOns:[],
                printData:null,
                printTemplate:null,
                isTaxAddons:false,
                isDiscountAddons:false,
                isOtherChargesAddons:false,
                loading:false
            }
        },
        components:{
            'dialog-confirmation': dialogConfirmation,
            // 'print-template-inventory':printTemplateInventory,
            // 'print-template-non-inventory':printTemplateNonInventrory
            'dialog-loading':dialogLoading
        },
        methods:{

            fn_itemTotal: function(item){
                let netAmount = item.netAmount ? item.netAmount : 0;
                let taxValue = item.taxvalue ? item.taxvalue : 0;
                let cessValue = item.cessvalue ? item.cessvalue : 0;
                return this.$root.numberToLocale(netAmount - taxValue - cessValue);
            },

            fn_preview: function(){
                this.fn_previewInvoice(true, 'purchase');
                // if(this.inventory){
                //  this.fn_previewInvoice(true, 'purchase');
                // }
                // else{
                //  this.fn_previewInvoice(true, 'purchase', 'ab');
                // }
            },

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_shareInvoice: function(type, fnName){
                // this.displayData.transactionTypeFormName = "Expense";

                let shareObj = {};
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';
                // shareObj.shareVia = this.displayData.primaryMobileNumber && type == 'sharewhatsapp' ? 'ShareOnWhatsApp' : 'Share';
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = this.displayData.primaryMobileNumber && type == 'sharewhatsapp' ? this.displayData.primaryMobileNumber : null;
                shareObj.subject = this.toolbarTitle + " Details";
                shareObj.email = this.displayData.email ? [this.displayData.email] : null ;

                let party = this.displayData.partyName ? this.displayData.partyName + ',' : '';

                shareObj.textMessage = "Dear "+party+"\n\nBelow are your " + this.toolbarTitle + " details. Please find the attached pdf.\n\nTransaction Date: "+this.$moment(this.displayData.transactionDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(this.displayData.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;

                // shareObj.textMessage = "Dear "+this.displayData.partyName+",\n\nBelow are your invoice details. Please find the attached pdf of your invoice.\n\nInvoice Date: "+this.$moment(this.displayData.createdDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(this.displayData.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.fn_getPrintData(fnName, this.displayData.transactionId, 'pdf', shareObj);
            },

            // fn_getPrint: function(type){
            //  let name = this.inventory ? 'InvoiceData' : 'ExpensePrintData';
            //  this.fn_getPrintData(name, this.displayData.transactionId, type);
            // },

            fn_callCancelTransaction: function(paramObj){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;

                let obj = {};
                obj.transactionTypeId = this.displayData.transactionListId;

                let self = this;
                if(paramObj.type=='cancel'){
                    this.fn_cancelTransaction(this.displayData.transactionId, this.displayData.transactionListId, null, function(status){
                        self.displayData.status = status;
                        if(status==0){
                            // alert("Transaction "+self.displayData.mergeTransactionNumber+ " cancelled");
                            self.$eventHub.$emit('snackbarAlert', {msg:"Transaction "+self.displayData.mergeTransactionNumber+ " cancelled"});
                            if(self.$route.params.listId == 'parties' || self.$route.params.listId == 'ledgers'){
                                self.$eventHub.$emit('UpdateLedgerList', {});
                            }
                            else{
                                self.$eventHub.$emit('UpdateExpenseList', obj);
                            }
                        }
                        else{
                            // alert("Transaction "+self.displayData.mergeTransactionNumber+ " not cancelled");
                            self.$eventHub.$emit('snackbarAlert', {msg:"Transaction "+self.displayData.mergeTransactionNumber+ " not cancelled"});
                        }
                    });  
                }
                else{
                    let transactionNumber = this.displayData.mergeTransactionNumber;
                    this.fn_deleteTransaction(this.displayData.transactionId, this.displayData.transactionListId, function(status){
                        if(status){
                            // alert('#'+transactionNumber+ ' deleted parmanently');
                            self.$eventHub.$emit('snackbarAlert', {msg:'#'+transactionNumber+ ' deleted parmanently'});
                            // self.$eventHub.$emit('UpdateExpenseList', obj);
                            if(self.$route.params.listId == 'parties' || self.$route.params.listId == 'ledgers'){
                                self.$eventHub.$emit('UpdateLedgerList', {});
                            }
                            else{
                                self.$eventHub.$emit('UpdateExpenseList', obj);
                            }
                            self.$router.push({path:"/"+self.$root.CurrentBusiness.BusinessID+"/"+self.$route.params.listId});
                        }
                        else{
                            // alert('Something went wrong. Try again');
                            self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                        }
                    });
                }
            },

            fn_initializeSettings: function () {
                transactionForms.filter(item => {
                    if(item.id == this.settings.transactionTypeId){
                        this.toolbarTitle = item.title;
                    }
                })
            },

            fn_comingSoon: function () {
                // this.snackbar_msg = "Coming Soon..";
                // this.snackbar = true;
                this.showToast("under developement");
            },

            fn_get: function(itemId){
                var self = this;
                this.displayData = null;
                this.loading = true;
                this.getData('Expense', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.displayData = JSON.parse(response);

                            if(self.displayData.transactionProducts && self.displayData.transactionProducts.length){
                                // sorting items as per order number
                                self.displayData.transactionProducts.sort(function(a, b){
                                    return a.orderNumber - b.orderNumber;
                                });

                                // self.inventory = true;
                                self.fn_calculateAddOnsSummary();
                                self.fn_itemsSummary();
                            }
                            // else{
                            //  self.inventory = false;
                            // }

                            self.fn_bindCustomFields();
                            if(self.displayData.transactionAddOns && self.displayData.transactionAddOns.length){
                                self.fn_checkTransactionAddOns();   
                            }

                            self.loading = false;
                        }, 
                        onError: function(error){
                            self.loading = true;
                            console.log(error);
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                        }
                    }
                );
            },

            fn_calculateAddOnsSummary: function () {


                let transactionProducts = JSON.parse(JSON.stringify(this.displayData.transactionProducts));

                let self = this;

                transactionProducts.forEach(function (item) {
                    
                    if(item.transactionProductAddOns){

                        item.transactionProductAddOns.forEach(function (addOnsItem) {

                            self.fn_checkItemExist(addOnsItem, function (itemIndex) {

                                if(itemIndex==-1){
                                    if(addOnsItem.isGst){
                                        self.gstAddOns.push(addOnsItem);
                                    }
                                    else{
                                        self.addOnsSummary.push(addOnsItem);
                                    }
                                }
                                else{
                                    if(addOnsItem.isGst){
                                        let addOnsTotalValue = self.gstAddOns[itemIndex].value + addOnsItem.value;
                                        self.gstAddOns[itemIndex].value = self.roundUpto2Decimals(addOnsTotalValue);
                                        self.$forceUpdate();
                                    }
                                    else{
                                        let addOnsTotalValue = self.addOnsSummary[itemIndex].value + addOnsItem.value;
                                        self.addOnsSummary[itemIndex].value = self.roundUpto2Decimals(addOnsTotalValue);
                                        self.$forceUpdate();
                                    }
                                }

                            })

                            console.log(self.addOnsSummary);

                        })

                    }
                    
                })          

            },

            // fn_calculateAddOnsSummary: function () {


            //  let transactionProducts = JSON.parse(JSON.stringify(this.displayData.transactionProducts));

            //  let self = this;

            //  transactionProducts.forEach(function (item) {
                    
            //      if(item.transactionProductAddOns){

            //          item.transactionProductAddOns.forEach(function (addOnsItem) {

            //              self.fn_checkItemExist(addOnsItem.addOnsId, function (itemIndex) {

            //                  if(itemIndex==-1){
            //                      self.addOnsSummary.push(addOnsItem);
            //                  }
            //                  else{
            //                      let addOnsTotalValue = self.addOnsSummary[itemIndex].value + addOnsItem.value;
            //                      self.addOnsSummary[itemIndex].value = self.roundUpto2Decimals(addOnsTotalValue);
            //                      self.$forceUpdate();
            //                  }

            //              })

            //              console.log(self.addOnsSummary);

            //          })

            //      }
                    
            //  })          

            // },

            fn_checkItemExist: function (addOnsItem, callback) {
                let itemIndex = null;

                if(addOnsItem.isGst){
                    itemIndex = this.gstAddOns.findIndex(item=>{
                        return item.addOnsId == addOnsItem.addOnsId;
                    });
                }
                else{
                    itemIndex = this.addOnsSummary.findIndex(item=>{
                        return item.addOnsId == addOnsItem.addOnsId;
                    });
                }

                callback(itemIndex);
            },

            // fn_checkItemExist: function (addOnsId, callback) {
            //  let itemIndex = this.addOnsSummary.findIndex(item=>{
            //      return item.addOnsId == addOnsId;
            //  });

            //  callback(itemIndex);
            // },

            fn_bindCustomFields: function () {
                let sec = JSON.parse(JSON.stringify(this.displayData.transactionSections));
                let sections = sec.sort(function(a, b){return a.orderNumber - b.orderNumber});
                // let sections = JSON.parse(JSON.stringify(this.displayData.transactionSections));
                let fields = JSON.parse(JSON.stringify(this.displayData.transactionFields));

                let self = this;
                sections.forEach(function (sectionItem) {
                    sectionItem.items = [];
                    fields.forEach(function (item) {

                        if(item.sectionId == sectionItem.sectionId){
                            sectionItem.items.push(item);
                        }
                    })
                    self.customFields.push(sectionItem);
                })

            },

            fn_itemsSummary: function () {
                let self = this;
                this.displayData.transactionProducts.forEach(function (item) {

                    let taxpercent = 0;
                    let taxvalue = 0;
                    item.transactionProductAddOns.forEach(function (addOnsItem) {
                        if(addOnsItem.isGst == 1){
                            taxpercent += addOnsItem.percentage;
                            taxvalue += addOnsItem.value;
                        }
                        else if(addOnsItem.isGst == 2){
                            item.cesspercent = addOnsItem.percentage;
                            item.cessvalue = addOnsItem.value;
                        }
                    });

                    item.taxpercent = taxpercent;
                    item.taxvalue = taxvalue;

                    item.productVariants.forEach(function (variantItem) {

                        let qty = variantItem.qty;

                        let itemIndex = self.itemsQuanity.findIndex(existingItem=>{
                            return existingItem.unitId == variantItem.unitId && existingItem.packQuantity == variantItem.packQuantity;
                        });

                        if(itemIndex!=-1){
                            self.itemsQuanity[itemIndex].qty += qty;
                        }
                        else{
                            let vObj = {};
                            vObj.unitId = variantItem.unitId;
                            vObj.unitName = variantItem.unitName;
                            vObj.packQuantity = variantItem.packQuantity;
                            vObj.qty = variantItem.qty;
                            self.itemsQuanity.push(vObj);
                        }
                    })
                })
            },

            fn_bindInvoiceTemplate: function(type){

                if(this.disableAction){
                    return;
                }

                // let fnName = this.inventory ? 'InvoiceData' : 'AccountBookPrintData';
                let fnName = 'InvoiceData';

                this.disableAction = true;

                let self = this;
                setTimeout(function(){
                    self.disableAction = false;
                },500);

                this.printTemplate = null;
                this.printData = null;

                // this.printTemplate = require('../../../invoice_templates/invoices/template'+this.displayData.invoiceTemplate).default;
                console.log(this.printTemplate);

                if(type=='sharewhatsapp'){
                    this.fn_shareInvoice('sharewhatsapp', fnName);
                }
                else if(type=='print'){
                    this.fn_getPrintData(fnName, this.displayData.transactionId, 'print');
                }
                else if(type=='pdf'){
                    this.fn_getPrintData(fnName, this.displayData.transactionId, 'pdf');
                }
                else if(type=='share'){
                    this.fn_shareInvoice('share', fnName);
                }
            },

            fn_checkTransactionAddOns: function(){
                for(let i=0; i<this.displayData.transactionAddOns.length; i++){
                    if(this.displayData.transactionAddOns[i].addOnsType == 1){
                        this.isDiscountAddons = true;
                    }
                    else if(this.displayData.transactionAddOns[i].addOnsType == 2){
                        this.isTaxAddons = true;
                    }
                    else if(this.displayData.transactionAddOns[i].addOnsType == 3){
                        this.isOtherChargesAddons = true;
                    }
                }
            },

            fn_getBottomMenu: function (tagId) {

                var bottomMenu = {"BottomMenu": [menuItem.createPayment, menuItem.cancelTransaction, menuItem.delete]};

                // this.inventory ? bottomMenu.BottomMenu.unshift(menuItem.preview) : '';

                this.displayData.status==0 ? bottomMenu = {"BottomMenu": [menuItem.delete]} : '';

                this.setBottomMenu(tagId, bottomMenu, 
                    {
                        onSuccess: function (response) {
                            console.log(response);
                        },
                        onError: function (error) {
                            console.log(error);
                        }
                    }
                )
            },

            fn_menuItemClick: function(tagId, menuId, menuAction){
                // tagId id form future use
                // menuId id form future use
                if(menuAction=='edit' && this.displayData && this.displayData.transactionId){
                    this.fn_edit();
                }
                else if(menuAction=='cancel'){
                    // this.fn_cancelTransaction(this.displayData.transactionId, 'display');
                    this.actionType = 'cancel';
                    this.confirmationDialogHeader = "Cancel Transaction?";
                    this.confirmationMessage = "Cancelled transaction cannot be uncancelled. Are you sure you want to cancel this transaction?"
                    this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
                }
                else if(menuAction=='delete'){
                    this.actionType = 'delete';
                    this.confirmationDialogHeader = "Delete Transaction?";
                    this.confirmationMessage = "Deleted transaction cannot be restored. Are you sure you want to delete this transaction?"
                    this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
                }
                else if(menuAction=='share'){
                    this.fn_shareInvoice('share');
                }
                else if(menuAction=='createPayment'){
                    this.$router.push({path:'/transactions/payment/items/new', query:{tagId:this.guidWeb(), id:this.displayData.partyId, name:this.displayData.partyName, dt:1}});
                }
                else if(menuAction=='preview'){
                    this.fn_previewInvoice(true, 'purchase');
                }
            },

            fn_edit: function () {
                // this.$router.push("/transactions/purchase/"+this.toolbarTitle.toLowerCase().replace(/ /g,'')+"/items/"+this.displayData.transactionId+"/edit");
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+this.displayData.transactionId+'/edit');
            },

            fn_cancel: function(){
                this.actionType = 'cancel';
                this.confirmationDialogHeader = "Cancel Transaction?";
                this.confirmationMessage = "Cancelled transaction cannot be uncancelled. Are you sure you want to cancel this transaction?"
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            fn_delete: function(){
                this.actionType = 'delete';
                this.confirmationDialogHeader = "Delete Transaction?";
                this.confirmationMessage = "Deleted transaction cannot be restored. Are you sure you want to delete this transaction?"
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            fn_closeDialog: function(){
                this.$refs['ref_dialogPermission'].dialog_permission = false;
            },

            fn_showWebView: function () {
                var viewObject = {};

                var tagId = null;
                this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';
                var self = this;

                this.showWebView(tagId, viewObject, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }, 
                        // onChange: null, 
                        onMenuItemClick: function (tagId, menuId, menuAction, itemId) {
                            self.fn_menuItemClick(tagId, menuId, menuAction, itemId)
                        }
                    }
                );
            },
        },
        mounted: function(){
            // this.fn_initializeSettings();

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_onBackPress = this.fn_goBack;

            // this.fn_showWebView();
            
            // if(this.$route.params && this.$route.params.tagId){
            //     this.fn_get(this.$route.params.tagId);
            // }

            this.$eventHub.$on('delete', () => {
                this.fn_delete();
            });

            this.$eventHub.$on('cancel', () => {
                this.fn_cancel();
            });

            this.$eventHub.$on('edit', () => {
                this.fn_edit();
            });

            this.$eventHub.$on('preview', () => {
                this.fn_edit();
            });

            // let isCalled = false;

            // this.$eventHub.$once('connected', () => {
            //     setTimeout(() => {
            //         if(this.$route.params && this.$route.params.tagId && !isCalled){
            //             isCalled = true;
            //             this.fn_get(this.$route.params.tagId);
            //         }

            //         setTimeout(() => {
            //             isCalled = false;
            //         }, 3000);
            //     }, 1000);
            // });
            
        },
        beforeRouteEnter(to, from, next){
            next(vm => {
                vm.fn_initializeSettings();
                if(to.params && to.params.tagId){
                    vm.fn_get(to.params.tagId);
                }

                let isCalled = false;

                vm.$eventHub.$once('connected', () => {
                    setTimeout(() => {
                        if(vm.$route.params && vm.$route.params.tagId && !isCalled){
                            isCalled = true;
                            vm.fn_get(vm.$route.params.tagId);
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });
            });
        },
        beforeRouteUpdate(to, from, next){
            this.$eventHub.$off('connected');
            
            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);

                }, 1000);
            });

            next(true);
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('cancel');
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('preview');
            this.$eventHub.$off('connected');
            next(true)
        },
        computed: {
            discountAddons: function(){
                return this.displayData.transactionAddOns.filter(item=>{
                    return item.addOnsType == 1;
                });
            },
            taxAddons: function(){
                return this.displayData.transactionAddOns.filter(item=>{
                    return item.addOnsType == 2;
                });
            },
            otherChargesAddons: function(){
                return this.displayData.transactionAddOns.filter(item=>{
                    return item.addOnsType == 3;
                });
            },
            totalQty: function(){
                let totalQty = 0;
                if(this.itemsQuanity){
                    for(let i=0; i<this.itemsQuanity.length; i++){
                        totalQty += this.itemsQuanity[i].qty;
                    }   
                }
                return totalQty;
            },
            roundOffValue: function(){
                if(this.displayData && this.displayData.roundOffValue){
                    return this.displayData.roundOffValue;
                }
                else{
                    return this.roundUpto2Decimals(this.displayData.roundedAmount - this.displayData.amount);
                }
            }
        },
        // beforeRouteLeave (to, from, next) {
        //     if(this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation || this.$refs['ref_dialogPermission'].dialog_permission){
        //         this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;
        //         this.$refs['ref_dialogPermission'].dialog_permission = false;
        //         next(false);
        //     }
        //     else{
        //         next(true); 
        //     }
        // }
        // computed:{
        //  transactionNumber: function () {
        //      let prefix = this.displayData.prefix ? this.displayData.prefix + '/' : '';
     //         let suffix = this.displayData.suffix ? '/' + this.displayData.suffix : '';
     //         let transactionNumber = this.displayData.transactionNumber ? this.displayData.transactionNumber : '';
     //         return prefix + transactionNumber + suffix;
        //  }
        // }
    }

</script>