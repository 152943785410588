<template>
    <div>
        <v-dialog v-model="dialog_address" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="closeDialogWeb"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>{{$parent.Form.addressData.groupTypeId == 101 ? 'Billing Address' : 'Shipping Address'}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="primary" text @click="$parent.fn_checkValidations"><v-icon left>save</v-icon> Save</v-btn> -->
                   <!--  <div>
                        <div style="float: left;margin-right: 12px;margin-top: 6px;color: rgba(0,0,0,0.57);">Primary Group</div>
                        <v-switch class="hide-margins" hide-details v-model="$parent.Form.isPrimaryAccountGroup"></v-switch>
                    </div> -->
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back">

                  <v-form>

                    <v-layout row wrap ma-0 pr-4 pl-4 white-back pt-4 pb-4>

                      <v-flex xs12 sm12 md12 form-item>
                         <!-- <v-text-field  label="Address" placeholder=" " v-model="$parent.Form.addressData.addressLine"></v-text-field> -->

                         <v-textarea autocomplete="null" rows="1" label="Address" placeholder=" " v-model="$parent.Form.addressData.addressLine"></v-textarea>
                      </v-flex>

                      <v-flex xs6 sm6 md6 form-item>

                        <v-autocomplete
                            :items="$parent.countries ? $parent.countries : []"
                            label="Country"
                            v-model="$parent.Form.selectedCountry"
                            item-value="itemName"
                            item-text="itemName"
                            autocomplete="null"
                            return-object>
                        </v-autocomplete>
                          
                      </v-flex>

                      <v-flex xs6 sm6 md6 form-item>
                         <v-text-field autocomplete="null"  label="City" placeholder=" " v-model="$parent.Form.addressData.city"></v-text-field>
                      </v-flex>

                      <v-flex xs6 sm6 md6 form-item v-if="$parent.Form.selectedCountry.itemName=='India'">

                          <v-autocomplete
                            :items="$parent.states ? $parent.states : []"
                            label="State"
                            v-model="$parent.Form.selectedState"
                            item-text="itemName"
                            item-value="itemId"
                            autocomplete="null"
                            return-object>
                        </v-autocomplete>

                      </v-flex>

                      <v-flex xs6 sm6 md6 form-item v-else>
                          <v-combobox
                            v-model="$parent.Form.addressData.state"
                            :items="$parent.Form.otherStates ? $parent.Form.otherStates : null"
                            label="State"
                            placeholder=" " 
                          ></v-combobox>
                      </v-flex>

                      <v-flex xs6 sm6 md6 form-item>
                         <v-text-field  
                            autocomplete="null"
                            label="Pincode" 
                            placeholder=" " 
                            type="number"
                            v-model.number="$parent.Form.addressData.pincode">
                        </v-text-field>
                      </v-flex>

                      <v-flex xs6 sm6 md6 form-item>
                          <v-text-field  
                            autocomplete="null"
                            label="Telephone" 
                            placeholder=" " 
                            type="tel" 
                            v-model="$parent.Form.addressData.phone">
                          </v-text-field>
                      </v-flex>
    <!-- v-validate="'verify_pincode'"  -->
                      

                      <v-flex xs6 sm6 md6 form-item v-if="$parent.Form.addressData.groupTypeId == 101">
                           <v-checkbox class="check_box" label="Shipping Address (Same as billing)" v-model="$parent.Form.addressData.isSameAddress"></v-checkbox>
                        </v-flex>
                    </v-layout>
                  </v-form>
                </div>


                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex xs12 sm12 md12 height48>
                            <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow @click="saveAddress($parent.Form.addressData)">Done</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>
                  

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
// import { required } from 'vee-validate/dist/rules'
// import { extend, ValidationProvider, setInteractionMode } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })
    export default{
        props:['closeDialog', 'saveAddress'],
        data(){
            return{
                dialog_address: false,
            }
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_address = true;
            },
            closeDialogWeb: function () {
                this.dialog_address = false;
            },
            fn_checkValidations: function () {
              this.saveAddress(this.$parent.Form.addressData);
              // let self = this;

              // let customError = false;

              // if(!this.$parent.Form.addressData.isBillingAddress && !this.$parent.Form.addressData.isShippingAddress){
              //  customError = true;
              // }

              // this.$validator.validateAll('fields').then((result) => {
              //   // if(result && !customError){
              //   if(result){
              //     this.saveAddress(this.$parent.Form.addressData);
              //   }
              //   else{
              //     let cardElement = document.querySelector('.address .v-card__text');
              //     let errorElement = document.querySelectorAll('.address .v-card__text .error--text')[0];
              //     cardElement.scrollTop = errorElement.offsetTop - 100;
              //   }
              // });
            },
        }
    }
</script>