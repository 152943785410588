<template>
    <div>
        <v-dialog v-model="dialog_preview_settings" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                
                <v-toolbar flat :height="56">
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>Preview Settings</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <template v-if="$parent.invoiceLoaded">
                        <v-menu v-if="$parent.templateNo != 101">
                            <template v-slot:activator="{ on, attrs }">
                                <!-- <div v-bind="attrs" class="mr-4 center-align" v-on="on" style="height: 24px;width: 24px;border-radius:50%" :style="{'background':$parent.themeColor}" v-ripple></div> -->
                                <!-- <v-btn small class="mr-4" text capitalize outlined :style="{'background-color':$parent.themeColor}" v-bind="attrs" v-on="on">Theme<v-icon right>arrow_drop_down</v-icon></v-btn> -->
                                <v-btn class="mr-4" text small :style="{'background-color':$parent.themeColor}" v-bind="attrs" v-on="on">
                                    Colour<v-icon font20 right>arrow_drop_down</v-icon>
                                </v-btn>
                            </template>

                            <div white-back class="pt-4 pl-1 pr-1 pb-2" style="overflow:scroll">
                                <template v-for="(color, colorIndex) in $parent.colors">
                                    <div :key="colorIndex" class="ml-3 cursor-pointer" style="height: 48px;width: 48px;border-radius: 50%;padding:11px;float:left" :class="{'hasborder':color=='#fff'}" :style="{'background':color}" @click="$parent.fn_changeColor(color)" v-ripple>
                                        <v-icon style="color:#000000 !important" v-if="$parent.themeColor==color">done</v-icon>
                                    </div>
                                </template>
                            </div>
                        </v-menu>

                        <v-menu v-if="$parent.templateNo != 101">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small class="mr-4" text capitalize outlined color="primary" v-bind="attrs" v-on="on">Template {{$parent.templateNo}} <v-icon right>arrow_drop_down</v-icon></v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item v-for="(item, index) in $parent.templates" :key="index" @click="$parent.fn_bindInvoiceTemplate(item)">
                                    <v-list-item-title>Template {{item}}</v-list-item-title>
                                    <v-list-item-icon>
                                        <v-icon color="primary">{{item==$parent.templateNo ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-btn small capitalize color="primary" @click="$parent.fn_saveTemplateSettings">Save</v-btn>
                    </template>
                    
                </v-toolbar>

                <v-divider></v-divider>

                <div class="data-container">

                    <v-progress-linear height="4" nomargin indeterminate color="primary" v-if="!$parent.invoiceLoaded"></v-progress-linear>
                    

                    <!-- <template v-if="!$route.query.printtype && $parent.invoiceLoaded">
                        <div white-back style="overflow: scroll;white-space: nowrap;padding: 16px 12px;width: 100%;">
                            <template v-for="(item, index) in $parent.templates">
                                <v-chip outline :key="index" class="mr-3" @click="$parent.fn_bindInvoiceTemplate(item)">
                                    <v-icon left color="primary" class="ma-0 mr-2 font20" v-if="item==$parent.templateNo">done</v-icon>
                                    Template {{item}}
                                </v-chip>
                            </template>
                        </div>
                        <div white-back style="padding: 0px 4px 8px;overflow:scroll">

                            <template v-for="(color, colorIndex) in $parent.colors">
                                <div :key="colorIndex" class="ml-3" style="height: 48px;width: 48px;border-radius: 50%;padding:11px;float:left" :style="{'background':color}" @click="$parent.fn_changeColor(color)" v-ripple>
                                    <v-icon style="color:#000000 !important" v-if="$parent.themeColor==color">done</v-icon>
                                </div>
                            </template>
                        </div>

                        <v-divider></v-divider>
                    </template>	 -->

                    <!-- <div style="overflow-x:scroll"> -->
                    <!-- <div grey-back style="display:flex;justify-content:center"> -->
                    <div class="grey-back pa-2">
                        <!-- <div white-background style="transform-origin: 50% 24px 0px;flex:1;" :class="[hasItems ? data.transactionDetails.roundedAmount > 9999999 ? 'transform-preview-with-items-large' : 'transform-preview-with-items' : 'transform-preview']"> -->
                        <div white-back class="invoice-preview-settings" :class="{'visibility-hidden':!$parent.invoiceLoaded && $parent.templateNo!=101, 'pa-2':$parent.invoiceLoaded}">
                            <component :is="$parent.previewTemplate" ref="ref_previewTemplate" :data="$parent.data" :preview="true" :isEditable="true" :editParty="$parent.fn_editPartyDetails" :editBusiness="$parent.fn_editBusinessProfile" :editLogo="$parent.fn_openDialogBusinessLogo" :editTransactionFields="$parent.fn_editTransactionFields" :editPrintField="$parent.fn_editPrintField" :openToggleOptions="$parent.fn_openToggleOptions" :themeColor="$parent.themeColor" :gridClass="$parent.gridClass" v-if="$parent.previewTemplate && $parent.data!=null"></component>
                        </div>	
                    </div>
                    

                    <!-- <v-footer style="height:auto;position:fixed;bottom:0;width:100%" v-if="!$route.query.printtype && $parent.invoiceLoaded">
                        <v-layout grid wrap text-xs-center>
                            <v-flex xs12 sm12 md12>
                                <v-btn class="height48" color="primary" nomargin block no-round-borders noshadow @click="$parent.fn_saveTemplateSettings">Save Theme</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-footer> -->
                </div>

                <!-- <v-card-actions white-back>
                    <v-layout row wrap ma-0>
                        <v-flex xs12 sm12 md12 height48>
                            <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow @click="fn_saveTemplateSettings">Save Theme</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions> -->
                  

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
// import { required } from 'vee-validate/dist/rules'
// import { extend, ValidationProvider, setInteractionMode } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })
    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_preview_settings: false
            }
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_preview_settings = true;
                this.$parent.disableOnSave = false;
            },
            closeDialogWeb: function () {
                this.dialog_preview_settings = false;
            }
        }
    }
</script>