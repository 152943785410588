<template>
  <div>
      <v-dialog v-model="dialog_shortcuts" no-click-animation scrollable persistent max-width="75%" content-class="full-height" transition="dialog-bottom-transition">
          <v-card>
                <v-subheader class="pt-2 pb-2">
                    Keyboard Shortcuts <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                </v-subheader>
                <v-divider></v-divider>
                <v-card-text>

                    <template v-for="(group, groupIndex) in items">
                        <v-subheader justify-center class="primary-color" :key="groupIndex">{{group.title}}</v-subheader>
                        <v-layout ma-0 center-align row wrap :key="group.title">
                            
                                <v-flex :class="{'no-right-border':(index+1)%4==0}" style="border-right:1px #f0f0f0 solid" pa-3 xs1 sm4 md3 v-for="(item, index) in group.items" :key="index">
                                    <div>{{ item.title }}</div>
                                    <div><strong>"{{item.shortcut}}"</strong></div>
                                </v-flex>
                            
                        </v-layout>
                    </template>

                    <!-- <v-list dense>
                        <template v-for="(group, groupIndex) in items">
                            <v-divider :key="groupIndex" v-if="groupIndex!=0"></v-divider>
                            <v-subheader :key="group.title">{{group.title}}</v-subheader>
                            <v-divider :key="groupIndex"></v-divider>
                            <v-list-item v-for="(item, index) in group.items" :key="index">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <strong>"{{item.shortcut}}"</strong>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        
                    </v-list> -->

                </v-card-text>
                <!-- <v-divider></v-divider> -->
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="blue darken-1" text @click="closeDialogWeb">close</v-btn>
                </v-card-actions> -->
            </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        dialog_shortcuts:false,
            items:[
                    {
                        "title":"New Form Shortcuts",
                        "items":[
                            { title: 'Sale Invoice', shortcut:'Alt + S' },
                            { title: 'Purchase Invoice', shortcut:'Alt + P' },
                            { title: 'Payment In', shortcut:'Alt + I' },
                            { title: 'Payment Out', shortcut:'Alt + 0' },
                            { title: 'Sales Return', shortcut:'Alt + R' },
                            { title: 'Purchase Return', shortcut:'Alt + D' },
                            { title: 'Estimate', shortcut:'Alt + Q' },
                            { title: 'Expense', shortcut:'Alt + E' },
                            { title: 'Party', shortcut:'Alt + L' },
                            { title: 'Item', shortcut:'Alt + M' }
                        ]
                    },
                    {
                        "title":"Form Action Shortcuts",
                        "items":[
                            { title: 'Add Party', shortcut:'Shift + L' },
                            { title: 'Add Item', shortcut:'Shift + M' },
                            { title: 'Save', shortcut:'Ctrl + S' },
                            { title: 'Close', shortcut:'Esc' }
                        ]
                    },
                    {
                        "title":"Display View Action Shortcuts",
                        "items":[
                            { title: 'Cancel', shortcut:'Ctrl + X' },
                            { title: 'Delete', shortcut:'Ctrl + Delete' },
                            { title: 'Edit', shortcut:'Ctrl + E' },
                            { title: 'Print', shortcut:'Ctrl + P' },
                            { title: 'Download PDF', shortcut:'Ctrl + D' }
                        ]
                    }
                ],
            right: null,
        }
    },
    methods:{
      openDialogWeb: function () {
        this.dialog_shortcuts = true;
      },
      closeDialogWeb: function () {
        this.dialog_shortcuts = false;
      },
    }
  }
</script>