<template>
    <div>
        <v-dialog v-model="dialog_settings" no-click-animation persistent content-class="full-height white-back" max-width="460px">

            <v-card full-height>
                <v-toolbar flat :height="56">
                    <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>Transaction Settings</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider> 

                <v-card-text nopadding>
                    <div class="switches" white-background>
                        <v-list two-line nopadding>
                            <v-list-item>

                                <v-list-item-content>
                                    <v-list-item-title>Number Series</v-list-item-title>
                                    <v-list-item-subtitle>Enable type wise for Sale Invoice</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch style="padding: 0 !important;" @change="fn_toggleNumberSeriesTypeWise" v-model="TransactionSettings.typeWiseNumberSeries" nomargin></v-switch>
                                </v-list-item-action>

                            </v-list-item>
                        </v-list>
                    </div>
                </v-card-text>
            </v-card>   
        </v-dialog>
    </div>
</template>     

<script>

const accountGroupIds = require('../../../config.js').configs.accountGroupIds;

export default{
    props:['closeDialog'],
    data(){ 
        return{
            dialog_settings:false,
            TransactionSettings:{"businessId":null, "branchDivision":false, "beatRouteArea":false, "mrpWiseStock":false, "disableNegativeStock":false, "gstType":1, "typeWiseNumberSeries":false},
        }
    },
    methods:{
        openDialogWeb: function () {
            this.$root.fn_getTransactionSettings(()=>{
                this.TransactionSettings = JSON.parse(JSON.stringify(this.$root.TransactionSettings));
                this.fn_checkTaxMode();
            });
            // this.TransactionSettings.mrpWiseStock = this.$root.TransactionSettings.mrpWiseStock;
            this.dialog_settings = true;
        },

        closeDialogWeb: function () {
            this.dialog_settings = false;
        },

        fn_checkTaxMode: function(){
            let self = this;
            let filterObject = {};
            filterObject.transactionTypeId = [10001, 10002, 10003, 10004, 100005];
            filterObject.taxMode = this.TransactionSettings.gstType;
            this.getFilterData('CheckTaxMode', null, filterObject, null,
                {
                    onSuccess: function(response){
                        console.log(response);
                        let isSimilar = JSON.parse(response);
                        !isSimilar ? self.TransactionSettings.gstType = -1 : '';
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_toggleNumberSeriesTypeWise: function(val){
            let self = this;
            let typeArr = null;
            let title = "Tax Invoice";
            let taxMode = 1;
            if(this.$root.CurrentBusiness.GSTRegistrationStatus == 2){
                title = "Bill of Supply";
                taxMode = 0;
            }
            else if(this.$root.CurrentBusiness.GSTRegistrationStatus == 3){
                title = "Invoice";
                taxMode = 0;
            }
            if(val){
                typeArr = [
                    // Retail Invoice
                    {"accountData":null,"additionalData":null,"central":true,"centralTransactionAccountId":"41f99d4b-e806-422b-8036-8ecb8ba3e24d","fieldsData":null,"importExport":false,"importExportTransactionAccountId":null,"inventory":null,"invoiceType":1,"isAllPartySelected":false,"isRouteApplicable":false,"local":true,"localTransactionAccountId":"90470ef2-7d52-4707-80d4-24c4cb28d768","name":"Retail Invoice","numberSeriesId":"a431fe26-0dc5-4e6d-981d-45bd1ce24a3b","paymentMode":1,"salesGroupType":1,"taxMode":taxMode,"title":title,"tradingType":0,"transactionNature":null,"transactionTypeFormId":"33e66294-8148-1b9e-0090-50aa87b20cb5","transactionTypeId":10003,"isSystem":1,"isDefault":true},
                    // Wholesale Invoice
                    {"accountData":null,"additionalData":null,"central":true,"centralTransactionAccountId":"41f99d4b-e806-422b-8036-8ecb8ba3e24d","fieldsData":null,"importExport":false,"importExportTransactionAccountId":null,"inventory":null,"invoiceType":1,"isAllPartySelected":false,"isRouteApplicable":false,"local":true,"localTransactionAccountId":"90470ef2-7d52-4707-80d4-24c4cb28d768","name":"Wholesale Invoice","numberSeriesId":"690f3302-ce57-4439-b739-b90d1d257789","paymentMode":1,"salesGroupType":1,"taxMode":taxMode,"title":title,"tradingType":1,"transactionNature":null,"transactionTypeFormId":"757c506e-9a73-e012-2632-4c29b0c0ac18","transactionTypeId":10003,"isSystem":1,"isDefault":false},
                    // Export Invoice
                    {"accountData":null,"additionalData":null,"central":false,"centralTransactionAccountId":null,"fieldsData":null,"importExport":true,"importExportTransactionAccountId":"daf504a8-8355-4985-b414-505471c895b1","inventory":null,"invoiceType":1,"isAllPartySelected":false,"isRouteApplicable":false,"local":false,"localTransactionAccountId":null,"name":"Export Invoice","numberSeriesId":"db95e074-d5e6-43c4-af4e-41e7cb101d84","paymentMode":1,"salesGroupType":1,"taxMode":taxMode,"title":title,"tradingType":1,"transactionNature":null,"transactionTypeFormId":"5ed488ce-ed95-6d93-96d0-3cc463951983","transactionTypeId":10003,"isSystem":1,"isDefault":false}
                ]
            }
            else{
                typeArr = [
                    // Retail Invoice
                    {"accountData":null,"additionalData":null,"central":true,"centralTransactionAccountId":"41f99d4b-e806-422b-8036-8ecb8ba3e24d","fieldsData":null,"importExport":false,"importExportTransactionAccountId":null,"inventory":null,"invoiceType":1,"isAllPartySelected":false,"isRouteApplicable":false,"local":true,"localTransactionAccountId":"90470ef2-7d52-4707-80d4-24c4cb28d768","name":"Retail Invoice","numberSeriesId":"1af6f388-e3c1-2cba-11df-7138cd7a5c1e","paymentMode":1,"salesGroupType":1,"taxMode":taxMode,"title":title,"tradingType":0,"transactionNature":null,"transactionTypeFormId":"33e66294-8148-1b9e-0090-50aa87b20cb5","transactionTypeId":10003,"isSystem":1,"isDefault":true},
                    // Wholesale Invoice
                    {"accountData":null,"additionalData":null,"central":true,"centralTransactionAccountId":"41f99d4b-e806-422b-8036-8ecb8ba3e24d","fieldsData":null,"importExport":false,"importExportTransactionAccountId":null,"inventory":null,"invoiceType":1,"isAllPartySelected":false,"isRouteApplicable":false,"local":true,"localTransactionAccountId":"90470ef2-7d52-4707-80d4-24c4cb28d768","name":"Wholesale Invoice","numberSeriesId":"1af6f388-e3c1-2cba-11df-7138cd7a5c1e","paymentMode":1,"salesGroupType":1,"taxMode":taxMode,"title":title,"tradingType":1,"transactionNature":null,"transactionTypeFormId":"757c506e-9a73-e012-2632-4c29b0c0ac18","transactionTypeId":10003,"isSystem":1,"isDefault":false},
                    // Export Invoice
                    {"accountData":null,"additionalData":null,"central":false,"centralTransactionAccountId":null,"fieldsData":null,"importExport":true,"importExportTransactionAccountId":"daf504a8-8355-4985-b414-505471c895b1","inventory":null,"invoiceType":1,"isAllPartySelected":false,"isRouteApplicable":false,"local":false,"localTransactionAccountId":null,"name":"Export Invoice","numberSeriesId":"1af6f388-e3c1-2cba-11df-7138cd7a5c1e","paymentMode":1,"salesGroupType":1,"taxMode":taxMode,"title":title,"tradingType":1,"transactionNature":null,"transactionTypeFormId":"5ed488ce-ed95-6d93-96d0-3cc463951983","transactionTypeId":10003,"isSystem":1,"isDefault":false}
                ]
            }

            let postObj = {"transactionsType":typeArr};
            
            this.postData('TransactionTypeList', postObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_post();
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_post: function(){
            let self = this;
            let postObj = JSON.parse(JSON.stringify(this.TransactionSettings))
            //for gstType/taxmode update
            postObj.transactionTypeId = [10001, 10002, 10003, 10004, 100005];
            postObj.taxMode = this.TransactionSettings.gstType;
            //for branch/division status update
            postObj.accountGroupId = accountGroupIds.brandAndDivision.id;
            postObj.status = this.TransactionSettings.branchDivision ? 1 : 2;

            this.postData('TransactionSettings', postObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$root.fn_getTransactionSettings(()=>{
                            self.$eventHub.$emit('snackbarAlert', {msg:'Transaction settings updated'});
                        });
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        }
    }
}
</script>