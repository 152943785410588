<template>
    <div class="display_wrapper">

        <v-toolbar flat :height="56">

            <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>

            <v-toolbar-title>Set Balance</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn class="ml-4" small outlined @click="fn_openDialogConfirmation" color="red" v-if="displayData && !displayData.isSystem">
                <v-icon left>delete</v-icon> Delete
            </v-btn>

        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back" v-if="displayData && ledgerData">
            
            <v-list class="nopadding" two-line>
                <div>
                    <v-list-item>
                          <v-list-item-content>
                              <v-list-item-title>Party</v-list-item-title>
                              <v-list-item-subtitle>{{displayData.party}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>
                <div>
                    <v-list-item>
                          <v-list-item-content>
                              <v-list-item-title>Date</v-list-item-title>
                              <v-list-item-subtitle>{{displayData.transactionDate | moment("DD-MMM-YYYY")}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>
                <div v-if="ledgerData">
                       <v-list-item>
                          <v-list-item-content>
                              <v-list-item-title>Amount</v-list-item-title>
                              <v-list-item-subtitle>&#8377; {{$root.numberToLocale(displayData.amount)}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                   </div>

            </v-list>

        </div>

        <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);" v-if="loading">
          <v-col cols="6">
            <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
          </v-col>
        </v-row>

        <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" header="Delete Transaction?" confirmationMessage="Deleted transaction cannot be restored. Are you sure you want to delete this transaction?" :actionMethod="fn_callCancelTransaction" params="delete" :displayView='true' okBtnText="Yes" cancelBtnText="No" icon="warning" iconClass="redcolor" textClass="redcolor"></dialog-confirmation>

    </div>
</template>

<script>

import dialogConfirmation from '../../../dialogs/dialog_confirmation'
import {transactionMixin} from '../../transaction_mixin'

    export default{
        props:['settings'],
        mixins: [transactionMixin],
        data(){
            return{
                displayData:null,
                ledgerData:null,
                loading:false,
                amountType:null,
                confirmationMessage:null
            }
        },
        components:{
         'dialog-confirmation': dialogConfirmation
        },
        methods:{

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_openDialogConfirmation: function(){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            fn_callCancelTransaction: function(){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;

                let self = this;
                this.fn_deleteTransaction(this.displayData.transactionId, this.displayData.transactionListId, function(status){
                    if(status){
                        // alert("Opening ledger of " + self.displayData.accountLedgerName + " has been deleted");
                        self.$eventHub.$emit('snackbarAlert', {msg:"Set balance entry deleted"});
                        self.$eventHub.$emit('UpdateLedgerList', {});
                        self.fn_goBack();
                    }
                    else{
                        // alert("Something went wrong. Try again");
                        self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Try again"});
                    }
                });
            },

            fn_get: function (itemId) {
                this.displayData = null;
                this.loading = true;

                let self = this;

                this.getData('GetPartySetBalance', itemId,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.displayData = JSON.parse(response);
                            if(self.displayData.transactionLedgers && self.displayData.transactionLedgers.length){
                                self.amountType = self.displayData.transactionLedgers[0].amountType == 1 ? '(Positive)' : '(Negative)';
                            }

                            self.fn_getLedgerData(self.displayData.partyId);
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                          self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                        }
                    }
                );
            },

            fn_getLedgerData: function(selectedValueId){
                let self = this;
                this.getData('Ledger', selectedValueId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.ledgerData = JSON.parse(response);
                            self.loading = false;
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.loading = false;
                        }
                    }
                );
            },
        },
        mounted: function(){     
            if(this.$route.params && this.$route.params.tagId){
                this.fn_get(this.$route.params.tagId);
            }

            this.$eventHub.$on('delete', () => {
                this.fn_openDialogConfirmation();
            });
        },
        beforeRouteUpdate(to, from, next){
            console.log(from);
            next(true)
            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }  
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            next(true)
        },
    }

</script>