<template>
     <div style="margin:0;">
        <!-- <router-view/> -->
        <container />
     </div>
        <!-- <hello-world /> -->
</template>

<script>
  // import HelloWorld from '../components/HelloWorld'
  import container from './container'

  export default {
    // name: 'Home',

    components: {
      // HelloWorld,
      container
    },
    data(){
        return{
            drawer:true,
            mini:true
        }
    }
  }
</script>
