<template>
    <div>
        <v-dialog v-model="dialog_confimation" persistent no-click-animation max-width="300">
          <v-card flat>
            <v-card-title class="title noshadow" :class="[textClass ? textClass : '']">
                <v-icon v-if="icon" :class="[iconClass ? iconClass : '']">{{icon}}</v-icon>
                <span :class="[icon ? 'ml-4' : '', textColor ? textColor : '']">{{header}}</span>
            </v-card-title>
            <v-card-text class="pt-0" v-html="confirmationMessage" :class="[textClass ? textClass : '']"></v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-layout row wrap ma-0>
                    <v-flex xs6 sm6 md6 center-align style="border-right:1px #ddd solid">
                        <v-btn small font-heavy dark-opacity-color text @click="fn_closeDialog">{{cancelBtnText ? cancelBtnText : 'Close'}}</v-btn>
                    </v-flex>
                    <v-flex xs6 sm6 md6 center-align>
                        <v-btn small font-heavy class="primary-color" text @click="actionMethod(params)">{{okBtnText ? okBtnText : 'Ok'}}</v-btn>
                    </v-flex>
                </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['header', 'confirmationMessage', 'actionMethod', 'cancelMethod', 'displayView', 'params', 'okBtnText', 'cancelBtnText', 'icon', 'iconClass', 'textColor', 'textClass'],
    data(){
        return{
            dialog_confimation: false,
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_confimation = true;
        },
        closeDialogWeb: function () {
            this.dialog_confimation = false;
        },
        fn_closeDialog: function(){
            if(this.cancelMethod){
                this.cancelMethod();
            }
            else if(this.displayView){
                this.closeDialogWeb();
            }
            else{
                this.$parent.fn_closeDialog();
            }
        }
    }
}
</script>