<template>
	<div>

        <v-toolbar flat :height="56" z-index="9">
            <v-toolbar-title>Business Settings</v-toolbar-title>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back">

           <!--  <v-alert style="height:48px" :value="true" color="info pt-0 pb-0" :class="{'pr-0':!isBackupRunning}" transition="slide-y-transition" v-if="$root.unsyncedItemsCount">
              <v-layout row wrap style="align-items:center">
                <v-flex xs8 sm8 md8>{{$root.unsyncedItemsCount}} unsync {{$root.unsyncedItemsCount == 1 ? 'record' : 'records'}}</v-flex>
                <v-flex xs4 sm4 md4 right-align>
                  <template v-if="isBackupRunning && $root.fn_internetStatus()">
                    <v-progress-circular :size="24" :width="2" indeterminate color="primary"></v-progress-circular>
                    <div style="font-size:8px">Data sync in progress...</div>
                  </template>
                  <v-btn capitalize flat v-else @click="fn_syncNow">Sync Now</v-btn>
                </v-flex>
              </v-layout>
            </v-alert> -->

            <v-list two-line dense class="pa-0">
                
                <v-list-item @click="fn_businessProfile">
                    <v-list-item-action>
                        <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Business Profile</v-list-item-title>
                        <v-list-item-subtitle>Edit/Update business details</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_openDialogUpi">
                    <v-list-item-action>
                        <v-icon>account_balance</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>UPI Payment</v-list-item-title>
                        <v-list-item-subtitle>Add UPI payment link (PayTM, PhonePe, Google Pay)</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_openDialogBusinessLogo">
                    <v-list-item-action>
                        <v-icon>add_a_photo</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Business Logo</v-list-item-title>
                        <v-list-item-subtitle>Add your business logo</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_openDialogOpeningDate">
                    <v-list-item-action>
                        <v-icon>date_range</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Opening Date</v-list-item-title>
                        <v-list-item-subtitle>Set/Update opening date</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_itemSettings">
                    <v-list-item-action>
                        <v-icon>category</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Item Settings</v-list-item-title>
                        <v-list-item-subtitle>Update your item settings</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <template v-if="$root.appVersion >= 128">
                  <v-divider></v-divider>

                  <v-list-item @click="fn_dialogOpenCacheSettings">
                      <v-list-item-action>
                          <v-icon>cached</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title>Cache Settings</v-list-item-title>
                          <v-list-item-subtitle>Reset items and party cache</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                          <v-icon>keyboard_arrow_right</v-icon>
                      </v-list-item-action>
                  </v-list-item>
                </template>

                <v-divider></v-divider>

                <v-list-item @click="fn_transactionSettings" v-if="$root.appVersion >= 109">
                    <v-list-item-action>
                        <v-icon>settings</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Transaction Settings</v-list-item-title>
                        <v-list-item-subtitle>Update your transaction settings</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_printFields">
                    <v-list-item-action>
                        <v-icon>text_fields</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Print Fields</v-list-item-title>
                        <v-list-item-subtitle>Update printing content of bill/invoice</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_openDialogDataBackup">
                    <v-list-item-action>
                        <v-icon>backup</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Data Backup</v-list-item-title>
                        <v-list-item-subtitle>Backup data, change backup drive</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

            </v-list>
       </div>

       <dialog-payment ref="ref_dialogPayment"></dialog-payment>
       <dialog-opening ref="ref_dialogDialogPayment"></dialog-opening>
       <dialog-logo ref="ref_dialogLogo"></dialog-logo>
       <dialog-data-backup ref="ref_dialogDataBackup"></dialog-data-backup>
       <dialog-cache-settings ref="ref_dialogCacheSettings"></dialog-cache-settings>
       <dialog-action ref="ref_dialogAction" :title="dialogActionTitle" :message="dialogActionMessage" :actionMethod="fn_closeDialogAction" btnText="okay"></dialog-action>

       <!-- <dialog-rating ref="ref_dialogRating" :closeDialog="fn_closeDialogRating" :save="fn_saveRating"></dialog-rating> -->

</div>
</template>

<script>
// let tagId = 'settings'

// import VRuntimeTemplate from "v-runtime-template";

// import runtimetest from './runtimetest'

// import reportDialog from '../newreports/dialogs/dialog_gstr_report'
// import {openingDateMixin} from './opening_date_mixin'
// import dialogDatePickerOpening from '../transactions/forms/dialogs/dialog_datepicker_opening'
// import dialogRating from '../dialogs/dialog_rating'
// const navigationMenu = require('../../config.js').configs.bottomNavigation;

import dialogPayment from './payment/dialog_payment'
import dialogOpeningDate from './opening_date/dialog_form'
import dialogLogo from './business/dialogs/dialog_logo'
import dialogDataBackup from './backup_data/dialog_data_backup'
import dialogAction from '../dialogs/dialog_action.vue'

import dialogCacheSettings from './dialogs/dialog_cache_settings.vue'

  export default{
    // mixins: [openingDateMixin],
    data(){
      return{
        bottomsheet:false,
        shortBusinessName :null,
        isTemporarySetup:false,
        userType:null,
        isBackupRunning:false,
        dialogActionTitle:null,
        dialogActionMessage:null
        // unreadTicketObj:null
      }
    },
    components:{
      // 'report-dialog': reportDialog,
      // 'dialog-datepicker-opening':dialogDatePickerOpening
      // 'dialog-rating':dialogRating
      'dialog-payment':dialogPayment,
      'dialog-opening':dialogOpeningDate,
      'dialog-logo':dialogLogo,
      'dialog-data-backup':dialogDataBackup,
      'dialog-cache-settings':dialogCacheSettings,
      'dialog-action':dialogAction
    },

    methods:{

      fn_itemSettings: function(){
        this.dialogActionTitle = "Item Settings";
        this.dialogActionMessage = "Use mobile app to change or update item settings."
        this.fn_openDialogAction();
        // this.$eventHub.$emit('snackbarAlert', {msg:'Use mobile app to update item settings'});
        // this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/' + this.$route.params.listId + '/settings/transaction'})
      },

      fn_transactionSettings: function(){
        this.dialogActionTitle = "Transaction Settings";
        this.dialogActionMessage = "Use mobile app to change or update transaction settings."
        this.fn_openDialogAction();
        // this.$eventHub.$emit('snackbarAlert', {msg:'Use mobile app to update transaction settings'});
        // this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/' + this.$route.params.listId + '/settings/transactionnew'})
      },

      fn_openDialogAction: function(){
        this.$refs['ref_dialogAction'].openDialogWeb();
      },

      fn_closeDialogAction: function(){
        this.$refs['ref_dialogAction'].closeDialogWeb();
      },

      fn_businessProfile: function(){
        this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/' + this.$route.params.listId + '/business/profile/edit'});
      },

      fn_dialogOpenCacheSettings: function(){
        this.$refs['ref_dialogCacheSettings'].openDialogWeb();
      },

      fn_openDialogUpi: function(){
        this.$refs['ref_dialogPayment'].openDialogWeb();
      },

      fn_openDialogOpeningDate: function(){
        this.$refs['ref_dialogDialogPayment'].openDialogWeb();
      },

      fn_openDialogBusinessLogo: function(){
        this.$refs['ref_dialogLogo'].openDialogWeb();
      },

      fn_openDialogDataBackup: function(){
        if(!this.$root.CurrentBusiness.DataDrive){
          this.$eventHub.$emit('snackbarAlert', {msg:'Set up backup drive in mobile app to backup data'});
          return;
        }
        this.$refs['ref_dialogDataBackup'].openDialogWeb();
      },

      fn_printFields: function(){
        this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/print_fields'});
      },

      fn_syncNow: function(){
        if(!navigator.onLine){
          this.showToast("Internet not available");
          return;
        }
        
        this.isBackupRunning = true;
        this.$root.fn_startSync();
      },

      fn_openDialogRating: function(){
        this.$refs['ref_dialogRating'].dialog_rating = true;
      },

      fn_saveRating: function(rate){
        if(rate<4){
          this.$router.push({path:'/settings/feeback/'+rate});
          // this.showToast("Kindly give your feedback");
          // this.showToast("Your feedback is valuable to us");
        }
        else{
          console.log('open play store');
        }
      },

      fn_closeDialogRating: function(){
        this.$refs['ref_dialogRating'].dialog_rating = false;
      },

      fn_getUserType : function(){
        let self = this;
        this.getData('UserType', null, 
            {
              onSuccess: function(response){
                  console.log(response);
                  self.userType = response;
              }, 
              onError: function(error){
                  console.log(error);
                  self.userType = null;
              }
            }
        );
      },

      fn_reopenDB: function(businessid){
            let self = this;
            let bid = businessid && businessid != "null" ? businessid : this.$root.CurrentBusiness.BusinessID;
            this.getData('ReopenDataBase', bid, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        console.log("Restore Complete");
                        self.$root.fn_getBusiness(null, null, function(){
                          console.log("Get business data via AllBusiness");
                          // checking is temporary business setup or not
                          // self.fn_checkTemporarySetup();
                        });
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

      fn_startStopService: function(serviceType){
          // let self = this;
          this.getData(serviceType, null, 
              {
                  onSuccess: function(response){
                      console.log(response);
                  }, 
                  onError: function(error){
                      console.log(error);
                  }
              }
          );
      },

      fn_changeBusiness: function(){
        this.$router.push({path:"/settings/business/mybusiness"});
      },

      fn_sendFeedback: function(){
        this.$router.push({path:"/settings/feeback/3"});
      },

      fn_shareApp: function(){
        // let self = this;
        this.getData('ShareApp', null, 
            {
                onSuccess: function(response){
                    console.log(response);
                }, 
                onError: function(error){
                    console.log(error);
                }
            }
        );
      },

      fn_closeDialog: function(){
        this.$refs['ref_dialogDatePickerOpening'].datepicker = false;
      },

      fn_clearData: function(){

        if(!this.$root.CurrentBusiness.DataDrive){
          this.showToast("Please first set up backup drive");
          return;
        }

        this.$router.push({path:"/settings/cleardata"});
      },

    },
    mounted: function () { 

      this.fn_getUserType();

      if(this.$route.query.sync){
        this.fn_syncNow();
      }

      let isCalled = false;
      this.$eventHub.$on('connected', () => {
          setTimeout(() => {
              if(!isCalled){
                  isCalled = true;
                  this.fn_getUserType();
                  this.$route.query.sync ? this.fn_syncNow() : '';
                  if(this.$refs['ref_dialogPayment']){
                    this.$refs['ref_dialogPayment'].loading = false;
                    this.$refs['ref_dialogPayment'].saveBtnKey++;
                  }
              }

              setTimeout(() => {
                isCalled = false;
              }, 3000);
          }, 1000);
      });

      this.$eventHub.$on('cachereset', ()=>{
        // this.$eventHub.$emit('snackbarAlert', {msg:'Reset cache completed'});
        if(this.$refs['ref_dialogCacheSettings'] && this.$refs['ref_dialogCacheSettings'].dialog_cache_settings){
          this.$refs['ref_dialogCacheSettings'].dialog_cache_settings = false;
        }
      });

    },
    beforeRouteLeave (to, from, next) {
      this.$eventHub.$off('connected');
      next();
    }
  }
</script>