<template>
    <div>
        <v-dialog v-model="dialog_crop_image" no-click-animation persistent scrollable content-class="full-height" lazy transition="slide-y-transition" max-width="460px">
            <v-card>
                <v-card-text full-height style="padding:20px;">
                    <vue-croppie ref="croppieRef" :enableOrientation="true" :boundary="{ width: 260, height: 250}" :viewport="{ width:200, height:200, 'type':'square' }" :enableResize="false">
                    </vue-croppie>

                    <div center-align>
                        <!-- <v-btn flat icon @click="fn_rotate(-90)"><v-icon>rotate_left</v-icon></v-btn> -->
                        <v-btn text outlined @click="fn_rotate(90)">Rotate <v-icon right>rotate_right</v-icon></v-btn>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="blue darken-1" text @click="dialog_crop_image=false">cancel</v-btn>
                    <v-btn small color="blue darken-1" text @click="fn_cropImage">done</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>

    export default{
        props:['uploadLogo'],
        data(){
            return{
                dialog_crop_image:false,
                cropped:null
            }
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_crop_image = true;
            },
            closeDialogWeb: function () {
                this.dialog_crop_image = false;
            },
            fn_rotate(rotationAngle) {
                // Rotates the image
                this.$refs.croppieRef.rotate(rotationAngle);
            },
            // https://github.com/jofftiquez/vue-croppie
            fn_cropImage() {
              // Options can be updated.
              // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
              let options = {
                type: 'base64',
                size: { width: 200, height: 200 },
                format: 'jpeg'
              };
              this.$refs.croppieRef.result(options, output => {
                  console.log(output);
                  this.uploadLogo(output);
                });
             },
        },
    }
</script>