var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"content-class":"half-screen-dialog","no-click-animation":"","scrollable":"","persistent":"","transition":"slide-y-transition","max-width":_vm.$root.fn_getActionPaneWidth()},model:{value:(_vm.dialog_form),callback:function ($$v) {_vm.dialog_form=$$v},expression:"dialog_form"}},[_c('v-card',{attrs:{"no-border-radius":"","grey-back":""}},[_c('v-toolbar',{attrs:{"height":56,"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-toolbar-title',[_vm._v("Business Profile")])],1),_c('v-divider',{staticClass:"noprint"}),_c('div',{staticClass:"data-container grey-back"},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-layout',{attrs:{"row":"","wrap":"","ma-0":"","pb-3":"","white-back":""}},[_c('v-flex',{attrs:{"form-item":"","xs6":"","sm6":"","md6":"","pt-4":""}},[_c('ValidationProvider',{attrs:{"name":"Business Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loginType != 'self' || _vm.$parent.disableOnSave,"label":"Business Name","autocomplete":"null","error-messages":errors,"required":""},model:{value:(_vm.$parent.Form.BusinessName),callback:function ($$v) {_vm.$set(_vm.$parent.Form, "BusinessName", $$v)},expression:"$parent.Form.BusinessName"}})]}}])})],1),_c('v-flex',{attrs:{"form-item":"","xs6":"","sm6":"","md6":"","pt-4":""}},[_c('ValidationProvider',{attrs:{"name":"Business Owner Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loginType != 'self' || _vm.$parent.disableOnSave,"label":"Business Owner Name","autocomplete":"null","error-messages":errors,"required":""},model:{value:(_vm.$parent.Form.UserName),callback:function ($$v) {_vm.$set(_vm.$parent.Form, "UserName", $$v)},expression:"$parent.Form.UserName"}})]}}])})],1),_c('v-flex',{attrs:{"form-item":"","xs12":"","sm12":"","md12":"","pt-4":""}},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"disabled":_vm.$parent.disableOnSave,"label":"Address (will appear on invoice and bill prints)","rows":"1","autocomplete":"null","error-messages":errors,"counter":"250","required":""},model:{value:(_vm.$parent.Form.Address),callback:function ($$v) {_vm.$set(_vm.$parent.Form, "Address", $$v)},expression:"$parent.Form.Address"}})]}}])})],1),_c('v-flex',{attrs:{"form-item":"","xs6":"","sm6":"","md6":"","pt-4":""}},[_c('ValidationProvider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.$parent.disableOnSave,"items":_vm.$root.states ? _vm.$root.states : [],"label":"State/Place of Supply","item-value":"itemId","item-text":"itemName","autocomplete":"null","return-object":"","required":"","error-messages":errors},model:{value:(_vm.$parent.selectedState),callback:function ($$v) {_vm.$set(_vm.$parent, "selectedState", $$v)},expression:"$parent.selectedState"}})]}}])})],1),_c('v-flex',{attrs:{"form-item":"","xs6":"","sm6":"","md6":"","pt-4":""}},[_c('ValidationProvider',{attrs:{"name":"GST registration status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.$parent.disableOnSave,"items":_vm.$parent.registrationTypes,"label":"GST Registration Status","item-value":"status","item-text":"name","return-object":"","required":"","error-messages":errors},on:{"change":_vm.fn_updateRegistrationType},model:{value:(_vm.$parent.selectedRegistationType),callback:function ($$v) {_vm.$set(_vm.$parent, "selectedRegistationType", $$v)},expression:"$parent.selectedRegistationType"}})]}}])})],1),(_vm.$parent.selectedRegistationType && (_vm.$parent.selectedRegistationType.status == 1 || _vm.$parent.selectedRegistationType.status == 2))?_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","form-item":"","pt-4":""}},[_c('ValidationProvider',{attrs:{"name":"GST Number","rules":"required|verify_gstnumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.$parent.disableOnSave,"uppercase":"","label":"GST Number (GSTIN)","autocomplete":"null","placeholder":" ","hide-details":!errors || !errors.length,"error-messages":errors},on:{"input":_vm.fn_gstNumberInUpperCase},model:{value:(_vm.$parent.Form.GSTNumber),callback:function ($$v) {_vm.$set(_vm.$parent.Form, "GSTNumber", $$v)},expression:"$parent.Form.GSTNumber"}})]}}],null,false,405572680)}),_c('div',{staticClass:"pt-1",attrs:{"right-align":"","font12":"","light-opacity-color":""}},[_vm._v("Example: 12ABCDE1234A9Z9")])],1):_vm._e(),(_vm.$parent.selectedRegistationType && _vm.$parent.selectedRegistationType.status == 1)?_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","form-item":"","pt-4":""}},[_c('v-select',{attrs:{"disabled":_vm.$parent.disableOnSave,"row":"","label":"Tax","items":[{'title':'Exclusive', 'value':1},{'title':'Inclusive', 'value':2},{'title':'None', 'value':0}],"return-object":"","item-text":"title","item-value":"value"},model:{value:(_vm.$parent.selectedTaxMode),callback:function ($$v) {_vm.$set(_vm.$parent, "selectedTaxMode", $$v)},expression:"$parent.selectedTaxMode"}})],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{attrs:{"white-back":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","ma-0":""}},[_c('v-flex',{attrs:{"form-item":"","xs12":"","sm12":"","md12":"","height48":""}},[_c('v-btn',{key:_vm.$parent.saveBtnKey,attrs:{"no-border-radius":"","color":"primary","full-height":"","nomargin":"","block":"","no-round-borders":"","noshadow":"","disabled":_vm.$parent.disableOnSave},on:{"~click":function($event){return _vm.fn_validateForm.apply(null, arguments)}}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }