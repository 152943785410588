<template>
    <div>
        <v-dialog v-model="dialog_comp_tax_rate" no-click-animation persistent scrollable max-width="300px" transition="dialog-bottom-transition">
          <v-card flat>
              <v-card-title nopadding noshadow>
                <v-subheader full-width>GST Rate</v-subheader>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text dialog-card-content nopadding>
                <ValidationObserver ref="observer">
                    <v-form @submit.prevent="submit">

                        <v-layout ma-0 row wrap pt-4>

                            <v-flex xs12 sm12 md12 form-item @click="openDialogTaxRateList(true)">

                                <v-text-field  
                                    readonly
                                    value="Custom"
                                    label="Select GST Rate" 
                                    placeholder=" "
                                    append-icon="arrow_drop_down">
                                </v-text-field>
                                
                            </v-flex>

                            <v-flex xs12 sm12 md12 form-item>
                                <ValidationProvider name="tax rate" rules="required|decimal2" v-slot="{ errors }">
                                    <v-text-field 
                                        label="GST Rate (%)" 
                                        placeholder=" " 
                                        type="number" 
                                        v-model.number="taxRate"
                                        required
                                        :error-messages="errors">
                                    </v-text-field>
                                </ValidationProvider>
                            <!-- <v-text-field 
                                    label="GST Rate (%)" 
                                    placeholder=" " 
                                    type="number" 
                                    v-model.number="taxRate"
                                    v-validate="'required|decimal:2'" 
                                    data-vv-name="customtaxrate" 
                                    data-vv-as="gst rate" 
                                    :error-messages="errors.collect('fields.customtaxrate')">
                                </v-text-field> -->
                            </v-flex>
                        </v-layout>

                    </v-form>
                </ValidationObserver>

            </v-card-text>
            <!-- <v-divider></v-divider> -->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                <v-btn color="blue darken-1" text @click.native="fn_checkValidations">Done</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('required', required);

extend('decimal2', value => {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

});

export default{
    props:['openDialogTaxRateList'],
    data(){	
        return{
            dialog_comp_tax_rate: false,
            // taxName:"Custom",
            taxRate:null
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        openDialogWeb: function () {	
            // this.errors.clear('fields');
            // this.fn_getTaxRate();
            this.taxRate = this.$parent.compTaxRate.value;
            this.dialog_comp_tax_rate = true;
        },
        closeDialogWeb: function () {
            this.dialog_comp_tax_rate = false;
        },
        // fn_getTaxRate: function(){
        // 	for(let i=0; i<this.$parent.compTaxRateList.length; i++){
     //          if(this.$parent.compTaxRateList[i].value == this.$parent.compTaxRate.value){
     //            this.taxName = this.$parent.compTaxRateList[i].name;
     //            break;
     //          }
     //        }
     //    },
        // fn_checkValidations: function () {
        //     this.$validator.validateAll('fields').then((result) => {
        //         if(result){
        //             if(this.taxRate)
        //                 this.$parent.fn_updateTaxRate(this.taxRate, 2);
        //             else
        //                 this.showToast('Please add tax rate');
        //         }
        //     });
        // },

        fn_checkValidations: function(){
            this.$refs.observer.validate().then((result) => {
                if(result) {
                    if(this.taxRate)
                        this.$parent.fn_updateTaxRate(this.taxRate, 2);
                    else
                        this.showToast('Please add tax rate');
                }
            });
        },
    }
}
</script>