<template>
    <div grey-back full-height>

        <v-toolbar :height="56" flat>
            <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

            <v-toolbar-title>My Accountant</v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn> -->

            <v-btn icon @click="fn_new" :disabled="loading" v-if="items.length < 1 && !listHasError"><v-icon>add</v-icon></v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back">

            <v-row class="ma-0" align-content="center" justify="center" v-if="loading">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>
            
            <v-list two-line nopadding>
                
                <div v-for="(item, index) in items" :key="index">
                    <v-list-item :disabled="loading">

                        <v-list-item-action>
                            <a :href="'tel:'+item.PhoneNumber" style="text-decoration:none">
                                <v-icon color="primary">call</v-icon>
                            </a>
                            <!-- <v-btn icon color="red"><v-icon>delete</v-icon></v-btn> -->
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title>{{item.Name ? item.Name : 'Accountant ' + (index+1)}}</v-list-item-title>
                            <v-list-item-subtitle>{{item.PhoneNumber}}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="!$root.CurrentBusiness.IsAccountantOnly">
                            <v-btn icon @click="fn_openDialogConfirmation(item, index)"><v-icon color="red">delete</v-icon></v-btn>
                        </v-list-item-action>

                    </v-list-item>

                    <v-divider v-if="items.length-1!=index"></v-divider>

                </div>

            </v-list>

            <div v-if="!items.length && !loading" class="pa-4 font12" center-align light-opacity-color>There are no accountant added yet!</div>
        </div>

        <dialog-confirmation ref="ref_dialogConfirmation" header="Remove Accountant?" :actionMethod="fn_getToken" :params="{type:'delete'}" :displayView='true'></dialog-confirmation>

    </div>
</template>

<script>

import dialogConfirmation from '../../dialogs/dialog_confirmation'

    export default{
        data(){
            return{
                items:[],
                seletedItem:null,
                loading:false,
                listHasError:false
            }
        },
        components:{
            'dialog-confirmation': dialogConfirmation
        },
        methods:{
            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_new: function(){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/accountant/items/new', query:{tagId:this.guidWeb()}});
            },

            fn_openDialogConfirmation: function(item, index){
                this.seletedItem = item;
                this.seletedItem.itemIndex = index;
                this.$refs['ref_dialogConfirmation'].openDialogWeb();
            },

            fn_getToken: function(type){
                // alert("Please wait...");
                type == "items" ? this.items = [] : '';
                this.loading = true;
                this.$refs['ref_dialogConfirmation'].closeDialogWeb();
                this.$root.fn_getAuthToken(token => {
                    if(token=='error'){
                        this.loading = false;
                        return
                    }
                    else{
                        if(type=='items'){
                            this.fn_items(token);
                        }
                        else{
                            this.fn_removeAccountant(token);   
                        }
                        // type == "items" ? this.fn_items(token) : this.fn_removeAccountant(token)    
                    }
                });
            },

            fn_items: function(token){
                if(!navigator.onLine){
                    // alert('Internet not available');
                    this.$eventHub.$emit('snackbarAlert', {msg:'Internet not available'});
                    return;
                }
                
                let self = this;
                let dataObj = {};
                dataObj.businessId = this.$root.CurrentBusiness.BusinessID;
                dataObj.token = token;
                this.getServerData('GetAccountantList', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.items = JSON.parse(response);
                            self.loading = false;
                            self.listHasError = false;
                            // self.hideLoading();
                        }, 
                        onError: function(error){
                            console.log(error);
                            // self.hideLoading();
                            self.loading = false;
                            self.listHasError = true;
                            if(typeof JSON.parse(error) == 'object'){
                                let response = JSON.parse(error);
                                // alert(response.Message);
                                self.$eventHub.$emit('snackbarAlert', {msg:response.Message});
                            }
                        }, 
                    }
                );
            },

            fn_removeAccountant: function(token){

                if(!navigator.onLine){
                    // alert('Internet not available');
                    this.$eventHub.$emit('snackbarAlert', {msg:'Internet not available'});
                    this.loading = false;
                    return;
                }
                
                let self = this;
                let dataObj = {};
                dataObj.businessId = this.$root.CurrentBusiness.BusinessID;
                dataObj.userId = this.seletedItem.UserID;
                dataObj.token = token;
                this.getServerData('RemoveAccountant', dataObj, 
                    {
                        onSuccess: function(response){
                            // self.items = JSON.parse(response.data);
                            console.log(response);
                            self.items.splice(self.seletedItem.itemIndex, 1);
                            self.loading = false;
                            // self.hideLoading();
                            // alert('Accountant deleted!');
                            self.$eventHub.$emit('snackbarAlert', {msg:'Accountant deleted'});
                        }, 
                        onError: function(error){
                            console.log(error);
                            // self.hideLoading();
                            self.loading = false;
                            if(typeof JSON.parse(error) == 'object'){
                                let response = JSON.parse(error);
                                // alert(response.Message);
                                self.$eventHub.$emit('snackbarAlert', {msg:response.Message});
                            }
                        }, 
                    }
                );
            },

            
        },

        mounted: function(){
            this.fn_getToken('items');
            this.$eventHub.$on('UpdateAccountantList', dataObj => {
                console.log(dataObj);
                this.fn_getToken('items');
            });
        },
    }
</script>