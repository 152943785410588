<template>
    <div full-height grey-back>
        <dialog-transaction-settings ref="ref_dialogTransactionSettings" :closeDialog="fn_closeDialog"></dialog-transaction-settings>
    </div>
</template>

<script>
import dialogTransactionSettings from './dialog_transaction_settings_new'

export default{
    components:{
        'dialog-transaction-settings':dialogTransactionSettings
    },
    methods:{
        fn_openDialog: function(){
            this.$refs['ref_dialogTransactionSettings'].openDialogWeb();
        },

        fn_closeDialog: function(){
            this.$refs['ref_dialogTransactionSettings'].closeDialogWeb();
            this.$router.go(-1);
        }
    },
    mounted: function(){
        this.fn_openDialog();
    }
}
</script>