<template>
  <div id="datatable" class="table_wrapper grey-back" style="width:100%">

        <div class="reportdetails" v-if="!customDates">
          <!-- <h3 class="body-2" v-if="$root.CurrentBusiness">{{$root.CurrentBusiness.BusinessName}}</h3> -->
          <!-- <h3 class="body-2" v-if="$root.CurrentBusiness">{{propsObj.reportTitle}}</h3> -->
          <v-card flat>
            <v-card-text class="pt-4 pb-4">
              <!-- <p class="mb-0" v-if="propsObj.itemLabel">
                <span>{{propsObj.itemLabel}}</span>: <span>{{propsObj.itemName}}</span>
              </p> -->

              <p class="cursor-pointer mb-0">
                <span @click="openDialogCustomDates" v-if="propsObj.periodLabel == 'Period'">
                    <span>{{propsObj.periodLabel}}</span>: 
                    <span>{{propsObj.startDate | moment("DD-MMM-YYYY")}}</span> 
                    <span> To </span> 
                    <span>{{propsObj.endDate | moment("DD-MMM-YYYY")}}</span>
                  </span>

                  <span @click="openDatePicker('end', propsObj.endDate)" v-else>
                    <span>{{propsObj.periodLabel}}</span>: 
                    <span>{{propsObj.endDate | moment("DD-MMM-YYYY")}}</span>
                  </span>
              </p>

              <p class="mb-0" v-if="reportFilterObj && Object.keys(reportFilterObj).length">
                <span v-for="(filter, key, index) in reportFilterObj" :key="index">
                  <span>{{filter.name}}</span>: {{filter.value}}{{index!=Object.keys(reportFilterObj).length-1 ? ', ': ''}}
                </span>
              </p>

              <v-menu v-if="propsObj.periodLabel == 'Period'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small outlined capitalize v-bind="attrs" v-on="on" style="position:absolute;right:16px;top:0px;bottom:0;margin:auto">
                    {{propsObj.selectedPeriod}}&nbsp;<v-icon right>date_range</v-icon>
                  </v-btn>
                </template>
                  <v-list class="pa-0">
                    <v-list-item v-for="(item, index) in periods" :key="index" @click="selectPeriod(item.value)">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
              </v-menu>
            </v-card-text>
          </v-card>
          
        </div>

        <v-divider></v-divider>

        <div class="tile-wrapper data-container" style="padding-bottom:100px" v-if="propsObj.reportData">

          <!-- <div>
            <v-runtime-template :template="propsObj.tileContent"/>
          </div> -->
          <template v-if="$route.params.reportId==801">

            <v-layout row wrap ma-0>
                
                <v-flex xs6 sm6 md6 cursor-pointer v-for="(item, index) in propsObj.reportData.detailRows" :key="index" @click="fn_getDetails($event, item)">
                  <v-card v-ripple class="mt-2 mr-2 ml-2" font16>

                    <!-- <v-card-title noshadow>
                        <div full-width class="font-medium">{{item.cols.gstR1Title}}</div>
                    </v-card-title> -->

                    <v-subheader dark-opacity-color>{{item.cols.gstR1Title}}</v-subheader>
                    <v-divider></v-divider>

                    <v-card-text>
                        
                        <v-layout row wrap ma-0>

                            <template v-if="item.subReportId == 'GSTR1EXEMPT'">
                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Nil Amount</div>
                                    <div dark-opacity-color font-heavy>&#8377; {{item.cols.totalTaxableValue ? $root.numberToLocale(item.cols.totalTaxableValue) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Exempted Amount</div>
                                    <div dark-opacity-color font-heavy>&#8377; 0</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6 pt-2>
                                    <div font12 light-opacity-color>Total Non-GST Amount</div>
                                    <div dark-opacity-color font-heavy>&#8377; 0</div>
                                </v-flex>
                            </template>

                            <template v-else-if="item.subReportId == 'GSTR1DOCUMENT'">
                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Docs</div>
                                    <div dark-opacity-color font-heavy>{{item.cols.totalDocs ? item.cols.totalDocs : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Cancelled Docs</div>
                                    <div dark-opacity-color font-heavy>{{item.cols.cancelDocs ? item.cols.cancelDocs : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6 pt-2>
                                    <div font12 light-opacity-color>Net Issued Docs</div>
                                    <div dark-opacity-color font-heavy>{{item.cols.netIssueDocs ? item.cols.netIssueDocs : '0'}}</div>
                                </v-flex>
                            </template>

                            <template v-else>
                                <v-flex xs6 sm6 md6 v-if="item.subReportId != 'GSTR1HSN'">
                                    <div font12 light-opacity-color>Total Value</div>
                                    <div dark-opacity-color font-heavy>&#8377; {{item.cols.totalInvoiceAmount ? $root.numberToLocale(item.cols.totalInvoiceAmount) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Taxable Value</div>
                                    <div dark-opacity-color font-heavy>&#8377; {{item.cols.totalTaxableValue ? $root.numberToLocale(item.cols.totalTaxableValue) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6 :class="{'pt-2':item.subReportId != 'GSTR1HSN'}">
                                    <div font12 light-opacity-color>Total Tax Liability</div>
                                    <div dark-opacity-color font-heavy>&#8377; {{item.cols.totalTaxAmount ? $root.numberToLocale(item.cols.totalTaxAmount) : '0'}}</div>
                                </v-flex>
                            </template>
                        </v-layout>

                    </v-card-text>
                    
                </v-card>
                </v-flex>

              </v-layout>

            <!-- <div :key="index" @click="fn_getDetails(item)">

                <v-card v-ripple flat class="mt-2 mr-2 ml-2" font16>

                    <v-card-title noshadow>
                        <div full-width class="font-medium">{{item.cols.gstR1Title}}</div>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                        
                        <v-layout row wrap>

                            <template v-if="item.subReportId == 'GSTR1EXEMPT'">
                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Nil Amount</div>
                                    <div>&#8377; {{item.cols.totalInvoiceAmount ? $root.numberToLocale(item.cols.totalInvoiceAmount) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Exempted Amount</div>
                                    <div>&#8377; 0</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Non-GST Amount</div>
                                    <div>&#8377; 0</div>
                                </v-flex>
                            </template>

                            <template v-else-if="item.subReportId == 'GSTR1DOCUMENT'">
                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Docs</div>
                                    <div>&#8377; {{item.cols.totalInvoiceAmount ? $root.numberToLocale(item.cols.totalInvoiceAmount) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Cancelled Docs</div>
                                    <div>&#8377; {{item.cols.totalTaxableValue ? $root.numberToLocale(item.cols.totalTaxableValue) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Net Issued Docs</div>
                                    <div>&#8377; {{item.cols.totalTaxAmount ? $root.numberToLocale(item.cols.totalTaxAmount) : '0'}}</div>
                                </v-flex>
                            </template>

                            <template v-else>
                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Value</div>
                                    <div>&#8377; {{item.cols.totalInvoiceAmount ? $root.numberToLocale(item.cols.totalInvoiceAmount) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Taxable Value</div>
                                    <div>&#8377; {{item.cols.totalTaxableValue ? $root.numberToLocale(item.cols.totalTaxableValue) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Tax Liability</div>
                                    <div>&#8377; {{item.cols.totalTaxAmount ? $root.numberToLocale(item.cols.totalTaxAmount) : '0'}}</div>
                                </v-flex>
                            </template>
                        </v-layout>

                    </v-card-text>
                    
                </v-card>

            </div> -->
          </template>

          <template v-if="$route.params.reportId==802">
            <v-layout row wrap ma-0>

              <v-flex xs6 sm6 md6 cursor-pointer v-for="(item, index) in propsObj.reportData.detailRows" :key="index" @click="fn_getDetails($event, item)">
                <v-card v-ripple class="mt-2 mr-2 ml-2" font16>

                    <v-subheader dark-opacity-color>{{item.cols.gstR2Title}}</v-subheader>
                    <v-divider></v-divider>
                    <v-card-text>
                        
                        <v-layout row wrap ma-0>

                            <v-flex xs6 sm6 md6 v-if="item.subReportId != 'GSTR2HSN'">
                                <div font12 light-opacity-color>Voucher Count</div>
                                <div dark-opacity-color font-heavy>
                                    {{item.cols.voucherCount ? item.cols.voucherCount : '0'}}
                                </div>
                            </v-flex>

                            <v-flex xs6 sm6 md6>
                                <div font12 light-opacity-color>Taxable Value</div>
                                <div dark-opacity-color font-heavy>&#8377; {{item.cols.totalTaxableValue ? $root.numberToLocale(item.cols.totalTaxableValue) : '0'}}</div>
                            </v-flex>

                            <v-flex xs6 sm6 md6 :class="{'pt-2':item.subReportId != 'GSTR2HSN'}">
                                <div font12 light-opacity-color>Tax Amount</div>
                                <div dark-opacity-color font-heavy>&#8377; {{item.cols.totalTaxAmount ? $root.numberToLocale(item.cols.totalTaxAmount) : '0'}}</div>
                            </v-flex>

                            <v-flex xs6 sm6 md6 pt-2 v-if="item.subReportId != 'GSTR2HSN'">
                                <div font12 light-opacity-color>Invoice Amount</div>
                                <div dark-opacity-color font-heavy>&#8377; {{item.cols.totalInvoiceAmount ? $root.numberToLocale(item.cols.totalInvoiceAmount) : '0'}}</div>
                            </v-flex>

                        </v-layout>

                    </v-card-text>
                    
                </v-card>
              </v-flex>

            </v-layout>
          </template>

          <!-- {{propsObj.reportData.gstr3BData}} -->

          <template v-if="$route.params.reportId==803">

            <v-layout row wrap ma-0 v-if="propsObj.reportData && propsObj.reportData.gstr3BData && propsObj.reportData.gstr3BData.categories">
              <template v-for="(item, index) in propsObj.reportData.gstr3BData.categories">
                <v-flex xs6 sm6 md6 cursor-pointer :key="index" @click="fn_getDetails($event, item)">
                  <v-card v-ripple flat class="mt-2 mr-2 ml-2">
                      <v-subheader dark-opacity-color style="height:auto;min-height:48px;padding:8px 16px">{{item.title}}</v-subheader>
                      <v-divider></v-divider>
                      <v-card-text>
                          
                          <v-layout row wrap ma-0>
                              <v-flex xs6 sm6 md6 v-for="(col, colIndex) in item.items" :key="colIndex" :class="{'pt-2':colIndex>=2}">
                                  <div font12 light-opacity-color>{{col.title}}</div>
                                  <div dark-opacity-color font-heavy>&#8377; {{$root.numberToLocale(col.value)}}</div>
                              </v-flex>
                          </v-layout>

                      </v-card-text>
                      
                  </v-card>
                </v-flex>  
              </template>
              
            </v-layout>

            <!-- <div v-for="(item, index) in propsObj.reportData.gstr3BData.categories" :key="index" @click="fn_getDetails(item)">

                <v-card v-ripple flat class="mt-2 mr-2 ml-2" font16>

                    <v-card-title noshadow>
                        <div full-width class="font-medium">{{item.title}}</div>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        
                        <v-layout row wrap>
                            <v-flex xs6 sm6 md6 v-for="(col, colIndex) in item.items" :key="colIndex">
                                <div font12 light-opacity-color>{{col.title}}</div>
                                <div>&#8377; {{$root.numberToLocale(col.value)}}</div>
                            </v-flex>
                        </v-layout>

                    </v-card-text>
                    
                </v-card>

            </div> -->
          </template>

        </div>

        <!-- <div class="footer" style="padding-left:0 !important" id="tableFooter" v-if="propsObj.reportData.footerRow || propsObj.reportData.summaryRows" @click="$parent.$parent.tileViewTotalSheet = !$parent.$parent.tileViewTotalSheet">
          <v-runtime-template :template="propsObj.footerContent"/>
        </div> -->

        <!-- <v-bottom-sheet v-model="$parent.$parent.tileViewTotalSheet">
          <v-card class="report-details-card" v-if="$parent.$parent.tileViewTotalSheet">

            <v-subheader dark-opacity-color norightpadding>Total 
              <v-spacer></v-spacer>
              <v-btn icon flat @click="$parent.$parent.tileViewTotalSheet=false"><v-icon>close</v-icon></v-btn>
            </v-subheader>

            <v-divider></v-divider>

            <v-card-text>

              <v-layout row wrap>
                <v-flex xs6 sm6 md6>
                  <div style="padding:4px 0px;" v-for="(value, key) in propsObj.tileViewFooterHeaders">
                    {{value ? value : '-'}}
                  </div>
                </v-flex>

                <v-flex xs6 sm6 md6 right-align>
                  <div style="padding:4px 0px;" v-if="$route.params.reportid==501 || $route.params.reportid==502"  v-for="(item, index) in propsObj.reportData.summaryRows" :key="index">
                    <strong>{{item.transactionListId ? $root.numberToLocale(item.transactionListId) : ''}}</strong>
                  </div>

                  <div style="padding:4px 0px;" v-if="$route.params.reportid!=501 && $route.params.reportid!=502" v-for="(value, key) in propsObj.tileViewFooterHeaders">
                    <strong>{{propsObj.reportData.footerRow[key] == null || propsObj.reportData.footerRow[key] == '' ? '-' : $root.numberToLocale(propsObj.reportData.footerRow[key])}}</strong>
                  </div>
                </v-flex>
              </v-layout>


            </v-card-text>
          </v-card>
        </v-bottom-sheet> -->


        <!-- <v-bottom-sheet v-model="$parent.$parent.tileViewDetailSheet">

          <v-card class="report-details-card" v-if="$parent.$parent.tileViewDetailSheet && currentItem">
            <v-card-text>
              <div style="float:left">
                <div style="padding:4px 0px;" v-for="(item, index) in propsObj.reportData.headerRow" :key="index">
                  <div><strong>{{item.headerName ? item.headerName : '-'}}</strong></div>
                </div>
              </div>

              <div style="padding:4px 0px;"  v-for="(col, colIndex) in propsObj.columns" :key="colIndex">
                <div right-align>
                  <span v-if="currentItem.cols[col]">
                    <span v-if="propsObj.reportData.headerRow[colIndex].type == 'date'">{{currentItem.cols[col] | moment("DD-MMM-YYYY")}}</span>
                    <span v-else>{{currentItem.cols[col] ? $root.numberToLocale(currentItem.cols[col]) : ''}}</span>
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-bottom-sheet> -->

  </div>
</template>

<script>
// const transactionForms = require('../../../config.js').configs.transactionForms;

// import VRuntimeTemplate from "v-runtime-template";

  export default{
    props:['reportId', 'propsObj', 'openDialogAndroid', 'getReportItemDetails', 'openDatePicker', 'reportFilterObj', 'openTransaction', 'openAccountDetails', 'getSubReportData', 'periods', 'selectPeriod', 'openDialogCustomDates', 'customDates'],
    data(){
      return{
        footerHeaders:null,
        currentItem:null
      }
    },
    // components:{
    //   "v-runtime-template": VRuntimeTemplate
    // },
    methods:{

      // fn_expandMore: function(item){
      //   console.log(JSON.stringify(this.propsObj));
      //   this.currentItem = item;
      //   this.$parent.$parent.tileViewDetailSheet = true;
      // },

      // fn_headerRowForFooter: function(){
      //   this.footerHeaders = {};
      //   let self = this;
      //   for(let i=0; i<this.propsObj.reportData.headerRow.length; i++){
      //     (function(index){
      //       for(let j in self.propsObj.reportData.footerRow){
      //         if(self.propsObj.reportData.footerRow[j]){
      //           self.footerHeaders[j] = self.propsObj.reportData.headerRow[index].headerName;
      //         }
      //       }
      //     }(i));
      //   }
      //   // this.totalSheet = true;
      //   this.$parent.$parent.tileViewTotalSheet = true;
      //   console.log(JSON.stringify(this.footerHeaders));
      // },

      // fn_getTransactionType: function(listId){
      //   for(let i=0; i<transactionForms.length; i++){
      //     if(transactionForms[i].id==listId){
      //       return transactionForms[i].title;
      //     }
      //   }
      // },


      fn_getDetails: function(event, item, linkedAccount){
        // alert('in');
        // if(this.propsObj.disableClick || this.propsObj.openView){
        //   alert('in');
        //   return;
        // }
        
        this.propsObj.disableClick = true;
        let startDate = this.propsObj.startDate ? this.$moment(new Date(this.propsObj.startDate)).format('YYYY-MM-DD') : null;
        let endDate = this.$moment(new Date(this.propsObj.endDate)).format('YYYY-MM-DD');

        // if(this.propsObj.detailreportId && item.transactionId){
        if(this.$route.params.reportId == 602 && !linkedAccount){
          this.openLinkedAccount(item.linkedAccounts);
        }
        else if(this.$route.params.reportId == 201){
          this.getReportItemDetails(event, item.transactionListId, item.transactionId);
          // this.show(event);
        }
        else if(this.$route.params.reportId == 801 || this.$route.params.reportId == 802){
          this.getSubReportData(item.subReportId);
        }
        else if(this.$route.params.reportId == 803){
          this.getSubReportData(item);
        }
        else if(this.propsObj.openView && item.transactionId && item.transactionListId){
          this.openTransaction(item.transactionListId, item.transactionId);
        }
        // else if(this.propsObj.openAccount){
        //   this.openAccountDetails(item.accountId);
        // }
        else if(item.accountId){
          if(this.$route.params.reportId == 302 || this.$route.params.reportId == 402){
            let routereportId = this.$route.params.reportId == 302 ? 301 : 401;
            // removing period from local storage before route update
            localStorage.removeItem('report_period_'+routereportId);
            this.$router.push({path:'/reports/'+this.$root.CurrentBusiness.BusinessID+'/'+routereportId, query:{"isFilter":true, "filterPartyId":item.accountId, "filterPartyName":item.accountName, "startDate":startDate, "endDate":endDate, "selectedPeriod":this.propsObj.selectedPeriod}});
          }
          else{
            // removing period from local storage before route update
            localStorage.removeItem('report_period_201');
            this.$router.push({path:'/reports/'+this.$root.CurrentBusiness.BusinessID+'/'+201, query:{"itemId":item.accountId, "itemName":item.accountName, "startDate":startDate, "endDate":endDate, "selectedPeriod":this.propsObj.selectedPeriod}});
          }
        }
        else if(item.productId){
          // removing period from local storage before route update
          localStorage.removeItem('report_period_101');
          if(this.reportFilterObj.party){
            this.$router.push({path:'/reports/'+this.$root.CurrentBusiness.BusinessID+'/'+101, query:{"itemId":item.productId, "itemName":item.productName, "isFilter":true, "filterPartyId":this.reportFilterObj.party.id, "filterPartyName":this.reportFilterObj.party.value, "startDate":startDate, "endDate":endDate, "selectedPeriod":this.propsObj.selectedPeriod}});
          }
          else{
            if(this.$route.params.reportId == 410){
              this.$router.push('/masters/inventory/productandservices/items/'+item.productId);
            }
            else{
              this.$router.push({path:'/reports/'+this.$root.CurrentBusiness.BusinessID+'/'+101, query:{"itemId":item.productId, "itemName":item.productName, "startDate":startDate, "endDate":endDate, "selectedPeriod":this.propsObj.selectedPeriod}});
            }
          }
        }

        let self = this;
        setTimeout(function(){
          self.propsObj.disableClick = false;
        },300);
      },

    }
  }
</script>