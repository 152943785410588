import { extend } from 'vee-validate'

extend('max50', value => {
    if(value.length <= 50){
        return true;
    }
    
    return 'This field can not be greater than 50 characters.'
})

const draftObj = new Object();

export const draftMixin =  {

    data(){
        return {
            askToDiscard: false,
            saveDraft:false,
            quitForm:false,
            nextRoute:{"path":null, "query":null},
            saveBtnKey:1
        }
    },

    methods: {

        fn_setDraftData: function(type, formName, listId, listTitle){
            draftObj.draftId = this.draftStorageId;
            draftObj.formId = this.formId;
            draftObj.formName = formName;
            draftObj.listId = listId;
            draftObj.listTitle = listTitle;
            draftObj.modifiedDate = new Date();
            draftObj.formPath = this.$route.path;
            draftObj.type = type;
            console.log(draftObj);
        },

        fn_saveDraft: function(draftStorageId, title){
            // var self = this;

            draftObj.title = title;
            draftObj.formData = JSON.stringify(this.Form);
            draftObj.transactionId = this.Form.transactionData && this.Form.transactionData.transactionId ? this.Form.transactionData.transactionId : null;
            console.log(JSON.stringify(draftObj));
            localStorage.setItem(draftStorageId, JSON.stringify(draftObj));

            // this.postData('Draft', draftObj, {
            //     onSuccess: function(response){
            //         console.log(response);
            //         self.askToDiscard = true;
            //         callback ? callback() : '';
            //     },
            //     onError: function(error){
            //         console.log(error);
            //     }
            // });
        },

        fn_setFormDataFromDraft: function (title, callback) {
            // let self = this;
            // this.getData('Draft', this.draftStorageId, {
            //     onSuccess: function(response){
            //         console.log(response);
            //         var draft = JSON.parse(response);
            //         draft ? callback(draft) : callback(false);
            //     },
            //     onError: function(error){
            //         console.log(error);
            //     }
            // });
            
            if(localStorage.getItem(title)){
                let dataObj = JSON.parse(localStorage.getItem);
                callback(dataObj)
            }
        },

        // fn_removeDraft: function(draftId, callback, discardChangeValue){
        fn_removeDraft: function(draftId, callback){
            localStorage.removeItem(draftId);
            this.askToDiscard = false;
            this.quitForm = true;
            callback ? callback() : '';
        },

        // fn_removeDraft: function(callback){
        //     var self = this;
        //     this.deleteData('RemoveDraftById', this.draftStorageId, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 response ? self.askToDiscard = false : '';
        //                 callback != undefined && response ? callback() : '';
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },

        fn_isDraft: function (callback) {
            // let self = this;
            this.getData('IsDraft', this.draftStorageId, {
                onSuccess: function(response){
                    console.log(response);
                    callback(JSON.parse(response));
                },
                onError: function(error){
                    console.log(error);
                }
            });
        },

        fn_draftAction: function(discard){
            // this.fn_closeDialog();
            this.$refs['ref_dialogDiscard'].closeDialogWeb();
            this.loadedFromDraft = false;
            if(discard){
                localStorage.removeItem(this.draftStorageId);
                this.askToDiscard = false;
                this.$refs['ref_dialogForm'].closeDialogWeb();
                this.$router.go(-1);
                // this.fn_removeDraft(this.draftStorageId, ()=>{
                //     alert('in');
                //     this.askToDiscard = false;
                //     this.$refs['ref_dialogForm'].closeDialogWeb();
                //     this.$router.go(-1);
                // })
            }
            else{
                this.saveDraft = true;
                this.askToDiscard = false;
                this.$refs['ref_dialogForm'].closeDialogWeb();
                this.$router.go(-1);
            }
        },

        fn_validate: function(){
            this.$refs['ref_dialogForm'].fn_validateForm();
        },

        //Drafts on new form load
        fn_checkDraftItems: function(formName, openDialog){
            let self = this;
            this.fn_draftItemsByForm(formName, function(drafts){
                self.unwatch = true;
                self.Form.draftItems = drafts.draftItems;
                if(openDialog && self.Form.draftItems && self.Form.draftItems.length){
                    openDialog && !self.loadedFromDraft ? self.fn_openDialogDraftItems() : '';   
                }
            })
        },

        fn_openDialogDraftItems: function(){
            this.fn_openDialogWeb('web', 'DraftItems', 'ref_dialogDraftItems');
        },

        fn_draftItemsByForm: function(formName, callback){
            let filterObject = {};
            filterObject.formName = formName;

            // let self = this;
            this.getItems("DraftItems", filterObject, 
              {
                onSuccess: function (response) {
                    console.log(response);
                    if(response){
                        let drafts = JSON.parse(response);
                        callback(drafts);
                    }
                },
                onError: function (error) {
                  console.log(error);
                }
              }
            )
        },

        fn_importDraft: function(draftItem){

            let self = this;
            this.fn_removeDraft(this.draftStorageId, function(){
                self.askToDiscard = false;
                self.fn_closeDialog();
                setTimeout(function(){

                    if(draftItem.listId){
                        self.$router.replace({path:'/masters/lists/'+draftItem.listId+'/items/new', query:{tagId:draftItem.formId, listTitle:draftItem.listTitle, draft:true}});
                    }
                    else{
                        self.$router.replace({path:draftItem.formPath, query:{tagId:draftItem.formId, draft:true}});
                    }

                },300);
            })
            // let self = this;
            // this.askToDiscard = false;
            // this.fn_closeDialog();
            // setTimeout(function(){

            //     if(draftItem.listId){
            //         self.$router.replace({path:'/masters/lists/'+draftItem.listId+'/items/new', query:{tagId:draftItem.formId, listTitle:draftItem.listTitle, draft:true}});
            //     }
            //     else{
            //         self.$router.replace({path:draftItem.formPath, query:{tagId:draftItem.formId, draft:true}});
            //     }

            //     // self.$router.replace({path:draftItem.formPath, query:{tagId:draftItem.formId, draft:true}});
            // },300);

        },

        fn_removeDraftItem: function(draftId, draftIndex){
            let self = this;
            this.fn_removeDraft(draftId, function(){
                self.askToDiscard = true;
                draftIndex != undefined ? self.Form.draftItems.splice(draftIndex, 1) : '';
                if(self.Form.draftItems && !self.Form.draftItems.length){
                    self.fn_closeDialog();
                }
            });
        },

        fn_showDialogDiscard: function(){
            // let formValue = JSON.parse(JSON.stringify(this.Form));
            // let areObjectsEqual = this.$root.fn_getFormObject(this.formId) ? this.$root.fn_deepEqual(formValue, JSON.parse(this.$root.fn_getFormObject(this.formId))): false;
            let areObjectsEqual = false;
            // if(this.$root.fn_getFormObject(this.formId)){
            //     let formObjStr = JSON.stringify(this.Form);
            //     let savedFormObjStr = this.$root.fn_getFormObject(this.formId);
            //     formObjStr === savedFormObjStr ? areObjectsEqual = true : '';
            // }

            if(this.$root.fn_getFormObject(this.formId)){

                let currentFormObj = JSON.parse(JSON.stringify(this.Form));
                delete currentFormObj.draftItems;

                let savedFormObj = JSON.parse(this.$root.fn_getFormObject(this.formId));
                delete savedFormObj.draftItems;
                JSON.stringify(currentFormObj) === JSON.stringify(savedFormObj) ? areObjectsEqual = true : '';
            }

            // if(this.loadedFromDraft || (!areObjectsEqual && this.askToDiscard)){
            if(!areObjectsEqual && this.askToDiscard){
                return true;
            }
            else{
                return false;
            }

        },

        fn_clearData: function(formType, callback){
            this.$root.fn_deleteFormObject(this.formId);
            localStorage.removeItem(formType);
            if(!this.saveDraft){
                this.fn_removeDraft(this.draftStorageId, function(){
                    callback();
                })
            }
            else{
                callback();
            }
            // !this.saveDraft ? this.fn_removeDraft(this.draftStorageId) : '';
        }

    }
}