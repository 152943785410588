<template>
    <div>
        <v-toolbar :height="56" flat extended>
            <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

            <v-toolbar-title>Opening Stock</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

            <!-- <v-btn icon @click="fn_getReportData" v-if="listItemObject.totalCount"><v-icon>vertical_align_bottom</v-icon></v-btn>
            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn> -->

            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

            <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
        </v-toolbar>

        <div class="data-container grey-back hasExtention" style="padding-bottom:40px" v-scroll.self="onScroll">


            <v-list two-line nopadding>

                <!-- <v-subheader white-back subheader-ultra-dense>
                  Count: {{$root.numberToLocale(listItemObject.totalCount)}}
                </v-subheader> -->

                <!-- <v-subheader white-back subheader-dense>
                    Count: {{$root.numberToLocale(listItemObject.totalCount)}}
                    <v-spacer></v-spacer>

                    <div class="font11 mr-2" style="padding-left: 8px;display: flex;align-items: center;border: 1px #ddd solid;border-radius: 5px;" @click="fn_openCategories" v-ripple>
                        <div style="max-width: 60px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{selectionItems.categoryName ? selectionItems.categoryName : 'Category'}}</div>
                        <v-icon>arrow_drop_down</v-icon>
                    </div>

                    <div class="font11 mr-2" style="padding-left: 8px;display: flex;align-items: center;border: 1px #ddd solid;border-radius: 5px;" @click="fn_openBrands" v-ripple>
                        <div style="max-width: 60px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{selectionItems.brandName ? selectionItems.brandName : 'Brand'}}</div>
                        <v-icon>arrow_drop_down</v-icon>
                    </div>

                    <div class="font11" style="padding-left: 8px;display: flex;align-items: center;border: 1px #ddd solid;border-radius: 5px;" @click="fn_openCompanies" v-ripple>
                        <div style="max-width: 60px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{selectionItems.companyName ? selectionItems.companyName : 'Company'}}</div>
                        <v-icon>arrow_drop_down</v-icon>
                    </div>
                </v-subheader> -->

                <v-subheader class="pr-2" white-back subheader-dense>
                    Count: {{$root.numberToLocale(listItemObject.totalCount, 1)}}
                    <v-spacer></v-spacer>

                    <v-autocomplete
                        class="mr-2 autocomplete-small"
                        :class="{'item-selected':selectedBrand}"
                        solo
                        dense
                        :items="brands"
                        v-model="selectedBrand"
                        return-object
                        item-value="itemId"
                        item-text="itemName"
                        placeholder="Brand"
                        hide-details
                        flat
                        outlined
                        style="width:60px;max-height:24px"
                        @input="fn_searchItem"
                        @focus="fn_listItems($event, 'brand')"
                        clearable
                      ></v-autocomplete>

                      <v-autocomplete
                        class="mr-2 autocomplete-small"
                        :class="{'item-selected':selectedCompany}"
                        solo
                        dense
                        :items="companies"
                        v-model="selectedCompany"
                        return-object
                        item-value="itemId"
                        item-text="itemName"
                        placeholder="Company"
                        hide-details
                        flat
                        outlined
                        style="width:60px;max-height:24px"
                        @input="fn_searchItem"
                        @focus="fn_listItems($event, 'company')"
                        clearable
                      ></v-autocomplete>

                      <v-autocomplete
                        class="autocomplete-small"
                        :class="{'item-selected':selectedCategory}"
                        solo
                        dense
                        :items="productCategories"
                        v-model="selectedCategory"
                        return-object
                        item-value="itemId"
                        item-text="itemName"
                        placeholder="Category"
                        hide-details
                        flat
                        outlined
                        style="width:60px;max-height:24px"
                        @input="fn_searchItem"
                        @focus="fn_productCategries"
                        clearable
                      ></v-autocomplete>
                      
                </v-subheader>

                <v-divider></v-divider>

                <div v-for="(item, index) in listItemObject.items" :key="index">
                    <!-- {{item}} -->

                    <v-list-item class="right-subtitle-tile" @click="fn_itemClick(item.transactionId)">
                        <v-list-item-content>

                          <v-list-item-title class="mb-1">{{item.productName}}</v-list-item-title>
                            <v-list-item-subtitle class="mb-1">
                                {{item.qty}} {{item.unitName}}
                                <span v-if="item.packQuantity">({{item.packQuantity}})</span>  
                                <span>X &#8377; {{item.rate ? $root.numberToLocale(item.rate) : 0}}</span>
                                <!-- <span class="right_subtitle">&#8377; {{$root.numberToLocale(item.amount)}}</span>  -->
                            </v-list-item-subtitle>
                          <!-- <v-list-item-subtitle v-if="$root.TransactionSettings.mrpWiseStock">MRP: <b>&#8377; {{item.mrp ? $root.numberToLocale(item.mrp) : 0}}</b></v-list-item-subtitle> -->
                        </v-list-item-content>

                        <v-list-item-action>
                            <!-- <v-btn icon text @click="fn_getBottomMenu(902, item, index)"><v-icon>more_horiz</v-icon></v-btn> -->
                            &#8377; {{$root.numberToLocale(item.amount)}}
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider v-if="listItemObject.items.length-1 != index"></v-divider>
                </div>

                <!-- <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading> -->

                <!-- <div white-back padding-all-16 center-align v-if="loadMore">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div> -->


            </v-list>

            <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>

            <div center-align class="pa-6" v-if="loadTimeOut">
                <p font12 light-opacity-color>Unable to load the items!</p>
                <v-btn capitalize text color="primary" @click="fn_searchItem">
                    <v-icon left>refresh</v-icon>
                    Retry
                </v-btn>
            </div>

            <!-- <div class="display_msg" v-if="!listItemObject.items.length && !loadMore">There are no items</div> -->

        </div>

        <v-footer white-back class="pl-3 pr-3" style="border-top:0.5px #F0F0F0 solid;height:auto;position:sticky;bottom:0">
            <v-layout ma-0 wrap align-center>
              <v-flex xs4 style="text-align:left" v-ripple @click="qtySheet=true">
                <span v-if="stockSummary.unitItems.length">Total Qty<v-icon style="position:absolute">keyboard_arrow_up</v-icon></span>
              </v-flex>
              <v-flex xs8 style="text-align:right">Value: &nbsp;&#8377; {{$root.numberToLocale(stockSummary.totalAmount)}}</v-flex>
            </v-layout>
        </v-footer>

        <!-- <v-bottom-sheet v-model="qtySheet"> -->
        <v-dialog v-model="qtySheet" no-click-animation persistent transition="slide-y-transition" :max-width="320">
            <v-card>
              <!-- <v-card-title noshadow>
                Total Qty
              </v-card-title> -->
              <v-subheader subtitle>Total Qty <v-spacer></v-spacer> <v-btn icon @click="qtySheet=false"><v-icon>close</v-icon></v-btn></v-subheader>
              <v-divider></v-divider>
              <v-card-text class="pt-4">
                <template v-for="(item, index) in stockSummary.unitItems">
                    <span :key="index" v-if="item.qty">
                        {{item.unitName}} {{item.qty}}{{stockSummary.unitItems.length-1!=index ? ',' : ''}}
                    </span>    
                </template>
                
              </v-card-text>
            </v-card>
        </v-dialog>
        <!-- </v-bottom-sheet>    -->

        

        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

        <!-- <dialog-drive-alert ref="ref_dialogDriveAlert"></dialog-drive-alert> -->
    </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../../config.js').configs.bottomSheetMenu;
// const transactionForms = require('../../../../config.js').configs.transactionForms;

// const dialogjs =  require('../../../masters/forms/dialog').dialogjs;
const listIdObject = require('../../../../config.js').configs.mastersPredefinedLists;

// import dialogDriveAlert from '../dialogs/dialog_drive_alert'

// import InfiniteLoading from 'vue-infinite-loading';

    export default{
        // props:['settings'],
        // components: {
        //     // InfiniteLoading,
        //     'dialog-drive-alert':dialogDriveAlert
        // },
        data(){
            return{
                toolbarTitle:null,
                listItemObject:{"items":[], "totalCount":0},
                searchValue:'',
                infiniteId: +new Date(),
                startIndex:0,
                scrollPositionTop:0,


                // selectedItem:null,
                // itemIndex:null,

                snackbar:false,
                snackbar_msg:null,

                stockSummary: {totalAmount:0, unitItems:[]},
                qtySheet:false,

                // loading: false,
                searchItems: false,

                // filterObject:{},
                formId:null,
                dialogStorageId:null,

                selectionItems:{
                    brandId:null,
                    brandName:null,
                    categoryId:null,
                    categoryName:null,
                    companyId:null,
                    companyName:null
                },

                selectedBrand:null,
                selectedCompany:null,
                selectedCategory:null,

                brands:[],
                companies:[],
                productCategories:[],

                // selectionItems.brandId:null,
                // selectionItems.brandName:null,
                // selectionItems.categoryId:null,
                // selectionItems.categoryName:null,
                // selectionItems.companyId:null,
                // selectionItems.companyName:null,
                loadMore:false,
                loadTimeOut:false,
                showPlaceHolder:false
            }
        },
        methods:{

            onScroll (event) {
                var element = event.target;
                if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.listItemObject.items.length < this.listItemObject.totalCount){
                      this.fn_loadItems();
                    }
                }
            },

            fn_drafts: function(){
                this.$router.push({path:'/drafts', query:{tabIndex:1}});
            },
            fn_goBack: function(){
                this.$router.go(-1);
                // this.$router.replace("/"+this.$root.CurrentBusiness.BusinessID+"/master_items");
            },
            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  if(menuAction=='edit'){
            //      this.fn_edit(this.selectedItem);
            //  }
            // },

            fn_openCategories: function(){
                let obj = {};
                obj.functionName = 'productCategories';
                obj.toolbarTitle = 'Select Category';
                obj.itemId = this.selectionItems.categoryId;
                obj.itemName = this.selectionItems.categoryName;
                this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_category', obj);
            },

            fn_openBrands: function(){
                let obj = {};
                obj.functionName = 'listItems';
                obj.toolbarTitle = 'Select Brand';
                obj.itemId = this.selectionItems.brandId;
                obj.itemName = this.selectionItems.brandName;
                this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_brand', obj);
            },

            fn_openCompanies: function(){
                let obj = {};
                obj.functionName = 'listItems';
                obj.toolbarTitle = 'Select Company';
                obj.itemId = this.selectionItems.companyId;
                obj.itemName = this.selectionItems.companyName;
                this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_company', obj);
            },

            // fn_clearAll: function(){
            //  this.selectionItems.categoryId = null;
            //  this.selectionItems.brandId = null;
            //  this.selectionItems.companyId = null;
            //  localStorage.removeItem('transaction_filter');
            //  this.fn_searchItem();
            // },

            fn_itemClick: function (itemId) {
                // this.$router.push('/transactions/inventory/openingstock/items/'+itemId);
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/openingstock/items/'+itemId);
            },

            fn_edit: function (item) {
                this.$router.push({path:'/transactions/inventory/openingstock/items/'+item.transactionId+'/edit', query:{groupId:item.groupId}});
            },

            // fn_checkDrive: function(){
         //        let self = this;
         //        if(this.$root.CurrentBusiness.DataDrive){
         //          this.fn_new();
         //        }
         //        else{
         //          this.$root.fn_getTransactionCount(function(count){
         //            if(count>=10){
         //              self.$refs['ref_dialogDriveAlert'].dialog_setup_drive = true;
         //            }
         //            else{
         //              self.fn_new();
         //            }
         //          })
         //        }
         //    },

            fn_new: function () {
                // localStorage.setItem("SetDataDrive", 1);
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/openingstock/items/new', query:{tagId:this.guidWeb()}});
            },

            fn_comingSoon: function () {
                this.showToast("Coming Soon...");
            },

            // fn_getBottomMenu: function (elementId, item, itemIndex) {

            //  if(item){
            //      this.selectedItem = item;
            //      this.itemIndex = itemIndex
            //  }

            //  let bottomMenu;

            //  // if(elementId == bottomSheetMenu.toolBar.Id){
            //  //  bottomMenu = {"BottomMenu": [menuItem.help]};
            //  // }
            //  if(elementId == bottomSheetMenu.masterListItems.Id){
            //      bottomMenu = {"BottomMenu": [menuItem.edit]};
            //  }
            //  else if(elementId == bottomSheetMenu.moreFilters.Id){
            //      menuItem.filterCategory.selectedItem = this.selectionItems.categoryId ? this.selectionItems.categoryName : null;
            //      menuItem.filterBrand.selectedItem = this.selectionItems.brandId ? this.selectionItems.brandName : null;
            //      menuItem.filterCompany.selectedItem = this.selectionItems.companyId ? this.selectionItems.companyName : null;
            //      bottomMenu = {"BottomMenu": [menuItem.filterCategory, menuItem.filterBrand, menuItem.filterCompany]};
            //      if(this.selectionItems.categoryId || this.selectionItems.brandId || this.selectionItems.companyId){
            //          bottomMenu.BottomMenu.push(menuItem.clearAll);
            //      }
            //  }
                
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_openDialogAndroid: function (platform, name, ref, obj) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.platform = platform;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = obj.toolbarTitle;
            //  viewDialogObject.isBottomMenu = false;

            //  if(ref == 'ref_brand'){
            //      viewDialogObject.listId = listIdObject.brandListGUID;
            //  }
            //  else if(ref == 'ref_company'){
            //      viewDialogObject.listId = listIdObject.companyListGUID;
            //  }

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":null, "name":null};

            //  if(obj.itemId && obj.itemName){
            //      paramObject.defaultValue = {"id":obj.itemId, "name":obj.itemName};
            //  }

            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, obj.functionName, tagId, paramObject, ref);
                
            // },

            // fn_closeDialog: function () {
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            // fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
            //  if(ref == 'ref_brand'){
            //      this.selectionItems.brandId = !isRemoved ? selectedValueId : null;
            //      this.selectionItems.brandName = !isRemoved ? selectedValueName : null;
            //  }
            //  else if(ref == 'ref_category'){
            //      this.selectionItems.categoryId = !isRemoved ? selectedValueId : null;
            //      this.selectionItems.categoryName = !isRemoved ? selectedValueName : null;
            //  }
            //  else if(ref == 'ref_company'){
            //      this.selectionItems.companyId = !isRemoved ? selectedValueId : null;
            //      this.selectionItems.companyName = !isRemoved ? selectedValueName : null;
            //  }

            //  localStorage.setItem('transaction_filter', JSON.stringify(this.selectionItems));

            //  isRemoved ? this.fn_closeDialog() : '';

            //  this.fn_searchItem();
            // },

            // infiniteHandler($state) {
            //  this.loading = true;
            //  let filterObject = {}
            //  filterObject.type = this.settings.transactionTypeId;
            //  filterObject.limit = 10;
            //  filterObject.startIndex = this.startIndex;
            //  filterObject.search = this.searchValue.toLowerCase();

            //  filterObject.itemFilter = {};
            //  this.selectionItems.categoryId ? filterObject.itemFilter.categories = [this.selectionItems.categoryId] : '';
            //  this.selectionItems.brandId ? filterObject.itemFilter.brands = [this.selectionItems.brandId] : '';
            //  this.selectionItems.companyId ? filterObject.itemFilter.companies = [this.selectionItems.companyId] : '';

            //  let self = this;
            //  this.getItems("TransactionProductList", filterObject, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //              let responseJson = JSON.parse(response);
            //              self.listItemObject.totalCount = responseJson.totalCount;

            //              self.fn_getStockSummary(filterObject);

            //              for(let i in responseJson.items){
            //                  self.listItemObject.items.push(responseJson.items[i]);  
            //              }

            //              self.startIndex = self.listItemObject.items.length;

            //              if(self.listItemObject.items.length < self.listItemObject.totalCount){
            //                  $state.loaded();
            //              }
            //              else {
   //                               $state.complete(); 
   //                       }

   //                       self.loading = false;
   //                       self.searchItems = false;
            //          },
            //          onError: function (error) {
            //              self.loading = false;
            //              console.log(error);
            //          }
            //      }
            //  )
         //    },

            fn_loadItems: function(search) {
                // this.loading = true;
                this.loadMore = true;
                let filterObject = {}
                filterObject.type = 40001;
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';

                filterObject.itemFilter = {};
                // this.selectionItems.categoryId ? filterObject.itemFilter.categories = [this.selectionItems.categoryId] : '';
                // this.selectionItems.brandId ? filterObject.itemFilter.brands = [this.selectionItems.brandId] : '';
                // this.selectionItems.companyId ? filterObject.itemFilter.companies = [this.selectionItems.companyId] : '';
                filterObject.itemFilter.categories = this.selectedCategory && this.selectedCategory.itemId ? [this.selectedCategory.itemId] : [];
                filterObject.itemFilter.brands = this.selectedBrand && this.selectedBrand.itemId ? [this.selectedBrand.itemId] : [];
                filterObject.itemFilter.companies = this.selectedCompany && this.selectedCompany.itemId ? [this.selectedCompany.itemId] : [];

                this.fn_loadTimeOut();

                let self = this;
                this.getItems("TransactionProductList", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;
                            self.fn_getStockSummary(filterObject);

                            search ? self.listItemObject.items = [] : '';
                            for(let i in responseJson.items){
                                self.listItemObject.items.push(responseJson.items[i]);  
                            }

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300);
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                            self.loadTimeOut = false;
                        }
                    }
                )
            },

            fn_loadTimeOut: function(){
                setTimeout(() => {
                    if(this.loadMore){
                        this.loadTimeOut = true;
                        this.loadMore = false;
                    }
                    else{
                        this.loadTimeOut = false;
                    }
                }, 1 * 10 * 1000);
            },

            fn_searchItem: function () {
                this.startIndex = 0;
                this.listItemObject.items = [];
                this.fn_loadItems(true);
            },

            fn_listItems: function(val, type){
                console.log(val);
              let filterObject = {};
              // filterObject.search = val ? val.toLowerCase() : '';
              if(type == 'brand'){
                filterObject.listId = listIdObject.brandListGUID;
              }
              else{
                filterObject.listId = listIdObject.companyListGUID;
              }
              
              filterObject.search = '';
              let self = this;
              this.getDialogItems("ListItemDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    if(type == 'brand'){
                        self.brands = JSON.parse(response);
                    }
                    else{
                        self.companies = JSON.parse(response);
                    }
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_productCategries: function(val){
                console.log(val);
                let filterObject = {};
                // filterObject.search = val ? val.toLowerCase() : '';
                filterObject.search = '';
                let self = this;
                this.getDialogItems("ProductCategoryDialog", filterObject, 
                {
                    onSuccess: function (response) {
                    console.log(response);
                    self.productCategories = JSON.parse(response);
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_debounceSearch: function(){
                 if (this.timeoutid != null){
                    clearTimeout(this.timeoutid);
                 }

                this.timeoutid = setTimeout(() => { 
                    this.fn_searchItem();
                },100);
            },

            fn_getStockSummary: function (filterObject) {
                var self = this;

                this.getItems('OpeningStockSummaryList', filterObject,
                    {
                        onSuccess: function(response){
                            self.stockSummary = JSON.parse(response);
                            self.stockSummary.totalAmount = self.roundUpto2Decimals(self.stockSummary.totalAmount);
                            console.log(response);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            // fn_searchItem: function () {
            //  this.searchItems = true;
            //  this.startIndex = 0;
            //  this.infiniteId += 1;
            //  this.listItemObject.items = [];
            // },

            // fn_showWebView: function () {
            //  let viewObject = {};
            //  let self = this;

            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null, 
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },
        },
        mounted: function(){
            // tagId = this.$route.query.tagId;
            // this.formId = this.$route.query.tagId;
            // this.dialogStorageId = 'dialog_'+this.formId;

            // this.fn_showWebView();
            // window.js_globals.fn_onBackPress = this.fn_goBack;
            // window.js_globals.fn_closeClick = this.fn_closeDialog;

            let self = this;
            document.addEventListener('scroll', function () {
                self.scrollPositionTop = window.pageYOffset
            });

            if(localStorage.getItem('transaction_filter')){
                let data = JSON.parse(localStorage.getItem('transaction_filter'));
                this.selectionItems.brandId = data.brandId;
                this.selectionItems.brandName = data.brandName;
                this.selectionItems.categoryId = data.categoryId;
                this.selectionItems.categoryName = data.categoryName;
                this.selectionItems.companyId = data.companyId;
                this.selectionItems.companyName = data.companyName;
            }

            this.fn_loadItems();

            this.$eventHub.$on('UpdateOpeningStockList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });
        },
        // beforeRouteLeave (to, from, next) {
        //      var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  if(dialogData && dialogData.length){
        //      dialogjs.fn_checkAndCloseOpenDialogs(this);
        //      this.backButtonPress = false;
        //      next(false);
        //  }
        //  else if(this.qtySheet){
        //          this.qtySheet = false;
        //          this.backButtonPress = false;
        //          next(false);
        //      }
        //      else {
        //          this.backButtonPress ? localStorage.removeItem('transaction_filter') : '';
        //          next();
        //      }
        // }
    }
</script>