<template>
    <!-- <div style="height: 100vh;" :style="{'background': 'url(' + require('../../assets/dashboard_background.png') + ')'}" grey-back> -->
    <div style="height: 100vh;overflow:auto;" grey-back>

        <!-- <template v-if="!installAvailable">  
            <v-progress-circular color="primary" width="4" size="48" indeterminate style="position: absolute;margin: auto;left: 0;right: 0;top: 0;bottom: 0"></v-progress-circular>
        </template> -->

        <template>
            <template v-if="isBrowserSupported && !fn_mobileBrowser()">
                <v-toolbar>
                    <!-- <v-btn disabled icon class="hidden-xs-only" style="width:28px;margin:0">
                        <v-img src="../../assets/Final_BizOpsLogo.svg" :width="24"></v-img>
                    </v-btn> -->
                    <!-- <v-toolbar-title primary-color class="pl-2">BizOps App</v-toolbar-title> -->
                    <img src="../../assets/BizOpsAPPLogo.svg" height="28" alt="bizopsapp_logo">
                    <v-spacer></v-spacer>

                    <template v-if="appAlreadyInstalled">
                        <v-btn color="primary" capitalize v-if="isBrowserSupported && displayMode == 'browser tab'" @click="fn_openApp">
                            <!-- <img style="margin-right:8px" src="../../assets/install_desktop.png" width="24" alt=""> -->
                            Open BizOps App
                            <v-icon right>open_in_new</v-icon>
                        </v-btn>
                    </template>
                    <template v-else>
                        <v-btn color="primary" capitalize :loading="!installAvailable" :disabled="!installAvailable" v-if="isBrowserSupported && displayMode == 'browser tab'" @click="fn_installApp">
                            <img style="margin-right:8px" src="../../assets/install_desktop.png" width="24" alt="">
                            Download Desktop App
                        </v-btn>
                    </template>

                    <v-btn color="error" text capitalize @click="fn_signOut(1)" v-if="businessDetails"><v-icon left>logout</v-icon> Logout</v-btn>

                    <!-- <v-btn color="success" @click="$root.infoDrawer=true">text</v-btn> -->
                    <!-- <v-btn color="success" @click="fn_testTwilio">tkest</v-btn> -->
                    

                    <!-- <v-btn color="success" @click="fn_test">text</v-btn> -->
                </v-toolbar>    

                <div>
                    <template v-if="displayMode != 'browser tab'">

                        <v-card style="max-width:640px;margin: auto;transform: translate(0px, 25%);" center-align v-if="$root.offline">
                            <v-card-text>
                                <v-icon style="font-size:36px">wifi_off</v-icon>
                                <div class="title center-align pt-2">Internet not available!</div>
                            </v-card-text>
                        </v-card>

                        <v-card style="max-width:640px;margin: auto;transform: translate(0px, 25%);" center-align v-if="!$root.offline && errorInDirectLogin">
                            <v-card-text>

                                <template v-if="isWebRTCDisabled">
                                    <div class="title left-align pt-2">
                                        <p>WebRTC has been disabled in your browser</p>
                                        <p>Please check for "WebRTC Control" chrome extention and deactivate this if activated.</p>
                                    </div>

                                    <v-btn color="primary" class="mt-4" @click="fn_reloadApp">Retry</v-btn>
                                </template>
                                

                                <template v-else>
                                    <div class="title center-align pt-2">
                                        <template v-if="$route.query.connection_error">Connection Intrupted!</template>
                                        <template v-else>Something went wrong!</template>
                                    </div>

                                    <v-btn color="primary" class="mt-4" @click="fn_getToken">Retry</v-btn>
                                </template>
                                

                            
                            </v-card-text>
                        </v-card>

                        <!-- <template v-if="!businessDetails && !$root.hasClientBusinesses"> -->
                            <template v-if="!businessDetails">
                            <v-card style="max-width:720px;margin: auto;transform: translate(0px, 20%);" v-if="!isUserLoggedIn && !$root.offline && !errorInDirectLogin">
                                <v-card-title primary-color style="font-size:24px">Scan QR Code from your Mobile to login</v-card-title>
                                <v-card-text>
                                    <v-layout ma-0 row wrap>
                                        <v-flex xs12 sm6 md6 font20>
                                            <ol>
                                                <li style="line-height:48px">Open BizOps App on mobile</li>
                                                <li style="line-height:48px">Click on <b>More</b> > <b>Desktop Login</b></li>
                                                <li style="line-height:48px">Scan the QR Code</li>
                                                <!-- <li style="line-height:42px">Press <b>Continue</b></li> -->
                                            </ol>
                                        </v-flex>

                                        <v-flex xs12 sm6 md6 style="position: relative;height: 300px;">
                                            <!-- <v-progress-circular indeterminate color="primary" :width="2" style="margin: auto;position: absolute;right: 0;left: 0;top: 0;bottom: 0;" v-if="loading"></v-progress-circular> -->
                                            <v-img src="../../assets/connectivity_animation.gif" :height="245" v-if="loading"></v-img>
                                            <div class="pa-4 center-align white-back" v-if="$root.isOfferCreated && $root.connectionNumber && loading">
                                                Connection ID: 
                                                <v-tooltip bottom :max-width="280">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span class="primary-color cursor-pointer" v-bind="attrs" v-on="on">
                                                            <strong>{{$root.connectionNumber}}</strong>
                                                            <v-icon font20 class="primary-color ml-1" style="margin-bottom:2px">info_outline</v-icon>
                                                        </span>
                                                    </template>
                                                    <span>{{$root.fn_getConnectionMsg()}}</span>
                                                </v-tooltip>
                                            </div>
                                            <v-img :src="qrCode" height="100%" v-if="qrCode && !loading"></v-img>

                                            <v-btn icon color="primary" style="position: absolute;margin: auto;left: 0;right: 0;top: 0;bottom: 0;" v-if="errorInRequestId" @click="fn_getRequestId"><v-icon>refresh</v-icon></v-btn>

                                            <!-- <v-tooltip bottom v-if="isTakingMoreTime">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="primary" text capitalize dark v-bind="attrs" v-on="on" @click="fn_signOut" style="position: absolute;bottom: 0px;left: 0;right: 0;margin: auto;">
                                                        Try Re-Login
                                                    </v-btn>
                                                </template>
                                                <span>This is taking time more than expected. Try login again</span>
                                            </v-tooltip> -->
                                        </v-flex>

                                        <v-flex xs6 sm6 md6 mt-2 style="align-content: center;">
                                            <div font16><em>Complete above steps and press Continue</em></div>
                                        </v-flex>

                                        <v-flex xs6 sm6 md6 pr-9 mt-2>
                                            <div right-align><v-btn color="primary" @click="fn_customToken" :disabled="loading">Continue <v-icon right>chevron_right</v-icon></v-btn></div>
                                        </v-flex>
                                    </v-layout>
                                    
                                </v-card-text>
                            </v-card>
                        </template>
                        
                        <template v-else>
                            <v-card style="max-width:480px;margin: auto;transform: translate(0px, 10%);" v-if="!$root.offline && !errorInDirectLogin && businessDetails">
                                <!-- <v-card-title :class="[loading ? 'greycolor' : 'primary-color']"> -->
                                    <v-card-title :class="{'greycolor':loading}">
                                    {{ loading ? "Connecting.... Please wait" : "Select Business to continue" }}
                                </v-card-title>
                                <v-divider v-if="!loading"></v-divider>
                                <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
                                <v-card-text class="pa-0">
                                    
                                    <v-list three-line style="max-height:400px;overflow:auto">
                    
                                        <template v-if="businessDetails.MyBusiness && businessDetails.MyBusiness.length">

                                            <template v-for="(item, index) in businessDetails.MyBusiness">
                                                <v-list-item :disabled="loading" :key="'mybusiness'+index" @click="fn_selectBusiness(item, 'self')">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{item.BusinessName}}</v-list-item-title>
                                                        <v-list-item-subtitle class="mt-1" v-if="item.BusinessUserName">{{item.BusinessUserName}}</v-list-item-subtitle>
                                                        <v-list-item-subtitle class="mt-1">
                                                            <v-chip :disabled="loading" class="ma-0" :height="0" small label>My Business</v-chip>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle class="mt-1" v-if="$root.connectionNumber && item.loadingBusiness">Connection ID: {{$root.connectionNumber}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-icon style="margin:auto">
                                                        <!-- <v-img src="../../assets/connectivity_animation.gif" width="120" height="120" v-if="item.loadingBusiness"></v-img> -->
                                                        <!-- <v-btn small color="primary" v-else>connect <v-icon>chevron_right</v-icon></v-btn> -->

                                                        <!-- <template v-if="item.loadingBusiness">
                                                            <v-menu left>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn icon small v-bind="attrs" v-on="on"><v-icon font20>more_vert</v-icon></v-btn>
                                                                </template>

                                                                <v-list dense nopadding>
                                                                    <v-list-item @click="fn_selectBusiness(item, 'self')">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title primary-color font-heavy>Reconnect</v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </template> -->
                                                        <v-progress-circular indeterminate width="2" color="grey" size="24" v-if="item.loadingBusiness && loading"></v-progress-circular>
                                                        <v-icon v-else>chevron_right</v-icon>
                                                    </v-list-item-icon>
                                                </v-list-item>  

                                                <!-- <div right-align :key="'reconnect'+index" v-if="item.loadingBusiness"><v-btn small color="primary" @click="fn_selectBusiness(item, 'self')">reconnect</v-btn></div> -->
                                                <v-divider v-if="businessDetails.MyBusiness.length-1 != index" :key="item.BusinessID"></v-divider>
                                            </template>
                                            
                                        </template>

                                        <template v-for="(item, index) in businessDetails.ClientBusinesses">
                                            <v-list-item :disabled="loading" @click="fn_selectBusiness(item, 'client')" :key="'clientbusiness'+index">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{item.BusinessName}}</v-list-item-title>
                                                    <v-list-item-subtitle class="mt-1" v-if="item.BusinessUserName">{{item.BusinessUserName}}</v-list-item-subtitle>
                                                    <v-list-item-subtitle class="mt-1">
                                                        <v-chip :disabled="loading" class="ma-0" :height="0" small label>Client Business</v-chip>
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle class="mt-1" v-if="$root.connectionNumber && item.loadingBusiness">Connection ID: {{$root.connectionNumber}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-icon style="margin:auto">
                                                    <!-- <v-progress-circular color="#dddddd" :width="2" :size="24" indeterminate v-if="item.loadingBusiness"></v-progress-circular> -->
                                                    <v-img src="../../assets/connectivity_animation.gif" width="120" height="120" v-if="item.loadingBusiness"></v-img>
                                                    <v-icon v-else>chevron_right</v-icon>
                                                </v-list-item-icon>
                                            </v-list-item>
                                            <v-divider v-if="businessDetails.ClientBusinesses.length-1 != index" :key="item.BusinessID"></v-divider>
                                        </template>
                                        
                                
                                            
                                    </v-list>
                                    
                                </v-card-text>

                                <!-- <v-card-text redcolor>
                                    Notes:
                                    <ul>
                                        <li>Your mobile device must have an active internet connection.</li>
                                        <li>Enable mobile server on your BizOps mobile app.</li>
                                    </ul>
                                </v-card-text> -->
                            </v-card>
                        </template>

                    </template>

                    <template v-else>
                        <div style="margin:0px 80px">
                            <v-layout ma-0 row wrap>
                                <v-flex xs12 sm6 md6>
                                    <img src="../../assets/AccountantAccess_500x500.png" alt="">
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <div style="margin-top:120px">
                                        <h1 style="font-size:50px;line-height:64px;margin-bottom:16px">Download <span class="primary-color">BizOps App</span> for Accounting &amp; Billing</h1>
                                        <p style="font-size:21px">Create invoice instantly, track your expenses, manage inventory, GST &amp; accounting reports etc. Which makes your accounting done easy.</p>
                                        
                                        <template v-if="appAlreadyInstalled">
                                            <v-btn x-large capitalize color="primary" v-if="isBrowserSupported && displayMode == 'browser tab'" @click="fn_openAppBlank">
                                                Open BizOps App »
                                            </v-btn>
                                        </template>
                                        <template v-else>
                                            <v-btn x-large capitalize color="primary" :loading="!installAvailable" :disabled="!installAvailable" v-if="isBrowserSupported && displayMode == 'browser tab'" @click="fn_installApp">
                                                Get Started for Free »
                                            </v-btn>
                                        </template>
                                        
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                    </template>

                </div>

                <dialog-loading ref="ref_dialogLoading" :message="dialogMessage"></dialog-loading>
                <dialog-action ref="ref_dialogAction" :message="dialogMessage" :actionMethod="fn_signOut" btnText="Retry"></dialog-action>
            </template>
        
            <template v-else>
                <div class="pa-4 light-opacity-color">
                    <h2>This browser is not supported. Kindly use Google Chrome or Microsoft Edge on your computer/desktop.</h2>
                </div>
            </template>
        </template>

        <v-dialog v-model="dialogAppOpenSteps" persistent max-width="500px" transition="dialog-transition">
            <v-card flat>
                <v-card-title>Desktop app already installed!</v-card-title>
                <v-card-text class="pt-3">
                    <div class="pt-2 pb-2"><strong>BizOps desktop app has already been installed on your computer. You can open app from your computer desktop. <v-img src="../../assets/desktop_step.png" style="width: 200px;border: 4px solid #666;margin: auto;margin-top: 12px;margin-bottom: 12px;"></v-img> Or find in apps/programs.</strong></div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="primary" text @click="dialogAppOpenSteps=false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        

    </div>

</template>

<script>

  import axios from 'axios';

  import QRCode from 'qrcode'

  import dialogLoading from '../dialogs/dialog_loading'
  import dialogAction from '../dialogs/dialog_action'
//   import dialogBusinessList from '../dialogs/dialog_business_list'

//   setInteractionMode('eager')

//   extend('digits', {
//     ...digits,
//     message: '{_field_} needs to be {length} digits. ({_value_})',
//   })

//   extend('required', {
//     ...required,
//     message: '{_field_} can not be empty',
//   })

//   extend('max', {
//     ...max,
//     message: '{_field_} may not be greater than {length} characters',
//   })

    // import firebase from 'firebase/app';
    // import 'firebase/firestore';

    // const firebaseConfig = {
    //   // your config
    //   apiKey: "AIzaSyBqe1wRXiA9WS34YYQrUemK1B5Rw4hK8TE",
    //   authDomain: "bizopsstaging.firebaseapp.com",
    //   databaseURL: "https://bizopsstaging.firebaseio.com",
    //   projectId: "bizopsstaging",
    //   storageBucket: "bizopsstaging.appspot.com",
    //   messagingSenderId: "22911752863",
    //   appId: "1:22911752863:android:ec46bf57ddc3662d9c60aa",
    // };

    // if (!firebase.apps.length) {
    //   firebase.initializeApp(firebaseConfig);
    // }

    // const firestore = firebase.firestore();

    // const servers = {
    //     iceServers: [
    //         {
    //             urls:['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
    //         },
    //     ],
    //     iceCandidatePoolSize:20,
    // };

    export default {
        components: {
            // ValidationProvider,
            // ValidationObserver,
            'dialog-loading':dialogLoading,
            'dialog-action':dialogAction,
            // 'dialog-business-list':dialogBusinessList
        },

        data: () => ({
            phoneNumber: null,
            isConnecting:false,
            requestId:null,
            qrCode:null,
            text_nav:false,
            loading:false,
            dialogMessage:"Please wait...",
            isUserLoggedIn:true,
            errorInDirectLogin:false,
            errorInRequestId:false,
            businessDetails:null,//{"MyBusiness":[{"BusinessID":"89e0331c-aae3-4279-905f-63c284f9b161","TempBusinessID":null,"BusinessName":"Abc Business","UserType":1,"UserID":"63cc8cd1-52bf-4d82-9a04-a3731a2ef901"}],"ClientBusinesses":[{"BusinessID":"bf68a06f-6944-449e-8565-b8377d214ba6","TempBusinessID":null,"BusinessName":"Ajio Biz","UserType":1,"UserID":"63cc8cd1-52bf-4d82-9a04-a3731a2ef901"}],"TempBusiness":null,"IsTemporary":false,"IsSupport":true,"IsDeveloper":false},
            deferredPrompt:null,
            displayMode:null,
            isBrowserSupported:false,
            days:null,
            hours:null,
            minutes:null,
            seconds:null,
            invoiceFor:null,
            invoiceForText:"Standard",
            invoiceTypes:[
                {"name":"Standard"}, 
                {"name":"Original For Recipient"},
                {"name":"Duplicate For Transporter"},
                {"name":"Duplicate For Supplier"},
                {"name":"Triplicate For Supplier"}
            ],
            requestKey:null,
            isWebRTCDisabled:false,
            installAvailable:false,
            appAlreadyInstalled:false,
            isTakingMoreTime:false,
            clearTimer:null,
            dialogAppOpenSteps:false
        }),

    methods: {

        fn_invoiceFor: function(item){
            this.$nextTick(() => {
                this.invoiceForText = item.name;
            })
        },

        // fn_testTwilio: function(){
        //     // const accountSid = process.env.VUE_APP_BASE_TWILIO_ACCOUNT_SID;
        //     // const authToken = process.env.VUE_APP_BASE_TWILIO_AUTH_TOKEN;
        //     const client = require('twilio')(process.env.VUE_APP_BASE_T_SID, process.env.VUE_APP_BASE_T_AT);
        //     client.tokens.create().then(token => console.log(token));
        // },

        fn_timer: function(){
            // Set the date we're counting down to
            var countDownDate = new Date("Jan 31, 2022 11:59:59").getTime();

            // Update the count down every 1 second
            let self = this;
            var x = setInterval(function() {

            // Get today's date and time
            var now = new Date().getTime();
                
            // Find the distance between now and the count down date
            var distance = countDownDate - now;
                
            // Time calculations for days, hours, minutes and seconds
            self.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            self.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            self.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            self.seconds = Math.floor((distance % (1000 * 60)) / 1000);
                
            // Output the result in an element with id="demo"
            // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
            // + minutes + "m " + seconds + "s ";
                
            //If the count down is over, write some text 
            if (distance < 0) {
                clearInterval(x);
                // document.getElementById("demo").innerHTML = "EXPIRED";
            }
            }, 1000);
        },

        fn_test: function(){
            this.$eventHub.$emit('snackbarAlert', {msg:'A quick brown fox jumps over the lazy dog'});
        },

        fn_openDialogBusinessList: function(){
            this.$refs['ref_dialogBusinessList'].openDialogWeb();
        },

        fn_closeDialogBusinessList: function(){
            this.$refs['ref_dialogBusinessList'].closeDialogWeb();
        },

        test: function(){
            this.$router.push("/dsd3232sds/dashboard");
        },

        // submit () {
        //     this.$refs.observer.validate().then((result) => {
        //         console.log(result);
        //     })
        // },

        fn_init: function(){
            this.isConnecting = true;
            this.fn_getDeviceDetails();
        },

        fn_getRequestId: function(){
            this.requestKey = this.guid();
            this.isUserLoggedIn = false;
            this.errorInRequestId = false;
            this.loading = true;
            this.qrCode = null;
            console.log(process.env.VUE_APP_BASE_API_URL);
            axios.get(this.$root.baseApiUrl + '/api/desktop/login/request?requestkey='+this.requestKey, {timeout:30000})
            .then(response => { 
                console.log(JSON.stringify(response));
                this.requestId = response.data.RequestID;
                this.fn_generateQrCode();
            }).catch(e => {
                console.log(e);
                this.loading = false;
                this.errorInRequestId = true;
            })
        },

         fn_generateQrCode: function(){
            QRCode.toDataURL(this.requestId).then(url => {
                console.log(url);
                this.qrCode = url;
                this.errorInRequestId = false;
                this.loading = false;
                // this.fn_setRequestOnFB(this.fn_customToken, this.fn_expireQrCode, this.requestKey);

                setTimeout(() => {
                    this.fn_expireQrCode();
                }, 5 * 60 * 1000);
            })
            .catch(err => {
                console.error(err);
                this.qrCode = null;
                this.errorInRequestId = true;
            })
        },

        fn_expireQrCode: function(){
            this.qrCode = null;
            this.loading = false;
            this.errorInRequestId = true;
        },

        fn_customToken: function(){
            this.loading = true;

            this.clearTimer = setTimeout(() => {
                this.isTakingMoreTime = true;
            }, 3*60*1000);
            
            axios.get(this.$root.baseApiUrl + '/api/desktop/login/request/'+this.requestId+'/gettoken?requestkey='+this.requestKey, {timeout:30000})
            .then(response => { 
                console.log(JSON.stringify(response));
                if(response.data && response.data.Token){
                    this.fn_signInWithCustomToken(response.data.Token);
                }
            }).catch(e => {
                console.log(e);
                this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong!'});
                this.fn_getToken();
                this.loading = false;
            })
        },

        fn_signInWithCustomToken(customToken){
            let self = this;
            this.signInWithCustomToken(customToken, function(response){
                if(response=='error'){
                    console.log("Something went wrong, while signin with custom token on firebase!");
                    self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong!'});
                    self.errorInDirectLogin = true;
                    self.loading = false;
                    return
                }

                self.getFirebaseToken(function(token, userId){
                    if(token=="error"){
                        console.log("Something went wrong, while getting id token from firebase!");
                        self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong!'});
                        self.errorInDirectLogin = true;
                        self.loading = false;
                        return;
                    }
                    else{
                        self.fn_getBusinesses(token, userId);
                    }
                })
            })
        },

        fn_getBusinesses: function(token, userId, directLogin){
            var headersObj = {
            headers: { 
                  'Authorization': 'bearer '+token,
                }
            };

            let url = null;
            if(directLogin){
                url = this.$root.baseApiUrl + '/api/desktop/login/request/getbusinessdetails';
            }
            else{
                url = this.$root.baseApiUrl + '/api/desktop/login/request/'+this.requestId+'/getbusiness'
            }

            axios.get(url, headersObj, {timeout:30000})
            .then(response => { 
                console.log(JSON.stringify(response));

                // if(directLogin && (response.data.IsSupport || response.data.IsDeveloper)){
                if(directLogin && response.data.IsSupport){
                    this.fn_signOut();
                }
                else{
                    if((response.data.MyBusiness && response.data.MyBusiness.length) || (response.data.ClientBusinesses && response.data.ClientBusinesses.length)){
                        this.businessDetails = response.data;
                        this.$refs['ref_dialogLoading'].closeDialogWeb();
                        this.loading = false;

                        if(response.data.ClientBusinesses && response.data.ClientBusinesses.length){
                            this.$root.hasClientBusinesses = true;
                        }
                    }
                    else{
                        this.loading = false;
                        if(response.data.IsTemporaryBusinessDeactivated){
                            this.$eventHub.$emit('snackbarAlert', {msg:'Business has been deactivated by user!'});
                        }
                        else{
                            this.$eventHub.$emit('snackbarAlert', {msg:'You are not added in any business yet!'});
                        }
                        this.fn_signOut();
                    }

                    // if(response.data.ClientBusinesses && response.data.ClientBusinesses.length){
                    //     this.$root.hasClientBusinesses = true;
                    //     this.businessDetails = response.data;
                    //     this.$refs['ref_dialogLoading'].closeDialogWeb();
                    //     this.loading = false;
                    // }
                    // else if(response.data.MyBusiness && response.data.MyBusiness.length){
                    //     this.businessDetails = response.data;
                    //     let businessData = {};
                    //     this.$root.mainBusinessID = response.data.MyBusiness[0].BusinessID;
                    //     businessData.BusinessID = response.data.MyBusiness[0].BusinessID;
                    //     businessData.Status = response.data.MyBusiness[0].Status;
                    //     businessData.type = 'self';
                    //     businessData.IsTemporary = response.data.IsTemporary;
                    //     businessData.TempBusinessID = response.data.MyBusiness[0].TempBusinessID;
                    //     businessData.hasBusinessList = false;
                    //     this.$refs['ref_dialogLoading'].closeDialogWeb();
                    //     this.loading = false;
                    //     this.fn_selectBusiness(this.businessDetails.MyBusiness[0], 'self')
                    // }
                    // else{
                    //     this.loading = false;
                    //     if(response.data.IsTemporaryBusinessDeactivated){
                    //         this.$eventHub.$emit('snackbarAlert', {msg:'Business has been deactivated by user!'});
                    //     }
                    //     else{
                    //         this.$eventHub.$emit('snackbarAlert', {msg:'You are not added in any business yet!'});
                    //     }
                    //     this.fn_signOut();
                    // }
                }
                

            }).catch(e => {
                console.log(e);
                this.$refs['ref_dialogLoading'].closeDialogWeb();
                if(directLogin){
                    this.errorInDirectLogin = true;
                    this.loading = false;
                }
            })
        },

        fn_selectBusiness: function(business, type){
            //   storing main business id
            this.$root.mainBusinessID = business.BusinessID;
            let selectedItem = {};
            selectedItem.type = type;
            selectedItem.BusinessID = business.BusinessID;
            selectedItem.Status = business.Status;
            selectedItem.UserID = business.UserID;
            selectedItem.ServerUserID = business.ServerUserID;
            selectedItem.IsTemporary = this.businessDetails.IsTemporary;
            // selectedItem.TempBusinessID = this.businessDetails.TempBusinessID;
            selectedItem.TempBusinessID = business.TempBusinessID;
            selectedItem.hasBusinessList = true;
            business.loadingBusiness = true;
            this.loading = true;
            // this.fn_getBusinessUserToken(selectedItem, selectedItem.UserID);
            this.fn_createConnection(selectedItem, selectedItem.UserID);
        },

        // fn_getBusinessUserToken: function(businessData, userId){
        //     let self = this;
        //     this.getFirebaseToken(token=>{
        //         if(token=="error"){
        //             console.log("Something went wrong, while getting id token from firebase!");
        //             this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong!'});
        //             this.errorInDirectLogin = true;
        //             this.loading = false;
        //             return;
        //         }
        //         else{
        //             var headersObj = {
        //             headers: { 
        //                 'Authorization': 'bearer '+token,
        //                 }
        //             };
                    
        //             // axios.get(this.$root.baseApiUrl + '/api/user/'+businessData.BusinessID+'/gettoken', headersObj, {timeout:30000})
        //             axios.get(this.$root.baseApiUrl + '/api/user/getbusinesstoken?businessid='+businessData.BusinessID, headersObj, {timeout:30000})
        //             .then(response => { 
        //                 self.fn_signInWithBusinessUserCustomToken(response.data.Token, businessData, userId)
        //             }).catch(e => {
        //                 self.errorInRequestId = true;
        //                 self.loading = false;
        //                 console.log(e);
        //             })
        //         }
        //     })
        // },

        // fn_signInWithBusinessUserCustomToken(customToken, businessData, userId){
        //     let self = this;
        //     this.signInWithCustomToken(customToken, function(response){
        //         if(response=='error'){
        //             console.log("Something went wrong, while signin with custom token on firebase!");
        //             this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong!'});
        //             this.errorInDirectLogin = true;
        //             this.loading = false;
        //             return
        //         }

        //         // storing login business to local storage
        //         localStorage.setItem("last_business_login", businessData.BusinessID);
        //         self.fn_createConnection(businessData, userId);
        //     })
        // },

        fn_createConnection: function(businessData, userId){
            if(businessData.Status === 0){
                console.log("This business has been deactivated!");
                this.$eventHub.$emit('snackbarAlert', {msg:'This business has been deactivated!'});
                this.fn_signOut();
            }
            else{
                this.$root.fn_getTwilioIceServers(response => {
                    if(response == 'error'){
                        this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Please try again'});
                        this.loading = false;
                        businessData.loadingBusiness = false;
                        // this.fn_signOut();
                        return;
                    }
                    
                    this.fn_checkDeviceStatus(businessData, this.fn_sendNotification, this.fn_getBusinessDetails, userId, this.$root.fn_dialogReconnect, this.$root.reconnectObj, this.fn_getConnectionIdFromServer, response, this.fn_connectionTimeOut, this.$root.fn_initConnectionRequest);
                });
            }
        },

        // fn_connectionTimeOut: function(businessType){
        fn_connectionTimeOut: function(){
            // let businessList = businessType == 'self' ? this.businessDetails.MyBusiness : this.businessDetails.ClientBusinesses;
            // for(let i=0; i<businessList.length; i++){
            //     businessList[i].loadingBusiness = false;
            // }
            for(let i=0; i<this.businessDetails.MyBusiness.length; i++){
                this.businessDetails.MyBusiness[i].loadingBusiness = false;
            }

            for(let i=0; i<this.businessDetails.ClientBusinesses.length; i++){
                this.businessDetails.ClientBusinesses[i].loadingBusiness = false;
            }

            this.loading = false;
            this.$root.connectionNumber = null;
        },

        fn_signOut: function(clearBusinessDetails){
            let self = this;
            // this.$refs['ref_dialogAction'].dialog_action = false;
            // this.$refs['ref_dialogLoading'].dialog_loading = false;
            self.$refs['ref_dialogAction'] ? self.$refs['ref_dialogAction'].closeDialogWeb() : '';
            self.$refs['ref_dialogLoading'] ? self.$refs['ref_dialogLoading'].closeDialogWeb() : '';
            this.signOut(function(response){
                if(response=='success'){
                    if(clearBusinessDetails === 1){
                        self.$root.hasClientBusinesses = false;
                        self.businessDetails = null;
                    }
                    self.fn_getRequestId();
                }
                else{
                    self.dialogMessage = "Something went wrong while signing out!"
                    // self.$refs['ref_dialogAction'].dialog_action = true;
                    self.$refs['ref_dialogAction'] ? self.$refs['ref_dialogAction'].openDialogWeb() : '';
                }
            })
        },

        // fn_sendNotification: function(connectionId, priority, callback){
        fn_sendNotification: function(businessId){
            this.getFirebaseToken(token=>{
                if(token=="error"){
                    // alert("Something went wrong!");
                    this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong!'});
                    return;
                }

                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                    }
                };

                // let sessionId = localStorage.getItem('sessionid');

                // axios.get(this.$root.baseApiUrl + '/api/user/datasharing?connectionid='+connectionId+'&priority='+priority+'&sessionid='+sessionId, headersObj, {timeout:30000})
                axios.get(this.$root.baseApiUrl + '/api/desktop/' + businessId + '/datasharing?priority=true', headersObj, {timeout:30000})
                .then(response => {
                    console.log(JSON.stringify(response));
                    // callback ? callback(response, this.fn_signOut) : '';
                    // callback ? callback(response) : '';
                }).catch(e => {
                    console.log(e);
                })
            })
        },

        fn_getConnectionIdFromServer: function(businessData, callback){

            this.getFirebaseToken(token=>{
                if(token=="error"){
                    if(this.$root.reconnectObj){
                        this.$root.reconnectObj.isConnecting = false;
                        this.$root.reconnectObj.isDisconnected = false;
                    }
                    console.log("Something went wrong, while getting id token from firebase!");
                    this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong!'});
                    this.errorInDirectLogin = true;
                    this.loading = false;
                    return;
                }
                
                var headersObj = {
                headers: { 
                    'Authorization': 'bearer '+token,
                    }
                };
                
                let postData = {"BusinessID":businessData.BusinessID, "TempBusinessID":businessData.TempBusinessID};
                // axios.post(this.$root.baseApiUrl + '/api/login/desktop/request/connectiondetails', postData, headersObj, {timeout:30000})
                axios.post(this.$root.baseApiUrl + '/api/desktop/login/request/connectiondetails', postData, headersObj, {timeout:30000})
                .then(response => { 
                    this.$root.connectionNumber = response.data.ConnectionNumber;
                    callback(response);
                }).catch(e => {
                    // reset reconnect dialog of reconnecting
                    if(this.$root.reconnectObj && this.$root.reconnectObj.isConnecting){
                        this.$root.reconnectObj.isConnecting = false;
                        this.$root.reconnectObj.isDisconnected = false;
                        this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong!'});
                    }
                    // logout and close connection if login from start
                    else{
                        this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong, Please login again to continue!'});
                        this.fn_closeConnection(true, null, 1);
                    }
                    console.log(e);
                })

                // self.fn_login(token)
            })
        },

        // fn_getDeviceDetails: function(phoneNumber, userId){
        //     // let postData = {"PhoneNumber":"91"+this.phoneNumber};
        //     let postData = {"PhoneNumber":phoneNumber};
        //     axios.post(this.$root.baseApiUrl + '/api/user/getcurrentdevice', postData, {timeout:30000})
        //     .then(response => { 
        //         console.log(JSON.stringify(response));
        //         // let deviceDetails = response;
        //         this.fn_checkDeviceStatus(response.data.DeviceID, this.fn_sendNotification, this.fn_getBusinessDetails, userId);
        //     }).catch(e => {
        //         console.log(e);
        //     })
        // },

        fn_getBusinessDetails: function(connectionId, connectionType){
            let self = this;
            this.$root.fn_getBusiness(null, null, function(){
                // console.log('getting states');
                self.$root.fn_generateUniqueIds();
                self.$root.fn_states();
                // getting app version saved in android local cache
                self.$root.fn_getLocalData('app_version', response=>{
                    self.$root.appVersion = response;
                    if(self.$root.appVersion < 117){
                        self.$eventHub.$emit('UpdateMobileApp', {onload:1});
                    }
                    // getting products on connection stablish
                    self.$root.fn_getMasterItems('products', ["Product", "deleteProductWithOpening"], true, true); 
                })
                // self.$root.fn_getMasterItems('products', ["Product", "deleteProductWithOpening"], true, true);
                self.$refs['ref_dialogLoading'] ? self.$refs['ref_dialogLoading'].closeDialogWeb() : '';
                self.loading = false;
                self.isConnecting = false;
                if(!self.$root.reconnectObj.isReconnecting){
                    self.$router.replace({path:'/'+self.$root.CurrentBusiness.BusinessID+"/dashboard"});
                }
                self.$root.reconnectObj.isReconnecting = false;
                connectionId && connectionType ? self.fn_postConnectionType(connectionId, connectionType) : '';
                // self.fn_getAndroidAppVer();
            });
        },

        // posting connection type relay/stun to android to add on firebase
        fn_postConnectionType: function(connectionId, connectionType){
            let postObj = {'connectionId':connectionId, 'connectionType':connectionType};
            // alert(JSON.stringify(postObj));
            this.postData('ClientConnectionType', postObj, {
                onSuccess: function(response){
                    console.log(response);
                },
                onError: function(error){
                    console.log(error);
                }
            });
        },

        fn_installApp: async function(){
            if (this.deferredPrompt !== null) {
                this.deferredPrompt.prompt();
                const { outcome } = await this.deferredPrompt.userChoice;
                if (outcome === 'accepted') {
                    this.deferredPrompt = null;
                    window.location.reload();
                }
            }
        },

        fn_openApp: function(){
            this.dialogAppOpenSteps = true;
            // if(process.env.NODE_ENV == 'staging'){
            //     window.open("https://uatdesktopapp.bizopsapp.com");
            // }
            // else{
            //     window.open("https://desktopapp.bizopsapp.com");
            // }
        },

        fn_openAppBlank: function(){
            this.dialogAppOpenSteps = true;
            // if(process.env.NODE_ENV == 'staging'){
            //     window.open("https://uatdesktopapp.bizopsapp.com", "_blank");
            // }
            // else{
            //     window.open("https://desktopapp.bizopsapp.com", "_blank");
            // }
        },

        fn_reloadApp: function(){
            location.reload();
            return;
        },

        fn_getToken: function(){
            if(!navigator.onLine){
                this.errorInDirectLogin = true;
                console.log("Internet not available!");
                this.$eventHub.$emit('snackbarAlert', {msg:'Internet not available'});
                return;
            }

            if(!RTCPeerConnection){
                this.isWebRTCDisabled = true;
                this.errorInDirectLogin = true;
                this.$eventHub.$emit('snackbarAlert', {msg:'WebRTC has been disabled in your browser'});
                return;
            }

            this.$root.hasClientBusinesses = false;
            this.$root.reconnectObj.isReconnecting = false;
            this.businessDetails = null;
            this.$root.connectionNumber = null;
            this.$root.isOfferCreated = false;

            // check user if already looged in
            this.errorInDirectLogin = false;

            this.isTakingMoreTime = false;
            this.clearTimer != null ? clearTimeout(this.clearTimer) : '';

            this.$forceUpdate();

            this.getUser(userId=>{
                // alert(userId);
                if(userId){

                    // this.directLogin = true;
                    this.$refs['ref_dialogLoading'] ? this.$refs['ref_dialogLoading'].openDialogWeb() : '';
                    this.dialogMessage = "Connecting..."
                    // get token from firebase if user already logged in
                    this.getFirebaseToken(token=>{
                        // retry in case of error
                        if(token == "error"){
                            // alert("token error");
                            this.errorInDirectLogin = true;
                            this.loading = false;
                            this.$refs['ref_dialogLoading'].closeDialogWeb();
                        }
                        else{
                            // alert(token);
                            this.fn_getBusinesses(token, userId, true);
                        }
                    })    
                }
                else{
                    // get request id from server if user not logged in
                    this.fn_getRequestId();
                }
                
            })
            // this.getFirebaseToken(function(token){
            //     if(token == "error"){
            //         alert("error");      
            //     }
            //     else{
            //         alert(token);
            //     }
            // })
        },

        fn_mobileBrowser: function(){
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];
            
            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        }
    },
    mounted: function(){
        // alert(process.env.NODE_ENV);
        // alert(process.env.VUE_APP_TITLE);
        // localStorage.clear();

        let sessionId = null;
        if(!localStorage.getItem('sessionid')){
            sessionId = this.guid();
            localStorage.setItem('sessionid', sessionId);
        }
        else{
            sessionId = localStorage.getItem('sessionid');
        }

        // clearing localstorage on app load except session id and transaction drafts
        for(let key in localStorage){
            // if(key !== 'sessionid' && key.indexOf('draft_transaction_') === -1){
            // if(key !== 'sessionid'){
            if(key !== 'sessionid' && key.indexOf('_ls_') === -1 && key.indexOf('last_business_login') === -1){
                localStorage.removeItem(key);
            }
        }

        if(navigator.userAgent.indexOf("Chrome") != -1 || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)){
            this.isBrowserSupported = true;
        }

        window.addEventListener('beforeinstallprompt', (e) => {
            this.appAlreadyInstalled = false;
            this.installAvailable = true;
            this.deferredPrompt = e;
        });

        setTimeout(() => {
            this.appAlreadyInstalled = !this.deferredPrompt ? true : false;
        }, 10000);

        if (navigator.standalone) {
            this.displayMode = 'standalone-ios';
        }
        if (window.matchMedia('(display-mode: standalone)').matches) {
            this.displayMode = 'standalone';
        }

        if(!this.displayMode || this.displayMode=='browser tab'){
            this.displayMode = "browser tab";
        }
        // Log launch display mode to analytics
        console.log('DISPLAY_MODE_LAUNCH:', this.displayMode);

        document.title = "BizOps App - Accounting & GST Billing Software";

        process.env.NODE_ENV === 'development' || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i) ? this.displayMode = 'testing' : '';
        if(this.isBrowserSupported && this.displayMode != 'browser tab'){
            // document.title = '';
            if(!this.$route.query.connection_error){
                this.fn_getToken();
            }
            else{
                this.errorInDirectLogin = true;
            }
        }

        this.$eventHub.$on('refreshLogin', ()=>{
            if(this.$refs['ref_dialogLoading']){
                this.$refs['ref_dialogLoading'].closeDialogWeb();
            }
            this.fn_getToken();
        });

        // this.$eventHub.$on('offercreated', ()=>{
        //     alert("in");
        //     this.isOfferCreated = true;
        // });

        window.addEventListener('appinstalled', function() {
            console.log('Thank you for installing our app!');
            window.location.reload();
        });

        // getInstalledRelatedApps works only in android phones
        // if ('getInstalledRelatedApps' in window.navigator) {
        //     navigator.getInstalledRelatedApps().then((relatedApps) => {
        //         console.log(relatedApps);
        //         console.log(relatedApps.length);
        //         relatedApps.forEach((app) => {
        //             console.log(app);
        //         });
        //     });
        // }
        // this.fn_getRequestId();
        // this.fn_generateQrCode();
        // this.errorInDirectLogin = false;
        // this.isUserLoggedIn = false;
    }
}


</script>