<template>
    <div>
        <div class="display_wrapper" id="non-printable">

            <v-toolbar flat :height="56">
                <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>

                <v-toolbar-title :class="{'two-line-title':offsetTop>=36}">
                    {{toolbarTitle}}
                    <div class="toolbar-subtitle" v-if="displayData && offsetTop>=36">
                        <div class="subtitle-text dark-opacity-color">
                            {{displayData.mergeTransactionNumber}}
                        </div>
                    </div>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn small outlined color="primary" @click="fn_preview" v-if="displayData">
                    <v-icon left>preview</v-icon> Preview
                </v-btn>

                <v-btn class="ml-4" color="primary" small outlined @click="fn_edit" v-if="displayData && displayData.status==1">
                    <v-icon left>edit</v-icon> Edit
                </v-btn>

                <v-btn class="ml-4" small outlined color="warning" v-if="displayData && displayData.status==1" @click="fn_cancel">
                    <v-icon left>close</v-icon> Cancel
                </v-btn>

                <v-btn class="ml-4" small outlined color="red" v-if="displayData" @click="fn_delete">
                    <v-icon left>delete</v-icon> Delete
                </v-btn>

                <!-- <v-btn icon @click="fn_edit" v-if="displayData && displayData.status==1">
                    <v-icon>edit</v-icon>
                </v-btn> -->
            </v-toolbar>

            <v-divider></v-divider>

            <div class="data-container grey-back" v-if="displayData" v-scroll="onScrollTransactionForm">

                <v-alert :height="40" class="pa-2 no-border-radius mb-0" :value="displayData && displayData.status==0" type="warning" icon="info_outline" transition="slide-y-transition">
                    <span>This transaction has been cancelled!</span>
                </v-alert>

                <v-layout row wrap pa-4 ma-0 font16 white-back>
                    <v-flex xs6 sm6 md6>{{displayData.mergeTransactionNumber}}</v-flex>
                    <v-flex xs6 sm6 md6 right-align>{{displayData.transactionDate | moment("DD-MMM-YYYY")}}</v-flex>
                </v-layout>

                <!-- <v-list class="pa-0" nopadding>
                    <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{displayData.mergeTransactionNumber}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-icon>{{displayData.transactionDate | moment("DD-MMM-YYYY")}}</v-list-item-icon>
                    </v-list-item>
                </v-list> -->

                <!-- <v-layout grid wrap text-xs-center>
                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('sharewhatsapp')">
                        <div right-border>
                            <div><v-icon>send</v-icon></div>
                            <div class="font-weight-bold">Send</div>
                        </div>  
                    </v-flex>

                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('print')">
                        <div right-border>
                            <div><v-icon>print</v-icon></div>
                            <div class="font-weight-bold">Print</div>
                        </div>
                    </v-flex>

                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('pdf')">
                        <div right-border>
                            <div><v-icon>import_export</v-icon></div>
                            <div class="font-weight-bold">Export</div>
                        </div>
                    </v-flex>

                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_getBottomMenu">
                        <div>
                            <div><v-icon>more_horiz</v-icon></div>
                            <div class="font-weight-bold">More</div>
                        </div>
                    </v-flex>
                </v-layout> -->

                
                <div class="mt-2">

                    <div full-width nopadding v-if="defaultView">
                        <v-list full-width nopadding>
                            <v-list-tile>
                                <v-list-tile-content>
                                  <v-list-tile-title medium-theme-color>Particulars</v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>

                        <trans-display 
                            :items="displayData.transactionLedgers" 
                            :showAll="true" 
                            :showDebitItems="false" 
                            :showCreditItems="false"
                            :selectedNatureValue="selectedNatureValue">
                        </trans-display>
                    </div>

                    <div v-else>

                        <!-- To on top then From -->
                        <div>
                            <v-list nopadding>
                                <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title v-if="settings.transactionTypeId==30003">{{selectedNatureValue ? 'To' : 'From'}}</v-list-item-title>
                                      <v-list-item-title v-else>{{selectedNatureValue ? 'Paid To' : 'Received From'}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <div v-if="!selectedNatureValue">
                                <trans-display 
                                    :items="displayData.transactionLedgers" 
                                    :showAll="false" 
                                    :showDebitItems="false" 
                                    :showCreditItems="true"
                                    :selectedNatureValue="selectedNatureValue">
                                </trans-display>
                            </div>

                            <div v-if="selectedNatureValue">
                                <trans-display 
                                    :items="displayData.transactionLedgers" 
                                    :showAll="false"
                                    :showDebitItems="true" 
                                    :showCreditItems="false"
                                    :selectedNatureValue="selectedNatureValue">
                                </trans-display>
                            </div>
                        </div>

                        <!-- From on top To From -->
                        <div>
                            <v-list full-width nopadding class="mt-2">
                                <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title v-if="settings.transactionTypeId==30003">{{selectedNatureValue ? 'From' : 'To'}}</v-list-item-title>
                                      <v-list-item-title v-else>{{settings.transactionTypeId==30004 ? 'Mode' : 'Payment Mode'}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <div v-if="selectedNatureValue">
                                <trans-display 
                                    :items="displayData.transactionLedgers" 
                                    :showAll="false"
                                    :showDebitItems="false" 
                                    :showCreditItems="true"
                                    :selectedNatureValue="selectedNatureValue">
                                </trans-display>
                            </div>

                            <div v-if="!selectedNatureValue">
                                <trans-display 
                                    :items="displayData.transactionLedgers" 
                                    :showAll="false"
                                    :showDebitItems="true" 
                                    :showCreditItems="false"
                                    :selectedNatureValue="selectedNatureValue">
                                </trans-display>
                            </div>
                        </div>
                        
                    </div>

                </div>

                <div v-if="displayData.notes" class="mt-2">
                    <v-list nopadding>
                        <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title medium-theme-color>Notes</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-card flat class="note-card">
                      <v-card-text font16 v-html="displayData.notes" white-space-pre-line dark-opacity-color></v-card-text>
                    </v-card>
                </div>

                <!-- <v-footer class="invoice-footer-actions">

                    <v-layout white-background grid wrap text-xs-center>
                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('sharewhatsapp')">
                            <div right-border>
                                <div><v-icon>send</v-icon></div>
                                <div font11 class="font-weight-bold">Send</div>
                            </div>  
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('share')">
                            <div right-border>
                                <div><v-icon>share</v-icon></div>
                                <div font11 class="font-weight-bold">Share</div>
                            </div>
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('print')">
                            <div right-border>
                                <div><v-icon>print</v-icon></div>
                                <div font11 class="font-weight-bold">Print</div>
                            </div>
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('pdf')">
                            <div right-border>
                                <div><v-icon>vertical_align_bottom</v-icon></div>
                                <div font11 class="font-weight-bold">Download</div>
                            </div>
                        </v-flex>
                    </v-layout>

                    <div v-ripple class="pb-2 pt-2" center-align style="width:20%" white-background @click="fn_getBottomMenu">
                        <div><v-icon>more_horiz</v-icon></div>
                        <div font11 class="font-weight-bold">More</div>
                    </div>
                    
                </v-footer> -->

            </div>

            <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px)" v-if="loading">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>

            <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" :header="confirmationDialogHeader" :confirmationMessage="confirmationMessage" :actionMethod="fn_callCancelTransaction" :params="{type:actionType}" :displayView='true' okBtnText="Yes" cancelBtnText="No" icon="warning" iconClass="redcolor" textClass="redcolor"></dialog-confirmation>
            
            <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

            <!-- <dialog-confirmation ref="ref_dialogDeleteConfirmation" :header="confirmationHeader" :actionMethod="actionMethod" :displayView='true'></dialog-confirmation> -->
        </div>

        <!-- <div id="printable" full-width>
            <print-template ref="ref_printTemplate" :data="printData" v-if="printData!=null"></print-template>
        </div> -->

        <!-- <dialog-permission ref="ref_dialogPermission"></dialog-permission> -->

        <v-snackbar v-model="snackbarOpenFile">
          File downloaded!
          <v-btn text color="primary" @click.native="$root.fn_openDownloadedFile">Open</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
const menuItem = require('../../../../config.js').configs.menuItem;
const transactionForms = require('../../../../config.js').configs.transactionForms;
const transactionTypes = require('../../../../config.js').configs.transactionTypes;

// import dialogConfirmation from '../../../dialogs/dialog_confirmation'

// import printTemplate from '../../../invoice_templates/accountbook_print'
// import printTemplate from '../../../invoice_templates/invoices/template101'

import transDisplay from './dialogs/trans_display'
import {transactionMixin} from '../../transaction_mixin'

import dialogConfirmation from '../../../dialogs/dialog_confirmation'

    export default{
        props:['settings'],
        mixins: [transactionMixin],
        data(){
            return{
                displayData:null,
                toolbarTitle:null,
                selectedNatureValue:null,
                snackbar:false,
                snackbar_msg:null,
                defaultView:null,
                actionType:null,
                confirmationDialogHeader:null,
                confirmationMessage:null,
                printData:null,
                loading:false,
            }
        },
        components:{
            'trans-display': transDisplay,
            'dialog-confirmation': dialogConfirmation,
            // 'print-template': printTemplate
        },
        methods:{

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_preview: function(){
                this.fn_previewInvoice(true, null, 'ab');
            },

            fn_bindInvoiceTemplate: function(type){

                if(this.disableAction){
                    return;
                }

                this.disableAction = true;

                let self = this;
                setTimeout(function(){
                    self.disableAction = false;
                },500);

                if(type=='sharewhatsapp'){
                    this.fn_shareInvoice('sharewhatsapp');
                }
                else if(type=='print'){
                    this.fn_getPrintData('AccountBookPrintData', this.displayData.transactionId, 'print');
                }
                else if(type=='pdf'){
                    this.fn_getPrintData('AccountBookPrintData', this.displayData.transactionId, 'pdf');
                }
                else if(type=='share'){
                    this.fn_shareInvoice('share');
                }
            },

            fn_shareInvoice: function(type){
                let shareObj = {};
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';
                // shareObj.shareVia = this.displayData.primaryMobileNumber && type == 'sharewhatsapp' ? 'ShareOnWhatsApp' : 'Share';
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = this.displayData.primaryMobileNumber && type == 'sharewhatsapp' ? this.displayData.primaryMobileNumber : null;
                shareObj.subject = this.toolbarTitle+" Details";
                shareObj.email = this.displayData.email ? [this.displayData.email] : null ;

                let party = this.displayData.partyName ? this.displayData.partyName + ',' : '';

                shareObj.textMessage = "Dear "+party+"\n\nBelow are your " + this.toolbarTitle + " details. Please find the attached pdf.\n\nTransaction Date: "+this.$moment(this.displayData.transactionDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(this.displayData.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;

                // shareObj.textMessage = "Dear,\n\nBelow are your "+this.toolbarTitle.toLowerCase()+ " details. Please find the attached pdf of your "+this.toolbarTitle.toLowerCase()+".\n\n "+this.toolbarTitle+ "Transaction Date: "+this.$moment(this.displayData.transactionDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(this.displayData.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.fn_getPrintData('AccountBookPrintData', this.displayData.transactionId, 'pdf', shareObj);
            },

            fn_callCancelTransaction: function(paramObj){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;

                let obj = {};
                obj.transactionTypeId = this.displayData.transactionListId;

                let self = this;
                if(paramObj.type=='cancel'){
                    this.fn_cancelTransaction(this.displayData.transactionId, this.displayData.transactionListId, null, function(status){
                        self.displayData.status = status;
                        if(status==0){
                            // alert("Transaction "+self.displayData.mergeTransactionNumber+ " cancelled");
                            self.$eventHub.$emit('snackbarAlert', {msg:"Transaction "+self.displayData.mergeTransactionNumber+ " cancelled"});

                            if(self.$route.params.listId == 'parties' || self.$route.params.listId == 'ledgers'){
                                self.$eventHub.$emit('UpdateLedgerList', {});
                            }
                            else{
                                self.$eventHub.$emit('UpdateAccountBookList', obj);
                            }
                        }
                        else{
                            // alert("Transaction "+self.displayData.mergeTransactionNumber+ " not cancelled");
                            self.$eventHub.$emit('snackbarAlert', {msg:"Transaction "+self.displayData.mergeTransactionNumber+ " not cancelled"});
                        }
                    });  
                }
                else{
                    let transactionNumber = this.displayData.mergeTransactionNumber;
                    this.fn_deleteTransaction(this.displayData.transactionId, this.displayData.transactionListId, function(status){
                        if(status){
                            // self.snackbar = true;
                            // self.snackbar = '#'+transactionNumber+ ' deleted parmanently!.';
                            // alert('#'+transactionNumber+ ' deleted parmanently');
                            self.$eventHub.$emit('snackbarAlert', {msg:'#'+transactionNumber+ ' deleted parmanently'});
                            // self.$eventHub.$emit('UpdateAccountBookList', obj);
                            if(self.$route.params.listId == 'parties' || self.$route.params.listId == 'ledgers'){
                                self.$eventHub.$emit('UpdateLedgerList', {});
                            }
                            else{
                                self.$eventHub.$emit('UpdateAccountBookList', obj);
                            }
                            // self.$router.go(-1);
                            // self.fn_goBack();
                            self.$router.push({path:"/"+self.$root.CurrentBusiness.BusinessID+"/"+self.$route.params.listId});
                        }
                        else{
                            // self.snackbar = true;
                            // self.snackbar = 'Something went wrong. Try again!.'; 
                            // alert('Something went wrong. Try again');
                            self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                        }
                    });
                }
            },

            fn_initializeSettings: function () {
                transactionForms.filter(item => {
                    if(item.id == this.settings.transactionTypeId){
                        this.toolbarTitle = item.title;
                        this.selectedNatureValue = item.selectedNature;
                    }
                });

                this.defaultView = JSON.parse(localStorage.getItem('acbDefaultView'));
            },

            fn_comingSoon: function () {
                // this.snackbar_msg = "Coming Soon..";
                // this.snackbar = true;
                this.showToast("Coming Soon..");
            },

            fn_get: function(itemId){
                var self = this;
                this.displayData = null;
                this.loading = true;
                this.getData('AccountBook', itemId, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          let data = JSON.parse(response);
                          self.fn_bindData(data);
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                          self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                        }
                    }
                );
            },

            fn_getPrefix: function(transactionListId){

                if(transactionListId == transactionTypes.purchaseInvoice.id || transactionListId == transactionTypes.salesInvoice.id){
                    return 'plus'
                }
                else if(transactionListId == transactionTypes.purchaseReturn.id || transactionListId == transactionTypes.salesReturn.id){
                    return 'minus'
                }

            },

            fn_bindData: function (data) {

                let self = this;
                
                data.transactionLedgers.forEach(function(group){

                    group.amount = null;

                    group.onAccount = null;

                    group.amountType = group.baseAmountType;

                    group.groupTotal = 0;

                    group.currentBalance < 0 ? group.currentBalanceType = 0 : group.currentBalanceType = 1;

                    // group.currentBalance = Math.abs(self.roundUpto2Decimals(group.currentBalance));
                    group.currentBalance = self.roundUpto2Decimals(group.currentBalance);

                    let onAccountItemIndex = null;

                    group.references.forEach(function(ref, index){

                        // group.groupTotal += ref.amount;
                        let prefix = self.fn_getPrefix(ref.transactionListId);

                        if(prefix=='minus'){
                            group.groupTotal -= ref.amount;
                        }
                        else{
                            group.groupTotal += ref.amount;
                        }

                        if(!ref.isAgainstRef){
                            // group.amount = ref.amount;
                            ref.isOnAccount ? group.onAccount = ref.amount : '';

                            //otherdetails
                            group.bankName = ref.bankName;
                            group.transType = ref.transType;
                            group.refNumber = ref.refNumber;
                            group.refDate = ref.refDate ? new Date(ref.refDate) : null;

                            onAccountItemIndex = index;
                        }

                    });

                    group.amount = group.groupTotal;    

                    if(onAccountItemIndex != null){
                        group.references.splice(onAccountItemIndex, 1);
                    }

                })              

                this.displayData = JSON.parse(JSON.stringify(data));


                

                if(this.settings.transactionTypeId==30004){
                    this.selectedNatureValue = this.displayData.addReduceAmount;

                    // sorting as per amount types
                    this.displayData.transactionLedgers.sort(function(a, b){
                        if(self.selectedNatureValue)
                            return a.amountType - b.amountType;
                        else
                            return b.amountType - a.amountType;
                    });
                }
                else{
                    // sorting as per amount types
                    this.displayData.transactionLedgers.sort(function(a, b){
                        if(self.selectedNatureValue)
                            return b.amountType - a.amountType;
                        else
                            return a.amountType - b.amountType;
                    });

                    // sorting as per order number
                    this.displayData.transactionLedgers.sort(function(a, b){
                        return a.orderNumber - b.orderNumber;
                    });
                }

                

                console.log(JSON.stringify(this.displayData.transactionLedgers));

                this.loading = false;

            },

            fn_getBottomMenu: function (tagId) {
                
                var bottomMenu = {"BottomMenu": [menuItem.cancelTransaction, menuItem.delete]};

                this.displayData.status==0 ? bottomMenu = {"BottomMenu": [menuItem.delete]} : '';

                this.setBottomMenu(tagId, bottomMenu, 
                    {
                        onSuccess: function (response) {
                            console.log(response);
                        },
                        onError: function (error) {
                            console.log(error);
                        }
                    }
                )
            },

            fn_menuItemClick: function(tagId, menuId, menuAction){
                // tagId id form future use
                // menuId id form future use
                if(menuAction=='edit' && this.displayData && this.displayData.transactionId){
                    this.fn_edit();
                }
                else if(menuAction=='cancel'){
                    // this.fn_cancelTransaction(this.displayData.transactionId, 'display');
                    this.actionType = 'cancel';
                    this.confirmationDialogHeader = "Cancel Transaction?";
                    this.confirmationMessage = "Cancelled transaction cannot be uncancelled. Are you sure you want to cancel this transaction?"
                    this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
                }
                else if(menuAction=='delete'){
                    this.actionType = 'delete';
                    this.confirmationDialogHeader = "Delete Transaction?";
                    this.confirmationMessage = "Deleted transaction cannot be restored. Are you sure you want to delete this transaction?"
                    this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
                }
                else if(menuAction=='share'){
                    // this.fn_shareInvoice('share');
                    this.fn_bindInvoiceTemplate('share');
                }
            },

            fn_edit: function () {
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+this.displayData.transactionId+'/edit');
            },

            fn_cancel: function(){
                this.actionType = 'cancel';
                this.confirmationDialogHeader = "Cancel Transaction?";
                this.confirmationMessage = "Cancelled transaction cannot be uncancelled. Are you sure you want to cancel this transaction?"
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            fn_delete: function(){
                this.actionType = 'delete';
                this.confirmationDialogHeader = "Delete Transaction?";
                this.confirmationMessage = "Deleted transaction cannot be restored. Are you sure you want to delete this transaction?"
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            // fn_showWebView: function () {
            //     var viewObject = {};

            //     var tagId = null;
            //     this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';
            //     var self = this;

            //     this.showWebView(tagId, viewObject, 
            //         {
            //             onSuccess: function(response){
            //               console.log(response);
            //             }, 
            //             onError: function(error){
            //               console.log(error);
            //             }, 
            //             // onChange: null, 
            //             onMenuItemClick: function (tagId, menuId, menuAction, itemId) {
            //                 self.fn_menuItemClick(tagId, menuId, menuAction, itemId)
            //             }
            //         }
            //     );
            // },

            // fn_getPrintData: function(){
            //  var self = this;
         //         this.getData('AccountBookPrintData', this.displayData.transactionId, 
            //      {
            //          onSuccess: function(response){
            //              console.log(response);
            //              self.printData = JSON.parse(response);
            //              setTimeout(function(){
            //                  self.$refs['ref_printTemplate'].fn_init();
            //              },300);
            //          }, 
            //          onError: function(error){
            //              console.log(error);
            //          }
            //      }
         //        );
            // },
        },
        mounted: function(){
            // alert('in');
            // this.fn_initializeSettings();

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_onBackPress = this.fn_goBack;

            // this.fn_showWebView();
            
            if(this.$route.params && this.$route.params.tagId){
                this.fn_get(this.$route.params.tagId);
            }

            this.$eventHub.$on('delete', () => {
                this.fn_delete();
            });

            this.$eventHub.$on('cancel', () => {
                this.fn_cancel();
            });

            this.$eventHub.$on('edit', () => {
                this.fn_edit();
            });

            this.$eventHub.$on('preview', () => {
                this.fn_preview();
            });

            // let isCalled = false;

            // this.$eventHub.$once('connected', () => {
            //     setTimeout(() => {
            //         if(this.$route.params && this.$route.params.tagId && !isCalled){
            //             isCalled = true;
            //             this.fn_get(this.$route.params.tagId);
            //         }

            //         setTimeout(() => {
            //             isCalled = false;
            //         }, 3000);
                    
            //     }, 1000);
            // });
        },
        beforeRouteEnter(to, from, next){
            next(vm => {
                vm.fn_initializeSettings();
                if(to.params && to.params.tagId){
                    vm.fn_get(to.params.tagId);
                }

                let isCalled = false;

                vm.$eventHub.$once('connected', () => {
                    setTimeout(() => {
                        if(vm.$route.params && vm.$route.params.tagId && !isCalled){
                            isCalled = true;
                            vm.fn_get(vm.$route.params.tagId);
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });
            });
        },
        beforeRouteUpdate(to, from, next){
            this.$eventHub.$off('connected');

            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);

                }, 1000);
            });

            next(true);
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('cancel');
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('preview');
            this.$eventHub.$off('connected');
            next(true)
        },
        // beforeRouteLeave (to, from, next) {
        //     if(this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation || this.$refs['ref_dialogPermission'].dialog_permission){
        //         this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;
        //         this.$refs['ref_dialogPermission'].dialog_permission = false;
        //         next(false);
        //     }
        //     else{
        //         next(true); 
        //     }
        // }
    }

</script>