<template>
  <v-dialog v-model="dialog_loading" persistent width="300">
      <v-card flat>
        <v-card-text class="pt-3">
          {{message}} <template v-if="value">{{value}}%</template>
          <!-- <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear> -->
          <v-progress-linear rounded :value="value" :indeterminate="!value || value == 100 ? true : false" :height="6" color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
  export default{
    props:['message', 'value'],
    data(){
      return{
        dialog_loading:false
      }
    },
    methods:{
      openDialogWeb: function () {
        this.dialog_loading = true;
      },
      closeDialogWeb: function () {
        this.dialog_loading = false;
      },
    }
  }
</script>