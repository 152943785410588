<template>
    <div>
        <v-dialog v-model="dialog_accountbook_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat class="noprint">
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>{{$parent.toolbarTitle}}</v-toolbar-title>
                    <!-- <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="$parent.fn_checkValidations"><v-icon left>save</v-icon> Save</v-btn> -->
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back">

                  <v-progress-linear indeterminate :height="6" color="primary" v-if="$root.dialogLoader"></v-progress-linear>
                  <div center-align class="pa-4 light-opacity-color" v-if="$root.dialogLoader">Syncing master items...</div>

                  <template v-if="!$root.dialogLoader">
                    <ValidationObserver ref="observer">

                      <v-list class="pa-0">
                          <v-list-item style="height:36px">
                              <v-list-item-content>
                                <v-list-item-title>
                                    <span cursor-pointer class="text-underline" @click="$parent.fn_openDialogNumberSeries">{{$parent.Form.transactionData.mergeTransactionNumber}}</span>
                                </v-list-item-title>
                              </v-list-item-content>

                              <span v-if="$parent.Form.transactionData.transactionDate">{{$parent.Form.transactionData.transactionDate | moment("DD-MMM-YYYY")}}</span> 

                              <v-list-item-action class="ml-0">
                                  <v-btn icon @click="$parent.fn_openDatePicker('transactionDate', $parent.Form.transactionData.transactionDate)"><v-icon>date_range</v-icon></v-btn>
                              </v-list-item-action>
                          </v-list-item>
                      </v-list>

                      <template v-if="$parent.settings.transactionTypeId == 30003">
                        <div class="mb-2">

                            <!-- <validation-observer ref="observer" v-slot="{ invalid }"> -->

                              <v-layout row wrap white-back ma-0 mt-2 pt-3>
                                  <v-list dense full-width class="pa-0 ma-0">
                                      <v-list-item style="height:36px">
                                          <v-list-item-content>
                                            <v-list-item-title>To</v-list-item-title>
                                          </v-list-item-content>

                                          <!-- <v-list-item-action class="ml-0">
                                              <v-btn icon color="primary" @click="$parent.fn_addParticular(1)"><v-icon>add</v-icon></v-btn>
                                          </v-list-item-action> -->
                                      </v-list-item>
                                      <v-divider></v-divider>
                                  </v-list>

                                  <template v-for="(item, index) in $parent.debitItems">
                                      
                                      <v-flex sm1 md1 xs12 pt-4 form-item style="display: flex;align-items: center;" v-if="$parent.debitItems.length>1" :key="index+'removebtndebit'"><v-btn icon @click="$parent.fn_removeItem(item.groupId)"><v-icon redcolor>remove_circle_outline</v-icon></v-btn></v-flex>

                                      <v-flex sm6 md6 xs12 pt-4 form-item :key="item.accountLedgerId">
                                        
                                        <ValidationProvider :name="'Ledger' + item.groupId" rules="required" v-slot="{ errors }">

                                          <!-- <v-autocomplete
                                            :items="$parent.parties"
                                            v-model="item.selectedLedger"
                                            label="Ledger"
                                            item-text="itemName"
                                            item-value="itemId"
                                            return-object
                                            @input="$parent.fn_getCurrentBalance(item.selectedLedger.itemId, item, index)"
                                            required
                                            :error-messages="errors"
                                            :hide-details="!errors || !errors.length"
                                            append>
                                            
                                            <template v-slot:item="data">
                                              <v-list-item-content>
                                                <v-list-item-title v-html="data.item.itemName"></v-list-item-title>
                                                <v-list-item-subtitle>{{data.item.groupTypeId == 101 ? 'Customer' : data.item.groupTypeId == 102 ? 'Supplier' : 'Other'}}</v-list-item-subtitle>
                                              </v-list-item-content>
                                            </template>

                                            <v-list-item @click="fn_new(item)" slot="prepend-item" class="primary--text">
                                              <v-list-item-title>Create New</v-list-item-title>
                                            </v-list-item>

                                          </v-autocomplete> -->

                                          <v-autocomplete
                                              :items="$parent.filteredLedgers"
                                              v-model="item.selectedLedger"
                                              label="Ledger"
                                              item-text="itemName"
                                              item-value="itemId"
                                              return-object
                                              @input="$parent.fn_getCurrentBalance(item.selectedLedger.itemId, item, index)"
                                              required
                                              :error-messages="errors"
                                              :hide-details="!errors || !errors.length"
                                              append>
                                              
                                              <template v-slot:item="data">
                                                <v-list-item-content>
                                                  <v-list-item-title v-html="data.item.itemName"></v-list-item-title>
                                                  <v-list-item-subtitle>{{data.item.groupTypeId == 101 ? 'Customer' : data.item.groupTypeId == 102 ? 'Supplier' : 'Other'}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                              </template>

                                              <v-list-item @click="fn_new(item)" slot="prepend-item" class="primary--text">
                                                <v-list-item-title>Create New</v-list-item-title>
                                              </v-list-item>

                                            </v-autocomplete>

                                        </ValidationProvider>

                                        <div v-if="item.selectedLedger && item.currentBalance != undefined" right-align class="pt-1 font12">{{ $parent.fn_balanceLabel(item.selectedLedger.groupTypeId, item.currentBalance) }}: <span :class="[item.currentBalance < 0 ? 'redcolor' : 'greencolor']">₹ {{$root.numberToLocale(item.currentBalance)}}</span></div>
                                      </v-flex>

                                      <v-flex :class="[$parent.debitItems.length>1 ? 'sm2 md2' : 'sm3 md3']"  xs0 :key="index+'debitblankspace'"></v-flex>

                                      <v-flex sm3 md3 xs12 pt-4 form-item :key="index+'debitamount'">

                                        <ValidationProvider :name="'Amount' + item.groupId" rules="required|min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field
                                                right-align
                                                prefix="₹"
                                                type="number"
                                                v-model.number="item.amount"
                                                :error-messages="errors"
                                                placeholder="Amount"
                                                required
                                                autocomplete="null">
                                            </v-text-field>
                                        </ValidationProvider>
                                          <!-- <v-text-field type="number" v-model.number="item.amount" dense placeholder="Amount" prefix="₹" right-align></v-text-field> -->
                                      </v-flex>
                                  </template>

                                  <v-flex xs12 sm12 md12 pb-3 pl-4>
                                    <v-btn color="primary" capitalize small outlined @click="$parent.fn_addParticular(1)"><v-icon left>add</v-icon> Add Ledger</v-btn>
                                  </v-flex>
                              </v-layout>

                              <v-layout row wrap white-back ma-0 mt-2>
                                  <v-list dense full-width class="pa-0">
                                      <v-list-item style="height:36px">
                                          <v-list-item-content>
                                            <v-list-item-title>From</v-list-item-title>
                                          </v-list-item-content>

                                          <!-- <v-list-item-action class="ml-0">
                                              <v-btn icon color="primary" @click="$parent.fn_addParticular(0)"><v-icon>add</v-icon></v-btn>
                                          </v-list-item-action> -->
                                      </v-list-item>
                                      <v-divider></v-divider>
                                  </v-list>

                                  <template v-for="(item, index) in $parent.creditItems">

                                      <v-flex sm1 md1 xs12 pt-4 form-item style="display: flex;align-items: center;" v-if="$parent.creditItems.length>1" :key="index+'removebtncredit'"><v-btn icon @click="$parent.fn_removeItem(item.groupId)"><v-icon redcolor>remove_circle_outline</v-icon></v-btn></v-flex>

                                      <v-flex sm6 md6 xs12 pt-4 form-item :key="item.accountLedgerId">
                                          <!-- <v-text-field outlined dense label="Ledger" value=" "></v-text-field> -->

                                        <ValidationProvider :name="'Ledger' + item.groupId" rules="required" v-slot="{ errors }">

                                          <!-- <v-autocomplete
                                            :items="$parent.parties"
                                            v-model="item.selectedLedger"
                                            label="Ledger"
                                            item-text="itemName"
                                            item-value="itemId"
                                            return-object
                                            @input="$parent.fn_getCurrentBalance(item.selectedLedger.itemId, item, index)"
                                            required
                                            :error-messages="errors"
                                            :hide-details="!errors || !errors.length"
                                            append>
                                            
                                            <template v-slot:item="data">
                                              <v-list-item-content>
                                                <v-list-item-title v-html="data.item.itemName"></v-list-item-title>
                                                <v-list-item-subtitle>{{data.item.groupTypeId == 101 ? 'Customer' : data.item.groupTypeId == 102 ? 'Supplier' : 'Other'}}</v-list-item-subtitle>
                                              </v-list-item-content>
                                            </template>

                                            <v-list-item @click="fn_new(item)" slot="prepend-item" class="primary--text">
                                              <v-list-item-title>Create New</v-list-item-title>
                                            </v-list-item>
                                            
                                          </v-autocomplete> -->
                                          <v-autocomplete
                                              :items="$parent.filteredLedgers"
                                              v-model="item.selectedLedger"
                                              label="Ledger"
                                              item-text="itemName"
                                              item-value="itemId"
                                              return-object
                                              @input="$parent.fn_getCurrentBalance(item.selectedLedger.itemId, item, index)"
                                              required
                                              :error-messages="errors"
                                              :hide-details="!errors || !errors.length"
                                              append>
                                              
                                              <template v-slot:item="data">
                                                <v-list-item-content>
                                                  <v-list-item-title v-html="data.item.itemName"></v-list-item-title>
                                                  <v-list-item-subtitle>{{data.item.groupTypeId == 101 ? 'Customer' : data.item.groupTypeId == 102 ? 'Supplier' : 'Other'}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                              </template>

                                              <v-list-item @click="fn_new(item)" slot="prepend-item" class="primary--text">
                                                <v-list-item-title>Create New</v-list-item-title>
                                              </v-list-item>
                                              
                                            </v-autocomplete>

                                        </ValidationProvider>

                                        <div v-if="item.selectedLedger && item.currentBalance != undefined" right-align class="pt-1 font12">{{ $parent.fn_balanceLabel(item.selectedLedger.groupTypeId, item.currentBalance) }}: <span :class="[item.currentBalance < 0 ? 'redcolor' : 'greencolor']">₹ {{$root.numberToLocale(item.currentBalance)}}</span></div>
                                      </v-flex>

                                      <v-flex :class="[$parent.creditItems.length>1 ? 'sm2 md2' : 'sm3 md3']"  xs0 :key="index+'creditblankspace'"></v-flex>

                                      <v-flex sm3 md3 xs12 pt-4 form-item :key="index+'creditamount'">
                                        <ValidationProvider v-slot="{ errors }" :name="'Amount' + item.groupId" rules="required|min_value:0|decimal2">
                                            <v-text-field
                                                right-align
                                                prefix="₹"
                                                type="number"
                                                v-model.number="item.amount"
                                                :error-messages="errors"
                                                placeholder="Amount"
                                                required
                                                autocomplete="null">
                                            </v-text-field>
                                        </ValidationProvider>
                                          <!-- <v-text-field type="number" v-model.number="item.amount" dense placeholder="Amount" prefix="₹" right-align></v-text-field> -->
                                      </v-flex>
                                  </template>

                                  <v-flex xs12 sm12 md12 pb-3 pl-4>
                                    <v-btn color="primary" capitalize small outlined @click="$parent.fn_addParticular(0)"><v-icon left>add</v-icon> Add Ledger</v-btn>
                                  </v-flex>
                              </v-layout>

                            <!-- </validation-observer> -->
                              
                              <!-- <v-divider></v-divider> -->
                        </div>
                      </template>

                      <template v-else>
                          <!-- <div :class="[Form.transactionTypeList && Form.transactionTypeList.length>1 ? 'mt-2' : 'mb-2']"> -->
                        <div class="mb-2">

                            <v-layout row wrap white-back ma-0 mt-2 pb-3>

                                <v-flex sm6 md6 xs12 pl-4 pr-2 pb-2 pt-4 mt-3>

                                  <ValidationProvider name="Party" rules="required" v-slot="{ errors }">
                                    
                                    <!-- <v-autocomplete
                                      :items="$parent.parties"
                                      label="Party"
                                      item-text="itemName"
                                      item-value="itemId"
                                      v-model="$parent.Form.selectedParty"
                                      return-object
                                      @input="$parent.fn_updateParty($parent.Form.selectedParty.itemId, $parent.Form.selectedParty.itemName)"
                                      required
                                      :error-messages="errors"
                                      :hide-details="!errors || !errors.length"
                                      append>
                                      
                                      <template v-slot:item="data">
                                        <v-list-item-content>
                                          <v-list-item-title v-html="data.item.itemName"></v-list-item-title>
                                          <v-list-item-subtitle>{{data.item.groupTypeId == 101 ? 'Customer' : data.item.groupTypeId == 102 ? 'Supplier' : 'Other'}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>

                                      <v-list-item @click="fn_new(null)" slot="prepend-item" class="primary--text">
                                        <v-list-item-title>Create New</v-list-item-title>
                                      </v-list-item>

                                    </v-autocomplete> -->
                                    <v-autocomplete
                                        :items="$parent.filteredLedgers"
                                        label="Party"
                                        item-text="itemName"
                                        item-value="itemId"
                                        v-model="$parent.Form.selectedParty"
                                        return-object
                                        @input="$parent.fn_updateParty($parent.Form.selectedParty.itemId, $parent.Form.selectedParty.itemName)"
                                        required
                                        :error-messages="errors"
                                        :hide-details="!errors || !errors.length"
                                        append>
                                        
                                        <template v-slot:item="data">
                                          <v-list-item-content>
                                            <v-list-item-title v-html="data.item.itemName"></v-list-item-title>
                                            <v-list-item-subtitle>{{data.item.groupTypeId == 101 ? 'Customer' : data.item.groupTypeId == 102 ? 'Supplier' : 'Other'}}</v-list-item-subtitle>
                                          </v-list-item-content>
                                        </template>

                                        <v-list-item @click="fn_new(null)" slot="prepend-item" class="primary--text">
                                          <v-list-item-title>Create New</v-list-item-title>
                                        </v-list-item>

                                      </v-autocomplete>

                                  </ValidationProvider>

                                      <div v-if="$parent.Form.partyDetails && $parent.Form.partyDetails.balance != undefined" right-align class="pt-1 font12">{{ $parent.fn_balanceLabel($parent.Form.partyDetails.groupTypeId, $parent.Form.partyDetails.balance) }}: <span :class="[$parent.Form.partyDetails.balance < 0 ? 'redcolor' : 'greencolor']">₹ {{$root.numberToLocale($parent.Form.partyDetails.balance)}}</span></div>
                                </v-flex>

                                <v-flex sm3 md3 xs0></v-flex>                        

                                <v-flex sm3 md3 xs12 pl-2 pr-4 pt-4 mt-3>
                                  <ValidationProvider name="Amount" rules="required|min_value:0|decimal2" v-slot="{ errors }">
                                    <v-text-field id="amount_field" type="number" v-model.number="$parent.Form.amount" placeholder="Amount" @input="$parent.fn_autoFillPaymentMode" prefix="₹" right-align required :error-messages="errors"></v-text-field>
                                  </ValidationProvider>
                                </v-flex>

                            </v-layout>


                            <v-list two-line subheader nopadding>
                                <v-subheader cursor-pointer v-ripple white-back class="mt-2" @click="$parent.fn_openDialogPaymentOptions">
                                    {{$parent.settings.transactionTypeId == 30004 ? 'From' : 'Payment Mode'}}<v-spacer></v-spacer>
                                    <v-icon>chevron_right</v-icon>
                                </v-subheader>

                                <v-divider></v-divider>

                                <v-list-item v-for="(item, index) in $parent.Form.paymentOptions" :key="index">
                                    <v-list-item-content>
                                      <v-list-item-title>{{item.accountLedgerName}}</v-list-item-title>
                                      <v-list-item-subtitle v-if="item.refNumber">{{item.refNumber}}</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action class="ml-0" v-if="item.amount">&#8377; {{item.amount}}</v-list-item-action>
                                </v-list-item>
                            </v-list>
                            
                            <!-- <v-divider></v-divider> -->
                        </div>
                      </template>

                      <v-layout row wrap white-back ma-0 mt-2>

                          <v-flex sm12 md12 xs12 pa-4>
                            <ValidationProvider v-slot="{ errors }" name="Notes" rules="max:255">
                              <v-textarea
                                rows="2"
                                label="Notes" 
                                auto-grow
                                :counter="255"
                                v-model="$parent.Form.transactionData.notes"
                                :error-messages="errors">
                              </v-textarea>
                            </ValidationProvider>
                          </v-flex>
                      </v-layout>

                    </ValidationObserver>
                  </template>

                  
                    
                </div>

                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <template v-if="$parent.settings.transactionTypeId == 30003">
                          <v-flex sm3 md3 xs3><span font12 light-opacity-color>To</span> <br>
                            &#8377; {{$root.numberToLocale($parent.debitTotal)}}
                          </v-flex>

                          <v-flex sm3 md3 xs3 right-align pr-4><span font12 light-opacity-color>From</span> <br>
                            &#8377; {{$root.numberToLocale($parent.creditTotal)}}
                          </v-flex>

                          <v-flex xs6 sm6 md6 height48>
                              <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                          </v-flex>
                        </template>

                        <template v-else>
                          <v-flex xs12 sm12 md12 height48>
                              <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                          </v-flex>
                        </template>

                    </v-layout>
                </v-card-actions>

            </v-card>

            <v-snackbar v-model="$parent.transactionNumberSnackbar" right vertical :timeout="0">
                Generate new transaction number?

                <template v-slot:action="{ attrs }">

                    <v-btn text @click="$parent.transactionNumberSnackbar = false" color="warning">Close</v-btn>

                    <v-btn class="ml-2" text @click="$parent.fn_bindTransactionNumber" v-bind="attrs" color="primary">Okay</v-btn>

                </template>
            </v-snackbar>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
import { required, max } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// setInteractionMode('eager')

extend('required', {
  ...required,
  // message: '{_field_} can not be empty',
  message: 'This field can not be empty',
})

extend('max', max);

extend('decimal2', value => {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

});

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_accountbook_form: false,
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_accountbook_form = true;
            },
            closeDialogWeb: function () {
                this.dialog_accountbook_form = false;
            },
            fn_validateForm: function(){
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                }
              });
            },
            fn_new: function(item){
              this.closeDialogWeb();

              let groupTypeId = null;

              if(this.$parent.settings.transactionTypeId == 30001){
                groupTypeId = 101;
              }
              else if(this.$parent.settings.transactionTypeId == 30002){
                groupTypeId = 102
              }
              else{
                groupTypeId = 103
              }

              setTimeout(()=>{
                if(item){
                  this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), ls:1, type:'ledger', amounttype:item.amountType, groupid:item.groupId, groupTypeId:groupTypeId}});
                }
                else{
                  this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), ls:1, type:'party', groupTypeId:groupTypeId}});  
                }
              },100);
            },
        }
    }
</script>