var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"no-click-animation":"","persistent":"","lazy":"","transition":"slide-y-transition","max-width":"460px"},model:{value:(_vm.dialog_field),callback:function ($$v) {_vm.dialog_field=$$v},expression:"dialog_field"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","height":56}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialogWeb}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[(_vm.$parent.Form.dynamicFields.section)?_c('span',[_vm._v(_vm._s(_vm.$parent.Form.dynamicFields.section.sectionName))]):_vm._e()])],1),_c('v-divider'),_c('v-card-text',{staticClass:"dynamic-fields",attrs:{"full-height":"","nopadding":"","grey-back":""}},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-layout',{attrs:{"ma-0":"","pt-4":"","row":"","wrap":"","white-back":""}},[_vm._l((_vm.$parent.Form.dynamicFields.items),function(item,index){return [(item.fieldType=='text')?_c('v-flex',{key:index,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.displayName,"type":"text","error-messages":errors,"counter":item.additionalData.maxLength,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,true)})],1):_vm._e(),(item.fieldType=='pincode')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.displayName,"type":"number","counter":item.additionalData.maxLength,"error-messages":errors,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", _vm._n($$v))},expression:"item.value"}})]}}],null,true)})],1):_vm._e(),(item.fieldType=='mobileNumber')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.displayName,"type":"tel","counter":item.additionalData.maxLength,"error-messages":errors,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", _vm._n($$v))},expression:"item.value"}})]}}],null,true)})],1):_vm._e(),(item.fieldType=='email')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required|email|max:'+item.additionalData.maxLength : 'email|max:'+item.additionalData.maxLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.displayName,"type":"email","counter":item.additionalData.maxLength,"error-messages":errors,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,true)})],1):_vm._e(),(item.fieldType=='dateTime')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""},on:{"click":function($event){return _vm.openDatePicker('dynamicFieldDate-fid-'+item.fieldId, item.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openDatePicker('dynamicFieldDate-fid-'+item.fieldId, item.value)}}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":"","append-icon":"arrow_drop_down","label":item.displayName,"value":_vm.fn_formatDate(item.value),"error-messages":errors,"required":""}})]}}],null,true)})],1):_vm._e(),(item.fieldType=='longText')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"2","label":item.displayName,"counter":item.additionalData.maxLength,"error-messages":errors,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,true)})],1):_vm._e(),(item.fieldType=='number')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required|numeric|max:'+item.additionalData.maxLength : 'numeric|max:'+item.additionalData.maxLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.displayName,"type":"number","counter":item.additionalData.maxLength,"error-messages":errors,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", _vm._n($$v))},expression:"item.value"}})]}}],null,true)})],1):_vm._e(),(item.fieldType=='decimal')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required|decimal:2|max:'+item.additionalData.maxLength : 'decimal:2|max:'+item.additionalData.maxLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.displayName,"type":"number","counter":item.additionalData.maxLength,"error-messages":errors,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", _vm._n($$v))},expression:"item.value"}})]}}],null,true)})],1):_vm._e(),(item.fieldType=='choice')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('v-select',{attrs:{"items":item.additionalData.choices,"label":item.displayName,"clearable":"","placeholder":" "},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),(item.fieldType=='yes/no')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":item.displayName,"right-align-checkbox":""},on:{"change":function($event){return _vm.fn_updateCheckBoxValue(item)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!item.value),expression:"!item.value"}],staticClass:"error--text font-small"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):_vm._e(),(item.fieldType=='code')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.displayName,"type":"text","error-messages":errors,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,true)})],1):_vm._e(),(item.fieldType=='linkedItem')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listItems,"label":item.displayName,"item-text":"itemName","item-value":"itemId","return-object":"","required":"","error-messages":errors,"hide-details":!errors || !errors.length,"append":""},on:{"focus":function($event){return _vm.fn_openListItems(item.listItemId, item.value, item.listId, item.listName, item.fieldId)}},model:{value:(item.selectedListItem),callback:function ($$v) {_vm.$set(item, "selectedListItem", $$v)},expression:"item.selectedListItem"}},[_c('v-list-item',{staticClass:"primary--text",attrs:{"slot":"prepend-item"},slot:"prepend-item"},[_c('v-list-item-title',[_vm._v("Create New")])],1)],1)]}}],null,true)})],1):_vm._e(),(item.fieldType=='accountItem')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":item.accountItems,"label":item.displayName,"item-text":"itemName","item-value":"itemId","return-object":"","required":"","error-messages":errors,"hide-details":!errors || !errors.length,"append":""},on:{"click":function($event){return _vm.fn_openAccountLedgers(item)},"focus":function($event){return _vm.fn_openAccountLedgers(item)}},model:{value:(item.selectedAccountItem),callback:function ($$v) {_vm.$set(item, "selectedAccountItem", $$v)},expression:"item.selectedAccountItem"}},[_c('v-list-item',{staticClass:"primary--text",attrs:{"slot":"prepend-item"},on:{"click":function($event){return _vm.$parent.fn_newDynamicAccount(index, _vm.$parent.Form.dynamicFields.section.sectionId)}},slot:"prepend-item"},[_c('v-list-item-title',[_vm._v("Create New")])],1)],1)]}}],null,true)})],1):_vm._e(),(item.fieldType=='phoneNumber')?_c('v-flex',{key:item.displayName,attrs:{"xs6":"","sm6":"","md6":"","form-item":""}},[_c('ValidationProvider',{attrs:{"name":item.displayName,"rules":item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.displayName,"type":"tel","counter":item.additionalData.maxLength,"error-messages":errors,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", _vm._n($$v))},expression:"item.value"}})]}}],null,true)})],1):_vm._e()]})],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},nativeOn:{"click":function($event){return _vm.closeDialogWeb.apply(null, arguments)}}},[_vm._v("close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},nativeOn:{"click":function($event){return _vm.fn_checkValidations.apply(null, arguments)}}},[_vm._v("ok")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }