<template>
    <div>
        <div id="non-printable-area">
            <v-toolbar :height="56" flat>
                <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>
                <v-toolbar-title>{{statementType == 1 ? 'Statement' : 'Activity'}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-btn icon @click="fn_export" :disabled="loading"><v-icon>picture_as_pdf</v-icon></v-btn> -->
                <v-btn @click="fn_export" capitalize small outlined>
                    <v-icon left>save_alt</v-icon> Download PDF
                </v-btn>
            </v-toolbar>

            <v-divider></v-divider>

            <div class="data-container" v-if="!loading">

                <div sticky-top style="z-index:1">
                    <v-subheader class="pr-0 white-back">
                        <span @click="fn_openDialogCustomDates">
                            Period:&nbsp;{{startDate | moment("DD-MMM-YYYY")}}&nbsp;To&nbsp;{{endDate | moment("DD-MMM-YYYY")}}
                        </span>
                        <!-- <v-spacer></v-spacer>
                        <v-menu>
                            <v-btn justify-content-to-end style="height: 16px;" nomargin nopadding text capitalize font-normal slot="activator">
                                {{selectedPeriod}}&nbsp;<v-icon font20>date_range</v-icon></v-btn>
                            <v-list nopadding>
                              <v-list-item v-for="(item, index) in periods" :key="index" @click="fn_dateRange(item)">
                                  <v-list-item-title>{{item.title}}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                        </v-menu> -->

                        <v-spacer></v-spacer>

                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn capitalize text v-bind="attrs" v-on="on">
                                {{selectedPeriod}}&nbsp;<v-icon>date_range</v-icon>
                              </v-btn>
                            </template>
                              <v-list class="pa-0">
                                <v-list-item v-for="(item, index) in periods" :key="index" @click="fn_dateRange(item)">
                                  <v-list-item-title>{{item.title}}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                        </v-menu>
                    </v-subheader>

                    <v-divider></v-divider>
                </div>
                

                <div>
                    <ledger-statement ref="ref_ledgerStatement" :statementData="statementData" :statementType="statementType" :opening="displayData.opening" :statementView="true" v-if="statementData"></ledger-statement>
                </div>

            </div>

            <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);z-index:99" v-if="loading">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>

            <!-- <v-footer class="invoice-footer-actions">
                <v-layout grid white-background wrap text-xs-center>

                    <v-flex xs4 sm4 md4 pt-2 pb-2 @click="fn_checkPermission('sharewhatsapp')">
                        <div right-border>
                            <div style="height:26px"><img src="../../../../assets/icon_whatsapp.svg" alt="whatapp_icon"></img></div>
                            <div font11 class="font-weight-bold">Send</div>
                        </div>
                    </v-flex>

                    <v-flex xs4 sm4 md4 pt-2 pb-2 @click="fn_checkPermission('share')">
                        <div right-border>
                            <div><v-icon>share</v-icon></div>
                            <div font11 class="font-weight-bold">Share</div>
                        </div>
                    </v-flex>

                    <v-flex xs4 sm4 md4 pt-2 pb-2 @click="fn_checkPermission('download')">
                        <div right-border>
                            <div><v-icon>vertical_align_bottom</v-icon></div>
                            <div font11 class="font-weight-bold">Download</div>
                        </div>
                    </v-flex>

                </v-layout>

            </v-footer> -->
        </div>

        <!-- <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker> -->

        <!-- <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection> -->

        <div id="printable-area" white-back>
            <ledger-statement-pdf ref="ref_ledgerStatement" :statementData="statementData" :statementType="statementType" :opening="displayData.opening" v-if="statementData"></ledger-statement-pdf>
        </div>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <v-snackbar v-model="snackbar">
          File downloaded!
          <v-btn text color="primary" @click.native="$root.fn_openDownloadedFile">Open</v-btn>
        </v-snackbar>

    </div>
</template>

<script>

import ledgerStatement from './ledger_statement'
import ledgerStatementPdf from './ledger_statement_pdf'

import { jsPDF } from "jspdf";
const html2pdf = require('html2pdf.js/dist/html2pdf.js');
// import 'jspdf-autotable'
// import dialogDatepicker from './dialogs/dialog_datepicker'
// import dialogPeriodSelection from '../../../dialogs/dialog_period_selection'

import dialogDatePicker from '../../../dialogs/dialog_datepicker';
import dialogPeriodSelection from '../../../dialogs/dialog_period_selection'

    export default{
        // props:['settings'],
        components: {
            'ledger-statement':ledgerStatement,
            'ledger-statement-pdf':ledgerStatementPdf,
            'dialog-datepicker':dialogDatePicker,
            'dialog-period-selection':dialogPeriodSelection
        },
        data(){
            return{
                startDate:this.$root.minDateSelection,
                endDate:this.$root.maxDateSelection,
                displayData:null,
                statementData:null,
                statementType:1,
                periods:[{'title':'Last 7 Days', "value":7}, {'title':'Last 30 Days', "value":30}, {'title':'All Time', "value":0}, {'title':'Custom', "value":null}],
                selectedPeriod:"All Time",
                selectedDate:null,
                dateType:null,
                snackbar:false,
                selectedStartDate:null,
                selectedEndDate:null,
                loadingStatusObj:{value:0},
                loading:false
            }
        },
        methods:{

            fn_export: function(){
                document.getElementById("printable-area").style.display = "block";

                let options = { orientation: 'p', unit: 'pt', format: 'a4' }
                const doc = new jsPDF(options);

                doc.setFontSize(11);

                let htmlContent = document.getElementsByClassName("template-pdf")[0];

                let fileName = null;

                let ledgerName = this.displayData.name.replace(/\//g,'');

                if(this.statementType == 1){
                    fileName = "LedgerStatement-"+ledgerName + '_' + this.$root.fn_getCurrentDateTimeString()+".pdf";
                }
                else{
                    fileName = "LedgerActivity-"+ledgerName + '_' + this.$root.fn_getCurrentDateTimeString()+".pdf";
                }

                // doc.html(htmlContent, {
                //     callback: function (doc) {
                //         doc.save(fileName);

                //         setTimeout(function(){
                //             document.getElementById("printable-area").style.display = "none";
                //         },2000);
                //     },
                //     x: 5,
                //     y: 5,
                //     margin:5,
                //     fontFamily:["Roboto", "sans-serif"],
                //     pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                // });

                // let htmlContent = document.getElementsByClassName("bill_template")[0];

                var opt = {
                    margin:       15,
                    // filename:     this.downloadFileName+'.pdf',
                    filename:     fileName,
                    image:        { type: 'jpeg', quality: 0.98},
                    html2canvas:  { scale: 2 },
                    enableLinks: true,
                    jsPDF:        { unit: 'pt', format: 'a4', orientation: 'portrait' },
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                    // pagebreak: { mode: 'avoid-all', before: '.html2pdf__page-break' }

                };

                html2pdf().set(opt).from(htmlContent).save();

              // doc.save(fileName);
            },

            fn_get: function(){
                this.loading = true;
                var self = this;
                // this.showLoading('Loading details...');
                this.getData('Ledger', this.$route.params.tagId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.displayData = JSON.parse(response);
                            self.fn_getLastTransactionDate();
                            // self.fn_getLedgerStatement();
                            // self.statementType == 1 ? self.fn_getLedgerStatement() : self.fn_getLedgerActivity();
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.loading = false;
                            // self.hideLoading();
                        }
                    }
                );
            },

            fn_getLastTransactionDate: function(){
                let self = this;
                let filterObject = {};
                // Sending opening stock and opening ledger list id to get first transaction date except these two.
                filterObject.transactionListId = [40001, 40003];
                this.getFilterData('LastTransactionDate', null, filterObject, null,
                    {
                        onSuccess: function(response){
                        console.log(response);
                        let ltd = JSON.parse(response);
                        self.statementType == 1 ? self.fn_getLedgerStatement(ltd) : self.fn_getLedgerActivity(ltd);
                    },
                        onError: function(error){
                        console.log(error);
                    }
                });
            },

            // fn_getLedgerStatement: function(){
            fn_getLedgerStatement: function(ltd){
                
                let filterObject = {};
                filterObject.reportId = 201;

                // if(new Date(this.startDate).getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                //     this.startDate = this.$root.CurrentBusiness.OpeningDate;
                // }

                if(new Date(this.endDate) > new Date(this.$root.maxDateSelection)){
                    // alert('in');
                    this.endDate = new Date(this.$root.maxDateSelection);
                }

                if(ltd && new Date(ltd) < new Date(this.endDate)){
                    this.endDate = new Date(ltd);
                }

                if(new Date(this.startDate) > new Date(this.endDate)){
                    this.$eventHub.$emit('snackbarAlert', {msg:'Selected period is not applicable'});
                    this.startDate = this.endDate;
                }

                filterObject.startDate = this.$moment(this.startDate).startOf('day'); //new Date(this.startDate);
                filterObject.endDate = this.$moment(this.endDate).endOf('day');

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);
                
                let self = this;
                this.getReportData('Report', this.$route.params.tagId, filterObject, this.loadingStatusObj,{

                    onSuccess: function(response){
                        console.log(response);
                        self.statementData = JSON.parse(response);
                        self.statementData.accountDetails = {};
                        self.statementData.accountDetails.accountName = self.displayData.name;
                        self.statementData.accountDetails.startDate = self.startDate;
                        self.statementData.accountDetails.endDate = self.endDate;
                        self.statementData.accountDetails.primaryMobileNumber = self.displayData.primaryMobileNumber
                        self.statementData.accountDetails.email = self.displayData.email;
                        self.statementData.accountDetails.gstNumber = self.displayData.gstNumber;
                        self.fn_statementSummary();
                        console.log(self.statementData);
                    }, 
                    onError: function(error){
                        console.log(error);
                        // self.hideLoading();
                        self.loading = false;
                    }

                });
            },

            fn_getLedgerActivity: function(ltd){
                // this.showLoading('Preparing Ledger Activity...');
                let filterObject = {};

                if(ltd && new Date(ltd) < new Date(this.endDate)){
                    this.endDate = new Date(ltd);
                }

                filterObject.startDate = this.$moment(this.startDate).startOf('day'); //new Date(this.startDate);
                filterObject.endDate = this.$moment(this.endDate).endOf('day');

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);
                
                let self = this;
                this.getFilterData('LedgerActivityData', this.displayData.accountId, filterObject, null,{

                    onSuccess: function(response){
                        console.log(response);
                        self.statementData = JSON.parse(response);
                        self.statementData.accountDetails = {};
                        self.statementData.accountDetails.accountName = self.displayData.name;
                        self.statementData.accountDetails.startDate = self.startDate;
                        self.statementData.accountDetails.endDate = self.endDate;
                        self.statementData.accountDetails.primaryMobileNumber = self.displayData.primaryMobileNumber
                        self.statementData.accountDetails.email = self.displayData.email;
                        self.fn_statementSummary();
                        console.log(self.statementData);
                    }, 
                    onError: function(error){
                        console.log(error);
                        // self.hideLoading();
                    }

                });
            },

            fn_statementSummary: function(){
                // this.statementData.accountDetails.opening = this.displayData.opening ? this.displayData.opening : 0;
                this.statementData.accountDetails.opening = this.statementData.data.footerRow && this.statementData.data.footerRow.openingValue ? this.statementData.data.footerRow.openingValue : 0;

                if(this.statementData.data.footerRow){
                    this.statementData.accountDetails.debitTotal = this.statementData.data.footerRow.debit;
                    this.statementData.accountDetails.creditTotal = this.statementData.data.footerRow.credit;
                    this.statementData.accountDetails.balance = this.statementData.data.footerRow.partyBalance;
                }
                
                this.loading = false;
                // this.hideLoading();
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                this.selectedDate = date ? date : new Date();
                setTimeout(function () {
                    // self.fn_openDialogWeb('web', 'DatePicker', 'ref_dialogDatePicker');
                    self.$refs['ref_dialogDatePicker'].openDialogWeb();
                });
            },

            // fn_openDialogCustomDates: function(){
            //     this.selectedStartDate = this.startDate;
            //     this.selectedEndDate = this.endDate;
            //     this.$refs['ref_dialogCustomPeriod'].openDialogWeb();
            // },
            fn_openDialogCustomDates: function(){
                this.selectedStartDate = this.startDate;
                this.selectedEndDate = this.endDate;
                this.$refs['ref_dialogCustomPeriod'].custom_date = true;
            },

            // fn_updateDate: function (value) {
            //     if(this.dateType=="start"){
            //         this.selectedStartDate = value;
            //     }
            //     else if(this.dateType=="end"){
            //         this.selectedEndDate = value;
            //     }

            //     this.fn_closeDialog();

            //     this.selectedPeriod = null;
            // },

            fn_updateDate: function (value) {

                this.$refs['ref_dialogDatePicker'].closeDialogWeb();

                if(this.dateType=="start"){
                    this.selectedStartDate = value;
                }
                else{
                    this.selectedEndDate = value;
                }

                // this.fn_closeDialog();
                let self = this;
                setTimeout(function(){
                    self.$refs['ref_dialogDatePicker'].disableBtn = false;
                }, 1000);
            },

            // fn_updateCustomDates: function(){
            //     this.startDate = this.selectedStartDate;
            //     this.endDate = this.selectedEndDate;
            //     this.selectedPeriod = "Custom";
            //     this.fn_closeDialog();
            //     this.fn_getLedgerStatement();
            // },

            fn_updateCustomDates: function(){
                this.startDate = this.selectedStartDate;
                this.endDate = this.selectedEndDate;
                this.selectedPeriod = "Custom";
                this.$refs['ref_dialogCustomPeriod'].custom_date = false;
                // this.fn_getLedgerStatement();
                this.statementType == 1 ? this.fn_getLedgerStatement() : this.fn_getLedgerActivity();
                // this.fn_getLastTransactionDate();
            },

            fn_closeDialog: function(){
                if(this.$refs['ref_dialogDatePicker'].datepicker){
                    this.$refs['ref_dialogDatePicker'].closeDialogWeb();
                    return; 
                }

                if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
                    this.$refs['ref_dialogCustomPeriod'].closeDialogWeb();
                }
            },

            fn_dateRange:  function (item){

                if(item.value==7){
                    let date = new Date();
                    date.setDate(date.getDate() - 6);
                    this.startDate = date
                    this.endDate = new Date();
                    this.selectedPeriod = item.title;
                }
                else if(item.value==30){
                    let date = new Date();
                    date.setDate(date.getDate() - 30);
                    this.startDate = date;
                    this.endDate = new Date();
                    this.selectedPeriod = item.title;
                }   
                else if(item.value==0){
                    this.startDate = this.$root.minDateSelection;
                    this.endDate = this.$root.maxDateSelection;
                    this.selectedPeriod = item.title;
                }
                else{
                    // this.selectedPeriod = null;
                    this.fn_openDialogCustomDates();
                }

                if(new Date(this.endDate) <= new Date(this.$root.CurrentBusiness.OpeningDate)){
                    // alert('Selected period is not applicable!');
                    this.$eventHub.$emit('snackbarAlert', {msg:'Selected period is not applicable'});
                    this.startDate = this.endDate;
                }

                if(item.value!=null){
                    // this.fn_getLedgerStatement();
                    if(item.value == 0){
                        this.fn_getLastTransactionDate();
                    }
                    else{
                        this.statementType == 1 ? this.fn_getLedgerStatement() : this.fn_getLedgerActivity(); 
                    }
                    // this.statementType == 1 ? this.fn_getLedgerStatement() : this.fn_getLedgerActivity();
                    
                }

                // let self = this;
                // this.$root.fn_checkStoragePermission(function(){
                //  self.fn_closeDialog();
                //  self.statementType == 1 ? self.fn_getLedgerStatement(type) : self.fn_getLedgerActivity(type);
                // })

            },

            fn_checkPermission: function(type){
                let self = this;
                this.$root.fn_checkStoragePermission(function(){
                    self.fn_exportStatementToPdf(type);
                })
            },

            fn_exportStatementToPdf: function(type){
                let self = this;
                // let downloadTime = new Date();
                let fileName = null;

                let ledgerName = this.displayData.name.replace(/\//g,'');

                if(this.statementType == 1){
                    fileName = "LedgerStatement-"+ledgerName + '_' + this.$root.fn_getCurrentDateTimeString()+".pdf";
                }
                else{
                    fileName = "LedgerActivity-"+ledgerName + '_' + this.$root.fn_getCurrentDateTimeString()+".pdf";
                }

                this.exportToPdf(fileName,{

                  onSuccess: function(response){
                      console.log(response);
                      if(type=='download'){
                        self.snackbar = true;
                      }
                      else{
                        self.fn_shareStatement(type);
                      }
                  }, 
                  onError: function(error){
                      console.log(error);
                      self.hideLoading();
                      self.showToast('Something went wrong. Try again');
                  }
                });
            },

            fn_shareStatement: function(type){
                let shareObj = {};
                // shareObj.shareType = 'share';
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = this.displayData.primaryMobileNumber ? this.displayData.primaryMobileNumber : null;
                shareObj.subject = this.statementType == 1 ? "Ledger Statement" : "Ledger Activity";
                shareObj.email = this.displayData.email ? [this.displayData.email] : null ;

                let party = this.displayData.name ? this.displayData.name + ',' : '';
                shareObj.textMessage = "Dear "+party+"\n\nPlease find the attached pdf\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.$root.fn_share(shareObj);
            },

            fn_goBack: function(){
                this.$router.go(-1);
            },


            
        },
        mounted: function(){
            // window.js_globals.fn_onBackPress = this.fn_goBack;
            this.statementType = this.$route.query.type;
            this.fn_get();
        },
        beforeRouteUpdate(to, from, next){
            // alert('up');
            console.log(from);
            console.log(to);
            next(true)
            this.fn_get();
        },
        // beforeRouteLeave (to, from, next) {
        //  if(this.$refs['ref_dialogDatePicker'].datepicker){
        //      this.$refs['ref_dialogDatePicker'].closeDialogWeb();
        //      next(false);
        //  }
        //  else if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
        //      this.$refs['ref_dialogCustomPeriod'].closeDialogWeb();
        //      next(false);    
        //  }
        //  else{
        //      next(true);
        //  }
     //    }
    }
</script>