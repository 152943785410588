<template>
    <div id="subdatatable">

        <div class="reportdetails table_view_details">
          <v-card flat>
            <v-card-text class="pt-4 pb-4">

              <p class="mb-0">
                <span>
                    <span>Period</span>: 
                    <span>{{reportData.startDate | moment("DD-MMM-YYYY")}}</span> 
                    <span> To </span> 
                    <span>{{reportData.endDate | moment("DD-MMM-YYYY")}}</span>
                  </span>
              </p>

            </v-card-text>
          </v-card>
          
        </div>

        <div style="overflow: auto;height: calc(((100vh - 56px) - 54px) - 3px);">
            <table full-width cellpadding="2" class="gstr-sub-table" :class="[reportData.internalHeaderRow ? 'nested-table' : 'simple-table', isPrint ? 'print-sub-report' : '']" style="font-size:12px" v-if="reportData && reportData.data">
                <thead white-back>
                    <tr>
                        <th v-for="(item, index) in reportData.headerRowArr" :key="index" :class="{'right-align':item.type=='number'}">
                            {{item.headerName}}
                            <span v-if="item.rupeeSymbol">
                                <img style="margin-left: 4px;" src="../../../assets/rupee_symbol.png" alt="rupee_symbol" align="top"/>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(row, index) in reportData.data.detailRows" :key="index">
                    <tr @click="fn_getSubReportData2(row.subReportId)">
                        <td v-for="(col, colIndex) in reportData.columns" :class="{'right-align':typeof(row.cols[col])=='number'}" :key="colIndex">
                            <span v-if="col=='transactionDate' || col=='shippingBillDate' || col=='referenceTransactionDate'">
                                {{row.cols[col] | moment("DD-MMM-YYYY")}}
                            </span>

                            <span v-else-if="typeof(row.cols[col])=='number'">
                                <span v-if="col != 'qty' && col != 'totalNumber' && col != 'cancelled' && col != 'netIssue'">{{row.cols[col] !== null && row.cols[col] !== '' ? $root.numberToLocale(row.cols[col]) : ''}}</span>
                                <span v-else>{{row.cols[col] !== null && row.cols[col] !== '' ? row.cols[col] : ''}}</span>
                            </span>
                            
                            <span v-else>
                                {{row.cols[col] !== null && row.cols[col] !== '' ? row.cols[col] : ''}}
                            </span>
                        </td>
                    </tr>

                    <tr grey-back v-if="reportData.internalHeaderRow && row.cols.otherTax && row.cols.otherTax.items && row.cols.otherTax.items.length">
                        <td v-for="(item, itemIndex) in reportData.internalHeaderRowArr" :key="itemIndex" :class="{'right-align':item.type=='number', 'has_rupee_symbol':item.rupeeSymbol}">
                            <strong>{{item.headerName}}</strong>
                            <span v-if="item.rupeeSymbol">
                                <img style="margin-left:4px" src="../../../assets/rupee_symbol.png" alt="rupee_symbol" align="top"/>
                            </span>
                        </td>
                    </tr>

                    <template v-if="reportData.internalHeaderRow && row.cols.otherTax && row.cols.otherTax.items && row.cols.otherTax.items.length">
                        <tr class="extrarow" v-for="(item, index) in row.cols.otherTax.items" :key="index" @click="fn_getSubReportData2(row.subReportId)">
                            <td v-for="(col, colIndex) in reportData.internalColumns" :key="colIndex" :class="{'right-align':typeof(item[col])=='number'}">
                                <span v-if="typeof(item[col])=='number'">
                                    {{item[col] !== null && item[col] !== '' ? $root.numberToLocale(item[col]) : ''}}
                                </span>
                                <span v-else>
                                    {{item[col] !== null && item[col] !== '' ? item[col] : ''}}
                                </span>
                            </td>
                        </tr>   
                    </template>
                    
                    <template v-if="reportData.internalHeaderRow && row.cols.otherTax && row.cols.otherTax.total">
                        <tr>
                            <td v-for="(col, colIndex) in reportData.internalColumns" :key="colIndex" :class="{'right-align':typeof(row.cols.otherTax.total[col])=='number'}">
                                <span v-if="typeof(row.cols.otherTax.total[col])=='number'">
                                    <strong>{{row.cols.otherTax.total[col] !== null && row.cols.otherTax.total[col] !== '' ? $root.numberToLocale(row.cols.otherTax.total[col]) : ''}}</strong>
                                </span>
                                <span v-else>
                                    <strong>{{row.cols.otherTax.total[col] !== null && row.cols.otherTax.total[col] !== '' ? row.cols.otherTax.total[col] : ''}}</strong>
                                </span>
                            </td>
                        </tr>   
                    </template>
                    
                </tbody>

                <!-- Footer -->
                <template v-if="reportData.data.detailRows && reportData.data.detailRows.length && reportData.data.footerRow">
                    <tr font-medium style="background:#ffffff !important;border-top:1px #f9f9f9 solid;border-bottom:1px #f9f9f9 solid">

                        <template v-if="reportData.internalHeaderRow">
                            <td v-for="(item, index) in reportData.internalHeaderRowArr" :key="index" :class="{'right-align':item.type=='number'}">
                                <span v-if="item.type=='number' && fn_getFooterColumnValue(index) !== null && fn_getFooterColumnValue(index) !== ''">
                                    <strong>{{$root.numberToLocale(fn_getFooterColumnValue(index))}}</strong>
                                </span>
                                <span v-else>
                                    <strong>{{fn_getFooterColumnValue(index)}}</strong>
                                </span>
                            </td>    
                        </template>
                        

                        <template v-if="!reportData.internalHeaderRow">
                            <td v-for="(item, index) in reportData.headerRowArr" :key="index" :class="{'right-align':item.type=='number'}">
                                <span v-if="item.type=='number' && fn_getFooterColumnValue(index) !== null && fn_getFooterColumnValue(index) !== ''">
                                    <strong>{{$root.numberToLocale(fn_getFooterColumnValue(index))}}</strong>
                                </span>
                                <span v-else>
                                    <strong>{{fn_getFooterColumnValue(index)}}</strong>
                                </span>
                            </td>
                        </template>
                    </tr>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
    export default{
        props:['reportData', 'isPrint', 'getSubReportData2'],
        methods:{
            fn_getSubReportData2: function(reportId){
                if(reportId){
                    this.getSubReportData2(reportId);
                }
                else{
                    // alert("No details");
                    this.$eventHub.$emit('snackbarAlert', {msg:'No details'});
                }
            },

            fn_getFooterColumnValue: function(index){
                // let key = Object.keys(this.reportData.data.footerRow)[index];
                let key = null;
                if(this.reportData.internalColumns){
                    key = this.reportData.internalColumns[index];   
                }
                else{
                    key = this.reportData.columns[index];
                }
                
                console.log(key);
                console.log(this.reportData.data.footerRow[key]);
                return this.reportData.data.footerRow[key];
            }
        }
    }
</script>

<style>
    table.gstr-sub-table, table.gstr-sub-table th, table.gstr-sub-table td{
        border-collapse: collapse;
    }
    table.gstr-sub-table thead{
        border-bottom: 1px solid #ddd;
    }
    table.gstr-sub-table th, table.gstr-sub-table td {
      padding: 12px;
      min-width:120px;
    }
</style>