<template>
  <div>

        <v-toolbar flat :height="56" z-index="9">
            <v-toolbar-title>Favourites</v-toolbar-title>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back">

          <v-alert type="info" icon="info_outline" font12 dense :value="true">Select an item to get details</v-alert>

            <v-list dense class="pa-0">
                
                <v-list-item-group color="primary">
                  <template v-for="(item, itemIndex) in favouriteItems">
                      <v-list-item style="background:#fff" :key="itemIndex" @click="fn_openList(item)">
                          <v-list-item-content>
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                              <v-icon>chevron_right</v-icon>
                          </v-list-item-icon>
                      </v-list-item>
                      <v-divider v-if="favouriteItems.length-1 != itemIndex" :key="item.title"></v-divider>    
                  </template>
                
                </v-list-item-group>
            </v-list>
        </div>

        <dialog-business-list ref="ref_dialogBusinessList"></dialog-business-list>
    </div>
</template>

<script>

// import dialogDriveAlert from '../../transactions/forms/dialogs/dialog_drive_alert'

import dialogBusinessList from '../dialogs/dialog_business_list'

  export default{
    name: "favourites",
    // props:['draftCount'],
    components:{
      // 'dialog-drive-alert':dialogDriveAlert
      'dialog-business-list':dialogBusinessList
    },
    data(){
      return{
        selectedItem:1,
        favouriteItems:[
          {
            "id":1, 
            "title":"Sale Invoice",
            "uri":"/transactions/sales/saleinvoice/items",
            "routeName":"",
            "listId":"sale",
            "type":"transaction",
            "transactionTypeId":10003
          },
          {
            "id":2, 
            "title":"Order",
            "uri":"/transactions/sales/order/items",
            "routeName":"",
            "listId":"sale",
            "type":"transaction",
            "transactionTypeId":10001
          },
          {
            "id":3, 
            "title":"Estimate/Quotation",
            "uri":"/transactions/sales/estimate/items",
            "routeName":"",
            "listId":"sale",
            "type":"transaction",
            "transactionTypeId":10005
          },
          {
            "id":4, 
            "title":"Purchase Invoice",
            "uri":"/transactions/purchase/purchaseinvoice/items",
            "routeName":"",
            "listId":"purchase",
            "type":"transaction",
            "transactionTypeId":20003
          },
          {
            "id":5, 
            "title":"Expense",
            "uri":"/transactions/purchase/expense/items",
            "routeName":"",
            "listId":"expense",
            "type":"transaction",
            "transactionTypeId":20005
          },
          // {
          //   "id":5, 
          //   "title":"Customer",
          //   "uri":"/masters/accounts/ledgers/items",
          //   "routeName":"ledgerItems",
          //   "groupTypeId":101
          // },
          {
            "id":6, 
            "title":"Customer",
            "uri":"/masters/accounts/ledgers/items",
            "routeName":"ledgerItems",
            "groupTypeId":101,
            "listId":"ledgers",
            "type":"master",
          },
          // {
          //   "id":6, 
          //   "title":"Supplier",
          //   "uri":"/masters/accounts/ledgers/items",
          //   "routeName":"ledgerItems",
          //   "groupTypeId":102
          // },
          {
            "id":7, 
            "title":"Supplier",
            "uri":"/masters/accounts/ledgers/items",
            "routeName":"ledgerItems",
            "groupTypeId":102,
            "listId":"ledgers",
            "type":"master"
          },
          {
            "id":8, 
            "title":"Items",
            "uri":"/masters/inventory/productandservices/items",
            "routeName":"productAndServiceItems",
            "listId":"product",
            "type":"master"
          },
          {
            "id":9, 
            "title":"Payment In",
            "uri":"/transactions/receipt/items",
            "routeName":"",
            "listId":"accountbooks",
            "type":"transaction",
            "transactionTypeId":30001
          },
          {
            "id":10, 
            "title":"Payment Out",
            "uri":"/transactions/payment/items",
            "routeName":"",
            "listId":"accountbooks",
            "type":"transaction",
            "transactionTypeId":30002
          },
        ]
      }
    },
    methods:{

      open: function(){
        this.$refs['ref_dialogBusinessList'].openDialogWeb();
      },
      // fn_openList: function(item){
      //   if(item.routeName){
      //     // this.$router.push({name:item.routeName, query:{tagId:this.guidWeb(), groupType:item.title}, params: { 'isNew': 1 }});
      //     if(item.groupTypeId){
      //       this.$router.push({name:item.routeName, query:{tagId:this.guidWeb()}, params: { 'isNew': 1, 'groupTypeId': item.groupTypeId}});  
      //     }
      //     else{
      //       this.$router.push({name:item.routeName, query:{tagId:this.guidWeb()}, params: { 'isNew': 1 }});  
      //     }
      //   }
      //   else{
      //     this.$router.push({path:item.uri, query:{tagId:this.guidWeb()}});
      //   }
      // },

      fn_openList: function(item){
          if(item.transactionTypeId){
              this.$router.push({path:"/"+this.$route.params.businessId+"/"+item.listId, query:{tagId:this.guidWeb(), transactionTypeId:item.transactionTypeId}});
          }
          else if(item.groupTypeId){
            this.$router.push({path:"/"+this.$route.params.businessId+"/"+item.listId, query:{tagId:this.guidWeb(), transactionTypeId:item.transactionTypeId, groupTypeId: item.groupTypeId}});
          }
          else{
              this.$router.push({path:"/"+this.$route.params.businessId+"/"+item.listId, query:{tagId:this.guidWeb()}});
          }
          // if(item.title == "Expense Items"){
          //     this.$router.push({name:item.routeName, query:{tagId:this.guidWeb(), inventory:0}, params: { 'isNew': 1 }});
          // }
          // else{
          //     this.$router.push({name:item.routeName, query:{tagId:this.guidWeb()}, params: { 'isNew': 1 }});
          // }
      },
      
      fn_openForm: function(item){
        // item.type == "transaction" ? localStorage.setItem("SetDataDrive", 1) : '';

        if(item.groupTypeId){
          this.$router.push({path:item.uri+'/new', query:{tagId:this.guidWeb(), groupTypeId:item.groupTypeId}});
        }
        else{
          this.$router.push({path:item.uri+'/new', query:{tagId:this.guidWeb()}});
        }
      }
    }
  }
</script>