<template>
    <div>

        <v-toolbar :height="56" flat extended>
            <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

            <v-toolbar-title>Items</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

            <v-btn icon @click="fn_openFilterNav">
                <v-icon>filter_list</v-icon>
                <span style="font-size: 48px;position: absolute;bottom: -10px;right: 6px;color: #4b77be;" v-if="selectedFilterItemValue != null || selectedSortValue">&bull;</span>
            </v-btn>

            <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back hasExtention" v-scroll.self="onScroll">

            <!-- div full-width class="search-filter two-btn">
                <v-text-field elevation-10  flat solo clearable placeholder="Search..." v-model="searchValue" @input="fn_searchItem" class="search-box" hide-details :class="{'bottom-shadow':scrollPositionTop!=0}"></v-text-field>

                <div v-ripple class="filter-btn" @click="add">
                    <v-icon>add</v-icon>
                </div>
                
                <div v-ripple class="filter-btn" @click="fn_openDialogFilterItems">
                    <v-icon>filter_list</v-icon>
                    <span style="font-size: 48px;position: absolute;bottom: -6px;right: 6px;color: #4b77be;" v-if="selectedFilterItemValue != null || selectedSortValue">&bull;</span>
                </div>

            </div> -->


            <div>

                <v-list grey-back three-line nopadding>

                    <div white-back v-for="(item, index) in listItemObject.items" :key="index" :class="{'mt-2':index!=0}" :id="'item_'+item.productId">
                        <v-list-item v-ripple @click="fn_itemClick(item.productId, index)">

                            <v-list-item-content>
                                <v-list-item-title class="mb-1">{{item.productName}}</v-list-item-title>
                                <v-list-item-subtitle>
                                    Purchase Rate: &#8377; {{item.purchasePrice ? $root.numberToLocale(roundUpto2Decimals(item.purchasePrice)) : '0'}}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle>
                                    Sale Price: &#8377; {{item.sellingPrice ? $root.numberToLocale(roundUpto2Decimals(item.sellingPrice)) : '0'}}
                                </v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-action style="padding:10px 0px">
                                <div></div>

                                <div :class="{'redcolor': item.currentStock <= 0}">
                                    {{item.currentStock ? item.currentStock : 0}} {{item.unitName}}
                                </div>
                                
                            </v-list-item-action>

                        </v-list-item>

                        <v-divider></v-divider>
                        <div right-align style="padding:2px 6px">
                            <v-btn nomargin icon @click="fn_adjustStock('plus', item.productId)"><v-icon font20>add_circle_outline</v-icon></v-btn>
                            <v-btn nomargin icon @click="fn_adjustStock('minus', item.productId)"><v-icon font20>remove_circle_outline</v-icon></v-btn>
                        </div>

                    </div>


                </v-list>

                <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
                  <v-col cols="6">
                    <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
                  </v-col>
                </v-row>

                <v-row class="ma-0" align-content="center" justify="center" v-if="!listItemObject.totalCount && !loadMore && !loadTimeOut">
                  <v-col cols="8" center-align>
                    There are no items!
                  </v-col>
                </v-row>

                <div center-align class="pa-6" v-if="loadTimeOut">
                    <p font12 light-opacity-color>Unable to load the items!</p>
                    <v-btn capitalize text color="primary" @click="fn_searchItem">
                        <v-icon left>refresh</v-icon>
                        Retry
                    </v-btn>
                </div>
                
                <!-- <transition name="fade" mode="out-in">
                    <v-card noshadow class="create-placeholder-card" v-if="showPlaceHolder && !listItemObject.totalCount">
                        <v-card-text @click="add">

                            <div center-align>
                                <div v-ripple class="placeholder-icon">
                                    <i aria-hidden="true" class="v-icon material-icons add-icon">add</i>
                                    <img src="../../../assets/icon_category.svg" alt="">
                                </div><br>

                                <p class="title light-opacity-color">Create New Item</p>
                            </div>
                        </v-card-text>
                    </v-card>
                </transition> -->
            </div>

        </div>  

        <!-- <dialog-filter-items ref="ref_dialogFilterItems" type="Items" :filterData="filterData" :setFilter="fn_setFilter" :selectedFilterItemValue="selectedFilterItemValue" :selectedSortValue="selectedSortValue"></dialog-filter-items> -->

        <v-navigation-drawer v-model="filter_nav" absolute right temporary>
          <filter-items ref="ref_filterItems" type="Items" :filterData="filterData" :setFilter="fn_setFilter" :selectedFilterItemValue="selectedFilterItemValue" :selectedSortValue="selectedSortValue" :closeFilterNav="fn_closeFilterNav"></filter-items>
        </v-navigation-drawer>


    </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../config.js').configs.bottomSheetMenu;

// import dialogFilterItems from '../dialogs/dialog_filter';

import filterItems from './dialogs/filter_items';

    export default{
        // props:['add'],
        components: {
            'filter-items': filterItems,
        },
        data(){
            return{
                listItemObject:{"items":[], "totalCount":0},
                searchValue:'',
                startIndex:0,
                scrollPositionTop:0,
                selectedItemId:null,
                filter_nav:false,
                filterData:{
                    filterItems:[
                        {"filterItemId":1, "name":"All", "value":null},
                        {"filterItemId":2, "name":"In Stock", "value":1},
                        {"filterItemId":3, "name":"Not In Stock", "value":0}
                    ],
                    sortItems:[
                        {"sortId":1, "name":"Item Name", "value":0},
                        {"sortId":2, "name":"Stock - High to Low", "value":1},
                        {"sortId":3, "name":"Stock - Low to High", "value":2}
                    ],
                },
                selectedFilterItemValue:null,
                selectedSortValue:0,
                loadMore:false,
                loadTimeOut:false,
                showPlaceHolder:false
            }
        },
        methods:{

            fn_new: function(){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/product/items/new', query:{tagId:this.guidWeb(), inventory:1}});
            },

            fn_openFilterNav: function(){
                this.filter_nav = true;
                this.$refs['ref_filterItems'].fn_setCurrentFilterValues();
            },

            fn_closeFilterNav: function(){
                this.filter_nav = false;
            },

            fn_adjustStock(type, productId){
                // let obj = {};
                // obj.itemId = null;
                // obj.lastScrollPos = window.pageYOffset;
                // obj.itemIndex = index + 1;
                // localStorage.setItem("activity_items_state", JSON.stringify(obj));

                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+"/stockadjustment/items/new", query:{tagId:this.guidWeb(), type:type, itemid:productId}});
            },

            onScroll: function(event){
                var element = event.target;
                if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.listItemObject.items.length < this.listItemObject.totalCount){
                        this.fn_loadItems();
                    }
                }
            },

            fn_setFilter: function(filterItemId, sortId){
                this.selectedFilterItemValue = filterItemId;
                this.selectedSortValue = sortId;

                let obj = {};
                obj.filterItemId = filterItemId;
                obj.sortId = sortId;
                localStorage.setItem('activity_item_filter', JSON.stringify(obj));
                // this.fn_closeDialog();
                this.fn_closeFilterNav();
                this.fn_searchItem();
            },

            fn_openDialogFilterItems: function(){
                this.$refs['ref_dialogFilterItems'].openDialogWeb();
            },

            fn_closeDialog: function(){
                this.$refs['ref_dialogFilterItems'].dialog_filter_items = false;
            },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='edit'){
            //      this.$router.push('/transactions/sales/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+this.selectedItemId+'/edit');
            //  }
            //  else if(menuAction=='copy'){
            //      this.$router.push({path:"/transactions/sales/"+this.toolbarTitle.toLowerCase().replace(/ /g,'')+"/items/new", query:{tagId:this.guidWeb(), itemId:this.selectedItemId, copy:true}});
            //  }
            // },

            fn_itemClick: function (itemId, index) {
                console.log(itemId, index);
                // alert("Under Development");
                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("activity_items_state", JSON.stringify(obj));

                // this.$router.push('/masters/inventory/productandservices/items/'+itemId);
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/product/items/'+itemId);
            },

            fn_loadItems: function() {
                this.loadMore = true;
                let filterObject = {};
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';

                if(localStorage.getItem('activity_item_filter')){
                    let data = JSON.parse(localStorage.getItem('activity_item_filter'));
                    this.selectedFilterItemValue = data.filterItemId;
                    this.selectedSortValue = data.sortId;
                }

                filterObject.stockType = this.selectedFilterItemValue;
                filterObject.sortByStock = this.selectedSortValue;

                let pageState = null;
                if(localStorage.getItem("activity_items_state")){
                    pageState = JSON.parse(localStorage.getItem("activity_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }

                this.fn_loadTimeOut();

                let self = this;
                this.getItems("InventoryItemList", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;

                            for(let i in responseJson.items){
                                let itemIndex = self.listItemObject.items.findIndex(item => item.productId==responseJson.items[i].productId);

                                if(itemIndex==-1){
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }
                            }

                            // console.log(pageState);
                            // if(pageState && pageState.itemId){
                            //     setTimeout(function(){
                            //         let scrollOptions = {"duration":0, "offset":-56, "easing":"linear"};
                            //         self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                            //         localStorage.removeItem("activity_items_state");
                            //     },100);
                            // }

                            // if(pageState && !pageState.itemId){
                            //     setTimeout(function(){
                            //         let scrollOptions = {"duration":300, "offset":-56, "easing":"linear"};
                            //         self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                            //         localStorage.removeItem("activity_items_state");
                            //     },100);
                            // }

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300);
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                            self.loadTimeOut = false;
                        }
                    }
                )
            },

            fn_searchItem: function () {
                this.startIndex = 0;
                this.listItemObject.items = [];
                this.fn_loadItems();
            },

            fn_debounceSearch: function(){
                 if (this.timeoutid != null){
                    clearTimeout(this.timeoutid);
                 }

                this.timeoutid = setTimeout(() => { 
                    this.fn_searchItem();
                },300);
            },

            fn_loadTimeOut: function(){
                setTimeout(() => {
                    if(this.loadMore){
                        this.loadTimeOut = true;
                        this.loadMore = false;
                    }
                    else{
                        this.loadTimeOut = false;
                    }
                }, 1 * 10 * 1000);
            }
        },
        mounted: function(){
            this.fn_loadItems();

            this.$eventHub.$on('UpdateItemList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });

            // let eventFired = false;

            // this.$eventHub.$on('item', () => {
            //     eventFired = true;
            //     eventFired ? this.fn_new() : '';
            //     setTimeout(() => {
            //         eventFired = false;
            //     }, 2000);
            // });

            // if(this.$route.query.type == 'item'){
            //     this.fn_new();
            // }
        },
        // beforeRouteLeave(to, from, next){
        //     this.$eventHub.$off('item');
        //     next(true)
        // },
    }
</script>