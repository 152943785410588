<template>
    <div>
        <v-navigation-drawer v-model="custom_date" absolute right :overlay="true">
            <v-card flat>
                <v-subheader>Custom Date<v-spacer></v-spacer><v-btn icon @click="custom_date=false"><v-icon>close</v-icon></v-btn></v-subheader>
                <v-divider></v-divider>
                <v-card-text>
                    <v-layout ma-0 row wrap>
                        <v-flex xs12 sm12 md12 cursor-pointer @click="openDatePicker('start', startDate)">
                            <v-text-field :value="fn_getStartDate()" label="Start Date" readonly append-icon="drop_down"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12 cursor-pointer @click="openDatePicker('end', endDate)">
                            <v-text-field :value="fn_getEndDate()" label="End Date" readonly append-icon="drop_down"></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md12 redcolor pa-2 v-if="!isDateSelectionValid">
                            Invalid date selection
                        </v-flex>

                        <v-flex xs12 sm12 md12 grey-back pa-2 v-if="!isDateSelectionValid">
                            Note: Start date should be less than end date.
                        </v-flex>

                        <!-- {{ isDateSelectionValid }} -->
                        <v-flex xs12 sm12 md12 right-align>
                            <v-btn text color="primary" @click="actionMethod" :disabled="!isDateSelectionValid">Done</v-btn>
                        </v-flex>

                        

                    </v-layout>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
    </div>
</template>
<script>
    export default{
        props:['openDatePicker', 'actionMethod', 'startDate', 'endDate'],
        data(){
            return{
                custom_date:false
            }
        },
        methods:{
            fn_getStartDate: function(){
                return this.$moment(this.startDate).format('DD-MMM-YYYY');
            },
            fn_getEndDate: function(){
                return this.$moment(this.endDate).format('DD-MMM-YYYY');
            },
        },
        computed:{
            isDateSelectionValid: function(){
                if(new Date(this.startDate) > new Date(this.endDate)){
                    return false;
                }
                else{
                    return true;
                }
            }
        }
    }
</script>