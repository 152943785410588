<template>
    <div>

        <!-- <div class="display_msg pos_inherit" grey-back v-if="!listItemObject.totalCount">There are no activities!</div> -->

        <div>

            <v-list grey-back three-line nopadding v-if="listItemObject.totalCount">

                <div white-back v-for="(item, index) in listItemObject.items" :key="index" :class="{'mt-2':index!=0}" :id="'item_'+item.transactionId">
                    <v-list-item class="right-subtitle-tile" @click="fn_itemClick(item, index)">
                        <v-list-item-content>
                          <!-- <v-list-item-title class="mb-1">{{fn_transactionName(item.transactionListId)}}</v-list-item-title> -->
                          <v-list-item-title class="mb-1">{{item.partyName}}</v-list-item-title>
                          <v-list-item-subtitle class="mb-1">{{fn_transactionName(item.transactionListId)}}</v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <span v-if="item.mergeTransactionNumber" class="primary-color">{{item.mergeTransactionNumber}}</span>
                            {{item.mergeTransactionNumber ? ' | ' : ''}}
                            {{fn_transactionDate(item.transactionListId, item.transactionDate)}} | {{item.qty}} {{item.unitName}}
                          </v-list-item-subtitle>

                            <template v-if="item.referenceItems && item.referenceItems.length">
                                <div class="pl-2 pr-2 mt-1" style="background: aliceblue;width: auto;border-radius: 5px;max-width: fit-content;">
                                    <v-list-item-subtitle @click="fn_subItemClick($event, ref, index)" class="mt-1" v-for="(ref, refIndex) in item.referenceItems" :key="refIndex">
                                        {{fn_transactionName(ref.transactionListId)}} <span class="primary-color">{{ref.mergeTransactionNumber}}</span> | {{fn_transactionDate(ref.transactionListId, ref.transactionDate)}}
                                    </v-list-item-subtitle>
                                </div>
                            </template>
                          
                        </v-list-item-content>

                        <v-list-item-action font16 align-flex-end>
                            <!-- <div></div>
                            <div>&#8377; {{$root.numberToLocale(item.taxableValue)}}</div> -->
                            <!-- <div font12>{{item.qty}} {{item.unitName}}</div> -->
                            <div class="warning-label" v-if="!item.status">Cancelled</div>
                            <div>&#8377; {{$root.numberToLocale(item.taxableValue)}}</div>
                            <!-- <div class="warning-label">Cancelled</div> -->
                            <!-- <div v-else></div> -->
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider v-if="item.invoiceTemplate"></v-divider>
                    <div right-align style="padding:2px 6px;position:relative" v-if="item.invoiceTemplate">
                        <!-- <div class="warning-label" v-if="!item.status">Cancelled</div> -->
                        <v-btn nomargin icon text @click="fn_preview(item, true)"><v-icon font20>print</v-icon></v-btn>
                        <v-btn nomargin icon text @click="fn_preview(item)">
                            <v-icon>preview</v-icon>
                        </v-btn>
                    </div>

                    <!-- <v-divider v-if="listItemObject.items.length-1 != index"></v-divider> -->
                </div>

            </v-list>

            <div style="display: flex;justify-content: center;padding: 16px;background: #f0f0f0;" v-if="loadMore">
                <!-- <v-progress-circular indeterminate color="primary" v-if="loadMore"></v-progress-circular> -->
                <v-progress-linear rounded indeterminate :height="6" color="primary" style="width:120px;"></v-progress-linear>
            </div>
        </div>
    </div>
</template>

<script>

const transactionForms = require('../../../../../config.js').configs.transactionForms;

import {transactionMixin} from '../../../../transactions/transaction_mixin'

    export default{
        mixins: [transactionMixin],
        props:['productId', 'preview', 'bindInvoiceTemplate'],
        data(){
            return{
                listItemObject:{"items":[], "totalCount":0},
                searchValue:'',
                startIndex:0,
                scrollPositionTop:0,
                selectedItemId:null,
                loadMore: false
            }
        },
        methods:{

            fn_test: function(event){
                event.stopPropagation();
                // alert('in');
            },

            fn_preview: function(item, isPrint){
                item.toolbarTitle = this.fn_transactionName(item.transactionListId);
                let printType = item.invoiceTemplate == 101 ? 'ab' : item.invoiceTemplate == 201 ? 'sj' : null;

                let type = null;
                if(item.transactionListId == 10001 || item.transactionListId == 10002 || item.transactionListId == 10003 || item.transactionListId == 10004 || item.transactionListId == 10005){
                  type = 'sale';
                }
                else if(item.transactionListId == 20001 || item.transactionListId == 20002 || item.transactionListId == 20003 || item.transactionListId == 20004){
                  type = 'purchase';
                }
                else if(item.transactionListId == 20005){
                  type = 'expense';
                }
                // else if(item.transactionListId == 40002){
                //   type = 'inventory';
                // }
                
                this.fn_previewInvoice(true, type, printType, item, isPrint);
            },

            fn_transactionDate: function(listId, transactionDate){
                if(listId != 40001){
                    return this.$moment(transactionDate).format('DD-MMM-YYYY'); 
                }
                else{
                    return this.$moment(this.$root.CurrentBusiness.OpeningDate).format('DD-MMM-YYYY');                  
                }
            },

            onScroll: function(event){
                var element = event.target;
                if (element.documentElement.scrollHeight - element.documentElement.scrollTop - 20 <= element.documentElement.clientHeight)
                {
                    console.log('scrolled');
                    if(this.listItemObject.items.length < this.listItemObject.totalCount){
                        this.fn_loadItems();
                    }
                }
            },

            fn_transactionName: function(transactionListId){
                for(let i=0; i<transactionForms.length; i++){
                    if(transactionForms[i].id == transactionListId){
                        return transactionForms[i].title;
                    }
                }
            },

            fn_menuItemClick: function(tagId, menuId, menuAction){
                if(menuAction=='edit'){
                    this.$router.push('/transactions/sales/saleinvoice/items/'+this.selectedItemId+'/edit');
                }
            },

            fn_itemClick: function (item, index) {
                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("transaction_items_state", JSON.stringify(obj));

                let viewName = this.fn_transactionName(item.transactionListId);

                // this.$root.fn_navigateToDisplayView(item.transactionListId, item.transactionId);
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+viewName.toLowerCase().replace(/ /g,'')+'/items/'+item.transactionId, query:{back:1}});
                // this.$router.push('/transactions/sales/saleinvoice/items/'+itemId);
            },

            fn_subItemClick: function (event, refItem, index) {
                event.stopPropagation();
                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("transaction_items_state", JSON.stringify(obj));
                let viewName = this.fn_transactionName(refItem.transactionListId);
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+viewName.toLowerCase().replace(/ /g,'')+'/items/'+refItem.transactionId, query:{back:1}});
            },

            // fn_getBottomMenu: function (elementId, itemId) {
            //     itemId ? this.selectedItemId = itemId : '';

            //     let bottomMenu;

            //     if(elementId == bottomSheetMenu.toolBar.Id){
            //         bottomMenu = {"BottomMenu": []};
            //     }
            //     else if(elementId == bottomSheetMenu.masterListItems.Id){
            //         bottomMenu = {"BottomMenu": [menuItem.edit]};
            //     }
                
            //     this.setBottomMenu(tagId, bottomMenu, 
            //         {
            //             onSuccess: function (response) {
            //                 console.log(response);
            //             },
            //             onError: function (error) {
            //                 console.log(error);
            //             }
            //         }
            //     )
            // },

            fn_loadItems: function(isFirstLoad) {
                if(isFirstLoad){
                    this.listItemObject = {"items":[], "totalCount":0};
                }

                this.loadMore = true;
                let filterObject = {};
                filterObject.productId = this.productId;
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue.toLowerCase();
                this.loading = true;

                let pageState = null;
                if(localStorage.getItem("transaction_items_state")){
                    pageState = JSON.parse(localStorage.getItem("transaction_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }

                let self = this;
                this.getItems("StockTimeLineList", filterObject, 
                    {
                        onSuccess: function (response) {
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;

                            for(let i in responseJson.items){
                                let itemIndex = self.listItemObject.items.findIndex(item => item.transactionId==responseJson.items[i].transactionId);

                                if(itemIndex==-1){
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }
                                // self.listItemObject.items.push(responseJson.items[i]);   
                            }

                            console.log(pageState);
                            // if(pageState && pageState.itemId){
                            //  setTimeout(function(){
                            //      let scrollOptions = {"duration":0, "offset":-56, "easing":"linear"};
                            //      self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                            //      localStorage.removeItem("transaction_items_state");
                            //  },100);
                            // }

                            if(pageState && !pageState.itemId){
                                setTimeout(function(){
                                    let scrollOptions = {"duration":300, "offset":-56, "easing":"linear"};
                                    self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                                    localStorage.removeItem("transaction_items_state");
                                },100);
                            }

                            if(localStorage.getItem("scroll_top")){
                                setTimeout(function(){
                                    let scrollOptions = {"duration":0, "offset":0, "easing":"linear"};
                                    self.$vuetify.goTo(0, scrollOptions);
                                },100);
                            }

                            // if(!pageState){
                            //  setTimeout(function(){
                            //      let scrollOptions = {"duration":300, "offset":0, "easing":"linear"};
                            //      self.$vuetify.goTo(0, scrollOptions);
                            //  },100);
                            // }

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                        }
                    }
                )
            }


        },
        // mounted: function(){
        //     this.fn_loadItems();
        // }
    }
</script>