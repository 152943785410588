<template>
    <div>
        <v-dialog v-model="dialog_returnForm" no-click-animation persistent scrollable content-class="half-screen-dialog" lazy transition="dialog-bottom-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card flat>

                <v-toolbar flat fixed>
                  <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                  <v-toolbar-title>Items</v-toolbar-title>
                  <!-- <v-spacer></v-spacer>
                  <v-btn outlined color="primary" @click="fn_appendItems">Done</v-btn> -->
                  <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" slot="extension"></v-text-field>
                </v-toolbar>

                <v-card-text full-height nopadding id="scrollable_div">

                    <v-form data-vv-scope="fields" full-width @submit.prevent="submit">

                        <ValidationObserver ref="observer">
                        
                            <v-list nopadding auto-height-tile>

                                <div v-for="(item, index) in filteredItems" :key="index" :id="'item_'+item.groupId">

                                    <div font16 class="pt-2 pl-4 pr-4">{{item.productName}}</div>

                                    <template v-for="(variant, vIndex) in item.productVariants">

                                        <v-layout row wrap ma-0 :key="vIndex">
                                            <v-flex xs8 sm8 md8 class="pl-4 pr-4 pt-2 pb-2">
                                                <div font14 light-opacity-color v-if="variant.qty">
                                                    {{variant.qty}} {{variant.unitName}}
                                                    <span v-if="variant.packQuantity">({{variant.packQuantity}})</span> X {{item.rate}}
                                                </div>    
                                            </v-flex>

                                            <v-flex xs4 sm4 md4 pr-4>

                                                <ValidationProvider name="retrun qty" :rules="'max_value:'+variant.qty" v-slot="{ errors }">
                                                    <!-- <v-text-field 
                                                        class="pa-0 ma-0"
                                                        align-input-text-right
                                                        hide-details
                                                        v-model.number="variant.returnQty"
                                                        label=""
                                                        placeholder="Rqty" 
                                                        type="number" 
                                                        @input="fn_validateInput(variant)"
                                                        :error-messages="errors">
                                                    </v-text-field> -->
                                                    <v-text-field 
                                                        class="pa-0 ma-0"
                                                        align-input-text-right
                                                        hide-details
                                                        @input="$root.fn_boundDecimalLength(variant, 'returnQty', variant, fn_validateInput)"
                                                        v-model="variant.returnQty"
                                                        label=""
                                                        placeholder="Rqty" 
                                                        type="number" 
                                                        :error-messages="errors">
                                                    </v-text-field>

                                                    <div style="font-size:12px;padding:2px;color:#ff5252">{{errors[0]}}</div>
                                                </ValidationProvider>

                                            </v-flex>
                                        </v-layout>
                                    </template>



                                    <!-- <v-list-item list-checkbox class="right-subtitle-tile" @click="fn_changeSelection(item)">
                                        <v-list-item-content>
                                          <v-list-item-title class="mb-1">{{item.productName}}</v-list-item-title>

                                          <template v-for="(variant, vIndex) in item.productVariants">
                                                <v-list-item-subtitle class="mb-2" :key="vIndex" v-if="variant.qty">

                                                    <v-layout row wrap layout-wrap align-flex-end>
                                                      <v-flex xs9 sm9 md9>
                                                        {{variant.qty}} {{variant.unitName}}
                                                        <span v-if="variant.packQuantity">({{variant.packQuantity}})</span> X {{item.rate}}
                                                      </v-flex>

                                                      <v-flex xs3 sm3 md3>

                                                        <ValidationProvider :name="'retrunQty'+index+vIndex" rules="numeric|min_value:1|max_value:'+variant.qty" v-slot="{ errors }">
                                                            <v-text-field 
                                                                align-input-text-right
                                                                hide-details
                                                                 
                                                                v-model.number="variant.returnQty"
                                                                label=""
                                                                placeholder="Rqty" 
                                                                type="number" 
                                                                @input="fn_validateInput(variant)"
                                                                required
                                                                :error-messages="errors">

                                                            </v-text-field>

                                                            <div style="font-size:12px;padding:2px;color:#ff5252">{{errors[0]}}</div>
                                                        </ValidationProvider>

                                                      </v-flex>

                                                    </v-layout>
                                                    
                                                </v-list-item-subtitle>
                                          </template>
                                          

                                        </v-list-item-content>
                                            

                                    </v-list-item> -->

                                    <!-- <div style="padding:5px 16px;background-color:#ddd" v-if="item.isValid == false">Return quantity should be less than or equal to bill quantity.</div>   -->
                                    <v-divider v-if="filteredItems.length-1 != index"></v-divider>
                                </div>

                            </v-list>

                        </ValidationObserver>

                    </v-form>

                    
                        

                    <div class="center-align pa-4" v-if="!filteredItems.length">No Items</div>
                </v-card-text>

                <v-card-actions>
                    <v-layout row wrap ma-0>
                        <v-flex height48 xs12 sm12 md12>
                            <v-btn color="primary" full-height nomargin block no-round-borders noshadow @click="fn_appendItems">Done</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>


        </v-dialog>
    </div>
</template>

<script>
import { numeric, min_value, max_value } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('min_value', min_value)
extend('max_value', max_value)
extend('numeric', numeric)

    export default{
        props:['items', 'type', 'addPendingItems'],
        data(){
            return{
                ordersList:[],
                dialog_returnForm:false,
                searchValue:'',
            }
        },
        components:{
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.searchValue = '';
                this.dialog_returnForm = true;
            },
            closeDialogWeb: function () {
                this.dialog_returnForm = false;
            },
            fn_changeSelection: function (item) {
                let transactionProducts = this.items[0].transactionProducts;
                for (var i = 0; i<transactionProducts.length; i++) {
                    if(transactionProducts[i].groupId == item.groupId){
                        transactionProducts[i].selected = !transactionProducts[i].selected;
                        this.$forceUpdate();
                        break;
                    }
                }
            },
            fn_appendItems: function () {

                this.$refs.observer.validate().then((result) => {
                    if (result) {
                        
                        let items = [];
                        // let error = 0;

                        let transactionProducts = JSON.parse(JSON.stringify(this.items[0].transactionProducts));

                        for(let i=0; i<transactionProducts.length; i++){

                            // this.items[0].transactionProducts[i].isValid = true;

                            for(let j=0; j<transactionProducts[i].productVariants.length; j++){

                                //assigning bill qty
                                transactionProducts[i].productVariants[j].billQty = transactionProducts[i].productVariants[j].qty;

                                //checking if return qty is greater then bill qty
                                // if(transactionProducts[i].productVariants[j].returnQty > transactionProducts[i].productVariants[j].qty || transactionProducts[i].productVariants[j].returnQty < 0){

                                //  this.onScroll(transactionProducts[i].productVariants[j].groupId);
                                //  error = 1;
                                //  break;
                                // }
                                // else{
                                transactionProducts[i].productVariants[j].qty = transactionProducts[i].productVariants[j].returnQty;
                                // }


                                let itemIndex = items.findIndex(item=>{
                                    return item.groupId == transactionProducts[i].groupId;
                                })

                                if(Number(transactionProducts[i].productVariants[j].returnQty) && itemIndex==-1){
                                    items.push(transactionProducts[i]);
                                }
                            }

                            // if(error){
                            //  this.items[0].transactionProducts[i].isValid = false;
                            //  this.$forceUpdate();
                            //  return;
                            // }

                        }

                        if(items.length){
                            this.addPendingItems(items, this.items[0].transactionAddOns, this.type);
                        }
                        else{
                            // this.$parent.snackbar_msg = 'Return quantity not filled.';
                            // this.$parent.snackbar = true;
                            // alert("Return quantity not filled");
                            this.$eventHub.$emit('snackbarAlert', {msg:'Return quantity not filled'});
                        }

                    }
                    else{
                        this.onScroll()
                    }
                });

                // let items = [];
                // let error = 0;

                // let transactionProducts = JSON.parse(JSON.stringify(this.items[0].transactionProducts));

                // for(let i=0; i<transactionProducts.length; i++){

                //  this.items[0].transactionProducts[i].isValid = true;

                //  for(let j=0; j<transactionProducts[i].productVariants.length; j++){

                //      //assigning bill qty
                //      transactionProducts[i].productVariants[j].billQty = transactionProducts[i].productVariants[j].qty;

                //      //checking if return qty is greater then bill qty
                //      if(transactionProducts[i].productVariants[j].returnQty > transactionProducts[i].productVariants[j].qty || transactionProducts[i].productVariants[j].returnQty < 0){

                //          this.onScroll(transactionProducts[i].productVariants[j].groupId);
                //          error = 1;
                //          break;
                //      }
                //      else{
                //          transactionProducts[i].productVariants[j].qty = transactionProducts[i].productVariants[j].returnQty;
                //      }


                //      let itemIndex = items.findIndex(item=>{
                //          return item.groupId == transactionProducts[i].groupId;
                //      })

                //      if(transactionProducts[i].productVariants[j].returnQty && itemIndex==-1){
                //          items.push(transactionProducts[i]);
                //      }
                //  }

                //  if(error){
                //      this.items[0].transactionProducts[i].isValid = false;
                //      this.$forceUpdate();
                //      return;
                //  }

                // }

                // if(items.length && !error){
                //  this.addPendingItems(items, this.items[0].transactionAddOns, this.type);
                // }
                // else{
                //  this.$parent.snackbar_msg = 'Return quantity not filled.';
                //  this.$parent.snackbar = true;
                // }
            },

            onScroll: function () {
                let scrollable_div = document.getElementById('scrollable_div');
                let target_Elements = document.getElementsByClassName('error--text');
                // let target_Element = document.getElementById('item_'+groupId);
                scrollable_div.scrollTop = target_Elements[0].offsetTop - 100;

                // this.$parent.snackbar_msg = 'Return quantity should be less then or equal to bill quantity';
                // this.$parent.snackbar = true;
                // alert("Return quantity should be less then or equal to bill quantity");
                this.$eventHub.$emit('snackbarAlert', {msg:'Return quantity should be less then or equal to bill quantity'});
            },

            fn_validateInput: function (variant) {
                if(variant.returnQty > variant.qty || variant.returnQty < 0){
                    // this.$parent.snackbar_msg = 'Return quantity should be less then or equal to bill quantity';
                    // this.$parent.snackbar = true;
                    // alert("Return quantity should be less then or equal to bill quantity");
                    this.$eventHub.$emit('snackbarAlert', {msg:'Return quantity should be less then or equal to bill quantity'});
                }
            }
        },
        computed:{
            filteredItems: function () {
                let searchText = this.searchValue ? this.searchValue.toLowerCase() : '';

                if(this.items && this.items.length){
                    return this.items[0].transactionProducts.filter(item => {
                        let name = item.productName.toLowerCase();
                        return name.match(searchText);
                    });
                }
                else{
                    return [];
                }


            }
                
        }
    }
</script>