<template>
    <div class="bill_template template_1 full-width" :class="{'noborder':!data.templateSettings.tableRowBorder, 'preview':preview, 'print-settings':isEditable}" full-width v-if="dataLoaded">
        <!-- <table border="0" id="header_template_1" full-width style="border-collapse:collapse;">
            <tbody>

                <tr>
                    <td left-align :style="{'background':invoiceColor}" class="noborder">
                        <div full-height class="title invoice_title pt-1 pb-1 center-align">
                            <template v-if="$root.CurrentBusiness.GSTRegistrationStatus == 1 && data.transactionDetails.transactionListId == 10003">
                                {{summary.totalTax && !hasNilRatedItems ? data.transactionDetails.invoiceTitle : summary.totalTax && hasNilRatedItems ? 'Invoice-cum-Bill of Supply' : 'Bill Of Supply'}}
                            </template>
                            <template v-else>
                                {{data.transactionDetails.invoiceTitle}}
                            </template>
                        </div>
                    </td>
                </tr>

                <tr right-align v-if="!data.transactionDetails.status">
                    <td class="noborder redcolor" colspan="3">Cancelled!</td>
                </tr>

                <tr center-align v-if="subtitle.data && subtitle.data.content">
                    <td class="noborder pl-2 pr-2" :colspan="headers.length">
                        <div v-html="subtitle.data.content" v-ripple :class="{'editable':isEditable}" @click="fn_editPrintField(subtitle.fieldId)"></div>
                    </td>
                </tr>

                <tr>
                    <td class="noborder border-bottom business_row" :colspan="headers.length" :class="{'pt-3':isEditable}">

                        <div class="col67" :class="{'hasLogo':!preview && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(logoField.showInPrintOut)}" style="white-space:nowrap">

                            <div class="business_logo" v-ripple v-if="data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(logoField.showInPrintOut)" :class="{'editable':isEditable, 'fade-opacity':!logoField.showInPrintOut}" @click="fn_editLogo">
                                <img :src="$root.CurrentBusiness.businessLogo"/>
                                <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Logo</div>
                            </div>

                            <div class="business_logo" :class="{'editable':isEditable}" @click="fn_editLogo" white-background v-if="(!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo) && isEditable">    
                                <div style="text-align: center;padding: 18px 0px;font-size: 10px;">Logo</div>
                                <div class="editabled-field-label" v-if="isEditable"><v-icon>add</v-icon> Logo</div>
                            </div>

                            <div style="clear:both;width:48px;float:left" class="pt-1" v-if="isEditable && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && logoField.fieldId">
                                <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="logoField.showInPrintOut" @change="fn_toggleField($event, logoField.fieldId)"></v-switch>
                            </div>

                            <div class="business_details" :class="{'nopadding': (!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo || !logoField.showInPrintOut) && !isEditable, 'pl-2':!preview && (data.templateSettings.businessLogo || $root.CurrentBusiness.businessLogo)}">
                                <div v-html="header.data.content" v-ripple v-if="header.data && header.data.content" :class="{'editable':isEditable}" @click="fn_editBusiness"></div>
                                <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Business Details</div>
                            </div>
                        </div>

                        <div class="col33" right-align style="white-space:nowrap">
                            <div style="padding: 1px 2px;border:1px solid;margin-bottom:2px" :style="{'borderColor':invoiceColor}" v-if="data.transactionDetails.transactionListId == 10003 && (($route.query.invoiceFor && $route.query.invoiceFor!='Standard') || (invoiceFor && invoiceFor!='Standard'))">
                                {{$route.query.invoiceFor ? $route.query.invoiceFor : invoiceFor ? invoiceFor : ''}}
                            </div>
                            <div>Invoice No: {{data.transactionDetails.transactionNumber}}</div>
                            <div>Date of Invoice: {{data.transactionDetails.transactionDate | moment("DD-MMM-YYYY")}}</div>
                            <div>Place of Supply: {{fn_getPlaceOfSupply()}}</div>
                            <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.gstNumber">GSTIN: {{data.transactionDetails.partyDetails.gstNumber}}</div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td :colspan="headers.length" class="noborder" full-height nopadding>

                        <div full-height class="col33_3" :class="{'col50_2':!otherFields.length}">
                            
                            <template v-if="data.transactionDetails.partyDetails.partyName && fn_showSection(billingLabel.showInPrintOut)">

                                <span :class="{'fade-opacity':!billingLabel.showInPrintOut}" font-heavy v-html="billingLabel.data.content" v-if="billingLabel.data && billingLabel.data.content"></span>

                                <div v-ripple :class="{'editable':isEditable && data.transactionDetails.partyDetails.partyName, 'fade-opacity':!billingLabel.showInPrintOut}" @click="fn_editParty">

                                    <div v-if="data.transactionDetails.partyDetails.partyName">
                                        {{data.transactionDetails.partyDetails.partyName}}
                                        <br>
                                        {{billingAddress}}<br>
                                        {{partyPhoneBilling ? 'Ph: ' + partyPhoneBilling : ''}} 
                                    </div>

                                    <div class="editabled-field-label" v-if="isEditable && data.transactionDetails.partyDetails.partyName"><v-icon>edit</v-icon> Party Details</div>
                                    
                                </div>

                                <div class="pt-1" v-if="isEditable">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="billingLabel.showInPrintOut" @change="fn_toggleField($event, billingLabel.fieldId)"></v-switch>
                                </div>

                            </template>

                            <div v-if="data.transactionDetails.transactionListId == 20005 && !data.transactionDetails.partyDetails.partyName">
                                Cash Expense
                            </div>

                        </div>

                        <div full-height class="col33_3" :class="{'col50_2 noborder':!otherFields.length}">

                            <template v-if="data.transactionDetails.partyDetails.partyName && fn_showSection(shippingLabel.showInPrintOut)">

                                <span :class="{'fade-opacity':!shippingLabel.showInPrintOut}" font-heavy v-html="shippingLabel.data.content" v-if="shippingLabel.data && shippingLabel.data.content"></span>

                                <div v-ripple :class="{'editable':isEditable, 'fade-opacity':!shippingLabel.showInPrintOut}" @click="fn_editParty('expense')">

                                    <div>
                                        {{data.transactionDetails.partyDetails.partyName}}
                                        <br>
                                        {{shippingAddress}}<br>
                                        {{partyPhoneShipping ? 'Ph: ' + partyPhoneShipping : ''}}
                                    </div>

                                    <div class="editabled-field-label" v-if="isEditable && data.transactionDetails.partyDetails.partyName"><v-icon>edit</v-icon> Party Details</div>
                                </div>

                                <div class="pt-1" v-if="isEditable">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17" nopadding v-model="shippingLabel.showInPrintOut" @change="fn_toggleField($event, shippingLabel.fieldId)"></v-switch>
                                </div>
                            </template>

                        </div>


                        <div full-height class="col33_3" v-if="otherFields.length">
                            <div v-for="(field, fieldIndex) in otherFields" :key="fieldIndex">
                                <template v-if="field.fieldValue">
                                    <div v-if="field.fieldType == 'print' && field.additionalData.content" v-html="field.additionalData.content"></div>
                                    <div v-if="field.fieldType != 'print'">{{field.name}}{{field.fieldValue ? ':' : ''}}
                                        <span v-if="field.fieldType == 'dateTime'">{{field.fieldValue | moment("DD-MMM-YYYY")}}</span>
                                        <span v-else>{{field.fieldValue}}</span>
                                    </div>  
                                </template>
                                
                            </div>
                        </div>

                    </td>
                </tr>

                <tr v-if="data.transactionDetails.expenseCategoryName">
                    <td class="noborder pl-2 pr-2" :colspan="headers.length">
                        <div><strong>Expense For</strong>: {{data.transactionDetails.expenseCategoryName}}</div>
                    </td>
                </tr>


            </tbody>
        </table> -->

        <!-- :style="{'maxWidth':fn_getDeviceWidth()}" style="overflow:hidden;table-layout:fixed;" -->
        <table class="invoice_table" id="body_template_1" full-width> 
            <!-- <thead class="noborder">

                <tr class="items_header noborder" :style="{'background':invoiceColor, 'color':invoiceColor=='#4b77be' ? '#ffffff' : '#000000'}">
                    <td class="noborder" v-for="(header, index) in headers" :key="index" :class="{'right-align':  header.name!='#' && header.name!='Description' && header.name!='HSN/SAC'}" :style="{'minWidth':header.name=='Description' ? '142px' : 'auto'}">
                        <strong>{{header.name}}</strong>
                    </td>
                </tr>

            </thead> -->
            
            <tbody full-width>

                <template>
                    <tr full-width>
                        <td :colspan="headers.length" left-align :style="{'background':invoiceColor}" class="noborder">
                            <div full-height class="invoice_title pt-1 pb-1 center-align font-heavy font20">
                                <template v-if="$root.CurrentBusiness.GSTRegistrationStatus == 1 && data.transactionDetails.transactionListId == 10003">
                                    {{summary.totalTax && !hasNilRatedItems ? data.transactionDetails.invoiceTitle : summary.totalTax && hasNilRatedItems ? 'Invoice-cum-Bill of Supply' : 'Bill Of Supply'}}
                                </template>
                                <template v-else>
                                    {{data.transactionDetails.invoiceTitle}}
                                </template>
                            </div>
                        </td>
                    </tr>

                    <tr right-align v-if="!data.transactionDetails.status">
                        <td class="noborder redcolor" colspan="3">Cancelled!</td>
                    </tr>

                    <tr center-align v-if="subtitle.data && subtitle.data.content">
                        <td class="noborder pl-2 pr-2" :colspan="headers.length">
                            <div v-html="subtitle.data.content" v-ripple :class="{'editable':isEditable}" @click="fn_editPrintField(subtitle.fieldId)"></div>
                        </td>
                    </tr>

                    <tr>
                        <td class="noborder border-bottom business_row" :colspan="headers.length" :class="{'pt-3':isEditable}">

                            <div class="col67" :class="{'hasLogo':!preview && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(logoField.showInPrintOut)}" style="white-space:nowrap">

                                <div class="business_logo" v-ripple v-if="data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(logoField.showInPrintOut)" :class="{'editable':isEditable, 'fade-opacity':!logoField.showInPrintOut}" @click="fn_editLogo">
                                    <img :src="$root.CurrentBusiness.businessLogo"/>
                                    <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Logo</div>
                                </div>

                                <div class="business_logo" :class="{'editable':isEditable}" @click="fn_editLogo" white-background v-if="(!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo) && isEditable">    
                                    <div style="text-align: center;padding: 18px 0px;font-size: 10px;">Logo</div>
                                    <div class="editabled-field-label" v-if="isEditable"><v-icon>add</v-icon> Logo</div>
                                </div>

                                <div style="clear:both;width:48px;float:left" class="pt-1" v-if="isEditable && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && logoField.fieldId">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="logoField.showInPrintOut" @change="fn_toggleField($event, logoField.fieldId)"></v-switch>
                                </div>

                                <div class="business_details" :class="{'nopadding': (!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo || !logoField.showInPrintOut) && !isEditable, 'pl-2':!preview && (data.templateSettings.businessLogo || $root.CurrentBusiness.businessLogo)}">
                                    <div v-html="header.data.content" v-ripple v-if="header.data && header.data.content" :class="{'editable':isEditable}" @click="fn_editBusiness"></div>
                                    <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Business Details</div>
                                </div>

                                <!-- <div style="clear:both;width:48px;" class="pt-1" v-if="isEditable && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && logoField.fieldId">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="logoField.showInPrintOut" @change="fn_toggleField($event, logoField.fieldId)"></v-switch>
                                </div> -->
                            </div>

                            <div class="col33" right-align style="white-space:nowrap">
                                <div style="padding: 1px 2px;border:1px solid;margin-bottom:2px" :style="{'borderColor':invoiceColor}" v-if="data.transactionDetails.transactionListId == 10003 && (($route.query.invoiceFor && $route.query.invoiceFor!='Standard') || (invoiceFor && invoiceFor!='Standard'))">
                                    {{$route.query.invoiceFor ? $route.query.invoiceFor : invoiceFor ? invoiceFor : ''}}
                                </div>
                                <div>Invoice No: {{data.transactionDetails.transactionNumber}}</div>
                                <div>Date of Invoice: {{data.transactionDetails.transactionDate | moment("DD-MMM-YYYY")}}</div>
                                <div>Place of Supply: {{fn_getPlaceOfSupply()}}</div>
                                <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.gstNumber">GSTIN: {{data.transactionDetails.partyDetails.gstNumber}}</div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td :colspan="headers.length" class="noborder" full-height nopadding>

                            <div full-height class="col33_3" :class="{'col50_2':!otherFields.length}">
                                
                                <template v-if="data.transactionDetails.partyDetails.partyName && fn_showSection(billingLabel.showInPrintOut)">

                                    <span :class="{'fade-opacity':!billingLabel.showInPrintOut}" font-heavy v-html="billingLabel.data.content" v-if="billingLabel.data && billingLabel.data.content"></span>

                                    <div v-ripple :class="{'editable':isEditable && data.transactionDetails.partyDetails.partyName, 'fade-opacity':!billingLabel.showInPrintOut}" @click="fn_editParty">

                                        <div v-if="data.transactionDetails.partyDetails.partyName">
                                            {{data.transactionDetails.partyDetails.partyName}}
                                            <br>
                                            {{billingAddress}}<br>
                                            {{partyPhoneBilling ? 'Ph: ' + partyPhoneBilling : ''}} 
                                        </div>

                                        <div class="editabled-field-label" v-if="isEditable && data.transactionDetails.partyDetails.partyName"><v-icon>edit</v-icon> Party Details</div>
                                        
                                    </div>

                                    <div class="pt-1" v-if="isEditable">
                                        <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="billingLabel.showInPrintOut" @change="fn_toggleField($event, billingLabel.fieldId)"></v-switch>
                                    </div>

                                </template>

                                <div v-if="data.transactionDetails.transactionListId == 20005 && !data.transactionDetails.partyDetails.partyName">
                                    Cash Expense
                                </div>

                            </div>

                            <div full-height class="col33_3" :class="{'col50_2 noborder':!otherFields.length}">

                                <template v-if="data.transactionDetails.partyDetails.partyName && fn_showSection(shippingLabel.showInPrintOut)">

                                    <span :class="{'fade-opacity':!shippingLabel.showInPrintOut}" font-heavy v-html="shippingLabel.data.content" v-if="shippingLabel.data && shippingLabel.data.content"></span>

                                    <div v-ripple :class="{'editable':isEditable, 'fade-opacity':!shippingLabel.showInPrintOut}" @click="fn_editParty('expense')">

                                        <div>
                                            {{data.transactionDetails.partyDetails.partyName}}
                                            <br>
                                            {{shippingAddress}}<br>
                                            {{partyPhoneShipping ? 'Ph: ' + partyPhoneShipping : ''}}
                                        </div>

                                        <div class="editabled-field-label" v-if="isEditable && data.transactionDetails.partyDetails.partyName"><v-icon>edit</v-icon> Party Details</div>
                                    </div>

                                    <div class="pt-1" v-if="isEditable">
                                        <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17" nopadding v-model="shippingLabel.showInPrintOut" @change="fn_toggleField($event, shippingLabel.fieldId)"></v-switch>
                                        <!-- <div>Show in Print/Pdf</div> -->
                                    </div>
                                </template>

                            </div>


                            <div full-height class="col33_3" v-if="otherFields.length">
                                <div v-for="(field, fieldIndex) in otherFields" :key="fieldIndex">
                                    <template v-if="field.fieldValue">
                                        <div v-if="field.fieldType == 'print' && field.additionalData.content" v-html="field.additionalData.content"></div>
                                        <div v-if="field.fieldType != 'print'">{{field.name}}{{field.fieldValue ? ':' : ''}}
                                            <span v-if="field.fieldType == 'dateTime'">{{field.fieldValue | moment("DD-MMM-YYYY")}}</span>
                                            <span v-else>{{field.fieldValue}}</span>
                                        </div>  
                                    </template>
                                    
                                </div>
                            </div>

                        </td>
                    </tr>

                    <tr v-if="data.transactionDetails.expenseCategoryName">
                        <td class="noborder pl-2 pr-2" :colspan="headers.length">
                            <div><strong>Expense For</strong>: {{data.transactionDetails.expenseCategoryName}}</div>
                        </td>
                    </tr>
                </template> 

                <tr class="items_header noborder" :style="{'background':invoiceColor, 'color':invoiceColor=='#4b77be' ? '#ffffff' : '#000000'}">
                    <td class="noborder" v-for="(header, index) in headers" :key="index" :class="{'right-align':  header.name!='#' && header.name!='Description' && header.name!='HSN/SAC'}" :style="{'minWidth':header.name=='Description' ? '142px' : 'auto'}">
                        <strong>{{header.name}}</strong>
                    </td>
                </tr>


                <template v-if="filteredItems && filteredItems.length">
                    <template v-for="(item, itemIndex) in filteredItems" class="noborder">
                        <tr v-if="isRef" class="noborder border-bottom" :key="itemIndex"><td class="noborder" :colspan="headers.length"><strong>{{item.baseTransactionNumber}}</strong></td></tr>

                        <tr v-for="(row, index) in item.items" :key="item.baseTransactionNumber + index" class="noborder border-bottom">
                            <td>{{index+1}}</td>
                            
                            <td>
                                {{row.itemName}}
                                <br v-if="row.description">
                                <span class="description_row" v-if="row.description" v-html="row.description"></span>
                            </td>

                            <td v-if="isHsn">{{row.hsn ? row.hsn : '-'}}</td>
                            <td right-align>{{row.qty}}</td>
                            <td right-align v-if="isMrp">{{row.mrp ? $root.fixed2Decimal(row.mrp) : ''}}</td>
                            <td right-align>{{row.rate ? $root.fixed2Decimal(row.rate) : ''}}</td>

                            <template v-for="(header, headerIndex) in headers">
                                <td right-align :key="headerIndex" v-if="header.addOnsId">
                                    <template v-for="(addOn, addOnIndex) in row.addOns">
                                        <span v-if="header.addOnsId == addOn.addOnsId" :key="addOnIndex">
                                            {{addOn.value ? $root.fixed2Decimal(addOn.value) : ''}}
                                            <span v-if="addOn.percentage && addOn.value"><br>{{'@' + addOn.percentage + '%'}}</span>
                                        </span>
                                        <span v-else :key="addOnIndex">-</span>    
                                    </template>
                                </td>
                            </template>
                            
                            <td right-align v-if="summary.totalTax">{{row.taxableValue ? $root.fixed2Decimal(row.taxableValue) : ''}}</td>
                            
                            <template v-for="(header, headerIndex) in headers">
                                <td right-align :key="headerIndex" v-if="header.gstAddOnsId">
                                    <template v-for="(addOn, addOnIndex) in row.gstAddOns">
                                        <span :key="addOnIndex" v-if="header.gstAddOnsId == addOn.addOnsId">
                                            {{addOn.value ? $root.fixed2Decimal(addOn.value) : ''}}
                                            <span v-if="addOn.percentage && addOn.value"><br>{{'@' + addOn.percentage + '%'}}</span>
                                        </span>    
                                    </template>
                                </td>    
                            </template>
                            
                            <td right-align>{{row.rate ? $root.fixed2Decimal(row.total) : 'Free'}}</td>
                        </tr>
                    </template> 
                </template>
                

                <!-- Body in case of direct amount invoice (no items) -->
                <template v-if="filteredItems && !filteredItems.length">
                    <tr class="noborder border-bottom">
                        <td>1</td>
                        <td><span class="description_row">{{data.transactionDetails.description}}</span></td>
                        <td class="right-align">{{$root.numberToLocale(data.transactionDetails.roundedAmount)}}</td>
                    </tr>
                </template>
                    
                <tr class="items_footer noborder border-bottom" v-if="filteredItems && filteredItems.length" :style="{'background':invoiceColor}">
                    <td :colspan="isHsn ? 3 : 2"><strong>Total (&#8377;)</strong></td>

                    <td right-align>
                        <strong>{{qtyTotal ? $root.numberToLocale(qtyTotal) : ''}}</strong>
                    </td>

                    <td :colspan="headers.length - addOnsTotal.length - gstAddOnsTotal.length - (isMrp ? 5 : 6) - (isHsn ? 1 : 0)"></td>

                    <template v-for="(header, headerIndex) in headers">
                        <td right-align :key="headerIndex" v-if="header.addOnsId">
                            <template v-for="(item, index) in addOnsTotal">
                                <span :key="index" v-if="header.addOnsId == item.addOnsId">
                                    <strong>{{$root.numberToLocale(item.value)}}</strong>
                                </span>    
                            </template>
                        </td>    
                    </template>
                    
                    <td right-align v-if="summary.totalTax"><strong>{{$root.numberToLocale(data.transactionDetails.taxableValue)}}</strong></td>
                    
                    <template v-for="(header, headerIndex) in headers">
                        <td right-align :key="headerIndex" v-if="header.gstAddOnsId">
                            <template v-for="(item, index) in gstAddOnsTotal">
                                <span :key="index" v-if="header.gstAddOnsId == item.gstAddOnsId">
                                    <strong>{{$root.numberToLocale(item.value)}}</strong>
                                </span>    
                            </template>
                        </td>    
                    </template>
                    

                    <td right-align><strong>{{$root.numberToLocale(netTotal)}}</strong></td>
                </tr>

                <!-- Footer in case of direct amount invoice (no items) -->
                <tr class="items_footer noborder border-bottom" v-else :style="{'background':invoiceColor}">
                    <td colspan="2"><strong>Total</strong></td>
                    <td class="right-align"><strong>{{$root.numberToLocale(data.transactionDetails.roundedAmount)}}</strong></td>
                </tr>

                <tr class="border-bottom" :class="{'border-top':!filteredItems.length}">
                    <td :colspan="headers.length" class="single-column-double-section noborder">
                        <div full-height class="left-column">
                            <div>
                                <strong>Total amount (in words)</strong><br>
                                {{convertNumberToWords(data.transactionDetails.roundedAmount)}} Only.
                            </div>

                            <div v-if="data.transactionDetails.notes" class="pt-2">
                                <strong>Note</strong><br>
                                {{data.transactionDetails.notes}}
                            </div>

                        </div>

                        <div full-height class="right-column" right-align>
                            <div class="table_div">
                                <div class="table_div_row">
                                    <div class="table_div_col">Taxable Amount (&#8377;):</div>
                                    <div class="table_div_col" right-align>
                                        <!-- <strong>{{$root.fixed2Decimal(data.transactionDetails.taxableValue)}}</strong> -->
                                        <strong>{{$root.numberToLocale(data.transactionDetails.taxableValue)}}</strong>
                                    </div>
                                </div>
                                <div class="table_div_row">
                                    <div class="table_div_col">Total Tax {{data.transactionDetails.isRcmApplicable ? '(RCM)' : ''}} (&#8377;):</div>
                                    <div class="table_div_col" right-align>
                                        <!-- <strong>{{$root.fixed2Decimal(summary.totalTax)}}</strong> -->
                                        <strong>{{$root.numberToLocale(summary.totalTax)}}</strong>
                                    </div>
                                </div>

                                <div class="table_div_row" v-if="data.transactionDetails.transactionTds && data.transactionDetails.transactionTds.length">
                                    <div class="table_div_col">
                                        {{data.transactionDetails.transactionTds[0].tdsAccountName}} (&#8377;):
                                    </div>
                                    <div class="table_div_col" right-align>
                                        <!-- <strong>(-) {{data.transactionDetails.transactionTds[0].amount}}</strong> -->
                                        <strong>-{{$root.numberToLocale(data.transactionDetails.transactionTds[0].amount)}}</strong>
                                    </div>
                                </div>

                                <div class="table_div_row" v-for="(addOn, addOnIndex) in data.transactionDetails.transactionAddOns" :key="addOnIndex">
                                    <div class="table_div_col">{{addOn.name}} {{addOn.isGst == 1 && data.transactionDetails.isRcmApplicable ? '(RCM)' : ''}} (&#8377;):</div>
                                    <div class="table_div_col" right-align>
                                        <!-- <strong>{{$root.fixed2Decimal(addOn.value)}}</strong> -->
                                        <strong>{{$root.numberToLocale(addOn.value)}}</strong>
                                    </div>
                                </div>
                                <div class="table_div_row" v-if="roundOffAmount">
                                    <div class="table_div_col">Round Off (&#8377;):</div>
                                    <div class="table_div_col" right-align>
                                        <!-- <strong>{{$root.fixed2Decimal(roundOffAmount)}}</strong> -->
                                        <strong>{{$root.numberToLocale(roundOffAmount)}}</strong>
                                    </div>
                                </div>
                                <div class="table_div_row">
                                    <div class="table_div_col"><strong>Total Amount (&#8377;):</strong></div>
                                    <div class="table_div_col" right-align>
                                        <!-- <strong>{{$root.fixed2Decimal(data.transactionDetails.roundedAmount)}}</strong> -->
                                        <strong>{{$root.numberToLocale(data.transactionDetails.roundedAmount)}}</strong>
                                    </div>
                                </div>

                                <template v-if="data.transactionDetails.paymentAccounts && data.transactionDetails.paymentAccounts.length">
                                    <!-- <div class="table_div_row">
                                        <div class="table_div_col light-opacity-color">Payment Modes:</div>
                                    </div> -->

                                    <!-- if single payment mode -->

                                    <div class="table_div_row" v-if="data.transactionDetails.paymentAccounts.length==1">
                                        <div class="table_div_col"></div>
                                        <div class="table_div_col" right-align>
                                            (Paid by {{data.transactionDetails.paymentAccounts[0].accountLedgerName == 'Cash A/c' ? 'Cash' : data.transactionDetails.paymentAccounts[0].accountLedgerName}})
                                        </div>
                                    </div>

                                    <!-- if multiple payment modes -->
                                    <div class="table_div_row" v-else v-for="(item, index) in data.transactionDetails.paymentAccounts" :key="index">
                                        <div class="table_div_col">Paid by {{item.accountLedgerName == 'Cash A/c' ? 'Cash' : item.accountLedgerName}} (&#8377;):</div>
                                        <div class="table_div_col" right-align>
                                            <strong>{{$root.numberToLocale(item.amount)}}</strong>
                                        </div>
                                    </div>

                                    <!-- <div class="table_div_row" v-if="data.transactionDetails.onCredit">
                                        <div class="table_div_col">Balance Amount (&#8377;):</div>
                                        <div class="table_div_col" right-align>
                                            <strong>{{$root.numberToLocale(data.transactionDetails.onCredit)}}</strong>
                                        </div>
                                    </div> -->
                                </template>

                                <div class="table_div_row">
                                    <div class="table_div_col">Balance Amount (&#8377;):</div>
                                    <div class="table_div_col" right-align>
                                        <strong>{{data.transactionDetails.onCredit ? $root.numberToLocale(data.transactionDetails.onCredit) : '0'}}</strong>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </td>
                </tr>

                <tr>
                    <td :colspan="headers.length" class="single-column-double-section noborder" :class="{'pt-3':isEditable}">

                        <div full-height class="left-column" :class="{'editable':isEditable}">

                            <template v-if="fn_showSection(terms.showInPrintOut) && terms.fieldId">

                                <div v-ripple full-height :class="{'fade-opacity':!terms.showInPrintOut}" @click="fn_editPrintField(terms.fieldId)">
                                    <div v-html="terms.data.content" v-if="terms.data && terms.data.content"></div>

                                    <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Terms</div>
                                </div>  

                                <div class="pt-3 pb-3" v-if="isEditable && terms.data && terms.data.content">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="terms.showInPrintOut" @change="fn_toggleField($event, terms.fieldId)"></v-switch>
                                    <!-- <div>Show in Print/Pdf</div> -->
                                </div>  

                            </template>

                        </div>


                        <div full-height nopadding class="right-column bottom-right-column" :class="{'editable':isEditable}">

                            <template v-if="fn_showSection(signatory.showInPrintOut)">

                                <div full-height :class="{'fade-opacity':!signatory.showInPrintOut}" @click="fn_editPrintField(signatory.fieldId, 'signature')">

                                    <div right-align class="signatory" v-ripple v-html="signatory.data.content" v-if="signatory.data && signatory.data.content"></div>

                                    <div class="editabled-field-label" v-if="isEditable"><v-icon>add</v-icon> Signature</div>

                                </div>

                                <div class="pt-3 pb-3" v-if="isEditable && signatory.data && signatory.data.content">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="signatory.showInPrintOut" @change="fn_toggleField($event, signatory.fieldId)"></v-switch>
                                    <!-- <div>Show in Print/Pdf</div> -->
                                </div>  

                            </template>

                            
                        </div>


                    </td>
                </tr>

                <tr v-if="(data.transactionDetails.transactionListId == 10003 && ($root.CurrentBusiness.UpiID || (bankDetails.data && bankDetails.data.content))) || data.transactionDetails.transactionListId == 10003 && isEditable">

                    <td :colspan="headers.length" class="single-column-double-section noborder" :class="{'pt-5':isEditable}">

                        <div full-height class="left-column" :class="{'editable':isEditable}">

                            <template v-if="fn_showSection(bankDetails.showInPrintOut)">

                                <div full-height v-ripple :class="{'fade-opacity':!bankDetails.showInPrintOut}" @click="fn_editPrintField(bankDetails.fieldId)">

                                    <template v-if="isEditable">
                                        <div v-if="bankDetails.data && bankDetails.data.content" class="editabled-field-label"><v-icon>edit</v-icon> Bank Details</div>
                                        <div v-else class="editabled-field-label"><v-icon>add</v-icon> Bank Details</div>   
                                    </template>
                                    
                                    
                                    <template v-if="bankDetails.data && bankDetails.data.content">
                                        <div v-html="bankDetails.data.content"></div>
                                    </template>

                                </div>

                                <div class="pt-3" v-if="isEditable && bankDetails.data && bankDetails.data.content">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="bankDetails.showInPrintOut" @change="fn_toggleField($event, bankDetails.fieldId)"></v-switch>
                                    <!-- <div>Show in Print/Pdf</div> -->
                                </div>

                            </template>

                        </div>

                        <div full-height class="right-column bottom-right-column align-right" :class="{'editable':isEditable}">

                            <template v-if="fn_showSection(upiDetailField.showInPrintOut)">
                                
                                <div full-height :class="{'fade-opacity':!upiDetailField.showInPrintOut}" nopadding @click="fn_editUpiDetails">

                                    <template v-if="$root.CurrentBusiness.UpiID">
                                        <div right-align style="float:left;width:calc(100% - 48px);padding-right:8px;">
                                            <p>PAYMENT QR CODE</p>
                                            <p light-opacity-color>PayTM/Google Pay/PhonePe</p>
                                            <p light-opacity-color>UPI ID: {{$root.CurrentBusiness.UpiID}}</p>
                                        </div>

                                        <img :src="paymentQrCodeUrl" style="width:48px;"/>
                                    </template>

                                    <template v-if="isEditable">
                                        <div class="editabled-field-label" v-if="$root.CurrentBusiness.UpiID"><v-icon>edit</v-icon> UPI Details</div>
                                        <div class="editabled-field-label" v-else><v-icon>add</v-icon> UPI Details</div>    
                                    </template>

                                </div>

                                <div class="pt-3 left-align" v-if="isEditable && $root.CurrentBusiness.UpiID && upiDetailField.fieldId">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="upiDetailField.showInPrintOut" @change="fn_toggleField($event, upiDetailField.fieldId)"></v-switch>
                                    <!-- <div>Show in Print/Pdf</div> -->
                                </div>

                            </template>

                        </div>
                    </td>
                </tr>

            </tbody>

            <tfoot v-if="!preview">
                <tr class="noborder">
                    <td right-align class="noborder" :colspan="headers.length">
                        <!-- <div class="page-footer-space"></div> -->
                        <div class="invoice-footer">
                            <div style="font-size:8px !important;line-height:10px">Powered By</div>
                            <div style="font-size:10px !important;line-height:10px" class="title primary-color">
                                <!-- <img style="width:10px" src="../../../assets/Final_BizOpsLogo.svg" class="brand_logo logo_small" alt="bizops_logo"> -->
                                <img style="width:10px" :src="bizOpsLogo" class="brand_logo logo_small" alt="bizops_logo">
                                BizOps App
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>

        </table>

        <!-- <div class="page-footer mt-4" id="page_footer_template_1">
            <div class="branding watermark">
                <div style="font-size:8px !important">Powered By</div>
                <div style="font-size:10px !important" class="title primary-color">
                    <img src="../../../assets/Final_BizOpsLogo.svg" class="brand_logo logo_small" alt="bizops_logo">
                    BizOps App
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import {invoiceTemplateMixin} from '../invoice_template_mixin'

    export default{
        mixins: [invoiceTemplateMixin]
    }
</script>
<style>
    /* ---------------- Primary Header Css -----------------*/

    .invoice_table{
        /* font-family: "Roboto", sans-serif !important; */
        font-size:12px
    }

    .invoice_table .pt-1{
        padding-top:4px;
    }
    .invoice_table .pt-2{
        padding-top:8px;
    }
    .invoice_table .pt-3{
        padding-top:12px;
    }
    .invoice_table .pb-1{
        padding-bottom:4px;
    }
    .invoice_table .pb-2{
        padding-bottom:8px;
    }
    .invoice_table .pb-3{
        padding-bottom:12px;
    }

    /*.template_1 .business_logo{
        display: inline-block;
        width: 72px;
        position: relative;
        height: 72px;
        float: left;
    }

    .template_1 .business_logo img{
        width: 100%;
    }*/

    /* .template_1 .business_logo{
        float: left;    
    }

    .template_1.preview .business_details{
        padding-left: 56px;
        position:relative;
    }

    .template_1.print-settings .business_details{
        margin-left: 56px;
        padding:0 !important;
    }

    .template_1 .hasLogo .business_details{
        margin-left: 80px;
    }

    .template_1.noborder, .template_1.noborder table, .template_1.noborder table tr, .template_1.noborder tr td, .template_1.noborder table tr td div, .template_1.noborder div{
        border:0 !important;
    } */
</style>