<template>
    <div>
        <div id="non-printable">
            <v-toolbar :height="56" flat extended>
                <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

                <v-toolbar-title>{{toolbarTitle}}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

                <v-btn icon @click="fn_getReportData" v-if="listItemObject.totalCount && !loading"><v-icon>vertical_align_bottom</v-icon></v-btn>

                <v-progress-circular indeterminate color="primary" v-if="loading" :width="2" :size="28" class="mr-2"></v-progress-circular>

                <!-- <v-btn icon @click="fn_getReportData" v-if="listItemObject.totalCount"><v-icon>vertical_align_bottom</v-icon></v-btn> -->
                <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

                <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
            </v-toolbar>

            <v-divider></v-divider>

            <div class="data-container grey-back hasExtention" v-scroll.self="onScroll">

                <!-- <div class="filter-display" style="background:#F0F0F0;height:58px;border-top:1px #ddd solid;border-bottom:1px #ddd solid;" v-if="listItemObject.totalCount && (settings.transactionTypeId == 30001 || settings.transactionTypeId == 30002)"> -->
                <div class="filter-display grey-back" v-if="listItemObject.totalCount">
                    <v-layout ma-0 row wrap>
                        <v-flex xs6 sm6 md6>
                            <div class="display-block white-back">
                                <span font11 light-opacity-color>Count</span><br>
                                <span font16 class="filter-display-value">{{$root.numberToLocale(listItemObject.totalCount, 1)}}</span>
                            </div>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <div class="display-block white-back">
                                <span font11 light-opacity-color>Total Amount</span><br>
                                <span font16 class="filter-display-value">&#8377; {{$root.numberToLocale(listItemObject.totalAmount)}}</span>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>


                <v-list two-line nopadding grey-back>

                    <v-subheader white-back subheader-dense dark-opacity-color font-normal>
                        <!-- Period:&nbsp;<span @click="fn_openDatePicker('start', starDate)">{{selectionItems.startDate | moment("DD-MMM-YYYY")}}</span>&nbsp;To&nbsp;<span @click="fn_openDatePicker('endDate', endDate)">{{selectionItems.endDate | moment("DD-MMM-YYYY")}}</span> -->
                        <span @click="fn_openDialogCustomDates" v-if="selectionItems.selectedPeriod != 'All Time'">
                            Period: {{selectionItems.startDate | moment("DD-MMM-YYYY")}} To {{selectionItems.endDate | moment("DD-MMM-YYYY")}}
                        </span>
                        <v-spacer></v-spacer>
                       <!--  <v-menu>
                            <v-btn justify-content-to-end style="height: 16px;" nomargin nopadding text capitalize font-normal slot="activator">
                                {{selectionItems.selectedPeriod}}&nbsp;<v-icon font20>date_range</v-icon></v-btn>
                            <v-list nopadding>
                              <v-list-item v-for="(item, index) in periods" :key="index" @click="fn_selectPeriod(item.value)">
                                  <v-list-item-title>{{item.title}}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                        </v-menu> -->

                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="pa-0" capitalize text v-bind="attrs" v-on="on">
                                {{selectionItems.selectedPeriod}}&nbsp;<v-icon>date_range</v-icon>
                              </v-btn>
                            </template>
                            <v-list nopadding>
                              <v-list-item v-for="(item, index) in periods" :key="index" @click="fn_selectPeriod(item.value)">
                                  <v-list-item-title>{{item.title}}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                        </v-menu>
                        <!-- <v-icon font20 @click="fn_getReportData" v-if="listItemObject.totalCount">vertical_align_bottom</v-icon> -->
                    </v-subheader>

                    <v-divider></v-divider>

                    <div center-align class="pa-6" v-if="(listItemObject.totalCount && (!listItemObject.items || !listItemObject.items.length)) || loadTimeOut">
                        <p font12 light-opacity-color>Unable to load the items!</p>
                        <v-btn capitalize text color="primary" @click="fn_searchItem">
                            <v-icon left>refresh</v-icon>
                            Retry
                        </v-btn>
                    </div>

                    <div white-back v-for="(item, index) in listItemObject.items" :key="index" :class="{'mt-2':index!=0}" :id="'item_'+item.transactionId">
                        <v-list-item class="right-subtitle-tile" @click="fn_itemClick(item.transactionId, index)">
                            <v-list-item-content>
                              <v-list-item-title class="mb-1">{{toolbarTitle == 'Journal' ? 'Journal' : item.accountLedgerName}}</v-list-item-title>
                              <v-list-item-subtitle class="mb-1">
                                <span class="primary-color">{{item.mergeTransactionNumber}}</span>
                                &nbsp;| {{item.transactionDate | moment("DD-MMM-YYYY")}}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action align-flex-end>
                                
                                <div class="warning-label" v-if="!item.status">Cancelled</div>
                                <!-- <div v-else></div> -->

                                <div>&#8377; {{$root.numberToLocale(item.roundedAmount)}}</div>
                                
                            </v-list-item-action>
                        </v-list-item>

                        <!-- <v-divider v-if="listItemObject.items.length-1 != index"></v-divider> -->
                        <v-divider v-if="item.invoiceTemplate"></v-divider>
                        <div right-align style="padding:2px 6px;position:relative" v-if="item.invoiceTemplate">
                            <!-- <div class="warning-label" v-if="!item.status">Cancelled</div> -->

                            <v-btn nomargin icon text @click="fn_preview(item, true)"><v-icon font20>print</v-icon></v-btn>
                            <v-btn nomargin icon text @click="fn_preview(item)">
                                <v-icon>preview</v-icon>
                            </v-btn>
                        </div>
                    </div>


                </v-list>

                <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
                  <v-col cols="6">
                    <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
                  </v-col>
                </v-row>

                <!-- <div class="display_msg" v-if="!listItemObject.items.length">There are no items</div> -->
            </div>
        </div>

        <!-- <div id="printable" full-width>
          <component :is="printTemplate" ref="ref_printTemplate" :data="printData" v-if="printTemplate && printData!=null"></component>
        </div>

        <dialog-drive-alert ref="ref_dialogDriveAlert"></dialog-drive-alert>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <dialog-month-filter ref="ref_dialogMonthFilter" :selectPeriod="fn_selectPeriod"></dialog-month-filter>

        <dialog-quarter-filter ref="ref_dialogQuarterFilter" :selectPeriod="fn_selectPeriod"></dialog-quarter-filter> -->

        <dialog-month-filter ref="ref_dialogMonthFilter" :selectPeriod="fn_selectPeriod"></dialog-month-filter>
        <dialog-quarter-filter ref="ref_dialogQuarterFilter" :selectPeriod="fn_selectPeriod"></dialog-quarter-filter>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <v-snackbar v-model="snackbar">
          File downloaded!
          <v-btn text color="primary" @click.native="$root.fn_openDownloadedFile">Open</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../../config.js').configs.bottomSheetMenu;
const transactionForms = require('../../../../config.js').configs.transactionForms;

// const dialogjs =  require('../../../masters/forms/dialog').dialogjs;

// import dialogDriveAlert from '../dialogs/dialog_drive_alert'

// import dialogPeriodSelection from '../../../dialogs/dialog_period_selection'
// import dialogDatePicker from './dialogs/dialog_accountbook_datepicker';

import dialogMonthFilter from '../../../dialogs/dialog_filter_months'
import dialogQuarterFilter from '../../../dialogs/dialog_filter_quarter'
import dialogPeriodSelection from '../../../dialogs/dialog_period_selection'
import dialogDatePicker from '../../../dialogs/dialog_datepicker';

import {exportExcelMixin} from '../../export_excel_mixin'
import {transactionMixin} from '../../transaction_mixin'

// import InfiniteLoading from 'vue-infinite-loading';

    export default{
        // props:['settings'],
        mixins: [exportExcelMixin, transactionMixin],
        // mixins: [transactionMixin],
        components: {
            // InfiniteLoading,
            // 'dialog-drive-alert':dialogDriveAlert,
            // 'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker': dialogDatePicker,
            'dialog-month-filter': dialogMonthFilter,
            'dialog-quarter-filter': dialogQuarterFilter,
            'dialog-period-selection':dialogPeriodSelection,
        },
        data(){
            return{
                toolbarTitle:null,
                listItemObject:{"items":[], "totalCount":0, "totalAmount":null},
                searchValue:'',
                infiniteId: +new Date(),
                startIndex:0,
                scrollPositionTop:0,
                selectedItemId:null,
                loadMore:false,
                selectionItems:{
                    startDate:this.$root.minDateSelection,
                    endDate:this.$root.maxDateSelection,
                    selectedPeriod:"All Time"
                },
                dateType:null,
                selectedDate:null,
                snackbar:false,
                printData:null,
                printTemplate:null,
                showPlaceHolder:false,
                backButtonPress:false,
                formId:null,
                dialogStorageId:null,
                transactionTypeId:null
            }
        },
        methods:{

            fn_preview: function(item, isPrint){
                item.toolbarTitle = this.toolbarTitle;
                let printType = 'ab';
                this.fn_previewInvoice(true, null, printType, item, isPrint);
            },

            fn_bindInvoiceTemplate: function(type, item){

                this.printTemplate = null;
                this.printData = null;

                this.printTemplate = require('../../../invoice_templates/invoices/template'+item.invoiceTemplate).default;
                console.log(this.printTemplate);

                let dataFnName = 'AccountBookPrintData';

                if(type=='sharewhatsapp'){
                  this.fn_shareInvoice('sharewhatsapp', item, dataFnName);
                }
                else if(type=='print'){
                  this.fn_getPrintData(dataFnName, item.transactionId, 'print', null, item.mergeTransactionNumber, item.transactionDate, item.transactionListId);
                }
                else if(type=='pdf'){
                  this.fn_getPrintData(dataFnName, item.transactionId, 'pdf', null, item.mergeTransactionNumber, item.transactionDate, item.transactionListId);
                }
                else if(type=='share'){
                  this.fn_shareInvoice('share', item, dataFnName);
                }
            },

            fn_shareInvoice: function(type, item, dataFnName){
                let shareObj = {};
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = item.primaryMobileNumber && type == 'sharewhatsapp' ? item.primaryMobileNumber : null;
                shareObj.subject = this.toolbarTitle + " Details";
                shareObj.email = item.email ? [item.email] : null ;

                let party = item.accountLedgerName ? item.accountLedgerName + ',' : '';

                shareObj.textMessage = "Dear "+party+"\n\nBelow are your " + this.toolbarTitle + " details. Please find the attached pdf.\n\nTransaction Date: "+this.$moment(item.transactionDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(item.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;

                // shareObj.textMessage = "Dear "+item.accountLedgerName ? item.accountLedgerName : ''+",\n\nBelow are your "+this.toolbarTitle.toLowerCase()+" details. Please find the attached pdf of your "+this.toolbarTitle.toLowerCase()+".\n\n"+this.toolbarTitle+" Date: "+this.$moment(item.createdDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(item.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.fn_getPrintData(dataFnName, item.transactionId, 'pdf', shareObj, item.mergeTransactionNumber, null, item.transactionListId);
            },

            onScroll (event) {
                var element = event.target;
                if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.listItemObject.items.length < this.listItemObject.totalCount){
                        this.fn_loadItems();
                    }
                }
             },

            fn_drafts: function(){
                this.$router.push({path:'/drafts', query:{tabIndex:1}});
            },
            fn_initializeSettings: function () {
                this.$route.query.transactionTypeId ? this.transactionTypeId = this.$route.query.transactionTypeId : '';

                transactionForms.filter(item => {
                    if(item.id == this.transactionTypeId){
                        this.toolbarTitle = item.title;
                    }
                })
            },
            fn_goBack: function(){
                this.backButtonPress = true;
                this.$router.go(-1);
                // this.$router.replace("/"+this.$root.CurrentBusiness.BusinessID+"/transaction_items");
            },
            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='new'){
            //      // this.fn_new();
            //  }
            //  else if(menuAction=='edit'){
            //      this.$router.push('/transactions/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+this.selectedItemId+'/edit');
            //  }
            //  else if(menuAction=='draft'){
            //      // this.$router.push({path:'/drafts', query:{category:this.toolbarTitle}});
            //  }
            // },

            // fn_closeDialog: function(){
            //  this.$refs['ref_dialogDatePicker'].accountbook_datepicker = false;
            //  this.$refs['ref_dialogQuarterFilter'].dialog_filter_quarter = false;
   //           this.$refs['ref_dialogMonthFilter'].dialog_filter_month = false;
            // },

            fn_new: function () {
                // let obj = {};
                // obj.itemId = null;
                // obj.lastScrollPos = window.pageYOffset;
                // obj.itemIndex = this.listItemObject.items.length;
                // localStorage.setItem("transaction_items_state", JSON.stringify(obj));

                // localStorage.setItem("SetDataDrive", 1);
                // this.$router.push({path:'/transactions/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/new', query:{tagId:this.guidWeb()}});
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/new', query:{tagId:this.guidWeb()}});
            },

            fn_itemClick: function (itemId, index) {

                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("transaction_items_state", JSON.stringify(obj));
                // tagId id form future use
                // itemName id form future use
                // this.$router.push('/transactions/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+itemId);
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+itemId);
            },
            // infiniteHandler($state) {
         //     let filterObject = {};
            //  filterObject.type = this.settings.transactionTypeId;
            //  filterObject.limit = 10;
            //  filterObject.startIndex = this.startIndex;
            //  filterObject.search = this.searchValue.toLowerCase();

            //  let self = this;
            //  alert('out');
            //  this.getItems("AccountBookList", filterObject, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //              let responseJson = JSON.parse(response);
            //              self.listItemObject.totalCount = responseJson.totalCount;

            //              for(let i in responseJson.items){
            //                  self.listItemObject.items.push(responseJson.items[i]);  
            //              }

            //              self.startIndex = self.listItemObject.items.length;

            //              if(self.listItemObject.items.length < self.listItemObject.totalCount){
            //                  // self.listItemObject.items = self.listItemObject.items.concat(responseJson.items);
            //                  // self.startIndex = self.listItemObject.items.length;
            //                  $state.loaded();
            //                  alert('loaded');
            //              }
            //              else {
   //                               $state.complete();
   //                               alert('complete');
   //                       }
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
         //    },

            // fn_searchItem: function () {
            //  this.startIndex = 0;
            //  this.infiniteId += 1;
            //  this.listItemObject.items = [];
            // },

            fn_loadItems: function(search) {
                this.loadMore = true;
                let filterObject = {};
                filterObject.type = this.transactionTypeId;
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';
                // filterObject.startDate = new Date(this.selectionItems.startDate);
                filterObject.startDate = this.$moment(this.selectionItems.startDate).startOf('day');
                filterObject.endDate = this.$moment(this.selectionItems.endDate).endOf('day');

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);

                let pageState = null;
                if(localStorage.getItem("transaction_items_state")){
                    pageState = JSON.parse(localStorage.getItem("transaction_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }
                
                this.fn_loadTimeOut();

                let self = this;
                this.getItems("AccountBookList", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;
                            self.listItemObject.totalAmount = responseJson.totalAmount;

                            search ? self.listItemObject.items = [] : '';

                            let itemFound = false;
                            for(let i in responseJson.items){
                                if(pageState && pageState.itemId){
                                    if(responseJson.items[i].transactionId == pageState.itemId){
                                        itemFound = true;
                                    }
                                    // else{
                                    //  itemFound = false;
                                    // }
                                }
                                // else{
                                //  itemFound = true;
                                // }
                                let itemIndex = self.listItemObject.items.findIndex(item => item.transactionId==responseJson.items[i].transactionId);

                                if(itemIndex==-1){
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }
                                
                                // self.listItemObject.items.push(responseJson.items[i]);   
                            }

                            console.log(pageState);
                            if(pageState && pageState.itemId){
                                setTimeout(function(){
                                    if(itemFound){
                                        let scrollOptions = {"duration":0, "offset":0, "easing":"linear"};
                                        self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                                        localStorage.removeItem("transaction_items_state");
                                    }
                                    else{
                                        self.$vuetify.goTo(99999, self.scrollOptions);
                                    }
                                },100);
                            }

                            if(pageState && !pageState.itemId){
                                setTimeout(function(){
                                    let scrollOptions = {"duration":300, "offset":0, "easing":"linear"};
                                    self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                                    localStorage.removeItem("transaction_items_state");
                                },100);
                            }

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300);
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                            self.loadTimeOut = false;
                        }
                    }
                )
            },

            fn_searchItem: function () {
                this.startIndex = 0;
                this.listItemObject.items = [];
                this.fn_loadItems(true);
            },

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  let self = this;

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_closeDialog: function (tagId) {
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            // fn_showWebView: function () {
            //  let viewObject = {};
            //  let self = this;

            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null, 
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },
        },
        mounted: function(){
            // tagId = this.$route.query.tagId;

            // this.formId = this.$route.query.tagId;
            // this.dialogStorageId = "dialog_"+this.formId;

            this.fn_initializeSettings();

            // this.fn_showWebView();
            // window.js_globals.fn_onBackPress = this.fn_goBack;

            let self = this;
            document.addEventListener('scroll', function () {
                self.scrollPositionTop = window.pageYOffset
            }); 

            // if(localStorage.getItem('transaction_filter')){
            //     let data = JSON.parse(localStorage.getItem('transaction_filter'));
            //     this.selectionItems.startDate = new Date(data.startDate);
            //     this.selectionItems.endDate = new Date(data.endDate);
            //     this.selectionItems.selectedPeriod = data.selectedPeriod;
            // }

            this.fn_loadItems();

            this.$eventHub.$on('UpdateAccountBookList', dataObj => {
                console.log(dataObj);
                this.transactionTypeId = dataObj.transactionTypeId;
                this.fn_searchItem();
            });

            // this.myworker.addEventListener('message', event => {
      //           self.fn_breakDataIntoChunks(event);
      //       });
        },
        beforeRouteEnter(to, from, next){
            next(vm => {
                console.log(vm);
                if(from.name == 'actions'){
                    localStorage.removeItem("transaction_items_state");
                }   
            });
        },
        // beforeRouteLeave(to, from, next){
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  if(dialogData && dialogData.length){

        //      if(this.backButtonPress){
        //          dialogjs.fn_checkAndCloseOpenDialogs(this);
        //          next(false);
        //      }
        //      else{
        //          next(true);
        //      }
        //  }
        //  else{
        //      this.backButtonPress ? localStorage.removeItem('transaction_filter') : '';
        //      next(true);
        //  }
        // }
    }
</script>