<template>
    <div>
        <v-dialog v-model="dialog_settings" no-click-animation persistent content-class="full-height white-back" max-width="460px">

            <v-card full-height>
                <v-toolbar flat :height="56">
                    <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>Item Settings</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider> 

                <v-card-text nopadding>
                    <div class="switches" white-background>
                        <!-- <v-switch label="Enable Stock Transfer" v-model="TransactionSettings.branchDivision" nomargin></v-switch> -->
                        <!-- <v-switch label="Enable Beat/Area/Route" v-model="TransactionSettings.beatRouteArea" nomargin></v-switch> -->
                        <v-switch class="mt-1" label="Enable MRP" @change="fn_post" v-model="TransactionSettings.mrpWiseStock" nomargin></v-switch>
                        <!-- <v-switch label="Disable negative stock" v-model="TransactionSettings.disableNegativeStock" nomargin></v-switch> -->

                        <!-- <v-subheader>Items</v-subheader>
                        <v-switch label="Show Description" nomargin></v-switch>
                        <v-switch label="Show Brand" nomargin></v-switch>
                        <v-switch label="Show Company" nomargin></v-switch>
                        <v-switch label="Display Item Note" nomargin></v-switch>
                        <v-switch label="Show Category" nomargin></v-switch>

                        <v-subheader>Other Fields</v-subheader>
                        <v-switch label="Transportation Details" nomargin></v-switch>
                        <v-switch label="Other Details" nomargin></v-switch>
                        <v-switch label="E-Way Bill Details" nomargin></v-switch>

                        <v-subheader></v-subheader>
                        <v-switch label="PO No." nomargin></v-switch>
                        <v-switch label="PO Date" nomargin></v-switch>
                        <v-switch label="Transportation Mode" nomargin></v-switch> 
                        <v-switch label="Transporter" nomargin></v-switch>
                        <v-switch label="LR/CR/RR No." nomargin></v-switch>
                        <v-switch label="Vehicle No." nomargin></v-switch>
                        <v-switch label="E-Way Bill No" nomargin></v-switch>
                        <v-switch label="E-Way Bill Date" nomargin></v-switch>
                        <v-switch label="Distance" nomargin></v-switch>
                        <v-switch label="Sub Type" nomargin></v-switch>
                        <v-switch label="Document Type" nomargin></v-switch>
                        <v-switch label="Status of E-Way Bill" nomargin></v-switch>-->
                    </div>
                </v-card-text>

                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.native="closeDialogWeb" :disabled="loading">close</v-btn>
                    <v-btn color="blue darken-1" text :key="saveBtnKey" @click.once="fn_checkValidations" :disabled="loading" v-if="!isDisplay">ok</v-btn>
                </v-card-actions> -->
            </v-card>   
        </v-dialog>
    </div>
</template>     

<script>

const accountGroupIds = require('../../../config.js').configs.accountGroupIds;

export default{
    props:['closeDialog'],
    data(){ 
        return{
            dialog_settings:false,
            TransactionSettings:{"businessId":null, "branchDivision":false, "beatRouteArea":false, "mrpWiseStock":false, "disableNegativeStock":false, "gstType":1},
        }
    },
    methods:{
        openDialogWeb: function () {
            this.$root.fn_getTransactionSettings(()=>{
                this.TransactionSettings = JSON.parse(JSON.stringify(this.$root.TransactionSettings));
                this.fn_checkTaxMode();
            });
            this.TransactionSettings.mrpWiseStock = this.$root.TransactionSettings.mrpWiseStock;
            this.dialog_settings = true;
        },

        closeDialogWeb: function () {
            this.dialog_settings = false;
        },

        fn_checkTaxMode: function(){
            let self = this;
            let filterObject = {};
            filterObject.transactionTypeId = [10001, 10002, 10003, 10004, 100005];
            filterObject.taxMode = this.TransactionSettings.gstType;
            this.getFilterData('CheckTaxMode', null, filterObject, null,
                {
                    onSuccess: function(response){
                        console.log(response);
                        let isSimilar = JSON.parse(response);
                        !isSimilar ? self.TransactionSettings.gstType = -1 : '';
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_post: function(){
            let self = this;
            let postObj = JSON.parse(JSON.stringify(this.$root.TransactionSettings))
            //for gstType/taxmode update
            postObj.transactionTypeId = [10001, 10002, 10003, 10004, 100005];
            postObj.taxMode = this.$root.TransactionSettings.gstType;
            //for branch/division status update
            postObj.accountGroupId = accountGroupIds.brandAndDivision.id;
            postObj.status = this.$root.TransactionSettings.branchDivision ? 1 : 2;
            postObj.mrpWiseStock = this.TransactionSettings.mrpWiseStock;

            this.postData('TransactionSettings', postObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$root.fn_getTransactionSettings(()=>{
                            self.$eventHub.$emit('snackbarAlert', {msg:'Item settings updated'});
                        });
                        // self.$root.fn_getTransactionSettings();
                        // setTimeout(function(){
                        // 	self.showToast('Transaction Settings updated');
                        // 	self.$router.go(-1);
                        // },300);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        }
    }
}
</script>