<template>
    <div>

        <v-toolbar :height="56" flat extended>
            <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

            <v-toolbar-title>Orders</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

            <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
        </v-toolbar>

        <!-- <div class="display_msg" v-if="!listItemObject.items.length">No Items</div> -->

        <div class="data-container grey-back hasExtention">

            <!-- <div full-width class="search-filter">
                <v-text-field elevation-10 flat solo clearable nomargin placeholder="Search..." v-model.lazy="searchValue" @input="fn_searchItem" hide-details class="search-box"></v-text-field>
                <div v-ripple class="filter-btn" @click="add">
                    <v-icon>add</v-icon>
                </div>
            </div> -->

            <!-- <v-text-field elevation-10 flat solo clearable nomargin placeholder="Search..." v-model="searchValue" @input="fn_searchItem" hide-details class="search-box"></v-text-field> -->

            <div class="filter-display grey-back">
                <v-layout ma-0 row wrap>
                    <v-flex xs6 sm6 md6>
                        <div class="display-block white-back">
                            <span font11 light-opacity-color>Pending Orders</span><br>
                            <span font16 class="filter-display-value">{{$root.numberToLocale(roundUpto2Decimals(listItemObject.totalCount), 1)}}</span>
                        </div>
                    </v-flex>
                    <v-flex xs6 sm6 md6>
                        <div class="display-block white-back">
                            <span font11 light-opacity-color>Amount</span><br>
                            <span font16 class="filter-display-value">&#8377; {{$root.numberToLocale(roundUpto2Decimals(listItemObject.orderTotal))}}</span>
                        </div>
                    </v-flex>
                </v-layout>
            </div>

            <!-- <div style="clear:both" class="padding-all-16 light-opacity-color center-align" v-if="!listItemObject.totalCount">There are no orders!</div> -->

            <div>

                <v-list grey-back three-line nopadding v-if="listItemObject.totalCount">
                    <div white-back v-for="(item, index) in listItemObject.items" :key="index">
                        <v-list-item class="right-subtitle-tile" :class="{'mt-2':index!=0}" @click="fn_itemClick(item, index)">
                            <v-list-item-content>
                              <v-list-item-title class="mb-1">{{item.accountLedgerName}}</v-list-item-title>
                              <v-list-item-subtitle class="mb-1">
                                <span class="primary-color">{{item.mergeTransactionNumber}}</span> | {{item.transactionDate | moment("DD-MMM-YYYY")}}
                                <!-- {{item.dqty < item.qty ? ' | ' : ''}} -->
                                <!-- {{item.orderedAmount > 0 ? ' | ' : ''}} -->
                                <!-- <span primary-color v-if="item.dqty < item.qty">Partially Clear</span> -->
                                <!-- <span style="color:orange" v-if="item.orderedAmount > 0">Partially Clear</span> -->
                              </v-list-item-subtitle>
                              <template v-if="item.usedItemsQty > 0 && item.itemsQty > item.usedItemsQty">
                                <v-list-item-subtitle class="redcolor">Partially Clear</v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    Remaining Value: &#8377; {{$root.numberToLocale(roundUpto2Decimals(item.roundedAmount - item.orderedAmount))}}
                                </v-list-item-subtitle>
                              </template>
                              
                            </v-list-item-content>
                            
                            <v-list-item-action align-flex-end>

                                <!-- <v-btn icon flat @click="fn_getBottomMenu(902, item.transactionId, item.partyId, item.accountLedgerName)" v-if="item.status">
                                    <v-icon>more_horiz</v-icon>
                                </v-btn> -->
                                
                                <div class="warning-label" v-if="!item.status">Cancelled</div>

                                <div>&#8377; {{$root.numberToLocale(item.roundedAmount)}}</div>
                            </v-list-item-action>
                        </v-list-item>

                        <v-divider></v-divider>
                        <div right-align style="padding:2px 6px;position:relative">
                            <!-- <div class="warning-label" v-if="!item.status">Cancelled</div> -->
                            
                            <v-btn nomargin icon @click="fn_preview(item, true)"><v-icon font20>print</v-icon></v-btn>
                            <v-btn nomargin icon @click="fn_preview(item)">
                                <!-- <img src="../../../assets/icon_preview_black.svg" style="width:20px" alt="whatapp_icon"></img> -->
                                <v-icon>preview</v-icon>
                            </v-btn>
                            <v-btn nomargin icon @click="fn_createInvoice(item)"><v-icon>post_add</v-icon></v-btn>
                            <!-- <v-btn nomargin icon flat @click="fn_getBottomMenu(902, item.transactionId, item.partyId, item.accountLedgerName)" v-if="item.status"><v-icon font20>more_vert</v-icon></v-btn> -->
                        </div>

                        <!-- <v-divider v-if="listItemObject.items.length-1 != index"></v-divider> -->
                    </div>

                    <!-- <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading> -->


                </v-list>

                <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
                  <v-col cols="6">
                    <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
                  </v-col>
                </v-row>

                <div center-align class="pa-6" v-if="loadTimeOut">
                    <p font12 light-opacity-color>Unable to load the items!</p>
                    <v-btn capitalize text color="primary" @click="fn_searchItem">
                        <v-icon left>refresh</v-icon>
                        Retry
                    </v-btn>
                </div>
                
                <!-- <transition name="fade" mode="out-in">

                    <v-card noshadow class="create-placeholder-card" v-if="showPlaceHolder && !listItemObject.totalCount">
                        <v-card-text @click="add">

                            <div center-align>
                                <div v-ripple class="placeholder-icon">
                                    <i aria-hidden="true" class="v-icon material-icons add-icon">add</i>
                                    <img src="../../../assets/icon_order.svg" alt="">
                                </div><br>

                                <p class="title light-opacity-color">Create New Order</p>
                            </div>
                        </v-card-text>
                    </v-card>
                </transition> -->
            </div>

            <!-- <div padding-all-16 center-align>
                <v-progress-circular indeterminate color="primary" v-if="loadMore"></v-progress-circular>
            </div> -->

        </div>
    </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../config.js').configs.bottomSheetMenu;
// const transactionForms = require('../../../config.js').configs.transactionForms;

// import InfiniteLoading from 'vue-infinite-loading';

import {transactionMixin} from '../transactions/transaction_mixin'

    export default{
        // props:['settings', 'preview', 'bindInvoiceTemplate', 'add'],
        mixins: [transactionMixin],
        components: {
            // InfiniteLoading,
        },
        data(){
            return{
                toolbarTitle:null,
                listItemObject:{"items":[], "totalCount":0, "orderTotal":0},
                searchValue:'',
                infiniteId: +new Date(),
                startIndex:0,
                scrollPositionTop:0,
                selectedItemId:null,
                selectedPartyId:null,
                selectedPartyName:null,
                loadMore:false,
                loadTimeOut:false,
                showPlaceHolder:false
            }
        },
        methods:{

            fn_preview: function(item, isPrint){
                item.toolbarTitle = 'Orders';
                this.fn_previewInvoice(true, 'sale', null, item, isPrint);
            },

            fn_createInvoice: function(item){
                this.$router.push({
                    path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/saleinvoice/items/new', 
                    query:{
                        tagId:this.guidWeb(), 
                        loadItems:{
                            transactionId:item.transactionId,
                            type:'orders',
                            partyId:item.partyId,
                            partyName:item.accountLedgerName,
                        }
                    }
                });
            },

            fn_new: function () {
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/order/items/new', query:{tagId:this.guidWeb()}});
            },
            // fn_drafts: function(){
            //  this.$router.push({path:'/drafts', query:{tabIndex:1}});
            // },

            onScroll: function(event){
                var element = event.target;
                if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.listItemObject.items.length < this.listItemObject.totalCount){
                        this.fn_loadItems();
                    }
                }
            },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='edit'){
            //      this.$router.push('/transactions/sales/saleinvoice/items/'+this.selectedItemId+'/edit');
            //  }
            //  else if(menuAction=='createinvoice'){
            //      this.$router.push({
            //          path:'/transactions/sales/saleinvoice/items/new', 
            //          query:{
            //              tagId:this.guidWeb(), 
            //              loadItems:{
            //                  transactionId:this.selectedItemId,
            //                  type:'orders',
            //                  partyId:this.selectedPartyId,
            //                  partyName:this.selectedPartyName,
            //              }
            //          }
            //      });
            //  }
            // },

            fn_itemClick: function (item, index) {
                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("sale_items_state", JSON.stringify(obj));

                // this.$root.fn_navigateToDisplayView(item.transactionListId, item.transactionId);
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/order/items/'+item.transactionId});
                // this.$router.push({path:'/transactions/sales/Order/items/'+itemId, query:{'pendingOrder':1}});
            },

            // fn_getBottomMenu: function (elementId, itemId, partyId, partyName) {
            //  this.selectedItemId = itemId;
            //  this.selectedPartyId = partyId;
            //  this.selectedPartyName = partyName

            //  let bottomMenu = {"BottomMenu": [menuItem.createInvoice]}

            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // infiniteHandler($state) {
         //     let filterObject = {};
            //  filterObject.type = 10001;
            //  filterObject.limit = 10;
            //  filterObject.startIndex = this.startIndex;
            //  filterObject.search = this.searchValue.toLowerCase();

            //  filterObject.formTransactionListId = [10002, 10003];
            //  filterObject.transactionListId = [10001];

            //  alert('start');

            //  let self = this;
            //  this.getItems("OutstandingOrders", filterObject, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //              let responseJson = JSON.parse(response);
            //              self.listItemObject.totalCount = responseJson.totalCount;
            //              self.listItemObject.orderTotal = responseJson.orderTotal;

            //              for(let i in responseJson.items){
            //                  self.listItemObject.items.push(responseJson.items[i]);  
            //              }
            //              // self.listItemObject.items = self.listItemObject.items.concat(responseJson.items);
            //              self.startIndex = self.listItemObject.items.length;

            //              if(self.listItemObject.items.length < self.listItemObject.totalCount){
            //                  $state.loaded();
            //                  alert("loaded")
            //              }
            //              else{
            //                  alert("complete");
            //                  $state.complete();
            //              }
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
         //    },

            // fn_searchItem: function () {
            //  this.startIndex = 0;
            //  this.infiniteId += 1;
            //  this.listItemObject.items = [];
            // }

            fn_loadItems: function() {
                this.loadMore = true;
                let filterObject = {};
                filterObject.type = 10001;
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';

                filterObject.formTransactionListId = [10002, 10003];
                filterObject.transactionListId = [10001];

                let pageState = null;
                if(localStorage.getItem("sale_items_state")){
                    pageState = JSON.parse(localStorage.getItem("sale_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }

                this.fn_loadTimeOut();

                let self = this;
                this.getItems("OutstandingOrders", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;
                            self.listItemObject.orderTotal = responseJson.orderTotal;

                            let itemFound = false;

                            // self.listItemObject.items = [];
                            for(let i in responseJson.items){
                                if(pageState && pageState.itemId){
                                    if(responseJson.items[i].transactionId == pageState.itemId){
                                        itemFound = true;
                                        console.log(itemFound);
                                    }
                                    // else{
                                    //  itemFound = false;
                                    // }
                                }
                                // else{
                                //  itemFound = true;
                                // }

                                let itemIndex = self.listItemObject.items.findIndex(item => item.transactionId==responseJson.items[i].transactionId);

                                if(itemIndex==-1){
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }
                                // self.listItemObject.items.push(responseJson.items[i]);   
                            }
                            // console.log(pageState);
                            // if(pageState && pageState.itemId){
                            //     setTimeout(function(){
                            //         if(itemFound){
                            //             let scrollOptions = {"duration":0, "offset":-56, "easing":"linear"};
                            //             self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                            //             localStorage.removeItem("sale_items_state");
                            //         }
                            //         else{
                            //             self.$vuetify.goTo(99999, self.scrollOptions);
                            //         }
                            //     },100);
                            // }

                            // if(pageState && !pageState.itemId){
                            //     setTimeout(function(){
                            //         let scrollOptions = {"duration":300, "offset":-56, "easing":"linear"};
                            //         self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                            //         localStorage.removeItem("sale_items_state");
                            //     },100);
                            // }



                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300);
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                            self.loadTimeOut = false;
                        }
                    }
                )
            },

            fn_searchItem: function () {
                // this.showPlaceHolder = false;
                this.startIndex = 0;
                this.listItemObject.items = [];
                this.fn_loadItems();
            },

            fn_debounceSearch: function(){
                 if (this.timeoutid != null){
                    clearTimeout(this.timeoutid);
                 }

                this.timeoutid = setTimeout(() => { 
                    this.fn_searchItem();
                },300);
            },

            fn_loadTimeOut: function(){
                setTimeout(() => {
                    if(this.loadMore){
                        this.loadTimeOut = true;
                        this.loadMore = false;
                    }
                    else{
                        this.loadTimeOut = false;
                    }
                }, 1 * 10 * 1000);
            }
        },
        mounted: function(){
            this.fn_loadItems();

            this.$eventHub.$on('UpdateSaleList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });
        }
    }
</script>