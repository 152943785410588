<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>

              <v-toolbar :height="56" flat>
                  <v-btn icon @click="closeDialog" :disabled="$parent.loading"><v-icon>arrow_back</v-icon></v-btn>
                  <v-toolbar-title>Add Accountant</v-toolbar-title>
              </v-toolbar>

              <v-divider class="noprint"></v-divider>

              <div class="data-container grey-back">

                <v-progress-linear indeterminate :height="6" color="primary" v-if="$parent.loading"></v-progress-linear>

              <ValidationObserver ref="observer">

                  <v-form @submit.prevent="submit" :disabled="$parent.loading">

                

                      <v-layout row wrap ma-0 pr-4 pl-4 white-back pt-4 pb-4>

                        <v-flex xs12 sm12 md12>
                          <ValidationProvider name="Phone Number" rules="required|numeric|min:10|max:10" v-slot="{ errors }">
                            <v-text-field
                              id="title"
                              v-model="$parent.Form.PhoneNumber"
                              prefix="+91"
                              label="Phone Number"
                              required
                              autocomplete="null"
                              :error-messages="errors">
                            </v-text-field>
                          </ValidationProvider>
                        </v-flex>
                        
                      </v-layout>

              </v-form>

            </ValidationObserver>

          </div>

          <v-card-actions white-back>
              <v-layout row wrap ma-0>

                  <v-flex xs12 sm12 md12 height48>
                      <!-- <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow @click="handleSubmit($parent.fn_checkValidations)">Save</v-btn> -->
                      <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                  </v-flex>

              </v-layout>
          </v-card-actions>
                  

          </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
import { required, min, max, numeric } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// setInteractionMode('eager')
extend('required', required)
extend('min', min)
extend('numeric', numeric)
extend('max', max)
// extend('max', {
//   ...max,
//   message: 'Phone number is not valid.',
// })
    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
                this.$parent.disableOnSave = false;
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_validateForm: function(){
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                }
              });
            }
        }
    }
</script>