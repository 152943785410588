<template>
    <div>

        <v-toolbar :height="56" flat extended>
            <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

            <v-toolbar-title>{{listTitle}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

            <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
        </v-toolbar>

        <div class="data-container grey-back hasExtention" v-scroll.self="onScroll">

            <v-list nopadding grey-back dense>

                <v-subheader white-back class="pl-4">Count: {{$root.numberToLocale(listItemObject.totalCount, 1)}}</v-subheader>

                <v-divider></v-divider>

                <template white-back v-for="(item, index) in listItemObject.items">
                  <v-list-item white-back :key="index" :id="'item_'+item.listItemsId">
                        <v-list-item-content cursor-pointer @click="fn_itemClick(item.listItemsId, index)">
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-menu dense left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                                </template>
                                <v-list nopadding dense>
                                    <v-list-item @click="fn_copy(item.listItemsId)">
                                    <v-list-item-title>Copy</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <!-- <v-btn icon small @click="fn_copy(item.listItemsId)"><v-icon font20>content_copy</v-icon></v-btn> -->
                        </v-list-item-icon>
                  </v-list-item>

                  <v-divider v-if="listItemObject.items.length-1 != index" :key="item.name"></v-divider>
                </template>


            </v-list>

            <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>

            <div center-align class="pa-6" v-if="loadTimeOut">
                <p font12 light-opacity-color>Unable to load the items!</p>
                <v-btn capitalize text color="primary" @click="fn_searchItem">
                    <v-icon left>refresh</v-icon>
                    Retry
                </v-btn>
            </div>


            <!-- <div class="display_msg" v-if="!listItemObject.items.length">There are no items</div> -->
        </div>
    </div>
</template>

<script>
// const menuItem = require('../../../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../../../config.js').configs.bottomSheetMenu;

import {masterMixin} from '../../../master_mixin.js'

    export default{
        mixins: [masterMixin],
        data(){
            return{
                listTitle: null,
                selectedItemId:null
            }
        },
        methods:{
            fn_goBack: function(){
                this.$router.replace("/"+this.$root.CurrentBusiness.BusinessID+"/lists");
            },

            fn_new: function(){
                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = this.listItemObject.items.length;
                localStorage.setItem("master_items_state", JSON.stringify(obj));

                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/list_item/items/new', query:{tagId:this.guidWeb(), listTitle:this.listTitle, listId:this.$route.query.listId}});

                // this.$router.push({path:'/masters/lists/'+this.$route.query.listId+'/items/new', query:{tagId:this.guidWeb(), listTitle:this.listTitle}});
            },

            fn_copy: function(itemId){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/list_item/items/new', query:{tagId:this.guidWeb(), listTitle:this.listTitle, listId:this.$route.query.listId, itemId:itemId, copy:true}});
            },

            fn_itemClick: function (itemId, index) {
                // tagId id form future use
                // itemName id form future use
                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("master_items_state", JSON.stringify(obj));

                // this.$router.push({path:'/masters/lists/'+this.$route.query.listId+'/items/'+itemId, query:{tagId:this.guidWeb(), listTitle:this.listTitle}});
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/list_item/items/'+itemId, query:{tagId:this.guidWeb(), listTitle:this.listTitle, listId:this.$route.query.listId}});
            },

            fn_loadItems: function(search) {
                this.loadMore = true;
                let filterObject = {};
                filterObject.listId = this.$route.query.listId;
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';

                let pageState = null;
                if(localStorage.getItem("master_items_state")){
                    pageState = JSON.parse(localStorage.getItem("master_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }

                this.fn_loadTimeOut();

                let self = this;
                this.getItems("ListItemList", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;

                            search ? self.listItemObject.items = [] : '';

                            let itemFound = false;
                            for(let i in responseJson.items){
                                if(pageState && pageState.itemId){
                                    if(responseJson.items[i].listItemsId == pageState.itemId){
                                        itemFound = true;
                                    }
                                }

                                let itemIndex = self.listItemObject.items.findIndex(item => item.listItemsId==responseJson.items[i].listItemsId);

                                if(itemIndex==-1){
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }
                                
                            }

                            console.log(pageState);
                            if(pageState && pageState.itemId){
                                setTimeout(function(){
                                    if(itemFound){
                                        let scrollOptions = {"duration":0, "offset":-112, "easing":"linear"};
                                        self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                                        localStorage.removeItem("master_items_state");
                                    }
                                    else{
                                        self.$vuetify.goTo(99999, self.scrollOptions);
                                    }
                                },100);
                            }

                            if(pageState && !pageState.itemId){
                                setTimeout(function(){
                                    let scrollOptions = {"duration":300, "offset":0, "easing":"linear"};
                                    self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                                    localStorage.removeItem("master_items_state");
                                },100);
                            }

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300);
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                        }
                    }
                )
            },

            fn_searchItem: function () {
                this.startIndex = 0;
                this.listItemObject.items = [];
                this.fn_loadItems(true);
            },

        },
        mounted: function(){

            // this.fn_showWebView();
            // window.js_globals.fn_onBackPress = this.fn_goBack;
            this.listTitle = this.$route.query.listTitle;
            this.fn_loadItems();

            this.$eventHub.$on('UpdateListItem', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });
        }
    }
</script>