<template>
    <div grey-back>

        <v-list nopadding two-line v-if="data.primaryMobileNumber || data.email || (data.addresses && data.addresses.length)">

            <!-- <template>
                <v-list-item>
                    <v-list-item-action>
                        <v-icon>account_box</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-subtitle>Ledger Type</v-list-item-subtitle>
                        <v-list-item-title>
                            {{data.groupTypeId == 101 ? 'Customer' : data.groupTypeId == 102 ? 'Supplier' : 'Other'}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template> -->

           <template v-if="data.groupTypeId != 103">
                <template v-if="data.groupTypeId == 101 && $root.TransactionSettings.beatRouteArea">
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon>map</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-subtitle>Beat/Area/Route</v-list-item-subtitle>
                            <v-list-item-title>{{data.routeName ? data.routeName : '-'}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
                <template v-if="data.primaryMobileNumber">
                    <v-list-item>
                        <v-list-item-action>
                            <!-- <v-icon>phone</v-icon> -->
                            <a :href="'tel:'+data.primaryMobileNumber" style="text-decoration:none"><v-icon>call</v-icon></a>
                        </v-list-item-action>
                        <v-list-item-content>
                            <!-- <v-list-item-subtitle>Mobile Number</v-list-item-subtitle> -->
                            <a :href="'tel:'+data.primaryMobileNumber" style="text-decoration:none">
                                <v-list-item-title dark-opacity-color>
                                    {{data.primaryMobileNumber ? data.primaryMobileNumber : ''}}
                                    <!-- {{data.primaryMobileNumber}}{{data.primaryMobileNumber && data.mobile ? ',' : ''}}
                                    {{data.mobile ? data.mobile : ''}}
                                    {{!data.primaryMobileNumber && !data.mobile ? '-' : ''}} -->
                                </v-list-item-title>    
                            </a>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
                <template v-if="data.email">
                    <v-list-item>
                        <v-list-item-action>
                            <a :href="'mailto:'+data.email" style="text-decoration:none"><v-icon>email</v-icon></a>
                        </v-list-item-action>
                        <v-list-item-content>
                            <!-- <v-list-item-subtitle>Email</v-list-item-subtitle> -->
                            <a :href="'mailto:'+data.email ? data.email : ''" style="text-decoration:none">
                                <v-list-item-title dark-opacity-color>{{data.email ? data.email : ''}}</v-list-item-title>
                            </a>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>

                <template v-if="data.msmeUdyamNumber">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>MSME/Udyam No</v-list-item-subtitle>
                            <v-list-item-title>{{data.msmeUdyamNumber}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
                <!-- <template>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon>phone</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Telephone</v-list-item-title>
                            <v-list-item-subtitle>{{data.phone ? data.phone : '-'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template> -->

                <template v-for="(address, index) in data.addresses">
                    <v-list-item :key="index" two-line v-if="data.addresses && data.addresses.length">
                        <v-list-item-action>
                            <v-icon>location_city</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-subtitle>{{address.groupTypeId == 101 ? 'Billing Address' : address.groupTypeId == 102 ? 'Shipping Address' : 'Billing and Shipping Address' }}</v-list-item-subtitle>
                            <v-list-item-title>{{fn_mergeAddress(address)}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="data.addresses.length-1 != index" :key="address"></v-divider>
                </template>
           </template>
           
        </v-list>

        <v-list nopadding class="mt-2" two-line v-if="data.gstType && data.groupTypeId != 103">
            <v-subheader light>GST Details</v-subheader>
            <v-divider></v-divider>
            <template>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>GST Type</v-list-item-subtitle>
                        <v-list-item-title>{{fn_getGstTypeName(data.gstType)}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>
            <template v-if="data.gstNumber">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>GST Number(GSTIN)</v-list-item-subtitle>
                        <v-list-item-title>{{data.gstNumber}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>
            <template v-if="data.placeOfSupply">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>State/Place of Supply</v-list-item-subtitle>
                        <v-list-item-title>{{data.placeOfSupply}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>
            <template v-if="data.panNumber">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>PAN No.</v-list-item-subtitle>
                        <v-list-item-title>{{data.panNumber}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>
            <template v-if="data.country">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Country</v-list-item-subtitle>
                        <v-list-item-title>{{data.country}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>
            <template v-if="data.currency">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Currency</v-list-item-subtitle>
                        <v-list-item-title>{{data.currency}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>
            
        </v-list>

        <v-list nopadding class="mt-2" two-line>
            <v-subheader light>Accounting</v-subheader>
            <v-divider></v-divider>
            <template>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Accounting Group</v-list-item-subtitle>
                        <v-list-item-title>{{data.accountGroupName}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>

        </v-list>
        
    </div>
</template>

<script>
    export default{
        props:['data'],
        methods:{
            fn_getGstTypeName: function (gstType) {
                if(gstType == 101)
                    return 'Registered Business - Regular';
                else if(gstType == 102)
                    return 'Registered Business - Composition';
                else if(gstType == 103)
                    return 'Unregistered Business';
                else if(gstType == 104)
                    return 'Consumer';
                else if(gstType == 105)
                    return 'Overseas';
                else if(gstType == 106)
                    return 'Special Economic Zone';
                else if(gstType == 107)
                    return 'Deemed Export';
            },

            fn_mergeAddress: function(address){
                let addressLine, city, state, pincode, country;

                address.addressLine ? addressLine = address.addressLine + ', ' : addressLine = '';
                address.city ? city = address.city + ', ' : city = '';
                address.state ? state = address.state + ', ' : state = '';
                address.pincode ? pincode = address.pincode + ', ' : pincode = '';
                address.country ? country = address.country : country = '';

                return addressLine + city + state + pincode + country;
            },
        }
    }
</script>