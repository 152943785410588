<template>
    <div grey-back full-height>

        <!-- <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="white--text">Group</v-toolbar-title>
        </v-toolbar>

        <v-form class="form-element hasToolbar hasFooter grey-back">


            <v-layout wrap padding-top-bottom-ten white-background>

                <v-flex xs12 sm12 md12 form-item>
                   <v-text-field  v-model="Form.name" label="Name" placeholder=" " v-validate="'required'" :error-messages="errors.collect('name')" data-vv-name="name" required></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12 form-item>
                   <v-checkbox class="check_box" label="Primary Group" v-model="Form.isPrimaryAccountGroup"></v-checkbox>
                </v-flex>

                <v-flex xs12 sm12 md12 form-item @click="fn_openDialogNatureList" v-if="Form.isPrimaryAccountGroup">
                    <v-text-field  
                        readonly 
                        v-model="Form.natureTitle" 
                        label="Nature" 
                        placeholder=" " 
                        append-icon="arrow_drop_down"
                        v-validate="'required'" 
                        :error-messages="errors.collect('nature')" 
                        data-vv-name="nature" 
                        required>
                    </v-text-field>
                </v-flex>


                <div v-if="!Form.isPrimaryAccountGroup" full-width>
                    <v-flex xs12 sm12 md12 form-item 
                    @click="fn_openParentGroupsDialog('android', 'AccountGroups', 'field_accountGroups')">

                       <v-text-field  
                            readonly 
                            v-model="Form.parentGroupName" 
                            label="Parent Group" 
                            placeholder=" "
                            append-icon="arrow_drop_down"
                            ref="field_accountGroups" 
                            :onUpdate="fn_updateParentGroup" 
                            v-validate="'required'" 
                            data-vv-name="parent group"
                            :error-messages="errors.collect('parent group')" 
                            required>
                        </v-text-field>
                    </v-flex>
                </div>

            </v-layout>

            <div style="position:fixed;bottom:0" full-width>
                <v-btn noroundborders color="primary" block nomargin class="height48" @click="fn_checkValidations" style="border-radius:0;">Save</v-btn>
            </div>
            
                
        </v-form> -->


        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>


    </div>
</template>

<script>
// const dialogjs =  require('../dialog').dialogjs;
// const menuItem = require('../../../../config.js').configs.menuItem;

// import dialogNatureList from './dialogs/dialog_nature'
// import dialogDiscard from '../../../dialogs/dialog_discard'
import dialogDiscard from '../../../dialogs/dialog_discard'
import {draftMixin} from '../../../draft_mixin'

// import dialogDraftItems from '../../../dialogs/dialog_draft_items'

import dialogForm from './dialogs/dialog_form'

    export default{
        mixins: [draftMixin],
        data(){
            return{
                // Form:{"accountGroupId":null, "description":null, "groupTypeId":501, "isPrimaryAccountGroup":true, "name":null, "parentAccountGroupId":null, "parentGroupName":null, "title":null, "nature":null, "natureTitle":null, "isSystem":0, "status":1,
                //  "acRefDetails":false, "runningCustomerGroup":false, "disableRunningCustomer":false, "disableAcRefDetails":false, "draftItems":[], "createdDate":null, "modifiedDate":null
                //  },
                Form:{"accountGroupId":null, "description":null, "groupTypeId":501, "isPrimaryAccountGroup":true, "name":null, "parentAccountGroupId":null, "parentGroupName":null, "title":null, "nature":null, "natureTitle":null, "isSystem":0, "status":1, "draftItems":[], "createdDate":null, "modifiedDate":null, "isNew":true, "selectedNature":null, "selectedParentGroup":null
                    },
                tagId:null,
                draftStorageId:null,
                dialogStorageId:null,

                natureList: [{'id':101, 'title':'Assets'}, {'id':102, 'title':'Expenses'}, {'id':103, 'title':'Income'}, {'id':104, 'title':'Liabilities'}],

                backButtonPress: false,

                snackbar:false,
                snackbar_msg:null,

                unwatch:false,
                accountGroups:[],
                disableOnSave:false

                // loadedFromDraft:false
            }
        },
        watch:{
            'Form': {
                handler: function (){
                     // this.fn_saveToLocalStorage(this);
                     if(!this.unwatch){
                        this.fn_saveDraft(this.draftStorageId, this.Form.name);
                     }
                     this.unwatch = false;
                 },
                 deep: true
            }
        },
        components:{
            'dialog-form':dialogForm,
            // 'dialog-nature-list': dialogNatureList,
            'dialog-discard': dialogDiscard,
            // 'dialog-draft-items': dialogDraftItems
        },
        methods:{

            fn_accountGroups: function(val){
              let filterObject = {};
              filterObject.search = val ? val.toLowerCase() : '';
              filterObject.startIndex = 0;
              filterObject.limit = 100;
              
              let self = this;
              this.getDialogItems("AccountGroupDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    self.accountGroups = JSON.parse(response);
                    // self.accountGroups.unshift("header");
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_closeDialogForm: function(){
                this.askToDiscard = true;
                if(this.fn_showDialogDiscard()){
                    this.$refs['ref_dialogDiscard'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
                this.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
            },

            fn_checkValidations: function(){
                this.fn_post();
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('AccountGroups', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let status = JSON.parse(response);
                            if(status) {
                                self.fn_cleanUp();
                            }
                            else {
                                // self.disableOnSave = false;
                                // self.saveBtnKey++;
                                self.$eventHub.$emit('snackbarAlert', {msg:'Already exists with this name'});
                            }

                            self.disableOnSave = false;
                            self.saveBtnKey++;
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                        }
                    }
                );
            },

            fn_cleanUp: function () {

                // this.loadedFromDraft = false;

                if(this.$route.query.ls){
                    let data = {};
                    data.type = 'accountGroup';
                    data.id = this.Form.accountGroupId;
                    data.name = this.Form.name;
                    localStorage.setItem('newItem', JSON.stringify(data));
                    // localStorage.setItem('closeDialog', 1);
                }

                this.$eventHub.$emit('UpdateAccountGroupList', {});
                this.fn_goBack();

                // let self = this;
                // this.fn_removeDraft(this.draftStorageId, function(){

                //     if(localStorage.getItem("master_items_state")){
                //         let obj = JSON.parse(localStorage.getItem("master_items_state"));
                //         obj.itemId = self.Form.accountGroupId;
                //         localStorage.setItem("master_items_state",JSON.stringify(obj));
                //     }

                //     self.$router.go(-1);
                // });
            },

            fn_post: function(){
                // !this.Form.accountGroupId ? this.Form.accountGroupId = this.guid() : '';
                if(!this.Form.accountGroupId){
                    this.Form.accountGroupId = this.guid();
                    // this.Form.createdDate = new Date();
                    this.Form.createdDate = this.$root.fn_getLocalDateFormat();
                }

                // this.Form.modifiedDate = new Date();
                this.Form.modifiedDate = this.$root.fn_getLocalDateFormat();

                this.Form.name = this.Form.name.trim();

                if(this.Form.isPrimaryAccountGroup){
                    // this.Form.parentAccountGroupId = this.Form.accountGroupId;
                    this.Form.parentAccountGroupId = null;
                    this.Form.parentGroupName = null;
                }
                else{
                    this.Form.parentAccountGroupId = this.Form.selectedParentGroup.itemId;
                    this.Form.parentGroupName = this.Form.selectedParentGroup.itemName;
                }

                console.log(this.Form);
                this.fn_saveLocal(this.Form);
            },

            fn_getNature: function (id) {
                for(let i=0;i<this.natureList.length;i++){
                    if(this.natureList[i].id == id){
                        return this.natureList[i].title;
                    }
                }
            },

            fn_get: function(itemId, copyData, bindData){
                var self = this;
                this.unwatch = true;
                this.Form.isNew = false;
                this.getData('AccountGroups', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            
                            var item = JSON.parse(response);

                            if(bindData){
                                self.fn_bindData(item, copyData);
                            }
                            
                            
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_bindData: function(item, copyData){
                // alert('in');
                this.unwatch = true;
                this.Form.name = item.name;
                this.Form.accountGroupId = item.accountGroupId;
                
                this.Form.groupTypeId = item.groupTypeId;
                this.Form.isPrimaryAccountGroup = item.isPrimaryAccountGroup;

                if(!this.Form.isPrimaryAccountGroup){
                    // this.Form.parentAccountGroupId = item.parentAccountGroupId;
                    // this.Form.parentGroupName = item.parentAccountGroup.name;
                    this.Form.selectedParentGroup = {"itemId":item.parentAccountGroupId, "itemName":item.parentAccountGroup.name}
                }
                else{
                    this.Form.parentAccountGroupId = null;
                    this.Form.parentGroupName = null;

                    this.Form.nature = item.nature;
                    // this.Form.natureTitle = this.fn_getNature(item.nature);
                }

                this.Form.isSystem = item.isSystem;
                this.Form.status = item.status;

                // this.Form.runningCustomerGroup = item.runningCustomerGroup;
                // this.Form.acRefDetails = item.acRefDetails;

                if(copyData){
                    this.Form.accountGroupId=null;
                    this.Form.isSystem = 0;
                    this.fn_saveDraft(this.draftStorageId, this.Form.name);
                    // alert("Account Group copied");
                    this.$eventHub.$emit('snackbarAlert', {msg:'Account Group copied'});
                }

                this.$root.fn_copyFormObject(this.Form, this.formId);
            },

            fn_clearForm: function(){
                // this.Form = {"accountGroupId":null, "description":null, "groupTypeId":501, "isPrimaryAccountGroup":true, "name":null, "parentAccountGroupId":null, "parentGroupName":null, "title":null, "nature":null, "natureTitle":null, "isSystem":0, "status":1,
                //  "acRefDetails":false, "runningCustomerGroup":false, "disableRunningCustomer":false, "disableAcRefDetails":false, "draftItems":[], "createdDate":null, "modifiedDate":null
                // };
                this.unwatch = true;
                this.Form = {"accountGroupId":null, "description":null, "groupTypeId":501, "isPrimaryAccountGroup":true, "name":null, "parentAccountGroupId":null, "parentGroupName":null, "title":null, "nature":null, "natureTitle":null, "isSystem":0, "status":1, "draftItems":[], "createdDate":null, "modifiedDate":null, "isNew":true, "selectedNature":null, "selectedParentGroup":null
                };
                setTimeout(() => {
                    this.Form.isNew ? this.$root.fn_copyFormObject(this.Form, this.formId) : '';
                }, 100);
                // this.$validator.reset();
            },

            fn_menuItemClick: function(tagId, menuId, menuAction){
                // tagId id form future use
                // menuId id form future use

                this.backButtonPress = false;
                
                if(menuAction=='save'){
                    this.fn_checkValidations();
                }
                else if(menuAction=='new'){
                    this.$router.push({path:'/masters/accounts/groups/new', query:{tagId:this.guidWeb(), ls:1}})
                }
                else if(menuAction=='reset'){
                    this.fn_resetForm();
                }
            },

            fn_resetForm: function () {
                this.unwatch = true;
                // this.loadedFromDraft = false;
                
                if(this.Form.accountGroupId){
                    this.fn_get(this.Form.accountGroupId, null, true);
                }
                else{
                    this.fn_clearForm();
                }

                // let self = this;
                this.fn_removeDraft(this.draftStorageId, function(){
                    console.log("draft deleted");
                    // self.showToast("Item deleted from draft");
                });
                
            },

            // fn_showWebView: function () {
            //     var viewObject = {};
            //     viewObject.ToolbarTitle = "Account Groups";
            //     viewObject.TopMenu = [menuItem.save];
            //     viewObject.isBottomMenu = true;

            //     var tagId = this.formId;
            //     var self = this;
            //     this.showWebView(tagId, viewObject, 
            //         {
            //             onSuccess: function(response){
            //               console.log(response);
            //             }, 
            //             onError: function(error){
            //               console.log(error);
            //             }, 
            //             // onChange: null,
            //             onMenuItemClick: function (tagId, menuId, menuAction) {
            //                 self.fn_menuItemClick(tagId, menuId, menuAction)
            //             }
            //         }
            //     );
            // },

            fn_setNewItem: function(){
                if(localStorage.getItem('newItem')){

                    let itemData = JSON.parse(localStorage.getItem('newItem'));

                    switch (itemData.type) {
                        case "accountGroup":
                            // this.fn_updateParentGroup(itemData.id, itemData.name, false);
                            // alert(JSON.stringify(itemData));
                            this.Form.selectedParentGroup = {"itemId":itemData.id, "itemName":itemData.name}
                            break;
                        default:
                            console.log("condition not matched");
                    }

                    localStorage.removeItem('newItem');
                }

            },

            fn_goBack: function(){
                this.$refs['ref_dialogDiscard'].closeDialogWeb();
                this.$root.viaKeyboardShortCut = false;
                this.$refs['ref_dialogForm'].closeDialogWeb();
                localStorage.removeItem(this.draftStorageId);
                this.backButtonPress = true;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            }

        },
        mounted: function(){

            // if(this.$route.query && this.$route.query.tagId){//in case of new form
            //     this.formId = this.$route.query.tagId;
            //     this.draftStorageId = 'draft_master_'+this.formId;
            // }
            // else if(this.$route.params && this.$route.params.tagId){//in case of edit form
            //     this.formId = this.$route.params.tagId;
            //     this.draftStorageId = 'draft_master_'+this.formId;
            // }

            // if(localStorage.getItem(this.draftStorageId)){
            //     let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
            //     this.Form = JSON.parse(dataObj.formData);
            // }

            // this.fn_accountGroups();

            // this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });

            setTimeout(() => {
                this.Form.isNew ? this.$root.fn_copyFormObject(this.Form, this.formId) : '';
            }, 100);

        },
        beforeRouteEnter (to, from, next) {
            next(vm => {

                let itemId = null;
                let copyData = false;

                // to.query && to.query.copy && from.name == 'itemlist' ? copyData = true : '';
                to.query && to.query.copy ? copyData = true : '';

                // if(to.query && to.query.itemId && from.name == 'itemlist'){
                //     itemId = to.query.itemId;
                // }
                if(to.query && to.query.itemId){
                    itemId = to.query.itemId;
                }

                if(to.params && to.params.tagId){//from display view
                    itemId = to.params.tagId;
                }

                if(to.query && to.query.tagId){//in case of new form
                    vm.formId = to.query.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }
                else if(to.params && to.params.tagId){//in case of edit form
                    vm.formId = to.params.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }

                if(localStorage.getItem(vm.draftStorageId)){
                    let dataObj = JSON.parse(localStorage.getItem(vm.draftStorageId));
                    vm.Form = JSON.parse(dataObj.formData);
                }

                vm.fn_accountGroups();

                vm.$refs['ref_dialogForm'].openDialogWeb();

                if(itemId){
                    !localStorage.getItem(vm.draftStorageId) ? vm.fn_get(itemId, copyData, true) : '';
                }

                if(from.name == 'accountGroupEdit'){
                    // vm.fn_accountGroups();
                    vm.fn_clearForm();
                }

                if(from.name == 'accountGroupForm'){
                    // vm.fn_accountGroups();
                    setTimeout(()=>{vm.fn_setNewItem()},300);
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            !vm.accountGroups.length ? vm.fn_accountGroups() : '';
                            itemId ? vm.fn_get(itemId, copyData, true) : '';
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });

            })
        },
        beforeRouteUpdate (to, from, next) {

            if(to.query && to.query.tagId){//in case of new form
                this.formId = to.query.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
            }
            else if(to.params && to.params.tagId){//in case of edit form
                this.formId = to.params.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
            }

            if(localStorage.getItem(this.draftStorageId)){
                let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
                this.Form = JSON.parse(dataObj.formData);
            }
            else{
                this.fn_clearForm();
            }

            this.fn_accountGroups();

            setTimeout(()=>{this.fn_setNewItem()},300);

            this.$refs['ref_dialogForm'].openDialogWeb();

            let isCalled = false;
            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(!isCalled){
                        isCalled = true;
                        this.disableOnSave = false;
                        this.saveBtnKey++;
                        !this.accountGroups.length ? this.fn_accountGroups() : '';
                    }
                }, 1000);
            });
            
            next();
        },
        beforeRouteLeave (to, from, next) {
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('connected');
                next();
            }
        },
    }

</script>