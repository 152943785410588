<template>
    <div grey-back full-height>

        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>

    </div>
</template>

<script>
// const dialogjs =  require('../../../masters/forms/dialog').dialogjs;
// const menuItem = require('../../../../config.js').configs.menuItem;
const ledgerGroupTypeId = require('../../../../config.js').configs.ledgerGroupTypeId;
// const accountGroupIds = require('../../../../config.js').configs.accountGroupIds;

const transactionData = require('../../datamodel').data.transactionData;

// import dialogNote from '../dialogs/dialog_note'
// import dialogDiscard from '../../../dialogs/dialog_discard'
import {draftMixin} from '../../../draft_mixin'
import {transactionMixin} from '../../transaction_mixin'

import dialogForm from './dialogs/dialog_form'
import dialogDiscard from '../../../dialogs/dialog_discard'

// import dialogBwb from './dialogs/dialog_bwb_form'
// import dialogDatePicker from './dialogs/dialog_datepicker'
// import dialogDatePickerOpening from '../dialogs/dialog_datepicker_opening'

// import dialogConfirmation from '../../../dialogs/dialog_confirmation'



// const transactionTypes = require('../../../../config.js').configs.transactionTypes;

    export default{
        props:['settings'],
        mixins: [draftMixin, transactionMixin],
        data(){
            return{

                Form:{
                    "transactionData": JSON.parse(JSON.stringify(transactionData)),
                    "accountLedgerId":null,
                    "accountLedgerName":null,
                    "amount":null, 
                    "amountType":null,

                    "billData":JSON.parse(JSON.stringify(transactionData)),
                    // "bwbItems":[],
                    // "showBwb": false,
                    "ledgerData":null,
                    "isNew":true,
                    "selectedLedger":null
                },


                loading:false,
                tagId:null,
                draftStorageId:null,
                dialogStorageId:null,

                backButtonPress: false,
                // loadedFromDraft:false,

                snackbar:false,
                snackbar_msg:null,

                unwatch:false,
                invalidForm:false,
                newLedgerGroupTypeId:null,
                // accountLedgers:null,
                disableOnSave:false
                // selectedDate:new Date,

                // saleTransactionListId: transactionTypes.bwbRefSaleInvoice.id,
                // purchaseTransactionListId: transactionTypes.bwbRefPurchaseInvoice.id,

                // maxOpeningDate:null,
                // openingDate:null,
                // minDate:null,
                // bwbMaxDate:null
            }
        },
        components:{
            'dialog-form':dialogForm,
            // 'dialog-note':dialogNote,
            'dialog-discard': dialogDiscard,
            // 'dialog-bwb': dialogBwb,
            // 'dialog-datepicker':dialogDatePicker,
            // 'dialog-datepicker-opening':dialogDatePickerOpening,
            // 'dialog-confirmation':dialogConfirmation
        },
        watch:{
            'Form': {
                handler: function (){
                    if(!this.unwatch){
                        this.fn_saveDraft(this.dialogStorageId, this.Form.accountLedgerName);
                    }
                    this.unwatch = false;
                },
                deep: true
            }
        },
        methods:{

            fn_closeDialogForm: function(){
                this.askToDiscard = true;
                if(this.fn_showDialogDiscard()){
                    this.$refs['ref_dialogDiscard'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            // fn_accountLedgers: function(val){
            //     let filterObject = {};
            //     filterObject.search = val ? val.toLowerCase() : '';
              
            //     let self = this;
            //     this.getDialogItems("AccountDialog", filterObject, 
            //         {
            //             onSuccess: function (response) {
            //                 console.log(response);
            //                 self.accountLedgers = JSON.parse(response);
            //             },
            //             onError: function (error) {
            //                 console.log(error);
            //             }
            //         }
            //     )
            // },

            // fn_showMsg: function(){
            //  if(this.disableDebit || this.disableCredit){
            //      this.showToast("You need to remove billwise breakup items in order to change");
            //  }
            // },

            // fn_changeAmountType: function(value){
            //  // if(this.Form.ledgerData.groupTypeId == ledgerGroupTypeId.customer && value == 1){
            //  //  this.Form.showBwb = true;
            //  // }
            //  // else if(this.Form.ledgerData.groupTypeId == ledgerGroupTypeId.supplier && value == 0){
            //  //  this.Form.showBwb = true;
            //  // }
            //  // else{
            //  //  this.Form.showBwb = false;
            //  // }
            //  if(this.Form.ledgerData.groupTypeId == ledgerGroupTypeId.customer || this.Form.ledgerData.groupTypeId == ledgerGroupTypeId.supplier){
            //      this.Form.showBwb = true;
            //  }
            //  else{
            //      this.Form.showBwb = false;
            //  }
            // },

            // fn_showHideBwb: function(){
            //  let self = this;
                
            //  if(this.Form.ledgerData.groupTypeId == ledgerGroupTypeId.customer){
            //      this.Form.showBwb = true;
            //      setTimeout(function(){
            //          self.Form.amountType = 1;
            //      },100)
            //  }
            //  else if(this.Form.ledgerData.groupTypeId == ledgerGroupTypeId.supplier){
            //      this.Form.showBwb = true;
            //      setTimeout(function(){
            //          self.Form.amountType = 0;
            //      },100)
            //  }
            //  else{
            //      this.Form.showBwb = false;
            //  }
            // },

            

            // fn_openConfirmationDialog: function () {
            //  this.fn_openDialogWeb('web', 'confirmationDialog', 'ref_dialogConfirmation');
            // },

            // fn_openDatePicker: function (date) {
            //  let self = this;
            //  this.selectedDate = date;
            //  setTimeout(function () {
            //      self.fn_openDialogWeb('web', 'DatePicker', 'ref_dialogDatePicker');
            //  })
            // },

            // fn_updateDate: function (value) {
            //  this.Form.billData.refDate = value;
            //  this.fn_closeDialog();
            // },

            // fn_addBwb: function(){
            //  this.Form.billData = JSON.parse(JSON.stringify(transactionData));
            //  this.Form.billData.refDate = new Date();
            //  this.Form.billData.transactionListId = this.Form.amountType ? this.saleTransactionListId : this.purchaseTransactionListId;
            //  this.fn_openBwbDialog();
            // },

            // fn_openBwbDialog: function(){
            //  this.fn_openDialogWeb('web', 'Bwb', 'ref_dialogBwb');
            // },

            // fn_saveBwbItem: function(bwbItem){
            //  let billWiseBreakupItem = JSON.parse(JSON.stringify(bwbItem));
            //  if(!billWiseBreakupItem.transactionId){
            //      billWiseBreakupItem.transactionId = this.guid();
            //      this.Form.bwbItems.push(billWiseBreakupItem);
            //  }
            //  else{
            //      let itemIndex = this.Form.bwbItems.findIndex(item=>{
            //          return item.transactionId == billWiseBreakupItem.transactionId;
            //      })
            //      this.Form.bwbItems.splice(itemIndex, 1, billWiseBreakupItem);
            //  }
            //  this.fn_closeDialog();

            //  let self = this;
            //  setTimeout(function(){
            //      self.$refs['ref_dialogBwb'].disableBtn = false; 
            //  },1000);
            // },

            // fn_editBwbItem: function(item){
            //  this.Form.billData = JSON.parse(JSON.stringify(item));
            //  this.fn_openBwbDialog();
            // },

            // fn_removeBwbItem: function(transactionId, index){
            //  transactionId ? this.fn_checkReference(transactionId, index) : this.Form.bwbItems.splice(index, 1);
            // },

            // fn_checkReference: function(transactionId, index){
            //  let self = this;
            //  this.getData('CheckReferenceTransaction', transactionId, 
            //      {
            //          onSuccess: function(response){
            //              console.log(response);
            //              if(JSON.parse(response)){
            //                  self.showToast("Unable to delete as already used in transactions");
            //              }
            //              else{
            //                  self.Form.bwbItems.splice(index, 1);
            //              }
            //          }, 
            //          onError: function(error){
            //              console.log(error);
            //          }
            //      }
         //        );
            // },

            // fn_openDialogDiscard: function(){
            //  this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard');
            // },

            // fn_comingSoon: function () {
            //  this.showToast("Coming Soon...");
            // },

            // fn_openAccountLedger: function () {
            //  this.fn_openAccountLedgerDialog('android', 'AccountLedger', 'ref_accountLedger')
            // },

            // fn_openAccountLedgerDialog: function (platform, name, ref) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  //dialogObj.data = null;
            //  dialogObj.platform = platform;


            //  var self = this;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select Ledger";
            //  viewDialogObject.TopMenu = [menuItem.new];
            //  viewDialogObject.isBottomMenu = false;
            //  viewDialogObject.hideChip = true;
            //  viewDialogObject.enableFilterChip = true;

            //  let defaultGroupTypeId = 101;

            //  // viewDialogObject.groupTypeId = this.Form.partyId ? this.Form.partyDetails.groupTypeId : defaultGroupTypeId;
            //  viewDialogObject.groupTypeId = this.Form.ledgerData && this.Form.ledgerData.groupTypeId ? this.Form.ledgerData.groupTypeId : defaultGroupTypeId;

            //  this.newLedgerGroupTypeId = viewDialogObject.groupTypeId;

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":null, "name":null};

            //  if(this.Form.accountLedgerId && this.Form.accountLedgerName){
            //      paramObject.defaultValue = {"id":this.Form.accountLedgerId, "name":this.Form.accountLedgerName};
            //  }

            //  var self = this;
            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'accounts', tagId, paramObject, ref);
            // },

            fn_updateAccountLedger: function () {
                let selectedItem = JSON.parse(JSON.stringify(this.Form.selectedLedger));
                this.fn_get('TransactionByPartyId', this.Form.selectedLedger.itemId, ()=> {
                    this.Form.selectedLedger = JSON.parse(JSON.stringify(selectedItem));
                    this.fn_getLedgerData();
                });
            },

        //  // getting ledger data from groupTypeId as we are showing bwb only customers and suppliers
            // fn_getLedgerData: function(selectedValueId, callShowHideBwb){
            fn_getLedgerData: function(){
                let self = this;
                this.getData('Ledger', this.Form.selectedLedger.itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);

                            self.Form.ledgerData = JSON.parse(response);

                            if(!self.Form.transactionData.transactionId && self.Form.ledgerData && self.Form.ledgerData.groupTypeId){
                                if(self.Form.ledgerData.groupTypeId == 101 || self.Form.ledgerData.groupTypeId == 103){
                                    self.Form.amountType = 1;
                                }
                                else{
                                    self.Form.amountType = 0;
                                }
                            }
                            // !self.Form.transactionData.transactionId ? self.fn_showHideBwb() : self.fn_changeAmountType(self.Form.amountType);

                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            // fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
            //  this.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
            // },

            // fn_getBottomMenu: function (tagId) {
            //  let bottomMenu = null;

            //  let menuItem_Note = null;
            //  !this.Form.transactionData.notes ? menuItem_Note = menuItem.addNote : menuItem_Note = menuItem.editNote;

            //  bottomMenu = {"BottomMenu": [menuItem.save, menuItem_Note,  menuItem.reset]};

            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_closeDialog: function (tagId) {
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_checkValidations: function(){
                if(this.Form.amount <= 0){
                    // alert('Amount should be greater than zero');
                    this.$eventHub.$emit('snackbarAlert', {msg:"Amount should be greater than zero"});
                    this.saveBtnKey++;
                    this.disableOnSave = false;
                    // this.hideLoading();
                    return;
                }

                this.fn_post();
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.loading = true;
                this.postData('Transaction', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.loading = false;
                            self.fn_cleanUp();
                        },
                        onError: function(error){
                            console.log(error);
                            self.saveBtnKey++;
                            self.disableOnSave = false;
                            self.loading = false;
                            // self.hideLoading();
                            let response = JSON.parse(error);

                            if(response && response.openingLedgerExistsError){
                                // alert("Opening already added.");
                                self.$eventHub.$emit('snackbarAlert', {msg:"Opening already added"});
                            }
                        }
                    }
                );
            },

            fn_cleanUp: function () {
       //           let self = this;
                // this.fn_removeDraft(this.draftStorageId, function(){
                //  self.hideLoading();
                //  self.$router.go(-1);
                // });
                localStorage.removeItem(this.draftStorageId);
                this.$eventHub.$emit('UpdateOpeningLedgerList', {});
                this.fn_goBack();
            },

            fn_post: function(){

                //only in case of new transaction
                if(!this.Form.transactionData.transactionId){
                    this.Form.transactionData.transactionId = this.guid();
                    // this.Form.transactionData.createdDate = new Date();
                    this.Form.transactionData.createdDate = this.$root.fn_getLocalDateFormat();
                }

                // this.Form.transactionData.modifiedDate = new Date();
                this.Form.transactionData.modifiedDate = this.$root.fn_getLocalDateFormat();

                // this.Form.transactionData.partyId = this.Form.accountLedgerId;
                this.Form.transactionData.partyId = this.Form.selectedLedger.itemId;

                let amount = this.Form.amount ? this.Form.amount : 0;
                this.Form.transactionData.amount = amount  //debit or credit amount
                this.Form.transactionData.roundedAmount = Math.round(amount); //or creditTotal amount
                this.Form.transactionData.status = 1;
                this.Form.transactionData.transactionListId = this.settings.transactionTypeId;
                this.Form.transactionData.refDate = null;

                //subtracted 1 day from opening date as we are adding opening one day less than user selected opening date
                // let od = new Date(JSON.parse(JSON.stringify(this.openingDate)));
                let od = new Date(JSON.parse(JSON.stringify(this.$root.CurrentBusiness.OpeningDate)));
                od.setDate(od.getDate()-1);
                // this.Form.transactionData.transactionDate = new Date(od);
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(od);

                // let grossAmount = this.roundUpto2Decimals(amount - this.bwbTotal);
                let grossAmount = this.roundUpto2Decimals(amount);

                if((this.Form.ledgerData.groupTypeId == ledgerGroupTypeId.customer && this.Form.amountType == 0) || (this.Form.ledgerData.groupTypeId == ledgerGroupTypeId.supplier && this.Form.amountType == 1)){
                    this.Form.transactionData.grossAmount = -grossAmount;
                }
                else{
                    this.Form.transactionData.grossAmount = grossAmount;
                }

                // this.Form.transactionData.onAccount = this.onAccount;
                this.Form.transactionData.onAccount = null;

                let postObj = {};
                postObj = JSON.parse(JSON.stringify(this.Form.transactionData));

                let ledgerAccountAmount = amount;

                //Preparing Object for transaction ledger table
                let transactionLedgerObj = {};

                transactionLedgerObj.transactionLedgerId = this.guid();
                // transactionLedgerObj.accountLedgerId = this.Form.accountLedgerId;
                transactionLedgerObj.accountLedgerId = this.Form.selectedLedger.itemId;
                transactionLedgerObj.orderNumber = 1;
                transactionLedgerObj.amount = amount;
                transactionLedgerObj.amountType = this.Form.amountType;

                postObj.transactionLedgers = [];
                postObj.transactionLedgers.push(transactionLedgerObj);
                
                //Preparing Object for ledger accounts table
                let ledgerAccountObj = {};

                ledgerAccountObj.ledgerAccountId = this.guid();
                // ledgerAccountObj.accountId =  this.Form.accountLedgerId;
                ledgerAccountObj.accountId = this.Form.selectedLedger.itemId;
                ledgerAccountObj.amount =  this.Form.amountType == 1 ? ledgerAccountAmount : -ledgerAccountAmount;
                // ledgerAccountObj.amount = ledgerAccountAmount;
                ledgerAccountObj.amountType =  this.Form.amountType;

                postObj.ledgerAccounts = [];
                postObj.ledgerAccounts.push(ledgerAccountObj);

                //bwbItems
                // postObj.bwbItems = [];
                // for(let i=0; i<this.Form.bwbItems.length; i++){
                //  !this.Form.bwbItems[i].createdDate ? this.Form.bwbItems[i].createdDate =  new Date() : '';
                //  this.Form.bwbItems[i].modifiedDate =  new Date();
                //  this.Form.bwbItems[i].partyId = this.Form.accountLedgerId;
                //  this.Form.bwbItems[i].roundedAmount = Math.round(this.Form.bwbItems[i].amount);
                //  this.Form.bwbItems[i].netAmount = this.Form.bwbItems[i].amount;
                //  this.Form.bwbItems[i].parentTransactionId = this.Form.transactionData.transactionId;
                //  this.Form.bwbItems[i].transactionDate = this.Form.bwbItems[i].refDate;
                //  postObj.bwbItems.push(this.Form.bwbItems[i]);
                // }

                postObj.sortDate = this.$moment(this.Form.transactionData.transactionDate).format('YYYY-MM-DD');

                console.log(JSON.stringify(postObj));
                this.fn_saveLocal(postObj);
            },

            fn_get: function (type, itemId, callback) {
                this.Form.isNew = false;
                let filterObject = {};
                filterObject.type = this.settings.transactionTypeId;

                let self = this;
                this.getFilterData(type, itemId, filterObject, null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            if(response){
                                let data = JSON.parse(response);
                                self.fn_bindData(data);
                            }
                            else{
                                self.fn_clearForm();
                                callback();
                            }
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                            self.fn_goBack();
                        }
                    }
                );
            },


            fn_bindData: function (data) {
                this.Form.transactionData.additionalData = data.additionalData;
                this.Form.transactionData.amount = data.amount;
                this.Form.transactionData.businessId = data.businessId;
                this.Form.transactionData.createdBy = data.createdBy;
                this.Form.transactionData.createdDate = data.createdDate;
                this.Form.transactionData.currencyId = data.currencyId;
                this.Form.transactionData.dueDate = data.dueDate;
                this.Form.transactionData.dueType = data.dueType;
                this.Form.transactionData.modifiedBy = data.modifiedBy;
                this.Form.transactionData.modifiedDate = data.modifiedDate;
                this.Form.transactionData.notes = data.notes;
                this.Form.transactionData.partyId = data.partyId;
                this.Form.transactionData.paymentMode = data.paymentMode;
                this.Form.transactionData.prefix = data.prefix;
                this.Form.transactionData.refDate = data.refDate;
                this.Form.transactionData.refNumber = data.refNumber;
                this.Form.transactionData.roundedAmount = data.roundedAmount;
                this.Form.transactionData.salesmanId = data.salesmanId;
                this.Form.transactionData.status = data.status;
                this.Form.transactionData.suffix = data.suffix;
                this.Form.transactionData.transactionDate = data.transactionDate;
                this.Form.transactionData.transactionId = data.transactionId;
                this.Form.transactionData.transactionListId = data.transactionListId;
                this.Form.transactionData.transactionNumber = data.transactionNumber;

                let transactionLedger = JSON.parse(JSON.stringify(data.transactionLedgers[0]));
                // this.Form.accountLedgerName = transactionLedger.accountLedgerName;
                // this.Form.accountLedgerId = transactionLedger.accountLedgerId;
                this.Form.selectedLedger = {"itemId":transactionLedger.accountLedgerId, "itemName":transactionLedger.accountLedgerName};
                this.Form.amount = transactionLedger.amount;
                this.Form.amountType = transactionLedger.amountType;


                // this.Form.bwbItems = [];
                // if(data.bwbItems && data.bwbItems.length){
                //  for(let i=0; i<data.bwbItems.length; i++){
                //      let bwbData = JSON.parse(JSON.stringify(transactionData));
                //      bwbData.transactionId = data.bwbItems[i].transactionId;
                //      bwbData.refDate = data.bwbItems[i].refDate;
                //      bwbData.transactionDate = data.bwbItems[i].transactionDate;
                //      bwbData.refNumber = data.bwbItems[i].refNumber;
                //      bwbData.amount = data.bwbItems[i].amount;
                //      bwbData.transactionListId = data.bwbItems[i].transactionListId;
                //      bwbData.createdDate = data.bwbItems[i].createdDate;
                //      this.Form.bwbItems.push(bwbData);
                //  }
                // }

                //getting ledger data from groupTypeId as we are showing bwb only customers and suppliers
                this.fn_getLedgerData(data.partyId);

                let self = this;
                setTimeout(function(){
                    self.$root.fn_copyFormObject(self.Form, self.formId);
                },300);
            },


            fn_clearForm: function(){
                this.Form = {
                    "transactionData": JSON.parse(JSON.stringify(transactionData)),
                    "accountLedgerId":null,
                    "accountLedgerName":null,
                    "amount":null, 
                    "amountType":0,
                    "billData":JSON.parse(JSON.stringify(transactionData)),
                    // "bwbItems":[],
                    // "showBwb":false,
                    "ledgerData":null
                }
            },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use

            //  this.backButtonPress = false;
                
            //  if(menuAction=='save'){
            //      this.fn_checkValidations();
            //  }
            //  else if(menuAction=='new'){
            //      let groupTypeId = this.newLedgerGroupTypeId ? this.newLedgerGroupTypeId : 101;
            //      this.$router.push({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), groupTypeId: groupTypeId, ls:1}});
            //  }
            //  else if(menuAction=='reset'){
            //      this.fn_resetForm();
            //  }
            //  else if(menuAction=='addnote'){
            //      this.fn_addNote();
            //  }
            // },

            // fn_addNote: function () {
            //  this.$refs['ref_dialogNote'].noteValue = this.Form.transactionData.notes;
            //  this.fn_openDialogWeb('web', 'Note', 'ref_dialogNote');
            //  setTimeout(function(){
            //      let noteField = document.getElementById('noteField');
            //      noteField.focus();
            //  });
            // },

            // fn_saveNote: function () {
            //  this.Form.transactionData.notes = this.$refs['ref_dialogNote'].noteValue;
            //  this.fn_closeDialog();
            //  let self = this;
            //  setTimeout(function () {
            //      self.$vuetify.goTo('#note', self.scrollOptions);
            //  },300);
            // },

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  let self = this;

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_resetForm: function () {
            //  this.unwatch = true;
            //  // this.loadedFromDraft = false;

            //  if(this.Form.transactionData.transactionId){
            //      this.fn_get('Transaction', this.Form.transactionData.transactionId);
            //  }
            //  else{
            //      this.fn_clearForm();
            //      // this.fn_checkOpening(transactionTypes.openingStock.id, transactionTypes.openingLedger.id);
            //  }

            //  this.fn_removeDraft(this.draftStorageId);
            // },

            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.isBottomMenu = true;

            //  var tagId = this.formId;
            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null,
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            fn_setNewItem: function(){
                let itemData = null;
                if(localStorage.getItem('newItem')){

                    itemData = JSON.parse(localStorage.getItem('newItem'));

                    switch (itemData.type) {
                        case "ledger":
                            this.Form.selectedLedger = {"itemId":itemData.id, "itemName":itemData.name};
                            this.fn_updateAccountLedger()
                            break;
                            
                        default:
                            console.log("condition not matched");
                    }

                    localStorage.removeItem('newItem');
                }

                if(this.$route.query && this.$route.query.dt && !itemData){
                    this.Form.selectedLedger = {"itemId":this.$route.query.id, "itemName":this.$route.query.name};
                    this.fn_updateAccountLedger();
                }

                this.Form.isNew ? this.$root.fn_copyFormObject(this.Form, this.formId) : '';
            },

            fn_selectedGroupTypeId: function(groupTypeId){
                this.newLedgerGroupTypeId = Number(groupTypeId);
            },

            // fn_goBack: function(){
            //     this.$refs['ref_dialogForm'].closeDialogWeb();
            //     localStorage.removeItem(this.draftStorageId);
            //     this.backButtonPress = true;
            //     setTimeout(() => {
            //         this.$router.go(-1);
            //     }, 50);
            // }

            fn_goBack: function(){
                this.$refs['ref_dialogForm'].closeDialogWeb();
                localStorage.removeItem(this.draftStorageId);
                this.$root.viaKeyboardShortCut = false;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            }
        },
        mounted: function(){
            console.log(this.$route);

            var self = this;

            if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }

            // !this.$root.CurrentBusiness.DataDrive ?  this.fn_checkTransactionCount() : '';


            // var formsArray = [];
            // if(localStorage.getItem('openingledgerform')){
            //     formsArray = JSON.parse(localStorage.getItem('openingledgerform'));
            // }

            // formsArray.push(this.formId);
            // localStorage.setItem('openingledgerform', JSON.stringify(formsArray));

            // window.js_globals.fn_onBackPress = this.fn_goBack;
            // window.js_globals.fn_closeClick = this.fn_closeDialog;
            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_selectedGroupTypeId = this.fn_selectedGroupTypeId;

            // this.fn_showWebView();

           

            if(localStorage.getItem(this.draftStorageId)){
                let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
                this.Form = JSON.parse(dataObj.formData);
            }

            setTimeout(function(){self.fn_setNewItem()},300);

            // this.fn_accountLedgers();

            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('select_party', () => {
                let element = document.getElementById("party_dropdown");
                element.focus();
                element.click();
            });

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });

            // this.fn_setDraftData('temp', 'Opening Ledger');

            // this.fn_checkOpening(transactionTypes.openingStock.id, transactionTypes.openingLedger.id);
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {

                let itemId = null;

                if(to.params && to.params.tagId){//from display view
                  itemId = to.params.tagId;
                }

                if(itemId){
                    vm.fn_get('Transaction', itemId);
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            vm.loading = false;
                            // !vm.accountLedgers ? vm.fn_accountLedgers() : '';
                            itemId ? vm.fn_get('Transaction', itemId) : '';
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                        
                    }, 1000);
                });

            })
        },
        beforeRouteLeave (to, from, next) {
            // this.$eventHub.$off('select_party');
            // this.$eventHub.$off('save');
            // this.$eventHub.$off('escape');
            // next();
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('select_party');
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('connected');
                next();
            }
        },
        // beforeRouteUpdate (to, from, next) {
            
        //     var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //     var existingForm = false;
        //     if(dialogData && dialogData.length){
        //         var openForms = JSON.parse(localStorage.getItem('openingledgerform'));
        //         for(var i=0; i<openForms.length; i++){
        //             if(openForms[i]==to.query.tagId){
        //                 existingForm = true;
        //                 dialogjs.fn_checkAndCloseOpenDialogs(this);
        //                 break;
        //             }
        //         }
        //         existingForm ? next(false) : next(true);
        //     }
        //     else{
        //         next();
        //     }
        // },
        // beforeRouteLeave (to, from, next) {
        //     var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //     if(dialogData && dialogData.length){
        //         if(this.backButtonPress){
        //             dialogjs.fn_checkAndCloseOpenDialogs(this);
        //             this.backButtonPress = false;
        //             next(false);
        //         }
        //         else{
        //             localStorage.removeItem("SetDataDrive");
        //             next(true);
        //         }
        //     }
        //     else{
        //         if(this.fn_showDialogDiscard()){
        //             this.fn_openDialogDiscard();
        //             next(false);
        //         }
        //         else{
        //             localStorage.removeItem('openingledgerform');
        //             localStorage.removeItem("SetDataDrive");
        //             next(); 
        //         }
        //     }
        // },
    }

</script>