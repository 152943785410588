<template>
    <div full-height>
        <div class="display_wrapper" id="non-printable">

            <v-toolbar flat :height="56">
                <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn>

                <v-toolbar-title>Stock Adjustment</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn outlined class="ml-4" small @click="fn_edit" color="primary" v-if="displayData && displayData.status==1">
                    <v-icon left>edit</v-icon> Edit
                </v-btn>

                <v-btn class="ml-4" small outlined color="red" v-if="displayData" @click="fn_delete">
                    <v-icon left>delete</v-icon> Delete
                </v-btn>

                <!-- <v-btn icon @click="fn_edit" v-if="displayData && displayData.status==1">
                    <v-icon>edit</v-icon>
                </v-btn>

                <v-btn icon @click="fn_getBottomMenu">
                  <v-icon>more_vert</v-icon>
                </v-btn> -->

            </v-toolbar>

            <v-divider></v-divider>

            <div class="data-container grey-back">

                <v-alert :value="displayData && displayData.status==0" type="warning" icon="info_outline" transition="slide-y-transition">
                    <span>This transaction has been cancelled!</span>
                </v-alert>

                <v-layout row wrap pa-4 ma-0 font16 white-back>
                    <v-flex xs6 sm6 md6>Date</v-flex>
                    <v-flex xs6 sm6 md6 right-align>{{displayData.transactionDate | moment("DD-MMM-YYYY")}}</v-flex>
                </v-layout>

                <!-- <v-divider></v-divider> -->

                
                <v-list three-line nopadding class="mt-2">

                    <div v-for="(item, index) in displayData.transactionProducts" :key="index">
                        <v-list-item class="right-subtitle-tile custom-action-tile">
                            <v-list-item-content>

                              <v-list-item-title class="mb-1">{{item.productName}}</v-list-item-title>
                                <v-list-item-subtitle class="mb-1">
                                    {{item.transactionProductMode ? 'Added' : 'Reduced'}}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle v-for="(vItem, vIndex) in item.variants" :key="vIndex">
                                    <template v-if="vItem.qty">
                                        &#8377; {{$root.numberToLocale(item.rate)}} X {{vItem.qty}} {{vItem.unitName}}    
                                    </template>
                                </v-list-item-subtitle>

                              <!-- <v-list-item-subtitle v-if="$root.TransactionSettings.mrpWiseStock">
                                <span>MRP: <b>{{$root.numberToLocale(item.mrp)}}</b></span>
                              </v-list-item-subtitle> -->
                            </v-list-item-content>  

                            <v-list-item-action font16 style="padding-bottom:6px !important">&#8377; {{$root.numberToLocale(item.amount)}}</v-list-item-action>
                        </v-list-item>

                        <v-divider v-if="displayData.transactionProducts.length-1 != index"></v-divider>

                        <template v-if="$root.TransactionSettings.enableBatch && item.transactionProductBatches && item.transactionProductBatches.length">
							<v-subheader class="mt-2 white-back">{{ item.selectedBatchType == 2 ? '#Series' : '#Batches' }}</v-subheader>
							<v-divider></v-divider>
							<v-card flat v-for="(batch, batchIndex) in item.transactionProductBatches" :key="batchIndex">
								<v-card-text>
									<v-layout ma-0 row wrap>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.batchNumber">
											<div light-opacity-color>Batch No.</div>
											<div>{{ batch.batchNumber }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.batchSize">
											<div light-opacity-color>Size</div>
											<div>{{ batch.batchSize }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.batchMRP">
											<div light-opacity-color>MRP</div>
											<div>{{ batch.batchMRP }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.mfgDate">
											<div light-opacity-color>Mfg. Date</div>
											<div v-if="batch.mfgDate">{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.expDate">
											<div light-opacity-color>Exp. Date</div>
											<div v-if="batch.expDate">{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.purchaseRate">
											<div light-opacity-color>Purchase Rate</div>
											<div>{{ batch.purchaseRate }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.field1">
											<div light-opacity-color>Serial Number</div>
											<div>{{ batch.field1 }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.field2">
											<div light-opacity-color>IMEI Number</div>
											<div>{{ batch.field2 }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.field3">
											<div light-opacity-color>Part Number</div>
											<div>{{ batch.field3 }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2 v-if="batch.field4">
											<div light-opacity-color>Model Number</div>
											<div>{{ batch.field4 }}</div>
										</v-flex>
										<v-flex xs4 sm4 md4 mb-2>
											<div light-opacity-color>Quantity</div>
											<div>{{ batch.qty }}</div>
										</v-flex>
									</v-layout>
								</v-card-text>
							</v-card>
							<v-divider v-if="batchIndex != item.transactionProductBatches.length - 1"></v-divider>
						</template>

                    </div>
                </v-list>

                <div v-if="displayData.notes" class="mt-2">
                    <v-list full-width nopadding>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title medium-theme-color>Notes</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-card flat class="note-card">
                        <v-card-text dark-opacity-color font16 v-html="displayData.notes" white-space-pre-line></v-card-text>
                    </v-card>
                </div>

            </div>

            <!-- <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" :header="confirmationDialogHeader" :confirmationMessage="confirmationMessage" :actionMethod="fn_callCancelTransaction" :params="{type:actionType}" :displayView='true'></dialog-confirmation> -->

            <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" :header="confirmationDialogHeader" :confirmationMessage="confirmationMessage" :actionMethod="fn_callCancelTransaction" :params="{type:actionType}" :displayView='true' okBtnText="Yes" cancelBtnText="No" icon="warning" iconClass="redcolor" textClass="redcolor"></dialog-confirmation>

            <dialog-confirmation ref="ref_dialogConfirmation" header="Batch Alert!" confirmationMessage="This transaction consists batch.<br>Please edit this transaction in BizOps App on mobile"  :actionMethod="fn_closeDialogConfirmation" :displayView='true' okBtnText="Okay"></dialog-confirmation>
            
            <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>
        </div>

        <!-- <div id="printable" full-width>
            <print-template ref="ref_printTemplate" :data="printData" v-if="printData!=null"></print-template>
        </div>

        <dialog-permission ref="ref_dialogPermission"></dialog-permission> -->

        <v-snackbar v-model="snackbarOpenFile">
          File downloaded!
          <v-btn text color="primary" @click.native="$root.fn_openDownloadedFile">Open</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
const menuItem = require('../../../../config.js').configs.menuItem;
// const transactionForms = require('../../../../config.js').configs.transactionForms;

import dialogConfirmation from '../../../dialogs/dialog_confirmation'

// import printTemplate from '../../../invoice_templates/invoices/template201'

import {transactionMixin} from '../../transaction_mixin'

    export default{
        props:['settings'],
        mixins: [transactionMixin],
        data(){
            return{
                displayData:{},
                toolbarTitle:null,
                selectedNatureValue:null,
                snackbar:false,
                snackbar_msg:null,
                actionType:null,
                confirmationDialogHeader:null,
                confirmationMessage:null,
                printData:null
            }
        },
        components:{
            'dialog-confirmation': dialogConfirmation,
            // 'print-template': printTemplate
        },
        methods:{

            // fn_printData: function(){
            //  this.fn_getPrintData();
            //  AndroidRoutingTest.print();
            // },

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_bindInvoiceTemplate: function(type){

                if(this.disableAction){
                    return;
                }

                this.disableAction = true;

                let self = this;
                setTimeout(function(){
                    self.disableAction = false;
                },500);

                if(type=='sharewhatsapp'){
                    this.fn_shareInvoice('sharewhatsapp');
                }
                else if(type=='print'){
                    this.fn_getPrintData('StockJournalPrintData', this.displayData.transactionId, 'print');
                }
                else if(type=='pdf'){
                    this.fn_getPrintData('StockJournalPrintData', this.displayData.transactionId, 'pdf');
                }
                else if(type=='share'){
                    this.fn_shareInvoice('share');
                }
            },

            fn_shareInvoice: function(type){
                let shareObj = {};
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';
                // shareObj.shareType = this.displayData.primaryMobileNumber && type == 'sharewhatsapp' ? 'ShareOnWhatsApp' : 'Share';
                shareObj.subject = "Stock Journal Details";
                shareObj.textMessage = "Dear,\n\nBelow are stock journal details. Please find the attached pdf of stock journal.\n\nDate: "+this.$moment(this.displayData.createdDate).format('DD-MMM-YYYY')+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.fn_getPrintData('StockJournalPrintData', this.displayData.transactionId, 'pdf', shareObj);
            },

            fn_callCancelTransaction: function(paramObj){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;

                let self = this;
                if(paramObj.type=='cancel'){
                    this.fn_cancelTransaction(this.displayData.transactionId, this.displayData.transactionListId, null, function(status){
                        self.displayData.status = status;
                        if(status==0){
                            // alert("Transaction "+self.displayData.mergeTransactionNumber+ " cancelled");
                            self.$eventHub.$emit('snackbarAlert', {msg:"Transaction "+self.displayData.mergeTransactionNumber+ " cancelled"});
                            self.$eventHub.$emit('UpdateItemList', {});
                        }
                        else{
                            // alert("Transaction "+self.displayData.mergeTransactionNumber+ " not cancelled");
                            self.$eventHub.$emit('snackbarAlert', {msg:"Transaction "+self.displayData.mergeTransactionNumber+ " not cancelled"});
                        }
                    });  
                }
                else{
                    let transactionNumber = this.displayData.mergeTransactionNumber;
                    this.fn_deleteTransaction(this.displayData.transactionId, this.displayData.transactionListId, function(status){
                        if(status){
                            // self.snackbar = true;
                            // self.snackbar = '#'+transactionNumber+ ' deleted parmanently!.';
                            // alert('#'+transactionNumber+ ' deleted parmanently');
                            self.$eventHub.$emit('snackbarAlert', {msg:'#'+transactionNumber+ ' deleted parmanently'});
                            self.$eventHub.$emit('UpdateItemList', {});
                            // self.$router.go(-1);
                            self.fn_goBack();
                        }
                        else{
                            // self.snackbar = true;
                            // self.snackbar = 'Something went wrong. Try again!.'; 
                            // alert('Something went wrong. Try again');
                            self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                        }
                    });
                }
            },

            fn_comingSoon: function () {
                // this.snackbar_msg = "Coming Soon..";
                // this.snackbar = true;
                this.showToast("Coming Soon...");
            },

            fn_getData: function (type, itemId) {
                let filterObject = {};
                filterObject.type = this.settings.transactionTypeId;

                let self = this;
                this.getFilterData(type, itemId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.displayData = JSON.parse(response);
                            self.displayData.transactionProducts.forEach(function (item) {
                                item.transactionProductMode = item.variants[0].transactionProductMode;
                                self.fn_getGroupTotal(item);
                            })
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                        }
                    }
                );
            },

            fn_getGroupTotal: function (tp) {
                let total = 0;
                tp.variants.forEach(function (item) {
                    total += item.amount;
                });
                tp.amount = total;
            },

            fn_getBottomMenu: function (tagId) {

                var bottomMenu = {"BottomMenu": [menuItem.delete]};

                // this.displayData.status==0 ? bottomMenu = {"BottomMenu": [menuItem.share, menuItem.delete]} : '';

                this.setBottomMenu(tagId, bottomMenu, 
                    {
                        onSuccess: function (response) {
                            console.log(response);
                        },
                        onError: function (error) {
                            console.log(error);
                        }
                    }
                )
            },

            fn_menuItemClick: function(tagId, menuId, menuAction){
                // tagId id form future use
                // menuId id form future use
                if(menuAction=='edit' && this.displayData && this.displayData.transactionId){
                    this.fn_edit();
                }
                else if(menuAction=='cancel'){
                    // this.fn_cancelTransaction(this.displayData.transactionId, 'display');
                    this.actionType = 'cancel';
                    this.confirmationDialogHeader = "Cancel Transaction?";
                    this.confirmationMessage = "Cancelled transaction cannot be uncancelled. Are you sure you want to cancel this transaction?"
                    this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
                }
                else if(menuAction=='delete'){
                    this.actionType = 'delete';
                    this.confirmationDialogHeader = "Delete Transaction?";
                    this.confirmationMessage = "Deleted transaction cannot be restored. Are you sure you want to delete this transaction?"
                    this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
                }
                else if(menuAction=='share'){
                    // this.fn_shareInvoice('share');
                    this.fn_bindInvoiceTemplate('share');
                }
            },

            fn_edit: function () {
                if(this.displayData.transactionProducts[0].transactionProductBatches && this.displayData.transactionProducts[0].transactionProductBatches.length){
                    this.actionMethod = this.fn_closeDialogConfirmation;
                    this.$refs['ref_dialogConfirmation'].dialog_confimation = true;
                    return;
                }
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+"/stockadjustment/items/"+this.displayData.transactionId+'/edit'}); 
                // this.$router.push({path:'/transactions/inventory/stockadjustment/items/'+this.displayData.transactionId+'/edit'});
            },

            fn_closeDialogConfirmation: function(){
                this.$refs['ref_dialogConfirmation'].dialog_confimation = false;
            },

            fn_delete: function(){
                this.actionType = 'delete';
                this.confirmationDialogHeader = "Delete Transaction?";
                this.confirmationMessage = "Deleted transaction cannot be restored. Are you sure you want to delete this transaction?"
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            fn_closeDialog: function(){
                this.$refs['ref_dialogPermission'].dialog_permission = false;
            },

            fn_showWebView: function () {
                var viewObject = {};

                var tagId = null;
                this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';
                var self = this;

                this.showWebView(tagId, viewObject, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }, 
                        // onChange: null, 
                        onMenuItemClick: function (tagId, menuId, menuAction, itemId) {
                            self.fn_menuItemClick(tagId, menuId, menuAction, itemId)
                        }
                    }
                );
            },

            // fn_getPrintData: function(){
            //  var self = this;
         //         this.getData('StockJournalPrintData', this.displayData.transactionId, 
            //      {
            //          onSuccess: function(response){
            //              console.log(response);
            //              self.printData = JSON.parse(response);
            //              setTimeout(function(){
            //                  self.$refs['ref_printTemplate'].fn_init();
            //              },300);
            //          }, 
            //          onError: function(error){
            //              console.log(error);
            //          }
            //      }
         //        );
            // },
        },
        mounted: function(){

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_onBackPress = this.fn_goBack;

            // this.fn_showWebView();
            
            if(this.$route.params && this.$route.params.tagId){
                this.fn_getData('TransactionProducts', this.$route.params.tagId);
            }
        },
        // beforeRouteLeave (to, from, next) {
        //  if(this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation || this.$refs['ref_dialogPermission'].dialog_permission){
        //      this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;
        //      this.$refs['ref_dialogPermission'].dialog_permission = false;
        //      next(false);
        //  }
        //  else{
        //      next(true); 
        //  }
     //    }
        // computed:{
        //  transactionNumber: function () {
        //      let prefix = this.displayData.prefix ? this.displayData.prefix + '/' : '';
     //         let suffix = this.displayData.suffix ? '/' + this.displayData.suffix : '';
     //         let transactionNumber = this.displayData.transactionNumber ? this.displayData.transactionNumber : '';
     //         return prefix + transactionNumber + suffix;
        //  }
        // }
    }

</script>