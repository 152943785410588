<template>
    <div>
        <v-card flat>
            <v-subheader>
                Select Transaction Type
                <v-spacer></v-spacer>
                <v-btn icon @click="closeFilterNav"><v-icon>close</v-icon></v-btn>
            </v-subheader>

            <v-divider></v-divider>
            
            <v-card-text nopadding class="data-container">

                <v-list nopadding>
                    <template v-for="(item, index) in transactionForms">
                        <v-list-item norightpadding :key="index" @click="selectItems(item.id)" v-if="!item.hidden">
                            <v-list-item-content>
                                <v-list-item-title>{{item.title}}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action class="list-checkbox">
                              <v-checkbox v-model="selectedTransactionListIds" :value="item.id" readonly></v-checkbox>
                            </v-list-item-action>
                        </v-list-item>   
                    </template>
                </v-list>

                <div white-back sticky-bottom class="ma-4" v-if="selectedTransactionListIds.length">
                    <v-btn color="blue darken-1" block outlined text @click="clearAll">clear all</v-btn>
                </div>
            </v-card-text>
            <!-- <v-divider></v-divider> -->
            <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="blue darken-1" flat v-if="selectedTransactionListIds.length" @click="clearAll">clear all</v-btn>
                <v-btn small color="blue darken-1" flat @click="$parent.dialog_transaction_list = false">Done</v-btn>
            </v-card-actions> -->
        </v-card>
    </div>
</template>

<script>

    export default{
        props:['transactionForms', 'selectedTransactionListIds', 'selectItems', 'clearAll', 'closeFilterNav']
    }
</script>