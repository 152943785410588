<template>
  <div>
        <div v-if="propsObj.detailRows">

          <!-- <div right-align><v-btn color="primary" nomargin flat @click="openTransaction(propsObj.transactionListId, propsObj.transactionId)">View Transaction</v-btn></div> -->
      
          <div class="detail-report-header">
              <div class="table full-width">
                  <div class="table_row">
                      <div class="table_col" v-for="(value, key) in propsObj.headerRow" :key="key" :class="{'right-align':key!='party'}"><strong>{{value}}</strong></div>
                  </div>
              </div>
          </div>

          <div class="detail-report-body">
              <div class="table full-width">
                  <div class="table_row" v-for="(item, index) in propsObj.detailRows" :key="index">
                      <div class="table_col" v-for="(col, colIndex) in propsObj.columns" :key="colIndex" :class="{'right-align': typeof(item.cols[col])=='number' || item.cols[col] == null, 'text-underline':colIndex==0}" @click="openAccountDetails(item.accountId)">
                        <span v-if="col == 'party'">{{item.cols[col] ? $root.numberToLocale(item.cols[col], 1) : '-'}}</span>
                        <span v-else>{{item.cols[col] ? $root.numberToLocale(item.cols[col]) : '-'}}</span>
                      </div>
                  </div>
              </div>
          </div>

          <div class="detail-report-footer" v-if="propsObj.footerRow">
              <v-divider></v-divider>
              <div class="table full-width">
                  <div class="table_row">
                      <div class="table_col" v-for="(col, index) in propsObj.columns" :key="index" 
                      :class="{'right-align': typeof(propsObj.footerRow[col])=='number' || propsObj.footerRow[col] == null}">
                        <span v-if="col == 'party'">
                          <strong>{{$root.numberToLocale(propsObj.footerRow[col], 1)}}</strong>
                        </span>
                        <span v-else>
                          <strong>{{$root.numberToLocale(propsObj.footerRow[col])}}</strong>
                        </span>
                      </div>
                  </div>
              </div>
          </div>

        </div>

  </div>
</template>

<script>
  export default{
    props:['propsObj', 'openTransaction', 'openAccountDetails']
  }
</script>