import Vue from 'vue'

Vue.prototype.dialog_reconnect = true;
// import router from './router'

let actionQueue = [];

// firebase configurations
import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/auth";
import "firebase/database";


let firebaseConfig = null;

if(process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'preprod'){
    // production configs
    firebaseConfig = {
        apiKey: "AIzaSyDgLFjui5BgZw5B_PfOPsyH-8UxF_Xwlqc",
        authDomain: "bizopsapp.firebaseapp.com",
        // databaseURL: "https://bizopsapp.firebaseio.com",
        databaseURL:"https://bizopsapp-92f16.asia-southeast1.firebasedatabase.app/",
        projectId: "bizopsapp",
        storageBucket: "bizopsapp.appspot.com",
        messagingSenderId: "81292557995",
        appId: "1:81292557995:android:252d55026b5aca0366c1ee",
    };
}
else if(process.env.NODE_ENV === 'staging'){
    // Uat configs
    firebaseConfig = {
        apiKey: "AIzaSyBqe1wRXiA9WS34YYQrUemK1B5Rw4hK8TE",
        authDomain: "bizopsstaging.firebaseapp.com",
        // databaseURL: "https://bizopsstaging.firebaseio.com",
        databaseURL:"https://bizopsstaging-default-rtdb.asia-southeast1.firebasedatabase.app/",
        projectId: "bizopsstaging",
        storageBucket: "bizopsstaging.appspot.com",
        messagingSenderId: "22911752863",
        appId: "1:22911752863:android:ec46bf57ddc3662d9c60aa",
    };
}
else if(process.env.NODE_ENV === 'development'){
    // Dev configs
    firebaseConfig = {
        apiKey: "AIzaSyCpj5j5bajxXczXScRE_IEGT7z4W8amFEU",
        authDomain: "bizopsdevelopment.firebaseapp.com",
        // databaseURL: "https://bizopsdevelopment.firebaseio.com",
        databaseURL:"https://bizopsdevelopment-default-rtdb.asia-southeast1.firebasedatabase.app/",
        projectId: "bizopsdevelopment",
        storageBucket: "bizopsdevelopment.appspot.com",
        messagingSenderId: "889089510735",
        appId: "1:889089510735:android:4830f61e16da24a74d093a",
    };
}
else if(process.env.NODE_ENV === 'uatprod'){
  // UatProd configs
  firebaseConfig = {
      apiKey: "AIzaSyATSLJp5tbQGdY87rnX7zMx2gY6hfhdiok",
      authDomain: "bizopsappuatprod.firebaseapp.com",
      databaseURL: "https://bizopsappuatprod.firebaseio.com",
      projectId: "bizopsappuatprod",
      storageBucket: "bizopsappuatprod.appspot.com",
      messagingSenderId: "84925644823",
      appId: "1:84925644823:android:58a08b938bb81d60f5dc52",
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Access Firestore Timestamp
// const timestamp = firebase.firestore.Timestamp.now();
// console.log("Timestamp:", timestamp.toDate());

const firestore = firebase.firestore();
const auth = firebase.auth();
const database = firebase.database();

// const servers = {
//     iceServers: [
//         {
//           urls: "stun:openrelay.metered.ca:80",
//         },
//         {
//           urls: "turn:openrelay.metered.ca:80",
//           username: "openrelayproject",
//           credential: "openrelayproject",
//         },
//         {
//           urls: "turn:openrelay.metered.ca:443",
//           username: "openrelayproject",
//           credential: "openrelayproject",
//         },
//         {
//           urls: "turn:openrelay.metered.ca:443?transport=tcp",
//           username: "openrelayproject",
//           credential: "openrelayproject",
//         },
//     ],
//     // [
//     //     {
//     //         urls:[
//     //             'stun:stun.1.google.com:19302', 
//     //             'stun:stun1.l.google.com:19302', 
//     //             'stun:stun2.l.google.com:19302', 
//     //             'stun:stun3.l.google.com:19302', 
//     //             'stun:stun4.l.google.com:19302'
//     //         ]
//     //     },
//     // ],
//     iceCandidatePoolSize:20,
// };

var serverConfig = {iceCandidatePoolSize:20, iceServers:null}
// var serverConfig = {iceCandidatePoolSize:20, iceServers:null, iceTransportPolicy: 'relay'}

// variables

let pc = null;
let sendChannel = null;
let receiveChannel = null;
let connectionId = null;
let connectionType = null;
let fn_getBusiness = null;
let fn_sendNotification = null;
// let fn_getConnectionIdFromServer = null;
let fn_dialogReconnect = null;
let fn_connectionTimeOutCallback = null;
// let serverDeviceId = null;
let pingChannel = null;
let pingSendChannel = null;
let broadcastChannel = null;
let postChannel = null;
let fileSendChannel = null;

let reconnectDialogObj = null;

var sendNotificationTimeOut = null;
var pingInterval = null;

let qrScanSnapshotListener = null;
// let callDocSnapshotListener = null;
let requestDocWatcher = null;
// let answerSnapshotListner = null;

let notconnectedMessageTimeout = null;
let unableToConnectTimeout = null;

// let unsubscribeConnectionDoc = null;

Vue.prototype.isConnected = false;
Vue.prototype.connectionStatus = null;

Vue.prototype.preventReconnectDialog = false;

let readyStateOpenCount = 0;

let answerRef = null;
let connectionWaitTimer = null;
let fnInitRequest = null;
let watcherDisposingTimer = null;
let connectionAckTimer = null;
let serverUserId = null;

// let timer = 0;
// let countdowntimer = null;

// webrtc connection functions
function initPeerConnection(iceServers){

    iceServers ? serverConfig.iceServers = iceServers : '';
    // Global State
    // pc = new RTCPeerConnection(servers);
    pc = new RTCPeerConnection(serverConfig);
    sendChannel = pc.createDataChannel('sendDataChannel');
    postChannel = pc.createDataChannel('PostDataChannel');
    pingSendChannel = pc.createDataChannel('PingDataChannel');
    fileSendChannel = pc.createDataChannel('FileDataChannel');
    pc.addEventListener('connectionstatechange', event => {
        console.log(event);
        console.log(event.target.connectionState);
        console.log(event.target.iceConnectionState);

        Vue.prototype.connectionStatus = event.target.connectionState;

        // showing reconnect dialog in case of connection state disconnected
        if(event.target.connectionState == 'disconnected'){
          // !Vue.prototype.preventReconnectDialog ? fn_dialogReconnect(true) : '';
          if(reconnectDialogObj){
            reconnectDialogObj.isConnecting = true;
            reconnectDialogObj.isDisconnected = true;
          }
        }

        // setTimeout(() => {
        if(reconnectDialogObj && reconnectDialogObj.isConnecting && ((event.target.connectionState == 'disconnected' && event.target.iceConnectionState == 'disconnected') || event.target.connectionState == 'new')){
          if(reconnectDialogObj){
            reconnectDialogObj.isConnecting = false;
            reconnectDialogObj.isDisconnected = false;
          }

          answerRef ? answerRef.off() : '';
          Vue.prototype.fn_closeConnection();

          if(fnInitRequest){
            if(!navigator.onLine){
              fn_dialogReconnect(true);
            }
            else{
              reconnectDialogObj.isConnecting = true;
              reconnectDialogObj.isReconnecting = true;
              // Vue.prototype.fn_checkDeviceStatus(b_data, null, null, uid, null, reconnectDialogObj, null, i_ser);
              fnInitRequest ? fnInitRequest() : '';
            }
          }

          // Vue.prototype.fn_checkDeviceStatus(b_data, null, fn_getBusiness, uid, fn_dialogReconnect, reconnectDialogObj, null, i_ser);
        }
        // }, 1 * 10 * 1000);

        if(event.target.connectionState == "connected" && (event.target.iceConnectionState == "connected" || event.target.iceConnectionState == "completed")){
            

            connectionWaitTimer ? clearTimeout(connectionWaitTimer) : '';
            watcherDisposingTimer ? clearTimeout(watcherDisposingTimer) : '';
            answerRef ? answerRef.off() : '';
            // connectingStateTimer !== null ? clearTimeout(connectingStateTimer) : '';
            // timer = 0;
            // countdowntimer? clearInterval(countdowntimer):'';
            console.log("Connected");
            Vue.prototype.isConnected = true;
            // callDocSnapshotListener();
            requestDocWatcher ? requestDocWatcher() : '';
            // answerSnapshotListner ? answerSnapshotListner() : '';
            // localStorage.clear();

            // hiding snackbar alert if any visible
            Vue.prototype.$eventHub.$emit('hideSnackbarAlert');

            clearTimeout(sendNotificationTimeOut);
            clearTimeout(notconnectedMessageTimeout);
            clearTimeout(unableToConnectTimeout);

            if(reconnectDialogObj){
              reconnectDialogObj.isConnecting = false;
              reconnectDialogObj.isDisconnected = false;
            }
            fn_dialogReconnect(false);

            pingInterval = setInterval(()=>{
              if(Vue.prototype.isConnected){
                sendPing();
              }
            }, 1 * 20 * 1000);

            // Checking which server turn/stun is used for connectivity
            pc.getStats(null).then(stats => {
                let selectedLocalCandidate

                // let arrObj = []
                // for (let value of stats.values()){
                //     arrObj.push(value);
                // }
                
                // console.log(JSON.stringify(arrObj));

                for (const {type, state, localCandidateId} of stats.values()){
                    if (type === 'candidate-pair' && state === 'succeeded' && localCandidateId) {
                        selectedLocalCandidate = localCandidateId
                        break
                    }
                }
                
                if(!!selectedLocalCandidate && stats.get(selectedLocalCandidate)?.candidateType === 'relay'){
                    connectionType = 'relay';
                }
                else{
                    connectionType = 'stun';
                }
            })

            // removing disabled property from save btn (if disabled and if any form is on front)
            // let saveBtn = document.getElementsByClassName('save_btn');
            // if(saveBtn.length && saveBtn[0].hasAttribute('disabled')){
            //   saveBtn[0].removeAttribute('disabled');
            //   saveBtn[0].classList.remove('v-btn--disabled');
            //   saveBtn[0].classList.add('primary');
            // }

            Vue.prototype.$eventHub.$emit('connected');
        }

        // showing reconnect dialog in case of connection state failed
        if(event.target.connectionState == "failed" && event.target.iceConnectionState == "disconnected"){

            // connectingStateTimer !== null ? clearTimeout(connectingStateTimer) : '';
            // alert('inside failed');
            // answerRef ? answerRef.off() : '';

            readyStateOpenCount = 0;
            Vue.prototype.isConnected = false;

            if(reconnectDialogObj){
                // fn_dialogReconnect(true);
                reconnectDialogObj.isConnecting = false;
                reconnectDialogObj.isDisconnected = false;
                Vue.prototype.$eventHub.$emit('disconnected');

                // if(fnInitRequest){
                //   reconnectDialogObj.isConnecting = true;
                //   reconnectDialogObj.isReconnecting = true;
                //   fnInitRequest ? fnInitRequest() : '';
                // }
            }
            clearInterval(pingInterval);

            // pc.getStats(null).then(stats => {
            //     let bytesReceived = 0;
            //     let bytesSent = 0;
            //     let arrObj = [];
            //     for (let value of stats.values()){
            //         arrObj.push(value);
            //         if(value.type=="data-channel"){
            //             bytesReceived += value.bytesReceived;
            //             bytesSent += value.bytesSent;
            //         }
            //     }

            //     console.log(JSON.stringify(arrObj));
            //     alert("received: "+bytesReceived);
            //     alert("sent: "+bytesSent);
            // })

            Vue.prototype.fn_closeConnection();
        }
    });
}

// let b_data = null;
// let i_ser = null;
// let uid = null;

// Vue.prototype.fn_checkDeviceStatus = function(deviceId, sendNotification, getBusiness, userId){
Vue.prototype.fn_checkDeviceStatus = async function(businessData, sendNotification, getBusiness, userId, dialogReconnect, reconnectObj, getConnectionIdFromServer, iceServers, connectionTimeOutCallback, initRequest){

  // b_data = businessData;
  // i_ser = iceServers;
  // uid = userId;
  initRequest ? fnInitRequest = initRequest : '';

  businessData && businessData.ServerUserID ? serverUserId = businessData.ServerUserID : '';
  // countdowntimer = setInterval(() => {
  //   timer++;
  //   console.log("connection timer: "+ timer);
  // }, 1000);

  console.log(new Date().toISOString());
  if(RTCPeerConnection){
      initPeerConnection(iceServers);
  }

  sendNotification ? fn_sendNotification = sendNotification : '';
  getBusiness ? fn_getBusiness = getBusiness : '';
  dialogReconnect ? fn_dialogReconnect = dialogReconnect : '';
  reconnectObj ? reconnectDialogObj = reconnectObj : '' ;
  // getConnectionIdFromServer ? fn_getConnectionIdFromServer = getConnectionIdFromServer : '';
  connectionTimeOutCallback ? fn_connectionTimeOutCallback = connectionTimeOutCallback : '';

  localStorage.setItem('login_type', businessData.type);

  pc.ondatachannel = receiveChannelCallback;

  sendChannel.onopen = onSendChannelStateChange;
  sendChannel.onclose = onSendChannelStateChange;

  postChannel.onopen = onPostChannelStateChange;
  postChannel.onclose = onPostChannelStateChange;

  pingSendChannel.onopen = onPingSendChannelStateChange;
  pingSendChannel.onclose = onPingSendChannelStateChange;

  fileSendChannel.onopen = onFileSendChannelStateChange;
  fileSendChannel.onclose = onFileSendChannelStateChange;

  // Create offer
  let offerDescription = await pc.createOffer();

  await pc.setLocalDescription(offerDescription);
  
  // let iceCount = 1;
  let iceCandidates = [];
  let allCandidatesReceived = false;
  let iCount = 0;
  // Get candidates for caller, save to db
  pc.onicecandidate = (event) => {

      if(event.candidate){
          iCount = iCount + 1;
          console.log("candidate: " + iCount);
          let data = event.candidate.toJSON();
          let dataObj = {};
          dataObj.sdp = data.candidate;
          dataObj.sdpMLineIndex = data.sdpMLineIndex;
          dataObj.sdpMid = data.sdpMid;
          dataObj.type = 'candidate';
          let dateTime = firebase.firestore.Timestamp.now();
          dataObj.candidateTime = dateTime.toDate();
          iceCandidates.push(dataObj);
          // console.log("candidate " + iceCount + " " + new Date().toISOString());
          // iceCount++;
          // offerCandidate.iceCandidates.push(dataObj);
      }
      
      if(!event.candidate){
        // iceCount = 0; 
        // iceCandidates = [];
        console.log('All candidates are received for offer');
        allCandidatesReceived = true;
      }
  };
  

  let docId = businessData.BusinessID;

  fn_sendNotification ? fn_sendNotification(businessData.BusinessID) : '';
  // let requestDocId = null;

  // try{
    // checking if user has permission to access connection doc
    // let doc = await firestore.collection('connections').doc(docId).get();
    // let doc = await firestore.collection('connectionsWebRTC').doc(docId).get();

    if(docId){

      let requestRef = null;
      let requestAckRef = null;
      let offerRef = null;
      watcherDisposingTimer ? clearTimeout(watcherDisposingTimer) : '';

      // disposing old watchers if request reinitiates
      // if(reconnectObj && reconnectObj.isConnecting){
      //   requestAckRef ? requestAckRef.off() : '';
      //   offerRef ? offerRef.off() : '';
      //   answerRef ? answerRef.off() : '';
      //   reconnectObj ? reconnectObj.isConnecting = false : '';
      //   reconnectObj ? reconnectObj.isDisconnected = false : '';
      //   Vue.prototype.fn_closeConnection(false, 'prevent_logout', null, true);
      // }

      // resetting for reconnect after 10 seconds if request not acknowledged only in case connecting from login screen
      connectionAckTimer = setTimeout(() => {
        if(Vue.prototype.connectionStatus != 'connected' && requestAckRef && getBusiness){
          reconnectObj ? reconnectObj.isConnecting = false : '';
          reconnectObj ? reconnectObj.isDisconnected = false : '';
          requestAckRef ? requestAckRef.off() : '';
          Vue.prototype.$eventHub.$emit('requestFailed');
          // closing channels
          Vue.prototype.fn_closeConnection(false, 'prevent_logout', null, true);
          connectionWaitTimer ? clearTimeout(connectionWaitTimer) : '';
          watcherDisposingTimer ? clearTimeout(watcherDisposingTimer) : '';
        }
      }, 1 * 10 * 1000);

      // showing reconnect dialog after 5 seconds if request not acknowledged only in case of auto reconnect
      connectionAckTimer = setTimeout(() => {
        if(Vue.prototype.connectionStatus != 'connected' && requestAckRef && reconnectObj && !reconnectObj.isDialogOpen && !getBusiness){
          reconnectObj ? reconnectObj.isConnecting = false : '';
          reconnectObj ? reconnectObj.isDisconnected = false : '';
          requestAckRef ? requestAckRef.off() : '';
          fn_dialogReconnect(true);
          // closing channels
          Vue.prototype.fn_closeConnection(false, 'prevent_logout', null, true);
          connectionWaitTimer ? clearTimeout(connectionWaitTimer) : '';
          watcherDisposingTimer ? clearTimeout(watcherDisposingTimer) : '';
        }
      }, 1 * 5 * 1000);

      // reset and showing reconnect dialog if not connected within 15 seconds in case of auto reconnect
      connectionWaitTimer = setTimeout(() => {
        if(Vue.prototype.connectionStatus != 'connected'){
          // disposing watchers if active
          requestAckRef ? requestAckRef.off() : '';
          offerRef ? offerRef.off() : '';
          answerRef ? answerRef.off() : '';
          // resetting flags
          reconnectObj ? reconnectObj.isConnecting = false : '';
          reconnectObj ? reconnectObj.isDisconnected = false : '';
          fn_dialogReconnect(true);

          setTimeout(() => {
            Vue.prototype.$eventHub.$emit('requestFailed');
          });
          // closing channels
          Vue.prototype.fn_closeConnection(false, 'prevent_logout', null, true);
        }
      }, 1 * 30 * 1000);

      // disposing watchers after 20 seconds if connection not established
      // watcherDisposingTimer = setTimeout(() => {
      //   if(Vue.prototype.connectionStatus != 'connected'){
      //     // disposing watchers if active
      //     requestAckRef ? requestAckRef.off() : '';
      //     offerRef ? offerRef.off() : '';
      //     answerRef ? answerRef.off() : '';
      //   }
      // }, 1 * 35 * 1000);

      // generating request id in case of new connection
      getBusiness || !connectionId ? connectionId = this.guidWeb() + '_' + serverUserId + '_' + userId : '';
      // let requestDoc = firestore.collection('connectionsWebRTC').doc(docId).collection('connectionRequests').doc(requestDocId);
      let reqObj = {clientUserId: userId, clientTimestamp:firebase.firestore.Timestamp.now()};

      // database.ref('Connections/' + docId + '/Requests/'+requestDocId+'/Initiate').set(reqObj);

      console.log("requestId: "+ connectionId);
      // requestRef = database.ref('Connections/' + docId + '/Requests/'+connectionId);
      requestRef = database.ref('Connections/' + docId + '/Requests/' + serverUserId + '/'+connectionId);
      requestRef.set(reqObj, (error) => {
        if (error) {
          // The write failed...
          console.log(error);
        } else {
          // Data saved successfully!
          console.log("Data saved successfully!");
        }
      });

      requestAckRef = database.ref('Connections/' + docId + '/Data/'+connectionId+'/Initiate');

      requestAckRef.on('value', (snapshot) => {
        let data = snapshot.val();
        console.log(data);
        if(data && data.ACK && data.connectionId){
          // requestWatcher();
          connectionAckTimer ? clearTimeout(connectionAckTimer) : '';
          requestAckRef.off();

          // let offerRef = database.ref('Connections/' + docId + '/Requests/'+requestDocId+'/toServerRTC');
          offerRef = database.ref('Connections/' + docId + '/Data/'+connectionId+'/toServerRTC');

          let offerScheme = {
            clientTimestamp:firebase.firestore.Timestamp.now(),
            // offer: { sdp: offerDescription.sdp, iceCandidates: JSON.parse(JSON.stringify(iceCandidates))},
            offer: { sdp: offerDescription.sdp, iceCandidates: []},
            type: offerDescription.type
          }

          for(let i=0;i<iceCandidates.length;i++){
            if(!iceCandidates[i].added){
              offerScheme.offer.iceCandidates.push(iceCandidates[i]);
            }
            iceCandidates[i].added = true;
          }

          // iceCandidates = [];

          offerRef.set(offerScheme, (error) => {
            if (error) {
              // The write failed...
              console.log(error);
            } else {
              // Data saved successfully!
              console.log("Offer saved successfully!");

              let gpCount = 1;
              let iceCheckInterval = setInterval(() => {

                let moreIces = iceCandidates.filter(ice=>{return !ice.added});

                if(moreIces && moreIces.length){
                  let candidateScheme = {};
                  // candidateScheme["candidategp"+gpCount] = {iceCandidates:JSON.parse(JSON.stringify(moreIces)), clientTimestamp:firebase.firestore.Timestamp.now()};
                  candidateScheme["candidategp"+gpCount] = {iceCandidates:[], clientTimestamp:firebase.firestore.Timestamp.now()};
                  // iceCandidates = [];

                  for(let i=0;i<iceCandidates.length;i++){
                    if(!iceCandidates[i].added){
                      candidateScheme["candidategp"+gpCount].iceCandidates.push(iceCandidates[i]);
                    }
                    iceCandidates[i].added = true;
                  }

                  offerRef.update(candidateScheme).then(() => {
                    console.log("Candidates successfully saved!");
                    gpCount++;
                  })
                }
                else{
                  if(allCandidatesReceived){
                    console.log("All candidates are posted!");
                    console.log(firebase.firestore.Timestamp.now().toDate());
                    console.log(firebase.firestore.Timestamp.now().toDate().getTime());
                    gpCount = 1;
                    iceCandidates = [];
                    clearInterval(iceCheckInterval);
                  }
                }

              }, 500);
            }
          });

          let postedCandidateStr = '';

          // let answerRef = database.ref('Connections/' + docId + '/Requests/'+requestDocId+'/toClientRTC');
          answerRef = database.ref('Connections/' + docId + '/Data/'+connectionId+'/toClientRTC');
          // setting flat to check if remote description set or not
          let isRemoteDescSet = false;
          // let remoteCandidates = [];
          let candiCount = 0;
          answerRef.on('value', (snapshot) => {
            let answerData = snapshot.val();
            console.log(answerData);

            let hasCandidate = false;
            if(answerData){
              hasCandidate = Object.keys(answerData).toString().indexOf("candidate") != -1 ? true : false;
            }
            
            if(pc && answerData && answerData.answer && !hasCandidate){

              console.log(answerData.serverTimestamp);

              console.log("answer snapshot: " + answerData.type + " : " + connectionId);

              // checking if remote description set or not
              if(!isRemoteDescSet){
                answerData.answer.type = answerData.type.toLowerCase();
                pc.setRemoteDescription(new RTCSessionDescription(answerData.answer));

                // setting flat that remote description is set
                isRemoteDescSet = true;

                for(let i=0; i<answerData.answer.iceCandidates.length; i++){
                  candiCount++;
                  let candidateData = {};
                  candidateData.candidate = answerData.answer.iceCandidates[i].sdp;
                  candidateData.sdpMLineIndex = answerData.answer.iceCandidates[i].sdpMLineIndex;
                  candidateData.sdpMid = answerData.answer.iceCandidates[i].sdpMid;
                  let candidate = new RTCIceCandidate(candidateData);
                  console.log("adding candidate: " + candiCount);
                  pc.addIceCandidate(candidate).then(() => {
                      console.log("Ice candidate added successfully.");
                  })
                  .catch((error) => {
                      console.error("Error adding Ice candidate:", error);
                  });
                  // remoteCandidates.push(candidate);
                }
              }
              
            }

            if(pc && answerData && hasCandidate){
              let keysArr = Object.keys(answerData);
              keysArr = keysArr.filter(key=>{return key.indexOf("candidate") != -1});
              var i=0;
              if(keysArr.length){
                for(;i<keysArr.length; i++){
                  if(postedCandidateStr.indexOf(keysArr[i]) == -1){
                    if(answerData[keysArr[i]] && answerData[keysArr[i]].iceCandidates && answerData[keysArr[i]].iceCandidates.length){
                    // if(answerData[keysArr[i]] && answerData[keysArr[i]].length){
                      postedCandidateStr ? postedCandidateStr = postedCandidateStr + '_' : '';
                      postedCandidateStr = postedCandidateStr + keysArr[i];

                      for(let j=0; j<answerData[keysArr[i]].iceCandidates.length; j++){
                      // for(let j=0; j<answerData[keysArr[i]].length; j++){
                        candiCount++;
                        let candiObj = answerData[keysArr[i]].iceCandidates[j];
                        // let candiObj = answerData[keysArr[i]][j];
                        let candidateData = {};
                        candidateData.candidate = candiObj.sdp;
                        candidateData.sdpMLineIndex = candiObj.sdpMLineIndex;
                        candidateData.sdpMid = candiObj.sdpMid;
                        let candidate = new RTCIceCandidate(candidateData);
                        console.log("adding candidate: " + candiCount);
                        pc.addIceCandidate(candidate).then(() => {
                            console.log("Ice candidate added successfully.");
                        })
                        .catch((error) => {
                            console.error("Error adding Ice candidate:", error);
                        });
                        // remoteCandidates.push(candidate);
                      }
                    }
                    
                  }
                } 
              }
            }

          })

          // setTimeout(() => {
          //   console.log('adding ice: ' + remoteCandidates.length);
          //   for(let index in remoteCandidates){
          //     pc.addIceCandidate(remoteCandidates[index]);
          //   }
          // }, 10000);
        }
      });

      // requestDoc.set(reqObj).then(() => {
      //   console.log("request successfully written!");
      //   console.log("requestID: "+ requestDocId);
        
      //   //watch for acknowledgement and connectionId
      //   requestDocWatcher = requestDoc.onSnapshot((snapshot) => {
      //     let data = snapshot.data();
      //     console.log(data);
      //     if(data.ACK && data.connectionId){

      //       // disposing request watcher
      //       requestDocWatcher();

      //       // write offer to firebase
      //       let offerDoc = firestore.collection('connectionsWebRTC').doc(docId).collection('connections').doc(data.connectionId).collection('toServerDataChannel').doc('clientRequest');

      //       let offerScheme = {
      //         clientTimestamp:firebase.firestore.Timestamp.now().toDate(),
      //         offer: { sdp: offerDescription.sdp, iceCandidates: JSON.parse(JSON.stringify(iceCandidates))},
      //         type: offerDescription.type
      //       }

      //       iceCandidates = [];
  
      //       offerDoc.set(offerScheme).then(() => {
      //         Vue.prototype.$eventHub.$emit('offercreated');
      //         console.log("offer successfully written!");
      //         let gpCount = 1;
      //         let iceCheckInterval = setInterval(() => {
      //           if(allCandidatesReceived){
      //             console.log("All candidates are posted!");
      //             console.log(firebase.firestore.Timestamp.now().toDate());
      //             console.log(firebase.firestore.Timestamp.now().toDate().getTime());
      //             gpCount = 1;
      //             clearInterval(iceCheckInterval);
      //           }
      //           else{
      //             if(iceCandidates.length){

      //               let candidateScheme = {};
      //               candidateScheme["candidategp"+gpCount] = {iceCandidates:JSON.parse(JSON.stringify(iceCandidates)), clientTimestamp:firebase.firestore.Timestamp.now().toDate()};
      //               iceCandidates = [];
      //               offerDoc.update(candidateScheme).then(() => {
      //                 console.log("candidates successfully updated!");
      //                 gpCount++;
      //               })
                
      //             }

      //           }
      //         }, 500);

      //         // clearing time
      //         if(iceCheckInterval){
      //           setTimeout(() => {
      //             if(iceCheckInterval){
      //               clearInterval(iceCheckInterval);
      //             }
      //           }, 2000);
      //         }
              
      //       });

      //       let postedCandidateStr = '';

      //       let answerDoc = firestore.collection('connectionsWebRTC').doc(docId).collection('connections').doc(data.connectionId).collection('toClientDataChannel').doc('serverRequest');

      //       answerSnapshotListner = answerDoc.onSnapshot((snapshot) => {
      //         let answerData = snapshot.data();
      //         let hasCandidate = false;
      //         if(answerData){
      //           hasCandidate = Object.keys(answerData).toString().indexOf("candidate") != -1 ? true : false;
      //         }
              
      //         // if(pc && answerData && answerData.type == 'answer'){
      //         if(pc && answerData && answerData.answer && !hasCandidate){

      //           console.log(answerData.serverTimestamp);

      //           answerData.answer.type = answerData.type.toLowerCase();
      //           pc.setRemoteDescription(new RTCSessionDescription(answerData.answer));

      //           console.log("answer snapshot: " + answerData.type);

      //           for(let i=0; i<answerData.answer.iceCandidates.length; i++){
      //             let candidateData = {};
      //             candidateData.candidate = answerData.answer.iceCandidates[i].sdp;
      //             candidateData.sdpMLineIndex = answerData.answer.iceCandidates[i].sdpMLineIndex;
      //             candidateData.sdpMid = answerData.answer.iceCandidates[i].sdpMid;
      //             let candidate = new RTCIceCandidate(candidateData);
      //             pc.addIceCandidate(candidate);
      //           }
      //         }


      //         if(pc && answerData && hasCandidate){
      //           let keysArr = Object.keys(answerData);
      //           keysArr = keysArr.filter(key=>{return key.indexOf("candidate") != 1});

      //           if(keysArr.length){
      //             for(let i=0; i<keysArr.length; i++){
      //               if(postedCandidateStr.indexOf(keysArr[i]) == -1){
      //                 if(answerData[keysArr[i]] && answerData[keysArr[i]].iceCandidates && answerData[keysArr[i]].iceCandidates.length){
      //                   postedCandidateStr ? postedCandidateStr = postedCandidateStr + '_' : '';
      //                   postedCandidateStr = postedCandidateStr + keysArr[i];

      //                   for(let j=0; j<answerData[keysArr[i]].iceCandidates.length; j++){
      //                     let candiObj = answerData[keysArr[i]].iceCandidates[j];
      //                     let candidateData = {};
      //                     candidateData.candidate = candiObj.sdp;
      //                     candidateData.sdpMLineIndex = candiObj.sdpMLineIndex;
      //                     candidateData.sdpMid = candiObj.sdpMid;
      //                     let candidate = new RTCIceCandidate(candidateData);
      //                     pc.addIceCandidate(candidate);
      //                   }
      //                 }
                      
      //               }
      //             } 
      //           }
      //         }

      //         // if(pc && answerData && (answerData.type == 'answer' || answerData.type == 'candidates') && answerData.dataRequest.iceCandidates && answerData.dataRequest.iceCandidates.length){
      //         // if(pc && answerData && hasCandidate){

      //         //   console.log("answer snapshot: " + answerData.type);
      //         //   console.log(firebase.firestore.Timestamp.now().toDate());
      //         //   console.log(firebase.firestore.Timestamp.now().toDate().getTime());

      //         //   for(let i=0; i<answerData.dataRequest.iceCandidates.length; i++){
      //         //     let candidateData = {};
      //         //     candidateData.candidate = answerData.dataRequest.iceCandidates[i].sdp;
      //         //     candidateData.sdpMLineIndex = answerData.dataRequest.iceCandidates[i].sdpMLineIndex;
      //         //     candidateData.sdpMid = answerData.dataRequest.iceCandidates[i].sdpMid;
      //         //     let candidate = new RTCIceCandidate(candidateData);
      //         //     pc.addIceCandidate(candidate);
      //         //   }
      //         // }

      //       })

      //       // disposing answer snapshot after 2 min
      //       setTimeout(() => {
      //         requestDocWatcher ? requestDocWatcher() : '';
      //         answerSnapshotListner ? answerSnapshotListner() : '';
      //       }, 2 * 60 * 1000);

      //       // let answerWatcher = answerDoc.onSnapshot((snapshot) => {
      //       //   let answerData = snapshot.data();
      //       //   if(answerData.type === 'answer'){
      //       //     answerData.type = answerData.type.toLowerCase();
      //       //     pc.setRemoteDescription(new RTCSessionDescription(answerData));
      //       //   }

      //       //   if(answerData.candidates && answerData.candidates.length){
      //       //     for(let i=0; i<answerData.candidates.length; i++){
      //       //       let candidateData = {};
      //       //       candidateData.candidate = answerData.candidates[i].sdp;
      //       //       candidateData.sdpMLineIndex = answerData.candidates[i].sdpMLineIndex;
      //       //       candidateData.sdpMid = answerData.candidates[i].sdpMid;
      //       //       let candidate = new RTCIceCandidate(candidateData);
      //       //       pc.addIceCandidate(candidate);
      //       //     }
      //       //     answerDocSnapshotListener();
      //       //   }
      //       //   // console.log(snapshot.docs);
      //       //   // setting up remote description


      //       //   // if(snapshot.docs[0].id === 'answer') {
      //       //   //   const answerDescription = snapshot.docs[0].data();
      //       //   //   answerDescription.type = answerDescription.type.toLowerCase();
      //       //   //   pc.setRemoteDescription(new RTCSessionDescription(answerDescription));
      //       //   // }

      //       //   // let docs = snapshot.docs.filter(doc=>{return doc.id != 'answer'});
      //       //   // if(docs.length){
      //       //   //   console.log(docs[docs.length - 1].data());
      //       //   //   let cData = docs[docs.length - 1].data();
      //       //   //   if(cData.type === 'candidate'){
      //       //   //     let candidateData = {};
      //       //   //     candidateData.candidate = cData.sdp;
      //       //   //     candidateData.sdpMLineIndex = cData.sdpMLineIndex;
      //       //   //     candidateData.sdpMid = cData.sdpMid;
      //       //   //     let candidate = new RTCIceCandidate(candidateData);
      //       //   //     pc.addIceCandidate(candidate);
      //       //   //   }
      //       //   // }
      //       // })
      //     }
      //   })

      // });

    }
  // }
  // catch(e){
  //   console.log(e);
  // }

// fn_getConnectionIdFromServer(businessData, function(response){

//     connectionId = response.data.ConnectionID;
//     let connectionNumber = response.data.ConnectionNumber;

//     Vue.prototype.fn_createOffer(docId);
//     fn_sendNotification(connectionId, false, function(response, fnSignOut){
//         if(response.data.Message == "Business not active in Device!"){
//             Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:'This business is currently not logged in mobile device!'});
//             clearTimeout(sendNotificationTimeOut);

//             if(location.pathname == '/login'){
//               fnSignOut();  
//             }
            
//             if(businessData.hasBusinessList){
//               connectionTimeOutCallback ? connectionTimeOutCallback() : '';
//             }

//             if(reconnectDialogObj){
//               reconnectDialogObj.isConnecting = false;
//             }
//         }
//     });

//     // Sending notification once again if connection is not established in first attempt
//     sendNotificationTimeOut = setTimeout(function(){
//         fn_sendNotification(connectionId, true);
//         clearTimeout(sendNotificationTimeOut);

//         notconnectedMessageTimeout = setTimeout(() => {
//             if(!Vue.prototype.isConnected){
//                 if(!navigator.onLine){
//                     Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:"Internet not available"});
//                 }
//                 else{
//                     let msgType = businessData.type == 'self' ? 'Go to BizOps mobile app' : 'Ask client to go BizOps mobile app';
//                     if(connectionNumber){
//                       Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:"<p>Still not connected?</p><ol><li>"+msgType+"</li><li>More > Connection Requests</li><li>Search for <strong>"+connectionNumber+"</strong> and press connect</li></ol>", isVertical:true, timeout:-1});
//                     }
//                 }
//             }
//         }, 1 * 45 * 1000);

//         // Signing out and closing connection after 1 min if web rtc connection not connected
//         unableToConnectTimeout = setTimeout(() => {
//             reconnectDialogObj ? reconnectDialogObj.isConnecting = false : '';
//             let type = null;

//             type = 'prevent_logout';
//             connectionTimeOutCallback ? connectionTimeOutCallback() : '';

//             if(reconnectDialogObj){
//               reconnectDialogObj.isReconnecting ? type = 'prevent_logout' : '';
//               reconnectDialogObj.isConnecting = false;
//             }

//             if(!reconnectDialogObj.isConnecting && !Vue.prototype.isConnected){
//                 Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:'Unable to connect as mobile device is not responding!'});
//                 Vue.prototype.fn_closeConnection(false, type);
//             }
//         }, 1 * 105 * 1000);

//     }, 1 * 15 * 1000);
    
// })

}

// Vue.prototype.fn_createOffer = async function(deviceId){
Vue.prototype.fn_createOffer = async function(docId){

  console.log("connection id " + connectionId);

    // reset, if offer will not create withing 15 seconds
    // let isOfferCreated = false;
    // setTimeout(() => {
    //   if(!isOfferCreated){
    //     if(reconnectDialogObj){
    //       reconnectDialogObj.isConnecting = false;
    //       reconnectDialogObj.isDisconnected = false;
    //     }
    //     Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:'Connection Timeout! Please try again'});
    //     Vue.prototype.fn_closeConnection(false, 'prevent_logout', null, true)
    //   }
    // }, 1 * 15 * 1000);

    // Reference Firestore collections for signaling

    try{
        // checking if user has permission to access connection doc
        // let doc = await firestore.collection('connections').doc(docId).get();
        let doc = await firestore.collection('connectionsWebRTC').doc(docId).get();

        if(doc){
            // const callDoc = firestore.collection('connections').doc(docId).collection('serverConnections').doc(connectionId);
            const offerDoc = firestore.collection('connectionsWebRTC').doc(docId).collection('serverConnections').doc(connectionId).collection('serverDataChannel').doc('offer');

            pc.ondatachannel = receiveChannelCallback;

            sendChannel.onopen = onSendChannelStateChange;
            sendChannel.onclose = onSendChannelStateChange;

            postChannel.onopen = onPostChannelStateChange;
            postChannel.onclose = onPostChannelStateChange;

            pingSendChannel.onopen = onPingSendChannelStateChange;
            pingSendChannel.onclose = onPingSendChannelStateChange;

            fileSendChannel.onopen = onFileSendChannelStateChange;
            fileSendChannel.onclose = onFileSendChannelStateChange;

            // Create offer
            const offerDescription = await pc.createOffer();

            await pc.setLocalDescription(offerDescription);

            // const timestamp = firebase.firestore.Timestamp.now();
            // console.log("Timestamp:", timestamp.toDate());

            const offerCandidate = {
                sdp: offerDescription.sdp,
                type: offerDescription.type,
                // offerTime:timestamp.toDate()
                offerTime:new Date().toISOString()
                // iceCandidates: []
            };

            offerDoc.set(offerCandidate);

            console.log("offer doc "+ new Date().toISOString());

            let iceCount = 1;
            // Get candidates for caller, save to db
            pc.onicecandidate = (event) => {

                if(event.candidate){
                    let data = event.candidate.toJSON();
                    let dataObj = {};
                    dataObj.sdp = data.candidate;
                    dataObj.sdpMLineIndex = data.sdpMLineIndex;
                    dataObj.sdpMid = data.sdpMid;
                    dataObj.type = 'candidate';
                    // let dateTime = firebase.firestore.Timestamp.now();
                    // dataObj.candidateTime = dateTime.toDate();
                    // dataObj.createdTime = new Date().toISOString();
                    const iceDoc = firestore.collection('connectionsWebRTC').doc(docId).collection('serverConnections').doc(connectionId).collection('serverDataChannel').doc('iceCandidate'+iceCount);
                    iceDoc.set(dataObj);
                    // console.log("candidate " + iceCount + " " + new Date().toISOString());
                    iceCount++;
                    // offerCandidate.iceCandidates.push(dataObj);
                }
                
                if(!event.candidate){
                  iceCount = 0; 
                }
            };

            // Listen for remote answer
            // callDocSnapshotListener = offerDoc.onSnapshot((snapshot) => {
            //     const data = snapshot.data();
            //     console.log(data);

                // // setting up remote description
                // if (!pc.currentRemoteDescription && data?.answerCandidate) {
                //     data.answerCandidate.type = data.answerCandidate.type.toLowerCase();
                //     const answerDescription = new RTCSessionDescription(data.answerCandidate);
                //     pc.setRemoteDescription(answerDescription);
                // }

                // if(data?.offerCandidate){
                //     // isOfferCreated = true;
                //     Vue.prototype.$eventHub.$emit('offercreated');
                // }

            // });

            // const serverDataChannel = firestore.collection('connectionsWebRTC').doc(docId).collection('serverConnections').doc(connectionId).collection('clientDataChannel');

            // callDocSnapshotListener = serverDataChannel.onSnapshot((snapshot) => {
            //   // console.log(snapshot.docs);
            //   // setting up remote description
            //   if(snapshot.docs.length === 1 && snapshot.docs[0].id === 'answer') {
            //     const answerDescription = snapshot.docs[0].data();
            //     answerDescription.type = answerDescription.type.toLowerCase();
            //     pc.setRemoteDescription(new RTCSessionDescription(answerDescription));
            //   }

            //   let docs = snapshot.docs.filter(doc=>{return doc.id != 'answer'});
            //   if(docs.length){
            //     console.log(docs[docs.length - 1].data());
            //     let cData = docs[docs.length - 1].data();
            //     if(cData.type === 'candidate'){
            //       let candidateData = {};
            //       candidateData.candidate = cData.sdp;
            //       candidateData.sdpMLineIndex = cData.sdpMLineIndex;
            //       candidateData.sdpMid = cData.sdpMid;
            //       let candidate = new RTCIceCandidate(candidateData);
            //       pc.addIceCandidate(candidate);
            //     }
            //   }
              // let candidateData = {};
              // candidateData.candidate = docs[docs.length - 1].iceCandidates.sdp;
              // candidateData.sdpMLineIndex = data.answerCandidate.iceCandidates[i].sdpMLineIndex;
              // candidateData.sdpMid = data.answerCandidate.iceCandidates[i].sdpMid;

              // let candidate = new RTCIceCandidate(candidateData);
              // pc.addIceCandidate(candidate);
            // })
            // Get candidates for caller, save to db
            // pc.onicecandidate = (event) => {

            //     if(event.candidate){
            //         let data = event.candidate.toJSON();
            //         let dataObj = {};
            //         dataObj.sdp = data.candidate;
            //         dataObj.sdpMLineIndex = data.sdpMLineIndex;
            //         dataObj.sdpMid = data.sdpMid;
            //         offerCandidate.iceCandidates.push(dataObj);
            //     }
                
            //     if(!event.candidate){
            //         callDoc.set({ offerCandidate });
            //         callDoc.update({"offerTime":new Date()});
            //     }
            // };

            // Listen for remote answer
            // callDocSnapshotListener = callDoc.onSnapshot((snapshot) => {
            //     const data = snapshot.data();

            //     // setting up remote description
            //     if (!pc.currentRemoteDescription && data?.answerCandidate) {
            //         data.answerCandidate.type = data.answerCandidate.type.toLowerCase();
            //         const answerDescription = new RTCSessionDescription(data.answerCandidate);
            //         pc.setRemoteDescription(answerDescription);
            //     }

            //     if(data?.offerCandidate){
            //         // isOfferCreated = true;
            //         Vue.prototype.$eventHub.$emit('offercreated');
            //     }

            //     // adding ice candidates
            //     if (data?.offerCandidate && data?.answerCandidate) {
            //         for(let i in data.answerCandidate.iceCandidates){
            //             let candidateData = {};
            //             candidateData.candidate = data.answerCandidate.iceCandidates[i].sdp;
            //             candidateData.sdpMLineIndex = data.answerCandidate.iceCandidates[i].sdpMLineIndex;
            //             candidateData.sdpMid = data.answerCandidate.iceCandidates[i].sdpMid;

            //             let candidate = new RTCIceCandidate(candidateData);
            //             pc.addIceCandidate(candidate);
            //         }
            //     }

            // });
        }
    }
    catch(error){
        console.log(error);
        // console.log(error.code);
        if (error.code === 'permission-denied') {
            Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:'Business details not found!'});
            Vue.prototype.fn_closeConnection(true, null, 1);
        }
    }

  
}

 function fn_clearVariables(){
    // variables
    pc = null;
    sendChannel = null;
    receiveChannel = null;
    connectionId = null;
    // fn_getBusiness = null;
    // fn_sendNotification = null;
    // fn_getConnectionIdFromServer = null;
    // fn_dialogReconnect = null;
    // let serverDeviceId = null;
    pingChannel = null;
    pingSendChannel = null;
    broadcastChannel = null;
    postChannel = null;
    fileSendChannel = null;

    // reconnectDialogObj = null;

    sendNotificationTimeOut = null;
    pingInterval = null;

    qrScanSnapshotListener = null;
    // callDocSnapshotListener = null;
    requestDocWatcher = null;
    // answerSnapshotListner = null;

    notconnectedMessageTimeout = null;
    unableToConnectTimeout = null;

    // let unsubscribeConnectionDoc = null;

    Vue.prototype.isConnected = false;
    Vue.prototype.connectionStatus = null;

    Vue.prototype.preventReconnectDialog = false;

    readyStateOpenCount = 0;

    answerRef = null;
}

Vue.prototype.fn_closeConnection = function(isManualSignOut, type, hideLogoutMsg, preventLogoutCallback){

    sendChannel && sendChannel.readyState == 'open' ?  sendChannel.close() : '';
    receiveChannel && receiveChannel.readyState == 'open' ? receiveChannel.close() : '';
    postChannel && postChannel.readyState == 'open' ? postChannel.close() : '';
    pingSendChannel && pingSendChannel.readyState == 'open' ? pingSendChannel.close() : '';
    fileSendChannel && fileSendChannel.readyState == 'open' ? fileSendChannel.close() : '';
    broadcastChannel && broadcastChannel.readyState == 'open' ? broadcastChannel.close() : '';
    pingChannel && pingChannel.readyState == 'open' ? pingChannel.close() : '';

    if(pc){
        pc.close();
        pc = null;
    }
    else{
        pc = null;
    }
    // pc ? pc.close() : '';

    // callDocSnapshotListener ? callDocSnapshotListener() : '';

    requestDocWatcher ? requestDocWatcher() : '';
    // answerSnapshotListner ? answerSnapshotListner() : '';

    answerRef ? answerRef.off() : '';

    clearTimeout(sendNotificationTimeOut);
    clearTimeout(notconnectedMessageTimeout);
    clearTimeout(unableToConnectTimeout);

    if(type == 'show_reconnect_dialog'){
      if(reconnectDialogObj){
          fn_dialogReconnect(true);
          reconnectDialogObj.isConnecting = false;
          reconnectDialogObj.isDisconnected = false;
          Vue.prototype.$eventHub.$emit('disconnected');
      }
    }

    fn_clearVariables();

    if(type == 'prevent_logout'){
      preventLogoutCallback ? fn_connectionTimeOutCallback() : '';
      return;
    }
    
    if(isManualSignOut){
        Vue.prototype.signOut(response => {
            if(response == "success"){
                clearInterval(pingInterval);
                // initPeerConnection();
                // type 'refresh_login' will come if connection already reached to maximum and because we are already on login page so will just refresh the token
                
                if(!hideLogoutMsg){
                    Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:'You have been logged out!'});
                }

                if(location.pathname == '/login'){
                    Vue.prototype.$eventHub.$emit('refreshLogin');
                }
                else{
                    Vue.prototype.fn_navigate('/login');
                }
            }
        })
    }
    else{
        clearInterval(pingInterval);
        // initPeerConnection();
        if(type == 'switch'){
            Vue.prototype.fn_navigate('/login', {switch:1});
        }
        // else if(location.pathname == '/login'){
        //     Vue.prototype.$eventHub.$emit('refreshLogin');
        // }
    }
}

let bytesArray = [];
let chunkCounter = 0;

// function sendData(data) {
//   if(data.method=='postData'){
//     let chunkSize = 48 * 1000 //48kb

//     let pData = JSON.stringify(data);
//     if(pData.length <= chunkSize){
//       postChannel.send(pData);
//     }
//     else{
//       let chunkPostObj = {};
//       chunkPostObj.name = data.name;
//       chunkPostObj.method = "postData";
//       chunkPostObj.callBackId = data.callBackId;
//       chunkPostObj.connectionId = connectionId;
//       chunkPostObj.requestTime = data.requestTime
//       chunkPostObj.priority = 0;
//       chunkPostObj.eod = 0;

//       postChannel.send(JSON.stringify(chunkPostObj));

//       let dataString = data.dataString;
//       let enc = new TextEncoder(); 
//       let arrBuf = enc.encode(dataString);
      
//       for(let i=0; i<arrBuf.byteLength; i += chunkSize){
//         let datachunk = arrBuf.slice(i, i + chunkSize);
//         postChannel.send(datachunk);
//       }

//       chunkPostObj.eod = 1;
//       postChannel.send(JSON.stringify(chunkPostObj));
      
//     }
//     console.log('Post Data: ' + JSON.stringify(data));
//   }
//   else{
//     sendChannel.send(JSON.stringify(data));
//     console.log('Sent Data: ' + JSON.stringify(data)); 
//   }
// }

// function sendData(data) {
//   if(data.method=='postData'){
//     // let chunkSize = (48 * 1000) - 73 //48kb
//     var chunkSize = 48 * 1000; //48kb

//     let pData = JSON.stringify(data);
//     if(pData.length <= chunkSize){
//       postChannel.send(pData);
//     }
//     else{

//       let chunkPostObj = {};
//       chunkPostObj.name = data.name;
//       chunkPostObj.method = "postData";
//       chunkPostObj.callBackId = data.callBackId;
//       chunkPostObj.connectionId = connectionId;
//       chunkPostObj.requestTime = data.requestTime
//       chunkPostObj.priority = 0;
//       chunkPostObj.eod = 0;

//       postChannel.send(JSON.stringify(chunkPostObj));

//       let dataString = data.dataString;
//       let enc = new TextEncoder(); 
//       let arrBuf = enc.encode(dataString);
      
//       for(let i=0; i<arrBuf.byteLength; i += chunkSize){
//         let datachunk = arrBuf.slice(i, i + chunkSize);
//         postChannel.send(datachunk);
//       }

//       chunkPostObj.eod = 1;
//       postChannel.send(JSON.stringify(chunkPostObj));
      
//     }
//     console.log('Post Data: ' + JSON.stringify(data));
//   }
//   else{
//     sendChannel.send(JSON.stringify(data));
//     console.log('Sent Data: ' + JSON.stringify(data)); 
//   }
// }

function sendData(data, isDataChunk) {
  if(isDataChunk){
    console.log(data);
    postChannel.send(data);
  }
  else{
    if(data.method=='postData'){
      try{
        postChannel.send(JSON.stringify(data));
      }
      catch(e){

        if(reconnectDialogObj && !reconnectDialogObj.isDialogOpen){
          reconnectDialogObj.isConnecting = false;
          reconnectDialogObj.isDisconnected = false;
          fn_dialogReconnect ? fn_dialogReconnect(true) : '';
          Vue.prototype.fn_closeConnection();
        }

        console.log(e);
      }

      console.log('Post Data: ' + JSON.stringify(data));
    }
    else{
      try{
        sendChannel.send(JSON.stringify(data));
      }
      catch(e){
        if(reconnectDialogObj && !reconnectDialogObj.isDialogOpen){
          reconnectDialogObj.isConnecting = false;
          reconnectDialogObj.isDisconnected = false;
          fn_dialogReconnect ? fn_dialogReconnect(true) : '';
          Vue.prototype.fn_closeConnection();
        }
        
        console.log(e);
      }
      
      console.log('Sent Data: ' + JSON.stringify(data)); 
    }
  }
}

function sendPing(){
    if(pingSendChannel.readyState == 'open'){
        let data = {"type":"message", "data":"ping"};
        pingSendChannel.send(JSON.stringify(data));
    }
//   console.log(new Date(), 'Ping Data: ' + JSON.stringify(data)); 
}

function sendFile(dataChunk){
    fileSendChannel.send(dataChunk);
}

function receiveChannelCallback(event) {
  console.log(event);
  console.log('Receive Channel Callback');
  if(event.channel.label === "PingDataChannel"){
    pingChannel = event.channel;
    pingChannel.onmessage = onReceivePingMessageCallback;
    pingChannel.onopen = onReceivePingChannelStateChange;
    pingChannel.onclose = onReceivePingChannelStateChange;
  }
  else if(event.channel.label === "BroadcastDataChannel"){
    broadcastChannel = event.channel;
    broadcastChannel.onmessage = onReceiveBroadcastMessageCallback;
    broadcastChannel.onopen = onReceiveBroadcastChannelStateChange;
    broadcastChannel.onclose = onReceiveBroadcastChannelStateChange;
  }
  else{
    receiveChannel = event.channel;
    receiveChannel.onmessage = onReceiveMessageCallback;
    receiveChannel.onopen = onReceiveChannelStateChange;
    receiveChannel.onclose = onReceiveChannelStateChange;
  }
}

function onReceivePingMessageCallback(event) {
    console.log(new Date());
    console.log('Received Ping Message');
    console.log(event);
}

function onReceivePingChannelStateChange() {
  const readyState = receiveChannel && receiveChannel.readyState ? receiveChannel.readyState : null;
  console.log(`Receive ping channel state is: ${readyState}`);
}

function onReceiveBroadcastMessageCallback(event) {
  console.log('Received Broadcast Message');
  console.log(event);
  let data = event.data ? JSON.parse(event.data) : null;

  if(data){
    if(
        data.type == "SetBusinessDataDrive" ||
        data.type == "RemoveBusinessLogo" ||
        data.type == "UpdatePhoneNumber" ||
        data.type == "UploadLogo" ||
        data.type == "PostUpiId" ||
        data.type == "SetDataDrive" ||
        data.type == "RemoveUpiId" ||
        data.type == "UpdateUpiId" ||
        data.type == "UpdateBusinessDetail" || 
        data.type == "UpdateBusinessDirect" ||
        data.type == "OpeningDate" || 
        data.type == "TransactionSettings"
      ){
      let dataObj = {};
      // dataObj.fnName = fn_getBusiness;
      dataObj.type = data.type;
      Vue.prototype.$eventHub.$emit('UpdateBusinessDetail', dataObj);    
      // fn_getBusiness();
    }
    else if(data.type == 'Product' || data.type == 'deleteProductWithOpening'){
      let dataObj = {type:'Product'};
      Vue.prototype.$eventHub.$emit('GetMasterItems', dataObj);
    }
    else if(data.type == 'Ledger' || data.type == 'deleteAccountWithOpening'){
      let dataObj = {type:'Ledger'};
      Vue.prototype.$eventHub.$emit('GetMasterItems', dataObj);
    }
    else if(data.type == "AccountantAccessRemoved"){
      Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:"<p>Accountant access of this business has been removed.</p>", isVertical:true, timeout:5000});
      Vue.prototype.fn_closeConnection(false, 'switch');
    }
    else if(data.type == "dbOperations"){
      Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:"<p>Database operations are in progress.</p> <p>Please wait for some time and try reconnect again.</p>", isVertical:true, timeout:5000});
      // Vue.prototype.fn_closeConnection(true, null, 1);
      Vue.prototype.fn_closeConnection(false, 'show_reconnect_dialog');
    }
    else if(data.type == "importOperations"){
      Vue.prototype.$eventHub.$emit('snackbarAlert', {msg:"<p>Import operations is running on mobile app.</p> <p>Please wait for some time and try reconnect again.</p>", isVertical:true, timeout:5000});
      Vue.prototype.fn_closeConnection(false, 'show_reconnect_dialog');
    }
  }
  
}

function onReceiveBroadcastChannelStateChange() {
  const readyState = receiveChannel && receiveChannel.readyState ? receiveChannel.readyState : null;
  console.log(`Receive broadcast channel state is: ${readyState}`);
}

// let totalChunkCount = null;

let chunkArr = [];
let totalChunkCount = null;
let currentCallbackId = null;

function onReceiveMessageCallback(event) {
  console.log('Received Message');
  // alert(JSON.parse(event.data).chunkNumber);
  // let testArr = [];
  // testArr.push(JSON.parse(event.data).chunkNumber);
  // console.log(JSON.stringify(testArr));
  // console.log(event);

  if(event.data && event.data!="ping"){
    let response = JSON.parse(event.data);

    if(response.dataByte){

      if(!currentCallbackId || (currentCallbackId && currentCallbackId != response.callBackId)){
        currentCallbackId = response.callBackId;

        chunkArr = [];
        !loadingStatusObject ? loadingStatusObject = {} : '';
        chunkCounter = 0;
        totalChunkCount = null;
      }

      // !loadingStatusObject ? loadingStatusObject = {} : '';
      chunkCounter += 1;
      // response.percent ? loadingStatusObject.value = response.percent : '';
      if(loadingStatusObject){
        response.percent ? loadingStatusObject.value = response.percent : '';
      }
    
      chunkArr.push(response);

      console.log('Total Chunks ' + response.chunkCount);
      console.log('Total Chunk Received ' + chunkCounter);

      
      response.chunkCount ? totalChunkCount = response.chunkCount : '';

      // if(chunkArr.length === response.chunkNumber && Number(response.EOD)){
        // alert(totalCount);
      if(chunkCounter === totalChunkCount && response.callBackId === currentCallbackId){

        chunkCounter = 0;
        totalChunkCount = 0;
        currentCallbackId = null;

        // alert('in');
        
        chunkArr.sort(function(a, b){
          return a.chunkNumber - b.chunkNumber;
        });

        for(let i=0; i<chunkArr.length; i++){
          chunkArr[i].dataByte.forEach(array=>{
            bytesArray.push(array);
          })
        }

        // loadingStatusObject.value = 0;
        loadingStatusObject ? loadingStatusObject.value = 0 : '';
        let arrayBuffer = new Uint8Array(bytesArray);
        console.log(arrayBuffer);

        let responseData = {};
        responseData.status = response.status;
        responseData.callBackId = response.callBackId;
        if(response.type=="file"){
          responseData.data = arrayBuffer;
        }
        else{
          responseData.data = new TextDecoder("utf-8").decode(arrayBuffer);
        }
        
        bytesArray = [];
        chunkArr = [];
        dataCallback(responseData);
        

      }


      // !loadingStatusObject ? loadingStatusObject = {} : '';

      // response.dataByte.forEach(array=>{
      //   bytesArray.push(array);
      // })

      // chunkCounter += 1;

      // response.percent ? loadingStatusObject.value = response.percent : '';

      // if(Number(response.EOD)){
      //   loadingStatusObject.value = 0;
      //   let arrayBuffer = new Uint8Array(bytesArray);
      //   console.log(arrayBuffer);
      //   if(response.type=="file"){
      //     response.data = arrayBuffer;
      //   }
      //   else{
      //     response.data = new TextDecoder("utf-8").decode(arrayBuffer);
      //   }
        
      //   dataCallback(response);
      //   bytesArray = [];
      //   console.log(chunkCounter);
      //   chunkCounter = 0;
      // }

    }
    else{
      loadingStatusObject ? loadingStatusObject.value = 0 : '';
      dataCallback(response);
    }

    // if(response.data){
    //   loadingStatusObject ? loadingStatusObject.value = 0 : '';
    //   dataCallback(response);
    // }
    // else if(response.dataByte){
    //   !loadingStatusObject ? loadingStatusObject = {} : '';

    //   response.dataByte.forEach(array=>{
    //     bytesArray.push(array);
    //   })

    //   chunkCounter += 1;

      
    //   response.chunkCount ? totalChunkCount = response.chunkCount : '';
    //   totalChunkCount ? loadingStatusObject.value = (chunkCounter/totalChunkCount)*100 : '';

    //   if(totalChunkCount){
    //     let status = (chunkCounter/totalChunkCount)*100;
    //     loadingStatusObject.value = Math.round(status);
    //   }

    //   if(Number(response.EOD)){
    //     totalChunkCount = null;
    //     loadingStatusObject.value = 0;
    //     let arrayBuffer = new Uint8Array(bytesArray);
    //     console.log(arrayBuffer);
    //     if(response.type=="file"){
    //       response.data = arrayBuffer;
    //     }
    //     else{
    //       response.data = new TextDecoder("utf-8").decode(arrayBuffer);
    //     }
        
    //     dataCallback(response);
    //     bytesArray = [];
    //     console.log(chunkCounter);
    //     chunkCounter = 0;
    //   }


    // dataCallback(response);
  }
  
}

function onSendChannelStateChange() {
  const readyState = sendChannel && sendChannel.readyState ? sendChannel.readyState : null;
  console.log('Send channel state is: ' + readyState);
  if (readyState === 'open') {
    // dataChannelSend.disabled = false;
    // dataChannelSend.focus();
    // sendButton.disabled = false;
    // closeButton.disabled = false;
  } else {
    // dataChannelSend.disabled = true;
    // sendButton.disabled = true;
    // closeButton.disabled = true;
  }
}

function onPostChannelStateChange() {
  const readyState = postChannel && postChannel.readyState ? postChannel.readyState : null;
  console.log('Post channel state is: ' + readyState);
  if (readyState === 'open') {
    // dataChannelSend.disabled = false;
    // dataChannelSend.focus();
    // sendButton.disabled = false;
    // closeButton.disabled = false;
  } else {
    // dataChannelSend.disabled = true;
    // sendButton.disabled = true;
    // closeButton.disabled = true;
  }
}

function onPingSendChannelStateChange() {
  const readyState = pingSendChannel && pingSendChannel.readyState ? pingSendChannel.readyState : '';
  console.log('Ping Send channel state is: ' + readyState);
  if (readyState === 'open') {
    // dataChannelSend.disabled = false;
    // dataChannelSend.focus();
    // sendButton.disabled = false;
    // closeButton.disabled = false;
  } else {
    // dataChannelSend.disabled = true;
    // sendButton.disabled = true;
    // closeButton.disabled = true;
  }
}

function onFileSendChannelStateChange() {
    const readyState = fileSendChannel && fileSendChannel.readyState ? fileSendChannel.readyState : null;
    console.log('File Send channel state is: ' + readyState);
    if (readyState === 'open') {
      // dataChannelSend.disabled = false;
      // dataChannelSend.focus();
      // sendButton.disabled = false;
      // closeButton.disabled = false;
    } else {
      // dataChannelSend.disabled = true;
      // sendButton.disabled = true;
      // closeButton.disabled = true;
    }
  }

// function connectionClosed(){
//   alert("Connecttion closed!");
// }

// function fn_closeConnection(){
//   alert("Connecttion closed!");
//   sendChannel.close();
//   receiveChannel.close();
//   pc.close();
//   initPeerConnection();
//   router.push("/login");
// }

function onReceiveChannelStateChange() {
  const readyState = receiveChannel && receiveChannel.readyState ? receiveChannel.readyState : null;
  console.log(`Receive channel state is: ${readyState}`);
  if(sendChannel && sendChannel.readyState == "open" && receiveChannel && receiveChannel.readyState == "open"){
    !readyStateOpenCount ? fn_getBusiness(connectionId, connectionType) : '';
    readyStateOpenCount++;
  }

  // if(readyState=="closed"){
  //   if(!reconnectDialogObj.isConnecting && !reconnectDialogObj.isReconnecting){
  //     // console.log('opening reconnect dialog if channel ready state is closed');
  //     // fn_dialogReconnect(true);
  //     console.log('closing connection if ready state is closed');
  //     Vue.prototype.fn_closeConnection();
  //   }
  // }
}

// Auth functions
Vue.prototype.signInWithCustomToken = function(customToken, callback){
  auth.signInWithCustomToken(customToken).then(function(response){
    console.log("Success");
    console.log(response);
    callback(response);
  }).catch(function(error) {
    console.log(JSON.stringify(error));
    callback("error");
  });
}

Vue.prototype.getFirebaseToken = function(callback){
  const user = auth.currentUser;
  if (user) {
    user.getIdToken(true).then(function(idToken) {
      console.log(idToken)
      callback(idToken, user.uid); // Nothing happens. No errors and the function not continues
      // let token = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjMzMDUxMThiZTBmNTZkYzA4NGE0NmExN2RiNzU1NjVkNzY4YmE2ZmUiLCJ0eXAiOiJKV1QifQ.eyJCdXNpbmVzc0lEIjoiNDdiY2JhNzAtMzRiZi00NjQ2LTgwMTItMWE0ZjY0ZjNkYTMyIiwiVGVtcEJ1c2luZXNzSUQiOiIiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vYml6b3Bzc3RhZ2luZyIsImF1ZCI6ImJpem9wc3N0YWdpbmciLCJhdXRoX3RpbWUiOjE3MTc1OTcxMzAsInVzZXJfaWQiOiJjZWFjNzEyNS0zY2QzLTQ0ZDktOGVhNi01YTFjMzZlZjkwNGMiLCJzdWIiOiJjZWFjNzEyNS0zY2QzLTQ0ZDktOGVhNi01YTFjMzZlZjkwNGMiLCJpYXQiOjE3MTc2NTg3OTksImV4cCI6MTcxNzY2MjM5OSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6e30sInNpZ25faW5fcHJvdmlkZXIiOiJjdXN0b20ifX0.Xk3f5CmWNyKNa_r9gi44iKxQ14kK6JF7z_sMes98j43BKrY7kFM-fZfvpHhVRYSBJpBOaRniht0V5KFxlJ8jzLHpPQOVUv5bMl77yD_HGasDNMYjb2_6Fj5Aeu_Bdn-1CfABAnKha3zO0pBckjnalsXWX6QLz-bWnWk5mw7v6lG_9PvopVP4QnpxiNlT3ryY0JidbUu45cXN7ACL5vwv5P3c-SeM1Oqu6il11daOew_REZ_LVl7FbrpqJqfRVs0xwUv9hsXKobtLBYVkjBLAD2yIsDSAUu6LxgyMJCRxXgM5nhhBGYR_Jri0X2JI9o4XPWoolBlioiztj0_ssmkyww";
      // callback(token, user.uid);
    }).catch(function(error) {
      console.log(error+'--'); // Nothing
      callback('error');
    });
  } else {
    // No user is signed in.
    callback("error");
  }
}

Vue.prototype.signOut = function(callback){
  auth.signOut().then(function() {
    // Sign-out successful.
    localStorage.removeItem('last_business_login');
    callback('success');
  }).catch(function(error) {
    // An error happened.
    console.log(error);
    callback('error');
  });
}

Vue.prototype.getUser = function(callback){
  var unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      var uid = user.uid;
      unsubscribe();
      callback(uid);
      // ...
    } else {
      // User is signed out
      // ...
      unsubscribe();
      callback();
    }
  });
}

// Vue.prototype.getCurrentUser = function(){
//   return auth.currentUser.uid;
// }

// Vue.prototype.fn_setRequestOnFB = function(requestId, fn_customToken, fn_expireQrCode, requestKey){
Vue.prototype.fn_setRequestOnFB = function(fn_customToken, fn_expireQrCode, requestKey){
    // let docId = businessData.IsTemporary ? businessData.Business.BusinessID + '-support' : businessData.Business.BusinessID;

    let checkExpiryTimeout = setTimeout(() => {
        if(!Vue.prototype.isConnected){
            // alert('expiring requestId');
            qrScanSnapshotListener ? qrScanSnapshotListener() : '';
            fn_expireQrCode();
        }
    }, 5 * 60 * 1000);

    // let requestDoc = firestore.collection('DesktopLoginRequests').doc(requestId);
    let requestDoc = firestore.collection('DesktopLoginRequests').doc(requestKey);

    // requestDoc.set({isDetailsUpdated:false});

    qrScanSnapshotListener = requestDoc.onSnapshot((snapshot) => {
        let data = snapshot.data();

        console.log(data);

        if(data && data.isDetailsUpdated){
            // alert(data.isDetailsUpdated);
            clearTimeout(checkExpiryTimeout);
            qrScanSnapshotListener();
            fn_customToken();
        }

    })
}

// action interface functions

let loadingStatusObject = null;

Vue.prototype.getData = function(action, itemId, callbackEvents){
    let obj = {};
    obj.name = action;
    obj.itemId = itemId;
    obj.method = "getData";
    obj.callBackId = this.guidWeb();
    //obj.requestId = this.guidWeb();
    pushAction(obj.callBackId, action, callbackEvents);
    sendData(obj);
}

Vue.prototype.postData = function(action, dataModel, callbackEvents){

  let obj = {};
  obj.name = action;
  obj.method = "postData";
  obj.callBackId = this.guidWeb();
  obj.dataString = dataModel ? JSON.stringify(dataModel) : null;

  obj.requestQueueId = null;
  obj.connectionId = connectionId;
  obj.requestTime = new Date();
  obj.priority = 0;
  obj.BusinessID = this.$root.CurrentBusiness.BusinessID;

  // sending eod 1 in case of sending data into chunks
  !dataModel ? obj.eod = 1 : '';


  // {"requestQueueId":"","callBackId":"","connectionId":"","name":"","dataString":"","method":"","filterObject":"","requestTime":"","priority":0}
  //obj.requestId = this.guidWeb();
  pushAction(obj.callBackId, action, callbackEvents);

  sendData(obj);
  // if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
  //   var callbackID = this.guidWeb();
  //   pushAction(callbackID, action, callbackEvents);
  //   console.log(JSON.stringify(dataModel));
  //   AndroidRoutingTest.postData(callbackID, action, JSON.stringify(dataModel));
  // }
  // else{
  //   if(window.webkit && window.webkit.messageHandlers){
  //     window.webkit.messageHandlers.postProductCategory.postMessage(JSON.stringify(dataModel));
  //   }
  //   else{
  //     console.log('messageHandlers not found');
  //   }
  //   callbackEvents.onError('Device api is not defined');
  // }
}

Vue.prototype.getReportData = function(action, itemId, filterObject, loadingStatusObj, callbackEvents){
    loadingStatusObject = loadingStatusObj

    let obj = {};
    obj.name = action;
    obj.itemId = itemId;
    obj.filterObject = JSON.stringify(filterObject);
    obj.method = "getReportData";
    obj.callBackId = this.guidWeb();
    //obj.requestId = this.guidWeb();
    pushAction(obj.callBackId, action, callbackEvents);
    sendData(obj);
}

Vue.prototype.getItems = function(action, filterObject, callbackEvents){
    let obj = {};
    obj.name = action;
    obj.filterObject = JSON.stringify(filterObject);
    obj.method = "getItems";
    obj.callBackId = this.guidWeb();
    //obj.requestId = this.guidWeb();
    pushAction(obj.callBackId, action, callbackEvents);
    sendData(obj);
}

Vue.prototype.getDialogItems = function(action, filterObject, callbackEvents){
    let obj = {};
    obj.name = action;
    obj.filterObject = JSON.stringify(filterObject);
    obj.method = "getDialogItems";
    obj.callBackId = this.guidWeb();
    //obj.requestId = this.guidWeb();
    pushAction(obj.callBackId, action, callbackEvents);
    sendData(obj);
}

Vue.prototype.getFilterData = function(action, itemId, filterObject, loadingStatusObj, callbackEvents){
    loadingStatusObject = loadingStatusObj;

    let obj = {};
    obj.name = action;
    obj.itemId = itemId;
    obj.filterObject = JSON.stringify(filterObject);
    obj.method = "getFilterData";
    obj.callBackId = this.guidWeb();
    //obj.requestId = this.guidWeb();
    pushAction(obj.callBackId, action, callbackEvents);
    sendData(obj);
}

Vue.prototype.postFilterData = function(action, itemId, filterObject, callbackEvents){
  let obj = {};
  obj.name = action;
  obj.itemId = itemId;
  obj.filterObject = JSON.stringify(filterObject);
  obj.method = "postFilterData";
  obj.callBackId = this.guidWeb();
  obj.BusinessID = this.$root.CurrentBusiness.BusinessID;
  //obj.requestId = this.guidWeb();
  pushAction(obj.callBackId, action, callbackEvents);
  sendData(obj);
}

Vue.prototype.deleteData = function(action, itemId, callbackEvents){
  let obj = {};
  obj.name = action;
  obj.itemId = itemId;
  obj.method = "deleteData";
  obj.callBackId = this.guidWeb();
  obj.BusinessID = this.$root.CurrentBusiness.BusinessID;
  pushAction(obj.callBackId, action, callbackEvents);
  sendData(obj);
}

Vue.prototype.getServerData = function(action, filterObject, callbackEvents){
  let obj = {};
  obj.name = action;
  obj.method = "getServerData";
  obj.callBackId = this.guidWeb();
  obj.filterObject = JSON.stringify(filterObject)
  pushAction(obj.callBackId, action, callbackEvents);
  sendData(obj);
}

Vue.prototype.postServerData = function(action, dataModel, callbackEvents){
  let obj = {};
  obj.name = action;
  obj.method = "postServerData";
  obj.callBackId = this.guidWeb();
  obj.dataString = JSON.stringify(dataModel);
  obj.requestQueueId = null;
  obj.connectionId = connectionId;
  obj.requestTime = new Date();
  obj.priority = 0
  pushAction(obj.callBackId, action, callbackEvents);
  sendData(obj);
}

Vue.prototype.sendFileChunks = function(dataChunks, callbackEvents){
    // console.log(dataChunk);
    let callBackId = this.guidWeb();

    let str = callBackId + "_" + connectionId;

    // let connectionDetailsBuf = new ArrayBuffer(73); // 2 bytes for each char
    let connectionDetailsBuf = new ArrayBuffer(147); // 2 bytes for each char
    let bufView = new Uint8Array(connectionDetailsBuf);
    for (var i=0, strLen=str.length; i<strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }

    // var enc = new TextDecoder("utf-8");
    // console.log(enc.decode(connectionDetailsBuf));
    console.log(String.fromCharCode.apply(null, new Uint8Array(connectionDetailsBuf)));

    let uintDataChunk = new Uint8Array(dataChunks);

    let tmp = new Uint8Array(connectionDetailsBuf.byteLength + uintDataChunk.buffer.byteLength);
    tmp.set(new Uint8Array(connectionDetailsBuf), 0);
    tmp.set(new Uint8Array(uintDataChunk.buffer), connectionDetailsBuf.byteLength);
    let fileData = tmp.buffer;

    console.log(String.fromCharCode.apply(null, new Uint8Array(fileData)));
    
    pushAction(callBackId, null, callbackEvents);
    sendFile(fileData);
}

Vue.prototype.sendDataChunks = function(dataChunks, callbackEvents){
  let callBackId = this.guidWeb();

  let str = callBackId + "_" + connectionId;

  // let connectionDetailsBuf = new ArrayBuffer(73); // 2 bytes for each char
  let connectionDetailsBuf = new ArrayBuffer(147); // 2 bytes for each char
  let bufView = new Uint8Array(connectionDetailsBuf);
  for (var i=0, strLen=str.length; i<strLen; i++) {
      bufView[i] = str.charCodeAt(i);
  }

  let uintDataChunk = new Uint8Array(dataChunks);

  let tmp = new Uint8Array(connectionDetailsBuf.byteLength + uintDataChunk.buffer.byteLength);
  tmp.set(new Uint8Array(connectionDetailsBuf), 0);
  tmp.set(new Uint8Array(uintDataChunk.buffer), connectionDetailsBuf.byteLength);
  let dataChunk = tmp.buffer;
  
  pushAction(callBackId, null, callbackEvents);
  sendData(dataChunk, true);
}

function pushAction(callBackId, action, callbackEvents) {
  var actionObj = {};
  actionObj.action = action;
  actionObj.actionId = callBackId;
  actionObj.callbackEvents = callbackEvents;
  actionQueue.push(actionObj);
}

// function dataCallback(callBackId, status){
function dataCallback(dataObj){
    if(Number(dataObj.status)){
        successCallback(dataObj);
    }
    else{
        errorCallback(dataObj);
    }
    
  // if(Number(status)){
  //   successCallback(callBackId);
  // }
  // else{
  //   errorCallback(callBackId);
  // }
}

function successCallback(dataObj){

    let queueIndex = null;

    actionQueue.map((action, index) =>{
      if(action.actionId == dataObj.callBackId){
        queueIndex = index;
        action.callbackEvents.onSuccess(dataObj.data);
      }
    })

    // removing item from action queue
    actionQueue.splice(queueIndex, 1);

    console.log(actionQueue);
}

function errorCallback(dataObj){

    let queueIndex = null;

    actionQueue.map((action, index) =>{
      if(action.actionId == dataObj.callBackId){
        queueIndex = index;
        action.callbackEvents.onError(dataObj.data);
      }
    })

    // removing item from action queue
    actionQueue.splice(queueIndex, 1);

    console.log(actionQueue);

}

// if(RTCPeerConnection){
//     initPeerConnection();
// }

// console.log(Vue.prototype.$data);