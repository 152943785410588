<template>
    <div>

        <v-toolbar :height="56" flat>
            <v-btn icon @click="fn_openDialogConfirmation"><v-icon>arrow_back</v-icon></v-btn>
            <v-toolbar-title>Import Parties</v-toolbar-title>
            <v-spacer></v-spacer>
            <template v-if="importItems">
                <v-menu>
                    <template v-slot:activator="{ on, attrs }">

                        <v-btn capitalize class="mr-4" small text outlined v-bind="attrs" v-on="on">
                            {{ selectedFilter === 1 ? "Valid Parties" : selectedFilter === -1 ? "Invalid Parties" : "All" }}
                            <v-icon right>arrow_drop_down</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense class="pa-0">
                        <v-list-item @click="fn_changeFilter(0)">
                            <v-list-item-title>All</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="fn_changeFilter(1)">
                            <v-list-item-title>Valid Parties</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="fn_changeFilter(-1)">
                            <v-list-item-title>Invalid Parties</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-btn capitalize small color="primary" @click="fn_processBulkImport">
                    <v-icon font18 left>upload_file</v-icon> Import Valid Parties
                </v-btn>
            </template>

            <template v-else>
                <v-btn v-if="uploadedItems" small color="primary" @click="fn_processMappedItems">Continue <v-icon class="ml-2" right>chevron_right</v-icon> </v-btn>
            </template>

        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back" v-scroll.self="onScroll">

            <div v-if="importItems">
                <div class="pa-4 pt-0 white-back">
                    <table style="border-radius: 5px;" class="import-table" fixed-header font-small>
                        <caption style="position: sticky;top: 0px;z-index: 1;background: #fff;text-align: left;">
                            <div class="pt-2 pb-2 font-small left-align" style="position: sticky;left: 16px;background: #fff;display: inline-block;">
                                <div>Valid Parties: {{ importItems.length - invalidItemsCount }}</div>
                                <div redcolor>Invalid Parties: {{ invalidItemsCount }}</div>
                            </div>
                        </caption>
                        <thead style="position: sticky;top: 54px;background: #fff;z-index: 1;">
                            <tr>
                                <th class="pr-2" style="min-width: 48px;"></th>
                                <th class="pr-2" style="min-width: 48px;"></th>
                                <th class="pr-2" style="min-width: 48px;"></th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Mobile Number</th>
                                <th class="text-left">Email</th>
                                <th class="text-left">Party Type</th>
                                <th class="text-left">GST Number</th>

                                <th class="text-left">Billing Address</th>
                                <th class="text-left">Billing City</th>
                                <th class="text-left">Billing State</th>
                                <th class="text-left">Billing Pincode</th>

                                <th class="text-left">Shipping Address</th>
                                <th class="text-left">Shipping City</th>
                                <th class="text-left">Shipping State</th>
                                <th class="text-left">Shipping Pincode</th>

                                <th class="text-left">Opening Balance</th>
                            </tr>
                        </thead>
                        <tbody>

                            <template v-for="(item, itemIndex) in filteredItems">
                                <tr :key="item.Name+itemIndex">
                                    <td class="pr-2"><v-icon :class="[item.hasError ? 'redcolor' : 'greencolor']">{{ item.hasError ? 'error' : 'check_circle' }}</v-icon></td>
                                    <td class="pr-2">
                                        <v-btn icon small class="redcolor" @click="fn_removeItem(item)"><v-icon font20>remove_circle_outline</v-icon></v-btn>
                                    </td>
                                    <td class="pr-2" @click="fn_editItem(item, itemIndex)">
                                        <v-btn icon small><v-icon font20>edit</v-icon></v-btn>
                                    </td>
                                    <td class="contains-edit-btn" :class="{'error-item':item.nameHasError, 'grey-back':item.editName}">
                                        
                                        <template v-if="item.nameHasError">
                                            <v-tooltip bottom :max-width="200" v-if="!item.editName">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span class="cursor-pointer mr-4" v-bind="attrs" v-on="on">
                                                        {{ item.Name }}
                                                    </span>
                                                </template>
                                                <span>Name field shouldn't be blank or duplicate</span>
                                            </v-tooltip>

                                            <template v-else>
                                                <v-text-field :id="item.Name+itemIndex" v-model="itemFieldModel" dense solo outlined class="textbox-in-table" hide-details></v-text-field>
                                                <div right-align class="pt-1">
                                                    <v-btn text capitalize x-small @click="item.editName=false">cancel</v-btn>
                                                    <v-btn text capitalize color="primary" x-small @click="fn_checkItemDuplicate(item)">done</v-btn>
                                                </div>
                                            </template>
                                        </template>

                                        <template v-else>
                                            <span>{{ item.Name }}</span>
                                        </template>

                                    </td>

                                    <td>
                                        <!-- <v-tooltip bottom :max-width="200" v-if="item.mobileNumberHasError">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span class="cursor-pointer" v-bind="attrs" v-on="on">
                                                    {{ item["Mobile Number"] }}
                                                </span>
                                            </template>
                                            <span>Invalid Mobile Number</span>
                                        </v-tooltip> -->
                                        <span>{{ item["Mobile Number"] }}</span>
                                    </td>

                                    <td :class="{'error-item':item.emailHasError}">
                                        <v-tooltip bottom :max-width="200" v-if="item.emailHasError">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span class="cursor-pointer" v-bind="attrs" v-on="on">
                                                    {{ item["Email"] }}
                                                </span>
                                            </template>
                                            <span>Invalid Email</span>
                                        </v-tooltip>
                                        <span v-else>{{ item["Email"] }}</span>
                                    </td>

                                    <!-- <td>{{ item["Party Type"] }}</td> -->

                                    <td :class="{'error-item':item.partyTypeHasError}">
                                        <v-tooltip bottom :max-width="200" v-if="item.partyTypeHasError">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span class="cursor-pointer" v-bind="attrs" v-on="on">
                                                    {{ item["Party Type"] }}
                                                </span>
                                            </template>
                                            <span>Party Type should be either "Customer" or "Supplier"</span>
                                        </v-tooltip>
                                        <span v-else>{{ item["Party Type"] }}</span>
                                    </td>
                                    
                                    <td :class="{'error-item':item.gstNumberHasError}">
                                        <v-tooltip bottom :max-width="200" v-if="item.gstNumberHasError">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span class="cursor-pointer" v-bind="attrs" v-on="on">
                                                    {{ item["GST Number"] }}
                                                </span>
                                            </template>
                                            <span>Invalid GST Number</span>
                                        </v-tooltip>
                                        <span v-else>{{ item["GST Number"] }}</span>
                                    </td>

                                    <td>{{ item["Billing Address"] }}</td>

                                    <td>{{ item["Billing City"] }}</td>

                                    <td>{{ item["Billing State"] }}</td>

                                    <td>{{ item["Billing Pincode"] }}</td>

                                    <td>{{ item["Shipping Address"] }}</td>

                                    <td>{{ item["Shipping City"] }}</td>

                                    <td>{{ item["Shipping State"] }}</td>

                                    <td>{{ item["Shipping Pincode"] }}</td>

                                    <td :class="{'error-item':item.openingHasError}">
                                        <v-tooltip bottom :max-width="200" v-if="item.openingHasError">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span class="cursor-pointer" v-bind="attrs" v-on="on">
                                                    {{ item["Opening Balance"] }}
                                                </span>
                                            </template>
                                            <span>Invalid Opening Balance</span>
                                        </v-tooltip>
                                        <span v-else>{{ item["Opening Balance"] }}</span>
                                    </td>
                                </tr>
                            </template>
                            
                            <!-- <template v-else>
                                <template v-for="item in importItems">
                                    <tr :key="item.Name" v-if="!item.hasError">
                                        <td><v-icon greencolor>check_circle</v-icon></td>
                                        <td>{{ item["Service Item"] }}</td>
                                        <td>{{ item.Name }}</td>
                                        <td>{{ item["Item Code"] }}</td>
                                        <td>{{ item.Charges }}</td>
                                        <td>{{ item["GST Percentage"] }}</td>
                                        <td>{{ item["Cess Percentage"] }}</td>
                                        <td>{{ item["HSN Code"] }}</td>
                                        <td>{{ item.Unit }}</td>
                                        <td>{{ item.Description }}</td>
                                        <td>{{ item["Selling Price"] }}</td>
                                        <td>{{ item["Wholesale Price"] }}</td>
                                        <td>{{ item["Expense Item"] }}</td>
                                        <td>{{ item.Category }}</td>
                                        <td>{{ item.Brand }}</td>
                                        <td>{{ item.Company }}</td>
                                    </tr>
                                </template>
                                
                                <template v-if="!importItems || !importItems.length">
                                    <tr>
                                        <td class="pa-4 error-item" colspan="16" center-align>No valid item found to import</td>
                                    </tr>
                                </template>
                            </template> -->
                            
                        </tbody>
                    </table>
                </div>

                <v-pagination v-model="page" :length="pageLength" :total-visible="10" rounded="circle" v-if="pageLength"></v-pagination>
                
            </div>

            <template v-else>
                <v-layout v-if="!uploadedItems" row wrap ma-0>
                
                    <v-flex xs12 sm6 md6 pa-4 pl-8>
                        <h3>Steps to Import Parties</h3>
                        <div class="light-opacity-color pt-4 pb-1">1. Create an Excel file as per template file</div>
                        <div class="pt-4 pb-2"><v-btn color="primary" outlined small @click="fn_downloadSample">Download Template</v-btn></div>
                        <div class="light-opacity-color pt-4 pb-1">2. Upload created excel file</div>
                        <div class="light-opacity-color pt-4 pb-1">3. Verify all the parties and import</div>
                    </v-flex>

                    <v-flex xs6 sm6 md6 pa-4>
                        <div class="redcolor font-medium" font10>
                            Notes*
                            <ol>
                                <li>Required fields: "Name"</li>
                                <li>Name of parties shouldn't be blank or duplicate.</li>
                            </ol>
                        </div>
                    </v-flex>

                    <v-flex xs12 sm12 md12 center-align pa-8 pt-4>
                        <div class="pa-4 mt-4" style="border: 2px dashed #1976d2;border-radius: 5px;position:relative;">
                            <input type="file" name="itemsheet" accept=".xlsx, .xls" id="itemsheet" @change="fn_convertFile"/>
                            <div class="pa-4"><v-icon color="primary" style="font-size: 72px;">upload_file</v-icon></div>
                            <div class="pa-2 light-opacity-color">Drag & Drop your excel file here</div>
                            <div class="pa-2 light-opacity-color">or</div>
                            <div class="ma-2"><v-btn color="success" @click="fn_selectFile">Upload File</v-btn></div>
                        </div>
                    </v-flex>
                </v-layout>

                <div class="pa-4" white-back v-else>
                    <table style="border-collapse: collapse;">
                        <tbody>
                            <tr class="grey-back">
                                <td class="pa-2 pl-5 pr-5" style="line-height: 20px;"><strong>Default Fields</strong> <br> <span style="font-size: x-small;">(Fields in BizOps App item master)</span></td>
                                <td class="pa-2 pl-5 pr-5" style="line-height: 20px;"><strong>Your Fields</strong> <br> <span style="font-size: x-small;">(Fetched from uploaded sheet)</span> </td>
                            </tr>
                            <tr class="mt-2" v-for="(item, index) in headerFields" :key="index">
                                <td class="pa-3 pl-5 pr-5" style="border-right: 1px #f0f0f0 solid;min-width: 200px;"><div class="mt-1">{{ item.defaultField }}:</div></td>
                                <td class="pa-3 pl-5 pr-5">
                                    <v-select
                                        dense
                                        :placeholder="item.placeholder"
                                        class="pa-0 mt-1"
                                        hide-details
                                        :items="sheetHeaderFields ? sheetHeaderFields : []"
                                        v-model="item.mappedField"
                                    ></v-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </div>

        <dialog-loading ref="ref_dialogLoading" :message="dialogMessage" :value="importStatus"></dialog-loading>

        <dialog-import-item-form ref="ref_dialogImportItemForm"></dialog-import-item-form>

        <dialog-confirmation header="Quit Import Items?" ref="ref_dialogConfirmation" :actionMethod="fn_goBack" :displayView="true" okBtnText="Yes" cancelBtnText="No"></dialog-confirmation>
    </div>
</template>

<script>


// const defaultUnit = require('../../../../config.js').configs.fixedUnits;
// const listIdObject = require('../../../../config.js').configs.mastersPredefinedLists;
// const unitQuantityCodes = require('../../../../config.js').configs.unitQuantityCodes;
const accountGroupIds = require('../../../../config.js').configs.accountGroupIds;

// import dialogImportItems from './dialogs/dialog_items_import'

import dialogImportItemForm from './dialogs/dialog_import_item_form.vue'

import dialogLoading from '../../../dialogs/dialog_loading.vue'

import dialogConfimation from '../../../dialogs/dialog_confirmation.vue'

let headers = [
    {"defaultField":"Name", "mappedField":null, "placeholder":"Select item name"},
    {"defaultField":"Mobile Number", "mappedField":null, "placeholder":"Select mobile number"},
    {"defaultField":"Email", "mappedField":null, "placeholder":"Select email"},
    {"defaultField":"Party Type", "mappedField":null, "placeholder":"Select party type"},
    {"defaultField":"GST Number", "mappedField":null, "placeholder":"Select gst number"},
    {"defaultField":"Billing Address", "mappedField":null, "placeholder":"Select billing address"},
    {"defaultField":"Billing City", "mappedField":null, "placeholder":"Select billing city"},
    {"defaultField":"Billing State", "mappedField":null, "placeholder":"Select billing state"},
    {"defaultField":"Billing Pincode", "mappedField":null, "placeholder":"Select billing pincode"},
    {"defaultField":"Shipping Address", "mappedField":null, "placeholder":"Select shipping address"},
    {"defaultField":"Shipping City", "mappedField":null, "placeholder":"Select shipping city"},
    {"defaultField":"Shipping State", "mappedField":null, "placeholder":"Select shipping state"},
    {"defaultField":"Shipping Pincode", "mappedField":null, "placeholder":"Select shipping pincode"},
    {"defaultField":"Opening Balance", "mappedField":null, "placeholder":"Select opening balance"}
]

    export default{
        data(){
            return{
                processCounter:0,
                importItems:null,
                // itemsWithError:null,
                invalidItemsCount:0,
                validItemsCount:0,
                // showErrorItems:false,
                dialogMessage:"Processing items...",
                importStatus:null,
                selectedFilter:0,
                itemFieldModel:null,
                editItemField:null,
                isUploading:false,
                scrollPosY:0,
                bytesArray:[],
                fileBufferValue:0,
                totalChunksLength:0,
                page:1,
                pageLength:0,
                headerFields:JSON.parse(JSON.stringify(headers)),
                sheetHeaderFields:null,
                uploadedItems:null,
                totalItemsCount:null
            }
        },
        components:{
            // 'dialog-import-items':dialogImportItems,
            'dialog-loading':dialogLoading,
            'dialog-import-item-form':dialogImportItemForm,
            'dialog-confirmation':dialogConfimation
        },
        methods:{
            fn_test: function(){
                // navigator.webkitPersistentStorage.queryUsageAndQuota ( 
                //     function(usedBytes, grantedBytes) {  
                //         console.log('we are using ', usedBytes, ' of ', grantedBytes, 'bytes');
                //     }, 
                //     function(e) { console.log('Error', e);  }
                // );

                var requestedBytes = 1024*1024*280; 

                navigator.webkitPersistentStorage.requestQuota (
                    requestedBytes, function(grantedBytes) {  
                        console.log('we were granted ', grantedBytes, 'bytes');

                    }, function(e) { console.log('Error', e); }
                );
            },

            fn_changeFilter: function(value){
                this.page = 1;
                this.selectedFilter = value
            },

            fn_editItem: function(item, itemIndex){
                this.editItemField = item;
                this.editItemField.itemIndex = ((this.page - 1) * 10) + itemIndex;
                this.$refs['ref_dialogImportItemForm'].openDialogWeb();    
            },

            fn_removeItem: function(item){
                let itemIndex = this.importItems.findIndex(listItem=>listItem.tempId === item.tempId);
                if(itemIndex != -1){
                    this.importItems.splice(itemIndex, 1);
                    item.hasError ? this.invalidItemsCount-- : '';
                }
            },

            fn_editErrorItem: function(item, key, itemIndex){
                if(this.editItemField){
                    this.editItemField.editName ? this.editItemField.editName = false : '';
                    // this.editItemField.editCharges ? this.editItemField.editCharges = false : '';
                }

                this.editItemField = item;
                this.itemFieldModel = item[key];
                // item.editName = true;
                item['edit'+key] = true;
                setTimeout(() => {
                    document.getElementById(item[key]+itemIndex).focus();
                }, 100);
            },

            fn_cancelEdit: function(e, item, key){
                e.stopPropagation();
                item['edit'+key] = false;
            },

            fn_openDialogConfirmation: function(){
                if(this.importItems && this.importItems.length){
                    this.$refs['ref_dialogConfirmation'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            fn_goBack: function(){
                if(this.importItems && this.importItems.length){
                    this.processCounter = 0;
                    this.importItems = null;
                    this.importStatus = null;
                    this.invalidItemsCount = 0;
                    this.validItemsCount = 0;
                    this.page = 1;
                    this.pageLength = 0;
                    this.uploadedItems = null;
                    this.headerFields = JSON.parse(JSON.stringify(headers));
                    // this.fn_addConditionalFields();
                    this.$refs['ref_dialogConfirmation'].closeDialogWeb();
                }
                else{
                    this.$router.go(-1);
                }
            },

            fn_selectFile: function(){
                document.getElementById("itemsheet").value = "";
                document.getElementById("itemsheet").click();
            },

            fn_convertFile: function(event){

                this.uploadedItems = null;
                this.selectedFilter = 0;

                if(this.$root.dialog_reconnect){
                    document.getElementById("itemsheet").value = "";
                    this.$eventHub.$emit('snackbarAlert', {msg:"Connection interrupted. Please try again once reconnected."});
                    return;
                }

                let file = event.target.files[0];
                let fileType = file.name.substring(file.name.lastIndexOf('.')+1);

                if(fileType != 'xlsx' && fileType != 'xls'){
                    document.getElementById("itemsheet").value = "";
                    this.$eventHub.$emit('snackbarAlert', {msg:"File format not supported. Please upload 'xlsx' or 'xls' file"});
                    return;
                }

                let self = this;
                const XLSX = require("xlsx");

                this.dialogMessage = "Processing items...";
                this.$refs['ref_dialogLoading'].openDialogWeb();
                
                var reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = function () {
                    // console.log(reader.result);
                    let data = reader.result;
                    let workbook = XLSX.read(data, {
                        type: 'binary'
                    });
                    // workbook.SheetNames.forEach(sheetName => {
                        
                    // });
                    // let XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets["Items"]);
                    let XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);

                    if(!XL_row_object || !XL_row_object.length){
                        self.$refs['ref_dialogLoading'].closeDialogWeb();
                        self.$eventHub.$emit('snackbarAlert', {msg:"No items found to import"});
                        return;
                    }

                    self.uploadedItems = XL_row_object;
                    
                    let sheetJSON = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })

                    // self.sheetHeaderFields = [];
                    // for(let key in XL_row_object[0]){
                    //     self.sheetHeaderFields.push(key);
                    // }
                    self.sheetHeaderFields = sheetJSON[0];

                    for(let i=0; i<self.headerFields.length; i++){
                        for(let j=0; j<self.sheetHeaderFields.length; j++){
                            if(self.headerFields[i].defaultField === self.sheetHeaderFields[j]){
                                self.headerFields[i].mappedField =  self.sheetHeaderFields[j];
                                // break;
                            }
                        }
                    }

                    self.$refs['ref_dialogLoading'].closeDialogWeb();


                    // let itemNames = [];

                    // for(let i=0; i<XL_row_object.length; i++){

                    //     XL_row_object[i].Name ? XL_row_object[i].Name = XL_row_object[i].Name.toString().trim() : '';
                    //     itemNames.push(XL_row_object[i].Name);

                    //     for(let k=(i+1); k<XL_row_object.length; k++){
                    //         if(XL_row_object[i].Name && XL_row_object[k].Name && XL_row_object[k].Name.toString().trim().toLowerCase() == XL_row_object[i].Name.toString().trim().toLowerCase()){
                    //             XL_row_object[k].nameHasError = true;
                    //             XL_row_object[k].hasError = true;
                    //             itemNames.splice(k, 1);
                    //             break;
                    //         }
                    //     }
                    // }

                    // document.getElementById("itemsheet").value = "";

                    // self.fn_checkDuplicates(itemNames, XL_row_object);
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                    self.$refs['ref_dialogLoading'].closeDialogWeb();
                    document.getElementById("itemsheet").value = "";
                    self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                };
            },

            fn_processMappedItems: function(){
                this.$refs['ref_dialogLoading'].openDialogWeb();

                console.log(JSON.stringify(this.uploadedItems));

                let itemNames = [];

                let t = 0;

                let self = this;
                function processInBatch(){
                    let endIndex = Math.min(t + 50, self.uploadedItems.length);
                    for(; t<endIndex; t++){
                        let itemObj = self.uploadedItems[t];

                        itemObj.tempId = self.guidWeb();

                        // adding keys to itemObj if not exist
                        for(let z=0;z<self.headerFields.length;z++){
                            if(!itemObj[self.headerFields[z].defaultField]){
                                itemObj[self.headerFields[z].defaultField] = null;
                            }
                        }

                        for(let u=0;u<self.headerFields.length;u++){
                            // for(let key in itemObj){
                            //     if(self.headerFields[u].mappedField == key){
                            //         itemObj[self.headerFields[u].defaultField] = itemObj[key];
                            //     }
                            // }
                             // assigning to temp variable
                            if(self.headerFields[u].mappedField != self.headerFields[u].defaultField){
                                for(let key in itemObj){
                                    if(self.headerFields[u].mappedField == key){
                                        itemObj['temp_'+self.headerFields[u].defaultField] = itemObj[key];
                                    }
                                }
                            }
                        }

                        // swapping and removing temp variable
                        for(let key2 in itemObj){
                            if(key2.indexOf('temp_') != -1){
                                let objKey = key2.substring(key2.indexOf('_') + 1);
                                itemObj[objKey] = itemObj[key2];
                                delete itemObj[key2];
                            }
                        }
                        
                        // finding duplicate ledger names in the sheet
                        self.uploadedItems[t].Name ? self.uploadedItems[t].Name = self.uploadedItems[t].Name.toString().trim() : '';
                        itemNames.push(self.uploadedItems[t].Name);

                        // for(let k=(t+1); k<self.uploadedItems.length; k++){
                        //     if(self.uploadedItems[t].Name && self.uploadedItems[k].Name && self.uploadedItems[k].Name.toString().trim().toLowerCase() == self.uploadedItems[t].Name.toString().trim().toLowerCase()){
                        //         self.uploadedItems[k].nameHasError = true;
                        //         self.uploadedItems[k].hasError = true;
                        //         itemNames.splice(k, 1);
                        //         break;
                        //     }
                        // }
                    }

                    if (t < self.uploadedItems.length) {
                        // Schedule the next batch after a delay
                        setTimeout(processInBatch, 10);
                    }
                    else{
                        self.uploadedItems = self.uploadedItems.filter(obj=>{
                            return Object.keys(obj).length;
                        })

                        console.log(JSON.stringify(self.uploadedItems));

                        for(let j=0; j<self.uploadedItems.length-1; j++){
                            for(let k=j+1; k<self.uploadedItems.length; k++){
                                if(self.uploadedItems[j].Name && self.uploadedItems[k].Name && (self.uploadedItems[j].Name.toString().trim().toLowerCase() == self.uploadedItems[k].Name.toString().trim().toLowerCase())){
                                    self.uploadedItems[k].nameHasError = true;
                                    self.uploadedItems[k].hasError = true;
                                    // itemNames.splice(k, 1);
                                    // break;
                                }
                            }
                        }

                        self.fn_checkDuplicates(itemNames, self.uploadedItems);
                    }
                }

                // Start processing the first batch
                processInBatch();
            },

            // fn_processMappedItems: function(){
            //     this.$refs['ref_dialogLoading'].openDialogWeb();

            //     console.log(JSON.stringify(this.uploadedItems));

            //     for(let t=0; t<this.uploadedItems.length; t++){
            //         let itemObj = this.uploadedItems[t];

            //         for(let u=0;u<this.headerFields.length;u++){

            //             for(let key in itemObj){
            //                 if(this.headerFields[u].mappedField == key){
            //                     itemObj[this.headerFields[u].defaultField] = itemObj[key];
            //                 }
            //             }
            //         }
            //     }

            //     this.uploadedItems = this.uploadedItems.filter(obj=>{
            //         return Object.keys(obj).length;
            //     })

            //     console.log(JSON.stringify(this.uploadedItems));

            //     let itemNames = [];

            //     // finding duplicate items in uploaded sheet
            //     for(let i=0; i<this.uploadedItems.length; i++){

            //         this.uploadedItems[i].Name ? this.uploadedItems[i].Name = this.uploadedItems[i].Name.toString().trim() : '';
            //         itemNames.push(this.uploadedItems[i].Name);

            //         for(let k=(i+1); k<this.uploadedItems.length; k++){
            //             if(this.uploadedItems[i].Name && this.uploadedItems[k].Name && this.uploadedItems[k].Name.toString().trim().toLowerCase() == this.uploadedItems[i].Name.toString().trim().toLowerCase()){
            //                 this.uploadedItems[k].nameHasError = true;
            //                 this.uploadedItems[k].hasError = true;
            //                 itemNames.splice(k, 1);
            //                 break;
            //             }
            //         }
            //     }

            //     this.fn_checkDuplicates(itemNames, this.uploadedItems);
            // },

            fn_checkItemDuplicate: function(item){
                if(!item.Name){
                    this.$eventHub.$emit('snackbarAlert', {msg:"Item name should not be blank"});
                    return;
                }

                let filterObj = {};
                filterObj.importItems = [this.itemFieldModel];

                let self = this;
                this.getFilterData('ValidateImportItems', 'Ledger', filterObj, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let duplicateItems = JSON.parse(response);
                            if(!duplicateItems.length){
                                item.hasError = false;
                                item.nameHasError = false;
                                item.editName = false;
                                item.Name = self.itemFieldModel;
                                self.invalidItemsCount--;
                            }
                            else{
                                self.$eventHub.$emit('snackbarAlert', {msg:"Item with name "+item.Name+" already exist"});
                            }
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.$refs['ref_dialogLoading'].closeDialogWeb();
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                        }
                    }
                );
            },

            fn_validateCharges: function(e, item){
                e.stopPropagation();
                if(!isNaN(Number(this.itemFieldModel))){
                    item.hasError = false;
                    item.chargesHasError = false;
                    item.editCharges = false;
                    item.Charges = Number(this.itemFieldModel);
                    this.invalidItemsCount--;
                }
                else{
                    this.$eventHub.$emit('snackbarAlert', {msg:"Charges field should be numerical"});
                }
            },

            fn_checkDuplicates: function(itemNames, importItems, responseItems){

                // sending maximum 500 items at a time
                let filterObj = {};
                filterObj.importItems = itemNames.slice(0, 500);

                let self = this;
                this.getFilterData('ValidateImportItems', 'Ledger', filterObj, null,
                    {
                        onSuccess: function(response){
                            console.log(response);

                            let items = [];
                            if(responseItems && responseItems.length){
                                items = JSON.parse(response) ?  responseItems.concat(JSON.parse(response)) : '';
                            }
                            else{
                                items = JSON.parse(response);
                            }

                            // removing verified items from the list
                            itemNames.splice(0, filterObj.importItems.length);

                            if(itemNames.length){
                                self.fn_checkDuplicates(itemNames, importItems, items);
                            }
                            else{
                                // response ? self.fn_processAfterValidate(response, importItems) : '';
                                self.fn_processAfterValidate(items, importItems);
                                self.$refs['ref_dialogLoading'].closeDialogWeb();
                            }

                        }, 
                        onError: function(error){
                            self.$refs['ref_dialogLoading'].closeDialogWeb();
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                            console.log(error);
                        }
                    }
                );
            },

            fn_processAfterValidate: function(response, importItems, isReconnected){
                // let items = JSON.parse(response) ? JSON.parse(response) : [];
                let items = response ? response : [];

                this.invalidItemsCount = 0;
                this.page = 1;
                this.pageLength = 0;

                for(let i=0; i<items.length; i++){
                    for(let k=0; k<importItems.length; k++){
                        if(items[i].name === importItems[k].Name){
                            importItems[k].nameHasError = true;
                            importItems[k].hasError = true;
                            // appending to check items have already been upload before connection break
                            isReconnected ? importItems[k].onReconnect = true : '';
                            break;
                        }
                    }
                }

                for(let j=0; j<importItems.length; j++){

                    importItems[j]["Name"] ? importItems[j]["Name"] = importItems[j]["Name"].toString().trim() : '';
                    importItems[j]["Mobile Number"] ? importItems[j]["Mobile Number"] = importItems[j]["Mobile Number"].toString().trim() : '';
                    importItems[j]["Email"] ? importItems[j]["Email"] = importItems[j]["Email"].toString().trim() : '';
                    importItems[j]["Party Type"] ? importItems[j]["Party Type"] = importItems[j]["Party Type"].toString().trim() : '';
                    importItems[j]["GST Number"] ? importItems[j]["GST Number"] = importItems[j]["GST Number"].toString().trim() : '';
                    importItems[j]["Billing Address"] ? importItems[j]["Billing Address"] = importItems[j]["Billing Address"].toString().trim() : '';
                    importItems[j]["Billing City"] ? importItems[j]["Billing City"] = importItems[j]["Billing City"].toString().trim() : '';
                    importItems[j]["Billing State"] ? importItems[j]["Billing State"] = importItems[j]["Billing State"].toString().trim() : '';
                    importItems[j]["Billing Pincode"] ? importItems[j]["Billing Pincode"] = importItems[j]["Billing Pincode"].toString().trim() : '';
                    importItems[j]["Shipping Address"] ? importItems[j]["Shipping Address"] = importItems[j]["Shipping Address"].toString().trim() : '';
                    importItems[j]["Shipping State"] ? importItems[j]["Shipping State"] = importItems[j]["Shipping State"].toString().trim() : '';
                    importItems[j]["Shipping Pincode"] ? importItems[j]["Shipping Pincode"] = importItems[j]["Shipping Pincode"].toString().trim() : '';
                    importItems[j]["Opening Balance"] ? importItems[j]["Opening Balance"] = importItems[j]["Opening Balance"].toString().trim() : '';

                    // setting up value null if value blank string or undefined
                    !importItems[j]["Mobile Number"] ? importItems[j]["Mobile Number"] = null : '';
                    !importItems[j]["Email"] ? importItems[j]["Email"] = null : '';
                    !importItems[j]["GST Number"] ? importItems[j]["GST Number"] = null : '';
                    !importItems[j]["Party Type"] ? importItems[j]["Party Type"] = null : '';
                    !importItems[j]["Billing Address"] ? importItems[j]["Billing Address"] = null : '';
                    !importItems[j]["Billing City"] ? importItems[j]["Billing City"] = null : '';
                    !importItems[j]["Billing State"] ? importItems[j]["Billing State"] = null : '';
                    !importItems[j]["Billing Pincode"] ? importItems[j]["Billing Pincode"] = null : '';
                    !importItems[j]["Shipping Address"] ? importItems[j]["Shipping Address"] = null : '';
                    !importItems[j]["Shipping City"] ? importItems[j]["Shipping City"] = null : '';
                    !importItems[j]["Shipping State"] ? importItems[j]["Shipping State"] = null : '';
                    !importItems[j]["Shipping Pincode"] ? importItems[j]["Shipping Pincode"] = null : '';
                    !importItems[j]["Opening Balance"] ? importItems[j]["Opening Balance"] = null : '';


                    importItems[j].isImported = false;

                    if(!importItems[j].Name){
                        importItems[j].nameHasError = true;
                        importItems[j].hasError = true;
                    }

                    if(!importItems[j]["Party Type"] || (importItems[j]["Party Type"]?.toLowerCase() != 'customer' && importItems[j]["Party Type"]?.toLowerCase() != 'supplier')){
                        importItems[j].partyTypeHasError = true;
                        importItems[j].hasError = true;
                    }

                    // if(importItems[j]["Mobile Number"] && importItems[j]["Mobile Number"].length != 10){
                    //     importItems[j].mobileNumberHasError = true;
                    //     importItems[j].hasError = true;
                    // }

                    if(importItems[j]["Email"]){
                        let emailRegex = /\S+@\S+\.\S+/;
                        if(!emailRegex.test(importItems[j]["Email"])){
                            importItems[j].emailHasError = true;
                            importItems[j].hasError = true;
                        }
                    }

                    if(importItems[j]["GST Number"]){
                        var gstNumberRegex = new RegExp("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$");
                        if(!gstNumberRegex.test(importItems[j]["GST Number"])){
                            importItems[j].gstNumberHasError = true;
                            importItems[j].hasError = true;
                        }
                    }

                    if(importItems[j]["Opening Balance"]){
                        if(isNaN(importItems[j]["Opening Balance"])){
                            importItems[j]["Opening Balance"] = null;
                        }
                        else{
                            importItems[j]["Opening Balance"] = this.roundUpto2Decimals(importItems[j]["Opening Balance"]);
                        }
                    }

                    if(importItems[j].hasError){
                        this.invalidItemsCount++;
                    }

                    if(importItems[j].nameHasError){
                        importItems[j].editName = false;
                    }
                }

                this.importItems = importItems;

                if(isReconnected){
                    this.invalidItemsCount = 0;
                    this.page = 1;
                    this.pageLength = 0;

                    let lengthBeforeFilter = this.importItems.length;
                    this.importItems = this.importItems.filter(item=>{
                        return !item.onReconnect;
                    });

                    if(this.importItems.length){
                        if(this.importItems.length < lengthBeforeFilter){
                            for(let i=0; i<this.importItems.length; i++){
                                this.importItems[i].hasError ? this.invalidItemsCount++ : '';
                            }
                            // this.$eventHub.$emit('snackbarAlert', {msg:items.length + " items have been imported successfully."});
                            if(this.importItems.length > this.invalidItemsCount){
                                // uploading remaining items
                                this.fn_processBulkImport();
                            }
                            else{
                                this.$eventHub.$emit('snackbarAlert', {msg:items.length + " parties have been imported successfully."});
                            }
                        }
                    }
                    else{
                        this.$eventHub.$emit('snackbarAlert', {msg:"All valid parties have been imported successfully."});
                    }
                }
            },

            // fn_processItems: function(){
            //     let postItems = this.importItems.filter(item=>{
            //         return !item.hasError;
            //     });
            //     this.fn_processTaxCategories(postItems);
            // },

            // fn_processTaxCategories: function(jsonData){
            //     this.isUploading = true;
            //     this.dialogMessage = "Importing items...";
            //     this.$refs['ref_dialogLoading'].openDialogWeb();

            //     if(this.processCounter < jsonData.length){

            //         let item = jsonData[this.processCounter];

            //         if(item["GST Percentage"]){
            //             let cessValue = item["Cess Percentage"] != undefined ? item["Cess Percentage"] : null;
            //             this.fn_getTaxCategoryDetails(item["GST Percentage"], cessValue, taxCategoryData => {
            //                 if(taxCategoryData){
            //                     item.taxCategoryId = taxCategoryData.taxCategoryId;
            //                     item.taxCategoryName = taxCategoryData.name;
            //                     this.fn_processItemCategories(jsonData, item);
            //                 }
            //                 else{
            //                     let taxCategoryObj = {};
            //                     taxCategoryObj.taxCategoryId = this.guid();
            //                     taxCategoryObj.name = item["GST Percentage"] + "% GST";
            //                     taxCategoryObj.taxValue = item["GST Percentage"];
            //                     taxCategoryObj.isCessApplicable = false;
            //                     taxCategoryObj.cessValue = null;
            //                     if(item["Cess Percentage"] != undefined){
            //                         taxCategoryObj.isCessApplicable = true;
            //                         taxCategoryObj.cessValue = item["Cess Percentage"]
            //                         taxCategoryObj.name = item["GST Percentage"] + "% GST + Cess " + item["Cess Percentage"] + "%";
            //                     }
            //                     taxCategoryObj.isSystem = 0;
            //                     taxCategoryObj.createdDate = new Date();
            //                     taxCategoryObj.modifiedDate = new Date();

            //                     this.fn_saveTaxCategory(taxCategoryObj, response=>{
            //                         console.log(response);
            //                         if(response != 'error'){
            //                             item.taxCategoryId = taxCategoryObj.taxCategoryId;
            //                             item.taxCategoryName = taxCategoryObj.name;
            //                             this.fn_processItemCategories(jsonData, item);
            //                         }
            //                     })
            //                 }                           
            //             });
            //         }
            //         else{
            //             this.fn_processItemCategories(jsonData, item);
            //         }
            //     }
            //     else{
            //         this.processCounter = 0;
            //         // this.importItems = null;
            //         this.importStatus = null;
            //         this.$eventHub.$emit('snackbarAlert', {msg:this.importItems.length - this.invalidItemsCount+" valid items have been imported successfully"});

            //         if((this.importItems.length - this.invalidItemsCount) > 0){
            //             this.importItems = this.importItems.filter(item=>{
            //                 return item.hasError;
            //             })
            //         }
            //         else{
            //             this.importItems = null;
            //         }
                    
            //         this.isUploading = false;
            //         this.$refs['ref_dialogLoading'].closeDialogWeb();
            //     }
            // },

            // fn_processItemCategories: function(jsonData, item){
            //     if(item.Category){
            //         this.fn_getProductCategoryDetails(item.Category, productCategoryData => {
            //             if(productCategoryData){
            //                 item.productCategoryId = productCategoryData.productCategoryId;
            //                 item.productCategoryName = productCategoryData.name;
            //                 this.fn_processUnits(jsonData, item);
            //             }
            //             else{
            //                 let productCategoryObj = {};
            //                 productCategoryObj.productCategoryId = this.guid();
            //                 productCategoryObj.name = item.Category;
            //                 productCategoryObj.isSystem = 0;
            //                 productCategoryObj.createdDate = new Date();
            //                 productCategoryObj.modifiedDate = new Date();

            //                 this.fn_saveProductCategory(productCategoryObj, response=>{
            //                     console.log(response);
            //                     if(response != 'error'){
            //                         item.productCategoryId = productCategoryObj.productCategoryId;
            //                         item.productCategoryName = productCategoryObj.name;
            //                         this.fn_processUnits(jsonData, item);
            //                     }
            //                 })
            //             }                           
            //         });
            //     }
            //     else{
            //         this.fn_processUnits(jsonData, item);
            //     }
            // },

            // fn_processUnits: function(jsonData, item){
            //     if(item.Unit){
            //         this.fn_getUnitDetails(item.Unit, (unitData, uqcId) => {
            //             if(unitData){
            //                 item.unitId = unitData.unitId;
            //                 item.unitName = unitData.name;
            //                 this.fn_processBrand(jsonData, item);
            //             }
            //             else{
            //                 let unitObj = {};
            //                 unitObj.unitId = this.guid();
            //                 unitObj.name = item.Unit
            //                 unitObj.baseUnitId = unitObj.unitId;
            //                 unitObj.baseUnitName = null;
            //                 unitObj.conversionQuantity = null;
            //                 unitObj.conversionType = 101;
            //                 unitObj.createdDate = new Date();
            //                 unitObj.modifiedDate = new Date();
            //                 unitObj.isSystem = 0;
            //                 unitObj.shortName = null;
            //                 unitObj.status = 1;
            //                 unitObj.unitQuantityCode = uqcId;

            //                 this.fn_saveUnit(unitObj, response=>{
            //                     console.log(response);
            //                     if(response != 'error'){
            //                         item.unitId = unitObj.unitId;
            //                         item.unitName = unitObj.name;
            //                         this.fn_processBrand(jsonData, item);
            //                     }
            //                 })
            //             }                           
            //         });
            //     }
            //     else{
            //         // item.unitId = item["Service Item"] ? defaultUnit.nos.id : defaultUnit.pieces.id,
            //         // item.unitName = item["Service Item"] ? defaultUnit.nos.name : defaultUnit.pieces.name,
            //         item.unitId = defaultUnit.pieces.id,
            //         item.unitName = defaultUnit.pieces.name,
            //         this.fn_processBrand(jsonData, item);
            //     }
            // },

            // fn_processBrand: function(jsonData, item){
            //     if(item.Brand){
            //         this.fn_getListItemDetails(item.Brand, listIdObject.brandListGUID, brandData => {
            //             if(brandData){
            //                 item.brandId = brandData.listItemsId;
            //                 item.brandName = brandData.name;
            //                 this.fn_processCompany(jsonData, item);
            //             }
            //             else{
            //                 let brandObj = {};
            //                 brandObj.createdDate = new Date();
            //                 brandObj.description = null;
            //                 brandObj.groupTypeId = 801;
            //                 brandObj.isSystem = 0;
            //                 brandObj.listId = listIdObject.brandListGUID;
            //                 brandObj.listItemsId = this.guid();
            //                 brandObj.listTitle = null;
            //                 brandObj.name = item.Brand;
            //                 brandObj.title = null;

            //                 this.fn_saveListItem(brandObj, response=>{
            //                     console.log(response);
            //                     if(response != 'error'){
            //                         item.brandId = brandObj.listItemsId;
            //                         item.brandName = brandObj.name;
            //                         this.fn_processCompany(jsonData, item);
            //                     }
            //                 })
            //             }                           
            //         });
            //     }
            //     else{
            //         this.fn_processCompany(jsonData, item);
            //     }
            // },

            // fn_processCompany: function(jsonData, item){
            //     if(item.Company){
            //         this.fn_getListItemDetails(item.Company, listIdObject.companyListGUID, companyData => {
            //             if(companyData){
            //                 item.companyId = companyData.listItemsId;
            //                 item.companyName = companyData.name;
            //                 this.fn_postItem(item, jsonData);
            //             }
            //             else{
            //                 let companyObj = {};
            //                 companyObj.createdDate = new Date();
            //                 companyObj.description = null;
            //                 companyObj.groupTypeId = 801;
            //                 companyObj.isSystem = 0;
            //                 companyObj.listId = listIdObject.companyListGUID;
            //                 companyObj.listItemsId = this.guid();
            //                 companyObj.listTitle = null;
            //                 companyObj.name = item.Company;
            //                 companyObj.title = null;

            //                 this.fn_saveListItem(companyObj, response=>{
            //                     console.log(response);
            //                     if(response != 'error'){
            //                         item.companyId = companyObj.listItemsId;
            //                         item.companyName = companyObj.name;
            //                         this.fn_postItem(item, jsonData);
            //                     }
            //                 })
            //             }                           
            //         });
            //     }
            //     else{
            //         this.fn_postItem(item, jsonData);
            //     }
            // },

            // fn_processItems: function(jsonData){
            //     if(this.processCounter < jsonData.length){
            //         let item = jsonData[this.processCounter];

            //         if(item["GST Percentage"]){
            //             this.fn_getTaxCategoryDetails(item["GST Percentage"], taxCategoryData => {
            //                 if(taxCategoryData){
            //                     item.taxCategoryId = taxCategoryData.taxCategoryId;
            //                     item.taxCategoryName = taxCategoryData.name;
            //                     this.fn_postItem(item, jsonData);
            //                 }
            //                 else{
            //                     let taxCategoryObj = {};
            //                     taxCategoryObj.taxCategoryId = this.guid();
            //                     taxCategoryObj.name = item["GST Percentage"] + "% GST";
            //                     taxCategoryObj.taxValue = item["GST Percentage"];
            //                     taxCategoryObj.isCessApplicable = false;
            //                     taxCategoryObj.cessValue = null;
            //                     if(item["Cess Percentage"] != undefined){
            //                         taxCategoryObj.isCessApplicable = true;
            //                         taxCategoryObj.cessValue = item["Cess Percentage"]
            //                         taxCategoryObj.name = item["GST Percentage"] + "% GST + Cess" + item["Cess Percentage"] + "%";
            //                     }
            //                     taxCategoryObj.isSystem = 0;
            //                     taxCategoryObj.createdDate = new Date();
            //                     taxCategoryObj.modifiedDate = new Date();

            //                     this.fn_saveTaxCategory(taxCategoryObj, response=>{
            //                         console.log(response);
            //                         if(response != 'error'){
            //                             item.taxCategoryId = taxCategoryObj.taxCategoryId;
            //                             item.taxCategoryName = taxCategoryObj.name;
            //                             this.fn_postItem(item, jsonData);
            //                         }
            //                     })
            //                 }                           
            //             });
            //         }
            //         else{
            //             this.fn_postItem(item, jsonData);
            //         }
            //     }
            //     else{
            //         console.log('no more items to import');
            //         return;
            //     }
            // },

            fn_processBulkImport: function(){

                if(!this.$refs['ref_dialogLoading'].dialog_loading){
                    this.dialogMessage = "Please wait...";
                    this.$refs['ref_dialogLoading'].openDialogWeb();
                    this.importStatus = 0;
                    this.totalItemsCount = this.importItems.length;
                }
                
                let postItems = this.importItems.filter(item=>{
                    return !item.hasError;
                });

                this.validItemsCount = postItems.length;

                // this.$root.fn_generateUniqueIds(uniqueIdsCount, duplicates=>{
                //     console.log('duplicate_items_'+duplicates);
                //     this.dialogMessage = "Please wait...";
                //     this.$refs['ref_dialogLoading'].openDialogWeb();
                //     this.fn_postImportData(postItems);
                // })
                this.fn_checkUniqueIdsLength(postItems);
            },

            fn_checkUniqueIdsLength: function(postItems){
                // number of unique id generation depends on how much guid's we're using in a post item
                // like in this case we are generating approx 8 guids for a single post with opening transaction 
                if(this.$root.uniqueIds.length < (postItems.length * 10)){
                    // generating max 500 unique ids at a time
                    this.$root.fn_generateUniqueIds(response=>{
                        if(response == 'error'){
                            this.$refs['ref_dialogLoading'].closeDialogWeb();
                            this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                        }
                        else{
                            this.fn_checkUniqueIdsLength(postItems);
                        }
                        // console.log('duplicate_items_'+duplicates);
                    })
                }
                else{
                    this.isUploading = true;
                    // this.dialogMessage = "Please wait...";
                    // this.$refs['ref_dialogLoading'].openDialogWeb();
                    this.fn_postImportData(postItems);
                }
            },

            fn_postImportData: function(jsonData){
                let postData = {ledgerItems:[]};

                let accountName = [];
                // let productName = [];
                // let categoryName = [];
                // let brandName = [];
                // let companyName = [];
                // let unitName = [];

                for(let i=0; i<jsonData.length; i++){
                    let item = jsonData[i];
                    let itemObj = {};
                    itemObj.name = item["Name"];
                    itemObj.accountId = this.guid();
                    if(item["Party Type"]?.toLowerCase() == 'supplier'){
                        itemObj.groupTypeId = 102;
                        itemObj.accountGroupId = accountGroupIds.creditors.id;
                    }
                    else if(item["Party Type"]?.toLowerCase() == 'customer'){
                        itemObj.groupTypeId = 101;
                        itemObj.accountGroupId = accountGroupIds.debtors.id;
                    }
                    itemObj.primaryMobileNumber = item["Mobile Number"];
                    itemObj.email = item["Email"];

                    if(item["GST Number"]){
                        itemObj.gstType = 101;
                        itemObj.gstNumber = item["GST Number"];
                        itemObj.stateCode = itemObj.gstNumber.substring(0, 2);
                    }
                    else{
                        // consumer
                        itemObj.gstType = 104;
                        itemObj.gstNumber = null;
                    }

                    itemObj.placeOfSupplyId = null;
                    itemObj.placeOfSupply = null;
                    itemObj.panNumber = null;
                    itemObj.currency = null;
                    itemObj.countryId = null;
                    itemObj.country = null;

                    itemObj.routeId = null;
                    itemObj.routeName = null;

                    itemObj.isSystem = 0;
                    
                    itemObj.createdDate = new Date();
                    itemObj.modifiedDate = new Date();
                    itemObj.isNew = true;

                    itemObj.addresses = [];
                    
                    if(item["Billing Address"] || item["Billing City"] || item["Billing State"] || item["Billing Pincode"]){
                        let billingAddress = {};
                        billingAddress.addressId = this.guid();
                        billingAddress.groupTypeId = 101;
                        billingAddress.addressLine = item["Billing Address"] ? item["Billing Address"] : null;
                        billingAddress.city = item["Billing City"] ? item["Billing City"] : null;
                        billingAddress.state = item["Billing State"] ? item["Billing State"] : null;
                        billingAddress.stateId = null;
                        billingAddress.pincode = item["Billing Pincode"] ? item["Billing Pincode"] : null;
                        billingAddress.country = "India";
                        billingAddress.countryId = "d87bedca-5bcc-4241-8027-7e16f3b68f18";
                        billingAddress.phone = null;
                        billingAddress.isSameAddress = false;
                        itemObj.addresses.push(billingAddress);
                    }

                    if(item["Shipping Address"] || item["Shipping City"] || item["Shipping State"] || item["Shipping Pincode"]){
                        let shippingAddress = {};
                        shippingAddress.addressId = this.guid();
                        shippingAddress.groupTypeId = 102;
                        shippingAddress.addressLine = item["Shipping Address"] ? item["Shipping Address"] : null;
                        shippingAddress.city = item["Shipping City"] ? item["Shipping City"] : null;
                        shippingAddress.state = item["Shipping State"] ? item["Shipping State"] : null;
                        shippingAddress.stateId = null;
                        shippingAddress.pincode = item["Shipping Pincode"] ? item["Shipping Pincode"] : null;
                        shippingAddress.country = "India";
                        shippingAddress.countryId = "d87bedca-5bcc-4241-8027-7e16f3b68f18";
                        shippingAddress.phone = null;
                        shippingAddress.isSameAddress = false;
                        itemObj.addresses.push(shippingAddress);
                    }
                    else{
                        if(itemObj.addresses.length){
                            let shippingAddress = {};
                            shippingAddress.addressId = this.guid();
                            shippingAddress.groupTypeId = 102;
                            shippingAddress.addressLine = itemObj.addresses[0].addressLine;
                            shippingAddress.city = itemObj.addresses[0].city;
                            shippingAddress.state = itemObj.addresses[0].state;
                            shippingAddress.stateId = itemObj.addresses[0].stateId;
                            shippingAddress.pincode = itemObj.addresses[0].pincode;
                            shippingAddress.country = "India";
                            shippingAddress.countryId = "d87bedca-5bcc-4241-8027-7e16f3b68f18";
                            shippingAddress.phone = null;
                            shippingAddress.isSameAddress = true;
                            itemObj.addresses.push(shippingAddress);

                            // billing and shipping address same
                            itemObj.addresses[0].isSameAddress = true;
                        }
                    }

                    if(item["Opening Balance"]){
                        itemObj.openingAmount = Number(item["Opening Balance"]);

                        if(itemObj.openingAmount > 0){
                            itemObj.openingNature = 1;
                        }
                        else{
                            itemObj.openingNature = 0;
                        }

                        itemObj.transactionItem = {};
                        itemObj.transactionItem.transactionId = this.guid();
                        itemObj.transactionItem.prefix = null;
                        itemObj.transactionItem.transactionNumber = null;
                        itemObj.transactionItem.mergeTransactionNumber = null;
                        itemObj.transactionItem.suffix = null;
                        // itemObj.transactionItem.transactionDate = new Date(this.$root.CurrentBusiness.OpeningDate);
                        let od = new Date(JSON.parse(JSON.stringify(this.$root.CurrentBusiness.OpeningDate)));
                        od.setDate(od.getDate()-1);
                        itemObj.transactionItem.transactionDate = this.$root.fn_getLocalDateFormat(od);
                        
                        itemObj.transactionItem.transactionListId = 40003;
                        itemObj.transactionItem.transactionTypeFormId = null;
                        itemObj.transactionItem.refNumber = null;
                        itemObj.transactionItem.refDate = null;
                        itemObj.transactionItem.partyId = itemObj.accountId;
                        itemObj.transactionItem.salesmanId = null;
                        itemObj.transactionItem.currencyId = null;
                        itemObj.transactionItem.amount = Math.abs(itemObj.openingAmount);
                        itemObj.transactionItem.roundedAmount = Math.abs(itemObj.openingAmount);
                        itemObj.transactionItem.status = 1;
                        itemObj.transactionItem.notes = null;
                        itemObj.transactionItem.dueType = null;
                        itemObj.transactionItem.dueDate = null;
                        itemObj.transactionItem.paymentMode = null;
                        itemObj.transactionItem.additionalData = null;
                        itemObj.transactionItem.createdDate = new Date();
                        itemObj.transactionItem.createdBy = null;
                        itemObj.transactionItem.modifiedDate = new Date();
                        itemObj.transactionItem.modifiedBy = null;
                        itemObj.transactionItem.discount = null;
                        itemObj.transactionItem.tax = null;
                        itemObj.transactionItem.otherCharges = null;
                        itemObj.transactionItem.grossAmount = itemObj.openingAmount;
                        itemObj.transactionItem.netAmount = null;
                        itemObj.transactionItem.taxableValue = null;
                        itemObj.transactionItem.routeId = null;
                        itemObj.transactionItem.invoiceType = null;
                        itemObj.transactionItem.exportType = null;
                        itemObj.transactionItem.onAccount = null;
                        itemObj.transactionItem.sortDate = this.$moment(itemObj.transactionItem.transactionDate).format('YYYY-MM-DD');

                        itemObj.transactionItem.transactionLedgers = [];

                        let obj1 = {};
                        obj1.transactionLedgerId = this.guid();
                        obj1.accountLedgerId = itemObj.accountId;
                        obj1.orderNumber = 1;
                        obj1.amount = Math.abs(itemObj.openingAmount);
                        obj1.amountType = itemObj.openingNature;
                        itemObj.transactionItem.transactionLedgers.push(obj1);

                        itemObj.transactionItem.ledgerAccounts = [];

                        let obj2 = {};
                        obj2.ledgerAccountId = this.guid();
                        obj2.accountId = itemObj.accountId;
                        obj2.amount = itemObj.openingAmount;
                        obj2.amountType = itemObj.openingNature;
                        itemObj.transactionItem.ledgerAccounts.push(obj2);
                    }
                    
                    // postData.importItems.push(itemObj);
                    accountName.push(itemObj.name);
                    postData.ledgerItems.push(itemObj);
                }

                postData.accountName = accountName;
                // postData.productName = productName;
                // postData.categoryName = categoryName;
                // postData.brandName = brandName;
                // postData.companyName = companyName;
                // postData.unitName = unitName;

                // postData.brandListId = listIdObject.brandListGUID;
                // postData.companyListId = listIdObject.companyListGUID;

                // console.log(JSON.stringify(postData));

                let dataSize = 1024 * 32;
                if(JSON.stringify(postData).length >= dataSize){
                    // this.fn_breakDataIntoChunks(JSON.stringify(postData))
                    if(postData.ledgerItems.length > 500){
                        this.fn_makeBatches(postData);
                    }
                    else{
                        if(this.totalItemsCount == this.importItems.length){
                            postData.totalItems = this.totalItemsCount;
                            postData.invalidItems = this.invalidItemsCount;
                        }

                        if((this.importItems.length - this.invalidItemsCount) <= 500){
                            postData.lastChunk = true;
                        }
                        this.fn_breakDataIntoChunks(JSON.stringify(postData));
                    }
                }
                else{
                    this.fn_importLedgers(postData); 
                }
            },

            fn_makeBatches: function(postData){

                let obj = {};
                obj.accountName = [];

                let batchSize = 500;
                // let batchItems = postItems.slice(0, batchSize);
                obj.ledgerItems = postData.ledgerItems.slice(0, batchSize);
                postData.ledgerItems.splice(0, obj.ledgerItems.length);

                let isLastBatch = postData.ledgerItems.length ? false : true;

                for(let i=0; i<obj.ledgerItems.length; i++){
                    obj.accountName.push(obj.ledgerItems[i].name);
                }

                console.log('before data chunk');
                // console.log(JSON.stringify(obj));

                if(this.totalItemsCount == this.importItems.length){
                    obj.totalItems = this.totalItemsCount;
                    obj.invalidItems = this.invalidItemsCount;
                }

                if((this.importItems.length - this.invalidItemsCount) <= 500){
                    obj.lastChunk = true;
                }

                this.fn_breakDataIntoChunks(JSON.stringify(obj), isLastBatch, response=>{

                    // this.importStatus = Math.round((batchSize / this.importItems.length) * 100);
                    this.importStatus = Math.round(((this.validItemsCount - postData.ledgerItems.length) / this.validItemsCount) * 100);
                    // removing items from list after batch items is successfully posted
                    if(response != 'error'){
                        this.importItems = this.importItems.filter(item=>{
                            let itemIndex = obj.ledgerItems.findIndex(batchItem=>!item.hasError && item.Name == batchItem.name);
                            return itemIndex == -1;
                        });
                        
                        postData.ledgerItems.length ? this.fn_makeBatches(postData) : ''; 
                    }
                });
            },

            fn_breakDataIntoChunks: function(postObj, isLastBatch, callback){
                console.log(postObj);
                // let chunkSize = (1024 * 32) - 73; //self.$parent.datachunksize //1 byte
                let chunkSize = (1024 * 32) - 147; //self.$parent.datachunksize //1 byte
                let enc = new TextEncoder(); 
                let arrayBuffer = enc.encode(postObj);

                this.bytesArray = [];
                for (let i = 0; i < arrayBuffer.byteLength; i += chunkSize) {
                    let datachunk = arrayBuffer.slice(i, i + chunkSize);
                    this.bytesArray.push(datachunk);
                }
                // callback();
                this.fn_sendDataChunk(this.bytesArray[0], this.bytesArray.length, isLastBatch, callback);
            },

            fn_sendDataChunk: function(dataChunk, totalChunkLength, isLastBatch, callback){
                let self = this;
                this.sendDataChunks(dataChunk,
                    {
                        onSuccess: function(response){
                            // console.log("chunk received");
                            // console.log(new Date());
                            console.log(response);

                            self.bytesArray.shift();

                            totalChunkLength ? self.totalChunksLength = totalChunkLength : '';
                            self.fileBufferValue = Math.round(((self.totalChunksLength - self.bytesArray.length) * 100)/self.totalChunksLength);

                            if(self.bytesArray.length){
                                self.fn_sendDataChunk(self.bytesArray[0], self.bytesArray.length, isLastBatch, callback);
                            }
                            else{
                                self.fn_importLedgers(null, isLastBatch, callback); 
                            }
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.isUploading = false;
                            self.$refs['ref_dialogLoading'].closeDialogWeb();
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                        }
                    }
                );
            },

            fn_importLedgers: function(postData, isLastBatch, callback){
                let self = this;
                // if(isLastBatch == undefined || isLastBatch){
                //     postData.lastChunk = true;
                // }
                if(postData && (isLastBatch == undefined || isLastBatch)){
                    postData.totalItems = this.totalItemsCount;
                    postData.invalidItems = this.invalidItemsCount;
                    postData.lastChunk = true;
                }
                this.postData('PostImportLedger', postData, 
                    {
                        onSuccess: function(response){
                            console.log("chunk import posted mobile " + new Date());
                            console.log(response);

                            if(!isLastBatch && callback){
                                callback();
                            }
                            else{
                                self.importStatus = 100;

                                self.isUploading = false;
                                // self.$eventHub.$emit('snackbarAlert', {msg:self.importItems.length - self.invalidItemsCount+" valid items have been imported successfully"});
                                self.$eventHub.$emit('snackbarAlert', {msg:"All valid parties have been imported successfully"});

                                self.validItemsCount = 0;

                                if((self.importItems.length - self.invalidItemsCount) > 0){
                                    self.importItems = self.importItems.filter(item=>{
                                        return item.hasError;
                                    })
                                }
                                else{
                                    self.importItems = null;
                                }

                                self.$refs['ref_dialogLoading'].closeDialogWeb();
                                self.$root.fn_getMasterItems('ledgers', ["Ledger", "deleteAccountWithOpening"], false, true);
                            }
                        },
                        onError: function(error){
                            console.log(error);
                            let errorObj = error ? JSON.parse(error) : null;
                            let duplicateItems = errorObj && errorObj.data ? JSON.parse(errorObj.data) : null;
                            if(duplicateItems && duplicateItems.length){
                                self.fn_processAfterValidate(duplicateItems, self.importItems, true);
                            }
                            else{
                                self.isUploading = false;
                                self.$refs['ref_dialogLoading'].closeDialogWeb();
                                self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                            }

                            callback ? callback('error') : '';
                        }
                    }
                );
            },

            // fn_postItem: function(item, jsonData){
            //     let itemObj = {};
            //     itemObj.brandId = item.brandId ? item.brandId : null;
            //     itemObj.brandName = item.brandName ? item.brandName : null;
            //     // itemObj.charges = item.Charges ? item.Charges : null;
            //     itemObj.companyBarcode = null;
            //     itemObj.companyId = item.companyId ? item.companyId : null;
            //     itemObj.companyName = item.companyName ? item.companyName : null;
            //     itemObj.createdDate = new Date();
            //     itemObj.description = item.Description ? item.Description : null;
            //     itemObj.fixedCQty = null;
            //     // itemObj.groupTypeId = item["Service Item"] == 'Yes' ? 102 : 101;
            //     itemObj.groupTypeId = 101;
            //     itemObj.hsnCode = item["HSN Code"] ? item["HSN Code"] : null;
            //     // itemObj.inventory = item["Expense Item"] == 'Yes' ? 0 : 1;
            //     itemObj.inventory = 1;
            //     itemObj.isNew = true;
            //     itemObj.isSubVariant = false;
            //     itemObj.isSystem = 0;
            //     itemObj.modifiedDate = new Date();
            //     itemObj.mrp = null;
            //     itemObj.name = item.Name;
            //     itemObj.openingQty = null;
            //     itemObj.openingRate = null;
            //     itemObj.productCategoryId = item.productCategoryId;
            //     itemObj.productCategoryName = item.productCategoryName;
            //     itemObj.productCode = item["Item Code"] ? item["Item Code"] : null;
            //     itemObj.productId = this.guid();
            //     // itemObj.unitId = item.ServiceItem ? defaultUnit.nos.id : defaultUnit.pieces.id,
            //     // itemObj.unitName = item.ServiceItem ? defaultUnit.nos.name : defaultUnit.pieces.name,
            //     itemObj.unitId = item.unitId;
            //     itemObj.unitName = item.unitName;
            //     itemObj.productVariants = [
            //         {
            //             "description":null,
            //             "groupTypeId":401,
            //             "isDefault":true,
            //             "productId":itemObj.productId,
            //             "title":null,
            //             "productVariantId":this.guid(),
            //             "unitId":itemObj.unitId,
            //             "unitName":itemObj.unitName,
            //             "packQuantity":null,
            //             "variantBaseUnitId":itemObj.unitId
            //         }
            //     ];
            //     itemObj.purchaseRate = null;
            //     itemObj.sellingPrice = item["Selling Price"] ? item["Selling Price"] : null;
            //     itemObj.title = null;
            //     itemObj.taxCategoryId = item.taxCategoryId ? item.taxCategoryId : null;
            //     itemObj.taxCategoryName = item.taxCategoryName ? item.taxCategoryName : null;
            //     itemObj.transactionId = null;
            //     itemObj.wholeSalePrice = item["Wholesale Price"] ? item["Wholesale Price"] : null;

            //     itemObj.doNotBroadcast = this.processCounter < jsonData.length - 1 ? true : false;

            //     this.fn_saveItem(itemObj, itemResponse=>{
            //         console.log(itemResponse);
            //         // removing item from list on successfully import
            //         // this.importItems.splice(this.processCounter, 1);
            //         this.importItems[this.processCounter].isImported = true;

            //         this.processCounter++;
            //         this.importStatus = Math.round((this.processCounter/this.importItems.length)*100);
            //         // this.fn_processItems(jsonData);
            //         this.fn_processTaxCategories(jsonData);
            //     })
            // },

            fn_downloadSample: function(){
                const XlsxPopulate = require('xlsx-populate');
                let self = this;
                
                // Load a new blank workbook
                XlsxPopulate.fromBlankAsync()
                .then(workbook => {
                    // let data = [                    
                    //     ["Name", "Item Code", "GST Percentage", "Cess Percentage", "HSN Code", "Unit", "Description", "Selling Price", "Wholesale Price", "Category", "Brand", "Company"],
                    //     ["Item 1", "ACF89899",   12, null, 3303,        "Pcs",  null, 200,	180,    "Perfume",	"XYZ",	"ABC Enterprises"],
                    //     ["Item 2", "BCF8999",    12, null, 333434,          "Pcs",  null, 250,	200,    "Perfume",	"BBB",	"Y K Cosmetics Pvt. Ltd."],
                    //     ["Item 3", "GT8909a",    5,  null, 33049120,    "Case", null, null, null,   "Talcum",	null,	"YYK Cosmetics"],
                    //     ["Item 4", "AF89899C",   18, null, 3304,        "Pcs",  null, 300,	250,    "Lotion",	"JKY",	"Mathhrin Pvt. Ltd."],
                    //     ["Item 5", "49639",      18, null, 330410,      "Pcs",  null, 200,	180,    "Lipstick",	 "JYZ",	"XYZ Unilever"]
                    // ]
                    
                    let data = [                    
                        ["Name", "Mobile Number", "Email", "Party Type", "GST Number", "Billing Address", "Billing City", "Billing State", "Billing Pincode", "Shipping Address", "Shipping City", "Shipping State", "Shipping Pincode", "Opening Balance"],
                        ["Sample Party 1", "881224XXXX", "sampleparty1@gmail.com", "Customer",  "29ABCDE1234A9Z9", "A224 Mahatama Gandhi Road",	"Bengaluru",    "Karnataka",	"560001", null, null, null, null, 5000],
                        ["Sample Party 2", "988977XXXX", "sampleparty2@gmail.com", "Customer", "33ABCDE1234A9Z9", "No. 124, Greams Lane",	"Chennai",    "Tamilnadu",	"60006", null, null, null, null, 1000],
                        ["Sample Party 3", "922442XXXX", "sampleparty3@gmail.com", "Supplier",  "24ABCDE1234A9Z9", "1203 Mondeal Square",	"Ahmedabad",    "Gujarat",	"380015", null, null, null, null, -2000],
                        ["Sample Party 4", "877790XXXX", "sampleparty4@gmail.com", "Customer",  "36ABCDE1234A9Z9", "21, Banjara Hills",	"Hyderabad",    "Telangana",	"500033", null, null, null, null, 4000],
                        ["Sample Party 5", "991243XXXX", "sampleparty5@gmail.com", "Customer",  "07ABCDE1234A9Z9", "21/12, Karol Bagh",	"New Delhi",    "Delhi",	"110005", null, null, null, null, 2500],
                    ]

                    let fillRange = "A1:N1";

                    // if(this.$root.TransactionSettings.mrpWiseStock){
                    //     for(let i=0; i<data.length; i++){
                    //         let value = i==0 ? "MRP" : 100;
                    //         data[i].splice(7, 0, value);
                    //     }
                    //     fillRange = "A1:M1";
                    // }

                    // if(this.$root.CurrentBusiness.GSTRegistrationStatus == 1){
                    //     for(let i=0; i<data.length; i++){
                    //         let gstValue = i == 0 ? "GST Percentage" : 5;
                    //         let cessValue = i == 0 ? "Cess Percentage" : null;
                    //         let hsnValue = i == 0 ? "HSN Code" : 333434;
                    //         data[i].splice(3, 0, gstValue);
                    //         data[i].splice(4, 0, cessValue);
                    //         data[i].splice(5, 0, hsnValue);
                    //     }
                    // }
                    // else{
                    //     fillRange = this.$root.TransactionSettings.mrpWiseStock ? "A1:J1" : "A1:I1";
                    // }

                    // Modify the workbook.
                    workbook.sheet("Sheet1").cell("A1").value(data);

                    workbook.sheet("Sheet1").range(fillRange).style("fill", "92D050");

                    // workbook.sheet(0).name("Items");

                    let notes = [
                        ["Notes*", ""],
                        [1, 'Required fields: "Name"'],
                        [2, "Name of items shouldn't be blank or duplicate."],
                        // [3, '"Service Item" & "Expense Item" field should be "Yes" or "No/Blank" other than "Yes" will be consider as "No".'],
                        // [4, '"Charges", "GST Percentage", "Selling Price" and "Wholesale Price" fields should be numerical.'],
                        // [3, 'HSN Code field should be either 4, 6 or 8 characters long.']
                    ]

                    workbook.addSheet('Notes');
                    workbook.sheet("Notes").cell("A1").value(notes);
                    workbook.sheet("Notes").range("A1:B6").style({"fontColor":"FF0000", "bold":true});

                    workbook.outputAsync().then(function (blob) {
                        var fileName = 'samplefile'
                        self.exportExcel = false;
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = fileName + ".xlsx";
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    });
                });
            },

            // fn_getTaxCategoryDetails: function(taxValue, cessValue, callback){
            //     let self = this;
            //     let filterObject = {taxValue:taxValue, cessValue:cessValue};
            //     this.getFilterData('TaxCategoryByValue', null, filterObject, null,
            //         {
            //             onSuccess: function(response){
            //                 console.log(response);
            //                 callback ? callback(JSON.parse(response)) : '';
            //             }, 
            //             onError: function(error){
            //                 console.log(error);
            //             //   callback ? callback('error') : '';
            //                 self.isUploading = false;
            //                 self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                 self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //             }
            //         }
            //     );
            // },

            // fn_saveTaxCategory: function(dataObj, callback){
            //     let self = this;
            //     this.postData('TaxCategory', dataObj, 
            //         {
            //             onSuccess: function(response){
            //                 callback(response);
            //             },
            //             onError: function(error){
            //                 console.log(error);
            //                 // callback('error');
            //                 self.isUploading = false;
            //                 self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                 self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //             }
            //         }
            //     );
            // },

            // fn_getUnitDetails: function(unitName, callback){

            //     let res = unitQuantityCodes.filter(item=>{
            //         return item.name.toLowerCase().indexOf(unitName.toLowerCase()) != -1;
            //     });

            //     let filterObj = {};
            //     filterObj.uqc = -1;
            //     if(res && res.length){
            //         filterObj.uqc = res[0].id;
            //     }

            //     let self = this;

            //     this.getFilterData('UnitByName', unitName, filterObj, null,
            //         {
            //             onSuccess: function(response){
            //                 console.log(response);
            //                 // assigning unit quantity code. 44 for other if not matched
            //                 let uqcId = res.length ? res[0].id : 44;
            //                 callback ? callback(JSON.parse(response), uqcId) : '';
            //             }, 
            //             onError: function(error){
            //                 console.log(error);
            //                 //   callback ? callback('error') : '';
            //                 self.isUploading = false;
            //                 self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                 self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //             }
            //         }
            //     );
            // },

            // fn_saveUnit: function(dataObj, callback){
            //     let self = this;
            //     this.postData('Units', dataObj, 
            //         {
            //             onSuccess: function(response){
            //                 callback(response);
            //             },
            //             onError: function(error){
            //                 console.log(error);
            //                 // callback('error');
            //                 self.isUploading = false;
            //                 self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                 self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //             }
            //         }
            //     );
            // },

            // fn_getProductCategoryDetails: function(category, callback){
            //     let self = this;
            //     this.getData('ProductCategoryByName', category, 
            //         {
            //             onSuccess: function(response){
            //                 console.log(response);
            //                 callback ? callback(JSON.parse(response)) : '';
            //             }, 
            //             onError: function(error){
            //                 console.log(error);
            //                 //   callback ? callback('error') : '';
            //                 self.isUploading = false;
            //                 self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                 self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //             }
            //         }
            //     );
            // },

            // fn_saveProductCategory: function(dataObj, callback){
            //     let self = this;
            //     this.postData('ProductCategory', dataObj, 
            //         {
            //             onSuccess: function(response){
            //                 callback(response);
            //             },
            //             onError: function(error){
            //                 console.log(error);
            //                 // callback('error');
            //                 self.isUploading = false;
            //                 self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                 self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //             }
            //         }
            //     );
            // },

            // fn_getListItemDetails: function(listItemName, listId, callback){
            //     let filterObj = {};
            //     filterObj.listItemName = listItemName;
            //     // filterObj.listItemName = 'adidas pvt ltd';
            //     let self = this;

            //     this.getFilterData('ListItemByName', listId, filterObj, null,
            //         {
            //             onSuccess: function(response){
            //                 console.log(response);
            //                 callback ? callback(JSON.parse(response)) : '';
            //             }, 
            //             onError: function(error){
            //                 console.log(error);
            //                 // callback ? callback('error') : '';
            //                 self.isUploading = false;
            //                 self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                 self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //             }
            //         }
            //     );
            // },

            // fn_saveListItem: function(dataObj, callback){
            //     let self = this;
            //     this.postData('ListItem', dataObj, 
            //         {
            //             onSuccess: function(response){
            //                 callback(response);
            //             },
            //             onError: function(error){
            //                 console.log(error);
            //                 // callback('error');
            //                 self.isUploading = false;
            //                 self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                 self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //             }
            //         }
            //     );
            // },

            // fn_saveItem: function(dataObj, callback){
            //     let self = this;
            //     this.postData('Product', dataObj, 
            //         {
            //             onSuccess: function(response){
            //                 let status = JSON.parse(response);
            //                 if(status) {
            //                     callback();
            //                 }
            //                 else {
            //                     // callback('duplicate');
            //                     self.isUploading = false;
            //                     self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                     self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //                     console.log("Already exists with this name")
            //                 }
            //             },
            //             onError: function(error){
            //                 console.log(error);
            //                 // callback('error');
            //                 self.isUploading = false;
            //                 self.$refs['ref_dialogLoading'].closeDialogWeb();
            //                 self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
            //             }
            //         }
            //     );
            // },

            fn_testguid: async function(){
                console.log('log1');
                let guid = await this.guidtest();
                alert(guid);
                console.log('log2');
                console.log('log3');
                console.log('log4');
                alert('log5');
                console.log('log6');
            },
            onScroll (event) {
                if(document.querySelector('table > thead')){
                    if(event.target.scrollTop>0){
                        document.querySelector('table > thead').classList.add('has-shadow'); 
                    }
                    else{
                        document.querySelector('table > thead').classList.remove('has-shadow'); 
                    }
                }
                
            },

            fn_calculatePageLength: function(itemsLength){
                this.pageLength = itemsLength ? Math.ceil(itemsLength / 10) : 0;
            },

            // fn_addConditionalFields: function(){
            //     if(this.$root.TransactionSettings.mrpWiseStock){
            //         let mrpObj = {"defaultField":"MRP", "mappedField":null, "placeholder":"Select mrp"};
            //         this.headerFields.splice(7, 0, mrpObj);
            //     }

            //     if(this.$root.CurrentBusiness.GSTRegistrationStatus == 1){
            //         let gstObj = {"defaultField":"GST Percentage", "mappedField":null, "placeholder":"Select GST/Tax percentage"};
            //         let cessObj = {"defaultField":"Cess Percentage", "mappedField":null, "placeholder":"Select CESS percentage"};
            //         let hsnObj = {"defaultField":"HSN Code", "mappedField":null, "placeholder":"Select HSN code"};
            //         this.headerFields.splice(3, 0, gstObj);
            //         this.headerFields.splice(4, 0, cessObj);
            //         this.headerFields.splice(5, 0, hsnObj);
            //     }
            // }
        },
        computed:{
            filteredItems: function(){
                let items = null;
                if(this.selectedFilter === 0){
                    // items = this.importItems;
                    items = this.importItems.filter(item=>{
                        return !item.isImported;
                    })
                }
                else if(this.selectedFilter === 1){
                    items = this.importItems.filter(item=>{
                        return !item.hasError && !item.isImported;
                    })
                }
                else if(this.selectedFilter === -1){
                    items = this.importItems.filter(item=>{
                        return item.hasError;
                    })
                }

                this.fn_calculatePageLength(items.length);
                // return items;
                let startIndex = (this.page - 1) * 10;
                return items.slice(startIndex, startIndex+10);
            }
        },
        mounted: function(){
            let isCalled = false;
            this.$eventHub.$on('connected', () => {
                if(!isCalled){
                    isCalled = true;
                    if(this.isUploading){
                        setTimeout(() => {
                            this.fn_processBulkImport();
                        }, 1000);
                    }
                    else{
                        if(this.$refs['ref_dialogLoading'] && this.$refs['ref_dialogLoading'].dialog_loading){
                            this.$eventHub.$emit('snackbarAlert', {msg:"Connection was interrupted. Please select file to import."});
                            this.$refs['ref_dialogLoading'].closeDialogWeb();
                        }
                    }

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);
                }
                
            });

            // this.fn_addConditionalFields();

            // const myWorker = new Worker(require('./worker.js'));

            // myWorker.postMessage('Hello from the main thread');
            // console.log(JSON.stringify(this.uploadedItems));

            // myWorker.onerror = (event) => {
            //     console.log("There is an error with your worker! "+ event);
            // };

            // myWorker.onmessage = (e) => {
            //     console.log(e);
            //     myWorker.terminate();
            // }
        },
        beforeRouteLeave (to, from, next) {
            this.$eventHub.$off('connected');
            next();
        }
    }
</script>

<style>
    input#itemsheet {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        bottom: 0;
        opacity: 0;
    }

    .import-table{
        border-collapse: collapse;
    }
    .import-table tbody tr:nth-child(odd){
        background-color: #f0f0f0;
    }
    .import-table tr th{
        text-transform: capitalize;
        padding: 8px !important;
    }
    .import-table tr th, .import-table tr td{
        padding: 4px 8px;
        white-space: nowrap;
        border: 1px solid #ddd;
        padding-right: 20px;
    }
    .error-item{
        background-color: rgba(255,0,0,0.2);
    }
    .textbox-in-table{
        width: 162px;
        font-size: 12px;
    }
    .textbox-in-table .v-input__control, .textbox-in-table .v-input__slot{
        min-height: 30px !important;
    }
    .has-shadow{
        box-shadow: 0px 1px 5px -2px;
    }
    /* .contains-edit-btn{
        position: relative;
    } */
    /* .contains-edit-btn .v-btn{
        position: absolute;
        right: 8px;
    } */
</style>