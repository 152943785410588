<template>
    <div>
        <v-dialog v-model="dialog_payment_options" no-click-animation persistent scrollable content-class="half-screen-dialog" lazy transition="dialog-bottom-transition" :max-width="$root.fn_getActionPaneWidth()">

            <v-card>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>

                    <v-toolbar-title class="two-line-title">
                        {{$parent.settings.transactionTypeId == 30004 ? 'Mode' : 'Payment Mode'}}
                        <div class="toolbar-subtitle">
                            <div class="subtitle-text dark-opacity-color font12">Amount: &#8377; {{$root.numberToLocale($parent.Form.amount)}}</div>
                        </div>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>
                    <div>
                        <div style="float: left;margin-right: 12px;margin-top: 6px;color: rgba(0,0,0,0.57);">Split</div>
                        <v-switch class="hide-margins" hide-details v-model="$parent.Form.splitPayment" @change="fn_splitPayment"></v-switch>
                    </div>  

                    <!-- <v-btn round-borders outline @click="fn_checkValidations">Done</v-btn> -->

                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text full-height nopadding grey-back>
                    <v-form data-vv-scope="fields" full-width class="form-element-dialog hasBigFooter">

                        <!-- <v-subheader subheader-dense white-background @click="fn_splitPayment">
                            <v-spacer></v-spacer>
                            <v-icon>{{$parent.Form.splitPayment ? "close" : "call_split"}}</v-icon>&nbsp; {{$parent.Form.splitPayment ? "Cancel Split Payment" : "Split Payment"}}
                        </v-subheader> -->

                        <!-- <div v-for="(item, index) in $parent.Form.paymentGroups" :key="index" full-width v-if="item.accountLedgers && item.accountLedgers.length"> -->
                        <div v-for="(item, index) in $parent.Form.paymentGroups" :key="index" full-width :class="{'mt-2':index!=0}">

                                <!-- <v-subheader grey-back v-if="item.accountGroupName"> -->
                                <v-subheader white-back>
                                    {{item.accountGroupName != 'Cash - In - Hand' ? item.accountGroupName : 'Cash'}}
                                    <v-spacer></v-spacer>
                                    <v-btn class="mr-0" flat color="primary" icon @click="fn_newLedger(item.accountGroupId, item.accountGroupName, index)">new</v-btn>
                                </v-subheader>

                                <v-divider v-if="item.accountLedgers && item.accountLedgers.length"></v-divider>

                                <v-list two-line nopadding>
                                    <div v-for="(accountItem, accountIndex) in item.accountLedgers" :key="accountIndex">

                                        <template v-if="!$parent.Form.splitPayment">

                                            <v-radio-group class="mt-0 pt-0" col nomargin nopadding hide-details v-model="$parent.Form.paymentLedgerId" :disabled="fn_disablePaymentMode(accountItem.accountLedgerId)">

                                                <v-list-item :disabled="fn_disablePaymentMode(accountItem.accountLedgerId)">
                                                    <v-list-item-action>
                                                        <v-radio label="" :value="accountItem.accountLedgerId"></v-radio>
                                                    </v-list-item-action>

                                                    <v-list-item-content @click="$parent.Form.paymentLedgerId = accountItem.accountLedgerId">
                                                        <v-list-item-title>{{accountItem.accountLedgerName}}</v-list-item-title>
                                                        <!-- <v-list-item-subtitle>
                                                            Balance: <span :class="[accountItem.balance < 0 ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(roundUpto2Decimals(accountItem.balance))}}</span>
                                                        </v-list-item-subtitle> -->
                                                    </v-list-item-content>
                                                    
                                                </v-list-item>

                                            </v-radio-group>

                                            <v-layout ma-0 row wrap pt-4 pb-4 v-if="fn_showHideRefNumber(accountItem, index)">
                                                <v-flex xs12 sm12 md12 form-item>
                                                    <v-text-field 
                                                        hide-details
                                                        v-model="accountItem.refNumber"
                                                        label="Reference No. (Optional)"
                                                        placeholder=" ">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>
                                        </template>

                                        <template v-if="$parent.Form.splitPayment">

                                            <v-list-item :disabled="fn_disablePaymentMode(accountItem.accountLedgerId)">
                                            
                                                <v-list-item-action>
                                                    <v-checkbox v-model="accountItem.selected" @change="toggleAmount(accountItem)" :disabled="fn_disablePaymentMode(accountItem.accountLedgerId)"></v-checkbox>
                                                </v-list-item-action>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{accountItem.accountLedgerName}}</v-list-item-title>
                                                    <!-- <v-list-item-subtitle>
                                                        Balance: <span :class="[accountItem.balance < 0 ? 'redcolor' : 'greencolor']">&#8377; {{accountItem.balance}}</span>
                                                    </v-list-item-subtitle> -->
                                                </v-list-item-content>
                                                
                                                <v-list-item-action v-if="accountItem.selected" style="max-width:128px">
                                                    <!-- <v-text-field 
                                                        prefix="₹"
                                                        style="margin:0"
                                                        hide-details
                                                        right-align
                                                        v-model.number="accountItem.amount"
                                                        type="number"
                                                        label=""
                                                        v-validate="'decimal:2'" 
                                                        :data-vv-name="'amount'+index+accountIndex" 
                                                        data-vv-as="amount"
                                                        :error-messages="errors.collect('amount'+index+accountIndex)">
                                                    </v-text-field> -->
                                                    <v-text-field 
                                                        prefix="₹"
                                                        style="margin:0"
                                                        hide-details
                                                        right-align
                                                        v-model.number="accountItem.amount"
                                                        type="number"
                                                        label="">
                                                    </v-text-field>
                                                </v-list-item-action>

                                            </v-list-item>  

                                            <v-layout ma-0 row wrap pt-4 pb-4 v-if="fn_showHideRefNumber(accountItem, index)">
                                                <v-flex xs12 sm12 md12 form-item>
                                                    <v-text-field 
                                                        dense
                                                        hide-details
                                                        v-model.number="accountItem.refNumber"
                                                        label="Reference No. (Optional)"
                                                        placeholder=" ">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <!-- <div class="error--text font-small pa-2 full-width" v-if="errors.has('amount'+index+accountIndex)">
                                                {{ errors.first('amount'+index+accountIndex)}}
                                            </div> -->
                                            
                                        </template>
                                        

                                </div>

                                </v-list>

                        </div>
                        
                    </v-form>
                    
                </v-card-text>
                <!-- <v-divider v-if="$parent.Form.splitPayment"></v-divider> -->
                <!-- <v-card-actions v-if="$parent.Form.splitPayment">
                    <v-layout row wrap>
                        <v-flex xs6 md6 sm6>
                            Bill Amount <br>&#8377; {{$parent.Form.amount}}
                        </v-flex>
                        <v-flex xs6 md6 sm6 right-align :class="{'redcolor':paymentTotal>$parent.Form.amount}">
                            Total Payment <br>&#8377; {{paymentTotal}}
                        </v-flex>
                    </v-layout>
                </v-card-actions> -->
                <!-- <div white-background nopadding>
                    <v-layout row wrap ma-0>

                        <v-flex xs6 sm6 md6 style="padding:6px 6px 6px 16px" v-if="$parent.Form.splitPayment">
                            <div font-medium :class="{'redcolor':paymentTotal>$parent.Form.amount}" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">&#8377; {{$root.numberToLocale(paymentTotal)}}</div>
                            <div style="font-size:11px">Split Total</div>
                        </v-flex>
                        <v-flex xs6 sm6 md6 :class="{'xs12 sm12 md12 height48':!$parent.Form.splitPayment}">
                            <v-btn color="primary" full-height nomargin block no-round-borders noshadow @click="fn_checkValidations">Done</v-btn>
                        </v-flex>
                    </v-layout>
                </div> -->

                <v-card-actions>
                    <v-layout row wrap ma-0>

                        <v-flex height48 xs6 sm6 md6 style="padding:6px 6px 6px 16px" v-if="$parent.Form.splitPayment">
                            <div font-medium :class="{'redcolor':paymentTotal>$parent.Form.amount}" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">&#8377; {{$root.numberToLocale(paymentTotal)}}</div>
                            <div style="font-size:11px">Split Total</div>
                        </v-flex>
                        <v-flex height48 :class="[$parent.Form.splitPayment ? 'xs6 sm6 md6' : 'xs12 sm12 md12']">
                            <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow @click="fn_checkValidations">Done</v-btn>
                        </v-flex>

                        <!-- <v-flex pa-3 xs6 md6 sm6 :class="{'redcolor':paymentTotal>$parent.Form.amount}">
                            Total Payment <br>&#8377; {{paymentTotal}}
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-btn style="height:48px !important" color="primary" nomargin block no-round-borders noshadow @click="fn_checkValidations">Done</v-btn>
                        </v-flex> -->
                    </v-layout>
                </v-card-actions>


            </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['savePaymentOptions'],
    data(){ 
        return{
            dialog_payment_options: false,
            // $parent.Form.paymentLedgerId:null,
            // $parent.Form.splitPayment:null,
            // $parent.Form.paymentGroups:null
        }
    },
    methods:{
        openDialogWeb: function () {    
            // this.$validator.reset();
            // this.$parent.Form.splitPayment = this.$parent.Form.splitPayment;
            // this.$parent.Form.paymentLedgerId = this.$parent.Form.$parent.Form.paymentLedgerId;
            // this.$parent.Form.paymentGroups = JSON.parse(JSON.stringify(this.$parent.Form.paymentGroups));
            this.dialog_payment_options = true;
        },
        closeDialogWeb: function () {
            this.dialog_payment_options = false;
        },

        fn_disablePaymentMode: function(accountLedgerId){
            if(this.$parent.settings.transactionTypeId != 30004){
                return false;
            }
            else{
                if(this.$parent.Form.selectedParty.itemId == accountLedgerId){
                    return true;
                }
                else{
                    return false;
                }
            }
        },

        fn_showHideRefNumber: function(accountItem, index){
            if(!this.$parent.Form.splitPayment){
                if(this.$parent.Form.paymentLedgerId == accountItem.accountLedgerId){
                    if(this.$parent.settings.transactionTypeId == 30005 || this.$parent.settings.transactionTypeId == 30006){
                        return true;
                    }
                    else{
                        if(index == 0){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                }
                else{
                    return false;
                }   
            }
            else{
                if(accountItem.selected){
                    if((this.$parent.settings.transactionTypeId == 30001 || this.$parent.settings.transactionTypeId == 30002) && index == 0){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                else{
                    return false;
                }
            }
            
        },

        fn_newLedger: function(accountGroupId, accountGroupName, index){
            // setting up default account group to be selected
            let data = {};
            data.type = 'accountGroup';
            data.id = accountGroupId;
            data.name = accountGroupName;
            localStorage.setItem('newItem', JSON.stringify(data));

            this.$parent.backButtonPress = false;
            this.$parent.Form.paymentGroupIndex = index;

            let name = this.$parent.Form.selectedParty ? this.$parent.Form.selectedParty.itemName : '';
            this.$parent.fn_saveDraft(this.$parent.draftStorageId, name);

            this.$router.push({
                path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new',
                query:{
                    tagId:this.guidWeb(), 
                    groupTypeId: 103, 
                    ls:1,
                    type:'ledger_other',
                    dlg:1
                }
            })  
        },

        fn_splitPayment: function(){
            // this.$parent.Form.splitPayment = !this.$parent.Form.splitPayment;
            for(let i=0; i<this.$parent.Form.paymentGroups.length; i++){
                for(let j=0; j<this.$parent.Form.paymentGroups[i].accountLedgers.length; j++){
                    this.$parent.Form.paymentGroups[i].accountLedgers[j].selected = false;
                    this.$parent.Form.paymentGroups[i].accountLedgers[j].amount = null;
                    this.$parent.Form.paymentGroups[i].accountLedgers[j].refNumber = null;
                }
            }

            if(this.$parent.settings.transactionTypeId!=30004){
                if(this.$parent.Form.splitPayment){
                    this.$parent.Form.paymentGroups[0].accountLedgers[0].selected = true;
                    this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = this.$parent.Form.amount;
                }
                else{
                    this.$parent.Form.paymentLedgerId = this.$parent.Form.paymentGroups[0].accountLedgers[0].accountLedgerId;
                }   
            }
            
        },
        // toggleAmount: function(accountItem, isCheckBox){
        toggleAmount: function(accountItem){
            // if(!isCheckBox){
            //  accountItem.selected = !accountItem.selected;
            // }
            console.log('toggle');
            if(!accountItem.selected){
                accountItem.amount = null;
                accountItem.refNumber = null;
            }
            else{
                if(this.paymentTotal < this.$parent.Form.amount){
                    accountItem.amount = this.roundUpto2Decimals(this.$parent.Form.amount - this.paymentTotal);
                }
                // accountItem.amount = this.$parent.Form.paymentGroups[0].accountLedgers[0].amount;
            }


            // this.fn_calculateCreditAmount(accountItem.amount);

        },

        // fn_calculateCreditAmount: function(amount){
        //  if(this.$parent.Form.paymentGroups[0].accountLedgers[0].amount > amount){
        //      this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = this.roundUpto2Decimals(this.$parent.Form.paymentGroups[0].accountLedgers[0].amount - amount);
        //  }
        //  else{
        //      if(this.paymentTotal < this.$parent.Form.amount){
        //          this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = this.roundUpto2Decimals(this.$parent.Form.amount - this.paymentTotal);
        //      }
        //      else{
        //          this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = 0;
        //      }
        //  }
        // },

        fn_checkValidations: function(){

            // if(this.$parent.settings.transactionTypeId==30004){
            //  if(this.$parent.Form.splitPayment){
            //      for(let i=0; i<this.$parent.Form.paymentGroups.length; i++){
            //          let group = this.$parent.Form.paymentGroups[i];
            //          for(let j=0; j<group.accountLedgers.length; j++){
            //              if(group.accountLedgers[j].accountLedgerId == this.$parent.Form.partyId && group.accountLedgers[j].amount){
            //                  this.showToast("Invalid amount entry in "+group.accountLedgers[j].accountLedgerName);
            //                  return;
            //              }
            //          }
            //      }
            //  }
            //  else{
            //      if(this.$parent.Form.partyId == this.$parent.Form.paymentLedgerId){
            //          this.showToast("Select other account");
            //          return;
            //      }
            //  }
            // }

            if(this.$parent.Form.splitPayment && this.paymentTotal != this.$parent.Form.amount){
                // alert("Split total should be equal to amount");
                this.$eventHub.$emit('snackbarAlert', {msg:'Split total should be equal to amount'});
                return;
            }

            if(!this.$parent.Form.splitPayment && !this.$parent.Form.paymentLedgerId){
                // alert("Select Mode");
                this.$eventHub.$emit('snackbarAlert', {msg:'Select Mode'});
                return;
            }

            // this.$parent.Form.splitPayment = this.$parent.Form.splitPayment;
            this.$parent.Form.paymentGroups = JSON.parse(JSON.stringify(this.$parent.Form.paymentGroups));
            this.savePaymentOptions(this.$parent.Form.paymentLedgerId, true);   
            // this.$parent.fn_closeDialog();
            this.closeDialogWeb();
        }
    },
    computed:{
        paymentTotal: function(){
            let totalPayment = 0;

            if(this.$parent.Form.paymentGroups){

                for(let i=0; i<this.$parent.Form.paymentGroups.length; i++){

                    let ledgers = this.$parent.Form.paymentGroups[i].accountLedgers;

                    for(let j=0; j<ledgers.length; j++){

                        // if(ledgers[j].selected && i!=0){
                        if(ledgers[j].selected){
                            totalPayment += ledgers[j].amount ? Number(ledgers[j].amount) : 0;
                        }
                    
                    }   

                }

            }
            
            return this.roundUpto2Decimals(totalPayment);
        }
        // cashAmount: function(){
        //  let cashAmount = 0;
        //  if(this.paymentTotal < this.$parent.Form.amount){
        //      cashAmount = this.roundUpto2Decimals(this.$parent.Form.amount - this.paymentTotal);
        //  }
        //  this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = cashAmount;
        //  return cashAmount;
        // },
        // totalSum: function(){
        //  return this.roundUpto2Decimals(this.paymentTotal + this.cashAmount);
        // },
    }
}
</script>