<template>
    <div>
        <v-dialog v-model="dialog_signature" no-click-animation persistent lazy transition="slide-y-transition" max-width="460px">

            <v-card>

                <v-toolbar :height="56" flat>
                    <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>Signature</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="fn_clearPad">Clear</v-btn>
                    <!-- <v-btn flat icon color="primary" @click="fn_saveImage"><v-icon>done</v-icon></v-btn> -->
                </v-toolbar>

                <!-- <v-alert type="info" :value="true">
                    Draw your signature within given box.
                </v-alert> -->
                
                <v-card-text full-height pa-4 pt-5>
                    <div class="wrapper" style="border:1px #ddd solid;height:200px" >
                        <canvas id="signature-pad" class="signature-pad" style="width:100%;height:100%"></canvas>   
                        <!-- <canvas id="cropped-canvas" class="cropped-canvas"></canvas>        -->
                    </div>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                    <v-btn color="blue darken-1" text @click.native="fn_saveImage">Done</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>     

<script>

import SignaturePad from 'signature_pad';
// import trimCanvas from 'trim-canvas'

var canvas = null;
var signaturePad = null;

export default{
    props:['embedSignature'],
    data(){ 
        return{
            dialog_signature: false
            // quillContent:null
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_signature = true;
            setTimeout(()=>{
                canvas = document.getElementById('signature-pad');
            
                signaturePad = new SignaturePad(canvas, {
                  backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
                });
            },300);
        },
        closeDialogWeb: function () {
            this.dialog_signature = false;
        },
        fn_saveImage: function(){

            if (signaturePad.isEmpty()) {
                // alert("Please provide a signature");
                this.$eventHub.$emit('snackbarAlert', {msg:"Please provide a signature"});
                return
            }

            var dataUrl = signaturePad.toDataURL('image/png');
            console.log(dataUrl);

            this.embedSignature(dataUrl);
            
        },
        fn_clearPad: function(){
            signaturePad.clear();
        }
    }
}
</script>