<template>
  <div>

        <v-toolbar flat :height="56">
            <v-toolbar-title>Masters</v-toolbar-title>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back">

            <v-alert type="info" icon="info_outline" font12 dense :value="true">Select an item to get details</v-alert>

            <!-- <v-list dense class="pa-0" v-for="(group, index) in listCategories" :key="index" :class="{'mt-2':index!=0}">
                <v-subheader class="pl-4 pr-4">{{group.name}}</v-subheader>
                <v-divider></v-divider> -->
            <v-list dense class="pa-0" grey-back>

                <v-list-item-group v-model="selectedItem" color="primary">

                    <template v-for="(group, index) in listCategories">

                        <div :key="'container'+index" :class="{'mt-2':index!=0}">

                            <v-subheader class="pl-4 pr-4 white-back" :key="'subheader'+index">{{group.name}}</v-subheader>
                            <v-divider :key="group.name"></v-divider>

                            <template v-for="(item, itemIndex) in group.items">
                                <v-list-item white-back :key="itemIndex" @click="fn_openList(item)">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.title"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon>chevron_right</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                                <!-- <v-divider v-if="group.items.length-1 != itemIndex" :key="item.title"></v-divider>     -->
                            </template>
                        </div>  
                    </template>
                
                </v-list-item-group>
            </v-list>

        </div>
    </div>
</template>

<script>
    export default{
        name: "masters",
        data(){
            return{
                selectedItem:null,
                "listCategories":[  
                    {
                        "name":"Accounts",
                        "items":[
                            {
                                "id":1, 
                                "title":"Ledgers",
                                "uri":"/masters/accounts/ledgers/items",
                                "listId":"ledgers",
                                "routeName":"ledgerItems",
                                "showNew":true
                            },
                            {
                                "id":2, 
                                "title":"Groups",
                                "uri":"/account_group/items",
                                "listId":"account_group",
                                "routeName":"accountsGroupItems",
                                "showNew":true
                            }
                        ]
                    },
                    {
                        "name":"Inventory", 
                        "items":[
                            {
                                "id":1, 
                                "title":"Items",
                                "uri":"/masters/inventory/productandservices/items",
                                "routeName":"productAndServiceItems",
                                "listId":"product",
                                "showNew":true
                            },
                            {
                                "id":2, 
                                "title":"Categories",
                                "uri":"/masters/inventory/product/categories",
                                "listId":"product_categories",
                                "routeName":"productCategoriesItems",
                                "showNew":true
                            },
                            {
                                "id":3, 
                                "title":"Units",
                                "uri":"/inventory/units",
                                "listId":"units",
                                "routeName":"unitItems",
                                "showNew":true
                            }
                        ]
                    },
                    {
                        "name":"Opening", 
                        "items":[
                            {
                                "id":1, 
                                "title":"Ledger Opening Balance",
                                "uri":"/transactions/inventory/openingledger/items",
                                "routeName":"openingLedgerItems",
                                "listId":"opening_ledger",
                                "transactionTypeId":"40003",
                                "showNew":true
                            },
                            {
                                "id":2, 
                                "title":"Opening Stock",
                                "uri":"/transactions/inventory/openingstock/items",
                                "routeName":"stockItems",
                                "listId":"opening_stock",
                                "transactionTypeId":"40001",
                                "showNew":true
                            }
                        ]
                    },
                    {
                        "name":"Others", 
                        "selectedItem":null,
                        "items":[
                            {
                                "id":1, 
                                "title":"Lists",
                                "uri":"/masters/lists",
                                "listId":"lists",
                                "routeName":"listsItems",
                                "showNew":false
                            },
                            {
                                "id":3, 
                                "title":"Expense Categories",
                                "uri":"/masters/expensecategory/items",
                                "listId":"expense_categories",
                                "routeName":"expenseCategoryItems",
                                "showNew":true
                            },
                            {
                                "id":3, 
                                "title":"Expense Items",
                                "uri":"/masters/inventory/productandservices/items",
                                "routeName":"productAndServiceItems",
                                "listId":"product",
                                "showNew":true
                            },
                            {
                                "id":4, 
                                "title":"Tax Category",
                                "uri":"/tax/category/items",
                                "listId":"tax_category",
                                "routeName":"taxCategoryItems",
                                "showNew":true
                            },  
                            {
                                "id":5, 
                                "title":"Taxes",
                                "uri":"/addons/taxes",
                                "listId":"taxes",
                                "routeName":"taxesItems",
                                "showNew":true
                            }, 
                            {   "id":6, 
                                "title":"Discounts and Offers",
                                "uri":"/addons/discounts",
                                "listId":"discounts",
                                "routeName":"discountItems",
                                "showNew":true
                            }, 
                            {
                                "id":7, 
                                "title":"Other Charges",
                                "uri":"/addons/othercharges",
                                "listId":"other_charges",
                                "routeName":"otherChargesItems",
                                "showNew":true
                            }
                        ]
                    }
                ]
            }
        },
        methods:{
            fn_openList: function(item){
                if(item.transactionTypeId){
                    this.$router.push({path:"/"+this.$route.params.businessId+"/"+item.listId, query:{tagId:this.guidWeb(), transactionTypeId:item.transactionTypeId}});
                }
                else if(item.title == "Expense Items"){
                    // this.$router.push({path:item.uri+'/new', query:{tagId:this.guidWeb(), inventory:0}});
                    this.$router.push({path:"/"+this.$route.params.businessId+"/"+item.listId, query:{tagId:this.guidWeb(), inventory:0}});
                }
                else{
                    this.$router.push({path:"/"+this.$route.params.businessId+"/"+item.listId, query:{tagId:this.guidWeb()}});
                }
                // if(item.title == "Expense Items"){
                //     this.$router.push({name:item.routeName, query:{tagId:this.guidWeb(), inventory:0}, params: { 'isNew': 1 }});
                // }
                // else{
                //     this.$router.push({name:item.routeName, query:{tagId:this.guidWeb()}, params: { 'isNew': 1 }});
                // }
            },
            fn_openForm: function(item){
                if(item.title == "Expense Items"){
                    this.$router.push({path:item.uri+'/new', query:{tagId:this.guidWeb(), inventory:0}});
                }
                else{
                    this.$router.push({path:item.uri+'/new', query:{tagId:this.guidWeb()}});
                }
            },
            fn_checkHideStatus: function(){
                if(this.$root.CurrentBusiness && this.$root.CurrentBusiness.GSTRegistrationStatus && this.$root.CurrentBusiness.GSTRegistrationStatus == 1){
                    return true;
                }
                else{
                    return false;
                }
            }
        }
    }
</script>