<template>
    <div>
        <div v-for="(item, index) in items" :key="index">
            <v-divider v-if="index != items.length-1"></v-divider>
            
            <div class="items-wrapper" v-if="filterItems(item.amountType)">
                <v-list two-line full-width nopadding>

                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title>{{item.accountLedgerName}}</v-list-item-title>
                        <v-list-item-subtitle v-if="item.refNumber">Ref No: {{item.refNumber}}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <span v-if="$root.appVersion >= 136">{{ $parent.fn_balanceLabel(item.groupTypeId, item.currentBalance) }}</span>
                            <span v-else>Balance:</span>
                            <span :class="[item.currentBalance < 0 ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(item.currentBalance)}}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                        <v-list-item-action font16>
                          &#8377; {{$root.numberToLocale(item.groupTotal)}}
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props:['items', 'showAll', 'showDebitItems', 'showCreditItems', 'selectedNatureValue'],
        methods:{
            filterItems: function (amountType) {
                if(this.showAll){
                    return true;
                }
                else if(this.showDebitItems && amountType==1){
                    return true;
                }
                else if(this.showCreditItems && amountType==0){
                    return true;
                }
                else{
                    return false;
                }
            },
            // fn_processedBalance: function(balance, balanceType){
            //  let partyBal = this.$root.numberToLocale(this.roundUpto2Decimals(balance));
            //  let balanceString = 'Current Bal: ₹ '+ partyBal;
            //  if(balanceType==1){
            //      balanceString = balanceString+' Dr';
            //  }
            //  else if(balanceType==0){
            //      balanceString = balanceString+' Cr';
            //  }

            //  return balanceString;
            // },
        }

    }
</script>