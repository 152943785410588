<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>

              <v-toolbar :height="56" flat>
                  <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                  <v-toolbar-title>Group</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <v-btn color="primary" text @click="$parent.fn_checkValidations"><v-icon left>save</v-icon> Save</v-btn> -->
                 <!--  <div>
                      <div style="float: left;margin-right: 12px;margin-top: 6px;color: rgba(0,0,0,0.57);">Primary Group</div>
                      <v-switch class="hide-margins" hide-details v-model="$parent.Form.isPrimaryAccountGroup"></v-switch>
                  </div> -->
              </v-toolbar>

              <v-divider class="noprint"></v-divider>

              <div class="data-container grey-back">

              <ValidationObserver ref="observer">

                  <v-form @submit.prevent="submit">

                

                      <v-layout row wrap ma-0 pr-4 pl-4 white-back pt-4 pb-4>
                        <v-flex xs12 sm12 md12>
                          <ValidationProvider name="Name" rules="required|max50" v-slot="{ errors }">
                            <v-text-field
                                id="title"
                                v-model="$parent.Form.name"
                                label="Name"
                                required
                                autocomplete="null"
                                :error-messages="errors">
                            </v-text-field>
                          </ValidationProvider>
                        </v-flex>

                        <v-flex xs12 sm12 md12 pt-4 v-if="$parent.Form.isPrimaryAccountGroup">
                          <ValidationProvider name="Nature" rules="required" v-slot="{ errors }">
                              <v-autocomplete
                                :items="$parent.natureList"
                                v-model="$parent.Form.nature"
                                label="Nature"
                                item-text="title"
                                item-value="id"
                                required
                                :error-messages="errors">
                            </v-autocomplete>
                          </ValidationProvider>
                        </v-flex>

                        <v-flex xs12 sm12 md12 pt-4 v-else>
                          <ValidationProvider name="Parent group" rules="required" v-slot="{ errors }">
                            <v-autocomplete
                                :items="$parent.accountGroups"
                                v-model="$parent.Form.selectedParentGroup"
                                label="Parent Group"
                                item-value="itemId"
                                item-text="itemName"
                                return-object
                                append
                                required
                                :error-messages="errors"
                              >

                              <v-list-item @click="fn_new" slot="prepend-item" class="primary--text">
                                <v-list-item-title>Create New</v-list-item-title>
                              </v-list-item>

                               <!-- <template v-slot:item="data">
                                  <v-list-item-content>
                                    <v-list-item-title v-html="data.item.itemName"></v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.groupTypeId == 101 ? 'Customer' : data.item.groupTypeId == 102 ? 'Supplier' : 'Other'}}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template> -->

                            </v-autocomplete>
                          </ValidationProvider>
                        </v-flex>

                        <v-flex xs12 sm12 md12>
                            <v-checkbox label="Primary Group" v-model="$parent.Form.isPrimaryAccountGroup"></v-checkbox>
                        </v-flex>
                        
                      </v-layout>

              </v-form>

            </ValidationObserver>

          </div>

          <v-card-actions white-back>
              <v-layout row wrap ma-0>

                  <v-flex xs12 sm12 md12 height48>
                      <!-- <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow @click="handleSubmit($parent.fn_checkValidations)">Save</v-btn> -->
                      <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                  </v-flex>

              </v-layout>
          </v-card-actions>
                  

          </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
// import { required } from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })
    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
                this.$parent.disableOnSave = false;
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_validateForm: function(){
              // let self = this;
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                // alert(result);
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                  // alert(JSON.stringify(self.scrollOptions));
                  // setTimeout(function(){
                  //   self.$vuetify.goTo('.error--text', self.scrollOptions);
                  // },300);
                }
              });
            },
            fn_new: function(){
              this.closeDialogWeb();
              setTimeout(()=>{
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/account_group/items/new', query:{tagId:this.guidWeb(), ls:1}});
              },100);
            }
        }
    }
</script>