<template>
    <div class="display_wrapper">

        <v-toolbar :height="56" flat>

            <v-toolbar-title>Business Profile</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn outlined small @click="fn_edit" color="primary">
                <v-icon left>edit</v-icon> Edit
            </v-btn>

        </v-toolbar>

        <v-divider></v-divider>
        
        <div class="data-container white-back">
            <v-layout ma-0 row wrap v-if="businessData">
                <v-flex pa-4 xs12 sm6 md6>
                    <div>Business Name</div>
                    <div><strong>{{businessData.BusinessName}}</strong></div>
                </v-flex>
                <v-flex pa-4 xs12 sm6 md6>
                    <div>Business Owner Name</div>
                    <div><strong>{{businessData.UserName ? businessData.UserName : '-'}}</strong></div>
                </v-flex>
                <v-flex pa-4 xs12 sm6 md6>
                    <div>Address</div>
                    <div><strong>{{businessData.Address ? businessData.Address : '-'}}</strong></div>
                </v-flex>
                <v-flex pa-4 xs12 sm6 md6>
                    <div>State</div>
                    <div><strong>{{businessData.State}}</strong></div>
                </v-flex>
                <!-- <v-flex pa-4 xs12 sm6 md6>
                    <div>Business Profile Number</div>
                    <div><strong>{{businessData.BusinessProfileNumber}}</strong></div>
                </v-flex> -->
                <v-flex pa-4 xs12 sm6 md6>
                    <div>GST Registration Status</div>
                    <div><strong>{{registrationType}}</strong></div>
                </v-flex>
                <v-flex pa-4 xs12 sm6 md6 v-if="businessData.GSTRegistrationStatus == 1 || businessData.GSTRegistrationStatus == 2">
                    <div>GST Number</div>
                    <div><strong>{{businessData.GSTNumber}}</strong></div>
                </v-flex>
                <!-- <v-flex pa-4 xs12 sm6 md6 v-if="businessData.GSTRegistrationStatus == 1">
                    <div>HSN Applicable</div>
                    <div><strong>{{businessData.HSNApplicable ? "Yes" : "No"}}</strong></div>
                </v-flex> -->
                <v-flex pa-4 xs12 sm6 md6 v-if="businessData.GSTRegistrationStatus == 1">
                    <div>Tax Type</div>
                    <div><strong>{{businessData.TaxMode == 1 ? "Exclusive" : businessData.TaxMode == 2 ? "Inclusive" : "None"}}</strong></div>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>

    export default{
        data(){
            return{
                businessData:null,
                registrationTypes:[{"status":1, "name":"Registered - Regular"},{"status":2, "name":"Registered - Composite"},{"status":3, "name":"Unregistered"}],
            }
        },
        methods: {

            fn_getBusinessMasterData: function(){
                let self = this;
                this.getData('BusinessDetail', this.$root.CurrentBusiness.BusinessID, 
                    {
                      onSuccess: function(response){
                          console.log(response);
                          self.businessData = JSON.parse(response);
                      }, 
                      onError: function(error){
                          console.log(error);
                      }
                    }
                );
            },

            fn_edit: function(){
                let listView = this.$route.params.listId ? this.$route.params.listId : 'dashboard'
                this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/' + listView + '/business/profile/edit'});
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){
            this.fn_getBusinessMasterData();

            let isCalled = false;
            this.$eventHub.$on('connected', () => {
                setTimeout(() => {
                    if(!isCalled){
                        isCalled = true;
                        this.disableOnSave = false;
                        this.saveBtnKey++;
                        this.fn_getBusinessMasterData();
                    }

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);
                }, 1000);
            });
        },

        computed:{
            registrationType: function(){
                let type = null;
                for(let i in this.registrationTypes){
                    if(this.businessData.GSTRegistrationStatus == this.registrationTypes[i].status){
                        type = this.registrationTypes[i].name;
                        break;
                    }
                }
                return type;
            }
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('connected');
            next(true)
        },
    }
</script>