<template>
    <div class="display_wrapper">

        <v-toolbar :height="56" flat>

            <v-toolbar-title>Unit</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn outlined small @click="fn_edit" color="primary" v-if="displayData && !displayData.isSystem">
                <v-icon left>edit</v-icon> Edit
            </v-btn>

            <v-btn class="ml-4" small outlined @click="fn_openDialogConfirmation" color="red" v-if="displayData && !displayData.isSystem">
                <v-icon left>delete</v-icon> Delete
            </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back" v-if="displayData">
            <div class="title pa-4 white-back">{{displayData.name}}</div>

            <v-list class="mt-2 nopadding" two-line>
                <v-subheader light>Details</v-subheader>
                <v-divider></v-divider>
                <div v-if="false">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Short Name</v-list-item-title>
                            <v-list-item-subtitle>{{displayData.shortName ? displayData.shortName : '-'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>
                <!-- <div>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Decimal Places</v-list-item-title>
                            <v-list-item-subtitle>{{displayData.decimalPlaces ? displayData.decimalPlaces : '-'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </div> -->
                <div v-if="$root.CurrentBusiness.GSTRegistrationStatus==1">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Unit Qty Code</v-list-item-title>
                            <v-list-item-subtitle>{{fn_getUnitQuantityName(displayData.unitQuantityCode)}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </div>
               <!--  <div v-if="displayData.baseUnitId">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Base Unit</v-list-item-title>
                            <v-list-item-subtitle>{{displayData.baseUnit.name}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>
                <div>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Conversion Type</v-list-item-title>
                            <v-list-item-subtitle>{{displayData.conversionType == 101 ? 'Nil' : displayData.conversionType == 102 ? 'Custom' : 'Fixed'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>
                <div v-if="displayData.conversionType == 103">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Conversion Quantity</v-list-item-title>
                            <v-list-item-subtitle>{{displayData.conversionQuantity}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </div> -->

            </v-list>
        </div>

        <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);" v-if="loading">
          <v-col cols="6">
            <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
          </v-col>
        </v-row>

        <dialog-confirmation ref="ref_dialogDeleteConfirmation" header="Delete Master?" :actionMethod="fn_deleteMaster" params="delete" :displayView='true'></dialog-confirmation>

    </div>
</template>

<script>
// const menuItem = require('../../../../config.js').configs.menuItem;
const unitQuantityCodes = require('../../../../config.js').configs.unitQuantityCodes;
import {masterMixin} from '../../master_mixin.js'
import dialogConfirmation from '../../../dialogs/dialog_confirmation'

    export default{
        mixins: [masterMixin],
        data(){
            return{
                displayData:null,
                loading:false
            }
        },
        components:{
            'dialog-confirmation': dialogConfirmation
        },
        methods:{
            fn_getUnitQuantityName: function(code){
                for(let i=0; i<unitQuantityCodes.length; i++){
                    if(unitQuantityCodes[i].id == code){
                        return unitQuantityCodes[i].name;
                    }
                }
            },

            fn_get: function(itemId){
                var self = this;
                this.loading = true;
                this.displayData = null;
                this.getData('Units', itemId, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          self.displayData = JSON.parse(response);
                          self.displayData.shortName == 'null' ? self.displayData.shortName = null : '';
                          self.loading = false;
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                        }
                    }
                );
            },
            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.ToolbarTitle = "Units";
            //  viewObject.TopMenu = [menuItem.edit];
            //  viewObject.isBottomMenu = true;

            //  var tagId = null;
            //  this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';

            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null, 
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.copy]};
            //  !this.displayData.isSystem ? bottomMenu.BottomMenu.push(menuItem.delete) : '';

            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='edit' && this.displayData && this.displayData.unitId){
            //      this.fn_edit();
            //  }
            //  else if(menuAction=='copy'){
            //      this.$router.push({path:'/inventory/units/new', query:{tagId:this.guidWeb(), itemId:this.displayData.unitId, copy:true}});
            //  }
            //  else if(menuAction=='delete'){
            //      this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            //  }
            // },

            fn_openDialogConfirmation: function(){
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            },

            fn_deleteMaster: function(){
                let self = this;
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = false;
                this.fn_deleteMasterItem('units', this.displayData.unitId, function(response){
                    if(response){
                        // alert('Item deleted');
                        self.$eventHub.$emit('snackbarAlert', {msg:'Item deleted'});
                        self.$eventHub.$emit('UpdateUnitList', {});
                        self.$router.go(-1);
                    }
                    else{
                        // alert("Item cannot be deleted as already in use");
                        self.$eventHub.$emit('snackbarAlert', {msg:'Item cannot be deleted as already in use'});
                    }
                })
            },

            fn_edit: function () {
                this.$router.push('/'+this.$route.params.businessId+'/'+this.$route.params.listId+'/unit/items/'+this.displayData.unitId+'/edit');
            }
        },
        mounted: function(){
            
            // this.fn_showWebView();

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            
            if(this.$route.params && this.$route.params.tagId){
                this.fn_get(this.$route.params.tagId);
            }

            this.$eventHub.$on('delete', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_openDialogConfirmation() : '';
            });

            this.$eventHub.$on('edit', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_edit() : '';
            });

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteUpdate(to, from, next){
            // alert('up');
            console.log(from);
            next(true)
            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }
            
            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('connected');
            next(true)
        },
    }

</script>