<template>
    <div grey-back full-height>

        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>
        <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>

    </div>
</template>

<script>
// const dialogjs =  require('../dialog').dialogjs;
// const menuItem = require('../../../../config.js').configs.menuItem;
const accountGroupIds = require('../../../../config.js').configs.accountGroupIds;

import dialogDiscard from '../../../dialogs/dialog_discard'
import {draftMixin} from '../../../draft_mixin'

import dialogForm from './dialogs/dialog_form'

    export default{
        mixins: [draftMixin],
        data(){
            return{
                Form:{"expenseCategoryId":null, "name":null, "accountLedgerId":null, "accountLedgerName":null, "isSystem":0, "selectedLedger":null},
                tagId:null,
                draftStorageId:null,
                dialogStorageId:null,
                backButtonPress: false,
                snackbar:false,
                snackbar_msg:null,
                unwatch:false,
                accountLedgers:null,
                disableOnSave:false
            }
        },
        watch:{
            'Form': {
                handler: function (){
                     // this.fn_saveToLocalStorage(this);
                     if(!this.unwatch){
                        this.fn_saveDraft(this.draftStorageId, this.Form.name);
                     }
                     this.unwatch = false;
                 },
                 deep: true
            }
        },
        components:{
            'dialog-discard': dialogDiscard,
            'dialog-form':dialogForm
        },
        methods:{

            fn_closeDialogForm: function(){
                this.askToDiscard = true;
                if(this.fn_showDialogDiscard()){
                    this.$refs['ref_dialogDiscard'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            // fn_accountLedgers: function(val){
            //   let filterObject = {};
            //   filterObject.search = val ? val.toLowerCase() : '';
            //   filterObject.accountGroupIds = [accountGroupIds.indirectExpense.id, accountGroupIds.directExpense.id, accountGroupIds.fixedAssets.id];
              
            //   let self = this;
            //   this.getDialogItems("AccountDialog", filterObject, 
            //     {
            //       onSuccess: function (response) {
            //         console.log(response);
            //         self.accountLedgers = JSON.parse(response);
            //       },
            //       onError: function (error) {
            //         console.log(error);
            //       }
            //     }
            //   )
            // },

            // fn_openDialogDiscard: function(){
            //  this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
            // },

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_openAccountLedgers: function (platform, name, ref, itemId, itemName) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.platform = platform;

            //  var self = this;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select Ledger";

            //  let menuItemCustom = {"MenuID":null, "Icon":null, "Text":"New", "Action":"new"};
            //  viewDialogObject.TopMenu = [menuItemCustom];
            //  viewDialogObject.isBottomMenu = false;

            //  viewDialogObject.accountGroups = [accountGroupIds.indirectExpense.id, accountGroupIds.directExpense.id, accountGroupIds.fixedAssets.id];
            //  // if(ref=='ref_expenseLedger'){
            //  //  viewDialogObject.accountGroups = [accountGroupIds.indirectExpense.id, accountGroupIds.directExpense.id, accountGroupIds.fixedAssets.id];
            //  // }

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":itemId, "name":itemName};

            //  var self = this;
            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'accounts', tagId, paramObject, ref);
            // },

            // fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
            //  this.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
            // },

            // fn_updateAccountLedger: function (selectedValueId, selectedValueName, isRemoved) {
            //  if(!isRemoved){
            //      this.Form.accountLedgerId = selectedValueId;
         //         this.Form.accountLedgerName = selectedValueName;
         //         this.fn_validateExpenseLedger();
         //         this.errors.remove('');
            //  }
            //  else{
            //      this.Form.accountLedgerId = null;
         //         this.Form.accountLedgerName = null;
            //  }
            //  this.$forceUpdate();
            // },

            // fn_validateExpenseLedger: function (partyGroups) {
            //  let filterObject = {};
            //  let partyId = this.Form.accountLedgerId;
            //  filterObject.accountGroups = [accountGroupIds.indirectExpense.id, accountGroupIds.directExpense.id, accountGroupIds.fixedAssets.id];

            //  let self = this;
            //  this.getFilterData('ValidateParty', partyId, filterObject, 
            //      {
            //          onSuccess: function(response){
            //              console.log(response);
            //              let isPartyValid = JSON.parse(response);
            //              if(!isPartyValid){
            //                  self.Form.accountLedgerId = null;
            //                  self.Form.accountLedgerName = null;
            //                  alert("Invalid Expense Ledger");
            //              }
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }
            //      }
         //        );
            // },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.save, menuItem.reset, menuItem.help]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_closeDialog: function (tagId) {
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_checkValidations: function(){
                this.fn_post();
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('ExpenseCategory', dataObj, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          let status = JSON.parse(response);
                          if(status) {
                            self.fn_cleanUp();
                          }
                          else {
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            self.$eventHub.$emit('snackbarAlert', {msg:'Already exists with this name'});
                          }
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                        }
                    }
                );
            },

            fn_cleanUp: function () {

                // this.loadedFromDraft = false;

                if(this.$route.query.ls){
                    let data = {};
                    data.type = 'expenseCategory';
                    data.id = this.Form.expenseCategoryId;
                    data.name = this.Form.name;
                    localStorage.setItem('newItem', JSON.stringify(data));
                    // localStorage.setItem('closeDialog', 1);
                }

                this.$eventHub.$emit('UpdateExpenseCategory', {});
                this.fn_goBack();
            },

            fn_post: function(){
                if(!this.Form.expenseCategoryId){
                    this.Form.expenseCategoryId = this.guidWeb();
                    // this.Form.createdDate = new Date();
                    this.Form.createdDate = this.$root.fn_getLocalDateFormat();
                }
                // this.Form.modifiedDate = new Date();
                this.Form.modifiedDate = this.$root.fn_getLocalDateFormat();
                this.Form.name = this.Form.name.trim();

                this.Form.accountLedgerId = this.Form.selectedLedger.itemId;
                this.Form.accountLedgerName = this.Form.selectedLedger.itemName;
                
                console.log(this.Form);
                this.fn_saveLocal(this.Form);
            },

            fn_get: function(itemId, copyData, bindData){
                var self = this;
                this.unwatch = true;
                this.getData('ExpenseCategory', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            
                            var item = JSON.parse(response);

                            if(bindData){
                                self.fn_bindData(item, copyData);
                            }
                            
                            
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_bindData: function(item, copyData){
                this.unwatch = true;
                this.Form.name = item.name;
                this.Form.expenseCategoryId = item.expenseCategoryId;
                this.Form.accountLedgerId = item.accountLedgerId;
                this.Form.accountLedgerName = item.accountLedgerName;

                this.Form.selectedLedger = {"itemId":item.accountLedgerId, "itemName":item.accountLedgerName}

                this.Form.isSystem = item.isSystem;

                if(copyData){
                    this.Form.expenseCategoryId=null;
                    this.Form.isSystem = 0;
                    this.fn_saveDraft(this.draftStorageId, this.Form.name);
                    // alert("Expense Category copied");
                    this.$eventHub.$emit('snackbarAlert', {msg:'Expense Category copied'});
                }

                this.$root.fn_copyFormObject(this.Form, this.formId);
            },

            fn_clearForm: function(){
                this.Form = {"expenseCategoryId":null, "name":null, "accountLedgerId":null, "accountLedgerName":null, "isSystem":0};
                this.$validator.reset();
            },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use

            //  this.backButtonPress = false;
                
            //  if(menuAction=='save'){
            //      this.fn_checkValidations();
            //  }
            //  else if(menuAction=='new'){
            //      this.fn_saveDraft(this.Form.name);
            //      this.$router.push({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), groupTypeId:103, ls:1}})
            //  }
            // },

            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.ToolbarTitle = "Expense Category";
            //  viewObject.TopMenu = [menuItem.save];
            //  viewObject.isBottomMenu = true;

            //  var tagId = this.formId;
            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null,
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            fn_setNewItem: function(){
                if(localStorage.getItem('newItem')){

                    let itemData = JSON.parse(localStorage.getItem('newItem'));

                    switch (itemData.type) {
                        case "ledger":
                            // this.fn_updateAccountLedger(itemData.id, itemData.name, false);
                            this.Form.selectedLedger = {"itemId":itemData.id, "itemName":itemData.name};
                            break;
                        default:
                            console.log("condition not matched");
                    }

                    localStorage.removeItem('newItem');
                }

                this.$route.query && this.$route.query.tagId ? this.$root.fn_copyFormObject(this.Form, this.formId) : '';
            },

            fn_goBack: function(){
                this.$root.viaKeyboardShortCut = false;
                this.$refs['ref_dialogForm'].closeDialogWeb();
                localStorage.removeItem(this.draftStorageId);
                this.backButtonPress = true;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            }

        },
        mounted: function(){
            // var self = this;

            if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }

            setTimeout(()=>{this.fn_setNewItem()},300);

            if(localStorage.getItem(this.draftStorageId)){
                let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
                this.Form = JSON.parse(dataObj.formData);
            }

            // this.fn_accountLedgers();
            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });

        },
        computed:{
            filteredLedgers: function(){
                let groups = [accountGroupIds.indirectExpense.id, accountGroupIds.directExpense.id, accountGroupIds.fixedAssets.id]
                return this.$root.ledgers.filter(item => {
                    let itemIndex = groups.findIndex(groupId => groupId == item.accountGroupId);
                    return itemIndex != -1 ? true :  false;
                })
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {

                let itemId = null;
                let copyData = false;

                // to.query && to.query.copy && from.name == 'itemlist' ? copyData = true : '';
                to.query && to.query.copy ? copyData = true : '';

                // if(to.query && to.query.itemId && from.name == 'itemlist'){
                //     itemId = to.query.itemId;
                // }
                if(to.query && to.query.itemId){
                    itemId = to.query.itemId;
                }

                if(to.params && to.params.tagId){//from display view
                  itemId = to.params.tagId;
                }

                if(vm.$route.query && vm.$route.query.tagId){//in case of new form
                    vm.formId = vm.$route.query.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }
                else if(vm.$route.params && vm.$route.params.tagId){//in case of edit form
                    vm.formId = vm.$route.params.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }

                if(itemId){
                    !localStorage.getItem(vm.draftStorageId) ? vm.fn_get(itemId, copyData, true) : '';
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            // !vm.accountLedgers ? vm.fn_accountLedgers() : '';
                            itemId ? vm.fn_get(itemId, copyData, true) : '';
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                        
                    }, 1000);
                });

            })
        },
        beforeRouteLeave (to, from, next) {
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('connected');
                next();
            }
        },
        // beforeRouteUpdate (to, from, next) {
            
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  var existingForm = false;
        //  if(dialogData && dialogData.length){
        //      var openForms = JSON.parse(localStorage.getItem('accountgroupsforms'));
        //      for(var i=0; i<openForms.length; i++){
        //          if(openForms[i]==to.query.tagId){
        //              existingForm = true;
        //              dialogjs.fn_checkAndCloseOpenDialogs(this);
        //              break;
        //          }
        //      }
        //      existingForm ? next(false) : next(true);
        //  }
        //  else{
        //      next();
        //  }
     //    },
     //    beforeRouteLeave (to, from, next) {
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  if(dialogData && dialogData.length){
        //      if(this.backButtonPress){
        //          dialogjs.fn_checkAndCloseOpenDialogs(this);
        //          next(false);
        //      }
        //      else{
        //          this.fn_saveDraft(this.Form.name);
        //          next(true);
        //      }
        //  }
        //  else{

        //      if(this.fn_showDialogDiscard()){
        //          this.fn_openDialogDiscard();
        //          next(false);
        //      }
        //      else{
        //          this.fn_clearData('accountgroupsforms', function(){
        //              next();
        //          })
        //      }
                
        //  }
     //    }
    }

</script>