<template>
    <div>
        <v-dialog v-model="dialog_logo" no-click-animation persistent lazy content-class="full-height" scrollable transition="slide-y-transition" max-width="460px">

            <v-card class="business-logo-view">
                <v-toolbar flat :height="56">
                    <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>Business Logo</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider> 

                <v-alert type="info" :value="true">
                    <div>Upload your business logo.</div>
                    <div>Business logo will appear in all invoice templates.</div>
                </v-alert>

                <v-card-text class="pt-4" full-height nopadding>
                    
                    <v-card-text nopadding center-align>
                      <p class="title" style="line-height:28px !important">{{$root.CurrentBusiness.BusinessName}}</p>
                      <img :src="$root.CurrentBusiness.businessLogo" @click="fn_pickImage" v-if="$root.CurrentBusiness.businessLogo" class="logo-img"/>
                      <div grey-back class="logo-placeholder" v-else @click="fn_pickImage" v-ripple>Logo</div>

                      <div class="pt-8">
                        <v-btn outlined v-if="$root.CurrentBusiness.businessLogo" @click="fn_removeLogo">Remove</v-btn>
                        <v-btn color="primary" :class="{'ml-4':$root.CurrentBusiness.businessLogo}" @click="fn_pickImage">{{$root.CurrentBusiness.businessLogo ? 'Change' : 'Upload'}}</v-btn>
                      </div>

                    </v-card-text>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                    <!-- <v-btn color="blue darken-1" text @click.native="fn_checkValidations">ok</v-btn> -->
                </v-card-actions>

                <input type="file" id="logoPicker" accept="image/png, image/gif, image/jpeg"  style="display:none" @change="fn_selectFile"/>
            </v-card>   
        </v-dialog>

        <dialog-crop-image ref="ref_dailogCropImage" :uploadLogo="fn_uploadLogo"></dialog-crop-image>
    </div>
</template>     

<script>

import dialogCropImage from './dialog_crop_image'

export default{
    data(){ 
        return{
            dialog_logo: false,
        }
    },
    components:{
      'dialog-crop-image':dialogCropImage
    },
    methods:{
        openDialogWeb: function () {
            this.dialog_logo = true;
        },

        closeDialogWeb: function () {
            this.dialog_logo = false;
        },

        fn_pickImage: function(){
          document.getElementById("logoPicker").value = "";
          document.getElementById("logoPicker").click();
        },

        fn_selectFile: function(event){
          console.log(event.target.files);
          let self = this;
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = function () {
            console.log(reader.result);
            self.fn_bindImage(reader.result);
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
        },

        fn_bindImage: function(imageUri){
          console.log(imageUri);
          this.$refs['ref_dailogCropImage'].dialog_crop_image = true;
          let self = this;
          setTimeout(function(){
            self.$refs['ref_dailogCropImage'].$refs.croppieRef.bind({
              url: imageUri
            }); 
          },1000);
        },

        fn_uploadLogo: function(imgUri){
          let self = this;
          let filterObject = {};
          filterObject.businessLogo = imgUri;

          this.postFilterData('UploadLogo', this.$root.CurrentBusiness.BusinessID, filterObject, 
            {
                onSuccess: function(response){
                    console.log(response);
                    // self.fn_backupAllBusinessData()
                    self.$root.CurrentBusiness.businessLogo = imgUri;
                    self.$refs['ref_dailogCropImage'].dialog_crop_image = false;
                    // alert("Business logo updated");
                    self.$eventHub.$emit('snackbarAlert', {msg:'Business logo updated'});
                    // if(self.$route.query && self.$route.query.preview_template){
                    //   self.fn_goBack();
                    // }
                }, 
                onError: function(error){
                    console.log(error);
                    // alert('Something went wrong. Try again');
                    self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                }
            }
          );
        },

        fn_removeLogo: function(){
          let self = this;
          // this.fn_closeDialogConfimation();
          let postObj = {"businessId":this.$root.CurrentBusiness.BusinessID};
          this.postData('RemoveBusinessLogo', postObj, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      self.$root.CurrentBusiness.businessLogo = null;
                    //   alert('Logo removed');
                    self.$eventHub.$emit('snackbarAlert', {msg:'Logo removed'});
                  },
                  onError: function(error){
                      console.log(error);
                  }
              }
          );
        },
    }
}
</script>