<template>
    <div grey-back>
        <v-list nopadding class="mt-2" two-line v-if="data">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Ledger</v-list-item-subtitle>
                    <v-list-item-title>{{data.accountLedgerName}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
    export default{
        props:['data']
    }
</script>