export const data = new Object({
    transactionData:{
        "transactionId":null,
        "prefix":null, 
        "transactionNumber":null, 
        "mergeTransactionNumber":null,
        "suffix":null, 
        "transactionDate":null, 
        "transactionListId":null, 
        "transactionTypeFormId":null, 
        "refNumber":null, 
        "refDate":null, 
        "partyId":null, 
        "salesmanId":null, 
        "currencyId":null, 
        "amount":null, 
        "roundedAmount":null, 
        "status":1, 
        "notes":null, 
        "dueType":null, 
        "dueDate":null, 
        "paymentMode":null, 

        "additionalData":null, 
        "createdDate":null, 
        "createdBy":null, 
        "modifiedDate":null, 
        "modifiedBy":null, 

        "discount":null,
        "tax":null,
        "otherCharges":null,
        "grossAmount":null,
        "netAmount":null,
        "taxableValue":null,

        "routeId":null,
        // "invoiceGroupType":null,
        // "InvoiceGroup":null//Tax invoice/Bill of Supply
        "invoiceType":null,
        "exportType": null
     },

})