<template>
  <v-dialog v-model="dialog_action" persistent no-click-animation width="340">
      <v-card flat>
        <v-card-title v-if="title">{{title}}</v-card-title>
        <v-card-text style="color: rgba(0,0,0,0.72);" v-html="message" class="pt-3"></v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :color="cancelBtnText == 'Logout' ? 'red' : 'primary'" text @click="closeDialogWeb" v-if="cancelBtnText">{{cancelBtnText}}</v-btn>
          <v-btn color="primary" text @click="actionMethod">{{btnText}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  export default{
    props:['title', 'message', 'cancelBtnText', 'btnText', 'actionMethod', 'cancelMethod'],
    data(){
      return{
        dialog_action:false
      }
    },
    methods:{
        openDialogWeb: function(){
            this.dialog_action = true;
        },
        closeDialogWeb: function(){
            if(this.cancelMethod){
                this.cancelMethod();
            }
            this.dialog_action = false;
        },
    }
  }
</script>