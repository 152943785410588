<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>Ledger</v-toolbar-title>
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back">

                  <ValidationObserver ref="observer">

                  <v-form @submit.prevent="submit">

                    <v-layout row wrap ma-0 pb-3 white-back>

                      <v-flex form-item xs12 sm12 md12 pb-3 pt-3>
                          <v-radio-group v-model="$parent.Form.groupTypeId" row @change="$parent.fn_groupTypeChange">
                            <v-radio label="Customer" :value="101"></v-radio>
                            <v-radio label="Supplier" :value="102"></v-radio>
                            <v-radio label="Other" :value="103"></v-radio>
                            <v-radio label="Branch/Division" :value="104" v-if="$root.TransactionSettings.branchDivision"></v-radio>
                          </v-radio-group>
                      </v-flex>

                      <v-flex form-item xs6 sm6 md6>

                        <ValidationProvider name="Name" rules="required|max50" v-slot="{ errors }">
                             <v-text-field 
                                 v-model="$parent.Form.name" 
                                 :label="$parent.fn_getNameLabel()"
                                 required
                                 autocomplete="null"
                                 :error-messages="errors">
                             </v-text-field>
                         </ValidationProvider>
                      </v-flex>

                      <v-flex form-item xs6 sm6 md6>

                        <ValidationProvider name="Account Group" rules="required" v-slot="{ errors }">
                            <v-autocomplete
                              :items="$parent.accountGroups ? $parent.accountGroups : []"
                              v-model="$parent.Form.selectedAccountGroup"
                              label="Account Group"
                              item-text="itemName"
                              item-value="itemId"
                              return-object
                              append
                              required
                             autocomplete="null"
                             :error-messages="errors">

                              <v-list-item slot="prepend-item" class="primary--text" @click="fn_newAccountGroup">
                                <v-list-item-title>Create New</v-list-item-title>
                              </v-list-item>

                          </v-autocomplete>

                        </ValidationProvider>
                      </v-flex>
                    
                    </v-layout>


                    <div class="mt-2" full-width v-if="$parent.Form.groupTypeId!=103">

                      <v-subheader white-back full-width>Contact</v-subheader>

                      <v-divider></v-divider>

                      <v-layout white-back ma-0 row wrap pt-4 pb-3>

                          <v-flex xs6 sm6 md6 form-item prepend-text>
                              
                              <v-text-field  
                                label="Mobile Number" 
                                placeholder=" " 
                                type="tel" 
                                autocomplete="null"
                                v-model="$parent.Form.primaryMobileNumber">
                              </v-text-field>

                          </v-flex>

                          <v-flex xs6 sm6 md6 form-item>
                            <ValidationProvider name="Email" rules="email" v-slot="{ errors }">
                              <v-text-field  
                                label="Email" 
                                type="email"
                                required
                                placeholder=" " 
                                autocomplete="null"
                                v-model="$parent.Form.email"
                                :error-messages="errors">
                              </v-text-field>
                            </ValidationProvider>
                          </v-flex>

                      </v-layout>
                    </div>

                    <div class="mt-2" full-width>

                      <v-layout white-back ma-0 row wrap pt-4>

                          <v-flex xs6 sm6 md6 form-item prepend-text>
                              
                              <v-select
                                label="Opening"
                                :items="$parent.Form.groupTypeId != 103 ? $parent.openingTypes : $parent.openingTypesOther"
                                v-model="$parent.Form.openingNature"
                                item-text="itemName"
                                item-value="itemId"
                              ></v-select>

                          </v-flex>

                          <v-flex xs6 sm6 md6 form-item>
                            <v-text-field   
                              label="Amount"
                              prefix="₹"
                              clearable
                              type="number"
                              autocomplete="null"
                              v-model="$parent.Form.openingAmount">
                            </v-text-field>
                          </v-flex>

                      </v-layout>
                    </div>

                    <div class="mt-2" full-width v-if="$root.appVersion >= 109 && $parent.Form.groupTypeId!=103">

                      <v-layout white-back ma-0 row wrap pt-4>

                          <v-flex xs12 sm12 md12 form-item>
                            <v-text-field   
                              label="MSME Udyam No"
                              v-model="$parent.Form.msmeUdyamNumber">
                            </v-text-field>
                          </v-flex>

                      </v-layout>
                    </div>

                    <div class="pt-2" full-width v-if="$parent.Form.groupTypeId!=103">

                      <v-subheader class="pr-0" white-back full-width>GST Details
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$parent.fn_addGstDetails">{{!$parent.Form.gstType ? 'Add' : 'Edit'}}</v-btn>
                      </v-subheader>

                    <v-divider v-if="$parent.Form.selectedGstType"></v-divider>

                    <div class="mb-2 white-back" v-if="$parent.Form.gstType && ($parent.Form.gstType != 104 || ($parent.Form.gstType == 104 || $parent.Form.placeOfSupply))" full-width>

                        <v-layout font14 row wrap ma-0 pt-4 pl-4 pr-4>
                            <v-flex xs4 sm4 md4 pb-4 v-if="$parent.Form.gstType">
                                <div><strong>GST Type</strong></div>
                                <div>{{$parent.fn_getGstTypeName($parent.Form.gstType)}}</div>
                            </v-flex>

                            <v-flex xs4 sm4 md4 pb-4 v-if="$parent.Form.placeOfSupply">
                                <div><strong>State/Place of Supply</strong></div>
                                <div>{{$parent.Form.placeOfSupply}}</div>
                            </v-flex>

                            <v-flex xs4 sm4 md4 pb-4 v-if="$parent.Form.gstNumber">
                                <div><strong>GST Number</strong></div>
                                <div>{{$parent.Form.gstNumber}}</div>
                            </v-flex>

                            <v-flex xs4 sm4 md4 pb-4 v-if="$parent.Form.panNumber">
                                <div><strong>PAN Number</strong></div>
                                <div>{{$parent.Form.panNumber}}</div>
                            </v-flex>

                            <v-flex xs4 sm4 md4 pb-4 v-if="$parent.Form.country">
                                <div><strong>Country</strong></div>
                                <div>{{$parent.Form.country}}</div>
                            </v-flex>
                        </v-layout>


                    </div>

                    <!-- <v-layout row wrap white-back ma-0 pt-4 pb-3 v-if="$parent.Form.selectedGstType && $parent.Form.selectedGstType.id && ($parent.Form.selectedGstType.id != 104 || ($parent.Form.selectedGstType.id == 104 && $parent.Form.selectedGstState && $parent.Form.selectedGstState.itemId))">

                          <v-flex xs6 sm6 md6 form-item>

                            <ValidationProvider name="GST Type" rules="required" v-slot="{ errors }">

                              <v-autocomplete
                                  :items="$parent.gstTypes"
                                  v-model="$parent.Form.selectedGstType"
                                  label="Select GST Type"
                                  item-value="id"
                                  item-text="title"
                                  return-object
                                  required
                                  :error-messages="errors">
                              </v-autocomplete>

                            </ValidationProvider>

                          </v-flex>

                          <v-flex xs6 sm6 md6 form-item v-if="$parent.Form.selectedGstType.id!=105">

                            <ValidationProvider name="State" rules="required" v-slot="{ errors }">

                              <v-autocomplete
                                  :items="$parent.states ? $parent.states : []"
                                  v-model="$parent.Form.selectedGstState"
                                  label="State/Place of Supply"
                                  item-value="itemId"
                                  item-text="itemName"
                                  return-object
                                  required
                                  :error-messages="errors">
                              </v-autocomplete>

                            </ValidationProvider>

                          </v-flex>

                          <v-flex xs6 sm6 md6 form-item v-if="$parent.Form.selectedGstType.id==101 || $parent.Form.selectedGstType.id==102 || $parent.Form.selectedGstType.id==106 || $parent.Form.selectedGstType.id==107" >

                            <ValidationProvider name="GST Number" rules="required|verify_gstnumber" v-slot="{ errors }">
                             <v-text-field 
                                uppercase  
                                label="GST Number (GSTIN)" 
                                @input="fn_gstNumberInUpperCase"
                                placeholder=" " 
                                v-model="$parent.Form.gstNumber"
                                :hide-details="!errors || !errors.length"
                                :error-messages="errors">
                              </v-text-field>
                            </ValidationProvider>
                              <div class="pt-1" right-align font12 light-opacity-color>Example: 12ABCDE1234A9Z9</div>
                          </v-flex>

                        <v-flex xs6 sm6 md6 form-item v-if="$parent.Form.selectedGstType.id!=105">

                          <ValidationProvider name="Pan No" rules="verify_pannumber" v-slot="{ errors }">
                           <v-text-field 
                              uppercase  
                              label="PAN No" 
                              placeholder=" " 
                              v-model="$parent.Form.panNumber"
                              required
                              :error-messages="errors">
                            </v-text-field>
                          </ValidationProvider>
                        </v-flex>

                        <v-flex xs6 sm6 md6 form-item v-if="$parent.Form.selectedGstType.id==105">

                          <ValidationProvider name="Country" rules="required" v-slot="{ errors }">

                            <v-autocomplete
                                :items="$parent.countries ? $parent.countries : []"
                                v-model="$parent.Form.selectedGstCountry"
                                label="Country"
                                item-value="itemId"
                                item-text="itemName"
                                return-object
                                required
                                :error-messages="errors">
                            </v-autocomplete>

                          </ValidationProvider>

                        </v-flex>

                    </v-layout> -->

                    </div>

                    <v-layout nopadding mt-2 wrap full-width v-if="$parent.Form.groupTypeId!=103">

                      <v-list full-width nopadding> 

                      <v-subheader class="pr-0">Billing Address
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$parent.fn_addAddress(101)" v-if="!$parent.Form.billingAddress">Add</v-btn>
                        <template v-else>
                          <v-btn text color="primary" @click="$parent.fn_editAddress(101)">Edit</v-btn>
                          <v-btn text warning color="error" @click="$parent.fn_removeAddress(101)">Remove</v-btn>  
                        </template>
                        
                      </v-subheader>

                      <v-divider v-if="$parent.Form.billingAddress"></v-divider>

                    <div class="addressListItem" v-if="$parent.Form.billingAddress">

                      <v-list-item>
                          <v-list-item-content>
                              <v-list-item-title>{{$parent.fn_mergeAddress(101)}}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                    </div>
                  
                </v-list>
                  </v-layout>

                  <v-layout nopadding wrap full-width mt-2 mb-2 class="section-wrapper" v-if="$parent.Form.groupTypeId!=103">
                      <v-list full-width nopadding> 
                      <v-subheader class="pr-0">Shipping Address
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$parent.fn_addAddress(102)" v-if="!$parent.Form.shippingAddress">Add</v-btn>
                        <template v-else>
                          <v-btn text color="primary" @click="$parent.fn_editAddress(102)">Edit</v-btn>
                          <v-btn text warning color="error" @click="$parent.fn_removeAddress(102)">Remove</v-btn>  
                        </template>
                      </v-subheader>

                      <v-divider v-if="$parent.Form.shippingAddress"></v-divider>

                    <div class="addressListItem" v-if="$parent.Form.shippingAddress">

                      <v-list-item>
                          <v-list-item-content>
                              <v-list-item-title>{{$parent.fn_mergeAddress(102)}}</v-list-item-title>
                          </v-list-item-content>
                    </v-list-item>
                    </div>
                  
                </v-list>
                  </v-layout>

                  </v-form>

                  </ValidationObserver>
                </div>


                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex form-item xs12 sm12 md12 height48>
                            <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" @click.once="fn_validateForm" :key="$parent.saveBtnKey">Save</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>
                  

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
// import { required } from 'vee-validate/dist/rules'
// import { extend, ValidationProvider, setInteractionMode } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })

import { email } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('email', email);

// extend('verify_gstnumber', value => {

//   var strongRegex = new RegExp("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$");
//   let isValid = strongRegex.test(value);

//   if(isValid){
//     return true;
//   }

//   return 'GST Number is not valid.';
// });

extend('verify_pannumber', value => {

  var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  let isValid = regpan.test(value);

  if(isValid){
    return true;
  }

  return 'Pan Number is not valid.';
});

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
                this.$parent.disableOnSave = false;
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_validateForm: function(){
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                }
              });
            },
            fn_newAccountGroup: function(){
              this.closeDialogWeb();
              setTimeout(()=>{
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/account_group/items/new', query:{tagId:this.guidWeb(), ls:1}});
              },100);
            },
        }
    }
</script>