<template>
    <div>
        <v-dialog v-model="$root.dialog_customer_support" no-click-animation scrollable persistent content-class="half-screen-dialog" transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth() == 'auto' ? 680 : $root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <!-- <v-toolbar :height="56" flat>
                    <v-toolbar-title>BizOps App Support </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="$root.dialog_customer_support=false"><v-icon>close</v-icon></v-btn>
                </v-toolbar>
                <v-divider></v-divider> -->

                <!-- <div class="data-container grey-back"> -->
                <div class="grey-back full-height">
                    <iframe :src="iframeSrc" title="BizOps App Customer Support" id="iframe_customer_support" style="height:100%;width:100%;border:0" v-if="iframeSrc"></iframe>
                    <!-- <v-btn color="success" @click="closeDialogWeb">close</v-btn> -->
                </div>
                <!-- <v-card-text class="pa-0">
                    <iframe :src="iframeSrc" title="BizOps App Customer Support" id="iframe_customer_support" style="height:100%;width:100%"></iframe>
                </v-card-text> -->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                // $root.dialog_customer_support:false,
                // iframeSrc:"https://bizopsstaging-support.web.app/support/appsupport/tickets/items?businessid="+this.$root.CurrentBusiness.BusinessID
                iframeSrc:""
            }
        },
        methods:{
            openDialogWeb: function () {
                this.$root.dialog_customer_support = true;
                // let baseUri = process.env.NODE_ENV === 'production' ? "https://bizopsstaging-support.web.app" : "http://localhost:8081";
                // let baseUri = null;
                // if(process.env.NODE_ENV === 'production'){
                //     baseUri = "https://bizops-supportapp.web.app"
                // }
                // else if(process.env.NODE_ENV === 'staging'){
                //     baseUri = "https://bizopsstaging-support.web.app"
                // }
                // else{
                //     baseUri = "http://localhost:8081"
                // }
                // this.iframeSrc = baseUri + "/support/userlanding?businessid="+this.$root.CurrentBusiness.BusinessID;

                this.iframeSrc = process.env.VUE_APP_BASE_SUPPORT_URL + "/support/userlanding?businessid="+this.$root.CurrentBusiness.BusinessID;
                document.getElementById('iframe_customer_support').contentWindow.location.replace(this.iframeSrc);
            },
            closeDialogWeb: function () {
                this.$root.dialog_customer_support = false;
            }
        }
    }
</script>