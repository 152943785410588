import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually

import './actioninterface.js';
import {globalMixin} from './global_mixin'

// Disable console log in production
if (process.env.NODE_ENV === 'production') {
    console.log = function () {};
}

Vue.config.productionTip = false

// Vue.use(vuetify)

Vue.use(vuetify, {
  iconfont: 'md'
});

Vue.use(require('vue-moment'));
Vue.use(VueCroppie);

// let deferredPrompt;

// Check that service workers are supported
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('../../service-worker.js').then(registration => {
        console.log('sw');
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log(registrationError);
    });

    if (navigator.serviceWorker.controller) {
      console.log('This page is currently controlled by:', navigator.serviceWorker.controller);
    }

    navigator.serviceWorker.oncontrollerchange = function() {
      console.log('This page is now controlled by:', navigator.serviceWorker.controller);
      console.log('Install complete. calling update');
      if(window.matchMedia('(display-mode: standalone)').matches){
        fn_onUpdateFound();
      }
    };

  });
}

function fn_onUpdateFound(){
    console.log('update ready');
    let r = confirm("A new update is ready. Do you want to update now?");
    if(r==true){
        // let baseUrl = null;
        // if(process.env.NODE_ENV === 'production'){
        //     baseUrl = 'https://desktopapp.bizopsapp.com';
        // }
        // else if(process.env.NODE_ENV === 'staging'){
        //     baseUrl = 'https://uatdesktopapp.bizopsapp.com';
        // }
        // else{
        //     baseUrl = 'http://localhost:8080';
        // }
        
        // window.location.href = baseUrl;

        window.location.href = process.env.VUE_APP_BASE_URL
    }
    else{
        console.log("refresh cancel");
    }
}

Vue.prototype.fn_navigate = function(routePath, queryObj){
    if(queryObj){
        router.replace({path:routePath, query:queryObj});
    }
    else{
        router.replace({path:routePath});
    }
}

// Vue.prototype.displayMode = null;

// window.addEventListener('DOMContentLoaded', () => {

//   if (navigator.standalone) {
//     Vue.prototype.displayMode = 'standalone-ios';
//   }
//   if (window.matchMedia('(display-mode: standalone)').matches) {
//     Vue.prototype.displayMode = 'standalone';
//   }

//   if(!Vue.prototype.displayMode || Vue.prototype.displayMode=='browser tab'){
//     Vue.prototype.displayMode = "browser tab";
//   }
//   // Log launch display mode to analytics
//   console.log('DISPLAY_MODE_LAUNCH:', Vue.prototype.displayMode);
// });

Vue.prototype.guidWeb = function(){
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

// temp function
Vue.prototype.guid = function(){
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

Vue.prototype.scrollOptions = {"duration":300, "offset":-72, "easing":"linear"};

Vue.prototype.fn_scrollToElement = function(element){
  // alert(element);
  document.getElementsByClassName(element)[0].scrollIntoView({ behavior: "smooth" });
}

Vue.prototype.fn_scrollToElementById = function(element){
  // alert(element);
  document.getElementById(element).scrollIntoView({ behavior: "smooth" });
}

Vue.prototype.roundUpto2Decimals = function (value) {
  if(value)
    return Math.round(value*100)/100;
  else
    return 0;
}

Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  vuetify,
  mixins: [globalMixin],
  render: h => h(App)
}).$mount('#app')
