<template>
    <div id="printsubdatatable">

        <div class="reportdetails white-back pb-2" style="width:100%">
            <p style="margin:0" font16 v-if="$root.CurrentBusiness">{{$root.CurrentBusiness.BusinessName}}</p>

            <p style="margin:0">
                <span>Report: {{reportData.reportTitle}}</span>
            </p>

            <p style="margin:0">
                <span>Period: {{reportData.startDate | moment("DD-MMM-YYYY")}} To {{reportData.endDate | moment("DD-MMM-YYYY")}}</span>
            </p>

            <div class="invoice-footer" style="position:relative">
                <div class="branding grey-back" style="position:absolute;right:0;bottom:0">
                    <a href="https://bizopsapp.com/" target="_blank">
                        <div class="branding-box">
                            <div class="dark-opacity-color powered right-align">Powered By</div>
                            <div class="font16 title primary-color">
                                <img class="brand_logo" src="../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                            </div>
                        </div>
                    </a>
                </div>
            </div>
          
        </div>

        <!-- <div class="pa-2 full-width">
            <h3 class="ma-0" v-if="$root.CurrentBusiness">{{$root.CurrentBusiness.BusinessName}}</h3>

            <p class="ma-0"><span class="font-medium">Report</span>: {{reportData.reportTitle}}</p>

            <p style="margin:0" v-if="reportData.periodLabel == 'Period'">
                <span class="font-medium">Period</span>: {{reportData.startDate | moment("DD-MMM-YYYY")}} To {{reportData.endDate | moment("DD-MMM-YYYY")}}
            </p>
        </div> -->

        <table full-width cellpadding="2" class="gstr-sub-table gstr-sub-table-print" :class="[reportData.internalHeaderRow ? 'nested-table' : 'simple-table', isPrint ? 'print-sub-report' : '']" style="font-size:12px" v-if="reportData && reportData.data">
            <thead white-back>
                <tr>
                    <th v-for="(item, index) in reportData.headerRowArr" :key="index" :class="{'right-align':item.type=='number', 'has_rupee_symbol':item.rupeeSymbol}">
                        {{item.headerName}}
                        <span v-if="item.rupeeSymbol">
                            <img style="margin-left: 4px;" src="../../../assets/rupee_symbol.png" alt="rupee_symbol" align="top"/>
                        </span>
                    </th>
                    <template v-if="reportData.internalHeaderRowArr && reportData.internalHeaderRowArr > reportData.headerRowArr">
                        <th v-for="(extraCol, extraColIndex) in reportData.internalHeaderRowArr.length - reportData.headerRowArr.length" :key="'extracol'+extraColIndex"></th>
                    </template>
                    
                </tr>
            </thead>
            <tbody v-for="(row, index) in reportData.data.detailRows" :key="index">
                <tr @click="fn_getSubReportData2(row.subReportId)">
                    <td v-for="(col, colIndex) in reportData.columns" :class="{'right-align':typeof(row.cols[col])=='number'}" :key="colIndex">
                        <span v-if="col=='transactionDate' || col=='shippingBillDate' || col=='referenceTransactionDate'">
                            {{row.cols[col] | moment("DD-MMM-YYYY")}}
                        </span>

                        <span v-else-if="typeof(row.cols[col])=='number'">
                            <!-- {{row.cols[col] !== null && row.cols[col] !== '' ? $root.numberToLocale(row.cols[col]) : ''}} -->
                            <span v-if="col != 'qty' && col != 'totalNumber' && col != 'cancelled' && col != 'netIssue'">{{row.cols[col] !== null && row.cols[col] !== '' ? $root.numberToLocale(row.cols[col]) : ''}}</span>
                            <span v-else>{{row.cols[col] !== null && row.cols[col] !== '' ? row.cols[col] : ''}}</span>
                        </span>
                        
                        <span v-else>
                            {{row.cols[col] !== null && row.cols[col] !== '' ? row.cols[col] : ''}}
                        </span>
                    </td>
                </tr>

                <tr grey-back v-if="reportData.internalHeaderRow && row.cols.otherTax && row.cols.otherTax.items && row.cols.otherTax.items.length">
                    <td v-for="(item, itemIndex) in reportData.internalHeaderRowArr" :key="itemIndex" :class="{'right-align':item.type=='number', 'has_rupee_symbol internal_header':item.rupeeSymbol}">
                        <strong>{{item.headerName}}</strong>
                        <span v-if="item.rupeeSymbol">
                            <img style="margin-left:4px" src="../../../assets/rupee_symbol.png" alt="rupee_symbol" align="top"/>
                        </span>
                    </td>
                </tr>

                <template v-if="reportData.internalHeaderRow && row.cols.otherTax && row.cols.otherTax.items && row.cols.otherTax.items.length">
                    <tr v-for="(item, index) in row.cols.otherTax.items" :key="index" @click="fn_getSubReportData2(row.subReportId)">
                        <td v-for="(col, colIndex) in reportData.internalColumns" :key="colIndex" :class="{'right-align':typeof(item[col])=='number'}">
                            <span v-if="typeof(item[col])=='number'">
                                {{item[col] !== null && item[col] !== '' ? $root.numberToLocale(item[col]) : ''}}
                            </span>
                            <span v-else>
                                {{item[col] !== null && item[col] !== '' ? item[col] : ''}}
                            </span>
                        </td>
                    </tr>   
                </template>
                
                <template v-if="reportData.internalHeaderRow && row.cols.otherTax && row.cols.otherTax.total">
                    <tr>
                        <td v-for="(col, colIndex) in reportData.internalColumns" :key="colIndex" :class="{'right-align':typeof(row.cols.otherTax.total[col])=='number'}">
                            <span v-if="typeof(row.cols.otherTax.total[col])=='number'">
                                <strong>{{row.cols.otherTax.total[col] !== null && row.cols.otherTax.total[col] !== '' ? $root.numberToLocale(row.cols.otherTax.total[col]) : ''}}</strong>
                            </span>
                            <span v-else>
                                <strong>{{row.cols.otherTax.total[col] !== null && row.cols.otherTax.total[col] !== '' ? row.cols.otherTax.total[col] : ''}}</strong>
                            </span>
                        </td>
                    </tr>   
                </template>
                
            </tbody>

            <!-- Footer -->
            <template v-if="reportData.data.detailRows && reportData.data.detailRows.length && reportData.data.footerRow">
                <tr font-medium style="background:#ffffff !important;border-top:1px #f9f9f9 solid;border-bottom:1px #f9f9f9 solid">

                    <template v-if="reportData.internalHeaderRow">
                        <td v-for="(item, index) in reportData.internalHeaderRowArr" :key="index" :class="{'right-align':item.type=='number'}">
                            <span v-if="item.type=='number' && fn_getFooterColumnValue(index) !== null && fn_getFooterColumnValue(index) !== ''">
                                <strong>{{$root.numberToLocale(fn_getFooterColumnValue(index))}}</strong>
                            </span>
                            <span v-else>
                                <strong>{{fn_getFooterColumnValue(index)}}</strong>
                            </span>
                        </td>    
                    </template>
                    

                    <template v-if="!reportData.internalHeaderRow">
                        <td v-for="(item, index) in reportData.headerRowArr" :key="index" :class="{'right-align':item.type=='number'}">
                            <span v-if="item.type=='number' && fn_getFooterColumnValue(index) !== null && fn_getFooterColumnValue(index) !== ''">
                                <strong>{{$root.numberToLocale(fn_getFooterColumnValue(index))}}</strong>
                            </span>
                            <span v-else>
                                <strong>{{fn_getFooterColumnValue(index)}}</strong>
                            </span>
                        </td>
                    </template>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
    export default{
        props:['reportData', 'isPrint', 'getSubReportData2'],
        methods:{
            fn_getSubReportData2: function(reportId){
                if(reportId){
                    this.getSubReportData2(reportId);
                }
                else{
                    // alert("No details");
                    this.$eventHub.$emit('snackbarAlert', {msg:'No details'});
                }
            },

            fn_getFooterColumnValue: function(index){
                // let key = Object.keys(this.reportData.data.footerRow)[index];
                let key = null;
                if(this.reportData.internalColumns){
                    key = this.reportData.internalColumns[index];   
                }
                else{
                    key = this.reportData.columns[index];
                }
                
                console.log(key);
                console.log(this.reportData.data.footerRow[key]);
                return this.reportData.data.footerRow[key];
            }
        }
    }
</script>

<style>
    table.gstr-sub-table, table.gstr-sub-table th, table.gstr-sub-table td{
        border-collapse: collapse;
    }
    table.gstr-sub-table thead{
        border-top: 1px solid #f9f9f9;
        border-bottom: 1px solid #f9f9f9;
    }
    table.gstr-sub-table th, table.gstr-sub-table td {
      padding: 12px;
      min-width:120px;
    }
</style>