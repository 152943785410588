<template>
    <div>
        <v-dialog v-model="dialog_stockadjustment_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat class="noprint">
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>{{$parent.type=='minus' ? 'Reduce Stock' : 'Add Stock'}}</v-toolbar-title>
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back">

                  <ValidationObserver ref="observer">



                    <v-list class="pa-0">
                        <v-list-item style="height:36px">
                            <v-list-item-content>
                              <v-list-item-title>Date</v-list-item-title>
                            </v-list-item-content>

                            <span v-if="$parent.Form.transactionData.transactionDate">{{$parent.Form.transactionData.transactionDate | moment("DD-MMM-YYYY")}}</span> 

                            <v-list-item-action class="ml-0">
                                <v-btn icon @click="$parent.fn_openDatePicker('transactionDate', $parent.Form.transactionData.transactionDate)"><v-icon>date_range</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <template v-if="$parent.productData">
                      <v-layout ma-0 mt-2 row wrap white-back pt-4 pb-2>

                        <v-flex xs12 sm12 md12 form-item>

                          <v-text-field 
                            :disabled="true"
                            :hide-details="true"
                            :value="$parent.productData.name" 
                            label="Item Name"
                            placeholder=" ">
                          </v-text-field>

                          <div class="font12 right-align pt-2">
                            <template v-for="(item, index) in $parent.summaryArray">
                              <span :key="index" v-if="$parent.summaryArray.length">
                                Stock: <span :class="[item.qty<0 ? 'redcolor' : 'greencolor']">{{item.qty}} {{item.unitName}}</span>
                              </span>  
                            </template>
                          </div>
                         
                        </v-flex>

                        <v-flex xs6 sm6 md6 form-item pt-4>

                          <ValidationProvider v-slot="{ errors }" name="rate" rules="min_value:0|decimal2">

                            <v-text-field 
                              id="field_rate"
                              v-model.number="$parent.Form.rate"
                              type="number" 
                              label="Rate"
                              placeholder=" "
                              :error-messages="errors"
                              required>
                            </v-text-field>

                          </ValidationProvider>
                                 
                        </v-flex>

                        <v-flex xs6 sm6 md6 form-item pt-4>

                          <ValidationProvider v-slot="{ errors }" name="quantity" rules="required">

                            <v-text-field 
                              id="field_quantity"
                              :prefix="$parent.type == 'minus' ? '(-)' : '(+)'"
                              @input="$root.fn_boundDecimalLength($parent.Form, 'qty')"
                              v-model="$parent.Form.qty" 
                              label="Quantity"
                              type="number"
                              placeholder=" " 
                              :error-messages="errors"
                              :suffix="$parent.productData.productVariants[0].unitName"
                              required>
                            </v-text-field>

                          </ValidationProvider>
                                 
                        </v-flex>

                        <v-flex sm12 md12 xs12 pr-4>
                          <div class="font12 right-align">
                            Total Value: &#8377; {{$root.numberToLocale($parent.totalAmount)}}
                          </div>
                        </v-flex>

                              
                      </v-layout>

                      <v-layout row wrap white-back ma-0 mt-2>

                          <v-flex sm12 md12 xs12 pa-4>
                            <ValidationProvider v-slot="{ errors }" name="Notes" rules="max:255">
                              <v-textarea
                                rows="2"
                                label="Notes" 
                                auto-grow
                                :counter="255"
                                v-model="$parent.Form.transactionData.notes"
                                :error-messages="errors">
                              </v-textarea>
                            </ValidationProvider>
                          </v-flex>
                      </v-layout>
                    </template>

                    

                  </ValidationObserver>

                  
                    
                </div>

                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex xs12 sm12 md12 height48>
                            <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
import { required, max, min_value, numeric } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// setInteractionMode('eager')

extend('required', {
  ...required,
  // message: '{_field_} can not be empty',
  message: 'This field can not be empty',
})

extend('max', max)
extend('min_value', min_value)
extend('numeric', numeric)

extend('decimal2', value => {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

});

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_stockadjustment_form: false,
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_stockadjustment_form = true;
            },
            closeDialogWeb: function () {
                this.dialog_stockadjustment_form = false;
            },
            fn_validateForm: function(){
              this.$refs.observer.validate().then((result) => {
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.fn_scrollToElement('error--text');
                }
              });
            }
        }
    }
</script>