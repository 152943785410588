<template>
    <div>
        <v-dialog v-model="dialog_data_backup" no-click-animation persistent lazy transition="slide-y-transition" max-width="460px">

            <v-card>
                <v-toolbar flat :height="56">
                    <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>Data Backup</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider> 

                <v-card-text full-height nopadding grey-back class="dynamic-fields">
                    
                    <v-list two-line nopadding>
                        <v-list-item>

                            <v-list-item-content>
                                <v-list-item-title>Last Backup</v-list-item-title>

                                <div v-if="loading">
                                    <v-list-item-subtitle>Getting last backup details...</v-list-item-subtitle>
                                </div>

                                <div v-else>
                                    <v-list-item-subtitle v-if="!lastBackupTime">
                                        No last backup, backup now
                                    </v-list-item-subtitle>

                                    <v-list-item-subtitle v-if="lastBackupTime">
                                        Last Backup to Google Drive: 
                                        {{lastBackupTime | moment("DD-MMM-YYYY h:mm a")}}
                                    </v-list-item-subtitle>
                                </div>

                                

                            </v-list-item-content>

                            <!-- <v-list-item-action>
                                <v-btn color="primary" :disabled="$root.backupInProgress || loading" @click="fn_prepareDataToBackup">Back Up</v-btn>
                            </v-list-item-action> -->

                        </v-list-item>

                        <div class="pr-4 pl-4" light-opacity-color>
                            <p>Backup your data to Google Drive. You can restore them when you reinstall the mobile app and signin with Google Account.</p>

                            <v-btn color="primary" nomargin :disabled="$root.backupInProgress || loading" @click="fn_checkIsBackupInQueue">Backup</v-btn>
                        </div>

                        <div class="pr-4 pl-4" v-if="$root.backupInProgress">
                            <v-progress-linear indeterminate class="mt-2 mb-2" v-if="!$root.uploadStatus || $root.uploadStatus==100"></v-progress-linear>
                            <v-progress-linear v-model="$root.uploadStatus" :buffer-value="100" buffer v-else></v-progress-linear>
                            <span v-if="!$root.uploadStatus">Preparing data to backup...</span>
                            <span v-if="$root.uploadStatus && $root.uploadStatus!=100">{{$root.uploadStatus+'%'}}</span>
                            <span v-if="$root.uploadStatus==100">Finishing data backup...</span>
                        </div>
                    </v-list>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                </v-card-actions>
            </v-card>   
        </v-dialog>
    </div>
</template>     

<script>
export default{
    data(){ 
        return{
            dialog_data_backup: false,
            lastBackupTime:null,
            loading:true
        }
    },
    methods:{
        openDialogWeb: function () {
            this.dialog_data_backup = true;
            this.fn_getLastBackupInfo();
        },

        closeDialogWeb: function () {
            this.dialog_data_backup = false;
        },

        fn_getLastBackupInfo: function(){
            let self = this;
            this.getData('LastBackupTime', this.$root.CurrentBusiness.BusinessID, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        let date = JSON.parse(response);
                        if(date){
                            self.lastBackupTime = date;
                            self.$forceUpdate();
                        }
                        self.loading = false;
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                    }, 
                }
            );
        },

        fn_checkIsBackupInQueue: function(){
            let self = this;
            this.getData('BackupInQueue', this.$root.CurrentBusiness.BusinessID, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        if(JSON.parse(response)){
                            // alert("Data backup already in queue.");
                            self.$eventHub.$emit('snackbarAlert', {msg:'Data backup already in queue'});
                        }
                        else{
                            self.fn_backupData();
                        }

                        self.closeDialogWeb();
                    }, 
                    onError: function(error){
                        console.log(error);
                    }, 
                }
            );
        },

        fn_backupData: function(){
            let self = this;
            this.getData('BackupData', this.$root.CurrentBusiness.BusinessID, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        // alert("Data backup initiated.");
                        this.$eventHub.$emit('snackbarAlert', {msg:"Data backup initiated"});
                        self.closeDialogWeb();
                    }, 
                    onError: function(error){
                        console.log(error);
                    }, 
                }
            );
        },

        // fn_prepareDataToBackup: function(){

        //     if(!navigator.onLine){
        //         alert('Internet not available');
        //         return;
        //     }

        //     this.$root.uploadStatus = 0;

        //     let self = this;
        //     // this.showLoading('Preparing data to back up...');
        //     this.getData('PrepareBackupData', this.$root.CurrentBusiness.BusinessID, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.fn_reopenDatabase();
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //                 // self.hideLoading();
        //                 alert('Something went wrong. Try again');
        //             }
        //         }
        //     );
        // },

        // fn_reopenDatabase: function(){
        //     let self = this;
        //     this.getData('ReopenDataBaseFromDesktop', this.$root.CurrentBusiness.BusinessID, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.fn_backupData();
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //                 alert('Something went wrong. Try again');
        //             }
        //         }
        //     );
        // },

        // fn_backupData: function(){
        //     var self = this;
        //     this.$root.backupInProgress = true;
        //     this.getData('BackupData', this.$root.CurrentBusiness.BusinessID, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.$root.backupInProgress = false;
        //                 self.$root.uploadStatus = null;
        //                 self.fn_getLastBackupInfo();
        //                 alert('Data backup complete');
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //                 self.$root.backupInProgress = false;
        //                 self.$root.uploadStatus = null;
        //                 alert('Something went wrong. Try again');
        //             }
        //         }
        //     );
        // },
    }
}
</script>