<template>
    <div class="display_wrapper">

        <v-toolbar :height="56" flat>
            <!-- <v-toolbar-side-icon @click="$router.go(-1)"><v-icon>arrow_back</v-icon></v-toolbar-side-icon> -->

            <v-toolbar-title>{{listTitle}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn outlined small @click="fn_edit" color="primary" v-if="displayData && !displayData.isSystem">
                <v-icon left>edit</v-icon> Edit
            </v-btn>

            <v-btn class="ml-4" small outlined @click="fn_openDialogConfirmation" color="red" v-if="displayData && !displayData.isSystem">
                <v-icon left>delete</v-icon> Delete
            </v-btn>

            <!-- <v-btn icon @click="fn_getBottomMenu">
              <v-icon>more_vert</v-icon>
            </v-btn> -->
        </v-toolbar>

        
        <v-divider></v-divider>

        <div class="data-container grey-back" v-if="displayData">
            <div class="title pa-4 white-back">{{displayData.name}}</div>
        </div>

        <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);" v-if="loading">
          <v-col cols="6">
            <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
          </v-col>
        </v-row>

        <dialog-confirmation ref="ref_dialogDeleteConfirmation" header="Delete Master?" :actionMethod="fn_deleteMaster" params="delete" :displayView='true'></dialog-confirmation>

    </div>
</template>

<script>
// const menuItem = require('../../../../../config.js').configs.menuItem;
import {masterMixin} from '../../../master_mixin.js'

import dialogConfirmation from '../../../../dialogs/dialog_confirmation'

    export default{
        mixins: [masterMixin],
        data(){
            return{
                displayData:null,
                listTitle:null,
                loading:false
            }
        },
        components:{
         'dialog-confirmation': dialogConfirmation
        },
        methods:{
            fn_edit: function(){
                this.$router.push({path:'/'+this.$route.params.businessId+'/'+this.$route.params.listId+'/list_item/items/'+this.displayData.listItemsId+'/edit', query:{listTitle:this.listTitle, listId:this.$route.query.listId}});
            },

            fn_get: function(itemId){
                var self = this;
                this.displayData = null;
                this.loading = true;
                this.getData('ListItem', itemId, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          self.displayData = JSON.parse(response);
                          self.loading = false;
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                        }
                    }
                );
            },

            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.ToolbarTitle = this.listTitle;
            //  viewObject.TopMenu = [menuItem.edit];
            //  viewObject.isBottomMenu = true;

            //  var tagId = null;
            //  this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';

            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null, 
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.copy]};
            //  !this.displayData.isSystem ? bottomMenu.BottomMenu.push(menuItem.delete) : '';

            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='edit' && this.displayData && this.displayData.listId && this.displayData.listItemsId){
            //      this.fn_edit();
            //  }
            //  else if(menuAction=='copy'){
            //      this.$router.push({path:'/masters/lists/'+this.displayData.listId+'/items/new', query:{tagId:this.guidWeb(), listTitle:this.listTitle, itemId:this.displayData.listItemsId, copy:true}});
            //  }
            //  else if(menuAction=='delete'){
            //      this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            //  }
            // },

            fn_openDialogConfirmation: function(){
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            },

            fn_deleteMaster: function(){
                let self = this;
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = false;
                this.fn_deleteMasterItem('listItems', this.displayData.listItemsId, function(response){
                    if(response){
                        // alert('Item deleted');
                        self.$eventHub.$emit('snackbarAlert', {msg:'Item deleted'});
                        self.$eventHub.$emit('UpdateListItem', {});
                        self.$router.go(-1);
                    }
                    else{
                        // alert("Item cannot be deleted as already in use");
                        self.$eventHub.$emit('snackbarAlert', {msg:'Item cannot be deleted as already in use'});
                    }
                })
            },

            // fn_edit: function () {
            //  this.$router.push({path: "/masters/lists/"+this.displayData.listId+"/items/"+this.displayData.listItemsId+"/edit", query: {listTitle:this.listTitle}});
            // }
        },

        mounted: function(){
            this.listTitle = this.$route.query.listTitle;
            
            // this.fn_showWebView();

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            
            if(this.$route.params && this.$route.params.tagId){
                this.fn_get(this.$route.params.tagId);
            }

            this.$eventHub.$on('delete', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_openDialogConfirmation() : '';
            });

            this.$eventHub.$on('edit', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_edit() : '';
            });

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteUpdate(to, from, next){
            // alert('up');
            console.log(from);
            next(true)
            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }  

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('connected');
            next(true)
        },
    }

</script>