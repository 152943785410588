<template>
    <div>
        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>
    </div>
</template>

<script>

import dialogForm from './dialogs/dialog_form'


    export default{
        data(){
            return{
                saveBtnKey:1
            }
        },
        components:{
            'dialog-form':dialogForm
        },
        methods:{

            fn_closeDialogForm: function(){
                this.fn_goBack();
            },

            fn_goBack: function(){
                // this.$router.go(-1);
                this.$router.replace({path:'/' + this.$root.CurrentBusiness.BusinessID + '/settings'});
            }
        },
        mounted: function(){
            this.$refs['ref_dialogForm'].openDialogWeb();

            let isCalled = false;
            this.$eventHub.$on('connected', () => {
                setTimeout(() => {
                    if(!isCalled){
                        isCalled = true;
                        this.saveBtnKey++;
                        this.$refs['ref_dialogForm'].loading = false;
                    }

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);

                }, 1000);
            });
        },
        beforeRouteLeave (to, from, next) {
            this.$eventHub.$off('connected');
            next();
        }

    }

</script>