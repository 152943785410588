<template>
    <div>
        <v-dialog v-model="dialog_field" no-click-animation persistent lazy transition="slide-y-transition" max-width="460px">

            <v-card>
                <v-toolbar flat :height="56">
                    <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>
                        <span v-if="$parent.Form.dynamicFields.section">{{$parent.Form.dynamicFields.section.sectionName}}</span>
                    </v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider> 

                <v-card-text full-height nopadding grey-back class="dynamic-fields">
                    <ValidationObserver ref="observer">
                        <v-form @submit.prevent="submit">

                            <v-layout ma-0 pt-4 row wrap white-back>
                                <template full-width v-for="(item, index) in $parent.Form.dynamicFields.items">

                                    <v-flex :key="index" xs6 sm6 md6 form-item v-if="item.fieldType=='text'">
                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength" v-slot="{ errors }">
                                            <v-text-field  
                                                :label="item.displayName" 
                                                type="text" 
                                                v-model="item.value" 
                                                :error-messages="errors"
                                                :counter="item.additionalData.maxLength"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='pincode'">
                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength" v-slot="{ errors }">
                                            <v-text-field  
                                                :label="item.displayName" 
                                                type="number" 
                                                v-model.number="item.value" 
                                                :counter="item.additionalData.maxLength"
                                                :error-messages="errors"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='mobileNumber'">
                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength" v-slot="{ errors }">
                                            <v-text-field  
                                                :label="item.displayName" 
                                                type="tel" 
                                                v-model.number="item.value" 
                                                :counter="item.additionalData.maxLength"
                                                :error-messages="errors"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='email'">
                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required|email|max:'+item.additionalData.maxLength : 'email|max:'+item.additionalData.maxLength" v-slot="{ errors }">
                                            <v-text-field  
                                                :label="item.displayName" 
                                                type="email" 
                                                v-model="item.value" 
                                                :counter="item.additionalData.maxLength"
                                                :error-messages="errors"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='dateTime'" @click="openDatePicker('dynamicFieldDate-fid-'+item.fieldId, item.value)" @keyup.enter="openDatePicker('dynamicFieldDate-fid-'+item.fieldId, item.value)">

                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required' : ''" v-slot="{ errors }">
                                            <v-text-field  
                                                readonly
                                                append-icon="arrow_drop_down"
                                                :label="item.displayName"
                                                :value="fn_formatDate(item.value)"
                                                :error-messages="errors"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='longText'">

                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength" v-slot="{ errors }">
                                            <v-textarea  
                                                rows="2" 
                                                :label="item.displayName" 
                                                v-model="item.value" 
                                                :counter="item.additionalData.maxLength"
                                                :error-messages="errors"
                                                required>
                                            </v-textarea>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='number'">
                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required|numeric|max:'+item.additionalData.maxLength : 'numeric|max:'+item.additionalData.maxLength" v-slot="{ errors }">
                                            <v-text-field  
                                                :label="item.displayName" 
                                                type="number" 
                                                v-model.number="item.value" 
                                                :counter="item.additionalData.maxLength"
                                                :error-messages="errors"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='decimal'">

                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required|decimal:2|max:'+item.additionalData.maxLength : 'decimal:2|max:'+item.additionalData.maxLength" v-slot="{ errors }">
                                            <v-text-field  
                                                :label="item.displayName" 
                                                type="number" 
                                                v-model.number="item.value"
                                                :counter="item.additionalData.maxLength"
                                                :error-messages="errors"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='choice'">
                                        <!-- <div slot="label">{{item.displayName}}</div> -->

                                        <v-select
                                          :items="item.additionalData.choices"
                                          :label="item.displayName"
                                          clearable
                                          placeholder=" "
                                          v-model="item.value">
                                         </v-select>

                                        <!-- <span class="error--text font-small" v-show="errors.has('fields.'+item.displayName) && !item.value">
                                            {{ errors.first('fields.'+item.displayName) }}
                                        </span> -->
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='yes/no'">

                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required' : ''" v-slot="{ errors }">
                                            <v-checkbox 
                                                :label="item.displayName" 
                                                right-align-checkbox 
                                                v-model="item.value" 
                                                @change="fn_updateCheckBoxValue(item)">
                                            </v-checkbox>

                                            <span class="error--text font-small" v-show="!item.value">
                                                <!-- {{ errors.first('fields.'+item.displayName) }} -->
                                                {{errors[0]}}
                                            </span>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='code'">

                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required' : ''" v-slot="{ errors }">
                                            <v-text-field  
                                                :label="item.displayName" 
                                                type="text" 
                                                v-model="item.value" 
                                                :error-messages="errors"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='linkedItem'">

                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required' : ''" v-slot="{ errors }">

                                            <v-autocomplete
                                                :items="listItems"
                                                @focus="fn_openListItems(item.listItemId, item.value, item.listId, item.listName, item.fieldId)"
                                                v-model="item.selectedListItem"
                                                :label="item.displayName" 
                                                item-text="itemName"
                                                item-value="itemId"
                                                return-object
                                                required
                                                :error-messages="errors"
                                                :hide-details="!errors || !errors.length"
                                                append>
                                                
                                                <!-- <v-list-item @click="fn_new(item)" slot="prepend-item" class="primary--text"> -->
                                                <v-list-item slot="prepend-item" class="primary--text">
                                                  <v-list-item-title>Create New</v-list-item-title>
                                                </v-list-item>

                                            </v-autocomplete>

                                            <!-- <v-text-field  
                                                readonly
                                                v-model="item.value" 
                                                :label="item.displayName" 
                                                placeholder=" " 
                                                append-icon="arrow_drop_down"
                                                :onUpdate="fn_updateLinkedItem"
                                                :ref="fn_getRef('ref_dialogDynamicFields_linkedItem-fid-', item.fieldId)"
                                                :error-messages="errors"
                                                required>
                                            </v-text-field> -->
                                        </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='accountItem'">
                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required' : ''" v-slot="{ errors }">

                                            <v-autocomplete
                                                :items="item.accountItems"
                                                @click="fn_openAccountLedgers(item)"
                                                @focus="fn_openAccountLedgers(item)"
                                                v-model="item.selectedAccountItem"
                                                :label="item.displayName" 
                                                item-text="itemName"
                                                item-value="itemId"
                                                return-object
                                                required
                                                :error-messages="errors"
                                                :hide-details="!errors || !errors.length"
                                                append>
                                                
                                                <!-- <v-list-item @click="fn_new(item)" slot="prepend-item" class="primary--text"> -->
                                                <v-list-item slot="prepend-item" class="primary--text" @click="$parent.fn_newDynamicAccount(index, $parent.Form.dynamicFields.section.sectionId)">
                                                  <v-list-item-title>Create New</v-list-item-title>
                                                </v-list-item>

                                              </v-autocomplete>
                                            <!-- <v-text-field  
                                                readonly
                                                v-model="item.value" 
                                                :label="item.displayName" 
                                                placeholder=" " 
                                                append-icon="arrow_drop_down"
                                                :onUpdate="fn_updateAccountLedger"
                                                :ref="fn_getRef('ref_dialogDynamicFields_accountLedger-fid-', item.fieldId)"
                                                :error-messages="errors"
                                                required>
                                           </v-text-field> -->
                                          </ValidationProvider>
                                    </v-flex>

                                    <v-flex :key="item.displayName" xs6 sm6 md6 form-item v-if="item.fieldType=='phoneNumber'">
                                        <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength" v-slot="{ errors }">
                                            <v-text-field  
                                                :label="item.displayName" 
                                                type="tel" 
                                                v-model.number="item.value" 
                                                :counter="item.additionalData.maxLength"
                                                :error-messages="errors"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>

                                </template>
                            </v-layout>
                        
                        </v-form>
                    </ValidationObserver>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                    <v-btn color="blue darken-1" text @click.native="fn_checkValidations">ok</v-btn>
                </v-card-actions>
            </v-card>   
        </v-dialog>
    </div>
</template>     

<script>
const accountGroupIds = require('../../../../config.js').configs.accountGroupIds;
const fieldIds = require('../../../../config.js').configs.fieldIds;

import { required, max } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('required', required);
extend('max', max);

export default{
    props:['saveFields', 'openAccountLedgers', 'openListItems', 'openDatePicker'],
    data(){ 
        return{
            dialog_field: false,
            dynamicFieldDate:null,
            accountLedgers:[],
            listItems:[]
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        openDialogWeb: function () {
            this.dialog_field = true;

            setTimeout(()=>{
                let firstInputElement = document.querySelectorAll(".dynamic-fields input")[0];
                firstInputElement.focus();
            },300);
        },
        closeDialogWeb: function () {
            this.dialog_field = false;
        },
        fn_getRef: function(ref, fieldId){
            return ref + fieldId;
        },
        // fn_newLedger: function(index, sectionId){
        //     this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), groupTypeId:103, ls:1, type:'ledger', itemIndex:index, sectionId:sectionId}});
        // },

        // fn_openAccountLedgers: function (item, itemData) {

        //     if(item.accountItems && item.accountItems.length && !itemData){
        //         return;
        //     }

        //     if(fieldIds.transporterName.id == item.fieldId){
        //         this.$parent.Form.dynamicFields.accountGroupId = accountGroupIds.transporter.id;
        //         this.$parent.Form.dynamicFields.accountGroupName = accountGroupIds.transporter.name;    
        //     }
        //     else{
        //         this.$parent.Form.dynamicFields.accountGroupId = item.accountGroupId;
        //         this.$parent.Form.dynamicFields.accountGroupName = item.accountGroupName;    
        //     }
            
        //     let accountGroups = [];
        //     accountGroups.push(this.$parent.Form.dynamicFields.accountGroupId);

        //     this.$parent.fn_accountLedgers('', null, accountGroups, null, response =>{
        //         console.log(response);
        //         item.accountItems = JSON.parse(response);

        //         if(itemData){
        //             item.selectedAccountItem = {"itemId":null, "itemName":null};
        //             this.$set(item.selectedAccountItem, 'itemId', itemData.id);
        //             this.$set(item.selectedAccountItem, 'itemName', itemData.name);    
        //         }

        //         this.$forceUpdate();
        //     })

        // },

        fn_openAccountLedgers: function (item, itemData) {

            if(item.accountItems && item.accountItems.length && !itemData){
                return;
            }

            if(fieldIds.transporterName.id == item.fieldId){
                this.$parent.Form.dynamicFields.accountGroupId = accountGroupIds.transporter.id;
                this.$parent.Form.dynamicFields.accountGroupName = accountGroupIds.transporter.name;    
            }
            else{
                this.$parent.Form.dynamicFields.accountGroupId = item.accountGroupId;
                this.$parent.Form.dynamicFields.accountGroupName = item.accountGroupName;    
            }

            item.accountItems = this.$root.ledgers.filter(item => {
                return item.accountGroupId == this.$parent.Form.dynamicFields.accountGroupId;
            });

            if(itemData){
                item.selectedAccountItem = {"itemId":null, "itemName":null};
                this.$set(item.selectedAccountItem, 'itemId', itemData.id);
                this.$set(item.selectedAccountItem, 'itemName', itemData.name);    
            }
            this.$forceUpdate();

        },
        
        fn_openListItems: function (id, name, listId, listName, fieldId) {
            this.$parent.Form.dynamicFields.ref = 'ref_dialogDynamicFields_accountLedger-fid-'+fieldId;
            // this.openListItems('android', 'ListItems', 'ref_dialogDynamicFields_linkedItem-fid-'+fieldId, id, name, listId, listName);
            this.$parent.fn_listItems('', listId, response=>{
                this.listItems = JSON.parse(response);
            })
        },
        fn_updateAccountLedger: function (selectedValueId, selectedValueName, isRemoved, ref, accountGroupId) {
            // accountGroupId coming from new ledger making directly via dialog
            if(this.$parent.Form.dynamicFields.accountGroupId == accountGroupIds.transporter.id && (accountGroupId && accountGroupId != accountGroupIds.transporter.id)){
                this.showToast("Invalid Transporter");
                return;
            }

            let items = this.$parent.Form.dynamicFields.items;

            let fieldRef = ref ? ref : this.$parent.Form.dynamicFields.ref;

            let fieldId = fieldRef.substring(fieldRef.lastIndexOf('-fid-') + 5);

            for(let i=0; i<items.length; i++){
                let item = items[i];
                if(item.fieldId == fieldId && item.fieldType == 'accountItem'){
                    if(!isRemoved){
                        item.value = selectedValueName;
                        item.accountLedgerId = selectedValueId;
                    }
                    else{
                        item.value = null;
                        item.accountLedgerId = null;    
                    }
                    this.$forceUpdate();
                    break;
                }
            }
        },
        fn_updateLinkedItem: function (selectedValueId, selectedValueName, isRemoved, ref) {
            let items = this.$parent.Form.dynamicFields.items;

            let fieldRef = ref ? ref : this.$parent.Form.dynamicFields.ref;

            let fieldId = fieldRef.substring(fieldRef.lastIndexOf('-fid-') + 5);
            
            for(let i=0; i<items.length; i++){
                let item = items[i];
                if(item.fieldId == fieldId && item.fieldType=='linkedItem'){
                    if(!isRemoved){
                        item.value = selectedValueName;
                        item.listItemId = selectedValueId;
                    }
                    else{
                        item.value = null;
                        item.listItemId = null; 
                    }
                    this.$forceUpdate();
                    break;
                }
            }
        },
        fn_updateCheckBoxValue: function(item){
            this.$forceUpdate();
            !item.value ? item.value = null : '';
        },

        // fn_updateChoice: function(){
        //  this.$forceUpdate();
        //  console.log(JSON.stringify(this.$parent.Form.dynamicFields.items));
        // },
        // fn_updateDateFormat: function (date) {
        //  this.dynamicFieldDate = this.$moment(date).format('DD-MMM-YYYY');
        //  this.$forceUpdate();
        // },
        fn_checkValidations: function(){
            this.$refs.observer.validate().then((result) => {
                if(result) {
                    let emptyFields = true;
                    for(let i=0; i<this.$parent.Form.dynamicFields.items.length; i++){
                        if(this.$parent.Form.dynamicFields.items[i].value || this.$parent.Form.dynamicFields.items[i].selectedListItem || this.$parent.Form.dynamicFields.items[i].selectedAccountItem){
                            emptyFields = false;
                            break;
                        }
                    }
                    !emptyFields ? this.saveFields(this.$parent.Form.dynamicFields) : this.closeDialogWeb();
                }
            });
        },
        fn_updateDynamicDate: function(value, dateType){
            let fieldId = dateType.substring(dateType.lastIndexOf('-fid-') + 5);

            for(let i in this.$parent.Form.dynamicFields.items){
                if(this.$parent.Form.dynamicFields.items[i].fieldId == fieldId && this.$parent.Form.dynamicFields.items[i].fieldType == 'dateTime'){
                    // this.$set(this.$parent.Form.dynamicFields.items[i], 'value', value);
                    let date = this.$root.fn_getLocalDateFormat(value);
                    this.$set(this.$parent.Form.dynamicFields.items[i], 'value', date);
                    this.$forceUpdate();
                    break;
                }
            }
        },
        fn_formatDate: function(val){
            let date = val ? new Date(val) : null;
            return date ? this.$moment(date).format('DD-MMM-YYYY') : null;
        }
    }
}
</script>