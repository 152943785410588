// import work from 'webworkify-webpack'

// const myworker = work(require.resolve('../plugins/worker_prepare_excel_data.js'));

export const exportExcelMixin =  {
    data(){
        return{
            // myworker: work(require.resolve('../plugins/worker_prepare_excel_data.js')),
            // periods:[
            //   {"title":"Today", "value":"currentDate"},
            //   {"title":"This Month", "value":"month"},
            //   {"title":"This Quarter", "value":"quarter"},
            //   {"title":"This Financial Year", "value":"year"},
            //   {"title":"Monthly", "value":"M"},
            //   {"title":"Quarterly", "value":"Q"}
            // ]
            periods:[
              {"title":"Today", "value":"currentDate"},
              {"title":"Yesterday", "value":"previousDate"},
              {"title":"This Month", "value":"month"},
              {"title":"Previous Month", "value":"previousMonth"},
              {"title":"Select Month", "value":"M"},
              {"title":"This Quarter", "value":"quarter"},
              {"title":"Select Quarter", "value":"Q"},
              {"title":"This Fiscal Year", "value":"year"},
              {"title":"All Time", "value":"alltime"},
              {"title":"Custom", "value":null},
            ],
            selectedStartDate:null,
            selectedEndDate:null,
            loadingStatusObj:{value:0},
            loading:false
        }
    },

    methods: {

        fn_openDialogCustomDates: function(){
            this.selectedStartDate = this.selectionItems.startDate;
            this.selectedEndDate = this.selectionItems.endDate;
            // this.fn_openDialogWeb('web', 'CustomPeriod', 'ref_dialogCustomPeriod');
            this.$refs['ref_dialogCustomPeriod'].custom_date = true;
            // this.$refs['ref_dialogCustomPeriod'].openDialogWeb();
        },
        
        fn_openDatePicker: function (type, date) {
            let self = this;
            this.dateType = type;
            this.selectedDate = date ? date : new Date();
            setTimeout(function () {
                // self.fn_openDialogWeb('web', 'DatePicker', 'ref_dialogDatePicker');
                self.$refs['ref_dialogDatePicker'].openDialogWeb();
            })
        },

        fn_updateDate: function (value) {

            this.$refs['ref_dialogDatePicker'].closeDialogWeb();

            if(this.dateType=="start"){
                this.selectedStartDate = value;
            }
            else{
                this.selectedEndDate = value;
            }

            // this.fn_closeDialog();
            let self = this;
            setTimeout(function(){
                self.$refs['ref_dialogDatePicker'].disableBtn = false;
            }, 1000);
        },


        fn_updateCustomDates: function(){
            this.selectionItems.startDate = this.selectedStartDate;
            this.selectionItems.endDate = this.selectedEndDate;
            this.selectionItems.selectedPeriod = "Custom";
            // localStorage.setItem('transaction_filter', JSON.stringify(this.selectionItems));
            // this.fn_closeDialog();
            this.$refs['ref_dialogCustomPeriod'].custom_date = false;
            this.fn_searchItem();
        },

        fn_getReportData: function(){
            let self = this;
        // self.showLoading("Downloading Summary...");
            let filterObject = {};
            filterObject.reportId = 11;
            filterObject.startDate = this.$moment(this.selectionItems.startDate).startOf('day'); //new Date(this.selectionItems.startDate);
            filterObject.endDate = this.$moment(this.selectionItems.endDate).endOf('day');

            filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
            filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);

            filterObject.transactionListId = this.$route.query.transactionTypeId ? [this.$route.query.transactionTypeId] : [this.transactionTypeId]; 
            this.selectionItems.typeFormId ? filterObject.transactionTypeFormIds = [this.selectionItems.typeFormId] : '';
            filterObject.search = this.searchValue;

            if(filterObject.transactionListId == 20005){
              filterObject.fiterType = this.selectionItems.filterType;
            }

            console.log(JSON.stringify(filterObject));

            this.loading = true;

            let itemId = null;
            this.getReportData('Report', itemId, filterObject, this.loadingStatusObj,{

                onSuccess: function(response){
                    console.log(response);
                    let reportData = JSON.parse(response);

                    // setting up party column as cash in case of cash expense
                    if(self.$route.query.transactionTypeId == 20005){
                        for(let i=0; i<reportData.data.detailRows.length; i++){
                            if(!reportData.data.detailRows[i].cols.party){
                                reportData.data.detailRows[i].cols.party = "Supplier not applicable";
                            }
                        }
                    }

                    self.fn_processDataForExcel(reportData);
                }, 
                onError: function(error){
                    console.log(error);
                    // self.hideLoading();
                    self.loading = false;
                }

            });
        },

        fn_processDataForExcel: function(reportData){
            let startDate = this.$moment(this.selectionItems.startDate).format('DD-MMM-YYYY');
            let endDate = this.$moment(this.selectionItems.endDate).format('DD-MMM-YYYY');  

            let dataObj = {};

            dataObj = {"reportDataObject":{}}
            dataObj.reportDataObject = {};
            dataObj.reportDataObject.reportData = {};
            dataObj.reportDataObject.reportData = reportData.data;
            dataObj.reportDataObject.reportData.headerRow = {"transactionListId":"Voucher","transactionDate":"Date","mergeTransactionNumber":"Number","party":"Party","amount":"Amount","notes":"Notes"}

            dataObj.otherData = {};
            dataObj.otherData.headerRow1 = this.$root.CurrentBusiness.BusinessName;
            dataObj.otherData.headerRow2 = this.toolbarTitle + " Summary";

            dataObj.otherData.headerRow3 = 'Period: ' + startDate + ' To ' + endDate;

            console.log(JSON.stringify(dataObj));

            prepareExcel(dataObj, this);

            // this.myworker.postMessage(dataObj);
        },

        // fn_breakDataIntoChunks: function(event){
        //     // var fileName = this.toolbarTitle + "_Summary"+this.selectionItems.startDate+'_'+this.selectionItems.endDate;
        //     // let downloadTime = new Date();
        //     // var fileName = this.toolbarTitle + "_Summary_"+downloadTime.getTime();
        //     var fileName = this.toolbarTitle.replace(/ /g,'') + "-Summary_" + this.$root.fn_getCurrentDateTimeString();
                    
        //     var chunkcounter = 0;
        //     if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
        //       console.log('inside');

        //       //opening file
        //       let fileId = this.guidWeb();
        //       AndroidRoutingTest.openFile(fileId, fileName+'.xlsx')

        //       //writing file
        //       var chunkSize = event.data.BYTES_PER_ELEMENT * 1000 * 2; //self.$parent.datachunksize //1 byte
        //       for (var i = 0; i < event.data.length; i += chunkSize) {
        //         var datachunk = event.data.slice(i, i + chunkSize)
        //         AndroidRoutingTest.writeFile(fileId, datachunk);
        //         console.log(++chunkcounter);
        //       }

        //       //closing file
        //       console.log("close");
        //       AndroidRoutingTest.closeFile(fileId);

        //       //export file
        //       this.fn_exportToExcel(event.data, fileName+".xlsx")
        //     }
        // },

        // fn_exportToExcel: function(data, fileName){
        //     let self = this;
        //     this.exportToExcel(data, fileName,{

        //         onSuccess: function(response){
        //             console.log(response);
        //             self.showToast('File downloaded');
        //             self.hideLoading();
        //             self.snackbar = true; 
        //         }, 
        //         onError: function(error){
        //             console.log(error);
        //             self.showToast('File download failed');
        //         }

        //     });
        // },

        fn_selectPeriod: function(type, month, year){
            if(!type){
                this.fn_openDialogCustomDates();
                return;
            }

          if(type=='M'){
            this.fn_openDialogMonthFilter();
            // this.$refs['ref_dialogMonthFilter'].openDialogWeb();
          }
          else if(type=='Q'){
            this.fn_openDialogQuarterFilter();
            // this.$refs['ref_dialogQuarterFilter'].openDialogWeb();
          }
          else{
            // this.fn_closeDialog();
            this.$refs['ref_dialogMonthFilter'].closeDialogWeb();
            this.$refs['ref_dialogQuarterFilter'].closeDialogWeb();

            let previousDateSelected = false;

            // Date validation if selected before opening date
            if(type == "previousMonth"){
              let now = new Date();
              let previousMonthDate = new Date(now.getFullYear(), now.getMonth(), 0);
              if(previousMonthDate.getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                previousDateSelected = true;
              }
            }

            if(type == "previousDate"){
              let now = new Date();
              let yesterday = new Date(now.setDate(now.getDate() - 1));
              if(yesterday.getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                previousDateSelected = true;
              }
            }

            if(previousDateSelected){
              // this.showToast("Not Applicable");
            //   alert("Not Applicable!")
                this.$eventHub.$emit('snackbarAlert', {msg:'Not applicable'});
                return;
            }
            // Date validation end if selected before opening date

            let dateObj = this.fn_getTimeSpan(type, month, year);
            
            this.selectionItems.startDate = dateObj.startDate;
            this.selectionItems.endDate = dateObj.endDate;


            // localStorage.setItem('transaction_filter', JSON.stringify(this.selectionItems));

            this.fn_searchItem();
          }
        },

        fn_openDialogMonthFilter: function(){
            this.$refs['ref_dialogMonthFilter'].openDialogWeb();
          // this.fn_openDialogWeb('web', 'MonthFilter', 'ref_dialogMonthFilter');
        },

        fn_openDialogQuarterFilter: function(){
            this.$refs['ref_dialogQuarterFilter'].openDialogWeb();
          // this.fn_openDialogWeb('web', 'QuarterFilter', 'ref_dialogQuarterFilter');
        },

        fn_getTimeSpan: function(type, value, year){
            let dateObj = {};

            let minSelectionDate = new Date(this.$root.minDateSelection);
            // let minSelectionDate = this.$root.fn_getMinSelectionDate();
            let currentDate = new Date();
            let currentMonth = currentDate.getMonth() + 1;
            let thisFiscalYear = currentMonth<=3 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;

            if(type=='monthly'){

                let now = null;

                // if(value<=3){
                //     now = new Date("2022/"+value);
                // }
                // else{
                //     now = new Date();
                // }
                if(value<=3){
                    now = new Date((year+1)+"/"+value);
                }
                else{
                    now = new Date(year+"/"+value);
                }

                let endDate  = new Date(now.getFullYear(), value, 0);
                let startDate = new Date(now.getFullYear(), value-1, 1);

                let sDate = null;
                if(startDate.getTime() > minSelectionDate.getTime()){
                    sDate = startDate;
                }
                else{
                    sDate = minSelectionDate;
                }

                dateObj.startDate = formatDate(sDate);
                dateObj.endDate = formatDate(endDate);
            }
            
            // for current month / this month
            else if(type=='month'){
                let now = new Date();
                let endDate  = new Date(now.getFullYear(), now.getMonth()+1, 0);
                let startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

                let sDate = null;
                if(startDate.getFullYear() ==  minSelectionDate.getFullYear()){
                    if(startDate.getMonth()+1 > minSelectionDate.getMonth()+1){
                        sDate = startDate;
                    }
                    else{
                        sDate = minSelectionDate
                    }
                }
                else{
                    // sDate = minSelectionDate
                    sDate = startDate
                }

                dateObj.startDate = formatDate(sDate);
                dateObj.endDate = formatDate(endDate);
            }
            else if(type=='previousMonth'){
                let now = new Date();
                let sDate = new Date(now.getFullYear(), now.getMonth()-1, 1);
                let endDate  = new Date(now.getFullYear(), now.getMonth(), 0);
                dateObj.startDate = formatDate(sDate);
                dateObj.endDate = formatDate(endDate);
            }
            else if(type=='quarterly'){
                let now = null;

                // if(value<=3){
                //     now = new Date("2022/"+value);
                // }
                // else{
                //     now = new Date("2021/"+value);
                // }
                if(value<=3){
                    now = new Date((year+1)+"/"+value);
                }
                else{
                    now = new Date(year+"/"+value);
                }

                let quarter = Math.floor((now.getMonth() / 3));
                let firstDate = new Date(now.getFullYear(), quarter * 3, 1);
                let endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);


                if(firstDate.getTime() < minSelectionDate.getTime()){
                    dateObj.startDate = formatDate(minSelectionDate); 
                }
                else{
                    dateObj.startDate = formatDate(firstDate);
                }
                
                dateObj.endDate = formatDate(endDate);
            }
            else if(type=='quarter'){
                let now = new Date();
                let quarter = Math.floor((now.getMonth() / 3));
                let firstDate = new Date(now.getFullYear(), quarter * 3, 1);
                let endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);

                if(firstDate.getTime() < minSelectionDate.getTime()){
                    dateObj.startDate = formatDate(minSelectionDate); 
                }
                else{
                    dateObj.startDate = formatDate(firstDate);
                }
                
                dateObj.endDate = formatDate(endDate);
            }
            else if(type=='year'){
                // let firstday = new Date(this.$root.minDateSelection);
                let endDate = new Date(this.$moment(new Date(thisFiscalYear+"/03/31")).endOf('day'));
                // dateObj.startDate = formatDate(minSelectionDate);
                dateObj.startDate = formatDate(this.$root.fn_getMinSelectionDate());
                dateObj.endDate = formatDate(endDate);
            }
            else if(type=='alltime'){
                // let firstday = new Date(this.$root.minDateSelection);
                let maxDateYear = new Date(this.$root.maxDateSelection).getFullYear();
                let year = maxDateYear > thisFiscalYear ? maxDateYear : thisFiscalYear;
                let endDate = new Date(this.$moment(new Date(year+"/03/31")).endOf('day'));
                dateObj.startDate = formatDate(minSelectionDate);
                dateObj.endDate = formatDate(endDate);
            }
            else if(type=='endDate'){
                dateObj.startDate = null;
                dateObj.endDate = new Date();
            }
            else if(type=='currentDate'){
                dateObj.startDate = this.$moment(new Date()).startOf('day');
                dateObj.endDate = this.$moment(new Date()).endOf('day');
            }
            else if(type=='previousDate'){
                let now = new Date();
                let yesterday = now.setDate(now.getDate() - 1);
                dateObj.startDate = this.$moment(yesterday).startOf('day');
                dateObj.endDate = this.$moment(yesterday).endOf('day');
            }
            else{
                dateObj.startDate = new Date();
                dateObj.endDate = new Date();
            }

            dateObj.startDate = dateObj.startDate ? this.$root.fn_getLocalDateFormat(dateObj.startDate) : null;
            dateObj.endDate = this.$root.fn_getLocalDateFormat(dateObj.endDate);

            if(new Date(dateObj.endDate) < new Date(this.$root.CurrentBusiness.OpeningDate)){
                // alert('Selected period is not applicable!');
                this.$eventHub.$emit('snackbarAlert', {msg:'Selected period is not applicable'});
                dateObj.startDate = dateObj.endDate;
            }

            if(type == 'currentDate'){
                this.selectionItems.selectedPeriod = "Today";
            }
            else if(type == 'previousDate'){
                this.selectionItems.selectedPeriod = "Yesterday";
            }
            else if(type == 'month' || type == 'monthly' || type == 'previousMonth'){
                this.selectionItems.selectedPeriod = this.$moment(dateObj.startDate).format('MMM, YYYY');
            }
            else if(type == 'quarter' || type == 'quarterly'){
                this.selectionItems.selectedPeriod = this.$moment(dateObj.startDate).format('MMM') + ' - ' + this.$moment(dateObj.endDate).format('MMM');
            }
            else if(type == 'year'){
                this.selectionItems.selectedPeriod = "This Year";
            }
            else if(type == 'alltime'){
                this.selectionItems.selectedPeriod = "All Time";
            }
            else{
                this.selectionItems.selectedPeriod = null;
            }

            return dateObj;
        }

    }
    // mounted: function(){
    //     let self = this;
    //     myworker.addEventListener('message', event => {
    //         self.fn_breakDataIntoChunks(event);
    //     });
    // }
}

function formatDate(date_obj){
    let dt = new Date(date_obj);
    return dt.getFullYear() + "-" + ("0" + (dt.getMonth()+1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2);
}

function formatDateInIndianForm(dateStr){
  let dateObj = new Date(dateStr);
  console.log(dateObj);
  return ("0" + dateObj.getDate()).slice(-2) + "/" + ("0" + (dateObj.getMonth()+1)).slice(-2) + "/" +  dateObj.getFullYear();
    // return date_obj.getFullYear() + "/" + ("0" + (date_obj.getMonth()+1)).slice(-2) + "/" + ("0" + date_obj.getDate()).slice(-2);
}

function prepareExcel(dataObj, self){

    let headerRow1 = [dataObj.otherData.headerRow1];
    let headerRow2 = [dataObj.otherData.headerRow2];
    let headerRow3 = [dataObj.otherData.headerRow3];
    let headerRow4 = dataObj.otherData.headerRow4 ? [dataObj.otherData.headerRow4] : null;
    //this row is for advance filters;
    let headerRow5 = dataObj.otherData.headerRow5 ? [dataObj.otherData.headerRow5] : null;

    let tableHeader = [];
    let headerCols = dataObj.reportDataObject.reportData.headerRow;

    let headerInternalCols = null;
    if(dataObj.reportDataObject.reportData.internalHeaderRow){
      headerInternalCols = dataObj.reportDataObject.reportData.internalHeaderRow;  
    }
    

    for(let key in headerCols){
      tableHeader.push(headerCols[key]);

      //setting up header columns except first to null
      // if(key!='col1'){
        headerRow1.push(null);
        headerRow2.push(null);
        headerRow3.push(null);
        headerRow4 ? headerRow4.push(null) : '';
        headerRow5 ? headerRow5.push(null) : '';
      // }
    }

    // var excelData = [headerRow1, headerRow2, headerRow3, headerRow4, tableHeader];
    

    var excelData = [];
    excelData.push(headerRow1);
    excelData.push(headerRow2);
    excelData.push(headerRow3);

    let headerRowCount = 4;//headerRow1 + headerRow2 + headerRow3 + tableHeader

    if(headerRow4){
      excelData.push(headerRow4); 
      headerRowCount += 1;
    }

    if(headerRow5){
      excelData.push(headerRow5); 
      headerRowCount += 1;
    }
    // headerRow4 ? excelData.push(headerRow4) : '';
    // headerRow5 ? excelData.push(headerRow5) : '';
    excelData.push(tableHeader);

    for(var i=0; i<dataObj.reportDataObject.reportData.detailRows.length; i++){

      let tableRow = [];
      let item = dataObj.reportDataObject.reportData.detailRows[i];
      for(let key in headerCols){
        console.log(headerCols[key]+': '+item.cols[key]);
        if(headerCols[key]=='Date' || key=='transactionDate' || key=='shippingBillDate' || key=='referenceTransactionDate'){
          item.cols[key] = item.cols[key] ? formatDateInIndianForm(item.cols[key]) : '';
        }
        tableRow.push(item.cols[key]);
      }

      excelData.push(tableRow);

      if(item.cols.otherTax && item.cols.otherTax.items && item.cols.otherTax.items.length){
              
        tableRow = [];
        for(var col in headerInternalCols){
          tableRow.push(headerInternalCols[col]);
        }
        excelData.push(tableRow);

        for(var j=0; j<item.cols.otherTax.items.length; j++){
          let otherItem = item.cols.otherTax.items[j];

          tableRow = [];
          for(let hCol in headerInternalCols){
            tableRow.push(otherItem[hCol]);
          }
          excelData.push(tableRow);
        }

        if(item.cols.otherTax.total){
          tableRow = [];
          for(let tCol in headerInternalCols){
              tableRow.push(item.cols.otherTax.total[tCol]);
          }
          excelData.push(tableRow); 
        }

      }
    }

    let isFooterRow = false;
    let isSummaryRow = false;

    if(dataObj.reportDataObject.reportData.footerRow){
      let tableFooter = [];
      let footerCols = dataObj.reportDataObject.reportData.footerRow;

      if(headerInternalCols){
        for(let key in headerInternalCols){
          tableFooter.push(footerCols[key]);
        }  
      }
      else{
        for(let key in headerCols){
          tableFooter.push(footerCols[key]);
        }  
      }

      excelData.push(tableFooter);
      isFooterRow = true;
    }

    //only for outstanding recivable and payable reports
    if(dataObj.reportDataObject.reportData.summaryRows){
      let footerRows = dataObj.reportDataObject.reportData.summaryRows;
        let footerColumns = ['', '', 'mergeTransactionNumber', 'transactionListId'];
        for(let i=0; i<footerRows.length; i++){
          let tableFooter = [];
          let rows = footerRows[i];
          for(let i in footerColumns){
            tableFooter.push(rows[footerColumns[i]]);
          }
          excelData.push(tableFooter);
      }
      isFooterRow = true;
      isSummaryRow = true;
    }

    // let isFilterRow = headerRow5 ? true : false;

    exportExcel(excelData, isFooterRow, headerRowCount, isSummaryRow, self);
}

function exportExcel(data, isFooterRow, headerRowCount, isSummaryRow, self){

      console.log('called 2');

      const XlsxPopulate = require('xlsx-populate');

      // let headerRowCount = isFilterRow ? 6 : 5;

      XlsxPopulate.fromBlankAsync()
          .then(workbook => {
              // Modify the workbook.
              // workbook.sheet("Sheet1").cell("A1").value("This is neat");

              console.log('called 3');

              workbook.sheet(0).cell("A1").value(data);

              //removing slashes while setting up sheet name
              let sheetName = data[1][0].replace(/\//g, '');
              workbook.sheet(0).name(sheetName);

                  var tbl_LastColumn_Index = data[0].length - 1;
                  var tbl_LastRow_Index = data.length;

                  //Getting first row and first col address
                  const tbl_FirstRow_FirstCell = workbook.sheet(0).cell(headerRowCount,1).address();
                  //Getting first row last col address
                  const tbl_FirstRow_LastCell = workbook.sheet(0).cell(headerRowCount,tbl_LastColumn_Index).address();
                  //Getting last row first col address
                  const tbl_LastRow_FirstCell = workbook.sheet(0).cell(tbl_LastRow_Index,1).address();
                  //Getting last row last col address
                  const tbl_LastRow_LastCell = workbook.sheet(0).cell(tbl_LastRow_Index, tbl_LastColumn_Index).address();


                  //Getting column name e.g A,B,C....
                  // const cell = workbook.sheet(0).column(lastColumnIndex).columnName();
                  //Setting column width
                  // workbook.sheet(0).column(cell).width(100).hidden(false);

                  var headerRange = tbl_FirstRow_FirstCell+":"+tbl_FirstRow_LastCell;
                  var footerRange = tbl_LastRow_FirstCell+":"+tbl_LastRow_LastCell;
                  
                  //Setting cell styles
                  workbook.sheet(0).range(headerRange).style({"fill": "dddddd", "bold": true});

                  isFooterRow ? workbook.sheet(0).range(footerRange).style({"bold": true}) : '';

                  if(isSummaryRow){
                    const tbl_SecondLastRow_FirstCell = workbook.sheet(0).cell(tbl_LastRow_Index-1,1).address();
                    const tbl_SecondLastRow_LastCell = workbook.sheet(0).cell(tbl_LastRow_Index-1, tbl_LastColumn_Index).address();
                    const tbl_ThirdLastRow_FirstCell = workbook.sheet(0).cell(tbl_LastRow_Index-2,1).address();
                    const tbl_ThirdLastRow_LastCell = workbook.sheet(0).cell(tbl_LastRow_Index-2, tbl_LastColumn_Index).address();

                    var SecondLastFooterRange = tbl_SecondLastRow_FirstCell+":"+tbl_SecondLastRow_LastCell;
                    var ThirdLastFooterRange = tbl_ThirdLastRow_FirstCell+":"+tbl_ThirdLastRow_LastCell;

                    workbook.sheet(0).range(SecondLastFooterRange).style({"bold": true});
                    workbook.sheet(0).range(ThirdLastFooterRange).style({"bold": true});
                  }

                  workbook.outputAsync().then(function (blob) {

                      var fileName = self.toolbarTitle.replace(/ /g,'') + "-Summary_" + self.$root.fn_getCurrentDateTimeString();
                      console.log(event)
                      console.log(fileName)
                      self.exportExcel = false;
                      var url = window.URL.createObjectURL(blob);
                      var a = document.createElement("a");
                      document.body.appendChild(a);
                      a.href = url;
                      a.download = fileName + ".xlsx";
                      a.click();
                      window.URL.revokeObjectURL(url);
                      document.body.removeChild(a);

                      self.loading = false;
                });
          });
}