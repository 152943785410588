<template>
    <div id="printdatatable" class="table_wrapper white-back">

        <div class="reportdetails white-back pb-2" style="width:100%">
            <p style="margin:0" font16 v-if="$root.CurrentBusiness">{{$root.CurrentBusiness.BusinessName}}</p>

            <p style="margin:0">
                <span>Report: {{propsObj.reportTitle}}</span>
            </p>

            <p style="margin:0" v-if="propsObj.itemLabel">
                <span>{{propsObj.itemLabel}}</span>: 
                <span class="item-link">{{propsObj.itemName}}</span>
            </p>

            <p style="margin:0" v-if="propsObj.periodLabel == 'Period'">
                <span>Period: {{propsObj.startDate | moment("DD-MMM-YYYY")}} To {{propsObj.endDate | moment("DD-MMM-YYYY")}}</span>
            </p>

            <p style="margin:0" v-else>
                <span>As On: {{propsObj.endDate | moment("DD-MMM-YYYY")}}</span>
            </p>

            <p style="margin:0" v-if="reportFilterObj && Object.keys(reportFilterObj).length">
              <span v-for="(filter, key, index) in reportFilterObj" :key="key">
                <span>{{filter.name}}: {{filter.value}}{{index!=Object.keys(reportFilterObj).length-1 ? ', ': ''}}</span>
              </span>
            </p>

            <div class="invoice-footer" style="position:relative">
                <div class="branding grey-back" style="position:absolute;right:0;bottom:0px;">
                    <a href="https://bizopsapp.com/" target="_blank">
                        <div class="branding-box">
                            <div class="dark-opacity-color powered right-align">Powered By</div>
                            <div class="font16 title primary-color">
                                <img class="brand_logo" src="../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                            </div>
                        </div>
                    </a>
                </div>
            </div>
          
        </div>

        <table id="my-table-print" style="width:100%;border-collapse: collapse;" class="report-container-table font11" v-if="propsObj.reportData">

            <thead class="report-header">
                <tr>
                    <td class="pt-1 pb-1 pl-2 pr-2 dark-grey-back" :class="{'has_rupee_symbol':item.rupeeSymbol}" v-for="(item, index) in propsObj.reportData.headerRow" :key="index">
                      <!-- <strong>{{item.headerName ? item.headerName : ''}}</strong> -->
                      {{item.headerName ? item.headerName : ''}}
                      <span v-if="item.rupeeSymbol">
                          <img style="width:14px;margin-top:4px" src="../../../assets/rupee_symbol.png" alt="rupee_symbol"/>
                      </span>
                      <!-- <span v-if="item.headerName" v-html="item.headerName"></span> -->
                    </td>
                </tr>
            </thead>

            <tbody class="report-content" v-if="propsObj.reportData.detailRows">
                <tr v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <td class="pt-1 pb-1 pl-2 pr-2" v-for="(col, colIndex) in propsObj.columns" :key="colIndex">
                        <span v-if="propsObj.reportData.headerRow[colIndex].type == 'date'">{{item.cols[col] | moment("DD-MMM-YYYY")}}</span>
                        <span :class="{'font-medium': ($route.params.reportid == 601 || $route.params.reportid == 602 || $route.params.reportid == 603 || $route.params.reportid == 901) && item.accountGroupId}" v-else-if="propsObj.reportData.headerRow[colIndex].type == 'number' && (col != 'dqty' && col != 'qty' && col != 'inward' && col != 'outward' && col != 'voucherCount' && col != 'opening' && col != 'closing' && col != 'balance')">{{item.cols[col] ? $root.numberToLocale(item.cols[col]) : '-'}}</span>
                        <span :class="{'font-medium': ($route.params.reportid == 601 || $route.params.reportid == 602 || $route.params.reportid == 603 || $route.params.reportid == 901) && item.accountGroupId}" v-else>{{item.cols[col] ? item.cols[col] : '-'}}</span>
                    </td>
                </tr>

                <tr style="border-top:1px #f0f0f0 solid;border-bottom:1px #f0f0f0 solid;background-color:#ffffff !important" v-if="propsObj.reportData.footerRow">
                    <td class="pt-1 pb-1 pl-2 pr-2" v-for="(col, index) in propsObj.columns" :key="index">
                      <strong>
                        <span v-if="typeof(propsObj.reportData.footerRow[col])=='number' && (col != 'dqty' && col != 'qty' && col != 'inward' && col != 'outward' && col != 'voucherCount' && col != 'opening' && col != 'closing' && col != 'balance')">{{propsObj.reportData.footerRow[col] ? $root.numberToLocale(propsObj.reportData.footerRow[col]) : ''}}</span>
                        <span v-else>{{propsObj.reportData.footerRow[col] ? propsObj.reportData.footerRow[col] : ''}}</span>
                    </strong>
                    </td>
                </tr>
                
                <!-- <tr class="noborder">
                  <td class="noborder"><div class="page-footer-space"></div></td>
                </tr> -->
            </tbody>
        </table>

        <!-- <div class="page-footer mt-4">
            <div class="invoice-footer">
                <div class="branding grey-back">
                    <a href="https://bizopsapp.com/" target="_blank">
                        <div class="branding-box">
                            <div class="dark-opacity-color powered">Powered By</div>
                            <div class="font16 title primary-color">
                                <img class="brand_logo" src="../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div> -->

    </div>
</template>

<script>
  export default{
    props:['propsObj', 'reportFilterObj'],
  }
</script>

<style scoped>
    #my-table-print tbody tr td{
        max-width: 200px;
        white-space: normal;
    }
    #my-table-print tbody tr:nth-child(even) {background-color: #f9f9f9};
    /* #my-table-print tbody tr:last-child{background-color:#ffffff !important}; */
    /* #my-table-print tbody tr:nth-child(odd) {background-color: #4b77be} */
</style>