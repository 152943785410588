<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>Tax</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="primary" text @click="$parent.fn_checkValidations"><v-icon left>save</v-icon> Save</v-btn> -->
                   <!--  <div>
                        <div style="float: left;margin-right: 12px;margin-top: 6px;color: rgba(0,0,0,0.57);">Primary Group</div>
                        <v-switch class="hide-margins" hide-details v-model="$parent.$parent.Form.isPrimaryAccountGroup"></v-switch>
                    </div> -->
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back">

                    <ValidationObserver ref="observer">

                        <v-form @submit.prevent="submit">
                            <v-layout row wrap ma-0 pr-4 pl-4 white-back pt-4 pb-4>

                                <v-flex xs12 sm12 md12>
                                     <ValidationProvider name="Name" rules="required|max50" v-slot="{ errors }">
                                           <v-text-field 
                                               v-model="$parent.Form.name" 
                                               label="Name"
                                               required
                                               autocomplete="null"
                                               :error-messages="errors">
                                           </v-text-field>
                                       </ValidationProvider>
                                </v-flex>
                                
                                <v-flex xs12 sm12 md12 v-if="false">
                                    <v-text-field v-model="$parent.Form.shortName" label="Short Name" autocomplete="null"></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                    <v-radio-group v-model="$parent.Form.mode" row>
                                        <v-radio label="Percentage" :value="0" ></v-radio>
                                        <v-radio label="Value" :value="1"></v-radio>
                                    </v-radio-group>
                                </v-flex> 

                                <v-flex xs12 sm12 md12>
                                    <v-checkbox class="check_box" label="Adjust In Sale" v-model="$parent.Form.adjustInSale" @change="fn_focusAutocomplete('saleledger')"></v-checkbox>
                                </v-flex>

                                <v-flex xs12 sm12 md12 pt-4 v-if="!$parent.Form.adjustInSale">

                                    <ValidationProvider name="Ledger" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                id="saleledger"
                                                :items="$root.ledgers"
                                                v-model="$parent.Form.selectedSaleAccount"
                                                label="Ledger"
                                                item-value="itemId"
                                                item-text="itemName"
                                                return-object
                                                append
                                                required
                                                :error-messages="errors"
                                            >

                                                <v-list-item slot="prepend-item" class="primary--text" @click="fn_new('sale')">
                                                    <v-list-item-title>Create New</v-list-item-title>
                                                </v-list-item>

                                            </v-autocomplete>
                                        </ValidationProvider>

                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                    <v-checkbox class="check_box" label="Adjust In Purchase" v-model="$parent.Form.adjustInPurchase" @change="fn_focusAutocomplete('purchaseledger')"></v-checkbox>
                                </v-flex>

                                <v-flex xs12 sm12 md12 pt-4 v-if="!$parent.Form.adjustInPurchase">

                                    <ValidationProvider name="Ledger" rules="required" v-slot="{ errors }">

                                            <v-autocomplete
                                                id="purchaseledger"
                                                :items="$root.ledgers"
                                                v-model="$parent.Form.selectedPurchaseAccount"
                                                label="Ledger"
                                                item-value="itemId"
                                                item-text="itemName"
                                                return-object
                                                append
                                                required
                                                :error-messages="errors"
                                            >

                                                <v-list-item slot="prepend-item" class="primary--text" @click="fn_new('purchase')">
                                                    <v-list-item-title>Create New</v-list-item-title>
                                                </v-list-item>

                                            </v-autocomplete>

                                        </ValidationProvider>

                                </v-flex>

                            </v-layout>
                        </v-form>

                    </ValidationObserver>

                </div>


                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex xs12 sm12 md12 height48>
                            <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>
                  

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
// import { required } from 'vee-validate/dist/rules'
// import { extend, ValidationProvider, setInteractionMode } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })

import { ValidationProvider, ValidationObserver } from 'vee-validate'

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
                this.$parent.disableOnSave = false;
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_validateForm: function(){
                this.$parent.disableOnSave = true;
                this.$refs.observer.validate().then((result) => {
                    if(result) {
                        this.$parent.fn_checkValidations();
                    }
                    else{
                        this.$parent.saveBtnKey++;
                        this.$parent.disableOnSave = false;
                        this.fn_scrollToElement('error--text');
                    }
                });
            },
            fn_new: function(ledgerType){
              this.closeDialogWeb();
              setTimeout(()=>{
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), ls:1, type:ledgerType, groupTypeId:103}});
              },100);
            },
            fn_focusAutocomplete: function(ref){
                setTimeout(()=>{
                    document.getElementById(ref).click();
                },100)
                // val ? setTimeout(()=>this.$refs[ref].focus(),100) : '';
            }
        }
    }
</script>