<template>
    <div class="display grey-back">

        <v-list nopadding two-line>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Item Code</v-list-item-subtitle>
                    <v-list-item-title>{{displayData.productCode ? displayData.productCode : '-'}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Item Type</v-list-item-subtitle>
                    <v-list-item-title>{{displayData.groupTypeId == 101 ? 'Goods' : 'Service'}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Unit</v-list-item-subtitle>
                    <v-list-item-title>{{displayData.productVariants[0].unitName}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <template v-if="displayData.groupTypeId==101">
                <v-divider></v-divider>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>{{displayData.inventory ? 'Sale Price' : 'Price'}}</v-list-item-subtitle>
                        <v-list-item-title>
                            <span v-if="displayData.sellingPrice">&#8377;</span> 
                            {{displayData.sellingPrice ? $root.numberToLocale(displayData.sellingPrice) : '-'}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>  
            </template>

            <template v-if="displayData.inventory && displayData.groupTypeId==101">
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Whole Sale Price</v-list-item-subtitle>
                        <v-list-item-title>
                            <span v-if="displayData.wholeSalePrice">&#8377;</span> 
                            {{displayData.wholeSalePrice ? $root.numberToLocale(displayData.wholeSalePrice) : '-'}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <template v-if="$root.TransactionSettings.mrpWiseStock && displayData.inventory">
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>MRP</v-list-item-subtitle>
                        <v-list-item-title>
                            <span v-if="displayData.mrp">&#8377;</span> 
                            {{displayData.mrp ? $root.numberToLocale(displayData.mrp) : '-'}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <template v-if="displayData.groupTypeId==102">
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Charges</v-list-item-subtitle>
                        <v-list-item-title>&#8377; {{displayData.charges ? $root.numberToLocale(displayData.charges) : '-'}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>

        <v-list nopadding class="mt-2" two-line v-if="$root.CurrentBusiness && $root.CurrentBusiness.GSTRegistrationStatus==1">
            <v-subheader light>Tax Details</v-subheader>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>GST</v-list-item-subtitle>
                    <v-list-item-title>{{displayData.taxCategoryName ? displayData.taxCategoryName : '-'}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            
            <template v-if="displayData.hsnCode">
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>HSN Code</v-list-item-subtitle>
                        <v-list-item-title>{{displayData.hsnCode ? displayData.hsnCode : '-'}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>

        <template v-if="displayData.batches && displayData.batches">
			<v-subheader class="mt-2 white-back">{{ displayData.selectedBatchType == 2 ? '#Series' : '#Batches' }}</v-subheader>
			<v-divider></v-divider>
			<template v-for="(batch, batchIndex) in displayData.batches">
				<v-card flat :key="batchIndex" v-if="batch.opening">
					<v-card-text>
						<v-layout ma-0 row wrap>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.batchNumber">
								<div light-opacity-color>Batch No.</div>
								<div>{{ batch.batchNumber }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.batchSize">
								<div light-opacity-color>Size</div>
								<div>{{ batch.batchSize }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.batchMRP">
								<div light-opacity-color>MRP</div>
								<div>{{ batch.batchMRP }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.mfgDate">
								<div light-opacity-color>Mfg. Date</div>
								<div v-if="batch.mfgDate">{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.expDate">
								<div light-opacity-color>Exp. Date</div>
								<div v-if="batch.expDate">{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.opening && !batch.field1 && !batch.field2 && !batch.field3">
								<div light-opacity-color>Opening</div>
								<div>{{ batch.opening ? batch.opening : 0  }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.purchaseRate">
								<div light-opacity-color>Purchase Rate</div>
								<div>{{ batch.purchaseRate }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.field1">
								<div light-opacity-color>Serial Number</div>
								<div>{{ batch.field1 }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.field2">
								<div light-opacity-color>IMEI Number</div>
								<div>{{ batch.field2 }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.field3">
								<div light-opacity-color>Part Number</div>
								<div>{{ batch.field3 }}</div>
							</v-flex>
							<v-flex xs3 sm3 md3 mb-2 v-if="batch.field4">
								<div light-opacity-color>Model Number</div>
								<div>{{ batch.field4 }}</div>
							</v-flex>
						</v-layout>
					</v-card-text>
				</v-card>
				<v-divider :key="'divider'+batchIndex" v-if="batch.opening && displayData.batches.length - 1 != batchIndex"></v-divider>
			</template>
			
		</template>

        <v-list nopadding class="mt-2" two-line>
            <v-subheader light>Other Details</v-subheader>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Category</v-list-item-subtitle>
                    <v-list-item-title>{{displayData.productCategoryName ? displayData.productCategoryName : '-'}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Description</v-list-item-subtitle>
                    <v-list-item-title>{{displayData.description ? displayData.description : '-'}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

                
            <template v-if="displayData.groupTypeId == 101 && displayData.inventory">
                <v-divider></v-divider>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Brand</v-list-item-subtitle>
                        <v-list-item-title>{{displayData.brandName ? displayData.brandName : '-'}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Company</v-list-item-subtitle>
                        <v-list-item-title>{{displayData.companyName ? displayData.companyName : '-'}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>                  
            </template>
        </v-list>

    </div>
</template>

<script>
    export default{
        props:['displayData']
    }
</script>