<template>
    <div>
        <v-dialog v-model="dialog_toggle_options" v-if="dialog_toggle_options" no-click-animation persistent content-class="white-back" max-width="400px">

            <v-card full-height flat>
                <v-toolbar flat :height="56" style="position: sticky;top: 0;border-bottom: 1px #f0f0f0 solid;">
                    <v-btn icon @click="dialog_toggle_options=false"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>Item Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small outlined color="primary" @click="dialog_toggle_options=false">done</v-btn>
                    <!-- <v-spacer></v-spacer>
                    <v-btn small outlined color="primary" @click="$parent.fn_savePrintOptions">done</v-btn> -->
                </v-toolbar> 
                <v-card-text nopadding>
                    <v-alert class="pt-2 pb-2" type="info" icon="info_outline" :value="true">
                        You can Enable/Disable item fields if added any
                    </v-alert>
                    <div class="switches" white-background>      
                        <v-switch class="mt-1" label="Note" v-model="$parent.description.showInPrintOut" nomargin @change="$parent.fn_toggleItemDetails('item_description', $event)"></v-switch>
                        
                        <v-switch class="mt-1" label="Item Description" v-model="$parent.itemDescription.showInPrintOut" nomargin @change="$parent.fn_toggleItemDetails('item_itemDescription', $event)"></v-switch>

                        <v-switch class="mt-1" label="Category" v-model="$parent.category.showInPrintOut" nomargin @change="$parent.fn_toggleItemDetails('item_category', $event)"></v-switch>

                        <v-switch class="mt-1" label="Brand" v-model="$parent.brand.showInPrintOut" nomargin @change="$parent.fn_toggleItemDetails('item_brand', $event)"></v-switch>

                        <v-switch class="mt-1" label="Company" v-model="$parent.company.showInPrintOut" nomargin @change="$parent.fn_toggleItemDetails('item_company', $event)"></v-switch>
                    </div>
                </v-card-text>
            </v-card>   
        </v-dialog>
    </div>
</template>     

<script>

export default{
    data(){ 
        return{
            dialog_toggle_options:false
        }
    },
    methods:{
        openDialogWeb: function () {
            this.dialog_toggle_options = true;
        },

        closeDialogWeb: function () {
            this.dialog_toggle_options = false;
        },

        // checkFieldAvailable: function(value){
        //     let itemIndex = this.$parent.data.transactionDetails.otherDetails.transactionFields.findIndex(field=>field.fieldId === value.fieldId);
        //     if(itemIndex!=-1 || value.groupName == 'InvoiceTable'){
        //         return true;
        //     }
        //     else{
        //         return false;
        //     }
        // }
    }
}
</script>