<template>
    <div>
        <v-dialog v-model="dialog_number_series" no-click-animation persistent lazy full-width width="360px">
            <v-card class="pb-3">
                <v-card-title class="pt-2 pb-2 pr-2 pl-4" noshadow font-medium>
					{{ changePrefix ? 'Change Prefix' : 'Change Invoice No.' }}
					<v-spacer></v-spacer>
					<v-btn icon nomargin @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
				</v-card-title>
                <v-card-text class="pa-4">
                    <ValidationObserver ref="observer">
                        <v-form data-vv-scope="field">
                            <v-layout row wrap ma-0 v-if="!changePrefix">

                                <v-flex xs3 sm3 md3>
                                    <v-text-field
                                        label="Prefix"
                                        placeholder=" "
                                        :value="prefix"
                                        :disabled="true"
                                    ></v-text-field>
                                </v-flex>

                                <!-- <v-flex xs6 sm6 md6 pr-3 pl-3>
                                    <v-text-field
                                    label="Number"
                                    placeholder=" "
                                    type="number"
                                    v-model="transactionNumber"
                                    v-validate="'required|max:9'"
                                    data-vv-name="number" 
                                    :error-messages="errors.collect('field.number')" 
                                ></v-text-field>
                                </v-flex> -->

                                <v-flex xs6 sm6 md6 pr-3 pl-3>
                                    <ValidationProvider name="Number" rules="required" v-slot="{ errors }">
                                        <v-text-field
                                        label="Number"
                                        placeholder=" "
                                        type="number"
                                        v-model="transactionNumber"
                                        :error-messages="errors"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-flex>

                                <v-flex xs3 sm3 md3>
                                    <v-text-field
                                    label="Suffix"
                                    placeholder=" "
                                    v-model="suffix"
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-layout ma-0 row wrap v-else>
                                <v-flex xs12 sm12 md12 mb-2>
                                    <!-- <ValidationProvider name="Prefix" rules="required" v-slot="{ errors }">
                                        <v-text-field 
                                            v-model="newPrefix" 
                                            label="Enter Prefix" 
                                            :error-messages="errors"
                                            placeholder=" ">
                                        </v-text-field>
                                    </ValidationProvider> -->
                                    <v-text-field 
                                        v-model="newPrefix" 
                                        label="Enter Prefix" 
                                        placeholder=" ">
                                    </v-text-field>
                                </v-flex>

                                <v-alert icon="info_outline" style="border-radius: 5px;" :value="true" color="info full-width font-small" class="pt-2 pb-2">
                                    It will override the current prefix.
                                </v-alert>
                            </v-layout>
                        </v-form>
                    </ValidationObserver>
                    <v-alert :value="true" color="info" class="pt-3 pb-3" v-if="invalidSeries">
                        <ul>
                            <li class="mb-3">A commulative number series (prefix+number+suffix) cannot be greater than {{ transactionTypeId && transactionTypeId == 10005 ? '25' : '16' }} characters.</li>
                            <li>Prefix and suffix in series can only contain alphanumeric and "<strong>/</strong>" or "<strong>-</strong>" characters.</li>
                        </ul>
                    </v-alert>
                </v-card-text>
                <v-card-actions class="pr-4 pl-4">
                  <!-- <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                  <v-btn color="blue darken-1" text @click.native="fn_saveNumberSeries">ok</v-btn> -->
                    <v-layout ma-0 row wrap center-align v-if="!changePrefix">
                        <v-flex xs6 sm6 md6 pr-2 @click="fn_openPrefixDialog">
                            <v-btn capitalize outlined block style="border-radius:5px" color="primary" text>Change Prefix</v-btn>
                        </v-flex>
                        <v-flex xs6 sm6 md6 pl-2 @click="fn_saveNumberSeries">
                            <v-btn capitalize block style="border-radius:5px" color="primary">save</v-btn>		
                        </v-flex>
                    </v-layout>

                    <v-layout ma-0 row wrap center-align v-else>
                        <v-flex xs6 sm6 md6 pr-2 @click="changePrefix=false">
                            <v-btn capitalize outlined block style="border-radius:5px" color="primary" text>Cancel</v-btn>
                        </v-flex>
                        <v-flex xs6 sm6 md6 pl-2 @click="fn_savePrefix">
                            <v-btn capitalize block style="border-radius:5px" color="primary">Save</v-btn>		
                        </v-flex>
                    </v-layout>
                </v-card-actions>
                <!-- <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                  <v-btn color="blue darken-1" text @click.native="fn_saveNumberSeries">ok</v-btn>
                </v-card-actions> -->
            </v-card>   
        </v-dialog>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default{
    props:['checkTransactionNumber', 'transactionTypeId'],
    data(){ 
        return{
            dialog_number_series: false,
            prefix:null,
            transactionNumber:null,
            suffix:null,
            invalidSeries:false,
            changePrefix:false,
			newPrefix:null
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        openDialogWeb: function () {    
            this.dialog_number_series = true;
            this.prefix = this.$parent.Form.transactionData.prefix;
            this.suffix = this.$parent.Form.transactionData.suffix;

            let tNumber = this.$parent.Form.transactionData.mergeTransactionNumber;
            tNumber = tNumber.substring(this.prefix.length);
            this.suffix ? tNumber = tNumber.substring(0, tNumber.length-this.suffix.length) : '';
            
            this.transactionNumber = tNumber;

            this.newPrefix = null;
			this.changePrefix = false;
        },
        closeDialogWeb: function () {
            this.dialog_number_series = false;
        },

        fn_openPrefixDialog: function(){
            if(this.$root.appVersion < 109){
				this.$eventHub.$emit('UpdateMobileApp');
				return;
			}

			console.log('ksjdsj');
            if(!this.transactionNumber){
                this.$eventHub.$emit('snackbarAlert', {msg:'Enter transaction number'});
			}
            this.$refs.observer.validate().then((result) => {
                if(result){
                    this.changePrefix = true;
                    this.$nextTick(() => {
                        this.newPrefix = this.prefix;
                    });
                }
            })
		},

        fn_savePrefix: function(){

            let mergedSeries = this.newPrefix+this.transactionNumber+(this.suffix ? this.suffix : '');

            let valid = true;
            let maxLength = this.transactionTypeId && this.transactionTypeId == 10005 ? 25 : 16; 
            if(mergedSeries.length>maxLength){
                valid = false;
            }

            let pattern = /^[0-9a-zA-Z/ -]+$/;
            if(!pattern.test(mergedSeries)){
                valid = false;
            }

            if(!valid){
                this.invalidSeries = true;
                this.$eventHub.$emit('snackbarAlert', {msg:'Number series criteria not matched'});
                return;
            }

            this.$refs.observer.validate().then((result) => {
                if(result){
                    // if(this.newPrefix === this.prefix){
                    //     this.changePrefix = false;
                    //     return;
                    // }

                    let self = this;
                    let postObj = {};
                    postObj.transactionNumberId = this.$parent.Form.transactionData.transactionNumberId;
                    postObj.otherPrefix = this.newPrefix;
                    this.postData('SetOtherPrefix', postObj, {
                        onSuccess: function(response){
                            console.log(response);
                            self.changePrefix = false;
                            self.prefix = self.newPrefix;
                            if(self.$parent.Form.transactionTypeData.numberSeries){
                                self.$parent.Form.transactionTypeData.numberSeries.otherPrefix = self.prefix;
                            }
                            if(self.transactionNumber){	
                                self.fn_saveNumberSeries();
                            }
                            // self.fn_saveNumberSeries();
                            
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    });
                }
            })
            // if(!this.newPrefix){
            //     this.$eventHub.$emit('snackbarAlert', {msg:'Enter prefix value'});
			// 	return;
			// }
		},

        fn_saveNumberSeries: function () {
            // var self = this;
            // this.$validator.validateAll('field').then((result) => {
            //     if (result) {
            //         this.fn_validateSeries();
            //     }
            // });
            this.$refs.observer.validate().then((result) => {
                if(result){
                    this.fn_validateSeries();
                }
            })
        },

        fn_validateSeries: function(){

            // if(!this.transactionNumber){
            //     this.$eventHub.$emit('snackbarAlert', {msg:'Enter transaction number'});
            //     return;
            // }

            let mergedSeries = this.prefix+this.transactionNumber+(this.suffix ? this.suffix : '');

            let valid = true;
            let maxLength = this.transactionTypeId && this.transactionTypeId == 10005 ? 25 : 16; 
            if(mergedSeries.length>maxLength){
                valid = false;
            }

            let pattern = /^[0-9a-zA-Z/ -]+$/;
            if(!pattern.test(mergedSeries)){
                valid = false;
            }

            if(!valid){
                this.invalidSeries = true;
                // this.showToast("Number series criteria not matched");
                this.$eventHub.$emit('snackbarAlert', {msg:'Number series criteria not matched'});
                return;
            }

            this.$parent.Form.transactionData.prefix = this.prefix;
            this.$parent.Form.transactionData.transactionNumber = this.transactionNumber;
            this.$parent.Form.transactionData.suffix = this.suffix;
            this.$parent.Form.transactionData.mergeTransactionNumber = mergedSeries;
            this.checkTransactionNumber();
            // this.$parent.fn_closeDialog();
            this.closeDialogWeb();
            
        },

    }
}
</script>
<!-- <template>
    <div>
        <v-dialog v-model="dialog_number_series" no-click-animation persistent lazy full-width width="290px">
            <v-card>
                <v-card-text class="pt-4">
                    <v-form data-vv-scope="field">
                        <v-layout row wrap ma-0>

                            <v-flex xs3 sm3 md3>
                                <v-text-field
                                    label="Prefix"
                                    placeholder=" "
                                    :value="prefix"
                                    :disabled="true"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs6 sm6 md6 pr-3 pl-3>
                                <v-text-field
                                label="Number"
                                placeholder=" "
                                type="number"
                                v-model="transactionNumber"
                            ></v-text-field>
                            </v-flex>

                            <v-flex xs3 sm3 md3>
                                <v-text-field
                                label="Suffix"
                                placeholder=" "
                                v-model="suffix"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-form>
                    <v-alert :value="true" color="info" class="pt-3 pb-3" v-if="invalidSeries">
                        <ul>
                            <li class="mb-3">A commulative number series (prefix+number+suffix) cannot be greater than 16 characters.</li>
                            <li>Prefix and suffix in series can only contain alphanumeric and "<strong>/</strong>" or "<strong>-</strong>" characters.</li>
                        </ul>
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                  <v-btn color="blue darken-1" text @click.native="fn_saveNumberSeries">ok</v-btn>
                </v-card-actions>
            </v-card>   
        </v-dialog>
    </div>
</template>

<script>
export default{
    props:['checkTransactionNumber'],
    data(){ 
        return{
            dialog_number_series: false,
            prefix:null,
            transactionNumber:null,
            suffix:null,
            invalidSeries:false
        }
    },
    methods:{
        openDialogWeb: function () {    
            this.dialog_number_series = true;
            this.prefix = this.$parent.Form.transactionData.prefix;
            this.suffix = this.$parent.Form.transactionData.suffix;

            let tNumber = this.$parent.Form.transactionData.mergeTransactionNumber;
            tNumber = tNumber.substring(this.prefix.length);
            this.suffix ? tNumber = tNumber.substring(0, tNumber.length-this.suffix.length) : '';
            
            this.transactionNumber = tNumber;
        },
        closeDialogWeb: function () {
            this.dialog_number_series = false;
        },
        fn_saveNumberSeries: function () {
            if(!this.transactionNumber){
                this.$eventHub.$emit('snackbarAlert', {msg:'Enter transaction number'});
            }
            else{
                this.fn_validateSeries();
            }
        },

        fn_validateSeries: function(){

            let mergedSeries = this.prefix+this.transactionNumber+(this.suffix ? this.suffix : '');

            let valid = true;
            if(mergedSeries.length>16){
                valid = false;
            }

            let pattern = /^[0-9a-zA-Z/ -]+$/;
            if(!pattern.test(mergedSeries)){
                valid = false;
            }

            if(!valid){
                this.invalidSeries = true;
                this.showToast("Number series criteria not matched");
                return;
            }

            this.$parent.Form.transactionData.prefix = this.prefix;
            this.$parent.Form.transactionData.transactionNumber = this.transactionNumber;
            this.$parent.Form.transactionData.suffix = this.suffix;
            this.$parent.Form.transactionData.mergeTransactionNumber = mergedSeries;
            this.checkTransactionNumber();
            this.closeDialogWeb();
            
        },

    }
}
</script> -->