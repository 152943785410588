export const masterMixin =  {

    data(){
        return{
            listItemObject:{"items":[], "totalCount":0, "totalAmount":null},
            searchValue:'',
            startIndex:0,
            scrollPositionTop:0,
            loadMore:false,
            showPlaceHolder:false,
            timeoutid:null,
            disableOnSave:false,
            loadTimeOut:false
        }
    },

    methods: {

        onScroll (event) {
            var element = event.target;
            if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight)
            {
                console.log('scrolled');
                if(this.listItemObject.items.length < this.listItemObject.totalCount){
                    this.fn_loadItems();
                }
            }
        },

        fn_debounceSearch: function(){
             if (this.timeoutid != null){
                clearTimeout(this.timeoutid);
             }

            this.timeoutid = setTimeout(() => { 
                this.fn_searchItem();
            },300);
        },

       fn_deleteMasterItem: function(type, itemId, callback){
            // let self = this;
            this.deleteData(type, itemId, {
                onSuccess: function(response){
                    console.log(response);
                    callback(JSON.parse(response));
                }, 
                onError: function(error){
                  console.log(error);
                }
            });     
        },

        fn_loadTimeOut: function(){
            setTimeout(() => {
                if(this.loadMore){
                  this.loadTimeOut = true;
                  this.loadMore = false;
                }
                else{
                  this.loadTimeOut = false;
                }
            }, 1 * 10 * 1000);
        }

    }
}