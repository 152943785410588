<template>
    <!-- <div class="ledger-statement white-back" :class="[statementView ? 'statement-view font6' : 'font10']"> -->

    <div class="ledger-statement white-back pl-2 pr-2">

        <!-- <div class="statement-header">
            <v-layout ma-0 row wrap pa-2 :style="{'minHeight': statementView ? '92px' : '112px'}">
                <v-flex xs6 sm6 md6>
                    <div v-if="$root.CurrentBusiness" class="details-business">
                        <img :src="$root.CurrentBusiness.businessLogo" alt="" v-if="$root.CurrentBusiness.businessLogo">
                        <div style="margin-left:80px" :style="{'marginLeft':$root.CurrentBusiness.businessLogo ? '80px' : '0px'}">
                            <div font16>{{$root.CurrentBusiness.BusinessName}}</div>
                            <div v-if="$root.CurrentBusiness.Address">{{$root.CurrentBusiness.Address}}</div>
                            <div v-if="$root.CurrentBusiness.GSTNumber">GSTIN: {{$root.CurrentBusiness.GSTNumber}}</div>
                        </div>
                    </div>
                </v-flex>

                <v-flex xs6 sm6 md6 right-align style="position:relative">

                <div>{{statementType == 1 ? 'Ledger Statement' :  'Ledger Activity'}}</div>

                    <div white-back class="branding" style="position:absolute;right:0;bottom:0;padding:0 !important;border-radius: 5px;">
                        <div style="background:rgba(75,119,190,.2)!important;padding:4px 8px">
                            <div class="dark-opacity-color">Powered By</div>
                            <div class="font16 title primary-color" style="line-height:18px">
                                <img class="brand_logo" src="../../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo" style="width:18px;margin-right:4px;margin-bottom:0px">BizOps App
                            </div>
                        </div>
                    </div>
                </v-flex>
            </v-layout>
        </div> -->

        <div white-back>

            <!-- <div class="party-details pa-2">
                <div><strong>Period</strong>:&nbsp;{{statementData.accountDetails.startDate | moment("DD-MMM-YYYY")}}&nbsp;To&nbsp;{{statementData.accountDetails.endDate | moment("DD-MMM-YYYY")}}</div>
                <div class="pt-2"><strong>{{statementData.accountDetails.accountName}}</strong></div>
                <div v-if="statementData.accountDetails.primaryMobileNumber"><strong>Mobile</strong>:&nbsp;+91-{{statementData.accountDetails.primaryMobileNumber}}</div>
                <div v-if="statementData.accountDetails.email"><strong>Email</strong>:&nbsp;{{statementData.accountDetails.email}}</div>
                <div v-if="statementData.accountDetails.gstNumber"><strong>GSTIN</strong>:&nbsp;{{statementData.accountDetails.gstNumber}}</div>
            </div>

            <v-layout ma-0 mt-2 mb-2 row wrap class="statement-summary" v-if="statementType == 1" style="border-left:1px #ddd solid">

              <v-flex xs4 sm4 md4 class="left-box">
                <div class="pa-2">
                    <div>
                        <span v-if="statementData.accountDetails.opening">
                            <strong>&#8377; {{$root.numberToLocale(Math.abs(statementData.accountDetails.opening))}}</strong>
                        </span>
                        <span v-else><strong>&#8377; 0</strong></span>
                    </div>
                    <div><strong>Opening Balance</strong></div>
                </div>
              </v-flex>

              <v-flex xs4 sm4 md4 pl-2 pr-2 class="middle-box">
                <div class="pt-2 pb-2" style="border-bottom:1px #ddd solid;">
                    <div greencolor>
                        <span v-if="statementData.accountDetails.debitTotal">
                            <strong>&#8377; {{$root.numberToLocale(statementData.accountDetails.debitTotal)}}</strong>
                        </span>
                        <span v-else><strong>&#8377; 0</strong></span>
                    </div>
                    <div><strong>To Receive</strong></div>
                </div>

                <div class="pt-2 pb-2">
                    <div redcolor>
                        <span v-if="statementData.accountDetails.creditTotal">
                            <strong>&#8377; {{$root.numberToLocale(statementData.accountDetails.creditTotal)}}</strong>
                        </span>
                        <span v-else><strong>&#8377; 0</strong></span>
                    </div>
                    <div><strong>To Pay</strong></div>
                </div>
              </v-flex>

              <v-flex xs4 sm4 md4 class="right-box">
                <div class="pa-2">
                    <div primary-color>
                        <span v-if="statementData.accountDetails.balance">
                            <strong>&#8377; {{$root.numberToLocale(Math.abs(statementData.accountDetails.balance))}}</strong>
                        </span>
                    <span v-else><strong>&#8377; 0</strong></span>
                    </div>
                    <div><strong>Closing Balance</strong></div>
                    <div margin-top-ten color-light-grey v-if="statementData.data.footerRow">
                        {{statementData.data.footerRow.creditDebitType}} as on:<br> {{statementData.accountDetails.endDate | moment("DD-MMM-YYYY")}}
                    </div>
                </div>
              </v-flex>

            </v-layout> -->

            <div>
                <table border="0" id="statement_table">

                    <!-- <thead>
                        <tr>
                            <th left-align>Number</th>
                            <th left-align>Date</th>
                            <th left-align>Voucher</th>
                            <th left-align>Particulars</th>
                            <th right-align v-if="statementType == 2">Amount (&#8377;)</th>
                            <th right-align v-if="statementType == 1">To Receive (&#8377;)</th>
                            <th right-align v-if="statementType == 1">To Pay (&#8377;)</th>
                            <th right-align v-if="statementType == 1">Balance (&#8377;)</th>
                        </tr>
                    </thead> -->
                    
                    <tbody>

                        <tr>
                            <td class="pa-0" :colspan="fn_getColSpanVal()">
                                <div class="statement-header">
                                    <v-layout ma-0 row wrap pa-2 :style="{'minHeight': statementView ? '92px' : '112px'}">
                                        <v-flex xs6 sm6 md6>
                                            <div v-if="$root.CurrentBusiness" class="details-business">
                                                <img :src="$root.CurrentBusiness.businessLogo" alt="" v-if="$root.CurrentBusiness.businessLogo">
                                                <!-- <div style="margin-left:80px" :style="{'marginLeft':$root.CurrentBusiness.businessLogo ? '80px' : '0px'}"> -->
                                                <div style="display: grid;">
                                                    <div font16>{{$root.CurrentBusiness.BusinessName}}</div>
                                                    <div v-if="$root.CurrentBusiness.Address">{{$root.CurrentBusiness.Address}}</div>
                                                    <div v-if="$root.CurrentBusiness.GSTNumber">GSTIN: {{$root.CurrentBusiness.GSTNumber}}</div>
                                                </div>
                                            </div>
                                        </v-flex>

                                        <v-flex xs6 sm6 md6 right-align style="position:relative">

                                        <div>{{statementType == 1 ? 'Ledger Statement' :  'Ledger Activity'}}</div>

                                            <div white-back class="branding" style="position:absolute;right:0;bottom:0;padding:0 !important;border-radius: 5px;" v-if="!$root.TransactionSettings.removeLogoFromStatement">
                                                <a href="https://bizopsapp.com/" target="_blank">
                                                    <div style="background:rgba(75,119,190,.2)!important;padding:4px 8px">
                                                        <div class="dark-opacity-color">Powered By</div>
                                                        <div class="font16 title primary-color" style="line-height:18px">
                                                            <img class="brand_logo" src="../../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo" style="width:18px;margin-right:4px;margin-bottom:0px">BizOps App
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </td>
                            <!-- <td v-if="$root.TransactionSettings.ledgerStatementNotes"></td> -->
                        </tr>

                        <tr>
                            <td class="pa-0" :colspan="fn_getColSpanVal()">
                                <div>
                                    <div class="party-details pa-2">
                                        <div><strong>Period</strong>:&nbsp;{{statementData.accountDetails.startDate | moment("DD-MMM-YYYY")}}&nbsp;To&nbsp;{{statementData.accountDetails.endDate | moment("DD-MMM-YYYY")}}</div>
                                        <div class="pt-2"><strong>{{statementData.accountDetails.accountName}}</strong></div>
                                        <div v-if="statementData.accountDetails.primaryMobileNumber"><strong>Mobile</strong>:&nbsp;+91-{{statementData.accountDetails.primaryMobileNumber}}</div>
                                        <div v-if="statementData.accountDetails.email"><strong>Email</strong>:&nbsp;{{statementData.accountDetails.email}}</div>
                                        <div v-if="statementData.accountDetails.gstNumber"><strong>GSTIN</strong>:&nbsp;{{statementData.accountDetails.gstNumber}}</div>
                                    </div>

                                    <v-layout ma-0 mt-2 mb-2 row wrap class="statement-summary" v-if="statementType == 1" style="border-left:1px #ddd solid">

                                    <v-flex xs4 sm4 md4 class="left-box">
                                        <div class="pa-2">
                                            <div>
                                                <span v-if="statementData.accountDetails.opening">
                                                    <strong>&#8377; {{$root.numberToLocale(Math.abs(statementData.accountDetails.opening))}}</strong>
                                                </span>
                                                <span v-else><strong>&#8377; 0</strong></span>
                                            </div>
                                            <div><strong>Opening Balance</strong></div>
                                        </div>
                                    </v-flex>

                                    <v-flex xs4 sm4 md4 pl-2 pr-2 class="middle-box">
                                        <div class="pt-2 pb-2" style="border-bottom:1px #ddd solid;">
                                            <div greencolor>
                                                <span v-if="statementData.accountDetails.debitTotal">
                                                    <strong>&#8377; {{$root.numberToLocale(statementData.accountDetails.debitTotal)}}</strong>
                                                </span>
                                                <span v-else><strong>&#8377; 0</strong></span>
                                            </div>
                                            <div><strong>To Receive</strong></div>
                                        </div>

                                        <div class="pt-2 pb-2">
                                            <div redcolor>
                                                <span v-if="statementData.accountDetails.creditTotal">
                                                    <strong>&#8377; {{$root.numberToLocale(statementData.accountDetails.creditTotal)}}</strong>
                                                </span>
                                                <span v-else><strong>&#8377; 0</strong></span>
                                            </div>
                                            <div><strong>To Pay</strong></div>
                                        </div>
                                    </v-flex>

                                    <v-flex xs4 sm4 md4 class="right-box">
                                        <div class="pa-2">
                                            <div primary-color>
                                                <span v-if="statementData.accountDetails.balance">
                                                    <strong>&#8377; {{$root.numberToLocale(Math.abs(statementData.accountDetails.balance))}}</strong>
                                                </span>
                                            <span v-else><strong>&#8377; 0</strong></span>
                                            </div>
                                            <div><strong>Closing Balance</strong></div>
                                            <div margin-top-ten color-light-grey v-if="statementData.data.footerRow">
                                                {{statementData.data.footerRow.creditDebitType}} as on:<br> {{statementData.accountDetails.endDate | moment("DD-MMM-YYYY")}}
                                            </div>
                                        </div>
                                    </v-flex>

                                    </v-layout>
                                </div>
                            </td>
                            <!-- <td v-if="$root.TransactionSettings.ledgerStatementNotes"></td> -->
                        </tr>
                        
                        <tr class="theader">
                            <th left-align>Number</th>
                            <th left-align>Date</th>
                            <th left-align>Voucher</th>
                            <th left-align>Particulars</th>
                            <th right-align v-if="statementType == 2">Amount (&#8377;)</th>
                            <th right-align v-if="statementType == 1">To Receive (&#8377;)</th>
                            <th right-align v-if="statementType == 1">To Pay (&#8377;)</th>
                            <th right-align v-if="statementType == 1">Balance (&#8377;)</th>
                            <th left-align v-if="$root.TransactionSettings.ledgerStatementNotes" style="max-width: 68px;">Notes</th>
                        </tr>

                        <tr class="body" v-for="(row, index) in statementData.data.detailRows" :key="index">
                            
                            <td left-align>{{row.cols.mergeTransactionNumber ? row.cols.mergeTransactionNumber : ''}}</td>
                            <td left-align>
                                <span v-if="row.cols.transactionDate">{{row.cols.transactionDate | moment("DD-MMM-YYYY")}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td left-align>{{row.cols.transactionListId ? row.cols.transactionListId : ''}}</td>
                            <td left-align>{{row.cols.party ? row.cols.party : ''}}</td>

                            <td right-align v-if="statementType == 2">
                                <span>&#8377; {{$root.numberToLocale(row.cols.roundedAmount)}}</span>
                            </td>
                            <!-- <td right-align v-if="statementType == 2">
                                {{row.cols.debit ? 'Dr' : row.cols.credit ? 'Cr' : '-'}}
                            </td> -->
                            

                            <td right-align v-if="statementType == 1">
                                <span v-if="row.cols.debit">{{$root.numberToLocale(row.cols.debit)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td right-align v-if="statementType == 1">
                                <span v-if="row.cols.credit">{{$root.numberToLocale(row.cols.credit)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td right-align v-if="statementType == 1">
                                <span v-if="row.cols.partyBalance">{{$root.numberToLocale(row.cols.partyBalance)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>

                            <td left-align v-if="$root.TransactionSettings.ledgerStatementNotes" style="max-width: 68px;">{{row.cols.notes}}</td>
                        </tr>

                        <tr class="table-footer sticky-bottom white-back" v-if="statementData.data.footerRow && statementType == 1">
                            <td left-align>Total</td>
                            <!-- <td left-align></td> -->
                            <td left-align></td>
                            <td left-align></td>
                            <td left-align></td>

                            <td right-align v-if="statementType == 2">
                                <span v-if="statementData.data.footerRow && statementData.data.footerRow.amount">&#8377; {{$root.numberToLocale(statementData.data.footerRow.totalAmount)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <!-- <td right-align v-if="statementType == 2">
                                {{statementData.data.footerRow.debit ? 'Dr' : statementData.data.footerRow.credit ? 'Cr' : '-'}}
                            </td> -->

                            <td right-align v-if="statementType == 1">
                                <span v-if="statementData.data.footerRow && statementData.data.footerRow.debit">&#8377; {{$root.numberToLocale(statementData.data.footerRow.debit)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td right-align v-if="statementType == 1">
                                <span v-if="statementData.data.footerRow && statementData.data.footerRow.credit">&#8377; {{$root.numberToLocale(statementData.data.footerRow.credit)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td right-align v-if="statementType == 1">
                                <span v-if="statementData.data.footerRow && statementData.data.footerRow.partyBalance">&#8377; {{$root.numberToLocale(statementData.data.footerRow.partyBalance)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>

                            <td v-if="$root.TransactionSettings.ledgerStatementNotes" style="max-width: 68px;"></td>
                        </tr>
                    </tbody>

                </table>
            </div>

        </div>
    </div>
</template> 

<script>
    export default{
        // props:['statementData', 'statementType', 'opening']
        props:['statementData', 'statementType', 'statementView'],
        methods:{
            fn_getColSpanVal: function(){
                if(this.$root.TransactionSettings.ledgerStatementNotes){
                    if(this.statementType == 1){
                        return 8;
                    }
                    else{
                        return 6;
                    }
                }
                else{
                    if(this.statementType == 1){
                        return 7;
                    }
                    else{
                        return 5;
                    }
                }
            }
        }
    }
</script>

<style>
    .ledger-statement table{
        width: 100%;
        border-collapse:  collapse;
    }
    .ledger-statement table .theader{
        margin-bottom: 10px;color:rgba(0,0,0,0.87) !important;border-bottom:1px #ddd solid;border-top:1px #ddd solid
    }
    .ledger-statement table .theader{
        height: 36px;
    }
    .ledger-statement.statement-view table .theader{
        height: 28px;
    }
    .ledger-statement table tbody tr{
        height: 32px;
    }
    .ledger-statement.statement-view table tbody tr{
        height: 24px;
    }
    .ledger-statement table tr td, table tr th{
        padding: 0px 4px;
    }
    /*.ledger-statement tr.body:nth-child(odd) {
        background-color: rgba(75,119,190,0.2) !important;
    }*/

    .ledger-statement .divider-heavy{
        border-width: thin 4px 2px;margin-top: 16px;border-color: #999;
    }

    /*.ledger-statement .statement-summary{
        margin-bottom: 16px;
    }
    .ledger-statement .statement-summary .flex{
        border: 1px #ddd solid;
    }*/
    .ledger-statement .statement-summary .flex.middle-box{
        border-right: 1px #ddd solid;
    }
    .ledger-statement .statement-summary .flex.left-box{
        border-right: 1px #ddd solid;
    }
    .ledger-statement .statement-summary .header{
        padding: 8px 0px;background: ghostwhite;font-weight:600;
    }
    .ledger-statement .table-footer{
        font-weight:600;border-top:1px #ddd solid;height:42px;
    }
    .ledger-statement .watermark{
        width:auto;padding:4px 0px;
    }
    .ledger-statement .watermark.custom{
        border-radius:4px;
        padding:4px 10px !important;
        background:#fff !important;
        width:fit-content;
    }
    .ledger-statement .statement-header{
        min-height: 92px;
        background-color:#4b77be !important;
        color:#fff !important;
        -webkit-print-color-adjust: exact !important;
    }
    .ledger-statement.statement-view .statement-header{
        min-height: 72px;
    }
    .ledger-statement .party-details{
        width:280px;
        float:left;
    }
    .ledger-statement.statement-view .party-details{
        width:152px !important;
    }
    .details-business img{
        /* width:72px;
        height:72px; */
        max-width: 60px;
        max-height: 60px;
        float:left;
        margin-right: 8px;
    }
    /* .statement-view .details-business img{
        width:48px;height:48px;
    } */
</style>