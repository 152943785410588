const unionTerritoryStates = require('./config.js').configs.unionTerritoryStates;
const configs = require('./config.js').configs;
const defaultData = require('./default_data').data;
import axios from 'axios';

export const globalMixin = {
    data(){
        return{
            CurrentBusiness:null,
            Businesses:{ "BusinessList": null, "BusinessCount":0},
            TransactionSettings:{"businessId":null, "branchDivision":false, "beatRouteArea":false, "mrpWiseStock":false, "disableNegativeStock":false, "gstType":1},
            minDateSelection:"2022-04-01",
            // maxDateSelection:"2024-03-31",
            maxDateSelection:"2025-03-31",
            dashboard:{
                periodValue:"This Month",
                startDate:this.fn_getLocalDateFormat(),
                endDate:this.fn_getLocalDateFormat(), 
                defaultDateRange:'month'
            },
            dataObj:{
                reportTitle:"Ledger",
                itemName:null,
                itemId:null,
                itemObj:null,
                itemLabel:null,
                startDate:new Date(), 
                endDate:new Date(), 
                reportData:null,
                columns:null,
                footerColumns:null,
                detailReportId:null,
                openView:false,
                reportType:null,
                tableView:true,
                reportPreferences:null,
                selectedAccountGroupTypeId:null,
                selectedPeriod:null,
                disableClick:false,
                hideFilterBtn:false
            },
            reportFilterObj:{},
            reportFilters:null,
            offline:false,
            dialog_reconnect:false,
            states:null,

            //for backup data
            backupInProgress:false,
            uploadStatus:null,

            reconnectObj:{
                isConnecting:false,
                isDisconnected:false,
                isReconnecting:false,
                isDialogOpen:false
                // isTimeOut:false
            },

            connectionNumber:null,

            dialog_customer_support:false,

            hasClientBusinesses:false,
            snackbar:false,
            snackbarMessage:null,
            snackbarIsVertical:false,
            snackbarTimeout:null,
            // baseApiUrl:process.env.NODE_ENV === 'production' ? "https://bizopsappapi.azurewebsites.net" : process.env.NODE_ENV === 'staging' ? "https://uatbizopsappapi.azurewebsites.net" : "https://devbizopsappapi.azurewebsites.net",
            baseApiUrl:process.env.VUE_APP_BASE_API_URL,
            viaKeyboardShortCut:false,
            isOfferCreated:false,
            mainBusinessID:null,
            appVersion:null,
            uniqueIds:[],
            products:[],
            ledgers:[],
            dialogLoader:false,
            infoDrawer:false
        }
    },

    watch: {
        // whenever uniqueIds changes, this function will run
        uniqueIds() {
          if(this.uniqueIds.length<50){
            this.fn_generateUniqueIds();
          }
        }
    },

    methods:{

        // fn_deletedb: function(){
        //     let dataTransactionRef = require('./data_transaction.js').dataTransaction;
        //     dataTransactionRef.fn_deleteDatabase(this);
        // },

        // for quantity 8 digits and 3 decimals
        fn_boundDecimalLength: function(obj, key, item, inputfn){
            // this.$nextTick(() => {
                let decimalIndex = obj[key].indexOf('.');
                let intVal = null;
                if(decimalIndex != -1){
                    intVal = obj[key].substr(0, obj[key].indexOf('.'));
                }
                else{
                    intVal = obj[key];
                }
                let intValue = intVal.length > 8 ? intVal.slice(0, 8) : intVal;
                if(obj[key].indexOf('.') != -1){
                    let decVal = obj[key].substr(obj[key].indexOf('.')+1);
                    let decimalValue = decVal.slice(0, 3);
                    obj[key] = intValue ? intValue + '.' + decimalValue : '.' + decimalValue;
                }
                else{
                    obj[key] = intValue;
                }
                inputfn ? inputfn(item) : '';
            // })
        },

        // only for 2 decimals
        fn_boundDecimalLengthUpto2: function(obj, key){
            this.$nextTick(() => {
                let decimalIndex = obj[key].indexOf('.');
                if(decimalIndex != -1){
                    let intVal = obj[key].split('.')[0];
                    let decVal = obj[key].split('.')[1];
                    obj[key] = intVal + '.' + decVal.slice(0, 2);
                }
            })
        },

        fn_generateUniqueIds: function(callback){
            let counter = 500;
            let uniqueIds = [];
            for(let i=0; i<counter; i++){
                uniqueIds.push(this.guidWeb());
            }

            // this.uniqueIds = uniqueIds;
            this.uniqueIds = this.uniqueIds.concat(uniqueIds);

            let filterObject = {};
            filterObject.uniqueIds = uniqueIds;
            let self = this;
            this.getFilterData('ValidateUniqueIds', this.CurrentBusiness.BusinessID, filterObject, null, {
                onSuccess: function(response){
                    // console.log(response);
                    let duplicates = JSON.parse(response);
                    if(duplicates && duplicates.length){
                        for(let i=0; i<duplicates.length; i++){
                            let itemIndex = self.uniqueIds.findIndex(id=>id == duplicates[i].uniqueId);
                            itemIndex != -1 ? self.uniqueIds.splice(itemIndex, 1) : '';
                        }
                    }

                    callback ? callback() : '';
                }, 
                onError: function(error){
                    console.log(error);
                    callback ? callback('error') : '';
                }
            });
        },

        fn_dialogReconnect: function(val){
            // this.reconnectObj.isTimeOut = false;
            this.connectionNumber = null;
            this.isOfferCreated = false;

            // if(type == 'show_reconnect_dialog'){
            //     this.dialog_reconnect = val;
            // }

            this.reconnectObj.isConnecting = false;
            this.reconnectObj.isDisconnected = false;

            this.reconnectObj.isDialogOpen = val;
            
            this.dialog_reconnect = val;

            // reset reconncect dialog if offer not created within 15 seconds
            // setTimeout(() => {
            //     if(val && !this.isOfferCreated && this.$root.reconnectObj.isConnecting){
            //         this.reconnectObj.isConnecting = false;
            //         this.reconnectObj.isDisconnected = false;
            //         this.$eventHub.$emit('snackbarAlert', {msg:'Connection timeout! Please try again'});
            //         this.fn_closeConnection(false, 'prevent_logout', null, true);
            //     }
            // }, 1 * 15 * 1000);
            
        },

        fn_states: function(val){
          let filterObject = {};
          filterObject.search = val ? val.toLowerCase() : '';
          
          let self = this;
          this.getDialogItems("StateDialog", filterObject, 
            {
              onSuccess: function (response) {
                console.log(response);
                self.states = JSON.parse(response);
              },
              onError: function (error) {
                console.log(error);
              }
            }
          )
        },

        fn_getActionPaneWidth: function(){
            if(document.getElementsByClassName("action_pane") && document.getElementsByClassName("action_pane").length){
                return document.getElementsByClassName("action_pane")[0].offsetWidth;
            }
            else{
                return 'auto';
            }
        },

        fn_getLayoutWidth: function(){
            if(document.getElementsByClassName("right_layout") && document.getElementsByClassName("right_layout").length){
                return document.getElementsByClassName("right_layout")[0].offsetWidth;    
            }
            else{
                return 'auto';
            }
        },

        fn_getBusiness: function(path, businessStatusOld, callback){
            let self = this;
            // let businessDetails = false;

            // setTimeout(() => {
            //     if(!businessDetails){
            //         this.fn_getBusiness(path, businessStatusOld, callback);
            //     }
            // }, 1000 * 1 * 10);

            // let notRespondTimeout = setTimeout(() => {
            //     if(!businessDetails){
            //         // alert("Trying to reconnect as server device is not responding!");
            //         clearTimeout(notRespondTimeout);
            //         // alert('in');
            //         this.$eventHub.$emit('snackbarAlert', {msg:'Unable to connect as business mobile device is not responding!'});
            //         // this.fn_dialogReconnect(true);
            //         this.fn_closeConnection(true, null, 1);
            //     }
            // }, 1000 * 10 * 10);

            this.getData('AllBusiness', null, {
                onSuccess: function(response){
                    console.log(response);
                    
                    let data  = JSON.parse(response);

                    // businessDetails = true;

                    if(data.activeConnections > 2){
                        self.$eventHub.$emit('snackbarAlert', {msg:'This business already have reached to its maximum connections!'});
                        self.fn_closeConnection(false, 'prevent_logout', 1, true);
                        if(self.CurrentBusiness){
                            self.fn_dialogReconnect(true);
                        }
                        return;
                    }

                    if(data){
                        self.CurrentBusiness = data.currentBusiness;
                        self.Businesses.BusinessList = data.businessList;
                        self.Businesses.BusinessCount = data.businessCount;
                        // data.openingDate ? self.fn_assignMinDateSelection(data.openingDate) : '';
                        self.fn_setMinTransactionDate();

                        if(businessStatusOld && businessStatusOld != self.CurrentBusiness.GSTRegistrationStatus){
                            self.fn_updateTtf();
                        }

                        self.fn_getTransactionSettings();

                        
                        if(path=='actions'){
                            callback ? callback() : self.$router.replace('/actions');
                        }
                        else if(path=='goback'){
                          self.$router.go(-1);
                        }
                        else{
                            callback ? callback(data.activeConnections) : '';
                        }
                        
                    }
                    else{
                        console.log('Unable to load business');
                        // self.showToast('Something went wrong. Try again');
                    }
                },
                onError: function(error){
                    console.log(error);
                }
            });
        },

        // fn_getCheckPlayStoreUpdate : function(reloadApp, callback){
        fn_getCheckPlayStoreUpdate : function(callback){
            let self = this;
            this.getData('CheckPlayStoreUpdate', null, 
                {
                    onSuccess: function(response){
                        console.log(response);

                        // getting app version saved in android local cache
                        // self.$root.fn_getLocalData('app_version', response=>{
                        //     self.appVersion = response;
                        // });

                        // getting android version directly from android function
                        self.fn_getAndroidAppVer();

                        if(callback){
                            callback(response);
                        }
                        else{
                            if(JSON.parse(response)){
                                self.$eventHub.$emit('UpdateMobileApp', {onload:1});
                            }
                        }
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_getUserType : function(){
            this.getData('UserType', null, 
                {
                  onSuccess: function(response){
                      console.log(response);
                  }, 
                  onError: function(error){
                      console.log(error);
                  }
                }
            );
        },

        fn_updateTtf: function(callback){
            let postObj = {};
            postObj.transactionTypeId = ["10003"];
            postObj.transactionTypeForms = [];
            if(this.CurrentBusiness.GSTRegistrationStatus==1){
                // postObj.transactionTypeForms = [];
                postObj.title = 'Tax Invoice';

                // postObj.fieldId = "5b9dcbb4-697f-4115-ac86-79c3cb064617";
                postObj.fieldId = configs.statusUpdate.field.subtitle.id;
                postObj.additionalData = JSON.stringify({"content":null});

                // postObj.transactionType = {"id":configs.statusUpdate.transactionTypeForm.expense.id, "name":"ITC Not Eligible", "title":"ITC Not Eligible"};
                for(let i=0; i<defaultData.defaultDataJson.transactionsType.length; i++){
                    let obj = {};
                    obj.transactionTypeFormId = defaultData.defaultDataJson.transactionsType[i].transactionTypeFormId;
                    obj.taxMode = defaultData.defaultDataJson.transactionsType[i].taxMode;

                    if(defaultData.defaultDataJson.transactionsType[i].transactionTypeFormId == configs.statusUpdate.transactionTypeForm.expense.id){
                        // obj.name = "ITC Not Eligible";
                        // obj.title = "ITC Not Eligible";
                        obj.name = "Expense";
                        obj.title = "Expense";
                    }
                    postObj.transactionTypeForms.push(obj);
                }

                this.fn_updateTtfRegister(callback, postObj)
            }
            else if(this.CurrentBusiness.GSTRegistrationStatus==2){
                postObj.title = 'Bill of Supply';
                postObj.taxMode = 0;

                // postObj.fieldId = "5b9dcbb4-697f-4115-ac86-79c3cb064617";
                postObj.fieldId = configs.statusUpdate.field.subtitle.id;
                postObj.additionalData = JSON.stringify({"content":"Composition Taxable Person"});

                // postObj.transactionType = {"id":configs.statusUpdate.transactionTypeForm.expense.id, "name":"Expense", "title":"Expense"};
                let obj = {};
                obj.transactionTypeFormId = configs.statusUpdate.transactionTypeForm.expense.id;
                obj.name = "Expense";
                obj.title = "Expense";
                postObj.transactionTypeForms.push(obj);

                this.fn_updateTtfOther(callback, postObj);
            }
            else if(this.CurrentBusiness.GSTRegistrationStatus==3){
                postObj.title = 'Invoice';
                postObj.taxMode = 0;

                // postObj.fieldId = "5b9dcbb4-697f-4115-ac86-79c3cb064617";
                postObj.fieldId = configs.statusUpdate.field.subtitle.id;
                postObj.additionalData = JSON.stringify({"content":null});

                // postObj.transactionType = {"id":configs.statusUpdate.transactionTypeForm.expense.id, "name":"Expense", "title":"Expense"};
                let obj = {};
                obj.transactionTypeFormId = configs.statusUpdate.transactionTypeForm.expense.id;
                obj.name = "Expense";
                obj.title = "Expense";
                postObj.transactionTypeForms.push(obj);

                this.fn_updateTtfOther(callback, postObj);
            }
        },

        fn_updateTtfRegister: function(callback, postObj){
            console.log(JSON.stringify(postObj));
            this.postFilterData('UpdateTtfToRegister', null, postObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        callback ? callback() : '';
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_updateTtfOther: function(callback, postObj){
            this.postFilterData('UpdateTtfToOther', null, postObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        callback ? callback() : '';
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_setMinTransactionDate: function(){
            let year = this.$moment(this.CurrentBusiness.OpeningDate).format('YYYY');
            let month = this.$moment(this.CurrentBusiness.OpeningDate).format('MM');
            let day = this.$moment(this.CurrentBusiness.OpeningDate).format('DD');

            this.minDateSelection = year+"-"+month+"-"+day;
        },

        fn_setUploadStatus: function(status){
            this.uploadStatus = status;
        },

        fn_getGstType: function(partyStateId, partyGstType, importExport){
            if(this.CurrentBusiness.StateID){
            
                let ut = false;

                for(let i=0; i<unionTerritoryStates.length; i++){
                    if((unionTerritoryStates[i].id == this.CurrentBusiness.StateID) && (partyStateId == this.CurrentBusiness.StateID)){
                        ut = true;
                        break;
                    }
                }

                if(ut && !importExport)
                    return "UnionTerritory"

                else if(partyStateId == this.CurrentBusiness.StateID && (partyGstType!=configs.accountGstType.overseas.id && partyGstType!=configs.accountGstType.sez.id))
                    return "IntraState"

                else
                    return "InterState"
            }
              
            return null;
        },

        fn_getTransactionSettings: function(callback){
            let self = this;
            this.getData('TransactionSettings', this.CurrentBusiness.BusinessID, 
              {
                onSuccess: function(response){
                    console.log(response);
                    response!='null' ? self.TransactionSettings = JSON.parse(response) : '';
                    !self.TransactionSettings.businessId ? self.TransactionSettings.businessId = self.CurrentBusiness.BusinessID : '';

                    if(self.TransactionSettings && self.TransactionSettings.additionalData){
                        self.TransactionSettings = JSON.parse(self.TransactionSettings.additionalData);
                    }

                    // patch for type wise number series
                    let data = JSON.parse(response);
                    if(data.typeWiseNumberSeries != undefined){
                        self.TransactionSettings.typeWiseNumberSeries = data.typeWiseNumberSeries;
                    }
                
                    callback ? callback() : '';
                    self.fn_getBusinessLogo();
                },
                onError: function(error){
                    console.log(error);
                }
              }
            );
        },

        fn_getBusinessLogo: function(){
            let self = this;
            this.getData('BusinessLogo', this.CurrentBusiness.BusinessID, 
              {
                onSuccess: function(response){
                    console.log(response);
                    self.CurrentBusiness.businessLogo = response;
                },
                onError: function(error){
                    console.log(error);
                }
              }
            );
        },

        fn_getCurrentDateTimeString: function(){
            let isoDateString = new Date().toISOString();
            let date = isoDateString.substring(0, isoDateString.indexOf('T')).replace(/-/g,'');
            let localTimeString = new Date().toLocaleTimeString().replace(/:/g,'');
            let time = localTimeString.substring(0, localTimeString.indexOf(' '));
            let milliSeconds = new Date().getMilliseconds();
            return date + time + milliSeconds;
        },

        fn_getLocalDateFormat: function(dateObj){
            let now = dateObj ? new Date(dateObj) : new Date();
            let year = now.getFullYear();
            let month = ("0" + (now.getMonth()+1)).slice(-2);
            let date = ("0" + now.getDate()).slice(-2);
            let time = now.toTimeString().substring(0, now.toTimeString().indexOf(' '));
            return year + '-' + month + '-' + date + 'T' + time + '.' + now.getMilliseconds();
        },

        numberToLocale: function(value, withoutDecimal){
            // return value ? value.toLocaleString("hi-IN") : 0;
            let indianFormatValue = value ? value.toLocaleString("hi-IN") : 0;
            if(indianFormatValue){
                if(indianFormatValue.split('.').length == 1){
                    return withoutDecimal ? indianFormatValue : indianFormatValue + '.00';
                }
                else{
                    if(indianFormatValue.split('.')[1].length == 1){
                       return indianFormatValue + "0";
                    }
                    else{
                       return indianFormatValue;
                    }
                }
            }
            else{
               return indianFormatValue;
            }
        },

        fixed2Decimal: function(value){
            return value ? value.toFixed(2) : 0;
        },

        fn_abbreviatedBusinessName: function(){
            // let words = this.CurrentBusiness.BusinessName.trim().split(' ');
            // trimming spaces from start and end
            let words = this.CurrentBusiness.BusinessName.trim();
            // trimming spaces from middle if any
            words = words.replace(/ +(?= )/g,'').split(' ');
            // words = words.replace(/ +(?= )/g,'');

            if(words.length > 1){
                return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
            }
            else{
                return words[0][0].toUpperCase();
            }
            
        },

        fn_payOrReceive: function(type, groupTypeId){
            if(groupTypeId==103){
                return type == 1 ? 'Positive' : 'Negative';
            }
            else{
                return type == 1 ? 'To Receive' : 'To Pay';
            }
        },

        isObject(object) {
          return object != null && typeof object === 'object';
        },

        fn_copyFormObject: function(formObj, formId){
            if(!localStorage.getItem(formId)){
                localStorage.setItem(formId, JSON.stringify(formObj));
            }
        },

        fn_getFormObject: function(formId){
            return localStorage.getItem(formId);
        },

        fn_deleteFormObject: function(formId){
            localStorage.removeItem(formId);
        },

        fn_getAuthToken: function(callback){
            if(!navigator.onLine){
                // alert('Internet not available');
                this.$eventHub.$emit('snackbarAlert', {msg:'Internet not available'});
                // this.snackbarAlert = true;
                // this.snackbarAlertMessage = "Internet not available";
                callback('error');
                return;
            }
            
            let self = this;
            this.getData('GetToken', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        callback(response);
                    }, 
                    onError: function(error){
                        console.log(error);
                        // alert("Something went wrong. Try again!.");
                        self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                        // this.snackbarAlert = true;
                        // this.snackbarAlertMessage = "Something went wrong. Try again";
                        callback('error');
                        // self.hideLoading();
                    }
                }
            );
        },

        fn_getCustomToken: function(){
            this.fn_getAuthToken(token=>{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                    }
                };
                // axios.get(this.baseApiUrl + '/api/business/'+this.CurrentBusiness.BusinessID+'/getbusinessusertoken', headersObj, {timeout:30000})
                axios.get(this.$root.baseApiUrl + '/api/user/getbusinesstoken?businessid='+this.CurrentBusiness.BusinessID, headersObj, {timeout:30000})
                .then(response => { 
                    console.log(JSON.stringify(response));
                    document.getElementById('iframe_customer_support').contentWindow.postMessage({
                        type:"token",
                        authToken:response.data.Token
                    }, '*');
                }).catch(e => {
                    console.log(e);
                })
            });
        },

        fn_navigateToNew: function(routePath, routeQuery, formType){
            if(this.$route.path == routePath){
                this.fn_broadcastNavigation(routeQuery, formType);
            }
            else{
                this.$route.query.type != routeQuery.type ? localStorage.setItem('fromFormView', 1) : ''; 
                this.$router.replace({path:routePath, query:routeQuery}).catch(error => {
                    if (error.name === "NavigationDuplicated") {
                        this.fn_broadcastNavigation(routeQuery, formType);
                    }
                });
            }
        },

        fn_broadcastNavigation: function(routeQuery, formType){
            let broadCastFired = false;
            if(!broadCastFired){
                if(formType == 'transaction'){
                    let dataObj = {type:routeQuery.type};
                    this.$eventHub.$emit('newTransaction', dataObj);
                }
                else{
                    this.$eventHub.$emit(routeQuery.type);
                }
            }
            setTimeout(() => {broadCastFired=false}, 500);
        },

        fn_sendBusinessLogoToSupport: function(){
            document.getElementById('iframe_customer_support').contentWindow.postMessage({
                type:"business_logo",
                businessLogo:this.CurrentBusiness.businessLogo
            }, '*');
        },

        fn_getConnectionMsg: function(){
            if(localStorage.getItem('login_type') && localStorage.getItem('login_type') == 'client'){
                return 'Ask client to go "More > Connection Requests" in mobile app, search for '+ this.connectionNumber +' and press connect'
            }
            else{
                return 'Go to "More > Connection Requests" in mobile app, search for '+ this.connectionNumber +' and press connect'
            }
        },

        // getting ice servers from twilio
        fn_getTwilioIceServers: function(callback){

            if(document.cookie){
                let ser = document.cookie;
                // if(ser.substring(0, ser.indexOf('=')) == 'iceservers'){
                if(ser.indexOf('iceservers') != -1){
                    try{
                        let iceServers = JSON.parse(ser.substring(ser.indexOf('['), ser.indexOf(']') + 1));
                        return callback(iceServers);
                    }
                    catch(e){
                        console.log(e);
                    }
                }
            }

            this.getFirebaseToken(token=>{
                if(token=="error"){
                    this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong!'});
                    callback('error');
                    return;
                }

                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                    }
                };

                axios.get(this.baseApiUrl + '/api/desktop/webrtc/gettoken', headersObj, {timeout:30000})
                .then(response => {
                    console.log(JSON.stringify(response.data));

                    // let iceServers = response.data.ice_servers.filter(item=>{
                    //     // return item.url.indexOf('stun:stun') === -1 && item.urls.indexOf('stun:stun') === -1;
                    //     return item.userName;
                    // })

                    // console.log(JSON.stringify(iceServers));
                    
                    // let ed = new Date(response.data.ExpiryDate);
                    let dateTimeNow = new Date();
                    let ed = new Date(dateTimeNow.setHours(dateTimeNow.getHours()+12, dateTimeNow.getMinutes(), 0, 0));
                    var expires = "expires=" + ed.toGMTString();
                    document.cookie = "iceservers=" + JSON.stringify(response.data.ice_servers) + ";" + expires + ";path=/";

                    callback(response.data.ice_servers);
                }).catch(e => {
                    callback('error');
                    this.reconnectObj.isConnecting = false;
                    this.reconnectObj.isReconnecting = false;
                    console.log(e);
                })
            })
        },

        fn_getMinSelectionDate: function(){
            let currentDate = new Date();
            let currentMonth = currentDate.getMonth() + 1;

            let minDate = new Date(this.minDateSelection);

            if(currentDate > minDate){
              if(currentDate.getFullYear() >= minDate.getFullYear() && currentMonth>3){
                return new Date(currentDate.getFullYear()+"/04/01");
              }
              else{
                return new Date(this.minDateSelection);
              }
            }
            else{
              return new Date(this.minDateSelection);
            }
        },

        fn_getMasterItems: function(type, listNames, isGetAll, showLoader){

            if(this.appVersion < 117){
                return;
            }
            
            let filterObject = {};
            filterObject.listName = listNames;
            filterObject.counter = 0;

            showLoader ? this.dialogLoader = true : '';
            let dataTransactionRef = require('./data_transaction.js').dataTransaction;

            if(type == 'products'){

                // if(localStorage.getItem(this.CurrentBusiness.BusinessID+'_ls_item_counter')){
                //     let localObj = JSON.parse(localStorage.getItem(this.CurrentBusiness.BusinessID+'_ls_item_counter'));
                //     filterObject.counter = localObj.counter ? localObj.counter : 0;
                // }

                if(!this.products.length){
                    dataTransactionRef.fn_getMasterItemsFromDB(this, type, listNames, isGetAll, filterObject);
                }
                else{
                    dataTransactionRef.fn_getMasterData(this, type, listNames, isGetAll, filterObject);
                }
            }

            if(type == 'ledgers'){

                // if(localStorage.getItem(this.CurrentBusiness.BusinessID+'_ls_ledger_counter')){
                //     let localObj = JSON.parse(localStorage.getItem(this.CurrentBusiness.BusinessID+'_ls_ledger_counter'));
                //     filterObject.counter = localObj.counter ? localObj.counter : 0;
                // }

                if(!this.ledgers.length){
                    dataTransactionRef.fn_getMasterItemsFromDB(this, type, listNames, isGetAll, filterObject);
                }
                else{
                    dataTransactionRef.fn_getMasterData(this, type, listNames, isGetAll, filterObject);
                }
            }
        },

        fn_saveLocalData: function(dataObj){
            this.postData("LocalData", dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_getLocalData: function(key, callback){
            this.getData("LocalData", key, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        callback ? callback(response) : '';
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        // saving to table
        fn_saveLocalItem: function(dataObj, callback){
            this.postData("LocalItem", dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        callback ? callback() : '';
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        // getting from table
        fn_getLocalItem: function(key, callback){
            this.getData("LocalItem", key, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        callback ? callback(response) : '';
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_postMetrics: function(postObj){
            postObj.eventName = postObj.eventName.replaceAll(" ", "_");
            this.postData("FirebaseAnalyticEvent", postObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_getAndroidAppVer: function(){
            let self = this;
            this.getData('AndroidAppVersion', null, 
            {
                  onSuccess: function(response){
                    console.log(response);
                    self.appVersion = Number(response);
                  }, 
                  onError: function(error){
                    console.log(error);
                  }
            });
        },

        customItemFilter(item, queryText) {
            return (
              item.itemName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
                -1 ||
                item.productCode && item.productCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },

        fn_initConnectionRequest: function(){
            if(!navigator.onLine){
                this.$eventHub.$emit('snackbarAlert', {msg:"Internet not available"});
                return;
            }
    
            this.getUser(userId=>{
    
                if(userId){
                    this.reconnectObj.isConnecting = true;
                    this.reconnectObj.isReconnecting = true;
                    let businessData = JSON.parse(JSON.stringify(this.CurrentBusiness));
                    businessData.IsTemporary = this.CurrentBusiness.temporaryBusiness ? true : false;
                    if(this.CurrentBusiness.temporaryBusiness){
                        businessData.BusinessID = this.mainBusinessID;
                        businessData.TempBusinessID = this.CurrentBusiness.BusinessID;
                    }
                    else{
                        businessData.TempBusinessID = null;
                    }
    
                    businessData.type = localStorage.getItem('login_type');
                    // businessData.TempBusinessID = this.$root.CurrentBusiness.temporaryBusiness ? this.$root.CurrentBusiness.BusinessID : null;
                    this.fn_getTwilioIceServers(response => {
                        if(response == 'error'){
                            this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Please try again'});
                            // this.logout();
                            return;
                        }
    
                        this.fn_checkDeviceStatus(businessData, null, null, userId, null, this.reconnectObj, null, response);
                    });
                    
                }
                else{
                    this.$eventHub.$emit('snackbarAlert', {msg:'You have been logged out!'});
                    this.fn_navigate('/login');
                }
            })
        },
    },
    mounted: function(){

        this.$eventHub.$on('snackbarAlert', obj=>{
            this.$root.snackbarMessage = obj.msg;
            this.$root.snackbarIsVertical = obj.isVertical ? true : false;
            this.$root.snackbarTimeout = obj.timeout ? obj.timeout : 5000;
            this.$root.snackbar = true;
        });

        this.$eventHub.$on('hideSnackbarAlert', ()=>{
            this.$root.snackbar = false;
        });

        this.$eventHub.$on('offercreated', ()=>{
            this.isOfferCreated = true;
        });

        this.$eventHub.$on('requestFailed', ()=>{
            this.infoDrawer = true;
        });

        let isCalled = false;
        this.$eventHub.$on('GetMasterItems', obj=>{
            if(!isCalled){
                isCalled = true;
                let type = null;
                let listNames = null;
                if(obj.type == 'Product'){
                    type = 'products';
                    listNames = ["Product", "deleteProductWithOpening"];
                }
                else if(obj.type == 'Ledger'){
                    type = 'ledgers';
                    listNames = ["Ledger", "deleteAccountWithOpening"];
                }
                
                this.fn_getMasterItems(type, listNames);

                setTimeout(() => {
                    isCalled = false;
                }, 1000);
            }

        });

        window.addEventListener("offline", (event) => {
            console.log(event);
            this.offline = true;
            // alert('offline');
        });

        window.addEventListener("online", (event) => {
            console.log(event);
            this.offline = false;
            // alert('online');
        });

        window.addEventListener("message", (event) => {
            console.log(event);
            if(event.data.type == "getToken"){
                this.fn_getCustomToken();
            }

            if(event.data.type == "closeSupportApp"){
                this.dialog_customer_support = false
            }

            if(event.data.type == "getBusinessLogo"){
                this.fn_sendBusinessLogoToSupport();
            }
            // alert('online');
        });

        document.addEventListener("contextmenu", function (e){
            e.preventDefault();
        }, false);

        let eventFired = false;

        window.addEventListener('keydown', (event) => {
            console.log(event);
            
            setTimeout(() => {
                eventFired = false;
                this.viaKeyboardShortCut = false;
            }, 1000);

            if (event.ctrlKey && event.code === 'KeyR') {
                event.preventDefault();
            }

            if(!eventFired && this.$root.CurrentBusiness){
                // Display view actions
                if (event.ctrlKey && event.code === 'Delete') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    this.$eventHub.$emit('delete');
                }
                else if (event.ctrlKey && event.code === 'KeyE') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    this.$eventHub.$emit('edit');
                }
                else if (event.ctrlKey && event.code === 'KeyX') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    this.$eventHub.$emit('cancel');
                }
                // Form actions
                else if (event.ctrlKey && event.code === 'KeyS') {
                    // to prevent that discard dialog should not appear when saving form
                    this.viaKeyboardShortCut = false;
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    this.$eventHub.$emit('save');
                }
                else if(event.shiftKey && event.code === 'KeyL' && document.activeElement.type !== 'textarea' && document.activeElement.type !== 'text'){
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    this.$eventHub.$emit('select_party');
                }
                else if(event.shiftKey && event.code === 'KeyM' && document.activeElement.type !== 'textarea' && document.activeElement.type !== 'text'){
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    this.$eventHub.$emit('select_item');
                }
                // invoice actions
                else if (event.ctrlKey && event.code === 'KeyD') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    this.$eventHub.$emit('download');
                }
                // else if (event.ctrlKey && event.code === 'KeyP') {
                //     event.preventDefault();
                //     eventFired = true;
                //     this.viaKeyboardShortCut = true;
                //     this.$eventHub.$emit('print');
                // }
                else if (event.key == 'Escape' && event.code === 'Escape') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    this.$eventHub.$emit('escape');
                }
                // Navigations
                else if (event.altKey && event.code === 'KeyS') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/transactions";
                    let query = {type:'saleinvoice'}
                    this.fn_navigateToNew(path, query, 'transaction');
                }
                else if (event.altKey && event.code === 'KeyR') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/transactions";
                    let query = {type:'salereturn'}
                    this.fn_navigateToNew(path, query, 'transaction');
                }
                else if (event.altKey && event.code === 'KeyQ') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/transactions";
                    let query = {type:'estimate'}
                    this.fn_navigateToNew(path, query, 'transaction');
                }
                else if (event.altKey && event.code === 'KeyP') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/transactions";
                    let query = {type:'purchaseinvoice'}
                    this.fn_navigateToNew(path, query, 'transaction');
                }
                else if (event.altKey && event.code === 'KeyD') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/transactions";
                    let query = {type:'purchasereturn'}
                    this.fn_navigateToNew(path, query, 'transaction');
                }
                else if (event.altKey && event.code === 'KeyE') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/transactions";
                    let query = {type:'expense'}
                    this.fn_navigateToNew(path, query, 'transaction');
                }
                else if (event.altKey && event.code === 'KeyI') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/transactions";
                    let query = {type:'receipt'}
                    this.fn_navigateToNew(path, query, 'transaction');
                }
                else if (event.altKey && event.code === 'KeyO') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/transactions";
                    let query = {type:'payment'}
                    this.fn_navigateToNew(path, query, 'transaction');
                }
                else if (event.altKey && event.code === 'KeyL') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/parties";
                    let query = {type:'party'}
                    this.fn_navigateToNew(path, query, 'master');
                }
                else if (event.altKey && event.code === 'KeyM') {
                    event.preventDefault();
                    eventFired = true;
                    this.viaKeyboardShortCut = true;
                    let path = "/"+this.$route.params.businessId+"/items";
                    let query = {type:'item'}
                    this.fn_navigateToNew(path, query, 'master');
                }
            }
            
        });


    }
	
}