<template>
  <div full-height grey-back class="display_wrapper">

    <!-- <v-toolbar :height="56" flat class="noprint">
        <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn>
        <v-toolbar-title>{{toolbarTitle}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fn_checkValidations"><v-icon left>save</v-icon> Save</v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-list class="pa-0">
        <v-list-item style="height:36px">
            <v-list-item-content cursor-pointer @click="fn_openDialogNumberSeries">
              <v-list-item-title class="text-underline">{{Form.transactionData.mergeTransactionNumber}}</v-list-item-title>
            </v-list-item-content>

            <span v-if="Form.transactionData.transactionDate">{{Form.transactionData.transactionDate | moment("DD-MMM-YYYY")}}</span> 

            <v-list-item-action class="ml-0">
                <v-btn icon @click="fn_openDatePicker('transactionDate', Form.transactionData.transactionDate)"><v-icon>date_range</v-icon></v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-list> -->

    

    <dialog-loading ref="ref_dialogLoading" message="Saving Details..."></dialog-loading>
    <dialog-number-series ref="ref_dialogNumberSeries" :checkTransactionNumber="fn_checkTransactionNumber"></dialog-number-series>
    <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>
    <dialog-payment-options ref="ref_dialogPaymentOptions" :savePaymentOptions="fn_saveAccountPaymentOptions"></dialog-payment-options>
    <dialog-confirmation ref="ref_dialogConfirmation" :header="confirmationHeader" :confirmationMessage="confirmationMessage" :actionMethod="confirmationAction" okBtnText="Yes" cancelBtnText="No" :displayView="true" :params="confirmationActionParam"></dialog-confirmation>

    <!-- <v-snackbar v-model="transactionNumberSnackbar" vertical :timeout="0">
      Generate new transaction number?
      <v-btn text icon @click="fn_bindTransactionNumber" color="primary">okay</v-btn>
      <v-btn text icon @click="transactionNumberSnackbar=false">close</v-btn>
    </v-snackbar> -->

    <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>
    <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>
  </div>
</template>

<script>
// const dialogjs =  require('../../../masters/forms/dialog').dialogjs;

// const menuItem = require('../../../../config.js').configs.menuItem;
const transactionForms = require('../../../../config.js').configs.transactionForms;
const transactionTypes = require('../../../../config.js').configs.transactionTypes;
const ledgerGroupTypeId = require('../../../../config.js').configs.ledgerGroupTypeId;
const paymentAccountsAndLedger = require('../../../../config.js').configs.paymentAccountsAndLedger;
// const accountGroupIds = require('../../../../config.js').configs.accountGroupIds;

// import dialogParticular from './dialogs/dialog_add_particular'
// import dialogAccountBookDatePicker from './dialogs/dialog_accountbook_datepicker'
// import dialogAgainstReference from './dialogs/dialog_against_reference'
// import dialogBankTransactionTypfes from './dialogs/dialog_bank_trans_types'
// import dialogNote from '../dialogs/dialog_note'
import dialogNumberSeries from '../dialogs/dialog_number_series_manual'
// import dialogTransactionTypeList from '../dialogs/dialog_transaction_type_list' //coming from outer dialogs folder
import dialogConfirmation from '../../../dialogs/dialog_confirmation'

// import transItems from './dialogs/trans_items'

import dialogForm from './dialogs/dialog_form'

const transactionData = require('../../datamodel').data.transactionData;

// import dialogDiscard from '../../../dialogs/dialog_discard'
import {draftMixin} from '../../../draft_mixin'
import {transactionMixin} from '../../transaction_mixin'
// import dialogDraftItems from '../../../dialogs/dialog_draft_items'

import dialogPaymentOptions from './dialogs/dialog_payment_options'

// import dialogDriveAlert from '../dialogs/dialog_drive_alert'

import dialogDatePicker from '../../../dialogs/dialog_datepicker'

import dialogDiscard from '../../../dialogs/dialog_discard'

import dialogLoading from '../../../dialogs/dialog_loading'

// import { required } from 'vee-validate/dist/rules'
// import { extend, ValidationProvider, setInteractionMode } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })

  export default{
    props:['settings'],
    mixins: [draftMixin, transactionMixin],
    // mixins: [transactionMixin],
    data(){
      return{
        Form:{
            "transactionData":JSON.parse(JSON.stringify(transactionData)),

            "particularsData":{
              "groupId":null,
              "accountLedgerId":null, 
              "accountLedgerName":null, 
              "amount":null, 
              "amountType":null, 
              "currentBalance":0,
              "currentBalanceType":1,
              "groupTotal":null,
              "references":[],
              //other details
              "bankName": null,
              "transType":null,
              "refNumber":null,
              "refDate":null,
              "acRefDetails":null,
              "onAccount":null,
              "selectedLedger":null
              // "otherDetails":null
            },

            "filteredItems":[],
            "debitTotal":0,
            "creditTotal":0,
            "defaultView":false,
            "transactionTypeFormId":null,
            "transactionTypeFormName":null,
            "transactionTypeData":null,
            "references":null,
            "transactionTypeList":[],
            "draftItems":[],
            "paymentLedgerId":null,
            "paymentGroups":null,
            "splitPayment":false,
            "paymentOptions":[],
            "amount":null,
            "partyId":null,
            "partyName":null,
            // "partyBalance":null,
            "partyDetails":null,
            "paymentGroupIndex":null,
            "addReduceAmount":1,
            "isSuspenseAdjustment":false,

            "selectedParty":null
          },

        loading:false,
        tagId:null,
        draftStorageId:null,
        dialogStorageId:null,
        backButtonPress: false,
        snackbar:false,
        snackbar_msg:null,
        unwatch:false,
        // loadedFromDraft:false,

        toolbarTitle:null,
        selectedNatureValue:null,

        PropFunctions:{},
        PropData:{},
        componentKey:null,

        dateType:null,
        selectedDate:null,
        hasAgainstReference:false,
        transactionNumberSnackbar:false,
        disableBtn:false,
        newLedgerGroupTypeId:null,
        paymentAccounts:[],
        disableOnSave:false
      }
    },
    components:{
      'dialog-loading':dialogLoading,
      // 'dialog-particular': dialogParticular,
      // 'dialog-accountbook-datepicker': dialogAccountBookDatePicker,
      // 'dialog-against-reference': dialogAgainstReference,
      // 'dialog-note': dialogNote,
      // 'transaction-items': transItems,
      // 'bank-transaction-types': dialogBankTransactionTypes,
      // 'dialog-transaction-type-list': dialogTransactionTypeList,
      'dialog-discard': dialogDiscard,
      'dialog-number-series': dialogNumberSeries,
      // 'dialog-draft-items': dialogDraftItems,
      'dialog-payment-options':dialogPaymentOptions,
      'dialog-form': dialogForm,
      'dialog-datepicker':dialogDatePicker,
      'dialog-confirmation':dialogConfirmation
      // ValidationProvider
    },
    watch:{
        'Form': {
            handler: function (){
                 // this.fn_saveToLocalStorage(this);
                 if(!this.unwatch){
                    this.fn_saveDraft(this.draftStorageId, this.Form.partyName);
                 }
                 this.unwatch = false;
             },
             deep: true
        }
    },
    // watch:{
    //   'Form': {
    //             handler: function (newVal, oldVal){
    //                  // this.fn_saveToLocalStorage(this);
    //               if(!this.unwatch){
    //                 let name = 'no name';
    //               if(this.Form.filteredItems.length){
    //                 name = this.Form.filteredItems[0].accountLedgerName;
    //               }
    //               else{
    //                 name = this.Form.partyName;
    //               }

    //                   this.fn_saveDraft(name);
    //                  }
    //                  this.unwatch = false;
    //              },
    //              deep: true
    //         }
    // },
    methods:{

      fn_autoFillJournalAmount: function(item){
        if(!item.amount){
          if(item.amountType==1){
            this.creditTotal > this.debitTotal ? item.amount =  this.roundUpto2Decimals(this.creditTotal - this.debitTotal) : '';
          }
          else{
            this.debitTotal > this.creditTotal ? item.amount =  this.roundUpto2Decimals(this.debitTotal - this.creditTotal) : '';
          }  
        }
      },

      fn_removeItem: function(groupId){
        let itemIndex = this.Form.filteredItems.findIndex(item=>{
          return item.groupId == groupId;
        });
        this.Form.filteredItems.splice(itemIndex, 1);
      },

      fn_addParticular: function(amountType){

        let dataObj = JSON.parse(JSON.stringify(this.Form.particularsData));
        dataObj.amountType = amountType;
        dataObj.groupId = this.guidWeb();
        this.Form.filteredItems.push(dataObj);
      },

      // fn_openDialogForm: function(){
      //   this.$refs['ref_dialogForm'].openDialogWeb();
      // },

        fn_closeDialogForm: function(){
            this.askToDiscard = true;
            if(this.fn_showDialogDiscard()){
                this.$refs['ref_dialogDiscard'].openDialogWeb();
            }
            else{
                this.fn_goBack();
            }
        },

      fn_isSuspense: function(val){
        this.Form.paymentLedgerId = val ? paymentAccountsAndLedger.ledgers.adjustmentAccount.id : null;
        this.Form.paymentOptions = [];
      },

      fn_openDialogNumberSeries: function(){
        // this.fn_openDialogWeb('web', 'NumberSeries', 'ref_dialogNumberSeries');
        this.$refs['ref_dialogNumberSeries'].openDialogWeb();
        this.transactionNumberSnackbar = false;
      },

      fn_openDialogPaymentOptions: function(){
        // if(this.settings.transactionTypeId==30004 && !this.Form.partyId){
        if(this.settings.transactionTypeId==30004 && !this.Form.selectedParty){
        //   alert('Select Party');
            this.$eventHub.$emit('snackbarAlert', {msg:'Select Party'});
            return; 
        }

        if(!this.Form.amount || this.Form.amount<=0){
        //   alert('Enter amount to select payment mode');
            this.$eventHub.$emit('snackbarAlert', {msg:'Enter amount to select payment mode'});
            return;
        }

        this.fn_openDialog_PaymentOptions('yes');
      },

      // fn_callCancelTransaction: function(){
      //  this.fn_closeDialog();
      //  this.fn_cancelTransaction(this.Form.transactionData.transactionId, this.Form.transactionData.transactionListId, 'form');
      // },

      fn_openDialogDiscard: function(){
        this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
      },

      fn_getTransactionTypeList: function (setTFT) {
            let filterObject = {};
            filterObject.type = this.settings.transactionTypeId;
            // filterObject.inventory = [0, 1];

            let self = this;
            this.getItems("TransactionTypeList", filterObject, 
              {
                onSuccess: function (response) {
                    console.log(response);

                    self.unwatch = true;
                    self.Form.transactionTypeList = JSON.parse(response);

                    // if(self.settings.transactionTypeId==30004){
                    //  self.Form.transactionTypeList = [{"isDefault":false,"name":"Contra","transactionTypeFormId":"819859b3-85d9-9a11-661d-5610f7d4d5f5"}];
                    // }

                    setTFT ? self.fn_setLastSelectedTFT() : '';

        //            if(!self.loadedFromDraft && self.isNew){

        //              if(self.Form.transactionTypeList.length && !self.Form.transactionTypeFormId){

          //              if(localStorage.getItem(self.settings.transactionTypeId)){
            //      let transactionTypeFormId = localStorage.getItem(self.settings.transactionTypeId);

          //                for(let i=0; i<self.Form.transactionTypeList.length; i++){
          //                  if(self.Form.transactionTypeList[i].transactionTypeFormId == transactionTypeFormId){
          //                    self.fn_setTransactionType(self.Form.transactionTypeList[i]);
          //                    break;
          //                  }
          //                }                   
          //              }
          //              // else{
          //              //  if(self.Form.transactionTypeList.length == 1){
          //              //    self.fn_setTransactionType(self.Form.transactionTypeList[0]);
          //              //  }
          //              // }

          //           }
          
            // }    
                  
                },
                onError: function (error) {
                  console.log(error);
                }
              }
            )
        },

        fn_setLastSelectedTFT: function(){
          if(localStorage.getItem(this.settings.transactionTypeId) && this.Form.transactionTypeList.length){
            let transactionTypeFormId = localStorage.getItem(this.settings.transactionTypeId);

                  for(let i=0; i<this.Form.transactionTypeList.length; i++){
                    if(this.Form.transactionTypeList[i].transactionTypeFormId == transactionTypeFormId){
                      this.fn_setTransactionType(this.Form.transactionTypeList[i], true);
                      break;
                    }
                  }
          }
          else{
            if(this.Form.transactionTypeList.length == 1){
              this.fn_setTransactionType(this.Form.transactionTypeList[0], true); 
            }
            else{
              // this.loading = false;
              // this.fn_openTransactionTypeList();
              for(let i=0;i<this.Form.transactionTypeList.length; i++){
                if(this.Form.transactionTypeList[i].isDefault){
                  this.fn_setTransactionType(this.Form.transactionTypeList[i], true);
                  break;
                }
              }
            }
                  // this.Form.transactionTypeList.length == 1 ? this.fn_setTransactionType(this.Form.transactionTypeList[0]) : '';
                }
        },

      fn_openDatePicker: function (type, date) {
        let self = this;
        this.dateType = type;
        this.selectedDate = date;
        setTimeout(function () {
          // self.fn_openDialogWeb('web', 'AccountBookDatePicker', 'ref_dialogDatePicker')
          self.$refs['ref_dialogDatePicker'].openDialogWeb();
        })
      },

      fn_updateDate: function (value) {
        if(this.dateType=="transactionDate"){
            this.fn_updateTransactionDate(value, this.isNew);
        }
        else if(this.dateType=="other"){
            this.Form.particularsData.refDate = this.$root.fn_getLocalDateFormat(value);
        }

        this.$refs['ref_dialogDatePicker'].closeDialogWeb();
        this.$refs['ref_dialogDatePicker'].disableBtn = false;
      },

      fn_openTransactionTypeList: function () {
        this.fn_openDialogWeb('web', 'TransactionTypeList', 'ref_dialogTransactionTypeList'); 
      },

      fn_setTransactionType: function (item, autoset) {
        let transactionTypeSelected = this.Form.transactionTypeFormId ?  true : false;
        if(this.Form.transactionTypeFormId != item.transactionTypeFormId){
          this.fn_getTransactionTypeData(item.transactionTypeFormId, null, true);
        }

        this.Form.transactionTypeFormId = item.transactionTypeFormId;
        this.Form.transactionTypeFormName = item.name;

        localStorage.setItem(this.settings.transactionTypeId, item.transactionTypeFormId);

        // this.errors.remove('transactionType');
        this.$forceUpdate();
        !autoset ? this.fn_closeDialog() : '';

        if(!transactionTypeSelected && this.Form.draftItems && this.Form.draftItems.length){
          this.loading = false;
          this.fn_openDialogDraftItems();
        }
      },

      //updateNS = update number series
      fn_getTransactionTypeData: function(itemId, callback, updateNS){
        // this.showLoading('Please wait...');
        var self = this;
        // let transactionDate = this.Form.transactionData.transactionDate ? this.Form.transactionData.transactionDate : new Date();
        let transactionDate = this.Form.transactionData.transactionDate ? this.Form.transactionData.transactionDate : this.$root.fn_getLocalDateFormat();
        let filterObject = {};
        filterObject.fiscalYear = JSON.stringify(this.fn_getFiscalYear(transactionDate));
        this.getFilterData('TransactionTypeForm', itemId, filterObject, null,
          {
            onSuccess: function(response){
                    console.log(response);
                    // self.fn_accountLedgers();
                    self.Form.transactionTypeData = JSON.parse(response);
                    
                    if(self.settings.transactionTypeId != transactionTypes.journal.id){
                      self.fn_getAccountPaymentGroups();
                    }
                    self.fn_bindTransactionTypeData(callback, updateNS);
                }, 
                onError: function(error){
                  console.log(error);
                }
          }
            );
      },

      fn_getAccountPaymentGroups: function () {
              let filterObject = {};
              if(this.settings.transactionTypeId == transactionTypes.receipt.id || this.settings.transactionTypeId == transactionTypes.payment.id || this.settings.transactionTypeId == transactionTypes.contra.id){
                filterObject.accountGroups = [paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.card, paymentAccountsAndLedger.groups.wallet];  
              }
              // else{
              //  if(this.settings.transactionTypeId == transactionTypes.creditNote.id){
              //    filterObject.accountGroups = [accountGroupIds.expenseGroupCreditNote.id];
              //  }
              //  else if(this.settings.transactionTypeId == transactionTypes.debitNote.id){
              //    filterObject.accountGroups = [accountGroupIds.incomeGroupDebitNote.id];
              //  }
              // }
              

              let self = this;
              this.getFilterData('PaymentGroups', null, filterObject, null,
                  {
                      onSuccess: function(response){
                          console.log(response);
                          // self.Form.paymentGroups = JSON.parse(response);

                          let groups = JSON.parse(response);

                          groups.forEach(function(group){
                              group.accountLedgers.forEach(function(ledger){
                                  !ledger.selected ? self.$set(ledger, 'selected', false) : '';
                                  !ledger.amount ? self.$set(ledger, 'amount', null) : '';
                                  !ledger.refNumber ? self.$set(ledger, 'refNumber', null) : '';
                                  // self.paymentAccounts.push(ledger);
                              });
                          });

                          if(!self.Form.paymentGroups){
                            self.Form.paymentGroups = JSON.parse(JSON.stringify(groups));
                          }
                          else{
                            // let newAccountLedger = null;
                            // let groupIndex = null;

                            let oldLedgers = self.Form.paymentGroups[self.Form.paymentGroupIndex].accountLedgers;
                            let newLedgers = groups[self.Form.paymentGroupIndex].accountLedgers;

                            var res = newLedgers.filter(item1 => !oldLedgers.some(item2 => (item2.accountLedgerId === item1.accountLedgerId)));

                            console.log(res);

                            if(self.Form.splitPayment){
                              res[0].selected = true;
                              self.$refs['ref_dialogPaymentOptions'].toggleAmount(res[0]);
                            }
                            else{
                              self.Form.paymentLedgerId = res[0].accountLedgerId;
                            }

                            self.Form.paymentGroups[self.Form.paymentGroupIndex].accountLedgers.push(res[0]);

                          }

                          // assigning payment ledger id incase of paymentLedgerId undefined or !split payment for both cases new/edit
                          if(!self.Form.paymentLedgerId && !self.Form.splitPayment && self.isNew){
                            if(self.settings.transactionTypeId == transactionTypes.receipt.id || self.settings.transactionTypeId == transactionTypes.payment.id){
                              self.Form.paymentLedgerId = paymentAccountsAndLedger.ledgers.cash;
                            }
                            // else if(self.settings.transactionTypeId == transactionTypes.creditNote.id){
                            //  self.Form.paymentLedgerId = paymentAccountsAndLedger.ledgers.rateDifferenceExpense;
                            // }
                            // else if(self.settings.transactionTypeId == transactionTypes.debitNote.id){
                            //  self.Form.paymentLedgerId = paymentAccountsAndLedger.ledgers.rateDifferenceIncome;
                            // }
                              
                              self.fn_saveAccountPaymentOptions(self.Form.paymentLedgerId);
                          }
                          

                      }, 
                      onError: function(error){
                        console.log(error);
                      }
                  }
              );
          },

          fn_saveAccountPaymentOptions: function(paymentLedgerId){

              this.Form.paymentOptions = [];
              let groups = JSON.parse(JSON.stringify(this.Form.paymentGroups));

              for(let i=0; i<groups.length; i++){
                  for(let j=0; j<groups[i].accountLedgers.length; j++){

                      if(this.Form.splitPayment){
                          if(groups[i].accountLedgers[j].amount){
                              let ledger = {};
                              ledger.accountLedgerId = groups[i].accountLedgers[j].accountLedgerId;
                              ledger.accountLedgerName = groups[i].accountLedgers[j].accountLedgerName;
                              ledger.amount = groups[i].accountLedgers[j].amount;
                              ledger.refNumber = groups[i].accountLedgers[j].refNumber;  
                              this.Form.paymentOptions.push(ledger);
                          }
                      }
                      else{
                          if(groups[i].accountLedgers[j].accountLedgerId  == paymentLedgerId){
                              this.Form.paymentLedgerId = paymentLedgerId;
                              let ledger = {};
                              ledger.accountLedgerId = groups[i].accountLedgers[j].accountLedgerId;
                              ledger.accountLedgerName = groups[i].accountLedgers[j].accountLedgerName;
                              ledger.amount = this.Form.amount;
                              ledger.refNumber = groups[i].accountLedgers[j].refNumber;  
                              this.Form.paymentOptions.push(ledger);
                              break;
                          }
                      }
                      
                  }   
              }
              
          },

      fn_bindTransactionTypeData: function (callback, updateNS) {
        updateNS ? this.fn_bindTransactionNumber(updateNS) : '';

        if(callback){
          callback(1);
        }
        else{
          let self = this;
          setTimeout(function(){
            self.$root.fn_copyFormObject(self.Form, self.formId);
          },500); 
        }
        
        // this.hideLoading();
        this.loading = false;
      },

      // fn_bindTransactionNumber: function () {

      //  if(!this.Form.transactionTypeData){
      //    return;
      //  }

      //  this.transactionNumberSnackbar = false;

      //  let numberSeriesData = this.Form.transactionTypeData.numberSeries;

      //  this.Form.transactionData.prefix = numberSeriesData.prefix;
      //  this.Form.transactionData.suffix = numberSeriesData.suffix;

      //  //For reference use only as incrementing transaction number by numberSeries id in transaction number
      //  this.Form.transactionData.numberSeriesId = numberSeriesData.numberSeriesId;

      //  this.Form.transactionData.transactionNumberId = numberSeriesData.transactionNumberId

      //  let self = this;

      //  // this.getData('TransactionNumber', numberSeriesData.numberSeriesId,
      //  this.getData('TransactionNumber', numberSeriesData.transactionNumberId, 
      //    {
      //      onSuccess: function(response){
      //        console.log(response);
      //        let data = JSON.parse(response);
      //        self.Form.transactionData.transactionNumber = data.transactionNumber;
      //        self.Form.transactionData.mergeTransactionNumber = data.mergeTransactionNumber;
      //          }, 
      //          onError: function(error){
      //              console.log(error);
      //          }
      //    }
     //        );
        
      // },

      fn_bindTransactionNumber: function () {

        if(!this.Form.transactionTypeData){
          return;
        }

        this.transactionNumberSnackbar = false;

        let numberSeriesData = this.Form.transactionTypeData.numberSeries;

        // this.Form.transactionData.prefix = numberSeriesData.prefix;
        this.Form.transactionData.prefix = numberSeriesData.otherPrefix !== null ? numberSeriesData.otherPrefix : numberSeriesData.prefix;
        this.Form.transactionData.suffix = numberSeriesData.suffix;

        //For reference use only as incrementing transaction number by numberSeries id in transaction number
        this.Form.transactionData.numberSeriesId = numberSeriesData.numberSeriesId;

        // this.Form.transactionData.transactionNumberId = numberSeriesData.transactionNumberId;

        let filterObject = {};
        filterObject.numberSeriesId = numberSeriesData.numberSeriesId;
        // filterObject.transactionNumberId = numberSeriesData.transactionNumberId;
        filterObject.fiscalYear = JSON.stringify(this.fn_getFiscalYear(this.Form.transactionData.transactionDate));

        filterObject.typeId = this.settings.transactionTypeId;

        let self = this;

        this.getFilterData('TransactionNumber', null, filterObject, null,
          {
            onSuccess: function(response){
              console.log(response);
              let data = JSON.parse(response);
              self.Form.transactionData.transactionNumber = Number(data.transactionNumber);
              self.Form.transactionData.mergeTransactionNumber = data.mergeTransactionNumber;
              self.Form.transactionData.transactionNumberId = data.transactionNumberId;
              if(self.$root.appVersion >= 136){
                self.Form.transactionData.prefix = data.otherPrefix !== null ? data.otherPrefix : data.prefix;
              }
            }, 
            onError: function(error){
              console.log(error);
            }
          });
      },

      // fn_addParticulars: function (setDefaultValue, accountId, accountName) {

      //   let entryExist = false;

      //   this.Form.particularsData = {
      //     "groupId":null,
      //     "accountLedgerId": null, 
      //     "accountLedgerName": null, 
      //     "amount":null, 
      //     "amountType":null, 
      //     "currentBalance":0,
      //     "currentBalanceType":1,
      //     "groupTotal":null,
      //     "references":[],
      //     //otherdetails
      //     "bankName": null,
      //     "transType":null,
      //     "refNumber":null,
      //     "refDate":null,
      //     "acRefDetails":null,
      //     "onAccount":null
      //   }

      //   // !this.Form.filteredItems.length ? this.unwatch = true : '';

      //   if(this.Form.defaultView){

      //     if(this.debitTotal == this.creditTotal){
      //       this.Form.particularsData.amountType = this.selectedNatureValue;

      //       if(this.Form.filteredItems.length && this.toolbarTitle != 'Journal'){
      //         entryExist = this.fn_check_FirstParty();
      //       }
      //     }
      //     else{
      //       if(this.debitTotal > this.creditTotal){
      //         this.Form.particularsData.amountType = 0
      //       }
      //       else if(this.debitTotal < this.creditTotal){
      //         this.Form.particularsData.amountType = 1
      //       }

      //       if(this.Form.particularsData.amountType == this.selectedNatureValue && this.Form.filteredItems.length && this.toolbarTitle != 'Journal'){
      //         entryExist = this.fn_check_FirstParty();
      //       }
      //       else{
      //         // auto filling the difference of credit/debit sides
      //         this.fn_autoFillAmount();
      //       }
      //     }

      //   }
      //   else{
      //     if(setDefaultValue){
      //       this.Form.particularsData.amountType = this.selectedNatureValue;

      //       if(this.toolbarTitle != 'Journal'){
      //         entryExist = this.fn_check_FirstParty();
      //       }
      //     }
      //     else{
      //       if(this.selectedNatureValue){
      //         this.Form.particularsData.amountType = 0;
      //       }
      //       else{
      //         this.Form.particularsData.amountType = 1;
      //       }

      //       // auto filling the difference of credit/debit sides
      //       this.fn_autoFillAmount();
      //     }
      //   }

      //   if(!entryExist){
      //     accountId && accountName ? this.fn_updateAccountLedger(accountId, accountName, false) : '';
      //     this.fn_openDialogParticulars();
      //   }
        
      // },

      fn_check_FirstParty: function(){
        //checking if first party already exist
        let itemIndex = this.Form.filteredItems.findIndex(item=>{
          return item.amountType == this.selectedNatureValue;
        });

        if(itemIndex!=-1){
          this.fn_editEntry(this.Form.filteredItems[itemIndex], true);
          return true;
        }

        return false;
      },

      fn_openDialogParticulars: function(){
        this.fn_openDialogWeb('web', 'Particular', 'ref_dialogParticular');
      },

      fn_saveParticulars: function (data) {

        // if(this.Form.particularsData.amount < this.$refs['ref_dialogAgainstReference'].referenceTotal){
        //  if(this.Form.references && this.Form.references.length && this.Form.references.length > 1){
        //    this.fn_loadReferences();
        //    this.showToast("Amount is less than reference total");
        //    this.showToast("Please adjust references as per amount");
        //    this.$refs['ref_dialogParticular'].disableBtn = false;
        //    return;
        //  }
        // }

        if(this.Form.filteredItems){
          for(let i=0; i<this.Form.filteredItems.length; i++){
            if(this.Form.filteredItems[i].accountLedgerId == data.accountLedgerId && this.Form.filteredItems[i].amountType != data.amountType){
            //   alert("Ledger already selected");
                this.$eventHub.$emit('snackbarAlert', {msg:'Ledger already selected'});
                this.$refs['ref_dialogParticular'].disableBtn = false;
                return;
            }
          } 
        }
        

        let itemData = JSON.parse(JSON.stringify(data));

        itemData.groupTotal = 0;

        itemData.amount ? itemData.groupTotal += itemData.amount : '';

        let self = this;
        // itemData.references.forEach(function(ref){

        //  let prefix = self.fn_getPrefix(ref.transactionListId);

        //  if(prefix=='minus'){
        //    itemData.groupTotal -= ref.amount;
        //  }
        //  else{
        //    itemData.groupTotal += ref.amount;
        //  }

        // });

        if(!itemData.groupId){

          itemData.groupId = this.guid();

          let itemIndex = this.Form.filteredItems.findIndex(item=>{
            return item.accountLedgerId == itemData.accountLedgerId;
          });

          if(itemIndex == -1){
            this.Form.filteredItems.push(itemData); 
          }
          else{
            this.Form.filteredItems[itemIndex] = JSON.parse(JSON.stringify(itemData));
          }

          this.Form.filteredItems.sort(function(a, b){
            if(self.selectedNatureValue)
                return b.amountType - a.amountType;
              else
                return a.amountType - b.amountType;
          });
        }
        else{
          let itemIndex = null;

          itemIndex = this.Form.filteredItems.findIndex(item => item.groupId==itemData.groupId);

          //Updating like this as computed property does not update on change of object's property of array.
          //As we need total debit/credit amount
          this.Form.filteredItems.splice(itemIndex, 1, itemData);
        }

        console.log(JSON.stringify(this.Form.filteredItems));

        this.componentKey = this.guidWeb();

        this.fn_closeDialog();

        setTimeout(function(){
          self.$refs['ref_dialogParticular'].disableBtn = false;  
        },1000);
      },

      fn_loadReferences: function () {

        if(!this.Form.particularsData.accountLedgerId){
          // this.snackbar = true;
          // this.snackbar_msg = "Select party";
        //   alert("Select Party.");
            this.$eventHub.$emit('snackbarAlert', {msg:'Select Party'});
            return;
        }

        else if(!this.Form.particularsData.amount){
          // this.snackbar = true;
          // this.snackbar_msg = "Select party";
        //   alert("Enter amount");
            this.$eventHub.$emit('snackbarAlert', {msg:'Enter Amount'});
            this.fn_focusOnAmount();
            return;
        }

        let filterObject = {};
        filterObject.formTransactionListId = [this.Form.transactionData.transactionListId];

        if(this.Form.transactionData.transactionListId == transactionTypes.receipt.id){
          // filterObject.transactionListId = [transactionTypes.salesInvoice.id, transactionTypes.salesReturn.id, transactionTypes.bwbRefSaleInvoice.id]
          filterObject.transactionListId = [transactionTypes.salesInvoice.id, transactionTypes.salesReturn.id, transactionTypes.bwbRefPurchaseInvoice.id, transactionTypes.bwbRefSaleInvoice.id];
        }
        else if(this.Form.transactionData.transactionListId == transactionTypes.payment.id){
          // filterObject.transactionListId = [transactionTypes.purchaseInvoice.id, transactionTypes.purchaseReturn.id, transactionTypes.expense.id, transactionTypes.bwbRefPurchaseInvoice.id]
          filterObject.transactionListId = [transactionTypes.purchaseInvoice.id, transactionTypes.purchaseReturn.id, transactionTypes.expense.id, transactionTypes.bwbRefPurchaseInvoice.id, transactionTypes.bwbRefSaleInvoice.id];
        }

        let self = this;
        this.getFilterData('PendingReferences', self.Form.particularsData.accountLedgerId, filterObject, null,
          {
            onSuccess: function(response){
              console.log(response);
              self.Form.references = JSON.parse(response);

              //setting up max value to be filled and amount type dr/cr
              self.Form.references.forEach(function(item){
                item.maxValue = item.outStandingAmount;

                if(item.transactionListId == transactionTypes.purchaseInvoice.id || item.transactionListId == transactionTypes.expense.id || item.transactionListId == transactionTypes.salesReturn.id){
                    item.amountType = 1;
                }
                else if(item.transactionListId == transactionTypes.salesInvoice.id || item.transactionListId == transactionTypes.purchaseReturn.id){
                  item.amountType = 0;
                }

              });

              //Appending amount if any
              for(let i=0; i<self.Form.particularsData.references.length; i++){

                for(let j=0; j<self.Form.references.length; j++){

                   if(self.Form.particularsData.references[i].baseTransactionId == self.Form.references[j].baseTransactionId && self.Form.particularsData.references[i].baseTransactionNumber == self.Form.references[j].baseTransactionNumber){

                      self.$set(self.Form.references[j], 'amount', self.Form.particularsData.references[i].amount)

                      let amount = 0;
                      if(!this.isNew){
                      self.Form.references[j].amount ? amount = self.Form.references[j].amount : '';
                      self.Form.references[j].maxValue = amount + self.Form.references[j].outStandingAmount;
                      }

                      self.$forceUpdate();
                      break;
                   }
                
                } 

              }

              self.fn_openDialogReferences();
              
                }, 
                onError: function(error){
                  console.log(error);
                }
          }
            );
      },

      fn_adjustOnAccount: function(amount){
        if(amount > this.$refs['ref_dialogAgainstReference'].referenceTotal){
          this.Form.particularsData.onAccount = this.roundUpto2Decimals(amount - this.$refs['ref_dialogAgainstReference'].referenceTotal);  
        }
        else if(amount < this.$refs['ref_dialogAgainstReference'].referenceTotal){
          if(this.Form.references && this.Form.references.length && this.Form.references.length == 1){
            this.Form.particularsData.onAccount = null;
            this.Form.references[0].amount = amount;
          }
        }
        else{
          this.Form.particularsData.onAccount = null;
        }
      },

      // fn_saveFilledReferences: function (referenceTotal) {
      fn_saveFilledReferences: function () {
        this.Form.particularsData.references = this.Form.references.filter(item=>{
          if(item.amount)
            return true;
          else
            return false;
        });
        this.fn_closeDialog();

        // let totalAmount = 0;
        // for(let i in this.Form.particularsData.references){
        //  totalAmount += this.Form.particularsData.references[i].amount;
        // }

        // let onAccount = this.Form.particularsData.onAccount ? this.Form.particularsData.onAccount : 0;
        // this.Form.particularsData.amount = this.roundUpto2Decimals(totalAmount + onAccount);

        // this.Form.particularsData.amount = referenceTotal;

        let self = this;
        setTimeout(function(){
          self.$refs['ref_dialogAgainstReference'].disableBtn = false;
        },1000);
      },

      fn_getPrefix: function(transactionListId){
        if(
          (transactionListId == transactionTypes.purchaseInvoice.id) || 
          (transactionListId == transactionTypes.salesInvoice.id) || 
          ((this.Form.transactionData.transactionListId == transactionTypes.receipt.id) && 
            (transactionListId == transactionTypes.bwbRefSaleInvoice.id)) ||
          ((this.Form.transactionData.transactionListId == transactionTypes.payment.id) && 
            (transactionListId == transactionTypes.bwbRefPurchaseInvoice.id))
        ){
          return 'plus'
        }
        else if(
          (transactionListId == transactionTypes.purchaseReturn.id) || 
          (transactionListId == transactionTypes.salesReturn.id) ||
          ((this.Form.transactionData.transactionListId == transactionTypes.payment.id) && 
            (transactionListId == transactionTypes.bwbRefSaleInvoice.id)) ||
          ((this.Form.transactionData.transactionListId == transactionTypes.receipt.id) && 
            (transactionListId == transactionTypes.bwbRefPurchaseInvoice.id))
        ){
          return 'minus'
        }
      },

      fn_openDialogReferences: function () {
        this.fn_openDialogWeb('web', 'AgainstReference', 'ref_dialogAgainstReference');
      },

      fn_isReferenceVisible: function(amountType){
        if(amountType == this.selectedNatureValue && (this.settings.transactionTypeId == transactionTypes.receipt.id || this.settings.transactionTypeId == transactionTypes.payment.id)){
          return true;
        }
        else{
          return false;
        }
      },

      fn_focusOnAmount: function () {
        setTimeout(function(){
          let amountField = document.getElementById('amount_field');
          amountField.focus();
          amountField.select();
        },100);
      },

      fn_autoFillAmount: function () {
        if(this.Form.particularsData.amountType==1){
          if(this.debitTotal < this.creditTotal){
            this.Form.particularsData.amount = this.roundUpto2Decimals(this.creditTotal - this.debitTotal); 
          }
        }
        else{
          if(this.debitTotal > this.creditTotal){
            this.Form.particularsData.amount = this.roundUpto2Decimals(this.debitTotal - this.creditTotal); 
          }
        }
      },

      fn_removeEntry: function (groupId) {
        let filteredItemIndex = null;
        filteredItemIndex = this.Form.filteredItems.findIndex(filteredItem => filteredItem.groupId == groupId);
        this.Form.filteredItems.splice(filteredItemIndex, 1);
      },

      // fn_editEntry: function (item, openDialog) {

      //   // this.hasAgainstReference = false;

      //   this.Form.particularsData.groupId = item.groupId;
      //   this.Form.particularsData.accountLedgerId = item.accountLedgerId;
      //   this.Form.particularsData.accountLedgerName = item.accountLedgerName;
      //   this.Form.particularsData.amount = item.amount;
      //   this.Form.particularsData.onAccount = item.onAccount;
      //   this.Form.particularsData.amountType = item.amountType;
      //   this.Form.particularsData.currentBalance = item.currentBalance;
      //   this.Form.particularsData.currentBalanceType = item.currentBalanceType;
      //   this.Form.particularsData.references = JSON.parse(JSON.stringify(item.references));

      //   // if(!this.isNew && this.Form.particularsData.references.length){
      //   //  this.hasAgainstReference = true;
      //   // }

      //   //otherdetails
      //   this.Form.particularsData.bankName = item.bankName;
      //   this.Form.particularsData.transType = item.transType;
      //   this.Form.particularsData.refNumber = item.refNumber;
      //   this.Form.particularsData.refDate = item.refDate;
      //   this.Form.particularsData.acRefDetails = item.acRefDetails;

      //   this.fn_getCurrentBalance(item.accountLedgerId);
      //   this.fn_getPartyDetails(item.accountLedgerId);

      //   this.$forceUpdate();
      //   openDialog ? this.fn_openDialogParticulars() : '';
      // },

      fn_openDialogWeb: function (platform, name, ref) {

        let scroll_y = window.scrollY;
        this.fn_fixBackgroundScroll();

        var tagId = this.guidWeb();

        var dialogObj = {};
        dialogObj.tagId = tagId;
        dialogObj.formId = this.formId;
        dialogObj.name = name;
        dialogObj.ref = ref;
        dialogObj.data = null;
        dialogObj.platform = platform;

        dialogObj.scrollPosition = {};
        dialogObj.scrollPosition.x = 0;
        dialogObj.scrollPosition.y = scroll_y;

        // dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

        // let self = this;

        this.$refs[ref].openDialogWeb();
      },

      fn_openAccountLedgers: function (platform, name, ref) {

        var tagId = this.guidWeb();

        var dialogObj = {};
        dialogObj.tagId = tagId;
        dialogObj.formId = this.formId;
        dialogObj.name = name;
        dialogObj.ref = ref;
        dialogObj.platform = platform;

        // var self = this;

        var viewDialogObject = {};
        viewDialogObject.ToolbarTitle = this.settings.transactionTypeId == 30004 ?  "Select Ledger" : "Select Party";
        // viewDialogObject.TopMenu = [menuItem.new];
        viewDialogObject.isBottomMenu = false;
        viewDialogObject.hideChip = true;

        if(this.settings.transactionTypeId!=transactionTypes.contra.id){
          viewDialogObject.enableFilterChip = true;
        }

        let defaultGroupTypeId = null;
        
        if(this.settings.transactionTypeId==transactionTypes.journal.id || this.settings.transactionTypeId==transactionTypes.contra.id){
          defaultGroupTypeId = 103;
        }
        else{
          defaultGroupTypeId = this.selectedNatureValue ? 102 : 101;
        }

        // viewDialogObject.groupTypeId = this.Form.partyId ? this.Form.partyDetails.groupTypeId : defaultGroupTypeId;
        viewDialogObject.groupTypeId = this.Form.partyDetails && this.Form.partyDetails.groupTypeId ? this.Form.partyDetails.groupTypeId : defaultGroupTypeId;

        this.newLedgerGroupTypeId = viewDialogObject.groupTypeId;

        var paramObject = {};
        paramObject.defaultValue = {"id":null, "name":null};

        
        if(this.settings.transactionTypeId == transactionTypes.journal.id){
          if(this.Form.particularsData.accountLedgerId && this.Form.particularsData.accountLedgerName){
            paramObject.defaultValue = {"id":this.Form.particularsData.accountLedgerId, "name":this.Form.particularsData.accountLedgerName};
          }
        }
        else{
          // if(this.Form.partyId && this.Form.partyName){
          if(this.Form.selectedParty){
            // paramObject.defaultValue = {"id":this.Form.partyId, "name":this.Form.partyName};
            paramObject.defaultValue = {"id":this.Form.selectedParty.itemId, "name":this.Form.selectedParty.itemName};
          } 
        }

        if(this.settings.transactionTypeId==30004){
          viewDialogObject.accountGroupIds = [paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.card, paymentAccountsAndLedger.groups.wallet];
        }
        

        // var self = this;
        // dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'accounts', tagId, paramObject, ref);
      },

      fn_selectedGroupTypeId: function(groupTypeId){
        this.newLedgerGroupTypeId = Number(groupTypeId);
      },

      // fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
      //   if(ref=='ref_fieldParty'){
      //     this.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
      //   }
      //   else{
      //     this.fn_updateAccountLedger(selectedValueId, selectedValueName, isRemoved); 
      //   }
      // },

      fn_updateAccountLedger: function (itemData) {
        if(Number(itemData.amountType) === 1){
          for(let i=0; i<this.debitItems.length; i++){
            if(this.debitItems[i].groupId == itemData.groupId){
              this.debitItems[i].selectedLedger = {"itemId":itemData.id, "itemName":itemData.name};
              this.fn_getCurrentBalance(itemData.id, this.debitItems[i]);
              break;
            }
          }
        }
        else{
          for(let i=0; i<this.creditItems.length; i++){
            if(this.creditItems[i].groupId == itemData.groupId){
              this.creditItems[i].selectedLedger = {"itemId":itemData.id, "itemName":itemData.name};
              this.fn_getCurrentBalance(itemData.id, this.creditItems[i]);
              break;
            }
          }
        }
        // this.Form.particularsData.accountLedgerId = selectedValueId;
        // this.Form.particularsData.accountLedgerName = selectedValueName;
        // this.fn_getCurrentBalance(itemData.id);
      },

      fn_updateParty: function (selectedValueId, selectedValueName, isRemoved) {
        if(!isRemoved){
          if(this.settings.transactionTypeId == 30004 && this.Form.paymentLedgerId == selectedValueId){
            this.Form.paymentLedgerId = null;
            this.Form.paymentOptions = [];
          }

          // this.Form.partyId = selectedValueId;
          // this.Form.partyName = selectedValueName;
          // this.errors.remove('party');

          this.Form.selectedParty = {"itemId":selectedValueId, "itemName":selectedValueName}

          if(this.settings.transactionTypeId == 30004){
            this.fn_validateParty(selectedValueId);
          }
          else{
            this.fn_getPartyDetails(selectedValueId);
            this.fn_focusOnAmount();
          }
              
        }
        else{
          // this.Form.partyId = null;
          //     this.Form.partyName = null;
          this.Form.selectedParty = null;
        }

        this.$forceUpdate();
      },

      fn_validateParty: function (partyId) {

        let filterObject = {};
        filterObject.accountGroups = [paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.card, paymentAccountsAndLedger.groups.wallet];

        let self = this;
        this.getFilterData('ValidateParty', partyId, filterObject, null,
          {
            onSuccess: function(response){
              console.log(response);
              self.isPartyValid = JSON.parse(response);
              if(!self.isPartyValid){
                // self.Form.partyId = null;
                // self.Form.partyName = null;
                self.Form.selectedParty = null;
                // alert("Invalid ledger");
                self.$eventHub.$emit('snackbarAlert', {msg:'Invalid Ledger'});
              }
              else{
                self.fn_getPartyDetails(partyId);
                self.fn_focusOnAmount();
              }
                }, 
                onError: function(error){
                  console.log(error);
                }
          }
            );
      },

      fn_getPartyDetails: function (partyId) {
        var self = this;
          this.getData('Ledger', partyId, 
          {
            onSuccess: function(response){

                  console.log(response);
                  self.Form.partyDetails = JSON.parse(response);
                  // self.fn_focusOnAmount();
                }, 
                onError: function(error){
                  console.log(error);
                }
          }
        );
      },

      // fn_getPartyBalance: function(ledgerId){
      //  let self = this;
      //  // CurrentBalance
      //  this.getData('AccountDetails', ledgerId, 
      //    {
      //      onSuccess: function(response){
      //              console.log(response);
      //              let data = JSON.parse(response);
      //              if(data.balance){
      //                self.Form.partyBalance = self.roundUpto2Decimals(Number(data.balance)); 
      //              }
      //              else{
      //                self.Form.partyBalance = 0;
      //              }
                    

      //          }, 
      //          onError: function(error){
      //            console.log(error);
      //          }
      //    }
     //        );
      // },

      fn_getCurrentBalance: function(ledgerId, item, currentItemIndex){
        let self = this;

        if(currentItemIndex != undefined){
          if(item.amountType == 1){
            for(let i=0; i<this.creditItems.length; i++){
              if(this.creditItems[i].selectedLedger && this.creditItems[i].selectedLedger.itemId == ledgerId){
                setTimeout(()=>{
                  this.debitItems[currentItemIndex].selectedLedger = null;
                });
                // alert('Ledger already selected.');
                this.$eventHub.$emit('snackbarAlert', {msg:'Ledger already selected'});
                return
              }
            }
          }
          else{
            for(let i=0; i<this.debitItems.length; i++){
              if(this.debitItems[i].selectedLedger && this.debitItems[i].selectedLedger.itemId == ledgerId){
                setTimeout(()=>{
                  this.creditItems[currentItemIndex].selectedLedger = null;
                });
                // alert('Ledger already selected.');
                this.$eventHub.$emit('snackbarAlert', {msg:'Ledger already selected'});
                return
              }
            }
          }
        }
        

        // CurrentBalance
        this.getData('AccountDetails', ledgerId, 
          {
            onSuccess: function(response){
                    console.log(response);
                    let data = JSON.parse(response);
                    
                    item.currentBalanceType = Number(data.balance) < 0 ? 0 : 1;
                    item.currentBalance = self.roundUpto2Decimals(Number(data.balance));
                    item.acRefDetails = data.acRefDetails;

                    self.fn_autoFillJournalAmount(item)

                }, 
                onError: function(error){
                  console.log(error);
                }
          }
            );
      },

      fn_processedBalance: function(balance, balanceType){
        let partyBal = this.$root.numberToLocale(this.roundUpto2Decimals(balance));
        let balanceString = 'Current Bal: ₹ '+ partyBal;
        if(balanceType==1){
          balanceString = balanceString+' To Receive';
        }
        else if(balanceType==0){
          balanceString = balanceString+' To Pay';
        }

        return balanceString;
      },

      // fn_getBottomMenu: function (tagId) {
      //   let menuItem_Note = null;
      //   !this.Form.transactionData.notes ? menuItem_Note = menuItem.addNote : menuItem_Note = menuItem.editNote;
      //   // var bottomMenu = {"BottomMenu": [menuItem.save, menuItem_Note, menuItem.reset, menuItem.changeView, menuItem.help]};
      //   var bottomMenu = {"BottomMenu": [menuItem.save, menuItem_Note, menuItem.reset, menuItem.changeView]};

      //   this.setBottomMenu(tagId, bottomMenu, 
      //     {
      //       onSuccess: function (response) {
      //         console.log(response);
      //       },
      //       onError: function (error) {
      //         console.log(error);
      //       }
      //     }
      //   )
      // },

      fn_closeDialog: function () {
        // dialogjs.fn_checkAndCloseOpenDialogs(this);
      },

      fn_checkValidations: function(){
        var self = this;
        this.disableBtn = true;
        this.fn_checkTransactionNumber(function(){
          self.fn_post();
        });
      },

      fn_checkTransactionNumber: function(callback){
        var self = this;
        let filterObject = {};
        filterObject.mergeTransactionNumber = this.Form.transactionData.mergeTransactionNumber;
        filterObject.fiscalYear = JSON.stringify(this.fn_getFiscalYear(this.Form.transactionData.transactionDate));
        let transactionId = this.Form.transactionData.transactionId ? this.Form.transactionData.transactionId : null;
        this.getFilterData('CheckTransactionNumber', transactionId, filterObject, null,
          {
            onSuccess: function(response){
              console.log(response);
              callback && JSON.parse(response) ? callback() : '';
            }, 
            onError: function(error){
              console.log(error);
              self.saveBtnKey++;
              self.disableOnSave = false;
              self.transactionNumberSnackbar = true;
            }
          }
        );
      },

      fn_saveLocal: function(dataObj){
        var self = this;
        this.postData('AccountBook', dataObj, 
          {
            onSuccess: function(response){
                  console.log(response);
                  // self.fn_cleanUp();
                  self.loading = false
                  self.$refs['ref_dialogLoading'].closeDialogWeb();
                  self.$refs['ref_dialogForm'].closeDialogWeb();

                //   let obj = {};
                //   obj.transactionTypeId = self.settings.transactionTypeId;
                //   setTimeout(() => {
                //     localStorage.removeItem(this.draftStorageId);
                //     self.$eventHub.$emit('UpdateAccountBookList', obj); 
                //   }, 100);
                //   self.fn_goBack();
                    self.fn_cleanUp()
                },
                onError: function(error){
                  // self.hideLoading();
                  self.$refs['ref_dialogLoading'].closeDialogWeb();
                  self.loading = false;
                  self.saveBtnKey++;
                  self.disableOnSave = false;
                //   alert("Somthing went wrong");
                  self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong'});
                  console.log(error);

                  let response = JSON.parse(error);
                  if(response && response.numberExistsError){
                    self.transactionNumberSnackbar = true;
                  }
                }
          }
            );
      },

      fn_cleanUp: function () {
        // this.loadedFromDraft = false;
        // let self = this;
        // this.fn_removeDraft(this.draftStorageId, function(){

        //   if(localStorage.getItem("transaction_items_state")){
        //     let obj = JSON.parse(localStorage.getItem("transaction_items_state"));
        //     obj.itemId = self.Form.transactionData.transactionId;
        //     localStorage.setItem("transaction_items_state",JSON.stringify(obj));
        //   }

        //   self.hideLoading();
        //   // self.$router.go(-1);
        //   this.$root.viaKeyboardShortCut = false;
        //   self.fn_previewInvoice(false, null, 'ab');
        // });

        let obj = {};
        obj.transactionTypeId = this.settings.transactionTypeId;
        setTimeout(()=>{
            localStorage.removeItem(this.draftStorageId);
            this.$eventHub.$emit('UpdateAccountBookList', obj); 
            this.$eventHub.$emit('UpdateLedgerList', obj);
        },100);
        this.backButtonPress = true;
        this.$root.viaKeyboardShortCut = false;

        // posting metrics on save
        let postObj = {"eventName":this.toolbarTitle.toLowerCase()+"_desktop_save","eventData":[{"eventKey":"transaction_number","eventValue":this.Form.transactionData.mergeTransactionNumber}]}
        this.$root.fn_postMetrics(postObj);

        this.fn_previewInvoice(false, null, 'ab');
      },

      fn_post: function(){
        this.$refs['ref_dialogLoading'].openDialogWeb();
        if(this.settings.transactionTypeId == transactionTypes.journal.id){

          // let previousItem = null;

          for(let i=0; i<this.Form.filteredItems.length; i++){
            if(!this.Form.filteredItems[i].selectedLedger || (this.Form.filteredItems[i].selectedLedger && !this.Form.filteredItems[i].selectedLedger.itemId)){
                this.$refs['ref_dialogLoading'].closeDialogWeb();
            //   alert('Please select ledger');
                this.$eventHub.$emit('snackbarAlert', {msg:'Please select ledger'});
                this.disableBtn = false;
                this.saveBtnKey++;
                this.disableOnSave = false;
                return;
            }

            if(!this.Form.filteredItems[i].amount){
                this.$refs['ref_dialogLoading'].closeDialogWeb();
            //   alert('Amount cannot be empty');
                this.$eventHub.$emit('snackbarAlert', {msg:'Amount cannot be empty'});
                this.disableBtn = false;
                this.saveBtnKey++;
                this.disableOnSave = false;
                return;
            }

          }

          if(this.debitTotal != this.creditTotal){
            // alert("To/From sides should be equal");
            this.$eventHub.$emit('snackbarAlert', {msg:'To/From sides should be equal'});
            this.disableBtn = false;
            this.saveBtnKey++;
            this.disableOnSave = false;
            // this.hideLoading();
            this.loading = false
            this.$refs['ref_dialogLoading'].closeDialogWeb();
            return;
          }

          if(this.debitTotal<=0 || this.creditTotal<=0){
            // alert("To/From sides can't be zero or negative");
            this.$eventHub.$emit('snackbarAlert', {msg:"To/From sides can't be zero or negative"});
            this.disableBtn = false;
            this.saveBtnKey++;
            this.disableOnSave = false;
            // this.hideLoading();
            this.loading = false
            this.$refs['ref_dialogLoading'].closeDialogWeb();
            return;
          }

          
        }
        else{
          if(!this.Form.selectedParty){
            this.$refs['ref_dialogLoading'].closeDialogWeb();
            // alert("Select party");
            this.$eventHub.$emit('snackbarAlert', {msg:'Select Party'});
            this.disableBtn = false;
            this.saveBtnKey++;
            this.disableOnSave = false;
            return;
          }
          else if(!this.Form.amount){
            this.$refs['ref_dialogLoading'].closeDialogWeb();
            // alert("Enter amount");
            this.$eventHub.$emit('snackbarAlert', {msg:'Enter Amount'});
            this.disableBtn = false;
            this.saveBtnKey++;
            this.disableOnSave = false;
            return;
          }
          else if(this.splitPaymentTotal != this.Form.amount && !this.Form.isSuspenseAdjustment){
              // this.hideLoading();
              this.$refs['ref_dialogLoading'].closeDialogWeb();
              if(this.Form.splitPayment){
                // alert("Split total should be equal to amount");
                this.$eventHub.$emit('snackbarAlert', {msg:'Split total should be equal to amount'});
              }
              else{
                // alert("Select payment mode");
                this.$eventHub.$emit('snackbarAlert', {msg:'Select payment mode'});
              }
          
            this.disableBtn = false;
            this.saveBtnKey++;
            this.disableOnSave = false;
            this.fn_openDialog_PaymentOptions();
            return
          }
        }
        

        //only in case of new transaction
        if(!this.Form.transactionData.transactionId){
          this.Form.transactionData.transactionId = this.guid();
          // this.Form.transactionData.createdDate = new Date();
          this.Form.transactionData.createdDate = this.$root.fn_getLocalDateFormat();
        }

        // this.Form.transactionData.modifiedDate = new Date();
        this.Form.transactionData.modifiedDate = this.$root.fn_getLocalDateFormat();

        // this.Form.transactionData.partyId = this.toolbarTitle != 'Journal' ? this.Form.filteredItems[0].accountLedgerId : null;
        // this.Form.transactionData.partyId = this.toolbarTitle != 'Journal' ? this.Form.partyId : null;
        // this.Form.transactionData.amount = this.debitTotal;//or creditTotal amount
        // this.Form.transactionData.amount = this.Form.amount;//or creditTotal amount
        // this.Form.transactionData.roundedAmount =  this.debitTotal; //or creditTotal amount
        // this.Form.transactionData.roundedAmount =  this.Form.amount; //or creditTotal amount
        // this.Form.transactionData.netAmount = this.debitTotal; //or creditTotal amount
        // this.Form.transactionData.netAmount = this.Form.amount; //or creditTotal amount

        if(this.settings.transactionTypeId==transactionTypes.journal.id){
          this.Form.transactionData.partyId = null;
          this.Form.transactionData.amount = this.debitTotal;//or creditTotal amount
          this.Form.transactionData.roundedAmount =  this.debitTotal; //or creditTotal amount
          this.Form.transactionData.netAmount = this.debitTotal; //or creditTotal amount
        }
        else{
          this.Form.transactionData.partyId = this.Form.selectedParty.itemId;
          this.Form.transactionData.amount = this.Form.amount; 
          this.Form.transactionData.roundedAmount =  this.Form.amount;
          this.Form.transactionData.netAmount = this.Form.amount;
        }


        this.Form.transactionData.status = 1;
        this.Form.transactionData.refDate = null;

        this.Form.transactionData.transactionTypeFormId = this.Form.transactionTypeFormId;
        this.Form.transactionData.transactionTypeFormName = this.Form.transactionTypeFormName;

        // this.Form.transactionData.prefix = this.Form.transactionData.prefix ? this.Form.transactionData.prefix : null;
				this.Form.transactionData.suffix = this.Form.transactionData.suffix ? this.Form.transactionData.suffix : null;

        let postObj = {};
        postObj = JSON.parse(JSON.stringify(this.Form.transactionData));

        postObj.transactionLedgers = [];
        postObj.ledgerAccounts = [];

        if(this.settings.transactionTypeId != transactionTypes.journal.id){
          this.fn_generateAccountPaymentEntries(postObj.ledgerAccounts, postObj.transactionLedgers);  
        }
        else{
          let self = this;

          this.Form.filteredItems.forEach(function(group){
            let obj = {};

            obj.groupId = group.groupId;
            // obj.accountLedgerId = group.accountLedgerId;
            obj.accountLedgerId = group.selectedLedger.itemId;
            obj.refTId = null;

            obj.baseAmountType = group.amountType;
            obj.additionalData = null;
            obj.transactionListId = self.Form.transactionData.transactionListId;

            //otherdetails
            obj.bankName = group.bankName;
            obj.transType = group.transType;
            obj.refNumber = group.refNumber;
            obj.refDate = group.refDate;

            let onAccountObj = JSON.parse(JSON.stringify(obj));
            if(group.onAccount){
              onAccountObj.amount = Number(group.onAccount);
              onAccountObj.isOnAccount = true;
            }
            else{
              onAccountObj.amount = Number(group.amount);
            }
            onAccountObj.amountType = group.amountType;
            onAccountObj.transactionLedgerId = self.guid();
            onAccountObj.isAgainstRef = false;
            onAccountObj.orderNumber = postObj.transactionLedgers.length + 1;
            
            if(group.references.length){
              group.references.forEach(function(ref, index){
                let refObj = JSON.parse(JSON.stringify(obj));
                refObj.transactionLedgerId = self.guid();
                refObj.amount = Number(ref.amount);
                refObj.amountType = ref.amountType;
                refObj.refTId =  refObj.refTId ? refObj.refTId : ref.baseTransactionId;
                refObj.isAgainstRef = true;
                refObj.orderNumber = Number(group.amount) ? index+2 : index+1;
                postObj.transactionLedgers.push(refObj);
              });

              //if refrences exist and on account also
              group.onAccount ? postObj.transactionLedgers.push(onAccountObj) : '';
            }
            else{
              //if refrences does not exist and on account exist or not
              // if onAccount exist on account value will apply
              // else group total amount will apply
              postObj.transactionLedgers.push(onAccountObj);
            }

            let ledgerAccountObj = {};

            ledgerAccountObj.ledgerAccountId = self.guid();
            ledgerAccountObj.transactionListId = self.Form.transactionData.transactionListId;
            ledgerAccountObj.transactionDate = self.Form.transactionData.transactionDate; 
            ledgerAccountObj.amountType = group.amountType;//credit/debit
            ledgerAccountObj.accountId = group.selectedLedger.itemId;
            ledgerAccountObj.amount = group.amountType == 1 ? Number(group.amount) : -Number(group.amount);
            // ledgerAccountObj.amount = group.amountType == 1 ? group.groupTotal : -group.groupTotal;

            postObj.ledgerAccounts.push(ledgerAccountObj);

          });
        }

        postObj.fiscalYear = JSON.stringify(this.fn_getFiscalYear(this.Form.transactionData.transactionDate));
        postObj.sortDate = this.$moment(this.Form.transactionData.transactionDate).format('YYYY-MM-DD');
        postObj.splitPayment = this.Form.splitPayment;

        if(this.settings.transactionTypeId==30004){
          postObj.addReduceAmount = this.Form.addReduceAmount;
          postObj.isSuspenseAdjustment = this.Form.isSuspenseAdjustment;
        }
        
        
        // this.fn_saveLocal(postObj);
        let dataSize = 1024 * 32;
        if(JSON.stringify(postObj).length >= dataSize){
            this.fn_breakDataIntoChunks(JSON.stringify(postObj))
        }
        else{
            this.fn_saveLocal(postObj); 
        }
      },

      fn_generateAccountPaymentEntries: function(ledgerAccounts, transactionLedgers){

        // party ledger account entry
        let partyLedgerObj = {};

        if(this.settings.transactionTypeId!=30004){
          partyLedgerObj.amountType = this.selectedNatureValue;
                  partyLedgerObj.amount = this.selectedNatureValue ? this.Form.amount : -this.Form.amount;  
        }
        else{
          partyLedgerObj.amountType = this.Form.addReduceAmount;
                  partyLedgerObj.amount = this.Form.addReduceAmount ? this.Form.amount : -this.Form.amount;
        }
                
        // partyLedgerObj.accountId = this.Form.partyId;
        partyLedgerObj.accountId = this.Form.selectedParty.itemId;
        let ledgerObj = this.fn_bindAndReturnSimilarValues(partyLedgerObj);
        ledgerAccounts.push(ledgerObj);

        // party transaction ledger entry
        let tlObj = {};
        tlObj.transactionLedgerId = this.guid();
        // tlObj.accountLedgerId = this.Form.partyId;
        tlObj.accountLedgerId = this.Form.selectedParty.itemId;
        tlObj.orderNumber = transactionLedgers.length + 1;
        tlObj.amount = this.Form.amount;

        if(this.settings.transactionTypeId!=30004){
          tlObj.amountType = this.selectedNatureValue;
          tlObj.baseAmountType = this.selectedNatureValue;
        }
        else{
          tlObj.amountType = this.Form.addReduceAmount;
          tlObj.baseAmountType = this.Form.addReduceAmount;
        }
        
        tlObj.transactionListId = this.Form.transactionData.transactionListId;

        tlObj.groupId = this.guid();
        tlObj.refTId = null;
        tlObj.refNumber = null;
        tlObj.isAgainstRef = false;
        transactionLedgers.push(tlObj);

      if(!this.Form.splitPayment){
                  // cash ledger account entry
          let cashLedgerObj = {};
                  
          if(this.settings.transactionTypeId!=30004){
            cashLedgerObj.amountType = this.selectedNatureValue ? 0 : 1;
                    cashLedgerObj.amount = this.selectedNatureValue ? -this.Form.amount : this.Form.amount;
          }
          else{
                    cashLedgerObj.amount = this.Form.addReduceAmount ? -this.Form.amount : this.Form.amount;
            cashLedgerObj.amountType = this.Form.addReduceAmount ? 0 : 1;
          }

          cashLedgerObj.accountId = this.Form.paymentLedgerId;
          let ledgerObj2 = this.fn_bindAndReturnSimilarValues(cashLedgerObj);
          ledgerAccounts.push(ledgerObj2);

          // cash transaction ledger entry
          let tlCashObj = {};
          tlCashObj.transactionLedgerId = this.guid();
          tlCashObj.accountLedgerId = this.Form.paymentLedgerId;
          tlCashObj.orderNumber = transactionLedgers.length + 1;
          tlCashObj.amount = this.Form.amount;
          
          if(this.settings.transactionTypeId!=30004){
            tlCashObj.amountType = this.selectedNatureValue ? 0 : 1;
            tlCashObj.baseAmountType = this.selectedNatureValue ? 0 : 1;
          }
          else{
            tlCashObj.amountType = this.Form.addReduceAmount ? 0 : 1;
            tlCashObj.baseAmountType = this.Form.addReduceAmount ? 0 : 1;
          }

          tlCashObj.transactionListId = this.Form.transactionData.transactionListId;

          tlCashObj.groupId = this.guid();
          tlCashObj.refTId = null;
          tlCashObj.refNumber = this.Form.paymentOptions.length ? this.Form.paymentOptions[0].refNumber : null;
          tlCashObj.isAgainstRef = false;
          transactionLedgers.push(tlCashObj);
      }
      else{
        for(let i=0; i<this.Form.paymentOptions.length; i++){
            // payment ledger account entries
          let paymentLedgerObj = {};
                  
          if(this.settings.transactionTypeId!=30004){
            paymentLedgerObj.amountType = this.selectedNatureValue ? 0 : 1;
                    paymentLedgerObj.amount = this.selectedNatureValue ? -this.Form.paymentOptions[i].amount : this.Form.paymentOptions[i].amount;
          }
          else{
            paymentLedgerObj.amountType = this.Form.addReduceAmount ? 0 : 1;
                    paymentLedgerObj.amount = this.Form.addReduceAmount ? -this.Form.paymentOptions[i].amount : this.Form.paymentOptions[i].amount;
          }

          paymentLedgerObj.accountId = this.Form.paymentOptions[i].accountLedgerId;
          let ledgerObj = this.fn_bindAndReturnSimilarValues(paymentLedgerObj);
          ledgerAccounts.push(ledgerObj);

          // payment transaction ledger entry
          let tlCashObj = {};
          tlCashObj.transactionLedgerId = this.guid();
          tlCashObj.accountLedgerId = this.Form.paymentOptions[i].accountLedgerId;
          tlCashObj.orderNumber = transactionLedgers.length + 1;
          tlCashObj.amount = this.Form.paymentOptions[i].amount;
          
          if(this.settings.transactionTypeId!=30004){
            tlCashObj.amountType = this.selectedNatureValue ? 0 : 1;
            tlCashObj.baseAmountType = this.selectedNatureValue ? 0 : 1;
          }
          else{
            tlCashObj.amountType = this.Form.addReduceAmount ? 0 : 1;
            tlCashObj.baseAmountType = this.Form.addReduceAmount ? 0 : 1;
          }

          tlCashObj.transactionListId = this.Form.transactionData.transactionListId;

          tlCashObj.groupId = this.guid();
          tlCashObj.refTId = null;
          tlCashObj.refNumber = this.Form.paymentOptions[i].refNumber;
          tlCashObj.isAgainstRef = false;
          transactionLedgers.push(tlCashObj);
        }
      }

  },

  fn_autoFillPaymentMode: function(val){
    if(this.Form.paymentOptions && this.Form.paymentOptions.length && this.Form.paymentOptions.length == 1){
      this.Form.paymentOptions[0].amount = val;
    }
  },

      fn_get: function(itemId, copyData){
        var self = this;
        this.getData('AccountBook', itemId, 
          {
            onSuccess: function(response){
              console.log(response);
              let data = JSON.parse(response)
              self.fn_bindData(data, copyData);
            }, 
            onError: function(error){
                console.log(error);
                self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                self.fn_goBack();
            }
          }
            );
      },

      fn_bindData: function(data){
        let self = this;
        this.unwatch = true;
        this.fn_getTransactionTypeData(data.transactionTypeFormId, function(){

          self.Form.transactionData.transactionId = data.transactionId;
          self.Form.transactionData.prefix = data.prefix;
          self.Form.transactionData.transactionNumber = data.transactionNumber;
          self.Form.transactionData.mergeTransactionNumber = data.mergeTransactionNumber;
          self.Form.transactionData.suffix = data.suffix;

          self.Form.transactionData.transactionDate = data.transactionDate;
          
          // this.Form.transactionData.partyId = data.partyId;

          // self.Form.partyId = data.partyId;
          // self.Form.partyName = data.partyName;
          self.Form.selectedParty = {"itemId":data.partyId, "itemName":data.partyName};
          // self.fn_getPartyBalance(data.partyId);
          data.partyId ? self.fn_getPartyDetails(data.partyId) : '';

          self.Form.amount = data.amount;
          
          self.Form.transactionData.amount = data.amount;
          self.Form.transactionData.roundedAmount = data.roundedAmount;
          self.Form.transactionData.status = data.status;
          self.Form.transactionData.notes = data.notes;
          
          self.Form.transactionData.createdDate = data.createdDate;

          self.Form.transactionTypeFormId = data.transactionTypeFormId;
          self.Form.transactionTypeFormName = data.transactionTypeFormName;

          self.Form.transactionData.numberSeriesId = data.numberSeriesId;
          self.Form.transactionData.transactionNumberId = data.transactionNumberId;

          self.Form.splitPayment = data.splitPayment;

          if(self.settings.transactionTypeId==30004){
            self.Form.addReduceAmount = data.addReduceAmount;
            self.Form.isSuspenseAdjustment = data.isSuspenseAdjustment;
          }

          //appending payment modes if applicable
          if(data.transactionLedgers && data.transactionLedgers.length){
            let paymentLedgers = [];

            let selectedNature = self.settings.transactionTypeId == 30004 ? self.Form.addReduceAmount : self.selectedNatureValue;

            for(let i=0; i<data.transactionLedgers.length; i++){
              // if(data.transactionLedgers[i].baseAmountType != self.selectedNatureValue){
              if(data.transactionLedgers[i].baseAmountType != selectedNature){
                let obj = {};
                obj.accountLedgerId = data.transactionLedgers[i].accountLedgerId;
                obj.accountLedgerName = data.transactionLedgers[i].accountLedgerName;
                obj.amount = data.transactionLedgers[i].references[0].amount;
                obj.refNumber = data.transactionLedgers[i].references[0].refNumber;
                obj.orderNumber = data.transactionLedgers[i].references[0].orderNumber;
                paymentLedgers.push(obj);
              }
            }

            self.Form.paymentOptions = JSON.parse(JSON.stringify(paymentLedgers));

            if(!self.Form.splitPayment && self.settings.transactionTypeId != transactionTypes.journal.id){
              self.Form.paymentLedgerId = self.Form.paymentOptions[0].accountLedgerId;
            }

            // if(self.Form.splitPayment){
            //  self.Form.paymentOptions = JSON.parse(JSON.stringify(paymentLedgers));
            // }
            // else{
            //  for(let i=0; i<data.transactionLedgers.length; i++){
            //    if(data.transactionLedgers[i].baseAmountType != self.selectedNatureValue){
            //      self.Form.paymentLedgerId = data.transactionLedgers[i].accountLedgerId;
            //      break;
            //    }
            //  }
            // }
          }


          data.transactionLedgers.forEach(function(group){

            group.amount = null;

            group.onAccount = null;

            group.amountType = group.baseAmountType;

            group.groupTotal = 0;

            group.currentBalance < 0 ? group.currentBalanceType = 0 : group.currentBalanceType = 1;

            // group.currentBalance = Math.abs(group.currentBalance);
            // group.currentBalance = group.currentBalance;

            let onAccountItemIndex = null;

            // if(group.references && group.references.length){
            //  self.hasAgainstReference = true;
            // }

            group.selectedLedger = {};
            group.selectedLedger.itemId = group.accountLedgerId;
            group.selectedLedger.itemName = group.accountLedgerName;

            group.references.forEach(function(ref, index){

              let prefix = self.fn_getPrefix(ref.transactionListId);

              if(prefix=='minus'){
                group.groupTotal -= ref.amount;
              }
              else{
                group.groupTotal += ref.amount;
              }

              if(!ref.isAgainstRef){
                // group.amount = ref.amount;
                ref.isOnAccount ? group.onAccount = ref.amount : '';
                //otherdetails
                group.bankName = ref.bankName;
                group.transType = ref.transType;
                group.refNumber = ref.refNumber;
                group.refDate = ref.refDate ? new Date(ref.refDate) : null;

                onAccountItemIndex = index;
              }
              else{
                self.hasAgainstReference = true;
              }

            });

            group.amount = group.groupTotal;

            if(onAccountItemIndex != null){
              group.references.splice(onAccountItemIndex, 1);
            }

          })        

          self.Form.filteredItems = JSON.parse(JSON.stringify(data.transactionLedgers));

          // sorting as per amount types
          self.Form.filteredItems.sort(function(a, b){
            if(self.selectedNatureValue)
                return b.amountType - a.amountType;
              else
                return a.amountType - b.amountType;
          });

          // sorting as per order number
          self.Form.filteredItems.sort(function(a, b){
            return a.orderNumber - b.orderNumber;
          });

          setTimeout(function(){
            self.$root.fn_copyFormObject(self.Form, self.formId);
          },500);

          console.log(JSON.stringify(self.Form.filteredItems));
        });
      },

      // fn_bindData: function (data, copyData) {
      //  let self = this;

      //  // this.fn_getTransactionTypeData(data.transactionTypeFormId, null, false);

      //  this.unwatch = true;
      //  this.Form.transactionData.transactionId = data.transactionId;
      //  this.Form.transactionData.prefix = data.prefix;
      //  this.Form.transactionData.transactionNumber = data.transactionNumber;
      //  this.Form.transactionData.mergeTransactionNumber = data.mergeTransactionNumber;
      //  this.Form.transactionData.suffix = data.suffix;

      //  this.Form.transactionData.transactionDate = data.transactionDate;
        
      //  // this.Form.transactionData.partyId = data.partyId;

      //  this.Form.partyId = data.partyId;
      //  this.Form.partyName = data.partyName;
      //  this.fn_getPartyBalance(data.partyId);

      //  this.Form.amount = data.amount;
        
      //  this.Form.transactionData.amount = data.amount;
      //  this.Form.transactionData.roundedAmount = data.roundedAmount;
      //  this.Form.transactionData.status = data.status;
      //  this.Form.transactionData.notes = data.notes;
        
      //  this.Form.transactionData.createdDate = data.createdDate;

      //  this.Form.transactionTypeFormId = data.transactionTypeFormId;
      //  this.Form.transactionTypeFormName = data.transactionTypeFormName;

      //  this.Form.transactionData.numberSeriesId = data.numberSeriesId;
      //  this.Form.transactionData.transactionNumberId = data.transactionNumberId;

      //  this.Form.splitPayment = data.splitPayment;

      //  //appending payment modes if applicable
      //  if(data.transactionLedgers && data.transactionLedgers.length){
      //    let paymentLedgers = [];
      //    for(let i=0; i<data.transactionLedgers.length; i++){
      //      if(data.transactionLedgers[i].baseAmountType != this.selectedNatureValue){
      //        let obj = {};
      //        obj.accountLedgerId = data.transactionLedgers[i].accountLedgerId;
      //        obj.accountLedgerName = data.transactionLedgers[i].accountLedgerName;
      //        obj.amount = data.transactionLedgers[i].amount;
      //        obj.orderNumber = data.transactionLedgers[i].orderNumber;
      //        paymentLedgers.push(obj);
      //      }
      //    }

      //    if(this.Form.splitPayment){
      //      this.Form.paymentOptions = JSON.parse(JSON.stringify(paymentLedgers));
      //    }
      //    else{
      //      for(let i=0; i<data.transactionLedgers.length; i++){
      //        if(data.transactionLedgers[i].baseAmountType != this.selectedNatureValue){
      //          this.Form.paymentLedgerId = data.transactionLedgers[i].accountLedgerId;
      //          break;
      //        }
      //      }
      //      // self.Form.paymentLedgerId = data.transactionLedgers[0].accountLedgerId;
      //    }
      //  }


      //  data.transactionLedgers.forEach(function(group){

      //    group.amount = null;

      //    group.onAccount = null;

      //    group.amountType = group.baseAmountType;

      //    group.groupTotal = 0;

      //    group.currentBalance < 0 ? group.currentBalanceType = 0 : group.currentBalanceType = 1;

      //    group.currentBalance = Math.abs(group.currentBalance);

      //    let onAccountItemIndex = null;

      //    // if(group.references && group.references.length){
      //    //  self.hasAgainstReference = true;
      //    // }

      //    group.references.forEach(function(ref, index){

      //      let prefix = self.fn_getPrefix(ref.transactionListId);

      //      if(prefix=='minus'){
      //        group.groupTotal -= ref.amount;
      //      }
      //      else{
      //        group.groupTotal += ref.amount;
      //      }

      //      if(!ref.isAgainstRef){
      //        // group.amount = ref.amount;
      //        ref.isOnAccount ? group.onAccount = ref.amount : '';
      //        //otherdetails
      //        group.bankName = ref.bankName;
      //        group.transType = ref.transType;
      //        group.refNumber = ref.refNumber;
      //        group.refDate = ref.refDate ? new Date(ref.refDate) : null;

      //        onAccountItemIndex = index;
      //      }
      //      else{
      //        self.hasAgainstReference = true;
      //      }

      //    });

      //    group.amount = group.groupTotal;

      //    if(onAccountItemIndex != null){
      //      group.references.splice(onAccountItemIndex, 1);
      //    }

      //  })        

      //  this.Form.filteredItems = JSON.parse(JSON.stringify(data.transactionLedgers));

      //  this.Form.filteredItems.sort(function(a, b){
      //    if(self.selectedNatureValue)
      //        return b.amountType - a.amountType;
      //      else
      //        return a.amountType - b.amountType;
      //  });

      //  setTimeout(function(){
      //    self.$root.fn_copyFormObject(self.Form, self.formId);
      //  },500);

      //  console.log(JSON.stringify(this.Form.filteredItems));
      // },

      fn_clearForm: function(){
        this.Form = {
          "transactionData":JSON.parse(JSON.stringify(transactionData)),

          "particularsData":{
            "groupId":null,
            "accountLedgerId":null, 
            "accountLedgerName":null, 
            "amount":null, 
            "amountType":null, 
            "currentBalance":0,
            "currentBalanceType":1,
            "groupTotal":null,
            "references":[],
            //other details
            "bankName": null,
            "transType":null,
            "refNumber":null,
            "refDate":null,
            "acRefDetails":null,
            "onAccount":null
          },

          "filteredItems":[],
          "debitTotal":0,
          "creditTotal":0,
          "defaultView":true,
          "transactionTypeFormId":null,
          "transactionTypeFormName":null,
          "transactionTypeData":null,
          "references":null,
          "transactionTypeList":[],
          "draftItems":[]
        }

        this.fn_initializeSettings();

        //sending true to setup transactionformtype from local storage
        this.fn_getTransactionTypeList(true);
      },

      // fn_saveToLocalStorage: function(){
      //  if(!this.unwatch){
      //    var dataObj = {};
      //    dataObj.type = this.toolbarTitle;
      //    dataObj.formType = "new";
      //    dataObj.modifiedDate = new Date();
      //    dataObj.formId = this.formId;
      //    dataObj.draftId = this.draftStorageId;  
      //    dataObj.uri = this.$route.path;
      //    dataObj.dataModel = JSON.parse(JSON.stringify(this.Form));
      //    dataObj.dataModel.name = "no name";

      //    if(this.Form.filteredItems.length){
      //      dataObj.dataModel.name = this.Form.filteredItems[0].accountLedgerName;
      //    }

      //    localStorage.setItem(this.draftStorageId, JSON.stringify(dataObj));
      //  }
      //  this.unwatch = false;
      // },

      fn_menuItemClick: function(tagId, menuId, menuAction){

        this.backButtonPress = false;
        
        if(menuAction=='save'){
          this.fn_checkValidations();
        }
        else if(menuAction=='new'){
          let groupTypeId = ledgerGroupTypeId.customer;
          let ledgerType = "party";

          // //setting up default group type as per 
          // if(this.settings.transactionTypeId == transactionTypes.receipt.id){
          //  this.Form.particularsData.amountType == this.selectedNatureValue ? groupTypeId = ledgerGroupTypeId.customer : '';
          // }
          // else if(this.settings.transactionTypeId == transactionTypes.payment.id){
          //  this.Form.particularsData.amountType == this.selectedNatureValue ? groupTypeId = ledgerGroupTypeId.supplier : '';
          // }  
          

          //setting up default group type as per transaction
          if(this.settings.transactionTypeId == transactionTypes.journal.id){
            ledgerType = "ledger"
          }
          else if(this.settings.transactionTypeId == transactionTypes.receipt.id){
            groupTypeId = ledgerGroupTypeId.customer;
          }
          else if(this.settings.transactionTypeId == transactionTypes.payment.id){
            groupTypeId = ledgerGroupTypeId.supplier;
          }

          this.newLedgerGroupTypeId ? groupTypeId = this.newLedgerGroupTypeId : '';
          
          this.$router.push({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), groupTypeId: groupTypeId, ls:1, type:ledgerType}});
        }
        else if(menuAction=='reset'){
          this.fn_resetForm();
        }
        else if(menuAction=='changeview'){
          this.Form.defaultView = !this.Form.defaultView;
          localStorage.setItem('acbDefaultView', this.Form.defaultView);
        }
        else if(menuAction=='addnote'){
          this.fn_addNote();  
        }
        else if(menuAction=='attachdocuments'){
          // this.snackbar_msg = "Coming Soon...";
          // this.snackbar = true;
        //   alert("Coming Soon...");
        }
        else if(menuAction=='takephoto'){
          // this.snackbar_msg = "Coming Soon...";
          // this.snackbar = true;
        //   alert("Coming Soon...");
        }
      },

      // fn_openDialogCancelTransaction: function(){
      //  this.confirmationMessage = "Cancelled transaction cannot be revoke."
      //  this.fn_openDialogWeb('web', 'Cancel Transaction', 'ref_dialogCancelTransaction');
      // },

      fn_resetForm: function () {
        this.unwatch = true;
        // this.loadedFromDraft = false;
        
        if(this.Form.transactionData.transactionId){
          this.fn_get(this.Form.transactionData.transactionId);
        }
        else{
          this.fn_clearForm();
        }

        // if(localStorage.getItem(this.draftStorageId)){
        //  localStorage.removeItem(this.draftStorageId);
        //  this.snackbar = true;
        //  this.snackbar_msg = "Item deleted from draft";
        // }
        this.fn_removeDraft(this.draftStorageId);
      },

      fn_initializeSettings: function () {
        // let self = this;
        transactionForms.filter(item => {
          if(item.id == this.settings.transactionTypeId){
            this.unwatch = true;
            this.toolbarTitle = item.title;
            this.selectedNatureValue = item.selectedNature;
            this.Form.transactionData.transactionListId = this.settings.transactionTypeId;
            // this.Form.transactionData.transactionDate = new Date();
            if(new Date().getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
              this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(this.$root.CurrentBusiness.OpeningDate);
            }
            else{
              this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(); 
            }
            // this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat();
          }
        });

        if(localStorage.getItem('acbDefaultView') == undefined){
          localStorage.setItem('acbDefaultView', this.Form.defaultView);
        }

        this.unwatch = true;
        this.Form.defaultView = JSON.parse(localStorage.getItem('acbDefaultView'));

        // this.fn_accountLedgers();
      },

      fn_addNote: function () {
        this.$refs['ref_dialogNote'].noteValue = this.Form.transactionData.notes;
        this.fn_openDialogWeb('web', 'Note', 'ref_dialogNote');
        setTimeout(function(){
          let noteField = document.getElementById('noteField');
          noteField.focus();
        });
      },

      fn_saveNote: function () {
        this.Form.transactionData.notes = this.$refs['ref_dialogNote'].noteValue;
        this.fn_closeDialog();
      },

      // fn_showWebView: function () {
      //   var viewObject = {};

      //   var tagId = this.formId;
      //   var self = this;
      //   this.showWebView(tagId, viewObject, 
      //     {
      //       onSuccess: function(response){
      //             console.log(response);
      //           }, 
      //           onError: function(error){
      //             console.log(error);
      //           }, 
      //           // onChange: null,
      //           onMenuItemClick: function (tagId, menuId, menuAction) {
      //         self.fn_menuItemClick(tagId, menuId, menuAction)
      //       }
      //     }
      //   );
      // },

      // fn_setFormDataFromLocalStorage: function () {
      //  if(localStorage.getItem(this.draftStorageId)){
      //    var formData = JSON.parse(localStorage.getItem(this.draftStorageId));
      //    this.unwatch = true;
      //    this.Form = formData.dataModel;

      //    if((this.$route.query && this.$route.query.draft) || (this.$route.params && this.$route.params.tagId)){
      //      this.loadedFromDraft = true;
      //      !this.Form.transactionData.transactionId ? this.fn_bindTransactionNumber() : '';
      //    }

      //    //refreshing all parties current balance
      //    let self = this;
      //    this.Form.filteredItems.forEach(function(group){
      //      (function(ledgerId){
      //        self.fn_getCurrentBalance(ledgerId, function(balance){
      //          balance < 0 ? group.currentBalanceType = 0 : group.currentBalanceType = 1;
      //          group.currentBalance = Math.abs(balance);
      //        });
      //      }(group.accountLedgerId));
      //    })
      //  } 
      // },

      fn_setNewItem: function(){
        let itemData = null;
        if(localStorage.getItem('newItem')){

          itemData = JSON.parse(localStorage.getItem('newItem'));

          switch (itemData.type) {
            case "transactionTypeForm":
                this.fn_setTransactionType({transactionTypeFormId:itemData.id, name:itemData.name}, true);
                  break;
              case "party":
                // this.fn_updateAccountLedger(itemData.id, itemData.name, false);
                this.fn_updateParty(itemData.id, itemData.name, false);
                  break;
              case "ledger":
                this.fn_updateAccountLedger(itemData);
                  break;
              case "ledger_other":
                // this.fn_updateAccountLedger(itemData.id, itemData.name, false);
                this.fn_openDialog_PaymentOptions();
                this.fn_getAccountPaymentGroups();
                  break;
              default:
                console.log("condition not matched");
          }

          localStorage.removeItem('newItem');
        }

        //dt = direct transaction
        if(this.$route.query && this.$route.query.dt && !itemData){
          if(this.settings.transactionTypeId == transactionTypes.journal.id){
            this.fn_addParticulars(true, this.$route.query.id, this.$route.query.name);
          }
          else{
            this.fn_updateParty(this.$route.query.id, this.$route.query.name, false);
          }
        }
      },

        fn_goBack: function(){
            this.$refs['ref_dialogForm'].closeDialogWeb();
            localStorage.removeItem(this.draftStorageId);
            this.$root.viaKeyboardShortCut = false;
            setTimeout(() => {
                if(this.nextRoute.path){
                    this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                }
                else{
                    this.$router.go(-1);
                }
            }, 50);
        },
        
        fn_beforeRouteEnter: function(vm, to){
          let itemId = null;

          if(to.params && to.params.tagId){//from display view
            itemId = to.params.tagId;
          }

          if(itemId){
            vm.fn_get(itemId);
          }
          else{
            vm.fn_addParticular(0);
            vm.fn_addParticular(1);
          }

          let setTFT = !itemId;

          vm.$route.query.loadItems ? setTFT = false : '';

          vm.fn_getTransactionTypeList(setTFT);

          if(vm.$route.query.loadItems){
            vm.fn_checkLastInvoice();
          }
        }
    },
    mounted: function(){
      // var self = this;
      // this.fn_openDialogForm();
      this.fn_initializeSettings();

      // posting metrics on save
			let postObj = {"eventName":this.toolbarTitle.toLowerCase() + '_desktop_open',"eventData":[{"eventKey":"form_type","eventValue":this.$route.params && this.$route.params.tagId ? "edit" : "new"}]}
      this.$root.fn_postMetrics(postObj);

      // this.PropFunctions.removeEntry = this.fn_removeEntry;
      // this.PropFunctions.editEntry = this.fn_editEntry
      // this.PropFunctions.processedBalance = this.fn_processedBalance;

      if(this.$route.query && this.$route.query.tagId){//in case of new form
        this.formId = this.$route.query.tagId;
        this.draftStorageId = 'draft_transaction_'+this.formId;
        // this.dialogStorageId = 'dialog_'+this.formId;
        // !this.$root.CurrentBusiness.DataDrive && localStorage.getItem("SetDataDrive") ?  this.fn_checkTransactionCount() : '';
      }
      else if(this.$route.params && this.$route.params.tagId){//in case of edit form
        this.formId = this.$route.params.tagId;
        this.draftStorageId = 'draft_transaction_'+this.formId;
        // this.dialogStorageId = 'dialog_'+this.formId;
      }

      if(localStorage.getItem(this.draftStorageId)){
          let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
          this.Form = JSON.parse(dataObj.formData);
      }

      // !this.$root.CurrentBusiness.DataDrive ?  this.fn_checkTransactionCount() : '';

      setTimeout(()=>{this.fn_setNewItem()},300);

      this.$refs['ref_dialogForm'].openDialogWeb();

        this.$eventHub.$on('save', () => {
            this.$refs['ref_dialogForm'].fn_validateForm();
        });

        this.$eventHub.$on('escape', () => {
            this.fn_closeDialogForm();
        });

      // this.fn_setDraftData('Transaction', this.toolbarTitle);
    },
    computed:{
      debitTotal: function () {
        let totalAmount = 0;
        // let self = this;
        for(let i=0;i<this.Form.filteredItems.length;i++){
          if(this.Form.filteredItems[i].amountType == 1){
            
            totalAmount +=  this.Form.filteredItems[i].amount ? this.Form.filteredItems[i].amount : 0;
          }
        }
        return this.roundUpto2Decimals(totalAmount);
      },
      creditTotal: function () {
        let totalAmount = 0;
        // this.creditItemsLength = 0;
        // let self = this;
        for(let i=0;i<this.Form.filteredItems.length;i++){
          if(this.Form.filteredItems[i].amountType == 0){
            
            totalAmount +=  this.Form.filteredItems[i].amount ? this.Form.filteredItems[i].amount : 0;
          }
        }
        return this.roundUpto2Decimals(totalAmount);
      },
      isNew: function(){
        if(this.Form.transactionData.transactionId)
          return false;
        else
          return true;
      },
      splitPaymentTotal: function(){
        let splitPaymentTotal = 0;
        for(let i=0; i<this.Form.paymentOptions.length; i++){
          splitPaymentTotal += this.Form.paymentOptions[i].amount;
        }
        return this.roundUpto2Decimals(splitPaymentTotal);
      },
      debitItems: function(){
        if(this.Form.filteredItems.length){
          return this.Form.filteredItems.filter(item=>{
            return item.amountType === 1;
          })  
        }
        else{
          return [];
        }
      },
      creditItems: function(){
        if(this.Form.filteredItems.length){
          return this.Form.filteredItems.filter(item=>{
            return item.amountType === 0;
          })  
        }
        else{
          return [];
        }
      }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {

            let isCalled = false;

            // reloading form if connection got disconnected and reconnected again before loading all resources
            vm.$eventHub.$on('connected', () => {
                setTimeout(() => {
                  if(!isCalled){
                    isCalled = true;
                    vm.disableOnSave = false;
                    vm.saveBtnKey++;
                    if(!vm.parties || !vm.parties.length){
                      vm.fn_beforeRouteEnter(vm, to, from)
                      vm.fn_initializeSettings();
                    }
                  }

                  setTimeout(() => {
                      isCalled = false;
                  }, 3000);
                  
                }, 1000);
            });

            vm.fn_beforeRouteEnter(vm, to, from);

        })
    },
    beforeRouteLeave (to, from, next) {
        // this.$eventHub.$off('save');
        // this.$eventHub.$off('escape');
        // this.$eventHub.$off('newTransaction');
        // next();
        if(this.$root.viaKeyboardShortCut){
            this.nextRoute.path = to.path;
            this.nextRoute.query = to.query;
            this.fn_closeDialogForm();
        }
        else{
            this.nextRoute.path = null;
            this.nextRoute.query = null;
            this.$eventHub.$off('save');
            this.$eventHub.$off('escape');
            this.$eventHub.$off('newTransaction');
            this.$eventHub.$off('connected');
            next();
        }
    },
    // beforeRouteLeave (to, from, next) {
    //   var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
    //   if(dialogData && dialogData.length){

    //       if(this.backButtonPress){
    //         dialogjs.fn_checkAndCloseOpenDialogs(this);
    //         next(false);
    //       }
    //       else{
    //         localStorage.removeItem("SetDataDrive");
    //         // this.$root.fn_deleteFormObject();
    //         next(true);
    //       }
    //   }
    //   else{
    //     let showDialog = false;
    //     if(this.isNew){
    //       if(this.settings.transactionTypeId == transactionTypes.journal.id){
    //         showDialog = this.fn_showDialogDiscard() && this.Form.filteredItems.length ? true : false;
    //       }
    //       else{
    //         showDialog = this.fn_showDialogDiscard();
    //       }
    //     }
    //     else{
    //       showDialog = this.fn_showDialogDiscard();
    //     }


    //     if(showDialog){
    //       this.fn_openDialogDiscard();
    //       next(false);
    //     }
    //     else{
    //       this.fn_clearData('salesforms', function(){
    //         localStorage.removeItem("SetDataDrive");
    //         next();
    //       })
    //     }
    //   }
    // }
  }

</script>