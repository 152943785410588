export const dialogjs = new Object({
    fn_pushDialogInStack: function (dialogStorageId, dialogObj) {

        var dialogStack = [];
        let dialogData = JSON.parse(localStorage.getItem(dialogStorageId));

        let scrollPosition = {};
        scrollPosition.x = window.scrollX;
        scrollPosition.y = window.scrollY;

        !dialogObj.scrollPosition ? dialogObj.scrollPosition = scrollPosition : '';

        if(dialogData && dialogData.length){
            dialogData.push(dialogObj);
            localStorage.setItem(dialogStorageId, JSON.stringify(dialogData));
        }
        else{
            dialogStack.push(dialogObj);
            localStorage.setItem(dialogStorageId, JSON.stringify(dialogStack));
        }
        // self.disableToolbar();
    },

    fn_removeDialogFromStack: function (dialogStorageId, self) {
        // let formId = null;

        // if(self.formId)
        //  formId = self.formId;
        // else if(self.$parent.formId)
        //  formId = self.$parent.formId;

        // let dStorageId = null;

        // self.dialogStorageId ? dStorageId = self.dialogStorageId : dStorageId = 'dialog_'+formId;

        var dialogData = JSON.parse(localStorage.getItem(dialogStorageId));

        let scrollPos = null;
        let platform = null;
        
        if(dialogData){
            scrollPos = dialogData[dialogData.length-1].scrollPosition;
            platform = dialogData[dialogData.length-1].platform;

            dialogData.splice(dialogData.length-1, 1);
            localStorage.setItem(dialogStorageId, JSON.stringify(dialogData));
        }

        // !dialogData.length ? self.enableToolbar() : '';

        if(dialogData && !dialogData.length){
            // self.enableToolbar();

            localStorage.removeItem(dialogStorageId);
            // self.fn_removeFixBackgroundScroll();

            // setTimeout(function () {

                if(platform=='web'){
                    self.fn_removeFixBackgroundScroll(scrollPos.y);
                }
                
            // });
        }
        

    },

    // fn_openDialog: function (self, dialogStorage, dialogObj, viewObject, dialogPath, tagId, paramObject, ref, sucessCallback, errorCallBack, changeCallback, menuItemClickCallback) {
    //  dialogjs.fn_pushDialogInStack(dialogStorage, dialogObj);
    //  self.openDialog(viewObject, dialogPath, tagId, paramObject, ref, sucessCallback, errorCallBack, changeCallback, menuItemClickCallback);
    // },

    fn_openDialog: function (self, dialogObj, viewObject, dialogPath, tagId, paramObject, ref) {

        // //force save item as draft in case of edit.
        // //To save draft if user directly navigate to another instance of view.
        // if(self.$route.params && self.$route.params.id){
        //  self.fn_saveToLocalStorage();
        // }

        let dStorageId = null;

        self.dialogStorageId ? dStorageId = self.dialogStorageId : dStorageId = 'dialog_'+dialogObj.formId;

        dialogjs.fn_pushDialogInStack(dStorageId, dialogObj, self);

        self.openDialog(viewObject, dialogPath, tagId, paramObject, ref, 
            {
                onSuccess: function(response){
                    console.log(response);
                  
                }, 
                onError: function(error){

                    console.log(error);

                }, 
                onChange: function (tagId, ref, selectedValueId, selectedValueName, isRemoved) {

                    // tagId id form future use
                    self.fn_onChange(tagId, ref, selectedValueId, selectedValueName, isRemoved);

                    // self.fn_closeDialog(tagId);
                    !isRemoved ? dialogjs.fn_checkAndCloseOpenDialogs(self) : '';

                }, 
                onChangeMultiple: function (tagId, ref, selectedItemsId) {
                    
                    // tagId id form future use
                    self.fn_onChangeMultiple(tagId, ref, selectedItemsId);

                    dialogjs.fn_checkAndCloseOpenDialogs(self);
                    // !isRemoved ? dialogjs.fn_checkAndCloseOpenDialogs(self) : '';

                }, 
                onMenuItemClick: function (tagId, menuId, menuAction) {

                    self.fn_menuItemClick(tagId, menuId, menuAction);
                    
                }
            }
        );
    },

    fn_showOpenDialog: function (tagId, ref, self) {
        self.showDialog(tagId, ref, 
            {
                onSuccess: function(response){
                  console.log(response);
                  let status = JSON.parse(response);
                  !status ? dialogjs.fn_checkAndCloseOpenDialogs(self) : '';
                }, 
                onError: function(error){
                  console.log(error);
                  let status = JSON.parse(error);
                  !status ? dialogjs.fn_checkAndCloseOpenDialogs(self) : '';
                }, 
                onChange: function (tagId, ref, selectedValueId, selectedValueName, isRemoved) {
                    // tagId id form future use
                    self.fn_onChange(tagId, ref, selectedValueId, selectedValueName, isRemoved);

                    // dialogjs.fn_checkAndCloseOpenDialogs(self);
                    !isRemoved ? dialogjs.fn_checkAndCloseOpenDialogs(self) : '';

                }, 
                onChangeMultiple: function (tagId, ref, selectedItemsId) {
                    // tagId id form future use
                    self.fn_onChangeMultiple(tagId, ref, selectedItemsId);

                    dialogjs.fn_checkAndCloseOpenDialogs(self);
                    // !isRemoved ? dialogjs.fn_checkAndCloseOpenDialogs(self) : '';

                }, 
                onMenuItemClick: function (tagId, menuId, menuAction) {
                    self.fn_menuItemClick(tagId, menuId, menuAction);
                }
            }
        );
    },

    // fn_showDialog: function (self, tagId, sucessCallback, errorCallBack, changeCallback, menuItemClickCallback) {
    //  self.showDialog(tagId, sucessCallback, errorCallBack, changeCallback, menuItemClickCallback)
    // },

    fn_checkAndCloseOpenDialogs: function (self) {

        let formId = null;

        if(self.formId)
            formId = self.formId;
        else if(self.$parent.formId)
            formId = self.$parent.formId;

        let dStorageId = null;

        self.dialogStorageId ? dStorageId = self.dialogStorageId : dStorageId = 'dialog_'+formId;
        var dialogData = JSON.parse(localStorage.getItem(dStorageId));

        if(dialogData && dialogData.length){
            var lastopen = dialogData[dialogData.length-1];
            if(lastopen.formId == formId){
                dialogjs.fn_closeDialog(self, lastopen.tagId, lastopen);
            }
        }
    },

    fn_checkAndShowOpenDialogs: function (self) {
        // var dialogData = JSON.parse(localStorage.getItem(self.dialogStorageId));

        let formId = null;

        if(self.formId)
            formId = self.formId;
        else if(self.$parent.formId)
            formId = self.$parent.formId;

        let dStorageId = null;

        self.dialogStorageId ? dStorageId = self.dialogStorageId : dStorageId = 'dialog_'+formId;
        var dialogData = JSON.parse(localStorage.getItem(dStorageId));

        if(dialogData && dialogData.length){
            var lastopen = dialogData[dialogData.length-1];
            if(lastopen.formId == formId){

                // self.disableToolbar();

                if(lastopen.platform=='web'){
                    if(self.dialogs){
                        self.dialogs[lastopen.ref] = true;
                    }
                    else{
                        self.$refs[lastopen.ref] ? self.$refs[lastopen.ref].openDialogWeb() : '';
                    }
                }
                else{
                    dialogjs.fn_showOpenDialog(lastopen.tagId, lastopen.ref, self);
                    // self.fn_showDialogAndroid(lastopen.tagId, lastopen.ref);
                }
            }

        }
    },

    fn_closeDialog: function (self, tagId, lastopen) {
        let dStorageId = null;

        let formId = null;

        if(self.formId)
            formId = self.formId;
        else if(self.$parent.formId)
            formId = self.$parent.formId;

        self.dialogStorageId ? dStorageId = self.dialogStorageId : dStorageId = 'dialog_'+formId;

        if(lastopen.platform == 'web'){
            // self.fn_regainScrollPosition();  

            // self.fn_removeFixBackgroundScroll();

            if(self.dialogs){
                self.dialogs[lastopen.ref] = false;
            }
            else{
                self.$refs[lastopen.ref].closeDialogWeb();
            }

            // if(lastopen.ref=='ref_dialogParticular'){
            //  setTimeout(function () {
            //      self.showToolBar();
            //  },100);
            // }

            dialogjs.fn_removeDialogFromStack(dStorageId, self);

            //check for more open dialogs
            dialogjs.fn_checkAndShowOpenDialogs(self);
        }
        else{
            self.closeDialog(tagId, 
                {
                    onSuccess: function(response){
                        //will recieve "false" in response if tagId or ref not found that case can occur when app is close while android dialog is opened.
                      console.log(response);
                      // response == 1 ? dialogjs.fn_removeDialogFromStack(self.dialogStorageId) : '';
                      dialogjs.fn_removeDialogFromStack(dStorageId, self);

                      //check for more open dialogs
                        dialogjs.fn_checkAndShowOpenDialogs(self);
                    }, 
                    onError: function(error){
                      console.log(error);
                    }
                }
            );
        }
    },

    // fn_fixBackgroundScroll: function () {
    //     // let scroll_y = window.scrollY;
    //     // let parentContainer = document.getElementsByClassName('parent-container')[0];

    //     document.getElementsByTagName("body")[0].classList.add("noscroll");

    //     // parentContainer.classList.add("scrollauto-y");

    //     // parentContainer.scrollTop = scroll_y;
    // },

    // fn_removeFixBackgroundScroll: function (spy) {
    //     // let parentContainer = document.getElementsByClassName('parent-container')[0];

    //     document.getElementsByTagName("body")[0].classList.remove("noscroll");
    //     // parentContainer.classList.remove("scrollauto-y");

    //     // let scroll_y = 0;
    //     // spy ? scroll_y = spy : '';

    //     // setTimeout(function() {
    //     //   window.scrollTo(0, scroll_y);
    //     // },100);
    // }


})