<template>
    <div grey-back full-height>

        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

    </div>
</template>

<script>

import dialogForm from './dialogs/dialog_form'
// import dialogDraftItems from '../../../dialogs/dialog_draft_items'


    export default{
        // mixins: [draftMixin],
        data(){
            return{
                Form:{"taxCategoryId":null, "name":null, "taxValue":null, "isCessApplicable":false, "cessValue":null, "isSystem":0, "draftItems":[], "createdDate":null, "modifiedDate":null},

                loading:false,
                tagId:null,
                dialogStorageId:null,
                draftStorageId:null,

                backButtonPress:false,

                snackbar:false,
                snackbar_msg:null,

                unwatch:false,
                nextRoute:{"path":null, "query":null},
                disableOnSave:false
                // loadedFromDraft:false
            }
        },
        // watch:{
        //  'Form': {
  //               handler: function (newVal, oldVal){
  //                   if(!this.unwatch){
  //                        this.fn_saveDraft(this.Form.name);
  //                    }
  //                    this.unwatch = false;
  //               },
  //               deep: true
  //           }
        // },
        components:{
            'dialog-form':dialogForm
            // 'dialog-discard': dialogDiscard,
            // 'dialog-draft-items': dialogDraftItems
        },
        methods:{

            fn_closeDialogForm: function(){
                this.fn_goBack();
             },

            // fn_openDialogDiscard: function(){
            //  this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
            // },

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_closeDialog(){
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.save, menuItem.reset, menuItem.help]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            fn_checkValidations: function(){
                this.fn_post();
            },
            
            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('TaxCategory', dataObj, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          let status = JSON.parse(response);
                          if(status) {
                            self.fn_cleanUp();
                          }
                          else {
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            self.$eventHub.$emit('snackbarAlert', {msg:'Already exists with this name'});
                          }
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                        }
                    }
                );
            },

            fn_cleanUp: function () {

                // this.loadedFromDraft = false;

                if(this.$route.query.ls){
                    let data = {};
                    data.type = 'taxCategory';
                    data.taxCategoryId = this.Form.taxCategoryId;
                    data.taxCategoryName = this.Form.name;
                    data.taxValue = this.Form.taxValue;
                    data.cessValue = this.Form.cessValue;
                    data.isCessApplicable = this.Form.isCessApplicable;
                    // data.itemIndex = this.$route.query.itemIndex;
                    data.itemGroupId = this.$route.query.itemGroupId;
                    localStorage.setItem('newItem', JSON.stringify(data));
                    // localStorage.setItem('closeDialog', 1);
                }

                this.$eventHub.$emit('UpdateTaxCategoryList', {});
                this.fn_goBack();
            },
            
            fn_post: function(){

                // !this.Form.taxCategoryId ? this.Form.taxCategoryId = this.guid() : '';
                if(!this.Form.taxCategoryId){
                    this.Form.taxCategoryId = this.guid();
                    // this.Form.createdDate = new Date();
                    this.Form.createdDate = this.$root.fn_getLocalDateFormat();
                }

                // this.Form.modifiedDate = new Date();
                this.Form.modifiedDate = this.$root.fn_getLocalDateFormat();
                
                this.Form.name = this.Form.name.trim();
                !this.Form.isCessApplicable ? this.Form.cessValue = null : '';

                console.log(this.Form);
                this.fn_saveLocal(this.Form);
            },

            fn_get: function(itemId, copyData){
                var self = this;
                this.getData('TaxCategory', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.unwatch = true;
                            self.Form = JSON.parse(response);
                            
                            if(copyData){
                                self.Form.taxCategoryId = null;
                                self.Form.isSystem = 0;
                    //              self.snackbar = true;
                                // self.snackbar_msg = "Data copied."
                                // alert("Tax Category copied");
                                self.$eventHub.$emit('snackbarAlert', {msg:'Tax Category copied'});
                            }

                            self.$root.fn_copyFormObject(self.Form, self.formId);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },
            
            fn_clearForm: function(){
                this.Form = {"taxCategoryId":null, "name":null, "taxValue":null, "isCessApplicable":false, "cessValue":null, "isSystem":0, "draftItems":[], "createdDate":null, "modifiedDate":null};
                this.$validator.reset();
            },
            
            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  this.backButtonPress = false;

            //  if(menuAction=='save'){
            //      this.fn_checkValidations();
            //  }
            //  else if(menuAction=='reset'){
            //      this.fn_resetForm();
            //  }
            // },

            // fn_resetForm: function () {
            //  this.unwatch = true;
            //  // this.loadedFromDraft = false;

            //  if(this.Form.taxCategoryId){
            //      this.fn_get(this.Form.taxCategoryId);
            //  }
            //  else{
            //      this.fn_clearForm();
            //  }
                
            //  let self = this;
            //  this.fn_removeDraft(this.draftStorageId, function(){
            //      console.log("draft deleted");
            //      // self.showToast("Item deleted from draft");
            //  });
            // },
            
            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.ToolbarTitle = "Tax Category";
            //  viewObject.TopMenu = [menuItem.save];
            //  viewObject.isBottomMenu = true;
            //  var tagId = this.formId;
            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null,
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },
            
            fn_goBack: function(){
                this.$root.viaKeyboardShortCut = false;
                this.$refs['ref_dialogForm'].closeDialogWeb();
                this.backButtonPress = true;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            }
        },
        mounted: function(){

            // var self = this;

            if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                // this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                // this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }

            // this.dialogStorageId = 'dialog_'+this.formId;

            

            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });

        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                // vm.enableToolbar();
                
                let itemId = null;
                let copyData = false;

                // to.query && to.query.copy && from.name == 'itemlist' ? copyData = true : '';
                to.query && to.query.copy ? copyData = true : '';

                // if(to.query && to.query.itemId && from.name == 'itemlist'){
                //     itemId = to.query.itemId;
                // }
                if(to.query && to.query.itemId){
                    itemId = to.query.itemId;
                }

                if(to.params && to.params.tagId){//from display view
                    itemId = to.params.tagId;
                }

                if(itemId){
                    vm.fn_get(itemId, copyData);
                }
                else{
                    vm.$root.fn_copyFormObject(vm.Form, vm.formId);                 
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            itemId ? vm.fn_get(itemId, copyData) : '';
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });

            })
        },
        beforeRouteLeave (to, from, next) {
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('connected');
                next();
            }
        },
        // beforeRouteUpdate (to, from, next) {

        //  this.enableToolbar();
            
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  var existingForm = false;
        //  if(dialogData && dialogData.length){
        //      var openForms = JSON.parse(localStorage.getItem('taxcategoryforms'));
        //      for(var i=0; i<openForms.length; i++){
        //          if(openForms[i]==to.query.tagId){
        //              existingForm = true;
        //              dialogjs.fn_checkAndCloseOpenDialogs(this);
        //              break;
        //          }
        //      }
        //      existingForm ? next(false) : next(true);
        //  }
        //  else{
        //      next();
        //  }
     //    },
        // beforeRouteLeave (to, from, next) {
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  if(dialogData && dialogData.length){

        //      if(this.backButtonPress){
        //          dialogjs.fn_checkAndCloseOpenDialogs(this);
        //          next(false);
        //      }
        //      else{
        //          // this.$root.fn_deleteFormObject();
        //          this.fn_saveDraft(this.Form.name);
        //          next(true);
        //      }
        //  }
        //  else{
        //      if(this.fn_showDialogDiscard()){
        //          this.fn_openDialogDiscard();
        //          next(false);
        //      }
        //      else{
        //          this.fn_clearData('taxcategoryforms', function(){
        //              next();
        //          })
        //      }
        //      // if(this.loadedFromDraft || (this.$root.fn_getFormObject(this.formId) && !this.$root.fn_deepEqual(this.Form, JSON.parse(this.$root.fn_getFormObject(this.formId))))){
        //      //  this.fn_openDialogDiscard();
        //      //  next(false);
        //      // }
        //      // else{
        //      //  this.$root.fn_deleteFormObject(this.formId);
        //      //  !this.askToDiscard ? this.fn_removeDraft(this.draftStorageId, null, -1) : '';
        //      //  localStorage.removeItem('taxcategoryforms');
        //      //  next(); 
        //      // }
        //  }
     //    }

    }

</script>