<template>
    <div grey-back full-height v-scroll="onScroll">

        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>

        <dialog-confirmation ref="ref_dialogConfirmation" header="Batch Alert!" confirmationMessage="<div dark-opacity-color>This item consists batch.<br>Please edit this item in BizOps App on mobile</div>"  :actionMethod="fn_closeDialogConfirmation" :cancelMethod="fn_closeDialogConfirmation" :displayView='true' okBtnText="Okay"></dialog-confirmation>

        <!-- <dialog-drive-alert ref="ref_dialogDriveAlert" :gotoSetupDrive="fn_gotoSetupDrive"></dialog-drive-alert> -->

    </div>
</template>

<script>
// const productGroupTypeId = require('../../../../config.js').configs.productGroupTypeId;
const transactionData = require('../../datamodel').data.transactionData;


import {draftMixin} from '../../../draft_mixin'
import {transactionMixin} from '../../transaction_mixin'

import dialogForm from './dialogs/dialog_form'
import dialogDiscard from '../../../dialogs/dialog_discard'

import dialogConfirmation from '../../../dialogs/dialog_confirmation'

// const transactionTypes = require('../../../../config.js').configs.transactionTypes;

    export default{
        props:['settings'],
        mixins: [draftMixin, transactionMixin],
        data(){
            return{

                Form:{
                    "transactionData":JSON.parse(JSON.stringify(transactionData)),
                    "transactionProducts":[],
                    "trProducts":[],

                    "productRefId":null,
                    "itemName":null,
                    "itemId":null,

                    // "deletedTransactionProductIds":[],
                    "summary":'',
                    "summaryArray":[],
                    "isNew":true,
                    "selectedProduct":null
                    // "maxOpeningDate":null,
                    // "openingDate":null,
                    // "minDate":null
                },

                transactionProductModel:{transactionProductId:null, transactionId:null, productId :null, productVariantId:null, transactionProductMode:1, mrp:null, rate:null, taxableValue:null, additionalData:{}, groupId:this.guid(), variants:[]},

                variantObj:{label:null, unitId:null, unitName:null, packQuantity:null, qty:null, amount:null, productId:null, productVariantId:null},


                loading:false,
                tagId:null,
                draftStorageId:null,
                dialogStorageId:null,

                backButtonPress: false,

                snackbar:false,
                snackbar_msg:null,

                unwatch:false,

                // loadedFromDraft:false,

                showFooter:true,
                offsetTop:0,

                // maxOpeningDate:null,
                // openingDate:null,
                // minDate:null,
                disableBtn:false,
                products:null,
                disableOnSave:false,
                batches:null
            }
        },
        components:{
            'dialog-form':dialogForm,
            'dialog-discard': dialogDiscard,
            'dialog-confirmation': dialogConfirmation
        },
        watch:{
            'Form': {
                handler: function (){
                     if(!this.unwatch){
                        this.fn_saveDraft(this.draftStorageId, this.Form.itemName);
                     }
                     this.unwatch = false;
                 },
                 deep: true
            }
        },
        methods:{

            fn_products: function(val){
              let filterObject = {};
              filterObject.search = val ? val.toLowerCase() : '';

              filterObject.groupTypeId = 101;
              filterObject.inventory = 1;
              
              let self = this;
              this.getDialogItems("ProductDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    self.products = JSON.parse(response);
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_closeDialogForm: function(){
                this.askToDiscard = true;
                if(this.fn_showDialogDiscard()){
                    this.$refs['ref_dialogDiscard'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            fn_focusItem: function (event) {
                let self = this;
                setTimeout(function () {
                    self.$vuetify.goTo(event.target, self.scrollOptions);
                },300);
            },

            onScroll: function () {
                this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
            },

            fn_removeItem: function (tIndex) {
                this.Form.trProducts.splice(tIndex, 1);
            },

            fn_openProductsDialog: function () {
                //force save to local storage
                // this.fn_saveDraft(this.Form.itemName);
                this.fn_openProductList('android', 'ProductList', 'ref_fieldProductName');
            },

            // fn_openProductList: function (platform, name, ref) {

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     //dialogObj.data = null;
            //     dialogObj.platform = platform;


            //     // var self = this;

            //     var viewDialogObject = {};
            //     viewDialogObject.ToolbarTitle = "Select Item";
            //     viewDialogObject.groupTypeId = productGroupTypeId.product;
            //     viewDialogObject.TopMenu = [menuItem.new];
            //     viewDialogObject.isBottomMenu = false;
            //     viewDialogObject.hideChip = true;
            //     viewDialogObject.inventory = 1;

            //     var paramObject = {};
            //     paramObject.defaultValue = {"id":null, "name":null};

            //     if(this.Form.itemId && this.Form.itemName){
            //         paramObject.defaultValue = {"id":this.Form.itemId, "name":this.Form.itemName};
            //     }

            //     var self = this;
            //     dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'product', tagId, paramObject, ref);
            // },

            fn_updateProduct: function (selectedValueId, selectedValueName, isRemoved) {
                if(!isRemoved){
                    this.Form.itemId = selectedValueId;
                    this.Form.itemName = selectedValueName;
                    this.errors.remove('item');
                    this.fn_getData('TransactionProductsOfProduct', selectedValueId);
                }
                else{
                    this.Form.itemId = null;
                    this.Form.itemName = null;
                    this.Form.productRefId = null;
                    this.Form.summaryArray = [];
                    // this.fn_clearForm(this.Form.openingDate, this.Form.maxOpeningDate, this.Form.minDate);
                    this.fn_clearForm();
                }
                this.$forceUpdate();
            },

            fn_getBatches: function(productId){
				let self = this;
				this.getData('GetProductBatchByProductId', productId, 
					{
						onSuccess: function(response){
							console.log(response);
							self.batches = JSON.parse(response);
                            if(self.batches && self.batches.length){
                                self.$refs['ref_dialogConfirmation'].dialog_confimation = true;
                                return;
                            }
						}, 
						onError: function(error){
							console.log(error);
						}
					}
				);
			},

            fn_closeDialogConfirmation: function(){
                this.fn_goBack();
                this.$refs['ref_dialogConfirmation'].dialog_confimation = false;
            },

            fn_itemTotal: function (item, rate) {
                let packQty = 1;

                item.packQuantity ? packQty = item.packQuantity : '';

                if(!item.qty || !rate)
                    item.amount = 0;
                else{
                    let amount = item.qty * packQty * rate;
                    item.amount = this.roundUpto2Decimals(amount);
                }
            },

            fn_addMore: function () {
                this.transactionProductModel.groupId = this.guid();
                let item = JSON.parse(JSON.stringify(this.transactionProductModel));
                this.Form.trProducts.push(item);
            },

            fn_clearModel: function () {
                this.transactionProductModel = {"transactionProductId":null, "transactionId":null, "productId" :null, "productVariantId":null, "mrp":null, "rate":null, "taxableValue":null, "additionalData":{}, "variants":[]};
            },

            // fn_getProductData: function (itemId, refreshUnits) {
            fn_getProductData: function (refreshUnits) {
                var self = this;
                this.getData('Product', this.Form.selectedProduct.itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);

                            let productData = JSON.parse(response);

                            if(refreshUnits){
                                self.fn_refreshUnits(productData.productVariants);
                            }
                            else{
                                self.Form.productRefId = productData.productId;
                                self.fn_bindProduct(productData);
                            }

                            self.fn_getSummary();
                            self.fn_getBatches(productData.productId);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },


            fn_refreshUnits: function (productVariants) {

                // let self = this;

                //adding/updating default variants values
                this.fn_pushVariants(productVariants);

                if(productVariants.length > this.Form.summaryArray.length){
                    this.fn_refreshSummary(productVariants);
                }

                let trp = this.Form.trProducts;

                // let variantsToAdd = [];

                for(let i=0; i<trp.length; i++){

                    let variants = JSON.parse(JSON.stringify(trp[i].variants));

                    if(variants.length != productVariants.length){

                        for(var j in productVariants){
                            var shared = false;

                            for (var k in variants){
                                if (variants[k].productVariantId == productVariants[j].productVariantId) {
                                   shared = true;
                                   break;
                               }
                            }
                               
                            if(!shared){
                                let variantObj = {};

                                let uName = productVariants[j].unitName;
                                let pQty = productVariants[j].packQuantity

                                variantObj.unitName = productVariants[j].unitName;
                                variantObj.unitId = productVariants[j].unitId;
                                variantObj.primaryUnitId = productVariants[i].variantBaseUnitId;
                                variantObj.packQuantity = productVariants[j].packQuantity;
                                variantObj.productId = productVariants[j].productId;
                                variantObj.productVariantId = productVariants[j].productVariantId;

                                variantObj.qty = null;
                                variantObj.amount = null;

                                this.fn_getLabel(uName, pQty, function (label) {
                                    variantObj.label = label;
                                    trp[i].variants.splice(j, 0, variantObj);
                                });
                            } 
                        }

                    }
                }

            },

            fn_refreshSummary: function (productVariants) {

                var summaryArray = JSON.parse(JSON.stringify(this.Form.summaryArray));

                for(var i in productVariants){
                    var shared = false;
                    for (var j in summaryArray){
                        if (summaryArray[j].unitId == productVariants[i].unitId) {
                           shared = true;
                           break;
                        }
                    }
                    if(!shared){
                        let obj = {};
                        obj.unitId = productVariants[i].unitId;
                        obj.unitName = productVariants[i].unitName;
                        obj.qty = 0;
                        this.Form.summaryArray.splice(i, 0, obj);
                    } 
                }

            },

            fn_bindProduct: function (productData) {
                this.Form.trProducts = [];
                this.transactionProductModel.variants = [];
                
                // let mrp = null;
                let self = this;

                //adding/updating default variants values
                this.fn_pushVariants(productData.productVariants, function () {
                    let item = JSON.parse(JSON.stringify(self.transactionProductModel));
                    self.Form.trProducts.push(item);
                });

                self.Form.trProducts[0].mrp = productData.mrp;

                productData.productVariants.filter(item=>{
                    let obj = {};
                    obj.unitId = item.unitId;
                    obj.unitName = item.unitName;
                    obj.qty = 0;
                    self.Form.summaryArray.push(obj);
                })
            },

            fn_pushVariants: function (variants, callback) {
                let mrp = null;
                this.transactionProductModel.variants = [];

                let self = this;
                for(let i=0;i<variants.length;i++){

                    let variantObj = {};

                    variants[i].isDefault ? mrp = variants[i].mrp : '';

                    let uName = variants[i].unitName;
                    let pQty = variants[i].packQuantity;

                    this.fn_getLabel(uName, pQty, function (label) {

                        variantObj.label = label;

                        variantObj.unitName = variants[i].unitName;
                        variantObj.unitId = variants[i].unitId;
                        variantObj.primaryUnitId = variants[i].variantBaseUnitId;
                        variantObj.packQuantity = variants[i].packQuantity;
                        variantObj.productId = variants[i].productId;
                        variantObj.productVariantId = variants[i].productVariantId;

                        variantObj.qty = null;
                        variantObj.amount = null;

                        self.transactionProductModel.variants.push(variantObj);

                    });
                }

                if(callback!=undefined){
                    callback(mrp);
                }
            },

            fn_getLabel: function (uName, pQty, cb) {
                let label = null
                if(pQty){
                    label = uName + ' ('+pQty+')';
                }
                else{
                    label = uName;
                }

                cb(label);
            },


            fn_checkValidations: function(){
                this.disableBtn = true;
                let quantityFilled = false;
               
                for(let i=0;i<this.Form.trProducts.length;i++){
                    
                    this.Form.trProducts[i].quantityFilled = false;

                    let tP = this.Form.trProducts[i];
                    for(let j=0;j<tP.variants.length;j++){
                        if(tP.variants[j].qty){
                            quantityFilled = true;
                            this.Form.trProducts[i].quantityFilled = true;
                        }
                    }

                }

                if(!quantityFilled){
                    // alert('Please add quantity');
                    this.$eventHub.$emit('snackbarAlert', {msg:"Please add quantity"});
                    this.disableBtn = false;
                    this.saveBtnKey++;
                    this.disableOnSave = false;
                    // this.hideLoading();
                    return;
                }
                else{
                    this.fn_post();
                }
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.loading = true;
                this.postData('TransactionProducts', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.loading = false;
                            // self.fn_setMinTransactionDate();
                            self.fn_cleanUp();
                        },
                        onError: function(error){
                            console.log(error);
                            self.saveBtnKey++;
                            self.disableOnSave = false;
                            self.loading = false;
                            // self.hideLoading();
                            let response = JSON.parse(error);

                            if(response && response.openingStockExistsError){
                                // alert("Opening already added.");
                                self.$eventHub.$emit('snackbarAlert', {msg:"Opening already added"});
                            }   
                        }
                    }
                );
            },

            fn_cleanUp: function () {
                // let self = this;
                // this.fn_removeDraft(this.draftStorageId, function(){
                //  self.hideLoading();
                //  self.$router.go(-1);
                // });
                localStorage.removeItem(this.draftStorageId);
                    this.$eventHub.$emit('UpdateOpeningStockList', {});
                    this.fn_goBack();
                },


            fn_post: function(){

                //only in case of new transaction
                if(!this.Form.transactionData.transactionId){
                    this.Form.transactionData.transactionId = this.guid();
                    // this.Form.transactionData.createdDate = new Date();
                    this.Form.transactionData.createdDate = this.$root.fn_getLocalDateFormat();
                    //random transaction number just for now
                    // this.Form.transactionData.transactionNumber = 'TN/'+this.randomFourCharString();
                    this.Form.transactionData.transactionListId = this.settings.transactionTypeId;
                }

                // this.Form.transactionData.modifiedDate = new Date();
                this.Form.transactionData.modifiedDate = this.$root.fn_getLocalDateFormat();

                this.Form.transactionData.amount = this.totalAmount;
                this.Form.transactionData.roundedAmount = Math.round(this.totalAmount);
                this.Form.transactionData.status = 1;
                this.Form.transactionData.refDate = null;

                //Calculation of opening ledger date
                // let currentDate = new Date();
                // let currentMonth = currentDate.getMonth() + 1;
                // let currentYear = currentDate.getFullYear();

                // let openingStockDate = null
                // openingStockDate = currentMonth > 3 ? currentYear : currentYear-1;

                // this.Form.transactionData.transactionDate = new Date(openingStockDate+"-03-31");

                // let od = new Date(JSON.parse(JSON.stringify(this.openingDate)));
                let od = new Date(JSON.parse(JSON.stringify(this.$root.CurrentBusiness.OpeningDate)));
                od.setDate(od.getDate()-1);
                // this.Form.transactionData.transactionDate = new Date(od);
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(od);

                console.log(JSON.stringify(this.Form.trProducts));

                this.Form.transactionProducts = [];

                for(let i=0; i<this.Form.trProducts.length; i++){
                    let tP = this.Form.trProducts[i];

                    for(let j=0; j<tP.variants.length; j++){

                        let variant = tP.variants[j];

                        let tpObj = {};

                        if(variant.qty){

                            !variant.transactionProductId ? tpObj.transactionProductId = this.guid() : tpObj.transactionProductId = variant.transactionProductId;

                            tpObj.transactionId = this.Form.transactionData.transactionId;

                            tpObj.productId = variant.productId;

                            tpObj.productVariantId = variant.productVariantId;

                            tpObj.groupId = tP.groupId;

                            // tpObj.mrp = tP.mrp ? tP.mrp : null;

                            // tpObj.rate = tP.rate ? tP.rate : null;

                            tpObj.mrp = tP.mrp ? tP.mrp : 0;

                            tpObj.rate = tP.rate ? tP.rate : 0;

                            tpObj.additionalData = null;

                            tpObj.unitId = variant.unitId;

                            tpObj.primaryUnitId = variant.primaryUnitId;

                            tpObj.qty = variant.qty;

                            tpObj.packQuantity = variant.packQuantity ? variant.packQuantity : 1;

                            tpObj.transactionProductMode = 1;

                            //saving actual quantity in positive and negative in inventory products in the form of qty
                            tpObj.actualQty = variant.qty;

                            tpObj.amount = variant.amount;

                            tpObj.taxableValue = variant.amount;

                            this.Form.transactionProducts.push(tpObj);

                        }

                    }

                }

                let postObj = {};

                postObj = this.Form.transactionData;
                postObj.transactionProducts = this.Form.transactionProducts;

                let currentAssetsAddOn = {};
                postObj.currentAssetsTransactions = [];
                currentAssetsAddOn.currentAssetsTransactionId = this.guid();
                // currentAssetsAddOn.transactionId = this.Form.transactionData.transactionId;
                // currentAssetsAddOn.value = this.Form.transactionData.roundedAmount;
                // currentAssetsAddOn.date = this.Form.transactionData.createdDate;
                // currentAssetsAddOn.transactionListId = this.Form.transactionData.transactionListId;
                postObj.currentAssetsTransactions.push(currentAssetsAddOn);
                postObj.sortDate = this.$moment(this.Form.transactionData.transactionDate).format('YYYY-MM-DD');
                // postObj.openingDate = this.Form.openingDate;
                console.log(this.Form);
                this.fn_saveLocal(postObj);
            },

            fn_getData: function (type, itemId) {
                
                this.Form.isNew = false;
                
                let filterObject = {};
                filterObject.type = this.settings.transactionTypeId;

                let self = this;
                this.getFilterData(type, itemId, filterObject, null, 
                    {
                        onSuccess: function(response){
                            console.log(response);

                            if(response){
                                let item = JSON.parse(response);
                                self.fn_bindData(item);
                            }
                            else{
                                // self.fn_clearForm(self.Form.openingDate, self.Form.maxOpeningDate, self.Form.minDate);
                                self.fn_clearForm();
                                type=='TransactionProductsOfProduct' ? self.fn_getProductData() : '';
                            }

                        }, 
                        onError: function(error){
                            console.log(error);
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                            self.fn_goBack();
                        }
                    }
                );
            },

            fn_getSummary: function () {
                var self = this;
                this.getData('ProductSummary', this.Form.selectedProduct.itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let str = '';
                            self.Form.summaryArray = JSON.parse(response);
                            
                            console.log(str);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_bindData: function (item) {
                this.unwatch = true;
                this.Form.transactionData.amount = item.amount;
                this.Form.transactionData.createdDate = item.createdDate;
                this.Form.transactionData.modifiedDate = item.modifiedDate;
                this.Form.transactionData.roundedAmount = item.roundedAmount;
                this.Form.transactionData.status = item.status;
                this.Form.transactionData.transactionDate = item.transactionDate;
                this.Form.transactionData.transactionId = item.transactionId;
                this.Form.transactionData.transactionListId = item.transactionListId;
                // this.Form.transactionData.transactionNumber = item.transactionNumber;
                this.Form.transactionData.notes = item.notes;

                this.Form.productRefId = item.productId;
                // this.Form.itemId = item.productId;
                // this.Form.itemName = item.productName;

                this.Form.selectedProduct = {"itemId":item.productId, "itemName":item.productName};

                this.fn_getSummary(item.productId);
                this.fn_getBatches(item.productId);

                if(item.transactionProducts.length > 1){
					let averageRateSum = 0;
					let totalQty = 0;
					for(let i=0; i<item.transactionProducts.length; i++){
						// average rate per piece
						let averageRate =  item.transactionProducts[i].variants[0].qty / item.transactionProducts[i].rate;
						// average rate per piece of all transaction products
						averageRateSum = averageRateSum + averageRate
						// total quantity
						totalQty = totalQty + Number(item.transactionProducts[i].variants[0].qty);
					}
					item.transactionProducts[0].rate = this.roundUpto2Decimals(averageRateSum * totalQty);
					item.transactionProducts[0].variants[0].rate = this.roundUpto2Decimals(averageRateSum * totalQty);
					item.transactionProducts[0].variants[0].qty = totalQty;
					let temp = JSON.parse(JSON.stringify(item.transactionProducts[0]));
					item.transactionProducts = [];
					item.transactionProducts.push(temp);
				}

                this.Form.trProducts = JSON.parse(JSON.stringify(item.transactionProducts));

                this.fn_bindLabels(this.Form.trProducts);

                //adding/updating default variants values
                this.fn_pushVariants(item.variants);

                let self = this;
                setTimeout(function () {
                    self.$root.fn_copyFormObject(self.Form, self.formId);
                    let htmlElement = document.getElementsByClassName(self.$route.query.groupId)[0];
                    htmlElement ? self.$vuetify.goTo(htmlElement, self.scrollOptions) : '';
                },300);
            },

            fn_bindLabels: function (items) {
                let self = this;
                for(let i = 0; i < items.length; i++){
                    for(let j = 0; j < items[i].variants.length; j++){
                        (function (i, j) {
                            let uName = items[i].variants[j].unitName;
                            let pQty = items[i].variants[j].packQuantity;
                            self.fn_getLabel(uName, pQty, function (label) {
                                items[i].variants[j].label = label;
                            });
                        }(i, j))
                    }
                }
            },

            // fn_clearForm: function(openingDate, maxOpeningDate, minDate){
            fn_clearForm: function(){
                this.Form.transactionData = JSON.parse(JSON.stringify(transactionData));
                this.Form.transactionProducts = [];
                this.Form.trProducts = [];
                // this.Form.deletedTransactionProductIds = [];
                this.Form.summary = '';
                this.Form.summaryArray = [];
                // this.Form.openingDate = openingDate ? openingDate : null
                // this.Form.maxOpeningDate = maxOpeningDate ? maxOpeningDate : null,
                // this.Form.minDate = minDate ? minDate : null,

                // this.Form.draftItems = [];
                // this.$validator.reset();
            },

            fn_setNewItem: function(){
                if(localStorage.getItem('newItem')){

                    let itemData = JSON.parse(localStorage.getItem('newItem'));

                    switch (itemData.type) {
                        case "product":
                            // this.fn_updateProduct(itemData.id, itemData.name, false);
                            this.Form.selectedProduct = {"itemId":itemData.id, "itemName":itemData.name};
                            this.fn_getData('TransactionProductsOfProduct', itemData.id);
                            break;
                        default:
                            console.log("condition not matched");
                    }

                    localStorage.removeItem('newItem');
                }

                this.Form.isNew ? this.$root.fn_copyFormObject(this.Form, this.formId) : '';
            },

            fn_goBack: function(){
                this.$refs['ref_dialogForm'].closeDialogWeb();
                localStorage.removeItem(this.draftStorageId);
                this.$root.viaKeyboardShortCut = false;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            }
        },
        mounted: function(){

            var self = this;

            if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                this.draftStorageId = 'cache_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                this.draftStorageId = 'cache_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }

            // !this.$root.CurrentBusiness.DataDrive ?  this.fn_checkTransactionCount() : '';

            if(localStorage.getItem(this.draftStorageId)){
                let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
                this.Form = JSON.parse(dataObj.formData);
            }

            setTimeout(function(){self.fn_setNewItem()},300);

            this.fn_products();

            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('select_item', () => {
                let element = document.getElementById("fieldproducts");
                element.focus();
                element.click();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });

            // this.fn_setDraftData('temp', "Opening Stock");

            // this.fn_checkOpening(transactionTypes.openingStock.id, transactionTypes.openingLedger.id);
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {

                let itemId = null;

                if(to.params && to.params.tagId){//from display view
                  itemId = to.params.tagId;
                }

                if(itemId){
                    vm.fn_getData('TransactionProducts', itemId);
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            vm.loading = false;
                            !vm.products ? vm.fn_products() : '';
                            itemId ? vm.fn_getData('TransactionProducts', itemId) : '';
                        }
                    }, 1000);

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);
                });

            })
        },
        beforeRouteLeave (to, from, next) {
            // this.$eventHub.$off('save');
            // this.$eventHub.$off('select_item');
            // this.$eventHub.$off('escape');
            // next();
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('select_item');
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('connected');
                next();
            }
        },
        // beforeRouteUpdate (to, from, next) {
        //  this.enableToolbar();
            
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  var existingForm = false;
        //  if(dialogData && dialogData.length){
        //      var openForms = JSON.parse(localStorage.getItem('openingstockforms'));
        //      for(var i=0; i<openForms.length; i++){
        //          if(openForms[i]==to.query.tagId){
        //              existingForm = true;
        //              dialogjs.fn_checkAndCloseOpenDialogs(this);
        //              break;
        //          }
        //      }
        //      existingForm ? next(false) : next(true);
        //  }
        //  else{
        //      next();
        //  }
     //    },
     //    beforeRouteLeave (to, from, next) {
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  if(dialogData && dialogData.length){

        //      if(this.backButtonPress){
        //          dialogjs.fn_checkAndCloseOpenDialogs(this);
        //          this.backButtonPress = false;
        //          next(false);
        //      }
        //      else{
        //          localStorage.removeItem("SetDataDrive");
        //          next(true);
        //      }
        //  }
        //  else{
        //      if(this.fn_showDialogDiscard()){
        //          this.fn_openDialogDiscard();
        //          next(false);
        //      }
        //      else{
        //          localStorage.removeItem('openingstockforms');
        //          localStorage.removeItem("SetDataDrive");
        //          next(); 
        //      }
        //  }
     //    },
        computed:{
            totalAmount: function () {
                let amount = 0;

                for(let i=0;i<this.Form.trProducts.length;i++){
                    let rate = 0, packQuantity = 0, qty = 0;

                    for(let j=0;j<this.Form.trProducts[i].variants.length;j++){
                        let variant = this.Form.trProducts[i].variants[j];

                        //calculation of item amount
                        this.fn_itemTotal(variant, this.Form.trProducts[i].rate);

                        this.Form.trProducts[i].rate ?  rate = Number(this.Form.trProducts[i].rate) : '';

                        variant.qty ? qty = Number(variant.qty) : qty = 0;

                        if(qty){
                            variant.packQuantity ? packQuantity = Number(variant.packQuantity) : packQuantity = 1;
                        }
                        else{
                            packQuantity = 0;
                        }

                        amount += rate * packQuantity * qty;
                    }
                }

                return this.roundUpto2Decimals(amount);
            },
            filteredProducts: function(){
                return this.$root.products.filter(item => {
                    return item.groupTypeId == 101 && item.inventory == 1;
                })
            }
        }
    }

</script>