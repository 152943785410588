<template>
    <div>
        <v-dialog v-model="dialog_opening_date" no-click-animation persistent lazy transition="slide-y-transition" max-width="460px">

            <v-card>
                <v-toolbar flat :height="56">
                    <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>Update Opening Date</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider> 

                <v-card-text full-height nopadding grey-back>
                    
                    <v-form @submit.prevent="submit">

                        <v-layout ma-0 pt-4 row wrap white-back>
                            <v-flex xs12 sm12 md12 form-item @click="fn_openDialogDatePicker">
                                <v-text-field  
                                  readonly
                                  :value="fn_formattedOpeningDate()"
                                  label="Opening Date"
                                  placeholder=" " 
                                  append-icon="date_range">
                               </v-text-field>
                            </v-flex>
                        </v-layout>
                    
                    </v-form>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                    <v-btn color="blue darken-1" text @click.native="fn_saveOpeningDate">save</v-btn>
                </v-card-actions>
            </v-card>   
        </v-dialog>

        <dialog-datepicker ref="ref_dialogDatePickerOpening" :selected="selectedDate" :updateDate="fn_updateDateOpening" :minDate="minDate" :maxDate="maxOpeningDate"></dialog-datepicker>
    </div>
</template>     

<script>

import dialogDatePicker from '../../dialogs/dialog_datepicker'

export default{
    data(){ 
        return{
          dialog_opening_date: false,
          maxOpeningDate:null,
          minDate:"2022-04-01",//made change
          selectedDate:null
        }
    },
    components:{
      'dialog-datepicker':dialogDatePicker
    },
    methods:{
      openDialogWeb: function () {
          this.dialog_opening_date = true;
          this.selectedDate = this.$root.CurrentBusiness.OpeningDate;
          this.fn_getFirstTransactionDate();
      },

      closeDialogWeb: function () {
          this.dialog_opening_date = false;
      },

      fn_formattedOpeningDate: function(){
        return this.$moment(this.selectedDate).format('DD-MMM-YYYY');
      },

      fn_openDialogDatePicker: function(){
        this.$refs['ref_dialogDatePickerOpening'].openDialogWeb();
      },

      fn_getFirstTransactionDate: function(){
        let self = this;
        let filterObject = {};
        // Sending opening stock and opening ledger list id to get first transaction date except these two.
        filterObject.transactionListId = [40001, 40003];
        this.getFilterData('FirstTransactionDate', null, filterObject, null,
          {
            onSuccess: function(response){
              console.log(response);
              let ftd = JSON.parse(response);
              let maxDate = null;

              if(!ftd){
                //Calculation of max date fy
                // let currentDate = new Date();
                // let currentMonth = currentDate.getMonth() + 1;
                // let currentYear = currentDate.getFullYear();

                // let year = null
                // year = currentMonth < 3 ? currentYear : currentYear+1;

                // maxDate = new Date(year+"-03-31");

                maxDate = new Date(self.$root.maxDateSelection);
              }
              else{
                maxDate = new Date(ftd);
              }

              console.log(maxDate);

              // Setting up min/max opening date selection
              self.fn_setupMinMaxOpeningDateSelection(maxDate)
              
            },
            onError: function(error){
              console.log(error);
            }
          }
        );
      },

      fn_setupMinMaxOpeningDateSelection: function(maxDate){
        let year = this.$moment(maxDate).format('YYYY');
        let month = this.$moment(maxDate).format('MM');
        let day = this.$moment(maxDate).format('DD');
        this.maxOpeningDate = year+"-"+month+"-"+day;
      },

      fn_updateDateOpening: function(value){
        this.selectedDate = value;
        this.$refs['ref_dialogDatePickerOpening'].disableBtn = false;
        console.log(this.selectedDate);
      },

      fn_saveOpeningDate: function(){

        var self = this;

        let postObj = {};

        //subtracted 1 day from opening date as we are adding opening one day less than user selected opening date

        // postObj.OpeningDate = new Date(JSON.parse(JSON.stringify(this.selectedDate)));

        // setting up selected opening date as start of the day
        this.selectedDate = this.$moment(this.selectedDate).startOf('day');
        
        postObj.OpeningDate = this.$root.fn_getLocalDateFormat(this.selectedDate);
        postObj.BusinessID = this.$root.CurrentBusiness.BusinessID;

        // let od = new Date(JSON.parse(JSON.stringify(this.selectedDate)));
        let od = new Date(this.selectedDate);
        od.setDate(od.getDate()-1);
        // postObj.transactionDate = new Date(od);
        postObj.transactionDate = this.$root.fn_getLocalDateFormat(od);
        postObj.transactionListId = [40001, 40003];

        this.postData('OpeningDate', postObj, 
          {
            onSuccess: function(response){
              console.log(response);
              // self.OpeningDate = new Date(JSON.parse(JSON.stringify(self.selectedDate)));
              self.$root.CurrentBusiness.OpeningDate = new Date(JSON.parse(JSON.stringify(self.selectedDate)));
              self.$root.fn_setMinTransactionDate();
              self.closeDialogWeb();
              // self.$router.go(-1);
            //   alert('Opening date saved');
                self.$eventHub.$emit('snackbarAlert', {msg:'Opening date saved'});
              // if(self.$route.query && self.$route.query.goback){
              //  self.$router.go(-1);
              // }
            },
            onError: function(error){
              console.log(error);
            //   alert('Something went wrong. Try again!.');
                self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
            }
          }
        );
      },
    }
}
</script>