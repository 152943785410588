<template>
    <div grey-back full-height class="display_wrapper">
        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>
    </div>
</template>

<script>
const transactionData = require('../../datamodel').data.transactionData;
import dialogDatePicker from '../../../dialogs/dialog_datepicker'
import dialogForm from './dialogs/dialog_form'
import {transactionMixin} from '../../transaction_mixin'

    export default{
        props:['settings'],
        mixins: [transactionMixin],
        data(){
            return{

                Form:{
                    "transactionData":JSON.parse(JSON.stringify(transactionData)),
                    "rate":null,
                    "qty":null
                },
                productData:null,
                summaryArray:null,
                loading:true,
                tagId:null,
                draftStorageId:null,
                dialogStorageId:null,
                backButtonPress: false,
                // unwatch:false,
                disabledBtn:false,
                type:"plus",
                selectedDate:null
            }
        },
        components:{
            'dialog-datepicker':dialogDatePicker,
            'dialog-form': dialogForm,
        },
        methods:{

            fn_closeDialogForm: function(){
                this.$refs['ref_dialogForm'].closeDialogWeb();
                setTimeout(()=>{
                    this.fn_goBack();
                })
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.selectedDate = date;
                setTimeout(function () {
                  self.$refs['ref_dialogDatePicker'].openDialogWeb();
                })
            },

            fn_updateDate: function (value) {
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(value);
                this.$refs['ref_dialogDatePicker'].closeDialogWeb();
            },

            fn_getSummary: function (itemId) {
                var self = this;
                this.getData('ProductSummary', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.summaryArray = JSON.parse(response);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_getProductData: function (itemId, isEdit) {
                var self = this;
                this.getData('Product', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.productData = JSON.parse(response);
                            if(!isEdit){
                                self.rate = self.productData.purchaseRate;
                            }

                            setTimeout(()=>{
                                if(self.rate){
                                    document.getElementById('field_rate').focus();      
                                }
                                else{
                                    document.getElementById('field_quantity').focus();
                                }
                            },50);
                            
                            self.fn_getSummary(itemId);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_getData: function (type, itemId) {
                this.Form.isNew = false;
                let filterObject = {};
                filterObject.type = this.settings.transactionTypeId;

                let self = this;
                this.getFilterData(type, itemId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            self.fn_bindingData(data);
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                            self.fn_goBack();
                        }
                    }
                );
            },

            fn_bindingData: function (data) {
                // this.fn_getTransactionTypeData(data.transactionTypeFormId, null, false);
                this.Form.transactionData.additionalData = data.additionalData;
                this.Form.transactionData.amount = data.amount;
                this.Form.transactionData.businessId = data.businessId;
                this.Form.transactionData.createdBy = data.createdBy;
                this.Form.transactionData.createdDate = data.createdDate;
                this.Form.transactionData.currencyId = data.currencyId;
                this.Form.transactionData.dueDate = data.dueDate;
                this.Form.transactionData.dueType = data.dueType;
                this.Form.transactionData.modifiedBy = data.modifiedBy;
                this.Form.transactionData.modifiedDate = data.modifiedDate;
                this.Form.transactionData.notes = data.notes;
                this.Form.transactionData.partyId = data.partyId;
                this.Form.transactionData.paymentMode = data.paymentMode;
                this.Form.transactionData.prefix = data.prefix;
                this.Form.transactionData.refDate = data.refDate;
                this.Form.transactionData.refNumber = data.refNumber;
                this.Form.transactionData.roundedAmount = data.roundedAmount;
                this.Form.transactionData.salesmanId = data.salesmanId;
                this.Form.transactionData.status = data.status;
                this.Form.transactionData.suffix = data.suffix;
                this.Form.transactionData.transactionDate = data.transactionDate;
                this.Form.transactionData.transactionId = data.transactionId;
                this.Form.transactionData.transactionListId = data.transactionListId;
                this.Form.transactionData.transactionNumber = data.transactionNumber;
                this.Form.transactionData.mergeTransactionNumber = data.mergeTransactionNumber;
                // this.Form.transactionProductsTemp = JSON.parse(JSON.stringify(data.transactionProducts));
                let products = JSON.parse(JSON.stringify(data.transactionProducts));
                this.Form.rate = products[0].variants[0].rate;
                this.Form.qty = products[0].variants[0].qty;
                this.type = products[0].variants[0].transactionProductMode ? 'plus' : 'minus';
                this.fn_getProductData(data.productId);
            },

            fn_checkValidations: function(){
                this.disabledBtn = true;
                this.fn_post();
                // this.showLoading('Please Wait...');
                // this.$validator.validateAll().then((result) => {
             //        if (result) {
             //         this.fn_post();
             //     }
             //     else{
             //         this.disabledBtn = false;
             //         this.hideLoading();
             //     }
             //    });
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('TransactionProducts', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            // self.hideLoading();
                            setTimeout(()=>{
                                self.$eventHub.$emit('UpdateItemList', {}); 
                            },100);

                            self.fn_goBack();
                        },
                        onError: function(error){
                            console.log(error);
                            self.saveBtnKey++;
                            // self.hideLoading();
                            let response = JSON.parse(error);
                            if(response && response.negativeProducts && response.negativeProducts.length){
                                self.fn_shortListInvalidItems(response.negativeProducts);
                            }
                        }
                    }
                );
            },

            fn_post: function(){

                //only in case of new transaction
                if(!this.Form.transactionData.transactionId){
                    this.Form.transactionData.transactionId = this.guid();
                    // this.Form.transactionData.createdDate = new Date();
                    this.Form.transactionData.createdDate = this.$root.fn_getLocalDateFormat();
                    this.Form.transactionData.transactionListId = this.settings.transactionTypeId;
                }

                // this.Form.transactionData.modifiedDate = new Date();
                this.Form.transactionData.modifiedDate = this.$root.fn_getLocalDateFormat();

                this.Form.transactionData.amount = this.totalAmount;
                this.Form.transactionData.roundedAmount = this.totalAmount
                this.Form.transactionData.status = 1;
                this.Form.transactionData.transactionTypeFormId = this.Form.transactionTypeFormId;
                this.Form.transactionData.refDate = null;

                // this.Form.transactionProducts = [];
                let transactionProducts = [];

                let tpObj = {};

                tpObj.transactionProductId = this.guid();
                tpObj.transactionId = this.Form.transactionData.transactionId;
                tpObj.productId = this.productData.productId;
                tpObj.productVariantId = this.productData.productVariantId;
                tpObj.groupId = this.guid();
                tpObj.mrp = null;
                tpObj.rate = this.Form.rate ? this.Form.rate : 0;
                tpObj.additionalData = null;
                tpObj.unitId = this.productData.productVariants[0].unitId;
                tpObj.primaryUnitId = this.productData.productVariants[0].variantBaseUnitId;
                tpObj.qty = this.Form.qty;
                tpObj.packQuantity = this.productData.productVariants[0].packQuantity ? this.productData.productVariants[0].packQuantity : 1;
                tpObj.amount = this.totalAmount;
                tpObj.taxableValue = this.totalAmount;
                if(this.type == 'minus'){
                    tpObj.transactionProductMode = 0;
                    tpObj.actualQty = -this.Form.qty;
                }
                else{
                    tpObj.transactionProductMode = 1;
                    tpObj.actualQty = this.Form.qty;
                }

                transactionProducts.push(tpObj);

                let postObj = {};

                postObj = this.Form.transactionData;
                postObj.transactionProducts = transactionProducts;

                postObj.fiscalYear = JSON.stringify(this.fn_getFiscalYear(this.Form.transactionData.transactionDate));
                postObj.sortDate = this.$moment(this.Form.transactionData.transactionDate).format('YYYY-MM-DD');
                
                console.log(this.Form);
                this.fn_saveLocal(postObj);
            },

            fn_goBack: function(){
                this.backButtonPress = true;
                this.$router.go(-1);
            }
        },
        mounted: function(){

            // !this.$root.CurrentBusiness.DataDrive ? this.fn_checkTransactionCount() : '';

            if(new Date().getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(this.$root.CurrentBusiness.OpeningDate);
            }
            else{
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(); 
            }

            this.type = this.$route.query.type;
            this.fn_getProductData(this.$route.query.itemid);

            this.$refs['ref_dialogForm'].openDialogWeb();

        },
        beforeRouteEnter (to, from, next) {
            next(vm => {

                let itemId = null;

                //edit from display view 
                if((from.params && from.params.tagId) && (to.query && !to.query.tagId)){//from display view
                    itemId = from.params.tagId;
                    vm.fn_getData('TransactionProducts', itemId);
                }

            })
        },
        computed:{
            totalAmount: function(){
                let rate = this.Form.rate ? this.Form.rate : 0;
                return this.roundUpto2Decimals(rate * this.Form.qty);
            }
        }
    }

</script>