<template>
    <div>
        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>
    </div>
</template>

<script>

// const dialogjs =  require('../dialog').dialogjs;
// const menuItem = require('../../../../config.js').configs.menuItem;

// import dialogDiscard from '../../../dialogs/dialog_discard'
// import {draftMixin} from '../../../draft_mixin'
// import dialogDraftItems from '../../../dialogs/dialog_draft_items'

import dialogForm from './dialogs/dialog_form'


    export default{
        // mixins: [draftMixin],
        data(){
            return{
                Form:{"PhoneNumber":null},
                disableOnSave:false,
                saveBtnKey:1,
                loading:false
            }
        },
        components:{
            'dialog-form':dialogForm
        },
        methods:{

            fn_closeDialogForm: function(){
                this.fn_goBack();
            },

            fn_checkValidations: function(){
                this.loading = true;
                this.$root.fn_getAuthToken(token=>{
                    if(token=='error'){
                        this.loading = false;
                        this.saveBtnKey++;
                        return;
                    }

                    this.fn_post(token);
                });
                // var self = this;
             //      this.$validator.validateAll().then((result) => {
             //        if (result) {
             //         this.showLoading("Please wait...");
                //      this.$root.fn_getAuthToken(token=>{
          //                  this.fn_post(token);
          //               });
             //        }
             //        else{
             //         this.$vuetify.goTo('.error--text', this.scrollOptions);
             //        }
             //     });
            },
            
            fn_post: function(token){

                if(!navigator.onLine){
                    // alert('Internet not available');
                    this.$eventHub.$emit('snackbarAlert', {msg:'Internet not available'});
                    this.loading = false;
                    this.saveBtnKey++;
                    return;
                }

                let self = this;

                let postObj = {};
                postObj.BusinessID = this.$root.CurrentBusiness.BusinessID;
                postObj.token = token;
                postObj.PhoneNumber = '91' + this.Form.PhoneNumber;

                this.postServerData('PostAccountant', postObj, {
                    onSuccess: function(response){
                        console.log(response);
                        // alert('Accountant added.');
                        self.$eventHub.$emit('snackbarAlert', {msg:'Accountant added'});
                        self.loading = false;
                        self.$eventHub.$emit('UpdateAccountantList', {});
                        self.fn_goBack();
                    }, 
                    onError: function(error){
                       console.log(error);
                       // self.hideLoading();
                       self.loading = false;
                       self.disableOnSave = false;
                       self.saveBtnKey++;
                       if(typeof JSON.parse(error) == 'object'){
                            let response = JSON.parse(error);
                            //  alert(response.Message);
                            self.$eventHub.$emit('snackbarAlert', {msg:response.Message});
                       }
                    }, 
                })
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted: function(){
            this.$refs['ref_dialogForm'].openDialogWeb();

            let isCalled = false;
            this.$eventHub.$on('connected', () => {
                setTimeout(() => {
                    if(!isCalled){
                        isCalled = true;
                        this.saveBtnKey++;
                        this.loading = false;
                    }

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);

                }, 1000);
            });
        },
        beforeRouteLeave (to, from, next) {
            this.$eventHub.$off('connected');
            next();
        }

    }

</script>