<template>
    <div>

        <v-dialog v-model="dialog_bank_details" no-click-animation persistent lazy transition="slide-y-transition" max-width="460px">

          <v-card>

            <v-toolbar :height="56" flat>
                <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                <v-toolbar-title>Bank Details</v-toolbar-title>
                <!-- <v-spacer></v-spacer>
                <v-btn flat icon @click="fn_saveBankDetails">Done</v-btn> -->
            </v-toolbar>

            <v-card-text full-height nopadding>

                <v-form @submit.prevent="submit">

                    <v-layout row wrap ma-0 pt-4>

                            <v-flex xs6 sm6 md6 form-item>
                                <v-text-field  
                                    v-model="bankDetails.bankName.value" 
                                    :label="bankDetails.bankName.label" 
                                    placeholder=" ">
                                </v-text-field>
                            </v-flex>

                            <v-flex xs6 sm6 md6 form-item>
                                <v-text-field  
                                    v-model="bankDetails.branchName.value" 
                                    :label="bankDetails.branchName.label" 
                                    placeholder=" ">
                                </v-text-field>
                            </v-flex>

                            <v-flex xs6 sm6 md6 form-item>
                                <v-text-field  
                                    v-model="bankDetails.ifscCode.value" 
                                    :label="bankDetails.ifscCode.label"     
                                    placeholder=" ">
                                </v-text-field>
                            </v-flex>

                            <v-flex xs6 sm6 md6 form-item>
                                <v-text-field  
                                    v-model="bankDetails.accountName.value" 
                                    :label="bankDetails.accountName.label" 
                                    placeholder=" ">
                                </v-text-field>
                            </v-flex>

                            <v-flex xs6 sm6 md6 form-item>
                                <v-text-field  
                                    v-model="bankDetails.accountNumber.value" 
                                    :label="bankDetails.accountNumber.label" 
                                    placeholder=" ">
                                </v-text-field>
                            </v-flex>

                    </v-layout>

                </v-form>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                <v-btn color="blue darken-1" text @click.native="fn_saveBankDetails">ok</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default{
    data(){ 
        return{
            dialog_bank_details: false,
            quillContent:null,
            bankDetails:{
                "bankName":{"label":"Bank Name", "value":null},
                "branchName":{"label":"Branch", "value":null},
                "ifscCode":{"label":"IFSC Code", "value":null},
                "accountName":{"label":"Account Name", "value":null}, 
                "accountNumber":{"label":"Account Number", "value":null}
            }
        }
    },
    methods:{
        openDialogWeb: function () {    
            // this.errors.clear('fields');
            this.quillContent = this.$parent.$refs['ref_dialogForm'].content;
            this.bankDetails = {
                "accountName":{"label":"Account Name", "value":null},
                "accountNumber":{"label":"Account Number", "value":null}, 
                "bankName":{"label":"Bank", "value":null},
                "branchName":{"label":"Branch", "value":null},
                "ifscCode":{"label":"IFSC Code", "value":null}, 
            }
            this.dialog_bank_details = true;
        },
        closeDialogWeb: function () {
            this.dialog_bank_details = false;
            let self = this;
            setTimeout(function(){
                self.$parent.$refs['ref_dialogForm'].content = self.quillContent;
                // self.$parent.content = self.quillContent;
                self.$parent.fn_preview();
            },300);
        },
        fn_saveBankDetails: function () {
            let bankName = this.bankDetails.bankName.value ? "<p><b>"+this.bankDetails.bankName.label +"</b>: "+ this.bankDetails.bankName.value +"</p>" : '';
            let bankBranch = this.bankDetails.branchName.value ? "<p><b>"+this.bankDetails.branchName.label +"</b>: "+ this.bankDetails.branchName.value +"</p>" : '';
            let ifscCode = this.bankDetails.ifscCode.value ? "<p><b>"+this.bankDetails.ifscCode.label +"</b>: "+ this.bankDetails.ifscCode.value +"</p>" : '';
            let accountName = this.bankDetails.accountName.value ? "<p><b>"+this.bankDetails.accountName.label +"</b>: "+ this.bankDetails.accountName.value +"</p>" : '';
            let accountNumber = this.bankDetails.accountNumber.value ? "<p><b>"+this.bankDetails.accountNumber.label +"</b>: "+ this.bankDetails.accountNumber.value +"</p>" : '';
            this.quillContent += "<div>" + bankName + bankBranch + ifscCode + accountName + accountNumber + "</div>";
            this.closeDialogWeb();
        },
    }
}
</script>