<template>
  <div>

        <v-toolbar flat :height="56">
            <v-toolbar-title>Transactions</v-toolbar-title>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back">

            <v-alert type="info" icon="info_outline" font12 dense :value="true">Select an item to get details</v-alert>

            <!-- <v-list dense class="pa-0" v-for="(group, index) in listCategories" :key="index" :class="{'mt-2':index!=0}">
                <v-subheader class="pl-4 pr-4">{{group.name}}</v-subheader>
                <v-divider></v-divider> -->
            <v-list dense class="pa-0" grey-back>
                <v-list-item-group v-model="selectedItem" color="primary">
                    <template v-for="(group, index) in listCategories">

                        <div :key="'wrapper'+index" :class="{'mt-2':index!=0}">

                            <v-subheader class="pl-4 pr-4 white-back" :key="'subheader'+index">{{group.name}}</v-subheader>
                            <v-divider :key="group.name"></v-divider>

                            <template v-for="(item, itemIndex) in group.items">
                                <v-list-item white-back :key="'listitem'+itemIndex" @click="fn_openList(item)">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.title"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon>chevron_right</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                                <!-- <v-divider v-if="group.items.length-1 != itemIndex" :key="item.title"></v-divider>     -->
                            </template>
                        </div>  
                    </template>
                
                </v-list-item-group>
            </v-list>

        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                "selectedItem":null,
                "listCategories":[  
                                    {
                                        "name":"Sales", 
                                        "items":[
                                            {
                                                "id":1, 
                                                "title":"Order",
                                                "uri":"transactions/sales/order/items",
                                                "routeName":"",
                                                "listId":"sale",
                                                "transactionTypeId":"10001"
                                            },
                                            {
                                                "id":2, 
                                                "title":"Delivery Challan",
                                                "uri":"transactions/sales/deliverychallan/items",
                                                "routeName":"",
                                                "listId":"sale",
                                                "transactionTypeId":"10002"
                                            },
                                            {
                                                "id":3, 
                                                "title":"Sale Invoice",
                                                "uri":"transactions/sales/saleinvoice/items",
                                                "routeName":"",
                                                "listId":"sale",
                                                "transactionTypeId":"10003"
                                            },
                                            {
                                                "id":4, 
                                                "title":"Sale Return",
                                                "uri":"transactions/sales/salereturn/items",
                                                "routeName":"",
                                                "listId":"sale",
                                                "transactionTypeId":"10004"
                                            },
                                            {
                                                "id":5, 
                                                "title":"Estimate/Quotation",
                                                "uri":"/transactions/sales/estimate/items",
                                                "routeName":"",
                                                "listId":"sale",
                                                "transactionTypeId":"10005"
                                            },
                                        ]
                                    },
                                    {
                                        "name":"Purchase", 
                                        "items":[
                                            {
                                                "id":1, 
                                                "title":"Purchase Order",
                                                "uri":"transactions/purchase/purchaseorder/items",
                                                "routeName":"",
                                                "listId":"purchase",
                                                "transactionTypeId":"20001"
                                            },
                                            {
                                                "id":2, 
                                                "title":"Purchase Challan",
                                                "uri":"transactions/purchase/purchasechallan/items",
                                                "routeName":"",
                                                "listId":"purchase",
                                                "transactionTypeId":"20002"
                                            },
                                            {
                                                "id":3, 
                                                "title":"Purchase Invoice",
                                                "uri":"transactions/purchase/purchaseinvoice/items",
                                                "routeName":"",
                                                "listId":"purchase",
                                                "transactionTypeId":"20003"
                                            },
                                            {
                                                "id":4, 
                                                "title":"Purchase Return",
                                                "uri":"transactions/purchase/purchasereturn/items",
                                                "routeName":"",
                                                "listId":"purchase",
                                                "transactionTypeId":"20004"
                                            },
                                            {
                                                "id":5, 
                                                "title":"Expense",
                                                "uri":"/transactions/purchase/expense/items",
                                                "listId":"expense",
                                                "routeName":"",
                                                "transactionTypeId":"20005"
                                            },
                                        ]
                                    },
                                    {
                                        "name":"Accounting", 
                                        "items":[
                                            {
                                                "id":1, 
                                                "title":"Payment In",
                                                "uri":"transactions/receipt/items",
                                                "listId":"accountbooks",
                                                "routeName":"",
                                                "transactionTypeId":"30001"
                                            },
                                            {
                                                "id":2, 
                                                "title":"Payment Out",
                                                "uri":"transactions/payment/items",
                                                "routeName":"",
                                                "listId":"accountbooks",
                                                "transactionTypeId":"30002"
                                            },
                                            {
                                                "id":3, 
                                                "title":"Journal",
                                                "uri":"transactions/journal/items",
                                                "routeName":"",
                                                "listId":"accountbooks",
                                                "transactionTypeId":"30003"
                                            },
                                            {
                                                "id":4, 
                                                "title":"Cash and Bank",
                                                "uri":"transactions/contra/items",
                                                "routeName":"",
                                                "listId":"accountbooks",
                                                "transactionTypeId":"30004"
                                            },
                                            // {
                                            //  "id":5, 
                                            //  "title":"Credit Note",
                                            //  "uri":"transactions/creditnote/items",
                                            //  "routeName":""
                                            // },
                                            // {
                                            //  "id":6, 
                                            //  "title":"Debit Note",
                                            //  "uri":"transactions/debitnote/items",
                                            //  "routeName":""
                                            // },
                                        ]
                                    }
                                    // {
                                    //  "name":"Inventory", 
                                    //  "items":[
                                    //      {
                                    //          "id":1, 
                                    //          "title":"Stock Journal",
                                    //          "uri":"transactions/inventory/stockjournal/items",
                                    //          "routeName":""
                                    //      }
                                    //  ]
                                    // }
                                ]
            }
        },
        methods:{
            fn_openList: function(item){
                // this.$router.push({path:uri, query:{tagId:this.guidWeb()}});
                this.$router.push({path:"/"+this.$route.params.businessId+"/"+item.listId, query:{tagId:this.guidWeb(), transactionTypeId:item.transactionTypeId}});
            },
            fn_openForm: function(uri){
                // localStorage.setItem("SetDataDrive", 1);
                this.$router.push({path:uri+'/new', query:{tagId:this.guidWeb()}});
            }
        }
    }
</script>