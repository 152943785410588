<template>
    <div>
      <div v-if="!subReportData">
        <!-- reportId!=3 && reportId!=9 -->
        <general-report ref="ref_generalReport" :reportId="reportId" :propsObj="propsObj" :openDialogAndroid="openDialogAndroid" :getReportItemDetails="getReportItemDetails" :openDatePicker="openDatePicker" :reportFilterObj="reportFilterObj" :openTransaction="openTransaction" :openAccountDetails="openAccountDetails" v-if="reportType === 'general' && tableView" :openLinkedAccount="openLinkedAccount" :getSubReportData="getSubReportData" :periods="periods" :selectPeriod="selectPeriod" :openDialogCustomDates="openDialogCustomDates" :customDates="customDates"></general-report>

        <general-report-tile-view :reportId="reportId" :propsObj="propsObj" :openDialogAndroid="openDialogAndroid" :getReportItemDetails="getReportItemDetails" :openDatePicker="openDatePicker" :reportFilterObj="reportFilterObj" :openTransaction="openTransaction" :openAccountDetails="openAccountDetails" :getSubReportData="getSubReportData" :periods="periods" :selectPeriod="selectPeriod" :openDialogCustomDates="openDialogCustomDates" :customDates="customDates" :exportSubReport="exportSubReport" v-else></general-report-tile-view>
      </div>

      <div v-else-if="subReportData2">
          <sub-report :reportData="subReportData2"></sub-report>
      </div>

      <div v-else-if="subReportData">
          <sub-report :reportData="subReportData" :getSubReportData2="getSubReportData2"></sub-report>
      </div>


      <div id="printable-section">

        <div style="white-space:nowrap">
            <template v-if="!subReportData">
                <print-general-report :propsObj="propsObj" :reportFilterObj='reportFilterObj' :subReportData="subReportData" v-if="reportType === 'general' && !subReportData"></print-general-report>  
            </template>

            <template v-else-if="subReportData2">
                <print-sub-report :reportData="subReportData2"></print-sub-report>
            </template>

            <template v-else-if="subReportData">
                <print-sub-report :reportData="subReportData"></print-sub-report>
            </template>
          
          <!-- <print-sub-report :reportData="subReportData" v-if="subReportData"></print-sub-report> -->
        </div>

          <!-- <print-group-report :propsObj="propsObj" v-if="reportType === 'group'"></print-group-report> -->

          <!-- <print-group-summary-report :propsObj="propsObj" v-if="reportType === 'groupSummary'"></print-group-summary-report> -->
      </div>
    </div>
</template>

<script>

// const generalReport = () => import('./report_templates/general');
// const groupReport = () => import('./reportcomponents/group');
// const itemDetails = () => import('./reportcomponents/itemdetails');
// const groupSummaryReport = () => import('./reportcomponents/groupsummary');

import generalReport from '../report_templates/general'
import gstrSubReport from '../report_templates/gstr_sub_report'

import generalReportTileView from '../report_templates/tileview'
// import groupReport from './report_templates/item_groups'

// const printGeneralReport = () => import('./print_report_templates/general');
// const printGroupReport = () => import('./reportprintcomponents/group_print');
// const printGroupSummaryReport = () => import('./reportprintcomponents/groupSummary_print');

import printGeneralReport from '../print_report_templates/general'
import printGstrSubReport from '../print_report_templates/gstr_sub_report'

export default{
    props:['reportId', 'propsObj', 'openDialogAndroid', 'reportType', 'getReportItemDetails', 'openDatePicker', 'reportFilterObj', 'openTransaction', 'openAccountDetails', 'tableView', 'openLinkedAccount', 'getSubReportData', 'subReportData', 'periods', 'selectPeriod', 'openDialogCustomDates', 'customDates', "exportSubReport", "getSubReportData2", "subReportData2"],
    components:{
      "general-report": generalReport,
      "general-report-tile-view":generalReportTileView,
      "print-general-report": printGeneralReport,
      "sub-report": gstrSubReport,
      "print-sub-report": printGstrSubReport,
      // "print-group-report": printGroupReport,
      // "print-group-summary-report": printGroupSummaryReport
    },
}

</script>


<style>
.non-printable-section .container{
  padding:0 !important;
}
.group_report .body .item_wrapper .table{
  display: block !important;
  padding-top: 40px !important;
}
/*.table_wrapper{
  padding-top: 110px;
  font-size:9px;
}
.table_wrapper.two-lines-header{
  padding-top: 91px;
}
.table_wrapper.four-lines-header{
  padding-top: 128px;
}

.table_wrapper .reportdetails{
    position:fixed;
    width:100%;
    top:56px;
    z-index:1;
    background:#fff;
    padding:8px;
    border-bottom: 1px #F0F0F0 solid;
}
.table_wrapper .reportdetails .caption{
    margin: 0;  
    font-size:13px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.table_wrapper .header{
    position: fixed;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
    z-index: 10;
    padding-left: 139px;
    overflow: hidden;
}

.tile-view .table_wrapper .reportdetails{
  padding:8px 16px;
}

#printdatatable.table_wrapper .reportdetails{
  padding:6px !important;
}*/

.fixed-width-table .header{
  padding-left:0 !important;
}

.fixed-width-table .table{
  width: 100%;
}

.link:first-child, .item-link{
  /*text-decoration: underline;*/
  color: rgba(0,0,0,0.72);
}
.bottom-sheet{
  max-height: 60% !important;
  overflow: auto !important;
  height: 100%;
  position: relative;
}
.bottom-sheet .card{
  height: 100% !important;
}
.bottom-sheet .card .card__title{
  position:fixed;
  width:100%;
  z-index:99;
  background:#fff;
  padding:0px 0px 0px 16px;
  border-bottom:1px #F0F0F0 solid;
}
.bottom-sheet .card .card__text{
  padding:48px 0px 0px 0px;
}

.bottom-sheet .card .card__title .btn .btn__content{
  padding-right: 0;
}

/* Table Css */

.header .table .table_col{
    font-weight: 700;
}
/*.footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow-x: auto;
    background: #fff !important;
    z-index: 10;
    padding-left: 139px;
    box-shadow: 0px 12px 5px 12px #ddd;
    overflow: hidden;
}*/
.fixed-width-table .footer{
  padding-left: 0 !important;
}
/*.body{
    padding-top: 55px;
    padding-bottom: 100px;
    padding-left: 139px;
    overflow: auto;
    z-index: 9;
}*/
.fixed-width-table .body{
  padding-left: 0 !important;
}
.table{
    display: table;
    border-collapse: collapse;
    width:100%;
}
.table_row{
    display: table-row;
    border-bottom:1px #ddd solid;
}
.table_col{
    display: table-cell;
    padding: 8px 10px;
    /*border: 1px #F0F0F0 solid;*/
    min-width: 100px;
    max-width: 120px;
    overflow-wrap: anywhere;
}
.table_col.small, .small{
    min-width: 48px !important;
    max-width: 48px !important;
    width: 48px;
}
/*.body .table .table_row .table_col:first-child{
    position: absolute;
    left: 0;
    background-color: inherit;
    width: 140px;
    max-width: 140px;
    box-shadow: 3px 0 3px -5px !important;
}*/

.fixed-width-table .header .table .table_row .table_col:first-child,
.fixed-width-table .body .table .table_row .table_col:first-child,
.fixed-width-table .footer .table .table_row .table_col:first-child{
  position: inherit !important;
}

.two-column .table .table_row .table_col{
  width: 50% !important;
}

.three-column .table .table_row .table_col{
  width: 33.33% !important;
}

.body .table .table_row:first-child .table_col{
    border-top: none;
}
/*.header .table .table_row .table_col:first-child{
    position: fixed !important;
    left: 0 !important;
    z-index: 11;
    background-color: inherit;
    width: 140px;
    max-width: 140px;
    box-shadow: 3px 0 3px -5px !important;
}

.footer .table .table_row .table_col:first-child{
    position: fixed !important;
    left: 0 !important; 
    bottom: 0;
    z-index: 11;
    background-color: #fff !important;
    background-color: inherit;
    width: 140px;
    max-width: 140px;
    box-shadow: 3px 0 3px -5px !important;
}*/

.groupheading{padding: 16px; text-align: left}

/*.body .item_wrapper .group_name{
    position: absolute;
    left: 0;
    background-color: #ddd;
    border-bottom: 1px #F0F0F0 solid;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
}*/
.body .item_wrapper .brand_table.firstitem,
.body .item_wrapper .brand_table .table{
    padding-top: 50px;
}
.leftpad{
    padding-left: 10px;
}
.endmsg{
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    padding: 16px;
}



  #printdatatable{
    padding-top:0px !important;
  }
  #printdatatable .reportdetails{
    border: none !important;
    position: unset !important;
    padding-left:0;
    border-bottom:1px #F0F0F0 solid !important;
  }

  #printdatatable .table .table_row{
    border-bottom:1px #F0F0F0 solid;
  }

  .body.table_row:nth-child(even),
  table.simple-table tbody:nth-child(even),
  table.nested-table tr:nth-child(even){
    background: #ffffff !important; 
  }

  .body.table_row:nth-child(odd){
    background: #f9f9f9 !important;
  }

  #tableHeader .table_row{
      /*background: #2196f3 !important;
      color: #fff !important;*/
      background: #F0F0F0 !important;
      color: rgba(0,0,0,0.87) !important;
  }

  .report-details-card .detail-report-header .table_row{
    background: #d9edf7 !important;
    font-weight:700;
  }

  .body.three-row-card{
      padding-bottom: 168px !important;
  }

  .footer-card{
    width: 100%;
    font-weight: 500;
    background: #fff;
    position: fixed;
    bottom: 0;
    border-top: 1px #ddd solid;
  }
    


#printable-section{
  display: none;
  visibility: hidden
}

  @media print
  {
   .tile-view{
    background:#fff !important;
   }

  thead.report-header,
  .internal-header {
     display: table-header-group;
  }

  tfoot.report-footer {
     display:table-footer-group;
  }

  table.report-container-table {
      page-break-after: always;
  }

  table tr td,
  table tr th,
  table tr {
    page-break-inside: avoid;
  }

  /* table.bottom-table{
    page-break-before: always !important;
  } */

    @page{
      /* size:21cm 29.7cm; */
      /* margin: 0.5cm 0.5cm 0.5cm 0.5cm; */
      mso-title-page:yes;
      mso-page-orientation: portrait;
    }
    /* @page content {margin: 0.5cm 0.5cm 0.5cm 0.5cm;} */
    div#printable-section {page: content;visibility: visible;position:absolute;left:0;right:0;}

    /* #printable-section, .gstr-sub-table{display: block;position: absolute;top:0;} */
    #printable-section{display: block;position: absolute;top:0;}

    #non-printable-section * {
      visibility: hidden;
    }

    #datatable, #subdatatable{position: absolute;top: -9999999px;}
    #printable-section, #printdatatable *,
    #printable-section, #printsubdatatable * {
      visibility: visible;
    }
    .action_menu, .noprint{
      visibility: hidden;
    }

  }

</style>