<template>
    <div>
        <v-card flat>

            <v-card-text full-height nopadding>

                <v-subheader>Filter {{type}} By
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeFilterNav"><v-icon>close</v-icon></v-btn>
                </v-subheader>

                <v-divider></v-divider>

                <v-list nopadding>
                    <v-list-item v-for="(item, index) in filterData.filterItems" :key="item.filterItemId + 'filter'" @click="fn_selectFilter(item.value)">

                        <v-list-item-content :key="index + item.name">
                          <v-list-item-title>{{item.name}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon v-if="item.value == currentFilterValue">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-subheader>Sort {{type}} By</v-subheader>

                    <v-divider></v-divider>

                    <v-list-item v-for="(item, index) in filterData.sortItems" :key="item.sortId + 'sort'" @click="fn_selectSort(item.value)">

                        <v-list-item-content :key="index + item.sortId">
                          <v-list-item-title>{{item.name}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon v-if="item.value == currentSortValue">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>

            <div class="ma-4">
              <v-btn color="blue darken-1" block outlined text @click="setFilter(currentFilterValue, currentSortValue)">Apply</v-btn>
              <!-- <v-btn color="blue darken-1" block outlined text @click="fn_closDialog">cancel</v-btn> -->
            </div>
            <!-- <v-btn color="blue darken-1" block outlined text @click="closeFilterNav">cancel</v-btn> -->
        </v-card>
    </div>
</template>

<script>
    export default{
        props:['type', 'filterData', 'setFilter', 'selectedFilterItemValue', 'selectedSortValue', 'closeFilterNav'],
        data(){
            return{
                currentFilterValue:null,
                currentSortValue:null
            }
        },
        methods:{
            fn_setCurrentFilterValues: function(){
                this.currentFilterValue = this.selectedFilterItemValue;
                this.currentSortValue = this.selectedSortValue;
            },
            fn_selectFilter: function(value){
                this.currentFilterValue = value;
            },
            fn_selectSort: function(value){
                this.currentSortValue = value;
            }
        }
    }
</script>