<template>
    <div>
        <v-dialog ref="dialog_datepicker" no-click-animation v-model="datepicker" :return-value.sync="selectedDate" persistent lazy full-width width="290px" transition="slide-y-transition">
            <!-- <v-date-picker v-model="selectedDate" :min="minDate ? minDate : $root.minDateSelection" :max="maxDate ? maxDate : $root.maxDateSelection" scrollable> -->
            <v-date-picker v-model="selectedDate" :min="minimumDate" :max="maximumDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="closeDialogWeb">Cancel</v-btn>
                <v-btn text color="primary" :disabled="disableBtn" @click="fn_saveDate(selectedDate)">OK</v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
// let currentDateObj = new Date();


export default{
    props:['selected', 'updateDate', 'minDate', 'maxDate'],
    data(){
        return{
            datepicker: false,
            selectedDate:new Date(),
            disableBtn:false,
            minimumDate:this.$root.minDateSelection,
			maximumDate:this.$root.maxDateSelection
        }
    },
    methods:{
        openDialogWeb: function () {
            let dateObj = null;
            if(this.selected){
                dateObj = new Date(this.selected);
            }
            else{
                dateObj = this.$parent.dateType == "start" ? new Date(this.$root.dataObj.startDate) : new Date(this.$root.dataObj.endDate);
            }
            this.selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
            this.minimumDate = this.minDate ? this.minDate : this.$root.minDateSelection,
			this.maximumDate = this.maxDate ? this.maxDate : this.$root.maxDateSelection
            this.datepicker = true;

            if(this.$parent.dateType && this.$parent.dateType.indexOf("dynamicFieldDate") != -1){
				this.minimumDate = null;
				this.maximumDate = null;
			}
        },
        closeDialogWeb: function () {
            this.datepicker = false;
        },
        // fn_minDateValidation: function(){
        //     if(this.$parent.dateType == "start"){
        //         return this.minDate ? this.minDate : this.$root.minDateSelection;
        //     }
        //     else{
        //         if(this.$parent.selectedStartDate){
        //             let sDate = new Date(this.$parent.selectedStartDate);
        //             return sDate.getFullYear()+'-'+("0" + (sDate.getMonth()+1)).slice(-2)+'-'+("0" + sDate.getDate()).slice(-2);
        //         }
        //         else{
        //             return this.minDate ? this.minDate : this.$root.minDateSelection;
        //         }
        //     }
        // },
        fn_saveDate: function (value) {
            this.disableBtn = true;
            let dateObj = new Date(value);
            this.$refs.dialog_datepicker.save(value);
            this.updateDate(dateObj);
        }
    }
}
</script>