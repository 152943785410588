<template>
    <div>
        <v-dialog v-model="dialog_upi" no-click-animation persistent lazy transition="slide-y-transition" min-height="400px" max-width="460px">

            <v-card>
                <v-toolbar flat :height="56">
                    <v-btn :disabled="loading" icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>{{isDisplay ? 'UPI Payment' : 'Add UPI ID'}}</v-toolbar-title>
                    <template v-if="isDisplay">
                      <v-spacer></v-spacer>
                      <v-btn :disabled="loading" icon @click="fn_getToken"><v-icon color="red">delete</v-icon></v-btn>
                      <v-btn :disabled="loading" icon @click="fn_editUpi"><v-icon>edit</v-icon></v-btn>
                    </template>
                </v-toolbar>

                <v-divider></v-divider> 

                <v-card-text full-height nopadding>

                  <v-progress-linear indeterminate :height="6" color="primary" v-if="loading"></v-progress-linear>

                  <template v-if="isDisplay">
                    <div center-align>
                        <p class="mb-1 mt-3 font20"><b>{{$root.CurrentBusiness.UPIName}}</b></p>
                        <p class="mb-0"><b>UPI ID:</b> {{$root.CurrentBusiness.UpiID}}</p>
                        <img :src="qrCode" style="width:200px"/>
                        <p font16>Scan the QR code using any UPI app</p>
                        <p class="mb-4 greencolor"><v-icon font20 class="greencolor">verified_user</v-icon> 100% Secure</p>
                        <p font20 class="pt-2 pb-2" style="border-top:1px #ddd solid;border-bottom:1px #ddd solid">{{$root.CurrentBusiness.BusinessName}}</p>

                        <div class="invoice-footer mb-2" style="display: flex;justify-content: center;">
                            <div class="branding grey-back" style="padding:0 !important;border-radius:5px;">
                              <a href="https://bizopsapp.com/" target="_blank">
                                <div style="padding:4px 8px;border-radius:5px;">
                                    <div class="dark-opacity-color right-align" style="font-size:9px !important;line-height:12px">Powered By</div>
                                    <div class="font16 title primary-color" style="line-height:18px;">
                                        <img class="brand_logo" src="../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo" style="width:16px;margin-right:4px;margin-bottom:-2px">BizOps App
                                    </div>
                                </div>
                              </a>
                            </div>
                        </div>
                    </div>
                  </template>

                  <template v-else>

                    <ValidationObserver ref="observer">
                        <v-form @submit.prevent="submit" :disabled="loading">

                            <v-layout ma-0 pt-4 row wrap white-back>

                                <v-flex xs12 sm12 md12 form-item>

                                    <ValidationProvider name="UPI Name" rules="required" v-slot="{ errors }">

                                      <v-text-field 
                                        id="field_upi"
                                        v-model="Form.UPIName" 
                                        autocomplete="null"
                                        label="UPI Name" 
                                        placeholder=" "
                                        :error-messages="errors"
                                        required>
                                      </v-text-field>

                                    </ValidationProvider>

                                </v-flex>

                                <v-flex xs12 sm12 md12 form-item>

                                    <ValidationProvider name="UPI" rules="required" v-slot="{ errors }">

                                      <v-text-field 
                                        id="field_upi"
                                        v-model="Form.UpiID" 
                                        autocomplete="null"
                                        label="UPI ID" 
                                        placeholder=" "
                                        :error-messages="errors"
                                        required>
                                      </v-text-field>

                                    </ValidationProvider>

                                </v-flex>

                            </v-layout>
                        
                        </v-form>
                    </ValidationObserver>
                  </template>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.native="closeDialogWeb" :disabled="loading">close</v-btn>
                    <v-btn color="blue darken-1" text :key="saveBtnKey" @click.once="fn_checkValidations" :disabled="loading" v-if="!isDisplay">ok</v-btn>
                </v-card-actions>
            </v-card>   
        </v-dialog>
    </div>
</template>     

<script>

import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('required', required);

import QRCode from 'qrcode'

// import * as htmlToImage from 'html-to-image';
// import { toPng } from 'html-to-image';

export default{
    data(){ 
        return{
            dialog_upi:false,
            Form:{"UpiID":null, "UPIName":null},
            isDisplay:false,
            qrCode:'',
            loading:false,
            saveBtnKey:1
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        openDialogWeb: function () {
          if(this.$root.CurrentBusiness.UpiID){
            this.isDisplay = true;
            // this.Form.UpiID = this.$root.CurrentBusiness.UpiID;
            this.fn_generateQrCode();
          }
          else{
            this.fn_editUpi();
          }
          
          this.dialog_upi = true;
        },

        closeDialogWeb: function () {
          this.dialog_upi = false;
        },

        fn_editUpi: function(){
          this.isDisplay = false;
          this.Form.UpiID = this.$root.CurrentBusiness.UpiID;
          this.Form.UPIName = this.$root.CurrentBusiness.UPIName;
          setTimeout(()=>{
            document.getElementById("field_upi").focus();
          },50);
        },

        fn_checkValidations: function(){
            this.$refs.observer.validate().then((result) => {
                if (result) {
                    // let self = this;
                    // this.showLoading("Please wait...");
                    this.loading = true;
                    this.$root.fn_getAuthToken(token=>{
                      if(token=='error'){
                        this.saveBtnKey++;
                        this.loading = false;
                        return
                      }
                      this.fn_saveUpiDetails(token);
                    });
                }
            });
        },

        fn_saveUpiDetails: function(token){
            if(!navigator.onLine){
                // alert('Internet not available');
                this.saveBtnKey++;
                this.$eventHub.$emit('snackbarAlert', {msg:'Internet not available'});
                // this.hideLoading();
                return;
            }

            let self = this;

            let postObj = {};
            postObj.UpiID = this.Form.UpiID;
            postObj.UPIName = this.Form.UPIName;
            // postObj.ShowInSalesPrintOuts = this.Form.ShowInSalesPrintOuts;
            postObj.BusinessID = this.$root.CurrentBusiness.BusinessID;
            postObj.token = token;

            console.log(JSON.stringify(postObj));

            this.postServerData('PostUpiId', postObj, {
                onSuccess: function(response){
                   console.log(response);
                   self.$root.CurrentBusiness.UpiID = self.Form.UpiID;
                   self.$root.CurrentBusiness.UPIName = self.Form.UPIName;
                   // self.$root.CurrentBusiness.ShowInSalesPrintOuts = self.Form.ShowInSalesPrintOuts;
                   self.loading = false;
                   self.closeDialogWeb();
                //    alert("Upi details saved");
                    self.$eventHub.$emit('snackbarAlert', {msg:'Upi details saved'});
                   // self.hideLoading();
                   // if(self.$route.query.edit){
                   //  self.fn_goBack();
                   // }
                   // else{
                   //  self.$router.replace({"path":"/settings/payment/upi/display"});
                   // }
                   
                }, 
                onError: function(error){
                   console.log(error);
                   // self.hideLoading();
                   self.loading = false;
                   self.saveBtnKey++;

                  try{
                      if(typeof JSON.parse(error) == 'object'){
                        let response = JSON.parse(error);
                        self.$eventHub.$emit('snackbarAlert', {msg:response.Message});
                      }
                  }
                  catch{
                    self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Try again"});
                  }
                   
                }, 
            })
        },

        // display code
        // fn_htmlToImage: function(){
        //   var node = document.getElementById('upi-card');

        //   let self = this;
        //   htmlToImage.toPng(node, {pixelRatio:1})
        //     .then(function (dataUrl) {
        //       console.log(dataUrl);
        //       self.fn_shareQrCode(dataUrl);
        //     })
        //     .catch(function (error) {
        //       console.error('oops, something went wrong', error);
        //     });
        // },

        fn_generateQrCode: function(){
          let self = this;
          let url = "upi://pay?pa="+this.$root.CurrentBusiness.UpiID+"&pn="+this.$root.CurrentBusiness.UPIName;
          // let url = "https://google.com"
          QRCode.toDataURL(url)
            .then(url => {
              console.log(url);
              self.qrCode = url;
            })
            .catch(err => {
              console.error(err)
          })
        },

        fn_getToken: function(){
          let self = this;
          this.loading = true;
          // this.showLoading("Please wait...");
          this.$root.fn_getAuthToken(function(token){
            if(token=='error'){
              self.loading = false;
              return
            }
            self.fn_removeUpiDetails(token);
          });
        },

        fn_removeUpiDetails: function(token){
          if(!navigator.onLine){
            //   alert('Internet not available');
                this.$eventHub.$emit('snackbarAlert', {msg:"Internet not available"});
              this.loading = false;
              // this.hideLoading();
              return;
          }

          let self = this;

          let postObj = {};
          postObj.businessId = this.$root.CurrentBusiness.BusinessID;
          postObj.token = token

          console.log(JSON.stringify(postObj));

          this.getServerData('RemoveUpiId', postObj, {
              onSuccess: function(response){
                 console.log(response);
                 self.$root.CurrentBusiness.UpiID = null;
                 self.$root.CurrentBusiness.UPIName = null;
                 self.loading = false;
                 self.closeDialogWeb();
                //  alert("Upi details removed");
                self.$eventHub.$emit('snackbarAlert', {msg:"Upi details removed"});
                 // self.hideLoading();
                 // self.fn_goBack();
              }, 
              onError: function(error){
                 console.log(error);
                 // self.hideLoading();
                 self.loading = false;
                 try{
                      if(typeof JSON.parse(error) == 'object'){
                        let response = JSON.parse(error);
                        self.$eventHub.$emit('snackbarAlert', {msg:response.Message});
                      }
                  }
                  catch{
                    self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Try again"});
                  }
              }, 
          })
        },
    }
}
</script>