<template>
    <div grey-back full-height>

        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

    </div>
</template>

<script>
import dialogForm from './dialogs/dialog_form'
// import dialogDraftItems from '../../../dialogs/dialog_draft_items'

    export default{
        // mixins: [draftMixin],
        data(){
            return{
                Form:{"productCategoryId":null, "name":null, "isSystem":0, "draftItems":[], "createdDate":null, "modifiedDate":null},
                loading:false,
                tagId:null,
                draftStorageId:null,

                snackbar:false,
                snackbar_msg:null,

                unwatch: false,
                // loadedFromDraft:false,
                backButtonPress:false,
                nextRoute:{"path":null, "query":null},
                disableOnSave:false
            }
        },
        // watch:{
        //  'Form': {
  //               handler: function (newVal, oldVal){
  //                   // this.fn_saveToLocalStorage(this)
  //                   if(!this.unwatch){
  //                        this.fn_saveDraft(this.Form.name);
  //                    }
  //                    this.unwatch = false;
  //               },
  //               deep: true
  //           }
        // },
        components:{
            'dialog-form':dialogForm
            // 'dialog-discard': dialogDiscard,
            // 'dialog-draft-items': dialogDraftItems
        },
        methods:{

            fn_closeDialogForm: function(){
                this.fn_goBack();
             },

            // fn_openDialogDiscard: function(){
            //  this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
            // },

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_closeDialog(){
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.save, menuItem.reset, menuItem.help]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            fn_checkValidations: function(){
                this.fn_post();
            },
            
            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('ProductCategory', dataObj, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          let status = JSON.parse(response);
                          if(status) {
                            self.fn_cleanUp();
                          }
                          else {
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            self.$eventHub.$emit('snackbarAlert', {msg:'Already exists with this name'});
                          }
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                        }
                    }
                );
                // this.fn_goBack();
            },

            fn_cleanUp: function () {

                // this.loadedFromDraft = false;

                if(this.$route.query.ls){
                    let data = {};
                    data.type = 'category';
                    data.id = this.Form.productCategoryId;
                    data.name = this.Form.name;
                    localStorage.setItem('newItem', JSON.stringify(data));
                    // localStorage.setItem('closeDialog', 1);
                }

                this.$eventHub.$emit('UpdateProductCategoryList', {});
                this.fn_goBack();
            },

            fn_post: function(){
                // !this.Form.productCategoryId ? this.Form.productCategoryId = this.guid() : '';
                if(!this.Form.productCategoryId){
                    this.Form.productCategoryId = this.guid();
                    // this.Form.createdDate = new Date();
                    this.Form.createdDate = this.$root.fn_getLocalDateFormat();
                }

                // this.Form.modifiedDate = new Date();
                this.Form.modifiedDate = this.$root.fn_getLocalDateFormat();
                
                this.Form.name = this.Form.name.trim();
                console.log(this.Form);
                this.fn_saveLocal(this.Form);
            },

            fn_get: function(itemId, copyData){
                var self = this;
                this.getData('ProductCategory', itemId, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          self.unwatch = true;
                          self.Form = JSON.parse(response);

                            if(copyData){
                                self.Form.productCategoryId=null;
                                self.Form.isSystem = 0;
                                // alert("Category copied");
                                self.$eventHub.$emit('snackbarAlert', {msg:'Category copied'});
                            }

                            // self.$root.fn_copyFormObject(self.Form, self.formId);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            // fn_clearForm: function(){
            //  this.Form = {"productCategoryId":null, "name":null, "isSystem":0, "draftItems":[], "createdDate":null, "modifiedDate":null};
            //  this.$validator.reset();
            // },

            // fn_saveToLocalStorage: function(){
            //  if(!this.unwatch){
            //      var dataObj = {};
            //      dataObj.type = "Category";
            //      dataObj.formType = "new";
            //      dataObj.modifiedDate = new Date();
            //      dataObj.formId = this.formId;
            //      dataObj.draftId = this.draftStorageId;
            //      dataObj.uri = this.$route.path;
            //      dataObj.dataModel = this.Form;
            //      localStorage.setItem(this.draftStorageId, JSON.stringify(dataObj)); 
            //  }
            //  this.unwatch = false;

            // },
            
            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use

            //  this.backButtonPress = false;
                
            //  if(menuAction=='save'){
            //      this.fn_checkValidations();
            //  }
            //  else if(menuAction=='reset'){
            //      this.fn_resetForm();
            //  }
            // },

            // fn_resetForm: function () {
            //  this.unwatch = true;
            //  // this.loadedFromDraft = false;

            //  if(this.Form.productCategoryId){
            //      this.fn_get(this.Form.productCategoryId);
            //  }
            //  else{
            //      this.fn_clearForm();
            //  }
                
            //  let self = this;
            //  this.fn_removeDraft(this.draftStorageId, function(){
            //      console.log("draft deleted");
            //      // self.showToast("Item deleted from draft");
            //  });
            // },
            
            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.ToolbarTitle = "Category";
            //  viewObject.TopMenu = [menuItem.save];
            //  viewObject.isBottomMenu = true;
            //  // viewObject.BottomMenu = [{"MenuID":2, "MenuType":null, "Icon":"save", "Text":"Save", "Action": "save"}]
            //  var tagId = this.formId;
            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null,
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            // fn_setFormDataFromLocalStorage: function () {
            //  if(localStorage.getItem(this.draftStorageId)){
            //      var formData = JSON.parse(localStorage.getItem(this.draftStorageId));
            //      this.Form = formData.dataModel;

            //      if((this.$route.query && this.$route.query.draft) || (this.$route.params && this.$route.params.tagId)){
            //          this.loadedFromDraft = true;
            //      }
            //  } 
            // },

            fn_goBack: function(){
                this.$root.viaKeyboardShortCut = false;
                this.$refs['ref_dialogForm'].closeDialogWeb();
                this.backButtonPress = true;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            }
        },
        mounted: function(){
            // var self = this;

            if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                // this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                // this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }

            // let formElement = document.getElementsByClassName("form-element")[0];

            // formElement.addEventListener("submit", function(e){
            //     e.preventDefault();
            // });


            // this.fn_setDraftData('Master', 'Product Category');
            // this.fn_setDraftData('temp', 'Product Category');
            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                // vm.enableToolbar();
                
                let itemId = null;
                let copyData = false;
                
                // to.query && to.query.copy && from.name == 'itemlist' ? copyData = true : '';
                to.query && to.query.copy ? copyData = true : '';

                // if(to.query && to.query.itemId && from.name == 'itemlist'){
                //     itemId = to.query.itemId;
                // }
                if(to.query && to.query.itemId){
                    itemId = to.query.itemId;
                }

                if(to.params && to.params.tagId){//from display view
                  itemId = to.params.tagId;
                }

                if(itemId){
                    vm.fn_get(itemId, copyData);
                }
                else{
                    vm.$root.fn_copyFormObject(vm.Form, vm.formId); 
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            itemId ? vm.fn_get(itemId, copyData) : '';
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });
            })
        },
        beforeRouteLeave (to, from, next) {
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('connected');
                next();
            }
        },
        // beforeRouteLeave (to, from, next) {
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  if(dialogData && dialogData.length){

        //      if(this.backButtonPress){
        //          dialogjs.fn_checkAndCloseOpenDialogs(this);
        //          next(false);
        //      }
        //      else{
        //          // this.$root.fn_deleteFormObject();
        //          this.fn_saveDraft(this.Form.name);
        //          next(true);
        //      }
        //  }
        //  else{
        //      // if(this.askToDiscard){
        //      //  this.fn_openDialogDiscard();
        //      //  next(false);
        //      // }
        //      if(this.fn_showDialogDiscard()){
        //          this.fn_openDialogDiscard();
        //          next(false);
        //      }
        //      else{
        //          this.fn_clearData(null, function(){
        //              next();
        //          })
        //      }
        //      // if(this.loadedFromDraft || (this.$root.fn_getFormObject(this.formId) && !this.$root.fn_deepEqual(this.Form, JSON.parse(this.$root.fn_getFormObject(this.formId))))){
        //      //  this.fn_openDialogDiscard();
        //      //  next(false);
        //      // }
        //      // else{
        //      //  this.$root.fn_deleteFormObject(this.formId);
        //      //  !this.askToDiscard ? this.fn_removeDraft(this.draftStorageId, null, -1) : '';
        //      //  next();
        //      // }
                
        //  }
     //    }
    }

</script>