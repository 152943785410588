<template>
  <div>

    <v-toolbar :height="56" flat extended>
          <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

          <v-toolbar-title>Tax Category</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon color="primary" @click="fn_getItems"><v-icon>autorenew</v-icon></v-btn>

          <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

          <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" slot="extension"></v-text-field>
      </v-toolbar>

    <!-- <div class="display_msg" v-if="!filteredItems.length">No Items</div> -->

    <div class="data-container grey-back hasExtention">
       <v-list nopadding grey-back dense>

          <v-subheader white-back class="pl-4">Count: {{filteredItems.length}}</v-subheader>

          <v-divider></v-divider>

          <template white-back v-for="(item, index) in filteredItems">
            <v-list-item white-back :key="index" :id="'item_'+item.taxCategoryId">
                <v-list-item-content cursor-pointer @click="fn_itemClick(item.taxCategoryId, index)">
                  <v-list-item-title v-text="item.taxCategoryName"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-menu dense left>
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                      </template>
                      <v-list nopadding dense>
                          <v-list-item @click="fn_copy(item.taxCategoryId)">
                          <v-list-item-title>Copy</v-list-item-title>
                          </v-list-item>
                      </v-list>
                  </v-menu>
                    <!-- <v-btn icon small @click="fn_copy(item.taxCategoryId)"><v-icon font20>content_copy</v-icon></v-btn> -->
                </v-list-item-icon>
            </v-list-item>

            <v-divider v-if="filteredItems.length-1 != index" :key="item.taxCategoryName"></v-divider>
          </template>


      </v-list>

      <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
        <v-col cols="6">
          <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
        </v-col>
      </v-row>

      <div center-align class="pa-6" v-if="loadTimeOut">
          <p font12 light-opacity-color>Unable to load the items!</p>
          <v-btn capitalize text color="primary" @click="fn_getItems">
              <v-icon left>refresh</v-icon>
              Retry
          </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../../config.js').configs.bottomSheetMenu;
import {masterMixin} from '../../master_mixin.js'

  export default{
    mixins: [masterMixin],
    data(){
      return{
        listItems:[],
        searchValue:'',
        scrollPositionTop:0,
        selectedItemId:null
      }
    },
    methods:{
      fn_new: function () {
        // this.$router.push({path:"/tax/category/items/new", query:{tagId:this.guidWeb()}});
        this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/tax_category/items/new', query:{tagId:this.guidWeb()}});
      },

      fn_copy: function(itemId){
        this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/tax_category/items/new', query:{tagId:this.guidWeb(), itemId:itemId, copy:true}});
      },

      fn_drafts: function(){
        this.$router.push({path:'/drafts', query:{tabIndex:0}});
      },

      fn_goBack: function(){
        this.$router.replace("/"+this.$root.CurrentBusiness.BusinessID+"/master_items");
      },

      fn_itemClick: function (itemId) {
        // tagId id form future use
        // itemName id form future use
        // this.$router.push("/tax/category/items/"+itemId);
        this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/tax_category/items/'+itemId);
      },


      fn_getItems: function () {
        this.loadMore = true;

        let filterObject = {};

        this.fn_loadTimeOut();

        let self = this;
        this.getItems("TaxCategoryList", filterObject, 
          {
            onSuccess: function (response) {
              self.loadTimeOut = false;
              console.log(response);
              self.listItems = JSON.parse(response);
              self.loadMore = false;
            },
            onError: function (error) {
              console.log(error);
              self.loadMore = false;
            }
          }
        )
      },

    },
    mounted: function () {
      this.fn_getItems();

      let self = this;
      document.addEventListener('scroll', function () {
        self.scrollPositionTop = window.pageYOffset
      })

      this.$eventHub.$on('UpdateTaxCategoryList', dataObj => {
          console.log(dataObj);
          this.fn_getItems();
      });
    },
    computed:{
      filteredItems: function () {
        let searchText = this.searchValue ? this.searchValue.toLowerCase() : '';

        return this.listItems.filter(item => {
          let taxCategoryName = item.taxCategoryName.toLowerCase();
          return taxCategoryName.match(searchText);
        })
      }
    }
  }
</script>