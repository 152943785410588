<template>
    <div class="display_wrapper">

        <v-toolbar :height="56" flat>

            <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>

            <v-toolbar-title>Expense Category</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn outlined small @click="fn_edit" color="primary" v-if="displayData && !displayData.isSystem">
                <v-icon left>edit</v-icon> Edit
            </v-btn>

            <v-btn class="ml-4" small outlined @click="fn_openDialogConfirmation" color="red" v-if="displayData && !displayData.isSystem">
                <v-icon left>delete</v-icon> Delete
            </v-btn>

            <!-- <v-btn icon @click="fn_getBottomMenu">
                <v-icon>more_vert</v-icon>
            </v-btn> -->
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back" v-if="displayData" v-scroll.self="onScroll">
            <div class="title pa-4 white-back">{{displayData.name}}</div>

            <!-- <v-list class="mt-2 nopadding" two-line>
                <v-subheader light>Details</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Ledger</v-list-item-title>
                        <v-list-item-subtitle>{{displayData.accountLedgerName}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list> -->

            <v-tabs v-model="activeIndex" slider-color="primary">
                <v-tab v-for="(tab, index) in tabs" :key="index" ripple>{{tab.title}}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="activeIndex">
                <v-tab-item>  
                    <activity-tab ref="ref_activity" :expenseCategoryId="displayData.expenseCategoryId" v-if="displayData"></activity-tab>
                </v-tab-item>
                <v-tab-item>
                    <details-tab ref="ref_details" :data="displayData" v-if="displayData"></details-tab>
                </v-tab-item>
            </v-tabs-items>

        </div>

        <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);" v-if="loading">
          <v-col cols="6">
            <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
          </v-col>
        </v-row>

        <dialog-confirmation ref="ref_dialogDeleteConfirmation" header="Delete Master?" :actionMethod="fn_deleteMaster" params="delete" :displayView='true'></dialog-confirmation>

    </div>
</template>

<script>
// const menuItem = require('../../../../config.js').configs.menuItem;
import {masterMixin} from '../../master_mixin.js'

import activityTab from './display_tabs/activity'
import detailsTab from './display_tabs/details'

import dialogConfirmation from '../../../dialogs/dialog_confirmation'

    export default{
        mixins: [masterMixin],
        data(){
            return{
                displayData:null,
                loading:false,
                tabs:[
                    {"id":1, "title":"Activity"},
                    {"id":2, "title":"Details"}
                ],
                activeIndex:0,
                offsetTop: 0,
                printData:null,
                printTemplate:null,
            }
        },
        components:{
            'dialog-confirmation': dialogConfirmation,
            'activity-tab': activityTab,
            'details-tab': detailsTab
        },
        methods:{

            fn_get: function(itemId){
                var self = this;
                this.loading = true;
                this.displayData = null;
                this.getData('ExpenseCategory', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.displayData = JSON.parse(response);
                            self.loading = false;

                            setTimeout(function(){
                                self.$refs['ref_activity'].fn_loadItems(true);
                            },100);

                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                        }
                    }
                );
            },

            fn_openDialogConfirmation: function(){
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            },

            fn_deleteMaster: function(){
                let self = this;
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = false;
                this.fn_deleteMasterItem('expenseCategory', this.displayData.expenseCategoryId, function(response){
                    if(response){
                        // alert('Item deleted');
                        self.$eventHub.$emit('snackbarAlert', {msg:'Item deleted'});
                        self.$eventHub.$emit('UpdateExpenseCategory', {});
                        self.$router.go(-1);
                    }
                    else{
                        // alert("Item cannot be deleted as already in use");
                        self.$eventHub.$emit('snackbarAlert', {msg:'Item cannot be deleted as already in use'});
                    }
                })
            },

            fn_edit: function () {
                this.$router.push('/'+this.$route.params.businessId+'/'+this.$route.params.listId+'/expense_category/items/'+this.displayData.expenseCategoryId+'/edit');
            },

            onScroll: function(event){
                var element = event.target;

                if(element.scrollTop>100){
                    this.fixedTabs = true;
                }
                else{
                    this.fixedTabs = false;
                }

                if (element.scrollHeight - element.scrollTop - 20 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.$refs['ref_activity'].listItemObject.items.length < this.$refs['ref_activity'].listItemObject.totalCount){
                        this.$refs['ref_activity'].fn_loadItems();
                    }
                }
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted: function(){
            // this.fn_showWebView();

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_goBack = this.fn_goBack;
            
            if(this.$route.params && this.$route.params.tagId){
                this.fn_get(this.$route.params.tagId);
            }

            this.$eventHub.$on('delete', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_openDialogConfirmation() : '';
            });

            this.$eventHub.$on('edit', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_edit() : '';
            });

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteUpdate(to, from, next){
            // alert('up');
            console.log(from);
            next(true)
            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }  

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('connected');
            next(true)
        },
    }

</script>