<template>
  <div>

    <v-toolbar :height="56" flat>
        <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn>
        <v-toolbar-title>Print Fields</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn> -->
        <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
    </v-toolbar>

    <div class="display_msg center-align pa-4" v-if="!filteredItems.length && !loadMore && !loadTimeOut">No Items</div>

    <div class="data-container grey-back hasExtention">
      <v-list dense nopadding>
        <div v-for="(item, index) in filteredItems" :key="index">
          <v-list-item @click="fn_itemClick(item)">
            <v-list-item-content>
              <v-list-item-title>{{item.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="filteredItems.length-1 != index"></v-divider>
        </div>
      </v-list> 

      <div center-align class="pa-6" v-if="loadTimeOut">
          <p font12 light-opacity-color>Unable to load the items!</p>
          <v-btn capitalize text color="primary" @click="fn_getItems">
              <v-icon left>refresh</v-icon>
              Retry
          </v-btn>
      </div>

    </div>

  </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../config.js').configs.bottomSheetMenu;
import {masterMixin} from '../../masters/master_mixin.js'

  export default{
    mixins: [masterMixin],
    data(){
      return{
        listItems:[],
        searchValue:'',
        // scrollPositionTop:0,
        selectedItemId:null,
        createNew:false,
        loadMore:false,
        loadTimeOut:false
      }
    },
    methods:{
      fn_new: function () {
        let parentId = this.$route.query.parentId ? this.$route.query.parentId : null;
        // this.$router.push({path:"/printfield/items/new", query:{tagId:this.guidWeb(), parentId:parentId}});
        this.$router.push({path:"/" + this.$root.CurrentBusiness.BusinessID + "/" + this.$route.params.listId + "/printfields/items/new", query:{tagId:this.guidWeb(), parentId:parentId}});
      },

      fn_goBack: function(){
        this.$router.go(-1);
      },

      fn_menuItemClick: function(tagId, menuId, menuAction){
        // tagId id form future use
        // menuId id form future use
        if(menuAction=='new'){
          this.fn_new();
        }
        else if(menuAction=='edit'){
          this.$router.push("/fields/"+this.selectedItemId+"/edit");
        }
        else if(menuAction=='copy'){
          this.$router.push({path:'/fields/new', query:{tagId:this.guidWeb(), itemId:this.selectedItemId, copy:true}});
        }
      },

      fn_itemClick: function (item) {
        // tagId id form future use
        // itemName id form future use
        if(item.isParent){
          this.$router.push({path:"/" + this.$root.CurrentBusiness.BusinessID + "/print_field_terms", query:{new:false, parentId:item.fieldId}});
        }
        else{
          // this.$router.push({path:"/printfield/items/"+item.fieldId+"/edit", query:{disableName: this.createNew ? false : true}});
          this.$router.push({path:"/" + this.$root.CurrentBusiness.BusinessID + "/" + this.$route.params.listId + "/printfields/items/" + item.fieldId + "/edit"});
        }
      },


      fn_getItems: function () {
        this.loadMore = true;
        let filterObject = {};
        filterObject.groupTypeId = 102;
        filterObject.parentId = this.$route.query && this.$route.query.parentId ? this.$route.query.parentId : null;
        // filterObject.limit = 10;
        // filterObject.startIndex = this.startIndex;
        // filterObject.search = this.searchValue.toLowerCase();

        this.fn_loadTimeOut();

        let self = this;
        this.getItems("FieldList", filterObject, 
          {
            onSuccess: function (response) {
              console.log(response);
              self.listItems = JSON.parse(response);
              self.loadMore = false;
              self.loadTimeOut = false;
              // for(let i=0; i<self.listItems.length; i++){
              //   self.listItems[i].fieldType = JSON.parse(self.listItems[i].fieldType)
              // }
            },
            onError: function (error) {
              console.log(error);
              self.loadMore = false;
              self.loadTimeOut = false;
            }
          }
        )
      },

      fn_loadTimeOut: function(){
        setTimeout(() => {
            if(this.loadMore){
              this.loadTimeOut = true;
              this.loadMore = false;
            }
            else{
              this.loadTimeOut = false;
            }
        }, 1 * 10 * 1000);
      }

    },
    mounted: function () {
      // tagId = this.$route.query.tagId;
      this.createNew = this.$route.query.new ? JSON.parse(this.$route.query.new) : false;
      this.fn_getItems();
    },
    beforeRouteUpdate (to, from, next){
      // console.log(from);
      this.createNew = to.query.new ? JSON.parse(to.query.new) : false;
      this.fn_getItems();
      next();
    },
    computed:{
      filteredItems: function () {
        let searchText = this.searchValue ? this.searchValue.toLowerCase() : '';

        return this.listItems.filter(item => {
          let fieldItem = item.name.toLowerCase();
          return fieldItem.match(searchText);
        })
      }
    }
  }
</script>