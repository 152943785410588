<template>
    <div>
        <v-dialog v-model="dialog_discard" persistent no-click-animation transition="slide-y-transition" max-width="290" z-index="999">
          <v-card>
              <v-card-title class="title noshadow pt-2 pb-2 pl-4 pr-4">
                  Unsaved Changes
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeDialogWeb" id="close_btn"><v-icon>close</v-icon></v-btn>
              </v-card-title>
            <v-card-text class="pt-0 pl-4 pr-4 pb-5">Do you want to discard changes and close form?</v-card-text>
            <v-divider></v-divider>
            <!-- Data has been saved as draft. What would you like to do? -->
            <v-card-actions center-align class="pt-2 pb-2 pl-0 pr-0">
                <v-layout wrap>
                    <!-- <v-flex xs4 sm4 md4 center-align greencolor :class="[hideKeepDraft ? 'xs6 sm6 md6' : 'xs4 sm4 md4']" v-if="!hideKeepDraft" style="border-right:1px #ddd solid">
                        <v-btn font-heavy small capitalize dark-opacity-color text @click="draftAction(false)">Save Draft</v-btn>
                    </v-flex>
                    <v-flex xs4 sm4 md4 center-align redcolor :class="[hideKeepDraft ? 'xs6 sm6 md6' : 'xs4 sm4 md4']" style="border-right:1px #ddd solid">
                        <v-btn font-heavy small capitalize color="red darken-1" text @click="draftAction(true)">Discard</v-btn>
                    </v-flex>
                    <v-flex xs4 sm4 md4 center-align :class="[hideKeepDraft ? 'xs6 sm6 md6' : 'xs4 sm4 md4']">
                        <v-btn font-heavy small capitalize class="primary-color" text @click="fn_save">Save</v-btn>    
                    </v-flex> -->
                    <v-flex xs6 sm6 md6 center-align style="border-right:1px #ddd solid">
                        <v-btn font-heavy small capitalize color="red darken-1" text @click="draftAction">Yes</v-btn>
                    </v-flex>
                    <v-flex xs6 sm6 md6 center-align>
                        <v-btn font-heavy small capitalize class="primary-color" text @click="closeDialogWeb">No</v-btn>    
                    </v-flex>
                </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>	 	

<script>

export default{
    props:['draftAction', 'save', 'hideKeepDraft'],
    data(){	
        return{
            dialog_discard: false,
        }
    },
    methods: {
        openDialogWeb: function () {	
            this.dialog_discard = true;
            setTimeout(() => {
                document.getElementById("close_btn").focus();
            }, 100);
        },
        closeDialogWeb: function () {
            this.dialog_discard = false;
        },
        fn_save: function(){
            this.closeDialogWeb();
            let self = this;
            setTimeout(function(){
                self.save();
            },300)
        }
    }
}
</script>