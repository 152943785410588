fn<template>
  <div>
    <v-toolbar :height="56" flat extended>
        <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

        <v-toolbar-title>Ledger Opening Balance</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

        <v-btn icon color="primary" @click="fn_new"><v-icon>add</v-icon></v-btn>

        <!-- <v-btn icon @click="fn_getReportData" v-if="listItemObject.totalCount"><v-icon>vertical_align_bottom</v-icon></v-btn>
        <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn> -->

        <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
    </v-toolbar>

    <div class="data-container grey-back hasExtention" v-scroll.self="onScroll">


      <v-list two-line nopadding>

        <v-subheader white-back subheader-ultra-dense>
          Count: {{$root.numberToLocale(listItemObject.totalCount, 1)}}
        </v-subheader>

        <v-divider></v-divider>

        <!-- <div v-if="listItemObject.totalCount">
          <v-divider></v-divider>
            <v-subheader subheader-ultra-dense>Count: {{listItemObject.totalCount}}</v-subheader>
          <v-divider></v-divider>
        </div> -->

        <!-- {{ledgerSummary}} -->

        <div v-for="(item, index) in listItemObject.items" :key="index">
          <v-list-item class="right-subtitle-tile" @click="fn_itemClick(item.transactionId)">
              <v-list-item-content>
                <v-list-item-title class="mb-1">{{item.accountLedgerName}}</v-list-item-title>
                <!-- <v-list-item-subtitle class="mb-1">
                  <span class="right_subtitle">
                    &#8377; {{$root.numberToLocale(item.amount)}}
                    <v-icon font18 :class="[item.amountType!=0 ? 'greencolor' : 'redcolor']">{{item.amountType!=0 ? 'arrow_downward' : 'arrow_upward'}}</v-icon>
                  </span> 
                </v-list-item-subtitle> -->
                <v-list-item-subtitle>{{item.accountGroupName}}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <!-- <v-btn icon text @click="fn_getBottomMenu(902, item.transactionId)"><v-icon>more_horiz</v-icon></v-btn> -->
                &#8377; {{$root.numberToLocale(item.amount)}}
                    <v-icon font18 :class="[item.amountType!=0 ? 'greencolor' : 'redcolor']">{{item.amountType!=0 ? 'arrow_downward' : 'arrow_upward'}}</v-icon>
              </v-list-item-action>
          </v-list-item>

          <v-divider v-if="listItemObject.items.length-1 != index"></v-divider>
        </div>


      </v-list>

      <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
        <v-col cols="6">
          <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
        </v-col>
      </v-row>

      <div center-align class="pa-6" v-if="loadTimeOut">
          <p font12 light-opacity-color>Unable to load the items!</p>
          <v-btn capitalize text color="primary" @click="fn_searchItem">
              <v-icon left>refresh</v-icon>
              Retry
          </v-btn>
      </div>
      <!-- <div class="display_msg" v-if="!listItemObject.items.length">There are no items</div> -->
    </div>


    <v-footer white-back class="pl-3 pr-3" style="border-top:1px #F0F0F0 solid;height:auto;position:sticky;bottom:0">
      <v-layout ma-0 wrap align-center>
        <v-flex xs2 sm2 md2 style="text-align:left" v-ripple>
          <!-- <span>Total<v-icon style="position:absolute">keyboard_arrow_up</v-icon></span> -->
          <v-menu offset-y :min-width="340">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">Total<v-icon style="position:absolute">keyboard_arrow_up</v-icon></span>
            </template>

            <v-card flat>
              <v-card-text class="pa-0 font12">
                <v-subheader>
                  <v-layout wrap align-center style="align-items:baseline">
                    <v-flex dark-opacity-color xs4 sm4 md4 style="text-align:left" v-ripple @click="total_sheet=true">
                      <span>Total</span>
                    </v-flex>
                    <v-flex dark-opacity-color xs4 sm4 md4 style="text-align:right">
                      To Receive<br>
                      <b>&#8377; {{$root.numberToLocale(stockValueAndDebitTotal)}}</b>
                    </v-flex>
                    <v-flex dark-opacity-color xs4 sm4 md4 style="text-align:right">
                      To Pay<br>
                      <b>&#8377; {{$root.numberToLocale(creditTotal)}}</b>
                    </v-flex>
                  </v-layout>
                </v-subheader>

                <v-divider></v-divider>

                <v-layout ma-0 wrap align-center style="padding:5px 16px">
                  <v-flex xs4 sm4 md4 style="text-align:left" v-ripple>
                    <span>Stock Value</span>
                  </v-flex>
                  <v-flex xs4 sm4 md4 style="text-align:right">
                    <b>&#8377; {{$root.numberToLocale(stockSummary.totalAmount)}}</b>
                  </v-flex>
                  <v-flex xs4 sm4 md4 style="text-align:right"></v-flex>
                </v-layout>

                <v-divider></v-divider>

                <v-layout ma-0 wrap align-center style="padding:5px 16px">
                  <v-flex xs4 sm4 md4 style="text-align:left" v-ripple>
                    <span>Ledger Value</span>
                  </v-flex>
                  <v-flex xs4 sm4 md4 style="text-align:right">
                    <b>&#8377; {{$root.numberToLocale(debitTotal)}}</b>
                  </v-flex>
                  <v-flex xs4 sm4 md4 style="text-align:right">
                    <b>&#8377; {{$root.numberToLocale(creditTotal)}}</b>
                  </v-flex>
                </v-layout>

                <v-divider></v-divider>

                <v-layout ma-0 wrap align-center style="padding:5px 16px">
                  <v-flex xs4 sm4 md4 style="text-align:left" v-ripple>
                    <span>Difference</span>
                  </v-flex>
                  <v-flex xs4 sm4 md4 style="text-align:right">
                    <span v-if="stockValueAndDebitTotal  > creditTotal"><b>&#8377; {{$root.numberToLocale(difference)}}</b></span>
                  </v-flex>
                  <v-flex xs4 sm4 md4 style="text-align:right">
                    <span v-if="creditTotal  > stockValueAndDebitTotal"><b>&#8377; {{$root.numberToLocale(difference)}}</b></span>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-menu>

        </v-flex>
        <v-flex xs5 sm5 md5 style="text-align:right">
          To Receive<br>
          <b>&#8377; {{$root.numberToLocale(stockValueAndDebitTotal)}}</b>
        </v-flex>
        <v-flex xs5 sm5 md5 style="text-align:right">
          To Pay<br>
          <b>&#8377; {{$root.numberToLocale(creditTotal)}}</b>
        </v-flex>
      </v-layout>
    </v-footer>

    <v-bottom-sheet v-model="total_sheet">
      <v-list nopadding>

        <v-subheader @click="total_sheet=false">
          <v-layout ma-0 wrap align-center>
            <v-flex xs2 sm2 md2 style="text-align:left" v-ripple @click="total_sheet=true">
              <span>Total<v-icon style="position:absolute">keyboard_arrow_down</v-icon></span>
            </v-flex>
            <v-flex xs5 sm5 md5 style="text-align:right">
              <b>&#8377; {{$root.numberToLocale(stockValueAndDebitTotal)}} <v-icon font18 class="greencolor">arrow_downward</v-icon></b>
            </v-flex>
            <v-flex xs5 sm5 md5 style="text-align:right">
              <b>&#8377; {{$root.numberToLocale(creditTotal)}} <v-icon font18 class="redcolor">arrow_upward</v-icon></b>
            </v-flex>
          </v-layout>
        </v-subheader>

        <v-divider></v-divider>

        <v-layout ma-0 wrap align-center style="padding:5px 16px">
          <v-flex xs2 sm2 md2 style="text-align:left" v-ripple>
            <span>Stock Value</span>
          </v-flex>
          <v-flex xs5 sm5 md5 style="text-align:right">
            <b>&#8377; {{$root.numberToLocale(stockSummary.totalAmount)}}</b>
          </v-flex>
          <v-flex xs5 sm5 md5 style="text-align:right"></v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout ma-0 wrap align-center style="padding:5px 16px">
          <v-flex xs2 sm2 md2 style="text-align:left" v-ripple>
            <span>Ledger Value</span>
          </v-flex>
          <v-flex xs5 sm5 md5 style="text-align:right">
            <b>&#8377; {{$root.numberToLocale(debitTotal)}}</b>
          </v-flex>
          <v-flex xs5 sm5 md5 style="text-align:right">
            <b>&#8377; {{$root.numberToLocale(creditTotal)}}</b>
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout ma-0 wrap align-center style="padding:5px 16px">
          <v-flex xs2 sm2 md2 style="text-align:left" v-ripple>
            <span>Difference</span>
          </v-flex>
          <v-flex xs5 sm5 md5 style="text-align:right">
            <span v-if="stockValueAndDebitTotal  > creditTotal"><b>&#8377; {{$root.numberToLocale(difference)}}</b></span>
          </v-flex>
          <v-flex xs5 sm5 md5 style="text-align:right">
            <span v-if="creditTotal  > stockValueAndDebitTotal"><b>&#8377; {{$root.numberToLocale(difference)}}</b></span>
          </v-flex>
        </v-layout>



        <!-- <v-list-item>
          <v-list-item-content><v-list-item-title>Stock Value</v-list-item-title></v-list-item-content>
          <v-list-item-action>&#8377; {{stockSummary.totalAmount}} Dr</v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content><v-list-item-title>Difference</v-list-item-title></v-list-item-content>
          <v-list-item-action>&#8377; {{difference}} {{creditTotal  > debitTotal ? 'Cr' : 'Dr'}}</v-list-item-action>
        </v-list-item> -->
      </v-list>
    </v-bottom-sheet>

    <!-- <dialog-drive-alert ref="ref_dialogDriveAlert"></dialog-drive-alert> -->
  </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../../config.js').configs.bottomSheetMenu;
// const transactionForms = require('../../../../config.js').configs.transactionForms;

// import dialogDriveAlert from '../dialogs/dialog_drive_alert'
// import InfiniteLoading from 'vue-infinite-loading';

  export default{
    props:['settings'],
    // components: {
    //     // InfiniteLoading,
    //     'dialog-drive-alert':dialogDriveAlert
    // },
    data(){
      return{
        toolbarTitle:null,
        listItemObject:{"items":[], "totalCount":0},
        searchValue:'',
        infiniteId: +new Date(),
        startIndex:0,
        scrollPositionTop:0,
        selectedItemId:null,
        ledgerSummary: {"items":[]},
        total_sheet:false,
        stockSummary: {totalAmount:0, unitItems:[]},
        loadMore:false,
        loadTimeOut:false,
        showPlaceHolder:false
      }
    },
    methods:{

      onScroll (event) {
        var element = event.target;
        if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight)
        {
            console.log('scrolled');
            if(this.listItemObject.items.length < this.listItemObject.totalCount){
              this.fn_loadItems();
            }
        }
      },

      fn_drafts: function(){
        this.$router.push({path:'/drafts', query:{tabIndex:1}});
      },
      // fn_initializeSettings: function () {
      //   transactionForms.filter(item => {
      //     if(item.id == this.$route.query.transactionTypeId){
      //       this.toolbarTitle = item.title;
      //     }
      //   })
      // },
      fn_goBack: function(){
        this.$router.go(-1);
        // this.$router.replace("/"+this.$root.CurrentBusiness.BusinessID+"/master_items");
      },
      // fn_menuItemClick: function(tagId, menuId, menuAction){
      //   // tagId id form future use
      //   // menuId id form future use
      //   if(menuAction=='new'){
      //     // this.fn_new();
      //   }
      //   else if(menuAction=='edit'){
      //     this.$router.push('/transactions/inventory/openingledger/items/'+this.selectedItemId+'/edit');
      //   }
      // },

      // fn_checkDrive: function(){
      //   let self = this;
      //   if(this.$root.CurrentBusiness.DataDrive){
      //     this.fn_new();
      //   }
      //   else{
      //     this.$root.fn_getTransactionCount(function(count){
      //       if(count>=10){
      //         self.$refs['ref_dialogDriveAlert'].dialog_setup_drive = true;
      //       }
      //       else{
      //         self.fn_new();
      //       }
      //     })
      //   }
      // },

      fn_new: function () {
        // localStorage.setItem("SetDataDrive", 1);
        this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/openingledger/items/new', query:{tagId:this.guidWeb()}});
      },

      fn_itemClick: function (itemId) {
        // tagId id form future use
        // itemName id form future use
        // this.$router.push('/transactions/inventory/openingledger/items/'+itemId);
        this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/openingledger/items/'+itemId);
      },

      // fn_getBottomMenu: function (elementId, itemId) {
      //   itemId ? this.selectedItemId = itemId : '';

      //   let bottomMenu;

      //   // if(elementId == bottomSheetMenu.toolBar.Id){
      //   //   bottomMenu = {"BottomMenu": [menuItem.help]};
      //   // }
      //   if(elementId == bottomSheetMenu.masterListItems.Id){
      //     bottomMenu = {"BottomMenu": [menuItem.edit]};
      //   }
        
      //   this.setBottomMenu(tagId, bottomMenu, 
      //     {
      //       onSuccess: function (response) {
      //         console.log(response);
      //       },
      //       onError: function (error) {
      //         console.log(error);
      //       }
      //     }
      //   )
      // },

      // infiniteHandler($state) {
      //   let filterObject = {};
      //   filterObject.type = this.$route.query.transactionTypeId;
      //   filterObject.limit = 10;
      //   filterObject.startIndex = this.startIndex;
      //   filterObject.search = this.searchValue.toLowerCase();

      //   let self = this;
      //   this.getItems("OpeningLedgerList", filterObject, 
      //     {
      //       onSuccess: function (response) {
      //         console.log(response);
      //         let responseJson = JSON.parse(response);
      //         self.listItemObject.totalCount = responseJson.totalCount;
      //         self.fn_getSummary(filterObject);

      //         for(let i in responseJson.items){
      //           self.listItemObject.items.push(responseJson.items[i]);  
      //         }

      //         self.startIndex = self.listItemObject.items.length;

      //         if(self.listItemObject.items.length < self.listItemObject.totalCount){
      //           $state.loaded();
      //         }
      //         else{
      //           $state.complete();
      //         }
      //       },
      //       onError: function (error) {
      //         console.log(error);
      //       }
      //     }
      //   )
      // },


     // fn_searchItem: function () {
     //    this.startIndex = 0;
     //    this.infiniteId += 1;
     //    this.listItemObject.items = [];
     //    // this.fn_getItems(searchValue, 0);
     //  },

     fn_loadItems(search) {
        this.loadMore = true;
        let filterObject = {};
        filterObject.type = 40003;
        filterObject.limit = 50;
        filterObject.startIndex = this.startIndex;
        filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';

        this.fn_loadTimeOut();

        let self = this;
        this.getItems("OpeningLedgerList", filterObject, 
          {
            onSuccess: function (response) {
              self.loadTimeOut = false;
              console.log(response);
              let responseJson = JSON.parse(response);
              self.listItemObject.totalCount = responseJson.totalCount;
              self.fn_getSummary(filterObject);

              search ? self.listItemObject.items = [] : '';
              for(let i in responseJson.items){
                self.listItemObject.items.push(responseJson.items[i]);
              }

              self.startIndex = self.listItemObject.items.length;
              self.loadMore = false;

              setTimeout(function(){
                self.showPlaceHolder = true;
              },300);
            },
            onError: function (error) {
              console.log(error);
              self.loadMore = false;
              self.loadTimeOut = false;
            }
          }
        )
      },

      fn_loadTimeOut: function(){
          setTimeout(() => {
              if(this.loadMore){
                this.loadTimeOut = true;
                this.loadMore = false;
              }
              else{
                this.loadTimeOut = false;
              }
          }, 1 * 10 * 1000);
      },

     fn_searchItem: function () {
        this.startIndex = 0;
        this.listItemObject.items = [];
        this.fn_loadItems(true);
      },

      fn_debounceSearch: function(){
           if (this.timeoutid != null){
              clearTimeout(this.timeoutid);
           }

          this.timeoutid = setTimeout(() => { 
              this.fn_searchItem();
          },100);
      },

      fn_getSummary: function (filterObject) {
        let self = this;
        this.getItems('LedgerSummaryList', filterObject, 
        {
          onSuccess: function (response) {
            console.log(response);
            self.ledgerSummary = JSON.parse(response);
            self.fn_getStockSummary();
          },
          onError: function (error) {
            console.log(error);
          }
        })
      },

      fn_getStockSummary: function () {
        var self = this;
        let filterObject = {};
        filterObject.type = 40001;
        filterObject.search = '';
        this.getItems('OpeningStockSummaryList', filterObject,
        {
            onSuccess: function(response){
              self.stockSummary = JSON.parse(response);
              self.stockSummary.totalAmount = self.roundUpto2Decimals(self.stockSummary.totalAmount);
              console.log(response);
            }, 
            onError: function(error){
              console.log(error);
            }
        });
      },

      // fn_showWebView: function () {
      //   let viewObject = {};
      //   let self = this;

      //   this.showWebView(tagId, viewObject, 
      //     {
      //       onSuccess: function(response){
      //             console.log(response);
      //           }, 
      //           onError: function(error){
      //             console.log(error);
      //           }, 
      //           // onChange: null, 
      //           onMenuItemClick: function (tagId, menuId, menuAction) {
      //         self.fn_menuItemClick(tagId, menuId, menuAction)
      //       }
      //     }
      //   );
      // },
    },
    mounted: function(){
      // tagId = this.$route.query.tagId;

      // this.fn_initializeSettings();

      // this.fn_showWebView();
      // window.js_globals.fn_onBackPress = this.fn_goBack;

      let self = this;
      document.addEventListener('scroll', function () {
        self.scrollPositionTop = window.pageYOffset
      })

      this.fn_loadItems();

      this.$eventHub.$on('UpdateOpeningLedgerList', dataObj => {
          console.log(dataObj);
          this.fn_searchItem();
      });

      //, this.fn_getItems(this.searchValue, 0);
    },
    // beforeRouteLeave (to, from, next) {
    //     if(this.total_sheet){
    //       this.total_sheet = false;
    //       next(false);
    //     }
    //     else {
    //       next();
    //     }
    // },
    computed:{
      debitTotal: function () {
        let total = 0;
        this.ledgerSummary.items.forEach(function (item) {
          item.amountType == 1 ? total += item.total : '';
        });
        return this.roundUpto2Decimals(total);
      },
      stockValueAndDebitTotal: function(){
        return this.roundUpto2Decimals(this.debitTotal+this.stockSummary.totalAmount);
      },
      creditTotal: function () {
        let total = 0;
        this.ledgerSummary.items.forEach(function (item) {
          item.amountType == 0 ? total += item.total : '';
        });
        return this.roundUpto2Decimals(total);
      },
      difference: function () {
        let diff = this.creditTotal - this.stockValueAndDebitTotal;
        if(diff < 0)
          return this.roundUpto2Decimals(-diff);
        else 
          return this.roundUpto2Decimals(diff);
      }
    }
  };
</script>