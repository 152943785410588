<template>
    <div>
        <v-dialog v-model="dialog_cache_settings" no-click-animation persistent content-class="full-height white-back" max-width="460px">

            <v-card full-height>
                <v-toolbar flat :height="56">
                    <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>Cache Settings</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider> 

                <v-card-text nopadding>
                    <div class="switches" white-background>
                        <v-list nopadding>
                            <v-list-item>

                                <v-list-item-content>
                                    <v-list-item-title>Items and Party Cache</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn small :disabled="$root.dialogLoader" @click="fn_resetCache" outlined color="primary"><v-icon color="primary" font16 class="mr-2">cached</v-icon> {{ $root.dialogLoader ? 'Reset Cache...' : 'Reset' }}</v-btn>
                                </v-list-item-action>

                            </v-list-item>
                        </v-list>
                    </div>
                </v-card-text>
            </v-card>   
        </v-dialog>
    </div>
</template>     

<script>

export default{
    data(){ 
        return{
            dialog_cache_settings:false
        }
    },
    methods:{
        openDialogWeb: function () {
            this.dialog_cache_settings = true;
        },

        closeDialogWeb: function () {
            this.dialog_cache_settings = false;
        },

        fn_resetCache: function(){
            let dataTransactionRef = require('../../../data_transaction.js').dataTransaction;
            dataTransactionRef.fn_deleteDatabase(this.$root);
        }
    }
}
</script>