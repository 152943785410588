<template>
    <div grey-back full-height>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

    </div>
</template>

<script>
import dialogForm from './dialogs/dialog_form'
// import dialogDraftItems from '../../../dialogs/dialog_draft_items'

    export default{
        // mixins: [draftMixin],
        data(){
            return{
                Form:{"unitId":null, "name":null, "shortName":null, "decimalPlaces":null, "baseUnitId":null, "baseUnitName":null, "conversionType":101, "conversionQuantity":null, "status":1, "unitQuantityCode":null, "unitQuantityName":null, "isSystem":0, "draftItems":[], "createdDate":null, "modifiedDate":null, "isNew":true, "selectedCode":null},

                loading:false,
                tagId:null,
                dialogStorageId:null,
                draftStorageId:null,

                backButtonPress:false,

                snackbar:false,
                snackbar_msg:null,

                unwatch:false,
                // loadedFromDraft:false,
                unitQuantityCodes:require('../../../../config.js').configs.unitQuantityCodes,
                disableBaseUnit:false,
                nextRoute:{"path":null, "query":null},
                disableOnSave:false
            }
        },
        components:{
            // 'dialog-uqc': dialogUqc,
            // 'dialog-discard': dialogDiscard,
            // 'dialog-draft-items': dialogDraftItems
            'dialog-form':dialogForm
        },
        // watch:{
        //     'Form': {
        //         handler: function (newVal, oldVal){
        //             if(!this.unwatch){
        //                 this.fn_saveDraft(this.Form.name);
        //             }
        //             this.unwatch = false;
        //         },
        //         deep: true
        //     }
        // },
        methods:{

            fn_closeDialogForm: function(){
                this.fn_goBack();
             },

            // fn_openDialogDiscard: function(){
            //     this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
            // },

            // fn_openDialogDiscard: function(){
            //     this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
            // },

            // fn_openDialogUqc: function(){
            //     this.fn_openDialogWeb('web', 'Uqc', 'ref_dialogUqc')
            // },

            // fn_openDialogWeb: function (platform, name, ref) {

            //     let scroll_y = window.scrollY;
            //     this.fn_fixBackgroundScroll();

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.data = null;
            //     dialogObj.platform = platform;

            //     dialogObj.scrollPosition = {};
            //     dialogObj.scrollPosition.x = 0;
            //     dialogObj.scrollPosition.y = scroll_y;

            //     dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //     let self = this;

            //     this.$refs[ref].openDialogWeb();
            // },

            // fn_updateUqc: function(item){
            //     this.Form.unitQuantityCode = item.id;
            //     this.Form.unitQuantityName = item.name;
            //     this.errors.remove('uqc');
            //     this.fn_closeDialog();
            // },

            fn_conversionType: function(value){
                value != 103 ? this.Form.conversionQuantity=null : '';
            },

            fn_setUnitAsBase: function(){
                this.Form.conversionType = 101;
                this.Form.conversionQuantity = null;
                this.Form.baseUnitName = null;
            },

            // fn_openUnitDialog: function (platform, name, ref) {

            //     if(this.disableBaseUnit){
            //         return;
            //     }

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     //dialogObj.data = null;
            //     dialogObj.platform = platform;

            //     var viewDialogObject = {};
            //     viewDialogObject.ToolbarTitle = "Select Units";
            //     viewDialogObject.TopMenu = [menuItem.new];
            //     viewDialogObject.isBottomMenu = false;

            //     var paramObject = {};
            //     paramObject.defaultValue = {"id":null, "name":null};

            //     if(this.Form.baseUnitId && this.Form.baseUnitName){
            //         paramObject.defaultValue = {"id":this.Form.baseUnitId, "name":this.Form.baseUnitName};
            //     }

            //     var self = this;
            //     dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'Units', tagId, paramObject, ref);
            // },

            // fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
            //     this.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
            // },

            fn_updateBaseUnit: function (selectedValueId, selectedValueName, isRemoved) {
                console.log(selectedValueId+':'+selectedValueName+':'+isRemoved);
                if(!isRemoved){
                    this.Form.baseUnitId = selectedValueId;
                    this.Form.baseUnitName = selectedValueName;
                    this.errors.remove('Base Unit');
                }
                else{
                    this.Form.baseUnitId = null;
                    this.Form.baseUnitName = null;
                }
                this.$forceUpdate();
                this.fn_saveDraft(this.Form.name);
            },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.save, menuItem.reset, menuItem.help]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_closeDialog: function (tagId) {
            //     dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },
            
            fn_checkValidations: function(){
                this.fn_post();
            },
            
            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('Units', dataObj, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          let status = JSON.parse(response);
                          if(status) {
                            self.fn_cleanUp();
                          }
                          else {
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            self.$eventHub.$emit('snackbarAlert', {msg:'Already exists with this name'});
                          }
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                        }
                    }
                );
            },

            fn_cleanUp: function () {

                // this.loadedFromDraft = false;

                if(this.$route.query.ls){
                    let data = {};
                    // data.type = this.Form.conversionType == 101 ? 'unit' : 'subUnit';
                    data.type = 'unit'
                    data.id = this.Form.unitId;
                    data.name = this.Form.name;
                    localStorage.setItem('newItem', JSON.stringify(data));
                    localStorage.setItem('closeDialog', 1);
                }

                this.$eventHub.$emit('UpdateUnitList', {});
                this.fn_goBack();
            },
            
            fn_post: function(){

                this.Form.decimalPlaces ? this.Form.decimalPlaces = Number(this.Form.decimalPlaces) : this.Form.decimalPlaces = null;
                
                // !this.Form.unitId ? this.Form.unitId = this.guid() : '';

                if(!this.Form.unitId){
                    this.Form.unitId = this.guid();
                    // this.Form.createdDate = new Date();
                    this.Form.createdDate = this.$root.fn_getLocalDateFormat();
                }

                // this.Form.modifiedDate = new Date();
                this.Form.modifiedDate = this.$root.fn_getLocalDateFormat();

                this.Form.name = this.Form.name.trim();
                
                this.Form.conversionType == 101 ? this.Form.baseUnitId = this.Form.unitId : '';

                this.Form.conversionType == 103 ? this.Form.conversionQuantity = Number(this.Form.conversionQuantity) : this.Form.conversionQuantity = null;

                if(this.Form.conversionType == 101){
                    this.fn_setUnitAsBase();
                }

                this.Form.unitQuantityCode = this.Form.selectedCode.id;

                console.log(this.Form);
                this.fn_saveLocal(this.Form);
            },

            fn_get: function(itemId, copyData){
                var self = this;
                this.unwatch = true;
                this.Form.isNew = false;
                this.getData('Units', itemId, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          var itemData = JSON.parse(response);
                          self.unwatch = true;
                          self.Form.unitId = itemData.unitId;
                          self.Form.name = itemData.name;
                          self.Form.shortName == 'null' ? self.Form.shortName = null : self.Form.shortName = itemData.shortName;
                          self.Form.decimalPlaces = itemData.decimalPlaces;
                          self.Form.conversionType = itemData.conversionType

                          self.Form.conversionQuantity = itemData.conversionQuantity;

                          self.Form.baseUnitId = itemData.baseUnitId;
                          
                          if(itemData.conversionType!=101){
                            self.Form.baseUnitName = itemData.baseUnit.name;
                          }

                          self.Form.unitQuantityCode = itemData.unitQuantityCode;

                          for(let i=0; i<self.unitQuantityCodes.length; i++){
                            if(self.unitQuantityCodes[i].id == itemData.unitQuantityCode){
                                self.Form.unitQuantityName = self.unitQuantityCodes[i].name;
                                break;
                            }
                          }

                          self.Form.selectedCode = {"id":self.Form.unitQuantityCode, "name":self.Form.unitQuantityName};

                          self.Form.isSystem = itemData.isSystem;

                          if(copyData){
                            self.Form.unitId=null;
                            self.Form.isSystem = 0;
                            // self.fn_saveDraft(self.draftStorageId, self.Form.name);
                            // alert("Data copied.");
                            self.$eventHub.$emit('snackbarAlert', {msg:'Data copied'});
                          }

                          self.$root.fn_copyFormObject(self.Form, self.formId);

                          console.log(self.Form);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },
            
            // fn_clearForm: function(){
            //     this.Form = {"unitId":null, "name":null, "shortName":null, "decimalPlaces":null, "baseUnitId":null, "baseUnitName":null, "conversionType":101, "conversionQuantity":null, "status":1, "unitQuantityCode":null, "unitQuantityName":null, "isSystem":0, "draftItems":[], "createdDate":null, "modifiedDate":null, "isNew":true};
            //     this.$validator.reset();
            // },
            
            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //     // tagId id form future use
            //     // menuId id form future use
            //     this.backButtonPress = false;

            //     if(menuAction=='save'){
            //         this.fn_checkValidations();
            //     }
            //     else if(menuAction=='new'){
            //         this.$router.push({path:'/inventory/units/new', query:{tagId:this.guidWeb(), ls:1}})
            //     }
            //     else if(menuAction=='reset'){
            //         this.fn_resetForm();
            //     }
            // },

            // fn_resetForm: function () {
            //     this.unwatch = true;
            //     // this.loadedFromDraft = false;

            //     if(this.Form.unitId){
            //         this.fn_get(this.Form.unitId);
            //     }
            //     else{
            //         this.fn_clearForm();
            //     }
                
            //     let self = this;
            //     this.fn_removeDraft(this.draftStorageId, function(){
            //         console.log("draft deleted");
            //         // self.showToast("Item deleted from draft");
            //     });
            // },
            
            // fn_showWebView: function () {
            //     var viewObject = {};
            //     viewObject.ToolbarTitle = "Units";
            //     viewObject.TopMenu = [menuItem.save];
            //     viewObject.isBottomMenu = true;
            //     var tagId = this.formId;
            //     var self = this;
            //     this.showWebView(tagId, viewObject, 
            //         {
            //             onSuccess: function(response){
            //               console.log(response);
            //             }, 
            //             onError: function(error){
            //               console.log(error);
            //             }, 
            //             // onChange: null,
            //             onMenuItemClick: function (tagId, menuId, menuAction) {
            //                 self.fn_menuItemClick(tagId, menuId, menuAction)
            //             }
            //         }
            //     );
            // },

            // fn_setNewItem: function(){
            //     if(localStorage.getItem('newItem')){

            //         let itemData = JSON.parse(localStorage.getItem('newItem'));

            //         switch (itemData.type) {
            //             case "unit":
            //                 this.fn_updateBaseUnit(itemData.id, itemData.name, false);
            //                 break;
            //             default:
            //                 console.log("condition not matched");
            //         }

            //         localStorage.removeItem('newItem');
            //     }

            //     this.Form.isNew ? this.$root.fn_copyFormObject(this.Form, this.formId) : '';
            // },
            
            fn_goBack: function(){
                this.$root.viaKeyboardShortCut = false;
                this.$refs['ref_dialogForm'].closeDialogWeb();
                this.backButtonPress = true;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            }
        },
        mounted: function(){

            // var self = this;

            if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                // this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                // this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }

            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });

        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                // vm.enableToolbar();
                
                let itemId = null;
                let copyData = false;

                // to.query && to.query.copy && from.name == 'itemlist' ? copyData = true : '';
                to.query && to.query.copy ? copyData = true : '';

                // if(to.query && to.query.itemId && from.name == 'itemlist'){
                //     itemId = to.query.itemId;
                // }
                if(to.query && to.query.itemId){
                    itemId = to.query.itemId;
                }

                if(to.params && to.params.tagId){//from display view
                    itemId = to.params.tagId;
                }

                if(itemId){
                    vm.fn_get(itemId, copyData);
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            itemId ? vm.fn_get(itemId, copyData) : '';
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });

            })
        },
        beforeRouteLeave (to, from, next) {
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('connected');
                next();
            }
        },
        // beforeRouteUpdate (to, from, next) {

        //     this.enableToolbar();
            
        //     var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //     var existingForm = false;
        //     if(dialogData && dialogData.length){
        //         var openForms = JSON.parse(localStorage.getItem('unitforms'));
        //         for(var i=0; i<openForms.length; i++){
        //             if(openForms[i]==to.query.tagId){
        //                 existingForm = true;
        //                 dialogjs.fn_checkAndCloseOpenDialogs(this);
        //                 break;
        //             }
        //         }
        //         existingForm ? next(false) : next(true);
        //     }
        //     else{
        //         next();
        //     }
        // },
        // beforeRouteLeave (to, from, next) {
        //     var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //     if(dialogData && dialogData.length){

        //         if(this.backButtonPress){
        //             dialogjs.fn_checkAndCloseOpenDialogs(this);
        //             next(false);
        //         }
        //         else{
        //             // this.$root.fn_deleteFormObject();
        //             this.fn_saveDraft(this.Form.name);
        //             next(true);
        //         }
        //     }
        //     else{
        //         if(this.fn_showDialogDiscard()){
        //             this.fn_openDialogDiscard();
        //             next(false);
        //         }
        //         else{
        //             this.fn_clearData('unitforms', function(){
        //                 next();
        //             })
        //         }
        //         // if(this.loadedFromDraft || (this.$root.fn_getFormObject(this.formId) && !this.$root.fn_deepEqual(this.Form, JSON.parse(this.$root.fn_getFormObject(this.formId))))){
        //         //  this.fn_openDialogDiscard();
        //         //  next(false);
        //         // }
        //         // else{
        //         //  this.$root.fn_deleteFormObject(this.formId);
        //         //  !this.askToDiscard ? this.fn_removeDraft(this.draftStorageId, null, -1) : '';
        //         //  localStorage.removeItem('unitforms')
        //         //  next(); 
        //         // }
        //     }
        // }

    }

</script>