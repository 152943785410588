<template>
    <div grey-back full-height>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <!-- fn_draftAction is in draft mixin -->
        <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="Form.transactionData.transactionDate" :updateDate="fn_updateDate"></dialog-datepicker>

    </div>
</template>

<script>
const accountGroupIds = require('../../../../config.js').configs.accountGroupIds;
const fixedAccountIds = require('../../../../config.js').configs.fixedAccountIds;
const transactionData = require('../../datamodel').data.transactionData;

import {draftMixin} from '../../../draft_mixin'
import {transactionMixin} from '../../transaction_mixin'
import dialogForm from './dialogs/dialog_form'
import dialogDiscard from '../../../dialogs/dialog_discard'
import dialogDatePicker from '../../../dialogs/dialog_datepicker'

// const transactionTypes = require('../../../../config.js').configs.transactionTypes;

    export default{
        props:['settings'],
        mixins: [draftMixin, transactionMixin],
        data(){
            return{

                Form:{
                    "transactionData": JSON.parse(JSON.stringify(transactionData)),
                    "accountLedgerId":null,
                    "accountLedgerName":null,
                    "amount":null, 
                    "amountType":null,
                    "balanceType":1,
                    "ledgerData":null,
                    "balanceAsOn":null,
                    "selectedLedger":null
                },


                loading:false,
                tagId:null,
                draftStorageId:null,
                dialogStorageId:null,

                backButtonPress: false,
                loadedFromDraft:false,

                snackbar:false,
                snackbar_msg:null,

                unwatch:false,
                // selectedDate:new Date(),
                newLedgerGroupTypeId:null,
                accountLedgers:null,
                disableOnSave:false
            }
        },
        components:{
            'dialog-form':dialogForm,
            'dialog-discard': dialogDiscard,
            'dialog-datepicker':dialogDatePicker,
        },
        watch:{
            'Form': {
                handler: function (){
                     if(!this.unwatch){
                         this.fn_saveDraft(this.draftStorageId, this.Form.accountLedgerName);
                     }
                     this.unwatch = false;
                 },
                 deep: true
            }
        },
        methods:{

            // fn_openConfirmationDialog: function () {
            // 	this.fn_openDialogWeb('web', 'confirmationDialog', 'ref_dialogConfirmation');
            // },

            fn_closeDialogForm: function(){
                this.askToDiscard = true;
                if(this.fn_showDialogDiscard()){
                    this.$refs['ref_dialogDiscard'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            fn_openDatePicker: function () {
                // let self = this;
                // setTimeout(function () {
                // 	self.fn_openDialogWeb('web', 'DatePicker', 'ref_dialogDatePicker');
                // })
                this.$refs['ref_dialogDatePicker'].openDialogWeb();
            },

            fn_updateDate: function (value) {
                // this.Form.transactionData.transactionDate = value;
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(value);
                this.fn_getBalanceAsOn();
                this.$refs['ref_dialogDatePicker'].closeDialogWeb();
                this.$refs['ref_dialogDatePicker'].disableBtn = false;
            },

            fn_getTransactionDate: function(){
                if(!this.Form.transactionData.transactionDate){
                    if(new Date().getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                        this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(this.$root.CurrentBusiness.OpeningDate);
                    }
                    else{
                        this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat();	
                    }
                }
                return this.$moment(this.Form.transactionData.transactionDate).format('DD-MMM-YYYY');
            },

            fn_openDialogDiscard: function(){
                // this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard');
                this.$refs['ref_dialogDiscard'].openDialogWeb();
            },

            // fn_openAccountLedger: function () {
            // 	this.fn_openAccountLedgerDialog('android', 'AccountLedger', 'ref_accountLedger')
            // },

            // fn_openAccountLedgerDialog: function (platform, name, ref) {

            // 	var tagId = this.guidWeb();

            // 	var dialogObj = {};
            // 	dialogObj.tagId = tagId;
            // 	dialogObj.formId = this.formId;
            // 	dialogObj.name = name;
            // 	dialogObj.ref = ref;
            // 	//dialogObj.data = null;
            // 	dialogObj.platform = platform;


            // 	var self = this;

            // 	var viewDialogObject = {};
            // 	viewDialogObject.ToolbarTitle = "Select Ledger";
            // 	viewDialogObject.TopMenu = [menuItem.new];
            // 	viewDialogObject.isBottomMenu = false;
            // 	viewDialogObject.hideChip = true;
            // 	viewDialogObject.enableFilterChip = true;

            // 	let defaultGroupTypeId = 101;

            // 	// viewDialogObject.groupTypeId = this.Form.partyId ? this.Form.partyDetails.groupTypeId : defaultGroupTypeId;
            // 	viewDialogObject.groupTypeId = this.Form.ledgerData && this.Form.ledgerData.groupTypeId ? this.Form.ledgerData.groupTypeId : defaultGroupTypeId;

            // 	this.newLedgerGroupTypeId = viewDialogObject.groupTypeId;

            // 	var paramObject = {};
            // 	paramObject.defaultValue = {"id":null, "name":null};

            // 	if(this.Form.accountLedgerId && this.Form.accountLedgerName){
            // 		paramObject.defaultValue = {"id":this.Form.accountLedgerId, "name":this.Form.accountLedgerName};
            // 	}

            // 	var self = this;
            // 	dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'accounts', tagId, paramObject, ref);
            // },

            fn_updateParty: function(partyId, partyName){
                this.fn_updateAccountLedger(partyId, partyName);
            },

            fn_updateAccountLedger: function (selectedValueId, selectedValueName) {
                this.Form.selectedLedger = {"itemId":selectedValueId, "itemName":selectedValueName};
                this.Form.accountLedgerId = selectedValueId;
                this.Form.accountLedgerName = selectedValueName;
                this.fn_getLedgerData(selectedValueId);
                this.$forceUpdate();
            },

            fn_getLedgerData: function(selectedValueId){
                let self = this;
                this.getData('Ledger', selectedValueId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.Form.ledgerData = JSON.parse(response);
                            self.fn_getBalanceAsOn();
                        }, 
                        onError: function(error){
                              console.log(error);
                        }
                    }
                );
            },

            fn_getBalanceAsOn: function(){
                let self = this;
                let filterObject = {};
                filterObject.accountId = this.Form.accountLedgerId;
                filterObject.endDate = this.Form.transactionData.transactionDate;
                this.getFilterData('BalanceAsOn', null, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let balance = response == "null" ? 0 : response;
                            self.Form.balanceAsOn = Number(balance);
                        }, 
                        onError: function(error){
                              console.log(error);
                        }
                    }
                );
            },

            // fn_closeDialog: function (tagId) {
            //     dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_checkValidations: function(){
                this.fn_post();
                // this.$validator.validateAll().then((result) => {
                //     if (result) {
                //            this.fn_post();
                //     }
                //     else{
                //         this.$vuetify.goTo('.error--text', this.scrollOptions);
                //         this.hideLoading();
                //     }
                // });
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('SetPartyBalance', dataObj, 
                    {
                        onSuccess: function(response){
                              console.log(response);
                              self.fn_cleanUp();
                        },
                        onError: function(error){
                              console.log(error);
                              self.saveBtnKey++;
                              self.disableOnSave = false;
                              self.hideLoading();
                        }
                    }
                );
            },

            fn_cleanUp: function () {
                localStorage.removeItem(this.draftStorageId);
                this.$eventHub.$emit('UpdateLedgerList', {});
                this.fn_goBack();
            },

            fn_post: function(){

                //only in case of new transaction
                if(!this.Form.transactionData.transactionId){
                    this.Form.transactionData.transactionId = this.guid();
                    // this.Form.transactionData.createdDate = new Date();
                    this.Form.transactionData.createdDate = this.$root.fn_getLocalDateFormat();
                }

                // this.Form.transactionData.modifiedDate = new Date();
                this.Form.transactionData.modifiedDate = this.$root.fn_getLocalDateFormat();

                this.Form.transactionData.partyId = this.Form.accountLedgerId;

                this.Form.transactionData.amount = this.Form.amount  //debit or credit amount
                this.Form.transactionData.roundedAmount = Math.round(this.Form.amount); //or creditTotal amount
                this.Form.transactionData.status = 1;
                this.Form.transactionData.transactionListId = this.settings.transactionTypeId;
                this.Form.transactionData.refDate = null;

                // this.Form.transactionData.grossAmount = this.Form.amountType ? this.Form.amount : -this.Form.amount;
                this.Form.transactionData.grossAmount = this.Form.amount;

                let postObj = {};
                postObj = JSON.parse(JSON.stringify(this.Form.transactionData));

                //Preparing Object for transaction ledger table
                let transactionLedgerObj = {};

                transactionLedgerObj.transactionLedgerId = this.guid();
                transactionLedgerObj.accountLedgerId = this.Form.accountLedgerId;
                transactionLedgerObj.orderNumber = 1;
                transactionLedgerObj.amount = this.Form.amount;
                transactionLedgerObj.amountType = this.Form.amountType;

                postObj.transactionLedgers = [];
                postObj.transactionLedgers.push(transactionLedgerObj);
                
                //Preparing Object for ledger accounts table
                let partyAccountObj = {};
                postObj.ledgerAccounts = [];

                partyAccountObj.ledgerAccountId = this.guid();
                partyAccountObj.accountId =  this.Form.accountLedgerId;
                // partyAccountObj.amount =  this.Form.amountType == 1 ? this.Form.amount : -this.Form.amount;
                // partyAccountObj.amount =  this.Form.amount;
                partyAccountObj.amount =  this.difference;
                // partyAccountObj.amountType = this.Form.amount >= 0 ? 1 : 0;
                // partyAccountObj.amountType = this.difference >= 0 ? 1 : 0;k
                partyAccountObj.amountType = this.Form.amountType;
                postObj.ledgerAccounts.push(partyAccountObj);

                // debtor/creditor written off account
                let writtenOffAccountObj = {};
                writtenOffAccountObj.ledgerAccountId = this.guid();
                if(this.Form.ledgerData.accountGroupId == accountGroupIds.creditors.id){
                    writtenOffAccountObj.accountId = fixedAccountIds.creditorWrittenOffAccount.id;
                }
                else if(this.Form.ledgerData.accountGroupId == accountGroupIds.debtors.id){
                    writtenOffAccountObj.accountId = fixedAccountIds.debtorWrittenOffAccount.id;
                }
                else{
                    if(this.Form.amountType==1){
                        writtenOffAccountObj.accountId = fixedAccountIds.debtorWrittenOffAccount.id;		
                    }
                    else{
                        writtenOffAccountObj.accountId = fixedAccountIds.creditorWrittenOffAccount.id;	
                    }
                }
                // writtenOffAccountObj.amount =  this.Form.amountType == 1 ? -this.Form.amount : this.Form.amount;
                writtenOffAccountObj.amount =  -this.difference;
                // writtenOffAccountObj.amountType = this.Form.amountType == 1 ? 0 : 1;
                // writtenOffAccountObj.amountType = this.difference >= 0 ? 1 : 0;
                writtenOffAccountObj.amountType = this.Form.amountType == 1 ? 0 : 1;
                postObj.ledgerAccounts.push(writtenOffAccountObj);

                postObj.sortDate = this.$moment(this.Form.transactionData.transactionDate).format('YYYY-MM-DD');

                console.log(JSON.stringify(postObj));
                this.fn_saveLocal(postObj);
            },

            fn_clearForm: function(){
                this.Form = {
                    "transactionData": JSON.parse(JSON.stringify(transactionData)),
                    "accountLedgerId":null,
                    "accountLedgerName":null,
                    "amount":null, 
                    "amountType":null,
                    "balanceType":1,
                    "ledgerData":null
                }
            },

            // fn_addNote: function () {
            // 	this.$refs['ref_dialogNote'].noteValue = this.Form.transactionData.notes;
            // 	this.fn_openDialogWeb('web', 'Note', 'ref_dialogNote');
            // 	setTimeout(function(){
            // 		let noteField = document.getElementById('noteField');
            // 		noteField.focus();
            // 	});
            // },

            // fn_saveNote: function () {
            // 	this.Form.transactionData.notes = this.$refs['ref_dialogNote'].noteValue;
            // 	this.fn_closeDialog();
            // 	let self = this;
            // 	setTimeout(function () {
            // 		self.$vuetify.goTo('#note', self.scrollOptions);
            // 	},300);
            // },

            fn_setNewItem: function(){
                let itemData = null;
                if(localStorage.getItem('newItem')){

                    itemData = JSON.parse(localStorage.getItem('newItem'));

                    switch (itemData.type) {
                        case "ledger":
                            this.fn_updateAccountLedger(itemData.id, itemData.name)
                            break;
                            
                        default:
                            console.log("condition not matched");
                    }

                    localStorage.removeItem('newItem');
                }

                if(this.$route.query && this.$route.query.dt && !itemData){
                    this.fn_updateAccountLedger(this.$route.query.id, this.$route.query.name);
                }
            },

            fn_goBack: function(){
                this.$refs['ref_dialogForm'].closeDialogWeb();
                this.$root.viaKeyboardShortCut = false;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            },

            fn_getDifferenceAmount: function(){
                let amount = this.Form.balanceType == 1 ? this.Form.amount : -this.Form.amount;
                let diff = this.roundUpto2Decimals(amount - this.Form.balanceAsOn);
                this.Form.amountType = diff < 0 ? 0 : 1;
                return diff;
            },

            fn_getAccountLedgers: function(val){
                let filterObject = {};
                filterObject.search = val ? val.toLowerCase() : '';
              
                let self = this;
                this.getDialogItems("AccountDialog", filterObject, 
                    {
                        onSuccess: function (response) {
                            console.log(response);
                            self.accountLedgers = JSON.parse(response);
                        },
                        onError: function (error) {
                            console.log(error);
                        }
                    }
                )
            },
        },
        computed:{
            difference: function(){
                return this.fn_getDifferenceAmount();
            }
        },
        mounted: function(){
            console.log(this.$route);

            var self = this;

             if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                this.draftStorageId = 'draft_transaction_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                this.draftStorageId = 'draft_transaction_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
            }

            // !this.$root.CurrentBusiness.DataDrive ?  this.fn_checkTransactionCount() : '';

            if(localStorage.getItem(this.draftStorageId)){
                let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
                this.Form = JSON.parse(dataObj.formData);
            }

            // let formElement = document.getElementsByClassName("form-element")[0];

            // formElement.addEventListener("submit", function(e){
            //     e.preventDefault();
            // });

            setTimeout(function(){self.fn_setNewItem()},300);

            this.fn_getAccountLedgers();

            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('select_party', () => {
                let element = document.getElementById("party_dropdown");
                element.focus();
                element.click();
            });

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });

            let isCalled = false;
            this.$eventHub.$on('connected', () => {
                setTimeout(() => {
                    if(!isCalled){
                        isCalled = true;
                        this.disableOnSave = false;
                        this.saveBtnKey++;
                        !this.accountLedgers ? this.fn_getAccountLedgers() : '';
                    }

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);
                }, 1000);
            });
        },
        beforeRouteLeave (to, from, next) {
            
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('select_party');
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('connected');
                next();
            }
        },
    }

</script>