<template>
    <div class="display_wrapper">

        <v-toolbar flat :height="56">
            <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>

            <v-toolbar-title>Opening Stock</v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- <v-btn round-borders outline @click="fn_edit">edit</v-btn> -->

            <v-btn outlined small @click="fn_edit" color="primary" v-if="displayData && !displayData.isSystem">
                <v-icon left>edit</v-icon> Edit
            </v-btn>

            <v-btn class="ml-4" small outlined @click="fn_openDialogConfirmation" color="red" v-if="displayData && !displayData.isSystem">
                <v-icon left>delete</v-icon> Delete
            </v-btn>

           <!--  <v-btn class="ml-4" small outlined @click="fn_openDialogConfirmation" color="red" v-if="displayData && !displayData.isSystem">
                <v-icon left>delete</v-icon> Delete
            </v-btn> -->

            <!-- <v-btn icon @click="fn_getBottomMenu">
              <v-icon>more_vert</v-icon>
            </v-btn> -->
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back" v-if="displayData">

            <div class="white-back pa-4">
                <span class="title">{{displayData.productName}}</span>
                <div custom-message left-align v-if="summaryArray && summaryArray.length">
                    <template v-for="(item, index) in summaryArray">
                        <span :key="index">
                            {{item.unitName}}: <b>{{item.qty}}</b>
                        </span>    
                    </template>
               </div>
            </div>  

            <v-list two-line class="section-container mt-2 nopadding">
                <v-subheader light>Details</v-subheader>
                <v-divider></v-divider>
                <div v-for="(item, index) in displayData.transactionProducts" :key="index">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <!-- <span v-if="$root.TransactionSettings.mrpWiseStock">MRP: <b>{{$root.numberToLocale(item.mrp)}}</b> | </span>  -->
                                <span>Rate: <b>{{$root.numberToLocale(item.rate)}}</b></span>
                            </v-list-item-title>

                            <template v-for="(vItem, vIndex) in item.variants">
                                <v-list-item-subtitle :key="vIndex">
                                    {{vItem.unitName}}<span v-if="vItem.packQuantity">({{vItem.packQuantity}})</span>: 
                                    <b>{{vItem.qty}}</b>
                                </v-list-item-subtitle>    
                            </template>
                            
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="displayData.transactionProducts.length-1 != index"></v-divider>
                </div>

            </v-list>

            <div v-if="$root.TransactionSettings.enableBatch && batches && batches.length">
				<v-subheader white-background>{{selectedBatchType == 1 ? 'Batches' : 'Series'}}</v-subheader>
				<v-divider></v-divider>
				<template v-for="(batch, batchIndex) in batches">
					<v-card flat :key="batchIndex" v-if="batch.opening">
						<v-card-text class="pb-2">
							<v-layout ma-0 row wrap>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.batchNumber">
									<div light-opacity-color>Batch No.</div>
									<div>{{ batch.batchNumber }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.batchSize">
									<div light-opacity-color>Size</div>
									<div>{{ batch.batchSize }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.batchMRP">
									<div light-opacity-color>MRP</div>
									<div>{{ batch.batchMRP }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.mfgDate">
									<div light-opacity-color>Mfg. Date</div>
									<div v-if="batch.mfgDate">{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.expDate">
									<div light-opacity-color>Exp. Date</div>
									<div v-if="batch.expDate">{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.opening && !batch.field1 && !batch.field2 && !batch.field3">
									<div light-opacity-color>Opening</div>
									<div>{{ batch.opening ? batch.opening : 0  }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.purchaseRate">
									<div light-opacity-color>Purchase Rate</div>
									<div>{{ batch.purchaseRate }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.field1">
									<div light-opacity-color>Serial Number</div>
									<div>{{ batch.field1 }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.field2">
									<div light-opacity-color>IMEI Number</div>
									<div>{{ batch.field2 }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.field3">
									<div light-opacity-color>Part Number</div>
									<div>{{ batch.field3 }}</div>
								</v-flex>
								<v-flex xs4 sm4 md4 mb-2 v-if="batch.field4">
									<div light-opacity-color>Model Number</div>
									<div>{{ batch.field4 }}</div>
								</v-flex>
								<!-- <v-flex xs4 sm4 md4 mb-2 v-if="item.selectedBatchType == 1 && (item.batches.length > 1 || item.batches.length == 1 && batch.productQty != item.productVariants[0].qty)">
									<div light-opacity-color>Quantity</div>
									<div>{{ batch.productQty }}</div>
								</v-flex> -->
							</v-layout>
							<!-- <v-layout row wrap>
								<v-flex xs4 sm4 md4 mb-2 v-for="(field, fieldIndex) in $root.TransactionSettings.batchFields" :key="fieldIndex" v-if="batch[field.id]">
									<div light-opacity-color>{{ field.title }}</div>
									<div v-if="field.type == 'date'">{{ batch[field.id] | moment('DD-MMM-YYYY') }}</div>
									<div v-else>{{ batch[field.id] }}</div>
								</v-flex>
							</v-layout> -->
						</v-card-text>
						<v-card-actions class="pa-0 ma-0" v-if="batch.isNew">
							<v-spacer></v-spacer>
							<v-btn color="red" capitalize flat small @click="fn_removeBatch(batchIndex)">Remove</v-btn>
						</v-card-actions>
					</v-card>
					<v-divider :key="'divider'+batchIndex" v-if="batches.length - 1 != batchIndex"></v-divider>
				</template>
			</div>

            <div v-if="displayData.notes" class="margin-top-ten">
                <v-list full-width nopadding>
                    <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title medium-theme-color>Notes</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card flat class="note-card">
                  <v-card-text font16 v-html="displayData.notes" white-space-pre-line></v-card-text>
                </v-card>
            </div>

        </div>

        <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);" v-if="loading">
          <v-col cols="6">
            <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
          </v-col>
        </v-row>

        <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" header="Delete Transaction?" confirmationMessage="Deleted transaction cannot be restored. Are you sure you want to delete this transaction?" :actionMethod="fn_callCancelTransaction" params="delete" :displayView='true' okBtnText="Yes" cancelBtnText="No" icon="warning" iconClass="redcolor" textClass="redcolor"></dialog-confirmation>

        <dialog-confirmation ref="ref_dialogConfirmation" header="Batch Alert!" confirmationMessage="<div dark-opacity-color>This item consists batch.<br>Please edit this item in BizOps App on mobile</div>"  :actionMethod="fn_closeDialogConfirmation" :displayView='true' okBtnText="Okay"></dialog-confirmation>
        
        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>
    </div>
</template>

<script>
// const menuItem = require('../../../../config.js').configs.menuItem;
// const transactionForms = require('../../../../config.js').configs.transactionForms;

import dialogConfirmation from '../../../dialogs/dialog_confirmation'

import {transactionMixin} from '../../transaction_mixin'

    export default{
        props:['settings'],
        mixins: [transactionMixin],
        data(){
            return{
                displayData:null,
                toolbarTitle:null,
                selectedNatureValue:null,
                snackbar:false,
                snackbar_msg:null,
                summaryArray:[],
                loading:false,
                batches:null
            }
        },
        components:{
            'dialog-confirmation': dialogConfirmation
        },
        methods:{

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_openDialogConfirmation: function(){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            fn_callCancelTransaction: function(){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;

                let self = this;
                this.fn_deleteTransaction(this.displayData.transactionId, this.displayData.transactionListId, function(status){
                    if(status){
                        // alert(self.displayData.productName + " opening stock deleted");
                        self.$eventHub.$emit('snackbarAlert', {msg:self.displayData.productName + " opening stock deleted"});
                        self.$eventHub.$emit('UpdateOpeningStockList', {});
                        self.fn_goBack();
                    }
                    else{
                        // alert("Something went wrong. Try again");
                        self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Try again"});
                    }
                });
            },

            fn_getData: function (type, itemId) {
                this.loading = true;
                this.displayData = null;

                let filterObject = {};
                filterObject.type = this.settings.transactionTypeId;

                let self = this;
                this.getFilterData(type, itemId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.displayData = JSON.parse(response);
                            self.fn_getSummary(self.displayData.productId);
                            self.fn_getBatches(self.displayData.productId);
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                          self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                        }
                    }
                );
            },

            fn_getSummary: function (itemId) {
                var self = this;
                this.getData('ProductSummary', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.summaryArray = JSON.parse(response);
                            self.loading = false;
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                        }
                    }
                );
            },

            fn_getGroupTotal: function (tp) {
                let total = 0;
                tp.variants.forEach(function (item) {
                    total += item.amount;
                });
                tp.amount = total;
            },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.delete]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='edit' && this.displayData && this.displayData.transactionId){
            //      this.fn_edit();
            //  }
            //  else if(menuAction=='delete'){
            //      this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            //  }
            // },

            fn_edit: function () {
                if(this.batches && this.batches.length){
                    this.actionMethod = this.fn_closeDialogConfirmation;
                    this.$refs['ref_dialogConfirmation'].dialog_confimation = true;
                    return;
                }
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/openingstock/items/'+this.displayData.transactionId+'/edit');
            },

            fn_closeDialogConfirmation: function(){
                this.$refs['ref_dialogConfirmation'].dialog_confimation = false;
            },

            fn_getBatches: function(productId){
				let self = this;
				this.getData('GetProductBatchByProductId', productId, 
					{
						onSuccess: function(response){
							console.log(response);
							self.batches = JSON.parse(response);
						}, 
						onError: function(error){
							console.log(error);
						}
					}
				);
			},

            // fn_showWebView: function () {
            //  var viewObject = {};

            //  var tagId = null;
            //  this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';
            //  var self = this;

            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null, 
            //          onMenuItemClick: function (tagId, menuId, menuAction, itemId) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction, itemId)
            //          }
            //      }
            //  );
            // },
        },
        mounted: function(){

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_onBackPress = this.fn_goBack;

            // this.fn_showWebView();
            
            if(this.$route.params && this.$route.params.tagId){
                this.fn_getData('TransactionProducts', this.$route.params.tagId);
            }

            this.$eventHub.$on('delete', () => {
                this.fn_openDialogConfirmation();
            });

            this.$eventHub.$on('edit', () => {
                this.fn_edit();
            });

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_getData('TransactionProducts', this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteUpdate(to, from, next){
            this.$eventHub.$off('connected');
            
            if(to.params && to.params.tagId){
                this.fn_getData('TransactionProducts', to.params.tagId);
            }

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_getData('TransactionProducts', this.$route.params.tagId);
                    }
                }, 1000);
            });

            next(true);
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('connected');
            next(true)
        },
        // beforeRouteLeave (to, from, next) {
        //  if(this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation){
        //      this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;
        //      next(false);
        //  }
        //  else{
        //      next(true); 
        //  }
     //    }
    }

</script>