<template>
  <div>
     <v-navigation-drawer permanent v-model="drawer" app :width="280" z-index="9" class="dark-back donotprint">

      <v-list dense class="pa-0" two-line v-if="$root.CurrentBusiness">
        <v-list-item avatar dark>

          <v-list-item-avatar cursor-pointer @click="fn_businessProfile" font12 style="width:36px;height:36px;min-width: 36px;background: #fff;color: rgba(0,0,0,0.87);opacity: 0.8;">
            <img :src="$root.CurrentBusiness.businessLogo" v-if="$root.CurrentBusiness.businessLogo">
            <template v-else>{{$root.fn_abbreviatedBusinessName()}}</template>
          </v-list-item-avatar>

          <v-list-item-content cursor-pointer @click="fn_businessProfile">
            <v-list-item-title style="white-space: normal;">{{$root.CurrentBusiness.BusinessName}}</v-list-item-title>
            <v-list-item-subtitle v-if="$root.CurrentBusiness.UserName">{{$root.CurrentBusiness.UserName}}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
              <!-- <v-btn icon @click="fn_openDialogConfiramtion"><v-icon>more_vert</v-icon></v-btn> -->
              <v-menu left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon font20>more_horiz</v-icon>
                  </v-btn>
                </template>
                  <v-list dense class="pa-0">
                    <v-list-item @click="fn_openDialogConfiramtion" v-if="$root.hasClientBusinesses">
                        <v-list-item-icon class="mr-4">
                            <v-icon>swap_horiz</v-icon>
                        </v-list-item-icon>
                        
                        <v-list-item-content>
                            <v-list-item-title>Switch Business</v-list-item-title>
                        </v-list-item-content>
                      
                    </v-list-item>
                    <!-- <v-divider class="mr-4 ml-4 mt-2 mb-2" v-if="$root.hasClientBusinesses"></v-divider> -->

                    <v-list-item :class="{'pt-2':!$root.hasClientBusinesses}" @click="fn_openDialogShortcuts">
                        <v-list-item-icon class="mr-4">
                            <v-icon>keyboard</v-icon>
                        </v-list-item-icon>
                        
                        <v-list-item-content>
                            <v-list-item-title>Keyboard Shortcuts</v-list-item-title>
                        </v-list-item-content>
                      
                    </v-list-item>

                    <v-menu right open-on-hover offset-x>
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item v-bind="attrs" v-on="on">

                                <v-list-item-icon class="mr-4">
                                    <v-icon>info_outline</v-icon>
                                </v-list-item-icon>
                                
                                <v-list-item-content>
                                    <v-list-item-title>About</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-icon>
                                    <v-icon>chevron_right</v-icon>
                                </v-list-item-icon>
                            
                            </v-list-item>
                        </template>

                        <v-list dense class="pa-0">
                                <v-list-item @click="fn_openTerms">
                                    
                                    <v-list-item-content>
                                        <v-list-item-title>Terms of Service</v-list-item-title>
                                    </v-list-item-content>
                                
                                </v-list-item>

                                <v-list-item @click="fn_openDialogOpenSourceLibraries">
                                    
                                    <v-list-item-content>
                                        <v-list-item-title>Open Source Libraries</v-list-item-title>
                                    </v-list-item-content>
                                
                                </v-list-item>
                            </v-list>
                    </v-menu>
                    

                    <v-divider class="mr-4 ml-4 mt-2 mb-2"></v-divider>

                    <v-list-item class="redcolor" @click="logout">
                        <v-list-item-icon class="mr-4">
                            <v-icon color="red" font20>logout</v-icon>
                        </v-list-item-icon>
                        
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                  </v-list>
              </v-menu>
          </v-list-item-action>

        </v-list-item>
      </v-list>

        <!-- <v-btn small class="ml-4" color="white" outlined capitalize @click="fn_openDialogConfiramtion" v-if="$root.hasClientBusinesses">
            <v-icon left>swap_horiz</v-icon>
            Switch Business
        </v-btn>   -->

      <v-divider color="white" class="ml-5 mr-5 mt-2 mb-2"></v-divider>

      <v-list dense nav class="pl-0 pr-0">
        
        <v-list-item-group v-model="instantSelected" color="white" :mandatory="instantSelected != null ? true : false">

          <template v-for="(childItem, index) in instantItems">
              <v-list-item
                  class="pl-4"
                  v-if="!childItem.isDialog"
                  :key="childItem.title"
                  link
                  @click="fn_view(childItem)"
                  color="rgba(255,255,255,0.8)"
                  active-class="primary"
                  :class="{'mb-1':index == instantItems.length - 1}"
                  >

                  <v-list-item-icon class="mr-8"> 
                      <!-- <v-icon style="font-size:18px;">{{childItem.icon}}</v-icon> -->
                      <!-- <img src="../assets/icon_rupee.svg" v-if="childItem.imgIcon"> -->
                      <v-icon>{{childItem.icon}}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                      <v-list-item-title v-text="childItem.title"></v-list-item-title>
                  </v-list-item-content>
              
              </v-list-item>

              <div class="dialog-item" @click="fn_view(childItem, item)" :key="'dialog'+childItem.title" cursor-pointer v-else>
                  <v-icon class="v-list-item__icon mr-4">{{childItem.icon}}</v-icon>{{childItem.title}}
              </div>
          </template>


          </v-list-item-group>

        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          color="#fff"
          no-action
          class="pl-2 mb-1"
        >

          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item-group v-model="item.selected" color="white" :mandatory="item.selected != null ? true : false">

            <template v-for="childItem in item.items">
                <v-list-item
                    v-if="!childItem.isDialog"
                    :key="childItem.title"
                    link
                    style="padding-left:24px;"
                    @click="fn_view(childItem, item)"
                    color="rgba(255,255,255,0.8)"
                    active-class="primary"
                    class=""
                    >

                    <v-list-item-icon class="mr-4">
                        <v-icon style="font-size:18px;">{{childItem.icon}}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-text="childItem.title"></v-list-item-title>
                    </v-list-item-content>
                
                </v-list-item>

                <div class="dialog-item" @click="fn_view(childItem, item)" :key="'dialog'+childItem.title" cursor-pointer v-else>
                    <v-icon class="v-list-item__icon mr-4">{{childItem.icon}}</v-icon>{{childItem.title}}
                </div>
            </template>
            

          </v-list-item-group>
        </v-list-group>

        <!-- <v-divider color="white" class="ml-5 mr-5 mt-4 mb-4"></v-divider>

        <v-list-item @click="fn_openDialogShortcuts" color="rgba(255,255,255,0.8)">

            <v-list-item-icon>
                <v-icon>keyboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>Keyboard Shortcuts</v-list-item-title>
            </v-list-item-content>
            
        </v-list-item> -->

        <!-- <v-list-item @click="fn_openDialogShortcuts" color="rgba(255,0,0)">

            <v-list-item-icon>
                <v-icon>logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
            
        </v-list-item> -->

      </v-list>

      

      <div style="position:fixed;bottom:0;width:100%;background-color:#333;">

        <!-- <div style="color: #fff;" v-if="$root.CurrentBusiness && $root.reconnectObj.isReconnecting && !$root.dialog_reconnect">...</div> -->

        <v-divider color="white" class="ml-4 mr-4"></v-divider>

        <div class="ma-3">
          <div class="invoice-footer">
              <div class="branding grey-back">
                  <!-- <a href="https://bizopsapp.com/" target="_blank"> -->
                      <div class="branding-box">
                          <div class="dark-opacity-color powered right-align">Powered By</div>
                          <div class="title primary-color">
                              <img class="brand_logo" style="width:16px;height:16px;" src="../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                          </div>
                      </div>
                  <!-- </a> -->
              </div>
          </div>

          <!-- <v-chip small color="success" label text-color="white">
            <v-icon font16 left>verified_user</v-icon>
            Safe &amp; Secure
          </v-chip> -->
            <!-- <v-btn block color="primary" capitalize @click="logout"><v-icon left>logout</v-icon> Log Out</v-btn>    -->
        </div>
        
      </div>
    </v-navigation-drawer>

      <!-- <div style="position:fixed;width:calc(100% - 280px)"> -->
        <div>
            <v-alert style="border:0;border-radius:0;" type="error" font12 dense v-model="$root.offline" class="ma-0">Internet not available!</v-alert>
            <!-- <v-alert style="border:0;border-radius:0;color: #fff;" color="primary" font12 dense class="ma-0 font14">Trying to connect with mobile server...</v-alert> -->
            <router-view/>
        </div>

      <v-dialog v-model="$root.dialog_reconnect" persistent no-click-animation width="360">
        <v-card flat>
          <v-card-title>
            <template v-if="$root.reconnectObj.isConnecting">Connecting...</template>
            <template v-else>Connection interrupted!</template>
          </v-card-title>
          <v-card-text class="pt-4 pb-2">

            <div v-if="$root.offline">
                <p>Internet not available! Connect to internet and try to reconnect.</p>
            </div>

            <v-layout ma-0 row wrap v-if="$root.reconnectObj.isConnecting">
              <v-flex xs2 sm2 md2 center-align>
                <v-icon>phone_android</v-icon>
              </v-flex>

              <v-flex xs8 sm8 md8 style="display: flex;align-items: center;">
                <v-progress-linear color="primary" buffer-value="0" stream></v-progress-linear>
              </v-flex>

              <v-flex xs2 sm2 md2 center-align>
                <v-icon>laptop</v-icon>
              </v-flex>

              <v-flex xs12 sm12 md12 pt-5 v-if="$root.isOfferCreated && $root.connectionNumber">
                    Connection ID: 
                    <v-tooltip bottom :max-width="280">
                        <template v-slot:activator="{ on, attrs }">
                            <span class="primary-color cursor-pointer" v-bind="attrs" v-on="on">
                                <strong>{{$root.connectionNumber}}</strong>
                                <v-icon font20 class="primary-color ml-1" style="margin-bottom:2px">info_outline</v-icon>
                            </span>
                        </template>
                        <span>{{$root.fn_getConnectionMsg()}}</span>
                    </v-tooltip>
              </v-flex>
            </v-layout>

            <!-- <div redcolor class="pt-2 pb-2">
              Notes:
                <ul>
                    <li>Your mobile device must have an active internet connection.</li>
                    <li>Enable mobile server on your BizOps mobile app.</li>
                </ul>
            </div> -->
          </v-card-text>

          <v-card-actions>
            <v-layout ma-0 row wrap>
              <v-flex xs12 sm12 md12 center-align>
                <v-btn color="primary" block :disabled="$root.offline || $root.reconnectObj.isConnecting || $root.reconnectObj.isDisconnected" @click="fn_reconnect"><v-icon left>sync</v-icon>Reconnect</v-btn>
              </v-flex>

              <v-flex xs12 sm12 md12 center-align mt-4 v-if="$root.hasClientBusinesses">
                <v-btn block  @click="fn_switchBusiness" :disabled="$root.offline || $root.reconnectObj.isConnecting || $root.reconnectObj.isDisconnected"><v-icon left>swap_horiz</v-icon> Switch Business</v-btn>
              </v-flex>

              <v-flex xs12 sm12 md12 center-align mt-4>
                <v-btn color="red" block text @click="logout"><v-icon left>logout</v-icon>Logout</v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>

        </v-card>
      </v-dialog>

      <!-- <dialog-feedback ref="ref_dialogFeedback"></dialog-feedback> -->
      <dialog-customer-support ref="ref_dialogCustomerSupport"></dialog-customer-support>
      <dialog-confirmation ref="ref_dialogConfirmation" header="Switch Business" iconClass="dark-opacity-color" :confirmationMessage="'Logout from ' + $root.CurrentBusiness.BusinessName" :actionMethod="fn_switchBusiness"></dialog-confirmation>
      <dialog-shortcut-drawer ref="ref_dialogShortcutDrawer"></dialog-shortcut-drawer>
      <dialog-open-source-library ref="ref_dialogOpenSourceLibrary"></dialog-open-source-library>
  </div>
</template>

<script>

// import dialogFeedback from '../components/settings/feedback/dialog_form'
import dialogCustomerSupport from '../components/dialogs/dialog_customer_support'
import dialogConfirmation from '../components/dialogs/dialog_confirmation'
import dialogShortcutDrawer from '../components/dialogs/dialog_shortcut_drawer'
import dialogOpenSourceLibraries from '../components/settings/about/dialog_open_source_lib'
  
  // import masterItems from '../components/actions/masters'

  export default {

    components:{
      // 'dialog-feedback':dialogFeedback,
      'dialog-customer-support':dialogCustomerSupport,
      'dialog-confirmation':dialogConfirmation,
      'dialog-shortcut-drawer':dialogShortcutDrawer,
      'dialog-open-source-library':dialogOpenSourceLibraries
    },

    data(){
        return{
            currentComponent:null,
            cards: ['Today'],
            selectedItem:null,
            drawer: null,
            instantSelected:0,
            instantItems:[
              {
                id:1,
                action: 'dashboard',
                active: true,
                selected:0,
                title: 'Dashboard',
                path:'/dashboard',
                icon:'dashboard'
              },
              {
                id:2,
                action: 'favourites',
                active: true,
                selected:0,
                title: 'Favourites',
                path:'/favourite_items',
                icon:'favorite'
              },
              {
                id:3,
                action: 'masters',
                active: true,
                selected:0,
                title: 'Masters',
                path:'/master_items',
                icon:'category'
              },
              {
                id:4,
                action: 'transactions',
                active: true,
                selected:0,
                title: 'Transactions',
                path:'/transaction_items',
                imgIcon:true,
                icon:'receipt'
              },
              {
                id:5,
                action: 'Reports',
                active: true,
                selected:0,
                title: 'Reports',
                path:'/reports',
                icon:'grid_view'
              },
            ],
            items: [
              // {
              //   id:1,
              //   action: 'dashboard',
              //   active: true,
              //   selected:0,
              //   items: [
              //     { title: 'Dashboard', path:'/dashboard' },
              //     { title: 'Report List', path:'/reports' }
              //   ],
              //   title: 'Reports',
              // },
              {
                id:2,
                action: 'local_activity',
                active: false,
                selected:null,
                items: [
                  { title: 'Transactions', path:'/transactions' },
                  { title: 'Parties', path:'/parties' },
                  { title: 'Items', path:'/items' },
                  { title: 'Orders', path:'/orders' },
                ],
                title: 'Activity',
              },
              // {
              //   id:3,
              //   action: 'view_list',
              //   active: false,
              //   selected:null,
              //   items: [
              //     { title: 'Favourites', path:'/favourite_items' },
              //     { title: 'Masters', path:'/master_items' },
              //     { title: 'Transactions', path:'/transaction_items' }
              //   ],
              //   title: 'Actions',
              // },
              {
                action: 'more_horiz',
                active: false,
                selected:null,
                items: [
                  { title: 'Settings', icon:"settings", path:'/settings' },
                  { title: 'Watch Help Videos', icon:"help_outline", isDialog:true, ref:"ref_helpVideos" },
                  // { title: 'Get Support', icon:"support_agent", isDialog:true, ref:"ref_dialogCustomerSupport" },
                  { title: 'Chat with us', icon:"chat", isDialog:true, ref:"ref_chat" },
                  { title: 'Talk to us', icon:"phone_in_talk", isDialog:true, ref:"ref_talk" },
                  // { title: 'Send Feedback', icon:"feedback", isDialog:true, ref:"ref_dialogFeedback" },
                  { title: 'Send Feedback', icon:"feedback", path:'/settings/feedback/items/new' },
                  { title: 'Accountant Access', icon:"account_circle", path:'/accountants' },
                ],
                title: 'More',
              }
            ],
            saveBtnKey:1
            // reconnectObj:{
            //   isConnecting:false,
            //   isDisconnected:false
            // }
        }
    },
    methods:{

        fn_openTerms: function(){
            window.open('https://bizopsapp.com/terms/');
        },

        fn_openDialogOpenSourceLibraries: function(){
            this.$refs["ref_dialogOpenSourceLibrary"].openDialogWeb();
        },

        fn_businessProfile: function(){
            let listView = this.$route.params.listId ? this.$route.params.listId : 'dashboard'
            // this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/' + listView + '/business/profile/edit'});
            this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/' + listView + '/business/profile/display'});
        },

      fn_reconnect: function(){
        if(!navigator.onLine){
            this.$eventHub.$emit('snackbarAlert', {msg:"Internet not available"});
            return;
        }

        this.getUser(userId=>{

            // reset reconncect dialog if offer not created within 15 seconds
            // setTimeout(() => {
            //   if(!this.$root.isOfferCreated && this.$root.reconnectObj.isConnecting){
            //     this.$root.reconnectObj.isConnecting = false;
            //     this.$root.reconnectObj.isDisconnected = false;
            //     this.$eventHub.$emit('snackbarAlert', {msg:'Connection timeout! Please try again'});
            //     this.fn_closeConnection(false, 'prevent_logout', null, true);
            //   }
            // }, 1 * 15 * 1000);

            if(userId){
                this.$root.reconnectObj.isConnecting = true;
                this.$root.reconnectObj.isReconnecting = true;
                let businessData = JSON.parse(JSON.stringify(this.$root.CurrentBusiness));
                businessData.IsTemporary = this.$root.CurrentBusiness.temporaryBusiness ? true : false;
                if(this.$root.CurrentBusiness.temporaryBusiness){
                    businessData.BusinessID = this.$root.mainBusinessID;
                    businessData.TempBusinessID = this.$root.CurrentBusiness.BusinessID;
                }
                else{
                    businessData.TempBusinessID = null;
                }

                businessData.type = localStorage.getItem('login_type');
                // businessData.TempBusinessID = this.$root.CurrentBusiness.temporaryBusiness ? this.$root.CurrentBusiness.BusinessID : null;
                this.$root.fn_getTwilioIceServers(response => {
                    if(response == 'error'){
                        this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Please try again'});
                        // this.logout();
                        return;
                    }

                    this.fn_checkDeviceStatus(businessData, null, null, userId, null, this.$root.reconnectObj, null, response);
                });
                
            }
            else{
                this.$eventHub.$emit('snackbarAlert', {msg:'You have been logged out!'});
                this.fn_navigate('/login');
            }
        })
        // let userId = this.getCurrentUser();
        // this.fn_checkDeviceStatus(businessData, null, null, userId, null, this.$root.reconnectObj);
      },

      logout: function(){
        // if(!navigator.onLine){
        //     this.$eventHub.$emit('snackbarAlert', {msg:"Internet not available"});
        //     return;
        // }
        this.fn_closeConnection(true);

        // let self = this;
        // this.signOut(function(event){
        //   if(event == "error"){
        //     alert("something went wrong!");
        //   }
        //   else{
        //     self.fn_closeConnection(true);
        //   }
        // })
      },

      fn_switchBusiness: function(){
        if(!navigator.onLine){
            // alert("Internet not available!");
            this.$eventHub.$emit('snackbarAlert', {msg:"Internet not available"});
            return;
        }
        this.fn_closeConnection(false, 'switch');
      },

      fn_openDialogConfiramtion: function(){
          this.$refs['ref_dialogConfirmation'].openDialogWeb();
      },

      fn_openDialogShortcuts: function(){
          this.$refs['ref_dialogShortcutDrawer'].openDialogWeb();
      },

      fn_closeDialog: function(){
          this.$refs['ref_dialogConfirmation'].closeDialogWeb();
      },

      fn_view: function (childItem, item) {

        item ? this.instantSelected = null : '';

        if(this.$root.CurrentBusiness.temporaryBusiness && childItem.ref == 'ref_dialogCustomerSupport'){
            this.$eventHub.$emit('snackbarAlert', {msg:"Remove temporary business to open user support"});
            return;
        }

        if(childItem.ref == 'ref_helpVideos'){
          window.open("https://www.youtube.com/@BizOpsApp/videos", '_blank');
          return;
        }

        if(childItem.ref == 'ref_chat'){
          window.open("https://api.whatsapp.com/send?phone=918882741212", '_blank');
          return;
        }

        if(childItem.ref == 'ref_talk'){
          window.open("https://api.whatsapp.com/send?phone=918882741212&text=Call me back", '_blank');
          return;
        }

        if(childItem.isDialog){
          this.$refs[childItem.ref].openDialogWeb();
          return;
        }

        if(!childItem.path){
          return;
        }

        for(let i in this.items){
          if(item && this.items[i].id != item.id){
            this.items[i].selected = null;
          }
          else{
            this.items[i].selected = null;
          }
        }
        
        console.log(this.$route);

        let routePath = '/'+this.$root.CurrentBusiness.BusinessID + childItem.path;

        if(routePath === this.$route.path){
            return
        }
        else{
            this.$router.push({path:routePath});
        }
      }
    }
    // mounted: function(){
    //   let isCalled = false;
    //   this.$eventHub.$on('connected', () => {
    //       setTimeout(() => {
    //           if(!isCalled){
    //               isCalled = true;
    //               if(this.$refs['ref_dialogFeedback']){
    //                 this.$refs['ref_dialogFeedback'].loading = false;
    //                 this.saveBtnKey++;
    //               }
    //           }

    //           setTimeout(() => {
    //             isCalled = false;
    //           }, 3000);

    //       }, 1000);
    //   });
    // }
  }
</script>
