<template>
    <div>
        <v-card flat>
            <!-- <v-card-title nopadding noshadow class="dialog_searchbox">

                <v-subheader full-width>Select {{$parent.toolbarTitle}} Form
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeFilterNav"><v-icon>close</v-icon></v-btn>
                </v-subheader>

                <v-text-field hide-details grey-back solo flat placeholder="Search" v-model="searchValue"></v-text-field>
            </v-card-title> -->

            <v-subheader>Select {{$parent.toolbarTitle}} Form
                <v-spacer></v-spacer>
                <v-btn icon @click="closeFilterNav"><v-icon>close</v-icon></v-btn>
            </v-subheader>

            <v-divider></v-divider>
            
            <v-card-text full-height nopadding class="data-container">

                <v-list nopadding>
                    <v-list-item v-for="(item, index) in filteredItems" :key="index" @click="setTransactionType(item, null, true)">

                        <v-list-item-content>
                          <v-list-item-title>{{item.name}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon v-if="item.transactionTypeFormId == selectedTypeFormId">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <!-- <div class="display_msg" v-if="!filteredItems.length">No Items</div> -->

                <div white-back sticky-bottom class="ma-4" v-if="clearAllBtn">
                    <v-btn color="blue darken-1" block outlined text @click="clearAll">clear all</v-btn>
                </div>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default{
        props:['transactionTypeId', 'setTransactionType', 'selectedTypeFormId', 'transactionTypeList', 'hideNew', 'clearAllBtn', 'clearAll', 'closeFilterNav'],
        data(){
            return{
                dialog_transactionTypeList:false,
                searchValue:''
            }
        },
        methods:{
            fn_setCurrentFilterValues: function(){
                this.searchValue = '';
            },
            // openDialogWeb: function () {
            //  this.searchValue = '';
            //  this.dialog_transactionTypeList = true;
            // },
            // closeDialogWeb: function () {
            //  this.dialog_transactionTypeList = false;
            // },
            // fn_newTransactionType: function () {
            //  this.$parent.backButtonPress = false;
            //  this.$router.push({path:"/settings/transaction/"+this.transactionTypeId+'/new', query:{tagId:this.guidWeb(), ls:1}});
            // },
        },
        computed:{
            filteredItems: function () {
                let searchText = this.searchValue.toLowerCase();

                if(this.transactionTypeList && this.transactionTypeList.length){
                    return this.transactionTypeList.filter(item => {
                      let name = item.name.toLowerCase();
                      return name.match(searchText);
                    })  
                }
                else{
                    return [];
                }
                
            }
        }
    }
</script>