<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>Tax Category</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider>

                <div class="data-container grey-back">

                  <ValidationObserver ref="observer">

                    <v-form @submit.prevent="submit">
                      <v-layout row wrap ma-0 pr-4 pl-4 white-back pt-4 pb-4>
                        <v-flex xs12 sm12 md12>

                          <ValidationProvider name="Name" rules="required|max50" v-slot="{ errors }">
                             <v-text-field 
                                 v-model="$parent.Form.name" 
                                 label="Name"
                                 required
                                 autocomplete="null"
                                 :error-messages="errors">
                             </v-text-field>
                         </ValidationProvider>

                        </v-flex>

                          <v-flex xs12 sm12 md12>
                            <ValidationProvider name="Tax" rules="required" v-slot="{ errors }">
                              <v-text-field  
                                type="number" 
                                v-model.number="$parent.Form.taxValue" 
                                label="Tax (Percentage)"
                                autocomplete="null"
                                required
                                :error-messages="errors">
                                  
                              </v-text-field>
                            </ValidationProvider>
                          </v-flex>

                          <v-checkbox label="Cess Applicable" v-model="$parent.Form.isCessApplicable"></v-checkbox>

                          <v-flex xs12 sm12 md12 v-if="$parent.Form.isCessApplicable">
                            <ValidationProvider name="Cess" rules="required" v-slot="{ errors }">
                              <v-text-field  
                                type="number" 
                                autocomplete="null"
                                v-model.number="$parent.Form.cessValue" 
                                label="Cess (Percentage)" 
                                required
                                :error-messages="errors"></v-text-field>
                            </ValidationProvider>
                          </v-flex>
                      </v-layout>
                    </v-form>

                  </ValidationObserver>

                </div>


                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex xs12 sm12 md12 height48>
                            <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>
                  

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
// import { required } from 'vee-validate/dist/rules'
// import { extend, ValidationProvider, setInteractionMode } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })

import { ValidationProvider, ValidationObserver } from 'vee-validate'

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
                this.$parent.disableOnSave = false;
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_validateForm: function(){
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                }
              });
            },
        }
    }
</script>