<template>
    <div class="display_wrapper">

        <v-toolbar :height="56" flat id="non-printable-area">
            <!-- <v-toolbar-side-icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-toolbar-side-icon> -->

            <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>

            <v-toolbar-title :class="{'pt-1':fixedTabs}" :style="{'line-height':fixedTabs ? '20px' : '1.5'}">
                {{fixedTabs ? displayData.name : 'Item'}}
                <div font12 v-if="fixedTabs">
                    <template v-for="(item, index) in summaryArray">
                        <span :key="index" v-if="summaryArray && summaryArray.length">
                            <span :class="{'redcolor':item.qty < 0}">{{item.qty}} {{item.unitName}}</span> 
                        </span>    
                    </template>
                </div>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn outlined small @click="fn_edit" color="primary" v-if="displayData && !displayData.isSystem">
                <v-icon left>edit</v-icon> Edit
            </v-btn>

            <v-btn class="ml-4" small outlined @click="fn_openDialogConfirmation" color="red" v-if="displayData && !displayData.isSystem">
                <v-icon left>delete</v-icon> Delete
            </v-btn>

            <v-btn class="ml-4" small outlined @click="fn_copy" color="primary" v-if="displayData">
                <v-icon left>content_copy</v-icon> Copy
            </v-btn>

           <!--  <v-btn icon @click="fn_getBottomMenu" v-if="displayData && (!displayData.inventory || displayData.groupTypeId != 101) ">
              <v-icon>more_vert</v-icon>
            </v-btn> -->
        </v-toolbar>

        <v-divider></v-divider>

        <!-- <div grey-back full-height id="non-printable"> -->
            

        <div class="data-container grey-back" v-if="displayData" v-scroll.self="onScroll">

            <div id="top_section">
                <v-list nopadding :three-line="displayData.groupTypeId==101 ? true : false" :two-line="displayData.groupTypeId!=101 ? true : false">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="mb-2 white-space-normal">{{displayData.name}}</v-list-item-title>
                            
                            <!-- <v-list-item-title class="mb-1" v-if="displayData.groupTypeId==101">
                                <template v-for="(item, index) in summaryArray">
                                    <span :key="index" v-if="summaryArray && summaryArray.length">
                                        <span :class="{'redcolor':item.qty < 0}">{{item.qty}} {{item.unitName}}</span>
                                    </span>    
                                </template>
                            </v-list-item-title> -->

                            <template v-if="displayData.groupTypeId==101">
                                <v-list-item-subtitle class="mb-1">
                                    Purchase Rate:&nbsp;<span>&#8377; {{displayData.purchaseRate ? $root.numberToLocale(displayData.purchaseRate) : '0'}}</span>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle class="mb-1">
                                    Sale Price:&nbsp;<span>&#8377; {{displayData.sellingPrice ? $root.numberToLocale(displayData.sellingPrice) : '0'}}</span>&nbsp;
                                </v-list-item-subtitle>
                            </template>

                            <template v-else>
                                <v-list-item-subtitle class="mb-1">
                                    Charges:&nbsp;<span>&#8377; {{displayData.charges ? $root.numberToLocale(displayData.charges) : '0'}}</span>
                                </v-list-item-subtitle>    
                            </template>
                            
                        </v-list-item-content>

                        <v-list-item-action v-if="displayData.groupTypeId==101">
                            <template v-for="(item, index) in summaryArray">
                                <span :key="index" v-if="summaryArray && summaryArray.length" font16>
                                    <span :class="{'redcolor':item.qty < 0}">{{item.qty}} {{item.unitName}}</span>
                                </span>    
                            </template>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <!-- <v-list two-line>
                    <v-list-item font20>
                        <v-list-item-content>
                            <v-list-item-title style="height:32px">{{displayData.name}}</v-list-item-title>
                            <v-list-item-title style="font-weight:500" v-if="displayData.groupTypeId==101">
                                <span v-for="(item, index) in summaryArray" v-if="summaryArray && summaryArray.length">
                                    <span :class="[item.qty < 0 ? 'redcolor' : 'greencolor']">{{item.qty}} {{item.unitName}}</span>
                                </span>
                            </v-list-item-title>
                            <v-list-item-title style="font-weight:500" v-else>-</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list> -->

            </div>

            <v-divider></v-divider>

            <!-- <v-tabs v-model="activeIndex" fixed-tabs @input="fn_tabChange" slider-color="primary" class="fix_width_tabcount2"> -->
            <v-tabs v-model="activeIndex" @input="fn_tabChange" slider-color="primary">
                <v-tab v-for="(tab, index) in tabs" :key="index" ripple>{{tab.title}}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="activeIndex" @input="fn_tabChange">

                <v-tab-item>  
                    <keep-alive>
                        <activity-tab ref="ref_activity" :productId="displayData.productId" :preview="fn_preview" :bindInvoiceTemplate="fn_bindInvoiceTemplate" v-if="displayData"></activity-tab>
                    </keep-alive>
                </v-tab-item>

                <v-tab-item>  
                    <keep-alive>
                        <details-tab ref="ref_details" :displayData="displayData" :summaryArray="summaryArray" v-if="displayData"></details-tab>
                    </keep-alive>
                </v-tab-item>

            </v-tabs-items>

           <!--  <v-footer class="invoice-footer-actions" v-if="displayData && (displayData.inventory && displayData.groupTypeId == 101)">
                <v-layout grid white-back wrap text-xs-center>

                    <v-flex xs4 sm4 md4 pt-2 pb-2 v-ripple @click="fn_adjustStock('plus')">
                        <div right-border>
                            <div><v-icon>add_circle_outline</v-icon></div>
                            <div font11 class="font-weight-bold">Add Stock</div>
                        </div>  
                    </v-flex>

                    <v-flex xs4 sm4 md4 pt-2 pb-2 v-ripple @click="fn_adjustStock('minus')">
                        <div right-border>
                            <div><v-icon>remove_circle_outline</v-icon></div>
                            <div font11 class="font-weight-bold">Reduce Stock</div>
                        </div>
                    </v-flex>

                    <v-flex xs4 sm4 md4 pt-2 pb-2 v-ripple @click="fn_getBottomMenu">
                        <div>
                            <div><v-icon>more_horiz</v-icon></div>
                            <div font11 class="font-weight-bold">More</div>
                        </div>
                    </v-flex>
                </v-layout> 
            </v-footer> -->
            
        </div>

        <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);z-index:99" v-if="loading">
          <v-col cols="6">
            <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
          </v-col>
        </v-row>


        <!-- </div> -->

        <div white-back id="printable" full-width>
          <component :is="printTemplate" ref="ref_printTemplate" :data="printData" v-if="printTemplate && printData!=null"></component>
        </div>

        <dialog-confirmation ref="ref_dialogDeleteConfirmation" header="Delete Master?" :actionMethod="fn_deleteProductAndOpening" params="delete" :displayView='true'></dialog-confirmation>

        <dialog-confirmation ref="ref_dialogConfirmation" header="Batch Alert!" confirmationMessage="<div dark-opacity-color>This item consists batch.<br>Please edit this item in BizOps App on mobile</div>"  :actionMethod="fn_closeDialogConfirmation" :displayView='true' okBtnText="Okay"></dialog-confirmation>

    </div>
</template>

<script>
// const menuItem = require('../../../../config.js').configs.menuItem;
const productTabConfigs = require('../../../../config.js').configs.productTabConfigs;

// const dialogjs =  require('../dialog').dialogjs;


import {masterMixin} from '../../master_mixin.js'
import activityTab from './display_tabs/activity'
import detailsTab from './display_tabs/details'

import dialogConfirmation from '../../../dialogs/dialog_confirmation'
import {transactionMixin} from '../../../transactions/transaction_mixin'

    export default{
        mixins: [masterMixin, transactionMixin],
        components: {
            'activity-tab': activityTab,
            'details-tab': detailsTab,
            'dialog-confirmation': dialogConfirmation
        },
        data(){
            return{
                displayData:null,
                summaryArray:null,
                defaultVariantName:null,
                tabs:[
                    {"id":1, "title":"Activity"},
                    {"id":2, "title":"Details"}
                ],
                // currentBalance:null,
                activeIndex:0,
                offsetTop: 0,
                fixedTabs:false,
                isGoBack:false,
                formId:null,
                dialogStorageId:null,
                printData:null,
                printTemplate:null,
                loading:false
            }
        },
        methods:{

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_closeDialog: function(){
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },
            fn_preview: function(item, formNameFn){
                item.toolbarTitle = formNameFn ? formNameFn(item.transactionListId) : 'order';
                let printType = item.invoiceTemplate == 101 ? 'ab' : item.invoiceTemplate == 201 ? 'sj' : null;

                let type = null;
                if(item.transactionListId == 10001 || item.transactionListId == 10002 || item.transactionListId == 10003 || item.transactionListId == 10004 || item.transactionListId == 10005){
                  type = 'sales';
                }
                else if(item.transactionListId == 20001 || item.transactionListId == 20002 || item.transactionListId == 20003 || item.transactionListId == 20004 || item.transactionListId == 20005){
                  type = 'purchase';
                }
                else if(item.transactionListId == 40002){
                  type = 'inventory';
                }
                
                this.fn_previewInvoice(true, type, printType, item);
            },

            fn_bindInvoiceTemplate: function(type, item){

                this.printTemplate = null;
                this.printData = null;

                this.printTemplate = require('../../../invoice_templates/invoices/template'+item.invoiceTemplate).default;
                // this.printTemplate = require('../invoice_templates/invoices/template1').default;
                console.log(this.printTemplate);

                let dataFnName = item.invoiceTemplate == 101 ? 'AccountBookPrintData' : item.invoiceTemplate == 201 ? 'StockJournalPrintData' : 'InvoiceData';

                if(type=='sharewhatsapp'){
                  this.fn_shareInvoice('sharewhatsapp', item, dataFnName);
                }
                else if(type=='print'){
                  this.fn_getPrintData(dataFnName, item.transactionId, 'print', null, item.mergeTransactionNumber, item.transactionDate, item.transactionListId);
                }
                else if(type=='pdf'){
                  this.fn_getPrintData(dataFnName, item.transactionId, 'pdf', null, item.mergeTransactionNumber, item.transactionDate, item.transactionListId);
                }
                else if(type=='share'){
                  this.fn_shareInvoice('share', item, dataFnName);
                }
            },

            fn_shareInvoice: function(type, item, dataFnName){

                let title = null;
                if(item.transactionListId==10003){
                    title = "Invoice";
                }
                else{
                    title = this.fn_getTransactionTitle(item.transactionListId);    
                }

                // if(item.transactionTypeFormName.toLowerCase() == "itc not eligible"){
             //      item.transactionTypeFormName = "Expense";
             //    }

             //    if(item.transactionTypeFormName == "Retail Invoice" || item.transactionTypeFormName == "Wholesale Invoice" || item.transactionTypeFormName == "Export Invoice" || item.transactionTypeFormName ==  "Stock Transfer"){
             //        item.transactionTypeFormName = "Invoice";
             //    }
        
                let shareObj = {};
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = item.primaryMobileNumber && type == 'sharewhatsapp' ? item.primaryMobileNumber : null;
                shareObj.subject = title + " Details";
                shareObj.email = item.email ? [item.email] : null ;

                let party = item.partyName ? item.partyName + ',' : '';

                shareObj.textMessage = "Dear "+party+"\n\nBelow are your " + title + " details. Please find the attached pdf.\n\nTransaction Date: "+this.$moment(item.transactionDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(item.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;

                // shareObj.textMessage = "Dear "+item.accountLedgerName+",\n\nBelow are your invoice details. Please find the attached pdf of your invoice.\n\nInvoice Date: "+this.$moment(item.createdDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(item.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.fn_getPrintData(dataFnName, item.transactionId, 'pdf', shareObj, item.mergeTransactionNumber, null, item.transactionListId);
              },

            fn_tabChange:function(tabIndex){
                productTabConfigs.activeIndex = tabIndex;
            },



            // onScroll (e) {
            //     console.log(e);
            //     this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
            //     // let topSectionHeight = document.getElementById('top_section').offsetHeight;
            //     // if(this.offsetTop >= topSectionHeight){
            //     if(this.offsetTop>60){
            //         this.fixedTabs = true;
            //     }
            //     else{
            //         this.fixedTabs = false;
            //     }
            // },

            onScroll: function(event){

                // this.offsetTop = window.pageYOffset || document.scrollTop;
                // if(this.offsetTop>60){
                //     this.fixedTabs = true;
                // }
                // else{
                //     this.fixedTabs = false;
                // }

                var element = event.target;

                // this.offsetTop = window.pageYOffset || document.scrollTop;
                if(element.scrollTop>60){
                    this.fixedTabs = true;
                }
                else{
                    this.fixedTabs = false;
                }

                if (element.scrollHeight - element.scrollTop - 20 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.$refs['ref_activity'].listItemObject.items.length < this.$refs['ref_activity'].listItemObject.totalCount){
                        this.$refs['ref_activity'].fn_loadItems();
                    }
                }
            },

            fn_adjustStock(type){
                this.$router.push({path:"/transactions/inventory/stockadjustment/items/new", query:{tagId:this.guidWeb(), type:type, itemid:this.displayData.productId}});
            },

            fn_get: function(itemId){
                var self = this;
                this.displayData = null;
                this.loading = true;
                this.activeIndex = 0;
                
                this.getData('Product', itemId, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          self.displayData = JSON.parse(response);

                          let variants = self.displayData.productVariants;
                          let defaultVariant;

                          for(var i=0; i<variants.length; i++){

                            if(variants[i].isDefault){
                                defaultVariant = variants[i];
                                self.defaultVariantName = variants[i].unitName

                                // defaultVariant.sellingPrice ? self.sellingPrice = defaultVariant.sellingPrice : '';
                                // defaultVariant.wholeSalePrice ? self.wholeSalePrice = defaultVariant.wholeSalePrice : '';
                                // defaultVariant.mrp ? self.mrp = defaultVariant.mrp : '';

                                if(variants.length>1){ //moving default variant to top
                                    self.displayData.productVariants.splice(i, 1);
                                    self.displayData.productVariants.unshift(defaultVariant);
                                }

                                break;
                            }
                          }

                          self.fn_getSummary(itemId);

                          self.fn_getProductBatches();


                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                        }
                    }
                );
            },

            fn_getSummary: function (itemId) {
                var self = this;
                this.getData('ProductSummary', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.summaryArray = JSON.parse(response);
                            self.loading = false;
                            setTimeout(function(){
                                self.$refs['ref_activity'].fn_loadItems(true);
                            },100);
                        }, 
                        onError: function(error){
                          console.log(error);
                          self.loading = false;
                        }
                    }
                );
            },

            fn_getProductBatches: function(){
				let self = this;
				this.getData('GetProductBatchByProductId', this.displayData.productId, 
					{
						onSuccess: function(response){
							console.log(response);
							self.displayData.batches = JSON.parse(response);
						}, 
						onError: function(error){
							console.log(error);
						}
					}
				);
			},

            // fn_showWebView: function () {
            //     var viewObject = {};
            //     viewObject.ToolbarTitle = "Items";
            //     viewObject.TopMenu = [{"MenuID":3, "MenuType":null, "Icon":null, "Text":"Edit", "Action":"edit"}]
            //     viewObject.isBottomMenu = true;

            //     var tagId = null;
            //     this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';

            //     var self = this;
            //     this.showWebView(tagId, viewObject, 
            //         {
            //             onSuccess: function(response){
            //               console.log(response);
            //             }, 
            //             onError: function(error){
            //               console.log(error);
            //             }, 
            //             // onChange: null, 
            //             onMenuItemClick: function (tagId, menuId, menuAction) {
            //                 self.fn_menuItemClick(tagId, menuId, menuAction)
            //             }
            //         }
            //     );
            // },

            // fn_getBottomMenu: function (tagId) {
            //     var bottomMenu = {"BottomMenu": [menuItem.copy]};
            //     !this.displayData.isSystem ? bottomMenu.BottomMenu.push(menuItem.delete) : '';

            //     this.setBottomMenu(tagId, bottomMenu, 
            //         {
            //             onSuccess: function (response) {
            //                 console.log(response);
            //             },
            //             onError: function (error) {
            //                 console.log(error);
            //             }
            //         }
            //     )
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //     // tagId id form future use
            //     // menuId id form future use
            //     if(menuAction=='edit' && this.displayData && this.displayData.productId){
            //         this.fn_edit();
            //     }
            //     else if(menuAction=='copy'){
            //         this.$router.push({path:'/masters/inventory/productandservices/items/new', query:{tagId:this.guidWeb(), itemId:this.displayData.productId, copy:true}});
            //     }
            //     else if(menuAction=='delete'){
            //         this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            //         // this.fn_deleteProductAndOpening();
            //     }
            // },

            fn_openDialogConfirmation: function(){
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = true;
            },

            fn_deleteProductAndOpening: function(){
                this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = false;
                let self = this;
                let filterObject = {};
                filterObject.productId = this.displayData.productId;
                if(this.displayData.opening && this.displayData.opening.transactionId){
                    filterObject.transactionId = this.displayData.opening.transactionId;
                }
                this.postFilterData('deleteProductWithOpening', null, filterObject, {

                    onSuccess: function(response){
                        
                        console.log(response);
                        if(JSON.parse(response)){
                            // alert('Item deleted');
                            self.$eventHub.$emit('snackbarAlert', {msg:'Item deleted'});
                            self.$eventHub.$emit('UpdateItemList', {});
                            self.fn_goBack();
                        }
                        else{
                            // alert("Item cannot be deleted as already in use");
                            self.$eventHub.$emit('snackbarAlert', {msg:'Item cannot be deleted as already in use'});
                        }
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                });
            },

            fn_edit: function () {
                if(this.displayData.batches && this.displayData.batches.length){
                    this.actionMethod = this.fn_closeDialogConfirmation;
                    this.$refs['ref_dialogConfirmation'].dialog_confimation = true;
                    return;
                }
                this.$router.push({path:'/'+this.$route.params.businessId+'/'+this.$route.params.listId+'/product/items/'+this.displayData.productId+'/edit', query:{inventory:this.$route.query.inventory != undefined ? this.$route.query.inventory : 1}});
            },

            fn_closeDialogConfirmation: function(){
                this.$refs['ref_dialogConfirmation'].dialog_confimation = false;
            },

            fn_copy: function(){
                this.$router.replace({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/product/items/new', query:{tagId:this.guidWeb(), itemId:this.displayData.productId, copy:true}});
            },

            fn_goBack: function(){
                this.isGoBack = true;
                this.$router.go(-1);
            }
        },
        mounted: function(){
            // this.fn_showWebView();

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_onBackPress = this.fn_goBack;
            
            if(this.$route.params && this.$route.params.tagId){
                this.fn_get(this.$route.params.tagId);
            }

            this.$eventHub.$on('delete', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_openDialogConfirmation() : '';
            });

            this.$eventHub.$on('edit', () => {
                this.displayData && !this.displayData.isSystem ? this.fn_edit() : '';
            });

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteUpdate(to, from, next){
            // alert('up');
            console.log(from);
            next(true)
            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }
                }, 1000);
            });
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('connected');
            next(true)
        },
        // beforeRouteLeave (to, from, next) {
        //     if(this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation){
        //         this.$refs['ref_dialogDeleteConfirmation'].dialog_confimation = false;
        //         next(false);
        //     }
        //     else{
        //         if(!this.isGoBack && !localStorage.getItem("transaction_items_state")){
        //             localStorage.setItem("scroll_top", 1);
        //         }
        //         else{
        //             localStorage.removeItem("scroll_top");
        //         }
        //         next(true);
        //     }
        // }
    }

</script>

<style>
    [space-even], .space-even{
        justify-content: space-evenly !important;
    }
</style>