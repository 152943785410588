<template>
    <div>
        <v-dialog v-model="dialog_feedback" no-click-animation persistent lazy transition="slide-y-transition" max-width="460px">

            <v-card>
                <v-toolbar flat :height="56">
                    <v-btn icon @click="closeDialogWeb" :disabled="loading"><v-icon>close</v-icon></v-btn>
                    <v-toolbar-title>Add Feedback</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider> 

                <v-card-text full-height nopadding grey-back>

                  <v-progress-linear indeterminate :height="6" color="primary" v-if="loading"></v-progress-linear>

                    <ValidationObserver ref="observer">
                        <v-form @submit.prevent="submit" :disabled="loading">

                            <v-layout ma-0 pt-4 row wrap white-back>
                                <v-flex xs12 sm12 md12 form-item>
                                  <v-select
                                      :prepend-icon="feedbackType.value == 1 ? 'sentiment_very_satisfied' : feedbackType.value == 2 ? 'sentiment_very_dissatisfied' : feedbackType.value == 3 ? 'chat_bubble_outline' : 'bug_report' "
                                          v-model="feedbackType"
                                          placeholder="Type"
                                          :items="feedbackTypes"
                                          item-text="name"
                                          item-value="value"
                                          label="Type"
                                          return-object
                                          single-line>
                                    </v-select> 
                                  </v-flex>

                                  <v-flex xs12 sm12 md12 form-item>
                                    <div class="light-opacity-color pb-1 font12">
                                      {{fn_getMessageFieldLable()}}
                                    </div>

                                    <ValidationProvider name="feedback" rules="required" v-slot="{ errors }">

                                      <v-textarea  
                                        rows="10" 
                                        outlined
                                        solo
                                        flat
                                        v-model="Form.FeedbackText" 
                                        placeholder=" "
                                        :error-messages="errors"
                                        required>
                                      </v-textarea>

                                    </ValidationProvider>
                                      <!-- <ValidationProvider :name="item.displayName" :rules="item.isRequired ? 'required|max:'+item.additionalData.maxLength : 'max:'+item.additionalData.maxLength" v-slot="{ errors }">
                                          <v-text-field  
                                              :label="item.displayName" 
                                              type="number" 
                                              v-model.number="item.value" 
                                              :error-messages="errors"
                                              required>
                                          </v-text-field>
                                      </ValidationProvider> -->
                                  </v-flex>
                            </v-layout>
                        
                        </v-form>
                    </ValidationObserver>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.native="closeDialogWeb" :disabled="loading">close</v-btn>
                    <v-btn color="blue darken-1" text @click.once="fn_checkValidations" :key="$parent.saveBtnKey" :disabled="loading">ok</v-btn>
                </v-card-actions>
            </v-card>   
        </v-dialog>
    </div>
</template>     

<script>

import { required, max } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('required', required);
extend('max', max);

export default{
    props:['closeDialog'],
    data(){ 
        return{
            dialog_feedback: false,
            Form:{"Rating":null, "FeedbackType":null, "FeedbackText":null},
            feedbackTypes: [
              { name: "I like something", value:1 },
              { name: "I don't like something", value:2 },
              { name: "I have a suggestion", value:3 },
              { name: "Report a bug", value:4 },
            ],
            feedbackType: { name: "I don't like something", value:2 },
            loading:false
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        openDialogWeb: function () {
          this.Form = {"Rating":null, "FeedbackType":null, "FeedbackText":null};
          this.feedbackType = { name: "I don't like something", value:2 };
          this.dialog_feedback = true;
        },
        closeDialogWeb: function () {
            this.dialog_feedback = false;
            this.closeDialog();
        },

        fn_getMessageFieldLable: function(){
          if(this.feedbackType.value == 1){
            return "What did you like?";
          }
          else if(this.feedbackType.value == 2){
            return "What did you not like?";
          }
          else if(this.feedbackType.value == 3){
            return "What do you suggest?";
          }
          else{
            return "What is the bug?";
          }
        },

        fn_checkValidations: function(){
            this.$refs.observer.validate().then((result) => {
                if (result) {
                    // this.showLoading('Please wait...');
                    this.loading = true;
                    this.$root.fn_getAuthToken(token=>{
                      this.fn_postFeedback(token);
                    })
                }
                else{
                  this.$parent.saveBtnKey++;
                }
            });
        },

        fn_postFeedback: function(token){
          if(!navigator.onLine){
            this.loading = false;
            // alert('Internet not available');
            this.$parent.saveBtnKey++;
            this.$eventHub.$emit('snackbarAlert', {msg:'Internet not available'});
            // this.hideLoading();
            return;
          }

          let self = this;
          let postObj = this.Form;
          postObj.BusinessID = this.$root.CurrentBusiness.BusinessID;
          postObj.token = token;
          postObj.FeedbackType = this.feedbackType.value;

          if(token=='error'){
            this.loading = false;
            console.log("Token not found");
            this.$parent.saveBtnKey++;
            // this.hideLoading();
            // alert("Something went wrong. Try again!.");
            this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
            return;
          }

          console.log(JSON.stringify(postObj));

          this.postServerData('PostUserFeedback', postObj, 
          {
            onSuccess: function(response){
                // self.fn_resetDatabase(backupAccountId);
                console.log(response);
                self.loading = false;
                // self.hideLoading();
                // alert('Thanks for the feedback');
                self.$eventHub.$emit('snackbarAlert', {msg:'Thank you for the feedback'});
                // self.fn_goBack();
                self.closeDialogWeb();
            }, 
            onError: function(error){
                console.log(error);
                self.loading = false;
                self.$parent.saveBtnKey++;
                // self.hideLoading();
                // AndroidRoutingTest.revokeAccount();
                if(typeof JSON.parse(error) == 'object'){
                  let response = JSON.parse(error);
                  //   alert(response.Message);
                  self.$eventHub.$emit('snackbarAlert', {msg:response.Message});
                }
            }
          })
        }
    }
}
</script>