import Vue from 'vue'
import VueRouter from 'vue-router'
const transaction = require('../config.js').configs.transactionTypes;

import Home from '../views/Home.vue'
import Login from '../components/login/login.vue'
import ListViewContainer from '../views/list_view_container.vue'

// reports
import Dashboard from '../components/reports/dashboard.vue'
import ReportContainer from '../components/reports/report_container/report_container.vue'

// masters
// import AccountGroupItems from '../components/masters/forms/account_groups/items.vue'
import AccountGroupForm from '../components/masters/forms/account_groups/form.vue'
import AccountGroupDisplay from '../components/masters/forms/account_groups/display.vue'

import DiscountForm from '../components/masters/forms/discounts/form.vue'
import DiscountDisplay from '../components/masters/forms/discounts/display.vue'

import ExpenseCategoryForm from '../components/masters/forms/expense_categories/form.vue'
import ExpenseCategoryDisplay from '../components/masters/forms/expense_categories/display.vue'

import ListForm from '../components/masters/forms/lists/form.vue'

import LedgerForm from '../components/masters/forms/ledgers/form.vue'
import LedgerDisplay from '../components/masters/forms/ledgers/display.vue'
import LedgersImport from '../components/masters/forms/ledgers/ledgers_import.vue'

import ListItemForm from '../components/masters/forms/lists/list_items/form.vue'
import ListItemDisplay from '../components/masters/forms/lists/list_items/display.vue'

import OtherChargeForm from '../components/masters/forms/other_charges/form.vue'
import OtherChargeDisplay from '../components/masters/forms/other_charges/display.vue'

import ProductForm from '../components/masters/forms/product/form.vue'
import ProductDisplay from '../components/masters/forms/product/display.vue'
import ItemsImport from '../components/masters/forms/product/items_import.vue'

import ProductCategoryForm from '../components/masters/forms/product_categories/form.vue'
import ProductCategoryDisplay from '../components/masters/forms/product_categories/display.vue'

import TaxCategoryForm from '../components/masters/forms/tax_category/form.vue'
import TaxCategoryDisplay from '../components/masters/forms/tax_category/display.vue'

import TaxForm from '../components/masters/forms/taxes/form.vue'
import TaxDisplay from '../components/masters/forms/taxes/display.vue'

import UnitForm from '../components/masters/forms/units/form.vue'
import UnitDisplay from '../components/masters/forms/units/display.vue'

import StatementView from '../components/masters/forms/ledgers/ledger_statement_view.vue'

// import AccountGroupDisplay from '../components/masters/forms/account_groups/display.vue'

// transactions
// Accountbook
import AccountBookDisplay from '../components/transactions/forms/accountbooks/display.vue'
import AccountBookForm from '../components/transactions/forms/accountbooks/form.vue'

// Sale
import SaleForm from '../components/transactions/forms/sale/form.vue'
import SaleDisplay from '../components/transactions/forms/sale/display.vue'

// Purchase
import PurchaseForm from '../components/transactions/forms/purchase/form.vue'
import PurchaseDisplay from '../components/transactions/forms/purchase/display.vue'

// Expense
import ExpenseForm from '../components/transactions/forms/expense/form.vue'
import ExpenseDisplay from '../components/transactions/forms/expense/display.vue'

// Stock Adjustment
import StockAdjustmentForm from '../components/transactions/forms/stock_adjustment/form';
import StockAdjustmentDisplay from '../components/transactions/forms/stock_adjustment/display';

// Opening Ledger
import OpeningLedgerForm from '../components/transactions/forms/opening_ledger/form';
import OpeningLedgerDisplay from '../components/transactions/forms/opening_ledger/display';

// Opening Stock
import OpeningStockForm from '../components/transactions/forms/opening_stock/form';
import OpeningStockDisplay from '../components/transactions/forms/opening_stock/display';

// Party Balance
import partyBalanceForm from '../components/transactions/forms/party_balance/form';
import partyBalanceDisplay from '../components/transactions/forms/party_balance/display';

// Invoice template preview
import invoicePreview from '../components/invoice_templates/invoice_preview'
import invoicePreviewSettings from '@/components/invoice_templates/invoice_template_preview'


// Settings

// Accountant
import accountantForm from '../components/settings/accountant/form'

// Business
import displayBusiness from '@/components/settings/business/display'
import updateBusiness from '@/components/settings/business/form'

// printfields
import printFieldForm from '@/components/settings/print_fields/form'

// Accountant
import feedbackForm from '../components/settings/feedback/form'

import transactionSettings from '../components/settings/transaction_settings/transaction_settings'
import transactionSettingsNew from '../components/settings/transaction_settings/transaction_settings_new'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login'},
  { path: '/login', name: 'Login', component: Login},
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
        {
          path: '/:businessId/dashboard',
          component: Dashboard
        },
        { path: '/:businessId/:listId/items_import', component: ItemsImport },
        { path: '/:businessId/:listId/ledgers_import', component: LedgersImport },
        {
          path: '/:businessId/:listId',
          component: ListViewContainer,
          name:"itemlist",
          children:[
            // report
            { path: '/:businessId/:listId/:reportId', name:"reportContainer", component: ReportContainer, props:true },

            // masters
            // { path: '/:businessId/:listId/account_group/items', component: AccountGroupItems },
            { path: '/:businessId/:listId/account_group/items/new', component: AccountGroupForm, name:"accountGroupForm" },
            { path: '/:businessId/:listId/account_group/items/:tagId', component: AccountGroupDisplay, name:"accountGroupDisplay" },
            { path: '/:businessId/:listId/account_group/items/:tagId/edit', component: AccountGroupForm, name:"accountGroupEdit" },

            { path: '/:businessId/:listId/discount/items/new', component: DiscountForm },
            { path: '/:businessId/:listId/discount/items/:tagId', component: DiscountDisplay, name:"discountDisplay" },
            { path: '/:businessId/:listId/discount/items/:tagId/edit', component: DiscountForm },

            { path: '/:businessId/:listId/expense_category/items/new', component: ExpenseCategoryForm },
            { path: '/:businessId/:listId/expense_category/items/:tagId', component: ExpenseCategoryDisplay, name:"expenseCategoryDisplay" },
            { path: '/:businessId/:listId/expense_category/items/:tagId/edit', component: ExpenseCategoryForm },

            { path: '/:businessId/:listId/list/items/new', component: ListForm },
            { path: '/:businessId/:listId/list/items/:tagId/edit', component: ListForm },

            { path: '/:businessId/:listId/ledger/items/new', component: LedgerForm },
            { path: '/:businessId/:listId/ledger/items/:tagId', component: LedgerDisplay, name:"ledgerDisplay" },
            { path: '/:businessId/:listId/ledger/items/:tagId/edit', component: LedgerForm },
            { path: '/:businessId/:listId/ledger/items/:tagId/statement', component: StatementView },

            { path: '/:businessId/:listId/list_item/items/new', component: ListItemForm },
            { path: '/:businessId/:listId/list_item/items/:tagId', component: ListItemDisplay, name:"listItemDisplay" },
            { path: '/:businessId/:listId/list_item/items/:tagId/edit', component: ListItemForm },

            { path: '/:businessId/:listId/other_charge/items/new', component: OtherChargeForm },
            { path: '/:businessId/:listId/other_charge/items/:tagId', component: OtherChargeDisplay, name:"otherChargeDisplay" },
            { path: '/:businessId/:listId/other_charge/items/:tagId/edit', component: OtherChargeForm },

            { path: '/:businessId/:listId/product/items/new', component: ProductForm },
            { path: '/:businessId/:listId/product/items/:tagId', component: ProductDisplay, name:"productDisplay" },
            { path: '/:businessId/:listId/product/items/:tagId/edit', component: ProductForm },

            { path: '/:businessId/:listId/product_category/items/new', component: ProductCategoryForm },
            { path: '/:businessId/:listId/product_category/items/:tagId', component: ProductCategoryDisplay, name:"productCategoryDisplay" },
            { path: '/:businessId/:listId/product_category/items/:tagId/edit', component: ProductCategoryForm },

            { path: '/:businessId/:listId/tax_category/items/new', component: TaxCategoryForm },
            { path: '/:businessId/:listId/tax_category/items/:tagId', component: TaxCategoryDisplay, name:"taxCategoryDisplay" },
            { path: '/:businessId/:listId/tax_category/items/:tagId/edit', component: TaxCategoryForm },

            { path: '/:businessId/:listId/tax/items/new', component: TaxForm },
            { path: '/:businessId/:listId/tax/items/:tagId', component: TaxDisplay, name:"taxDisplay" },
            { path: '/:businessId/:listId/tax/items/:tagId/edit', component: TaxForm },

            { path: '/:businessId/:listId/unit/items/new', component: UnitForm },
            { path: '/:businessId/:listId/unit/items/:tagId', component: UnitDisplay, name:"unitDisplay" },
            { path: '/:businessId/:listId/unit/items/:tagId/edit', component: UnitForm },
            // { path: '/:businessId/:listId/account_group/items/:tagId/edit', component: AccountGroupForm },

            // transactions

            // Accountbook
            // Receipt
            { path: '/:businessId/:listId/receipt/items/new', name:"receiptForm", component: AccountBookForm, props: {settings:{transactionTypeId:transaction.receipt.id}}},
            { path: '/:businessId/:listId/receipt/items/:tagId', name:"receiptDisplay", component: AccountBookDisplay, props: {settings:{transactionTypeId:transaction.receipt.id}}},
            { path: '/:businessId/:listId/receipt/items/:tagId/edit', name:"receiptFormEdit", component: AccountBookForm, props: {settings:{transactionTypeId:transaction.receipt.id}}},

            // Payment
            { path: '/:businessId/:listId/payment/items/new', name:"paymentForm", component: AccountBookForm, props: {settings:{transactionTypeId:transaction.payment.id}}},
            { path: '/:businessId/:listId/payment/items/:tagId', name:"paymentDisplay", component: AccountBookDisplay, props: {settings:{transactionTypeId:transaction.payment.id}}},
            { path: '/:businessId/:listId/payment/items/:tagId/edit', name:"paymentFormEdit", component: AccountBookForm, props: {settings:{transactionTypeId:transaction.payment.id}}},

            // Journal
            { path: '/:businessId/:listId/journal/items/new', name:"journalForm", component: AccountBookForm, props: {settings:{transactionTypeId:transaction.journal.id}}},
            { path: '/:businessId/:listId/journal/items/:tagId', name:"journal", component: AccountBookDisplay, props: {settings:{transactionTypeId:transaction.journal.id}}},
            { path: '/:businessId/:listId/journal/items/:tagId/edit', name:"journalFormEdit", component: AccountBookForm, props: {settings:{transactionTypeId:transaction.journal.id}}},

            // contra
            { path: '/:businessId/:listId/contra/items/new', name:"contraForm", component: AccountBookForm, props: {settings:{transactionTypeId:transaction.contra.id}}},
            { path: '/:businessId/:listId/contra/items/:tagId', name:"contraDisplay", component: AccountBookDisplay, props: {settings:{transactionTypeId:transaction.contra.id}}},
            { path: '/:businessId/:listId/contra/items/:tagId/edit', name:"contraFormEdit", component: AccountBookForm, props: {settings:{transactionTypeId:transaction.contra.id}}},

            

            // Sale
            // Sale Invoice
            { path: '/:businessId/:listId/saleinvoice/items/new', name:"saleInvoiceForm", component: SaleForm, props: {settings:{transactionTypeId:transaction.salesInvoice.id}}},
            { path: '/:businessId/:listId/saleinvoice/items/:tagId', name:"saleInvoiceDisplay", component: SaleDisplay, props: {settings:{transactionTypeId:transaction.salesInvoice.id}}},
            { path: '/:businessId/:listId/saleinvoice/items/:tagId/edit', name:"saleInvoiceEdit", component: SaleForm, props: {settings:{transactionTypeId:transaction.salesInvoice.id}}},

            // Sale Order
            { path: '/:businessId/:listId/order/items/new', name:"saleOrderForm", component: SaleForm, props: {settings:{transactionTypeId:transaction.order.id}}},
            { path: '/:businessId/:listId/order/items/:tagId', name:"saleOrderDisplay", component: SaleDisplay, props: {settings:{transactionTypeId:transaction.order.id}}},
            { path: '/:businessId/:listId/order/items/:tagId/edit', name:"saleOrderEdit", component: SaleForm, props: {settings:{transactionTypeId:transaction.order.id}}},

            // Delivery Challan
            { path: '/:businessId/:listId/deliverychallan/items/new', name:"deliveryChallanForm", component: SaleForm, props: {settings:{transactionTypeId:transaction.deliveryChallan.id}}},
            { path: '/:businessId/:listId/deliverychallan/items/:tagId', name:"deliveryChallanDisplay", component: SaleDisplay, props: {settings:{transactionTypeId:transaction.deliveryChallan.id}}},
            { path: '/:businessId/:listId/deliverychallan/items/:tagId/edit', name:"deliveryChallanEdit", component: SaleForm, props: {settings:{transactionTypeId:transaction.deliveryChallan.id}}},

            // Sale Return
            { path: '/:businessId/:listId/salereturn/items/new', name:"saleReturnForm", component: SaleForm, props: {settings:{transactionTypeId:transaction.salesReturn.id}}},
            { path: '/:businessId/:listId/salereturn/items/:tagId', name:"saleReturnDisplay", component: SaleDisplay, props: {settings:{transactionTypeId:transaction.salesReturn.id}}},
            { path: '/:businessId/:listId/salereturn/items/:tagId/edit', name:"saleReturnEdit", component: SaleForm, props: {settings:{transactionTypeId:transaction.salesReturn.id}}},

            // Estimate
            { path: '/:businessId/:listId/estimate/items/new', name:"estimateForm", component: SaleForm, props: {settings:{transactionTypeId:transaction.estimate.id}}},
            { path: '/:businessId/:listId/estimate/items/:tagId', name:"estimateDisplay", component: SaleDisplay, props: {settings:{transactionTypeId:transaction.estimate.id}}},
            { path: '/:businessId/:listId/estimate/items/:tagId/edit', name:"estimateEdit", component: SaleForm, props: {settings:{transactionTypeId:transaction.estimate.id}}},


            // Purchase
            // Purchase Invoice
            { path: '/:businessId/:listId/purchaseinvoice/items/new', name:"purchaseInvoiceForm", component: PurchaseForm, props: {settings:{transactionTypeId:transaction.purchaseInvoice.id}}},
            { path: '/:businessId/:listId/purchaseinvoice/items/:tagId', name:"purchaseInvoiceDisplay", component: PurchaseDisplay, props: {settings:{transactionTypeId:transaction.purchaseInvoice.id}}},
            { path: '/:businessId/:listId/purchaseinvoice/items/:tagId/edit', name:"purchaseInvoiceEdit", component: PurchaseForm, props: {settings:{transactionTypeId:transaction.purchaseInvoice.id}}},

            // Purchase Order
            { path: '/:businessId/:listId/purchaseorder/items/new', name:"purchaseOrderForm", component: PurchaseForm, props: {settings:{transactionTypeId:transaction.purchaseOrder.id}}},
            { path: '/:businessId/:listId/purchaseorder/items/:tagId', name:"purchaseOrderDisplay", component: PurchaseDisplay, props: {settings:{transactionTypeId:transaction.purchaseOrder.id}}},
            { path: '/:businessId/:listId/purchaseorder/items/:tagId/edit', name:"purchaseOrderEdit", component: PurchaseForm, props: {settings:{transactionTypeId:transaction.purchaseOrder.id}}},

            // Purchase Challan
            { path: '/:businessId/:listId/purchasechallan/items/new', name:"purchaseChallanForm", component: PurchaseForm, props: {settings:{transactionTypeId:transaction.purchaseChallan.id}}},
            { path: '/:businessId/:listId/purchasechallan/items/:tagId', name:"purchaseChallanDisplay", component: PurchaseDisplay, props: {settings:{transactionTypeId:transaction.purchaseChallan.id}}},
            { path: '/:businessId/:listId/purchasechallan/items/:tagId/edit', name:"purchaseChallanEdit", component: PurchaseForm, props: {settings:{transactionTypeId:transaction.purchaseChallan.id}}},

            // Purchase Return
            { path: '/:businessId/:listId/purchasereturn/items/new', name:"purchaseReturnForm", component: PurchaseForm, props: {settings:{transactionTypeId:transaction.purchaseReturn.id}}},
            { path: '/:businessId/:listId/purchasereturn/items/:tagId', name:"purchaseReturnDisplay", component: PurchaseDisplay, props: {settings:{transactionTypeId:transaction.purchaseReturn.id}}},
            { path: '/:businessId/:listId/purchasereturn/items/:tagId/edit', name:"purchaseReturnEdit", component: PurchaseForm, props: {settings:{transactionTypeId:transaction.purchaseReturn.id}}},

            // Expense
            { path: '/:businessId/:listId/expense/items/new', name:"expenseForm", component: ExpenseForm, props: {settings:{transactionTypeId:transaction.expense.id}}},
            { path: '/:businessId/:listId/expense/items/:tagId', name:"expenseDisplay", component: ExpenseDisplay, props: {settings:{transactionTypeId:transaction.expense.id}}},
            { path: '/:businessId/:listId/expense/items/:tagId/edit', name:"expenseEdit", component: ExpenseForm, props: {settings:{transactionTypeId:transaction.expense.id}}},


            // Stock Adjustment
            { path: '/:businessId/:listId/stockadjustment/items/new', name:'stockAdjustmentForm', component:StockAdjustmentForm, props: {settings:{transactionTypeId:transaction.stockAdjustment.id}}},
            { path: '/:businessId/:listId/stockadjustment/items/:tagId', name:'stockAdjustmentDisplay', component:StockAdjustmentDisplay, props: {settings:{transactionTypeId:transaction.stockAdjustment.id}}},
            { path: '/:businessId/:listId/stockadjustment/items/:tagId/edit', name:'stockAdjustmentEdit', component:StockAdjustmentForm, props: {settings:{transactionTypeId:transaction.stockAdjustment.id}}},

            // Opening Ledger
            { path: '/:businessId/:listId/openingledger/items/new', name:"openingLedgerForm", component: OpeningLedgerForm, props: {settings:{transactionTypeId:transaction.openingLedger.id}}},
            { path: '/:businessId/:listId/openingledger/items/:tagId', name:"openingLedgerDisplay", component: OpeningLedgerDisplay, props: {settings:{transactionTypeId:transaction.openingLedger.id}}},
            { path: '/:businessId/:listId/openingledger/items/:tagId/edit', name:"openingLedgerEdit", component: OpeningLedgerForm, props: {settings:{transactionTypeId:transaction.openingLedger.id}}},

            // Opening Stock
            { path: '/:businessId/:listId/openingstock/items/new', name:"openingStockForm", component: OpeningStockForm, props: {settings:{transactionTypeId:transaction.openingStock.id}}},
            { path: '/:businessId/:listId/openingstock/items/:tagId', name:"openingStockDisplay", component: OpeningStockDisplay, props: {settings:{transactionTypeId:transaction.openingStock.id}}},
            { path: '/:businessId/:listId/openingstock/items/:tagId/edit', name:"openingStockEdit", component: OpeningStockForm, props: {settings:{transactionTypeId:transaction.openingStock.id}}},

            // Set Ledger Balance
            { path: '/:businessId/:listId/setbalance/items/new', name:"partyBalanceForm", component: partyBalanceForm, props: {settings:{transactionTypeId:transaction.partyBalance.id}}},
            { path: '/:businessId/:listId/setbalance/items/:tagId', name:"partyBalanceDisplay", component: partyBalanceDisplay, props: {settings:{transactionTypeId:transaction.partyBalance.id}}},

            // accountant form
            { path: '/:businessId/:listId/accountant/items/new', component:accountantForm, name:'accountantForm'},

            // Business
            { path: '/:businessId/:listId/business/profile/display', name:'displayBusiness', component:displayBusiness},
            { path: '/:businessId/:listId/business/profile/edit', name:'updateBusiness', component:updateBusiness},

            // print fields
            { path: '/:businessId/:listId/printfields/items/new', name:'printFieldForm', component:printFieldForm},
            { path: '/:businessId/:listId/printfields/items/:tagId/edit', name:'printFieldEdit', component:printFieldForm},

            // Invoice Preview
            { path: '/:businessId/:listId/:type/items/:tagId/:transactionId', component:invoicePreview, name:'invoicePreview'},
            { path: '/:businessId/:listId/:type/items/:tagId/:transactionId/settings', component:invoicePreviewSettings, name:'invoicePreviewSettings'},

            // Feedback
            { path: '/:businessId/:listId/feedback/items/new', component:feedbackForm, name:'feedbackForm'},

            // item settings
            { path: '/:businessId/:listId/settings/transaction', component:transactionSettings, name:'transactionSettings'},

            // transaction settings
            { path: '/:businessId/:listId/settings/transactionnew', component:transactionSettingsNew, name:'transactionSettingsNew'},
          ]
        }
      ]
    },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
