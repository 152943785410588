<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>

              <v-toolbar :height="56" flat>
                  <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                  <v-toolbar-title>Ledger Opening Balance</v-toolbar-title>
              </v-toolbar>

              <v-divider class="noprint"></v-divider>

              <div class="data-container grey-back">

                <v-progress-linear indeterminate :height="6" color="primary" v-if="$root.dialogLoader || $parent.loading"></v-progress-linear>
                <div center-align class="pa-4 light-opacity-color" v-if="$root.dialogLoader">Syncing master items...</div>

                <template v-if="!$root.dialogLoader && !$parent.loading">

                  <v-subheader class="mb-2" white-background>Opening Date
                    <v-spacer></v-spacer>
                    <span><u>{{$root.CurrentBusiness.OpeningDate | moment("DD-MMM-YYYY")}}</u></span>
                  </v-subheader>

                  <ValidationObserver ref="observer">

                      <v-form>

                    

                          <v-layout row wrap ma-0 white-back pt-4 pb-4>
                            <v-flex xs12 sm12 md12 form-item>
                              <ValidationProvider name="Ledger" rules="required" v-slot="{ errors }">
                                  <v-autocomplete
                                    id="party_dropdown"
                                      :items="$root.ledgers"
                                      v-model="$parent.Form.selectedLedger"
                                      label="Ledger"
                                      item-value="itemId"
                                      item-text="itemName"
                                      return-object
                                      append
                                      required
                                      :error-messages="errors"
                                      @input="$parent.fn_updateAccountLedger">

                                      <v-list-item slot="prepend-item" class="primary--text" @click="fn_new">
                                          <v-list-item-title>Create New</v-list-item-title>
                                      </v-list-item>

                                  </v-autocomplete>

                                  <div class="pt-2 font12 right-align" v-if="$parent.Form.selectedLedger && $parent.Form.ledgerData">
                                    <span light-opacity-color>{{$parent.fn_balanceLabel($parent.Form.ledgerData.groupTypeId, $parent.Form.ledgerData.balance)}}: </span>
                                      <span :class="[$parent.Form.ledgerData.balance < 0 ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale($parent.Form.ledgerData.balance)}}</span>
                                  </div>
                              </ValidationProvider>
                            </v-flex>

                            <v-flex xs12 sm12 md12 form-item>
                              <ValidationProvider name="Amount" rules="required|decimal2" v-slot="{ errors }">
                                <v-text-field 
                                  type="number" 
                                  prefix="₹"
                                  v-model.number="$parent.Form.amount" 
                                  label="Amount" 
                                  required
                                  :error-messages="errors">
                                    
                                </v-text-field>
                              </ValidationProvider>
                          </v-flex>

                          <v-flex xs12 sm12 md12 form-item v-if="$parent.Form.selectedLedger && $parent.Form.ledgerData && $parent.Form.ledgerData.groupTypeId">
                            <v-radio-group v-model="$parent.Form.amountType" row>
                                <v-radio :label="$root.fn_payOrReceive(1, $parent.Form.ledgerData.groupTypeId)" :value="1"></v-radio>
                                <v-radio :label="$root.fn_payOrReceive(0, $parent.Form.ledgerData.groupTypeId)" :value="0"></v-radio>
                            </v-radio-group>
                          </v-flex>
                            
                      </v-layout>

                      <v-layout row wrap white-back ma-0 mt-2>

                            <v-flex sm12 md12 xs12 pa-4>
                              <ValidationProvider v-slot="{ errors }" name="Notes" rules="max:255">
                                <v-textarea
                                  rows="2"
                                  label="Notes" 
                                  auto-grow
                                  :counter="255"
                                  v-model="$parent.Form.transactionData.notes"
                                  :error-messages="errors">
                                </v-textarea>
                              </ValidationProvider>
                            </v-flex>
                        </v-layout>

                  </v-form>

                  </ValidationObserver>

                </template>

          </div>

          <v-card-actions white-back>
              <v-layout row wrap ma-0>

                  <v-flex xs12 sm12 md12 height48>
                      <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                  </v-flex>

              </v-layout>
          </v-card-actions>
                  

          </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
// import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })

extend('decimal2', value => {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

});
    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false,
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_validateForm: function(){
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                }
              });
            },
            fn_new: function(){
              this.closeDialogWeb();
              setTimeout(()=>{
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), ls:1}});
              },100);
            }
        }
    }
</script>