<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>

              <v-toolbar :height="56" flat>
                  <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                  <v-toolbar-title>Print Field</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$parent.fn_resetFieldContent">Reset</v-btn>
              </v-toolbar>

              <v-divider class="noprint"></v-divider>

              <div class="data-container grey-back print-field-content">
                <v-progress-linear indeterminate :height="6" color="primary" v-if="$parent.loading"></v-progress-linear>
                <ValidationObserver ref="observer" v-else>

                    <v-form @submit.prevent="submit" :disabled="$parent.loading">

                  

                        <v-layout row wrap ma-0 pr-4 pl-4 white-back pt-4 pb-4>

                          <v-flex xs12 sm12 md12>
                            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                              <v-text-field
                                id="title"
                                disabled
                                v-model="$parent.Form.name"
                                label="Name"
                                required
                                autocomplete="null"
                                :error-messages="errors">
                              </v-text-field>
                            </ValidationProvider>
                          </v-flex>
                          
                        </v-layout>

                        <v-layout ma-0 row wrap white-back mt-2>
                          <v-card flat full-width>
                            <v-subheader> 
                                Content
                                <v-spacer></v-spacer>
                                <!-- <v-spacer></v-spacer>
                                <v-btn color="primary" flat icon @click="fn_openDialogQuill">
                                  {{!content ? 'Add' : 'Edit'}}
                                </v-btn> -->
                                <v-menu left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                                  </template>
                                  <v-list dense>
                                    <v-list-item @click="$parent.fn_openDialogSignature">
                                      <v-list-item-content>
                                        <v-list-item-title>Add Signature</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item @click="fn_uploadSignature">
                                      <v-list-item-content>
                                        <v-list-item-title>Upload Signature</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item @click="$parent.fn_openDialogBankDetails">
                                      <v-list-item-content>
                                        <v-list-item-title>Add Bank Details</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="$parent.bottomSheet=true">
                                      <v-list-item-content>
                                        <v-list-item-title>Add Fields</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                            </v-subheader>
                            <v-divider></v-divider>

                            <!-- <v-card-text v-html="content" v-if="content"></v-card-text> -->

                            <quill-editor class="hasFoooterBtn full-width" ref="myTextEditor"
                              v-model="content"
                              :options="editorOption"
                              @blur="onEditorBlur($event)"
                              @focus="onEditorFocus($event)"
                              @ready="onEditorReady($event)"
                              @change="fn_previewContent">
                              <div id="toolbar" slot="toolbar">
                                  <!-- Add a bold button -->
                                  <button class="ql-bold">Bold</button>
                                  <button class="ql-italic">Italic</button>
                                  <button class="ql-underline">ql-underline</button>
                                  <button class="ql-strike">Italic</button>
                                  <!-- Add ordered/bullet list -->
                                  <button class="ql-list" value="ordered">ql-list</button>
                                  <button class="ql-list" value="bullet">ql-list</button>

                                  <!-- You can also add your own -->
                                  <!-- <button id="custom-button" style="padding:0"><v-icon>insert_photo</v-icon></button> -->

                                  <!-- Add font size dropdown -->
                                  <select class="ql-size">
                                    <option value="small"></option>
                                    <!-- Note a missing, thus falsy value, is used to reset to default -->
                                    <option selected></option>
                                    <option value="large"></option>
                                    <option value="huge"></option>
                                  </select>

                                  
                              </div>
                            </quill-editor>
                          </v-card>
                          
                        </v-layout>

                        <v-layout ma-0 row wrap white-back mt-2 v-if="$parent.previewContent">
                          <v-card flat full-width>
                              <v-subheader>Preview
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text small @click="fn_editBusinessProfile" v-if="$parent.Form.name=='Header'">Edit Business Details</v-btn>
                              </v-subheader>
                              <v-divider></v-divider>
                              <v-card-text v-html="$parent.previewContent" class="child-paragraph-no-margin"></v-card-text>
                          </v-card>
                        </v-layout>

                </v-form>

              </ValidationObserver>

            </div>

          <v-card-actions white-back v-if="!$parent.loading">
              <v-layout row wrap ma-0>

                  <v-flex xs12 sm12 md12 height48>
                      <!-- <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow @click="handleSubmit($parent.fn_checkValidations)">Save</v-btn> -->
                      <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                  </v-flex>

              </v-layout>
          </v-card-actions>
                  

          </v-card>
        </v-dialog>

        <v-bottom-sheet v-model="$parent.bottomSheet">
          <!-- <v-sheet> -->
              <v-card>
                  <v-card-title primary-title>
                    Add Fields
                    <v-spacer></v-spacer>
                    <v-icon v-ripple @click="$parent.bottomSheet=false">close</v-icon>
                  </v-card-title>
                  <v-card-text style="max-height:200px;overflow:auto;padding-top:0">
                      <div v-for="(category, index) in keywords" :key="index">
                          <v-subheader nopadding>{{category.name}}</v-subheader>
                          <v-btn color="primary" class="mr-2" small outlined v-for="(item, itemIndex) in category.items" :key="itemIndex" @click="fn_addKeyword(item.keyword)">
                              {{item.title}}<v-icon right color="primary">add</v-icon>
                          </v-btn>
                      </div>
                  </v-card-text>
              </v-card>
          <!-- </v-sheet> -->
      </v-bottom-sheet>

      <input type="file" id="signature_picker" style="display:none" accept="image/*" @change="fn_validateSignFile"/>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// setInteractionMode('eager')
extend('required', required)

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false,
                content:'',
                editorOption: {
                  modules: {
                    toolbar: '#toolbar'
                  }
                },
                keywords:[{"name":"Business Fields", "items":[{"title":"Name", "keyword":"{{BusinessName}}"},{"title":"Address", "keyword":"{{BusinessAddress}}"},{"title":"State", "keyword":"{{BusinessState}}"}]}]
            }
        },
        components:{
            ValidationProvider,
            ValidationObserver,
            quillEditor
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
                this.$parent.disableOnSave = false;

                if(this.$root.CurrentBusiness && (this.$root.CurrentBusiness.GSTRegistrationStatus==1 || this.$root.CurrentBusiness.GSTRegistrationStatus==2)){
                    this.keywords = [{"name":"Business Fields", "items":[{"title":"Name", "keyword":"{{BusinessName}}"},{"title":"Address", "keyword":"{{BusinessAddress}}"},{"title":"State", "keyword":"{{BusinessState}}"},{"title":"Gst Number", "keyword":"{{GSTIN}}"}]}]
                }
                else{
                    this.keywords = [{"name":"Business Fields", "items":[{"title":"Name", "keyword":"{{BusinessName}}"},{"title":"Address", "keyword":"{{BusinessAddress}}"},{"title":"State", "keyword":"{{BusinessState}}"}]}]
                }
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_validateForm: function(){
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                }
              });
            },

            onEditorBlur(editor) {
                console.log('editor blur', editor);
            },

            onEditorFocus(editor) {
                console.log('editor focus', editor);
                console.log(editor.clientX);
                //this.getCaretPosition();
            },

            onEditorReady(editor) {
                console.log('editor ready', editor)
            },

            fn_uploadResponse: function(image){
                console.log(image);
                // this.content += '<p><img src="data:image/jpg;base64,'+image+'" alt=""></p>';
                let dataUrl = "data:image/jpg;base64,"+image;
                let selectionRange = this.$refs['myTextEditor'].quill.getSelection(true);
                this.$refs['myTextEditor'].quill.insertEmbed(selectionRange.index, 'image', dataUrl);
            },

            fn_editBusinessProfile: function(){
                this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/' + this.$route.params.listId + '/business/profile/edit'});
            },

            fn_previewContent: function(){
                this.$parent.fn_preview(this.content, true);
            },

            fn_addKeyword: function(keyword){
                console.log(this.$refs['myTextEditor'].quill.getSelection());
                let selectionRange = this.$refs['myTextEditor'].quill.getSelection(true);
                this.$refs['myTextEditor'].quill.insertText(selectionRange.index, keyword);
                // let html = '<span>'+keyword+'</span>'
                // this.$refs['myTextEditor'].quill.clipboard.dangerouslyPasteHTML(selectionRange.index, html);
            },

            fn_embedSign: function(dataUrl){
                let selectionRange = this.$refs['myTextEditor'].quill.getSelection(true);
                this.$refs['myTextEditor'].quill.insertEmbed(selectionRange.index, 'image', dataUrl);
                // this.content += '<p>test</p>';
                // let self = this;
                // setTimeout(function(){
                //     self.content += '<p>test</p>';
                //     console.log(this.content);
                // },100);
                console.log(this.content);
            },

            fn_uploadSignature: function(){
              // pick image change is enabled in version no 105 from android's side
                if(this.$root.appVersion < 109){
                  this.$eventHub.$emit('UpdateMobileApp');
                  return;
                }

                document.getElementById("signature_picker").value = "";
                document.getElementById('signature_picker').click();
            },

            fn_validateSignFile: function(event){
                let self = this;
                // let extentions = ['PDF','DOC','DOCX','TEXT','TXT','XLX','XLSX','CSV','JPG','JPEG','PNG'];
                console.log(event.target.files[0]);
                let file = event.target.files[0];

                if(file.size > 1000000){
                  this.$eventHub.$emit('snackbarAlert', {msg:'Max file size limit is 1MB'});
                  return;
                }

                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    console.log(reader.result);
                    self.$parent.fn_embedSignature(reader.result);
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            },
        }
    }
</script>

<style>
  .ql-toolbar, .ql-container{
    border:0 !important;
  }
  .ql-container{
    background:#fff;
    min-height:200px;
  }
</style>