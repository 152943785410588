<template>
    <div>
        <v-dialog v-model="dialog_transaction_options" no-click-animation scrollable persistent content-class="full-height" :max-width="320" transition="dialog-bottom-transition">
            <v-card>
                <v-card-title nopadding>
                    <v-subheader class="pr-2" full-width>
                        Add Transaction
                        <v-spacer></v-spacer>
                        <v-btn icon @click.native="dialog_transaction_options=false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-subheader>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text grey-back nopadding>

                    <v-list dense nopadding :class="{'mt-2':index!=0 && index!=1}" subheader v-for="(category, index) in listCategories" :key="index">
                        <template v-if="category.showGroup && (!groupTypeId || (!category.groupTypeId || (category.groupTypeId == groupTypeId)))">
                            <v-subheader light v-if="category.name" class="pl-4">{{category. name}}</v-subheader>
                            <v-divider v-if="category.name"></v-divider>
                            <v-list-item v-for="(item, itemIndex) in category.items" v-ripple :key="itemIndex" @click="fn_openForm(item.uriTitle)">
                                <v-list-item-content>
                                    <v-list-item-title v-html="item.title"></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon>keyboard_arrow_right</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    
                    </v-list>

                </v-card-text>
                
                <!-- <v-divider></v-divider>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.native="dialog_transaction_options=false">close</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default{
        props:['groupTypeId', 'accountId', 'accountName', 'showAll', 'showPartyOptions'],
        data(){
            return{
                dialog_transaction_options: false,
                "listCategories":[	
                    {
                        "name":null, 
                        "groupTypeId":null,
                        "showGroup":this.showPartyOptions,
                        "items":[
                            {
                                "id":1, 
                                "title":"Opening Ledger",
                                "uriTitle":"Opening Ledger",
                                "uri":"inventory/openingledger/items",
                                "routeName":"",
                                "listId":"opening_ledger",
                                "transactionTypeId":"40003"
                            },
                            {
                                "id":2, 
                                "title":"Set Balance",
                                "uriTitle":"Set Balance",
                                "uri":"setbalance/items",
                                "routeName":"",
                                "listId":"opening_stock",
                                "transactionTypeId":"40001"
                            }
                        ]
                    },	
                    {
                        "name":"Sales",
                        "groupTypeId":101, 
                        "showGroup":true,
                        "items":[
                            {
                                "id":1, 
                                "title":"Order",
                                "uriTitle":"Order",
                                "uri":"sales/order/items",
                                "routeName":"",
                                "listId":"sale",
                                "transactionTypeId":"10001"
                            },
                            {
                                "id":2, 
                                "title":"Delivery Challan",
                                "uriTitle":"Delivery Challan",
                                "uri":"sales/deliverychallan/items",
                                "routeName":"",
                                "listId":"sale",
                                "transactionTypeId":"10002"
                            },
                            {
                                "id":3, 
                                "title":"Sale Invoice",
                                "uriTitle":"Sale Invoice",
                                "uri":"sales/saleinvoice/items",
                                "routeName":"",
                                "listId":"sale",
                                "transactionTypeId":"10003"
                            },
                            {
                                "id":4, 
                                "title":"Sale Return",
                                "uriTitle":"Sale Return",
                                "uri":"sales/salereturn/items",
                                "routeName":"",
                                "listId":"sale",
                                "transactionTypeId":"10004"
                            },
                            {
                                "id":5, 
                                "title":"Estimate",
                                "uriTitle":"Estimate",
                                "uri":"sales/estimate/items",
                                "routeName":"",
                                "listId":"sale",
                                "transactionTypeId":"10005"
                            },
                        ]
                    },
                    {
                        "name":"Purchase", 
                        "groupTypeId":102,
                        "showGroup":true,
                        "items":[
                            {
                                "id":1, 
                                "title":"Purchase Order",
                                "uriTitle":"Purchase Order",
                                "uri":"purchase/purchaseorder/items",
                                "routeName":"",
                                "listId":"purchase",
                                "transactionTypeId":"20001"
                            },
                            {
                                "id":2, 
                                "title":"Purchase Challan",
                                "uriTitle":"Purchase Challan",
                                "uri":"purchase/purchasechallan/items",
                                "routeName":"",
                                "listId":"purchase",
                                "transactionTypeId":"20002"
                            },
                            {
                                "id":3, 
                                "title":"Purchase Invoice",
                                "uriTitle":"Purchase Invoice",
                                "uri":"purchase/purchaseinvoice/items",
                                "routeName":"",
                                "listId":"purchase",
                                "transactionTypeId":"20003"
                            },
                            {
                                "id":4, 
                                "title":"Purchase Return",
                                "uriTitle":"Purchase Return",
                                "uri":"purchase/purchasereturn/items",
                                "routeName":"",
                                "listId":"purchase",
                                "transactionTypeId":"20004"
                            },
                            {
                                "id":5, 
                                "title":"Expense",
                                "uriTitle":"Expense",
                                "uri":"purchase/expense/items",
                                "listId":"expense",
                                "routeName":"",
                                "transactionTypeId":"20005"
                            },
                        ]
                    },
                    {
                        "name":"Account Books", 
                        "groupTypeId":null,
                        "showGroup":true,
                        "items":[
                            {
                                "id":1, 
                                "title":"Payment In",
                                "uriTitle":"Receipt",
                                "uri":"receipt/items",
                                "listId":"accountbooks",
                                "routeName":"",
                                "transactionTypeId":"30001"
                            },
                            {
                                "id":2, 
                                "title":"Payment Out",
                                "uriTitle":"Payment",
                                "uri":"payment/items",
                                "routeName":"",
                                "listId":"accountbooks",
                                "transactionTypeId":"30002"
                            },
                            {
                                "id":3, 
                                "title":"Journal",
                                "uriTitle":"Journal",
                                "uri":"journal/items",
                                "routeName":"",
                                "listId":"accountbooks",
                                "transactionTypeId":"30003"
                            },
                            {
                                "id":4, 
                                "title":"Cash and Bank",
                                "uriTitle":"Contra",
                                "uri":"contra/items",
                                "routeName":"",
                                "listId":"accountbooks",
                                "transactionTypeId":"30004"
                            },
                            // {
                            // 	"id":5, 
                            // 	"title":"Credit Note",
                            // 	"uri":"creditnote/items",
                            // 	"routeName":""
                            // },
                            // {
                            // 	"id":6, 
                            // 	"title":"Debit Note",
                            // 	"uri":"debitnote/items",
                            // 	"routeName":""
                            // },
                        ]
                    }
                ]
            }
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_transaction_options = true;
            },
            closeDialogWeb: function () {
                this.dialog_transaction_options = false;
            },
            fn_openForm: function(uriTitle){
                // localStorage.setItem("SetDataDrive", 1);
                this.closeDialogWeb();
                // this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+uriTitle.toLowerCase().replace(/ /g,'')+'/items/new', query:{tagId:this.guidWeb()}});
                if(this.showAll){
					this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+uriTitle.toLowerCase().replace(/ /g,'')+'/items/new', query:{tagId:this.guidWeb()}})
				}
				else{
                    this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+uriTitle.toLowerCase().replace(/ /g,'')+'/items/new', query:{tagId:this.guidWeb(), id:this.accountId, name:this.accountName, dt:1}})
				}
            }
        }
    }
</script>