var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"no-click-animation":"","persistent":"","lazy":"","transition":"slide-y-transition","max-width":"460px"},model:{value:(_vm.dialog_gst),callback:function ($$v) {_vm.dialog_gst=$$v},expression:"dialog_gst"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","height":56}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialogWeb}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v("GST Details")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4 pl-2 pr-2",attrs:{"dialog-card-content":""}},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-layout',{attrs:{"row":"","wrap":"","white-back":"","ma-0":""}},[_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","pl-2":"","pr-2":""}},[_c('ValidationProvider',{attrs:{"name":"GST Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.$parent.gstTypes,"label":"Select GST Type","item-value":"id","item-text":"title","return-object":"","autocomplete":"null","required":"","error-messages":errors},model:{value:(_vm.Form.selectedGstType),callback:function ($$v) {_vm.$set(_vm.Form, "selectedGstType", $$v)},expression:"Form.selectedGstType"}})]}}])})],1),(_vm.Form.selectedGstType && _vm.Form.selectedGstType.id!=105)?_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","pl-2":"","pr-2":""}},[_c('ValidationProvider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.$parent.states ? _vm.$parent.states : [],"label":"State/Place of Supply","item-value":"itemId","item-text":"itemName","return-object":"","required":"","autocomplete":"null","error-messages":errors},model:{value:(_vm.Form.selectedGstState),callback:function ($$v) {_vm.$set(_vm.Form, "selectedGstState", $$v)},expression:"Form.selectedGstState"}})]}}],null,false,1884463741)})],1):_vm._e(),(_vm.Form.selectedGstType && (_vm.Form.selectedGstType.id==101 || _vm.Form.selectedGstType.id==102 || _vm.Form.selectedGstType.id==106 || _vm.Form.selectedGstType.id==107))?_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","pl-2":"","pr-2":""}},[_c('ValidationProvider',{attrs:{"name":"GST Number","rules":"required|verify_gstnumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"uppercase":"","label":"GST Number (GSTIN)","placeholder":" ","autocomplete":"null","hide-details":!errors || !errors.length,"error-messages":errors},on:{"input":_vm.fn_gstNumberInUpperCase},model:{value:(_vm.Form.gstNumber),callback:function ($$v) {_vm.$set(_vm.Form, "gstNumber", $$v)},expression:"Form.gstNumber"}})]}}],null,false,3526394522)}),_c('div',{staticClass:"pt-1",attrs:{"right-align":"","font12":"","light-opacity-color":""}},[_vm._v("Example: 12ABCDE1234A9Z9")])],1):_vm._e(),(_vm.Form.selectedGstType && _vm.Form.selectedGstType.id!=105)?_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","pl-2":"","pr-2":""}},[_c('ValidationProvider',{attrs:{"name":"Pan No","rules":"verify_pannumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"uppercase":"","label":"PAN No","placeholder":" ","autocomplete":"null","required":"","error-messages":errors},on:{"input":_vm.fn_panNumberInUpperCase},model:{value:(_vm.Form.panNumber),callback:function ($$v) {_vm.$set(_vm.Form, "panNumber", $$v)},expression:"Form.panNumber"}})]}}],null,false,4054678549)})],1):_vm._e(),(_vm.Form.selectedGstType && _vm.Form.selectedGstType.id==105)?_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","pl-2":"","pr-2":""}},[_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.$parent.countries ? _vm.$parent.countries : [],"label":"Country","item-value":"itemId","item-text":"itemName","autocomplete":"null","return-object":"","required":"","error-messages":errors},model:{value:(_vm.Form.selectedGstCountry),callback:function ($$v) {_vm.$set(_vm.Form, "selectedGstCountry", $$v)},expression:"Form.selectedGstCountry"}})]}}],null,false,2627154579)})],1):_vm._e()],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialogWeb}},[_vm._v("close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.fn_checkValidations('setdetails')}}},[_vm._v("Done")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }