<template>
  <div>
    <v-toolbar :height="56" flat extended>
        <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

        <v-toolbar-title>Groups</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

        <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

        <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
    </v-toolbar>

    <v-divider></v-divider>

    <div class="data-container grey-back hasExtention" v-scroll.self="onScroll">


      <v-list nopadding grey-back dense>

        <v-subheader white-back class="pl-4">Count: {{$root.numberToLocale(listItemObject.totalCount, 1)}}</v-subheader>

        <v-divider></v-divider>

        <template white-back v-for="(item, index) in listItemObject.items">
          <v-list-item white-back :key="index" :id="'item_'+item.accountGroupId">
              <v-list-item-content @click="fn_itemClick(item.accountGroupId, index)" cursor-pointer>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-menu dense left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                  </template>
                  <v-list nopadding dense>
                    <v-list-item @click="fn_copy(item.accountGroupId)">
                      <v-list-item-title>Copy</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <!-- <v-btn icon small @click="fn_copy(item.accountGroupId)"><v-icon font20>content_copy</v-icon></v-btn> -->
              </v-list-item-icon>
          </v-list-item>

          <v-divider v-if="listItemObject.items.length-1 != index" :key="item.name"></v-divider>
        </template>


      </v-list>

      <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
        <v-col cols="6">
          <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
        </v-col>
      </v-row>

      <div center-align class="pa-6" v-if="loadTimeOut">
          <p font12 light-opacity-color>Unable to load the items!</p>
          <v-btn capitalize text color="primary" @click="fn_searchItem">
              <v-icon left>refresh</v-icon>
              Retry
          </v-btn>
      </div>

     <!--  <transition name="fade" mode="out-in">

        <v-card noshadow class="create-placeholder-card" v-if="showPlaceHolder && !listItemObject.totalCount">
          <v-card-text @click="fn_new">

            <div center-align>
                    <div v-ripple class="placeholder-icon">
                      <i aria-hidden="true" class="v-icon material-icons add-icon">add</i>
                      <v-icon>add</v-icon>
                    </div><br>

                    <p class="title light-opacity-color">Create New Group</p>
                  </div>
          </v-card-text>
        </v-card>
        
      </transition> -->

      <!-- <div class="display_msg" v-if="!listItemObject.items.length">There are no items</div> -->
    </div>
  </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../../config.js').configs.bottomSheetMenu;
import {masterMixin} from '../../master_mixin.js'

// const transactionForms = require('../../../../config.js').configs.transactionForms;

// const dialogjs =  require('../../../masters/forms/dialog').dialogjs;
// const listIdObject = require('../../../../config.js').configs.mastersPredefinedLists;

  export default{
    mixins: [masterMixin],
    data(){
      return{
        formId:null,
        dialogStorageId:null,

        selectionItems:{
          accountGroupId:null,
          accountGroupName:null,
          natureId:null,
          groupTypeName:null,
        },

        backButtonPress:false,
        selectedItemId:null
      }
    },
    methods:{

      fn_itemClick: function (itemId, index) {
        let obj = {};
        obj.itemId = null;
        obj.lastScrollPos = window.pageYOffset;
        obj.itemIndex = index + 1;
        localStorage.setItem("master_items_state", JSON.stringify(obj));
        // tagId id form future use
        // itemName id form future use
        // this.$router.push('/masters/accounts/groups/'+itemId);
        this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/account_group/items/'+itemId);
      },

      fn_goBack: function(){
        this.backButtonPress = true;
        // this.$router.go(-1);
        this.$router.replace("/"+this.$root.CurrentBusiness.BusinessID+"/master_items");
      },

      // fn_closeView: function(){
      //  this.closeView(this.$route.query.tagId, 
      //    {
      //      onSuccess: function(response){
      //            console.log(response);
      //          }, 
      //          onError: function(error){
      //            console.log(error);
      //          }
      //    }
     //        );
     //        // this.fn_goBack();
      // },

      fn_new: function(){
        this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/account_group/items/new', query:{tagId:this.guidWeb()}});
      },

      fn_copy: function(itemId){
        this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/account_group/items/new', query:{tagId:this.guidWeb(), itemId:itemId, copy:true}});
      },

      fn_menuItemClick: function(tagId, menuId, menuAction, itemId){
        // tagId id form future use
        // menuId id form future use
        if(menuAction=='edit'){
          this.$router.push("/masters/accounts/groups/"+this.selectedItemId+"/edit");
        }
        else if(menuAction=='copy'){
          this.$router.push({path:'/masters/accounts/groups/new', query:{tagId:this.guidWeb(), itemId:this.selectedItemId, copy:true}});
        }
        else if(menuAction=='draft'){
          // this.$router.push({path:'/drafts', query:{category:"Groups", tabIndex:0}});
          this.$router.push({path:'/drafts', query:{tabIndex:0}});
        }
        else if(menuAction=='delete'){
          let self = this;
          this.fn_deleteMasterItem('accountGroup', itemId, function(response){
            if(response){
                          self.showToast('Item deleted');
                      }
                      else{
                          self.showToast("Item cannot be deleted as already in use");
                      }
          })
        }
        else if(menuAction == 'filterNature'){
          let obj = {};
          obj.functionName = 'ledgerGroupType';
          obj.toolbarTitle = 'Select Nature';
          obj.itemId = this.selectionItems.natureId;
          obj.itemName = this.selectionItems.natureName;
          this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_nature', obj);
        }
        else if(menuAction == 'filterAccountGroup'){
          let obj = {};
          obj.functionName = 'accountgroups';
          obj.toolbarTitle = 'Select Account Group';
          obj.itemId = this.selectionItems.accountGroupId;
          obj.itemName = this.selectionItems.accountGroupName;
          this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_accountGroup', obj);
        }
        else if(menuAction == 'clearallfilters'){
          this.fn_clearAll();
        }
      },

      fn_clearAll: function(){
        this.selectionItems.natureId = null;
        this.selectionItems.accountGroupId = null;
        localStorage.removeItem('item_filter');
        this.fn_filterData();
      },

      // fn_getBottomMenu: function (tagId, elementId, item) {
      //   let bottomMenu;

      //   if(elementId == bottomSheetMenu.toolBar.Id){
      //     bottomMenu = {"BottomMenu": [menuItem.help]};
      //   }
      //   else if(elementId == bottomSheetMenu.masterListItems.Id){
      //     bottomMenu = {"BottomMenu": [menuItem.copy]};
      //     !Number(item.isSystem) ? bottomMenu = {"BottomMenu": [menuItem.edit, menuItem.copy]} : '';
      //     this.selectedItemId = item.accountGroupId;
      //   }
      //   else if(elementId == bottomSheetMenu.moreFilters.Id){
      //     menuItem.filterNature.selectedItem = this.selectionItems.natureId ? this.selectionItems.natureName : null;
      //     menuItem.filterAccountGroup.selectedItem = this.selectionItems.accountGroupId ? this.selectionItems.accountGroupName : null;
      //     bottomMenu = {"BottomMenu": [menuItem.filterNature, menuItem.filterAccountGroup]};
      //     if(this.selectionItems.natureId || this.selectionItems.accountGroupId){
      //       bottomMenu.BottomMenu.push(menuItem.clearAll);
      //     }
      //   }

      //   this.setBottomMenu(tagId, bottomMenu, 
      //     {
      //       onSuccess: function (response) {
      //         console.log(response);
      //       },
      //       onError: function (error) {
      //         console.log(error);
      //       }
      //     }
      //   )
      // },

      // fn_openDialogAndroid: function (platform, name, ref, obj) {

      //   let tagId = this.guidWeb();

      //   var dialogObj = {};
      //   dialogObj.tagId = tagId;
      //   dialogObj.formId = this.formId;
      //   dialogObj.name = name;
      //   dialogObj.ref = ref;
      //   dialogObj.platform = platform;

      //   var viewDialogObject = {};
      //   viewDialogObject.ToolbarTitle = obj.toolbarTitle;
      //   viewDialogObject.isBottomMenu = false;

      //   if(ref == 'ref_nature'){
      //     viewDialogObject.groupTypes = [
      //       {'id':101, 'name':'Assets'}, 
      //       {'id':102, 'name':'Expenses'}, 
      //       {'id':103, 'name':'Income'}, 
      //       {'id':104, 'name':'Liabilities'}
      //     ]
      //   }

      //   var paramObject = {};
      //   paramObject.defaultValue = {"id":null, "name":null};

      //   if(obj.itemId && obj.itemName){
      //     paramObject.defaultValue = {"id":obj.itemId, "name":obj.itemName};
      //   }

      //   dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, obj.functionName, tagId, paramObject, ref);
      // },

      // fn_closeDialog: function () {
      //   dialogjs.fn_checkAndCloseOpenDialogs(this);
      // },

      fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
        if(ref == 'ref_nature'){
          this.selectionItems.natureId = !isRemoved ? selectedValueId : null;
          this.selectionItems.natureName = !isRemoved ? selectedValueName : null;
        }
        else if(ref == 'ref_accountGroup'){
          this.selectionItems.accountGroupId = !isRemoved ? selectedValueId : null;
          this.selectionItems.accountGroupName = !isRemoved ? selectedValueName : null;
        }

        isRemoved ? this.fn_closeDialog() : '';
        this.fn_filterData(true);
      },

      fn_filterData: function(cacheData){
        let filterObject = {};
        this.selectionItems.natureId ? filterObject.accountGroupNatures = [this.selectionItems.natureId] : '';
        this.selectionItems.accountGroupId ? filterObject.accountGroupIds = [this.selectionItems.accountGroupId] : '';
        cacheData ? localStorage.setItem('item_filter', JSON.stringify(this.selectionItems)) : '';
        this.refreshData('accountGroups', this.$route.query.tagId, filterObject);
      },

      fn_loadItems: function(search) {
        this.loadMore = true;
        let filterObject = {};
        filterObject.limit = 50;
        filterObject.startIndex = this.startIndex;
        filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : ''; 
        filterObject.accountGroupId = this.selectionItems.accountGroupId ? [this.selectionItems.accountGroupId] : [];
        filterObject.natureId = this.selectionItems.natureId ? [this.selectionItems.natureId] : [];

        let pageState = null;
        if(localStorage.getItem("master_items_state")){
          pageState = JSON.parse(localStorage.getItem("master_items_state"));
          filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
        }

        this.fn_loadTimeOut();

        let self = this;
        this.getItems("AccountGroupList", filterObject, 
          {
            onSuccess: function (response) {
              console.log(response);
              self.loadTimeOut = false;
              let responseJson = JSON.parse(response);
              self.listItemObject.totalCount = responseJson.totalCount;

              search ? self.listItemObject.items = [] : '';

              let itemFound = false;
              for(let i in responseJson.items){
                if(pageState && pageState.itemId){
                  if(responseJson.items[i].accountGroupId == pageState.itemId){
                    itemFound = true;
                  }
                }

                let itemIndex = self.listItemObject.items.findIndex(item => item.accountGroupId==responseJson.items[i].accountGroupId);

                if(itemIndex==-1){
                  self.listItemObject.items.push(responseJson.items[i]);  
                }
                
              }

              console.log(pageState);
              if(pageState && pageState.itemId){
                setTimeout(function(){
                  if(itemFound){
                    let scrollOptions = {"duration":0, "offset":-112, "easing":"linear"};
                    self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                    localStorage.removeItem("master_items_state");
                  }
                  else{
                    self.$vuetify.goTo(99999, self.scrollOptions);
                  }
                },100);
              }

              if(pageState && !pageState.itemId){
                setTimeout(function(){
                  let scrollOptions = {"duration":300, "offset":0, "easing":"linear"};
                  self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                  localStorage.removeItem("master_items_state");
                },100);
              }

              self.startIndex = self.listItemObject.items.length;
              self.loadMore = false;

              setTimeout(function(){
                self.showPlaceHolder = true;
              },300);
            },
            onError: function (error) {
              console.log(error);
              self.loadMore = false;
            }
          }
        )
        },

      fn_searchItem: function () {
        this.startIndex = 0;
        this.listItemObject.items = [];
        this.fn_loadItems(true);
      },

      // fn_showWebView: function () {
      //   var viewObject = {};

      //   var tagId = null;
      //   this.$route.params && this.$route.query.tagId ? tagId = this.$route.query.tagId : '';
      //   var self = this;

      //   this.showWebView(tagId, viewObject, 
      //     {
      //       onSuccess: function(response){
      //             console.log(response);
      //           }, 
      //           onError: function(error){
      //             console.log(error);
      //           }, 
      //           // onChange: null, 
      //           onMenuItemClick: function (tagId, menuId, menuAction, itemId) {
      //         self.fn_menuItemClick(tagId, menuId, menuAction, itemId)
      //       }
      //     }
      //   );
      // }
    },
    mounted: function(){

      // alert('in');

      // this.fn_showWebView();

      // // tagId = this.$route.query.tagId;
      // this.formId = this.$route.query.tagId;
      // this.dialogStorageId = 'dialog_'+this.formId;

      // window.js_globals.fn_onBackPress = this.fn_goBack;
      // window.js_globals.fn_closeClick = this.fn_closeDialog;
      // window.js_globals.fn_closeClick = this.fn_closeDialog;

      this.fn_loadItems();

      if(localStorage.getItem('item_filter')){
        let data = JSON.parse(localStorage.getItem('item_filter'));
        this.selectionItems.accountGroupId = data.accountGroupId ? data.accountGroupId : null;
        this.selectionItems.accountGroupName = data.accountGroupName ? data.accountGroupName : null;
        this.selectionItems.natureId = data.natureId ? data.natureId : null;
        this.selectionItems.natureName = data.natureName ? data.natureName : null;
      }

      this.$eventHub.$on('UpdateAccountGroupList', dataObj => {
        console.log(dataObj);
        this.fn_searchItem();
      });
    },
    // beforeRouteLeave (to, from, next) {
    //     var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
    //   if(dialogData && dialogData.length){
    //     // dialogjs.fn_checkAndCloseOpenDialogs(this);
    //     this.backButtonPress = false;
    //     next(false);
    //   }
    //     else{
    //     this.backButtonPress ? localStorage.removeItem('item_filter') : '';
    //     next();
    //   }
    // }
  }
</script>